import chatFlowBuiltInTypes from 'components/@home/@settings/Tabs/chatFlowBuiltInTypes';
import requiredError from 'components/@home/@settings/Tabs/requiredError';
import { withFormik } from 'formik';
import { I18n } from 'utils/i18n';
import * as Yup from 'yup';

const getEntityType = (chatFlowEntityTypes, s) => {
  const type = s.name.split('_')[0];
  return Object.values(chatFlowEntityTypes).find(e => e.name === type) ||
    chatFlowBuiltInTypes.includes(type)
    ? type
    : null;
};

class FormSubmit {
  constructor(values, formikBag) {
    this.formikBag = formikBag;
    this.values = values;
  }

  assembleForm = () => {
    const {
      values: { name, phrases, slots },
    } = this;

    const newPhrases = phrases
      .filter(p => p.text)
      .map(p => {
        const np = { ...p };
        if (np._id.includes('.')) {
          delete np._id;
        }
        return np;
      });

    const newSlots = slots.map(s => {
      const ns = { ...s };
      if (ns._id.includes('.')) {
        delete ns._id;
      }
      return ns;
    });

    return { name, phrases: newPhrases, slots: newSlots };
  };

  sendRequest = async form => {
    let ret;
    const { intent: { _id } = { _id: 'new' }, actions } = this.formikBag.props;
    if (_id === 'new') {
      const newForm = { ...form };
      delete newForm._id;
      ret = await actions.chatFlowIntents.create(newForm);
    } else {
      ret = await actions.chatFlowIntents.update(_id, form);
      actions.chatFlows.load();
    }
    ret = await actions.chatFlowIntents.find(ret.value._id);
    return ret.value;
  };

  displayErrors = () => {
    const { setErrors } = this.formikBag;
    setErrors({ server: I18n.t('Something went wrong with sending data') });
  };
}

const chatFlowIntentFormik = withFormik({
  enableReinitialize: true,
  validateOnChange: true,
  validationSchema: Yup.object().shape({
    name: Yup.string().required(requiredError('name')),
    phrases: Yup.array().min(2, requiredError('phrases')),
    slots: Yup.array().of(
      Yup.object({
        prompt: Yup.string().required(requiredError('prompt')),
        type: Yup.string().required(() => I18n.t('ChatFlowSettings.Invalid entity type')),
      }),
    ),
  }),
  mapPropsToValues: ({ intent, chatFlowEntityTypes }) => {
    const phrases = (intent?.phrases || []).map(p =>
      p._id ? p : { _id: `${Math.random()}`, text: p.text, hidden: !!p.hidden },
    );
    phrases.push({ _id: `${Math.random()}`, hidden: false, text: '' });
    return {
      _id: intent?._id || 'new',
      name: intent?.name || '',
      phrases,
      slots: (intent?.slots || []).map(s => ({
        ...s,
        type: getEntityType(chatFlowEntityTypes, s),
      })),
    };
  },
  handleSubmit: async (values, formikBag) => {
    const {
      props: { context },
      setSubmitting,
      resetForm,
    } = formikBag;

    const handler = new FormSubmit(values, formikBag);
    const form = handler.assembleForm();

    try {
      const ret = await handler.sendRequest(form);
      if (context.current.callback) {
        context.current.callback(ret);
      }
      context.pop();
      resetForm();
    } catch (err) {
      setSubmitting(false);
      handler.displayErrors(err);
    }
    return true;
  },
});

export default chatFlowIntentFormik;
