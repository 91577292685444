import EmployeeImport from 'services/api/EmployeeImport';

export const LIST_EMPLOYEE_IMPORTS = 'LIST_EMPLOYEE_IMPORTS';

export const CANCEL_EMPLOYEE_IMPORT = 'CANCEL_EMPLOYEE_IMPORT';

export const RESUME_EMPLOYEE_IMPORT = 'RESUME_EMPLOYEE_IMPORT';

export const SHOW_RESULTS_EMPLOYEE_IMPORT = 'SHOW_RESULTS_EMPLOYEE_IMPORT';

export const START_EMPLOYEE_IMPORT = 'START_EMPLOYEE_IMPORT';

export const DOWNLOAD_RESUMED_EMPLOYEE_IMPORT = 'DOWNLOAD_RESUMED_EMPLOYEE_IMPORT';

export const EMPLOYEE_IMPORT_FINISHED = 'EMPLOYEE_IMPORT_FINISHED';

const list = () => ({
  type: LIST_EMPLOYEE_IMPORTS,
  payload: EmployeeImport.list(),
});

const cancel = employeeImportId => ({
  type: CANCEL_EMPLOYEE_IMPORT,
  payload: EmployeeImport.cancel(employeeImportId),
  meta: { employeeImportId },
});

const resume = id => ({
  type: RESUME_EMPLOYEE_IMPORT,
  payload: EmployeeImport.resume(id),
});

const downloadResumedImport = url => ({
  type: DOWNLOAD_RESUMED_EMPLOYEE_IMPORT,
  payload: EmployeeImport.downloadResumedImport(url),
});

const showResults = (employeeImportId, status) => ({
  type: SHOW_RESULTS_EMPLOYEE_IMPORT,
  payload: EmployeeImport.getFileUrl(employeeImportId),
  meta: {
    employeeImportId,
    status,
  },
});

const start = employeeImportId => ({
  type: START_EMPLOYEE_IMPORT,
  payload: EmployeeImport.start(employeeImportId),
  meta: { employeeImportId },
});

const finished = payload => ({
  type: EMPLOYEE_IMPORT_FINISHED,
  payload,
});

export default { cancel, downloadResumedImport, finished, list, resume, showResults, start };
