import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import 'emoji-mart/css/emoji-mart.css';
import { makeStyles } from '@mui/styles';
import Loading from 'components/common/Loading';
import { func } from 'prop-types';
import React, { memo, Suspense, useEffect, useRef, useState } from 'react';
import OptionButton from '../OptionButton';

const Picker = React.lazy(() => import('./Picker'));

const useStyles = makeStyles({
  root: { position: 'relative' },
  picker: {
    position: 'absolute',
    zIndex: 99,
    bottom: 0,
    right: 0,
  },
});

/**
 * Hook that alerts clicks outside of the passed ref
 */
const useOutsideAlerter = (ref, onClickOutside) => {
  /**
   * Alert if clicked on outside of element
   */
  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      onClickOutside();
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
};

const PickEmojiButton = ({ onPick }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef(null);
  const onClickOutside = () => setOpen(false);
  useOutsideAlerter(wrapperRef, onClickOutside);
  const onButtonClick = () => setOpen(true);
  const onSelect = ({ native }) => {
    onPick(native);
  };
  return (
    <div className={classes.root} ref={wrapperRef}>
      <Suspense fallback={<Loading classes={{ root: classes.loading }} size={60} />}>
        <OptionButton icon={<InsertEmoticonIcon />} onClick={onButtonClick} />
        {open && (
          <div className={classes.picker}>
            <Picker
              title=""
              emoji=""
              showSkinTones={false}
              showPreview={false}
              onSelect={onSelect}
              native
            />
          </div>
        )}
      </Suspense>
    </div>
  );
};

PickEmojiButton.propTypes = {
  onPick: func.isRequired,
};

export default memo(PickEmojiButton);
