import { SET_ARCHIVED_CHANNEL_FULFILLED, SET_CHANNEL } from 'store/app/entities/channels/action';
import search from 'utils/search';
import removeAccents from 'utils/removeAccents';
import { FILTER_CHANNELS, OPEN_CHANNEL, SHOW_ARCHIVED } from './action';

const initialState = {
  currentId: null,
  filtered: [],
  filter: '',
  showArchived: false,
};

const ChannelsPanel = (state = initialState, { type, payload, meta }) => {
  switch (type) {
    case FILTER_CHANNELS: {
      const { channels } = meta;

      const channelsArray = Object.values(channels);

      const filteredChannels = channelsArray.filter(c => !search(payload, c.name, c.description));

      const filteredChannelsIds = filteredChannels.map(c => c._id);

      return {
        ...state,
        filter: payload,
        filtered: payload ? filteredChannelsIds : [],
      };
    }

    case SET_CHANNEL:
      return {
        ...state,
        currentId: payload._id,
        messageId: meta?.messageId || null,
      };

    case OPEN_CHANNEL:
      return {
        ...state,
        currentId: payload,
        messageId: meta?.messageId || null,
      };

    case SHOW_ARCHIVED:
      return {
        ...state,
        showArchived: payload,
      };

    case SET_ARCHIVED_CHANNEL_FULFILLED:
      return {
        ...state,
        currentId: 1,
        messageId: null,
      };

    default:
      return state;
  }
};

export default ChannelsPanel;
