import { Button, SvgIcon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon';
import { func } from 'prop-types';
import React, { memo } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 0,
    minHeight: 0,
    padding: '6px 7px',
  },
  arrowIcon: {
    fontSize: 24,
    color: theme.palette.primary.dark,
  },
}));

const BackButton = ({ onClick }) => {
  const classes = useStyles();
  return (
    <Button onClick={onClick} className={classes.root} color="secondary" variant="outlined">
      <SvgIcon className={classes.arrowIcon}>
        <ArrowLeftIcon />
      </SvgIcon>
    </Button>
  );
};
BackButton.propTypes = {
  onClick: func.isRequired,
};

export default memo(BackButton);
