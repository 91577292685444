import { I18n } from 'utils/i18n';

const validateName = (values, errors) => {
  if (!values.name) {
    return {
      ...errors,
      name: I18n.t('WhistleblowerPortalConfig.errors.Required', {
        field: I18n.t('WhistleblowerPortalConfig.atts.name'),
      }),
    };
  }
  return errors;
};

export default validateName;
