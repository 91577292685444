import { FormHelperText, Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import EmbeddableButton, {
  getShareableURL,
} from 'components/@home/drawers/AppDrawer/Form/EmbeddableButton';
import EmbeddableButtonCopy from 'components/@home/drawers/AppDrawer/Form/EmbeddableButtonCopy';
import FormikAutosuggest from 'components/formik/Autosuggest';
import CopyField from 'components/formik/CopyField';
import ListField from 'components/formik/ListField';
import PhotoUpload from 'components/formik/PhotoUpload';
import Scope from 'components/formik/Scope';
import SecretField from 'components/formik/SecretField';
import Switch from 'components/formik/Switch';
import TextField from 'components/formik/TextField';
import { FastField, Field, Form } from 'formik';
import orderBy from 'lodash/orderBy';
import { bool, object } from 'prop-types';
import React, { useEffect } from 'react';
import { I18n } from 'utils/i18n';
import appShape from 'shapes/appShape';
import connector from './connector';
import formik from './formik';
import ActionButtons from './formik/ActionButtons';
import Headline from './Headline';
import Label from './Label';

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: 20,
  },
  inputs: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
    paddingBottom: 30,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  type: {
    minWidth: 680,
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      minWidth: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
    justifyContent: 'space-between',
  },

  upload: {
    paddingTop: 20,
    paddingRight: 20,
  },

  leave: {
    display: 'flex',
    alignItems: 'center',
    justifySelf: 'flex-start',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  errorMessage: {
    padding: '0 20px',
    alignSelf: 'center',
  },
  copy: {
    display: 'flex',
  },
  readOnly: {
    backgroundColor: theme.palette.secondary.ultraLight,
  },
  title: {
    marginTop: theme.spacing(2),
  },
  titleShare: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  muted: {
    color: theme.palette.secondary.main,
  },
});

const AppForm = ({ app, classes, errors, isEdit, actions, loading, values, chatFlows }) => {
  const appId = app?._id;
  useEffect(() => {
    if (isEdit) {
      actions.apps.find(appId);
    }
  }, [actions.apps, appId, isEdit]);

  const handleReset = () => {
    actions.drawers.app.close();
  };

  const confirmRegenerate = ({ name }) => {
    actions.apps.regenerateSecret(appId, name);
  };

  useEffect(() => {
    actions.chatFlows.load();
  }, [actions.chatFlows]);

  const flowSuggestions = chatFlows
    ? orderBy(
        Object.values(chatFlows).map(i => ({
          value: i._id,
          label: i.name,
        })),
        [i => i.label?.toLowerCase()],
      )
    : [];

  return (
    <Form className={classes.root}>
      <Headline isEdit={isEdit} />
      <div className={classes.inputs}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="caption" className={classes.title}>
              {I18n.t('AppDrawer.BASIC INFORMATION')}
            </Typography>
            <Grid container spacing={0}>
              <Grid item xs={2}>
                <FastField
                  component={PhotoUpload}
                  classes={{ root: classes.upload }}
                  name="avatar"
                />
              </Grid>
              <Grid item xs={10}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Label>{I18n.t('AppDrawer.NameField.label')}</Label>
                    <FastField
                      component={TextField}
                      autoComplete="off"
                      placeholder={I18n.t('AppDrawer.NameField.placeholder')}
                      name="name"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Label>{I18n.t('AppDrawer.DescriptionField.label')}</Label>
                    <FastField
                      component={TextField}
                      autoComplete="off"
                      placeholder={I18n.t('AppDrawer.DescriptionField.placeholder')}
                      name="description"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid spacing={2} container alignItems="center">
                <Grid item>
                  <FastField component={Switch} className={classes.field} name="chatbot" />
                </Grid>
                <Grid item>
                  <Typography>{I18n.t('AppDrawer.Ommnio chatbot App')}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {values.chatbot?.length ? (
            <Grid spacing={2} item xs={12}>
              <Typography variant="caption" className={classes.title}>
                {I18n.t('AppDrawer.CHATFLOW CONFIGURATION')}
              </Typography>
              <Label>{I18n.t('AppDrawer.ChatFlow.label')}</Label>
              {flowSuggestions.length ? (
                <Field
                  component={FormikAutosuggest}
                  suggestions={flowSuggestions}
                  placeholder={I18n.t('AppDrawer.ChatFlow.placeholder')}
                  name="chatFlow"
                  className={classes.field}
                />
              ) : (
                <Typography variant="caption" color="error">
                  {I18n.t('AppDrawer.You do not have any ChatFlows')}
                </Typography>
              )}
            </Grid>
          ) : (
            <>
              <Grid spacing={2} item xs={12}>
                {isEdit ? (
                  <>
                    <Typography variant="caption" className={classes.title}>
                      {I18n.t('AppDrawer.APP CREDENTIALS')}
                    </Typography>
                    <Grid container columnSpacing={2}>
                      <Grid item xs={6}>
                        <Label>Id</Label>
                        <FastField
                          component={TextField}
                          autoComplete="off"
                          name="_id"
                          readOnly
                          InputProps={{ className: classes.readOnly }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Label>{I18n.t('AppDrawer.CreatedAtField.label')}</Label>
                        <FastField
                          component={TextField}
                          autoComplete="off"
                          name="createdAt"
                          readOnly
                          InputProps={{ className: classes.readOnly }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Label>{I18n.t('AppDrawer.baseUrl.label')}</Label>
                        <FastField component={CopyField} name="baseUrl" readOnly />
                      </Grid>
                      <Grid item xs={6}>
                        <Label>{I18n.t('AppDrawer.company.label')}</Label>
                        <FastField component={CopyField} name="companyId" readOnly />
                      </Grid>
                    </Grid>
                    <Label>{I18n.t('AppDrawer.SigningSecretField.label')}</Label>
                    <div className={classes.copy}>
                      <FastField
                        component={SecretField}
                        name="signingSecret"
                        confirmRegenerate={confirmRegenerate}
                      />
                    </div>
                    <Label>{I18n.t('AppDrawer.ConfigPageURLField.label')}</Label>
                    <FastField
                      component={TextField}
                      autoComplete="off"
                      name="configPageUrl"
                      placeholder="https://..."
                    />
                    <FormHelperText>
                      {I18n.t('AppDrawer.ConfigPageURLField.placeholder')}
                    </FormHelperText>
                    <Label>{I18n.t('AppDrawer.BotTokenField.label')}</Label>
                    <div className={classes.copy}>
                      <FastField
                        component={SecretField}
                        name="botToken"
                        confirmRegenerate={confirmRegenerate}
                      />
                    </div>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Label>{I18n.t('AppDrawer.BotUserField.label')}</Label>
                        <div className={classes.copy}>
                          <FastField component={CopyField} name="appUser" />
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <Label>{I18n.t('AppDrawer.BotEmployeeField.label')}</Label>
                        <div className={classes.copy}>
                          <FastField component={CopyField} name="appEmployee" />
                        </div>
                      </Grid>
                    </Grid>
                    <Label>{I18n.t('AppDrawer.BotScopesField.label')}</Label>
                    <FastField
                      component={ListField}
                      itemComponent={Scope}
                      name="botScopes"
                      filterOut={values.botScopes}
                    />
                  </>
                ) : null}
              </Grid>
              <Grid spacing={2} item xs={12}>
                <Typography variant="caption" className={classes.title}>
                  {I18n.t('AppDrawer.ENDPOINTS')}
                </Typography>

                <Label>{I18n.t('AppDrawer.InteractiveEndpointField.label')}</Label>
                <FastField
                  component={TextField}
                  autoComplete="off"
                  name="interactiveEndpoint"
                  placeholder="https://..."
                />
                <FormHelperText>
                  {I18n.t('AppDrawer.InteractiveEndpointField.placeholder')}
                </FormHelperText>
                <Label>{I18n.t('AppDrawer.EventEndpointField.label')}</Label>
                <FastField
                  component={TextField}
                  autoComplete="off"
                  name="eventEndpoint"
                  placeholder="https://..."
                />
                <FormHelperText>
                  {I18n.t('AppDrawer.EventEndpointField.placeholder')}
                </FormHelperText>
              </Grid>
              <Grid spacing={2} item xs={12}>
                <Typography variant="caption" className={classes.title}>
                  {I18n.t('AppDrawer.OAuth')}
                </Typography>
                <Grid spacing={2} container alignItems="center">
                  <Grid item>
                    <FastField component={Switch} className={classes.field} name="enableOauth" />
                  </Grid>
                  <Grid item>
                    <Typography>{I18n.t('AppDrawer.Enable OAuth')}</Typography>
                  </Grid>
                </Grid>
                {values.enableOauth?.length ? (
                  <>
                    <Label>{I18n.t('AppDrawer.AppSecretField.label')}</Label>
                    <div className={classes.copy}>
                      <FastField
                        component={SecretField}
                        name="appSecret"
                        confirmRegenerate={confirmRegenerate}
                      />
                    </div>

                    <Label>{I18n.t('AppDrawer.RedirectUrisField.label')}</Label>
                    <FastField component={ListField} name="redirectUris" />

                    <Label>{I18n.t('AppDrawer.UserScopesField.label')}</Label>
                    <FastField
                      component={ListField}
                      itemComponent={Scope}
                      name="userScopes"
                      filterOut={values.userScopes}
                    />
                    <Typography variant="caption" className={classes.titleShare}>
                      {I18n.t('AppDrawer.SHARE YOUR APP')}
                    </Typography>
                    <Typography paragraph className={classes.muted}>
                      {I18n.t('AppDrawer.You can use the URL')}
                    </Typography>
                    <Label>{I18n.t('AppDrawer.EmbeddableOmmnioButton')}</Label>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <EmbeddableButton app={values} />
                      </Grid>
                      <Grid item xs={12}>
                        <EmbeddableButtonCopy app={values} />
                      </Grid>
                    </Grid>
                    <Label>{I18n.t('AppDrawer.ShareableURL')}</Label>
                    <CopyField value={getShareableURL(values)} />
                  </>
                ) : (
                  <Typography className={classes.muted}>
                    {I18n.t('AppDrawer.You should not need OAuth')}
                  </Typography>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </div>
      <div className={classes.actions}>
        <div className={classes.errorContainer}>
          {errors.server && (
            <Typography align="right" color="error" className={classes.errorMessage}>
              {errors.server}
            </Typography>
          )}
        </div>
        <ActionButtons onReset={handleReset} isEdit={isEdit} disabled={loading} />
      </div>
    </Form>
  );
};

AppForm.propTypes = {
  app: appShape,
  classes: object.isRequired,
  actions: object.isRequired,
  isEdit: bool.isRequired,
  errors: object.isRequired,
  loading: bool.isRequired,
  values: object.isRequired,
  chatFlows: object.isRequired,
};

AppForm.defaultProps = {
  app: null,
};

export default withStyles(styles)(connector(formik(AppForm)));
