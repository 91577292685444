import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import { node, object, string } from 'prop-types';
import React from 'react';

const styles = theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 20,
  },
});

const Actions = ({ classes, children, className }) => (
  <div className={classNames(classes.root, className)}>{children}</div>
);

Actions.propTypes = {
  classes: object.isRequired,
  children: node.isRequired,
  className: string,
};

Actions.defaultProps = {
  className: null,
};

export default withStyles(styles)(Actions);
