import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { object, string } from 'prop-types';

const styles = theme => ({
  root: {
    height: '29px',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSize: '24px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.6px',
    color: theme.palette.primary.ultraDark,
  },
});

const capitalize = e =>
  e
    .charAt(0)
    .toUpperCase()
    .concat(e.slice(1).toLowerCase());

const Employee = ({ classes, name, surname }) => (
<div className={classes.root}>
    {capitalize(name)} {capitalize(surname)}
  </div>
);Employee.propTypes = {
  classes: object.isRequired,
  name: string.isRequired,
  surname: string.isRequired,
};

export default withStyles(styles)(Employee);
