/* eslint-disable no-nested-ternary */

import {
  timeDay,
  timeHour,
  timeMinute,
  timeMonday,
  timeMonth,
  timeSecond,
  timeSunday,
  timeYear,
} from 'd3-time';
import { timeFormatLocale } from 'd3-time-format';
import d3Locales from 'utils/d3Locales';

const getFormat = locale => timeFormatLocale(d3Locales[locale] || d3Locales.en).format;

export const formatLocale = (locale, specifier) => getFormat(locale)(specifier);

export const multiTimeFormat = locale => date => {
  const format = getFormat(locale);
  const formatMillisecond = format('.%L');
  const formatSecond = format(':%S');
  const formatMinute = format(locale === 'en' ? '%I:%M' : '%H:%M');
  const formatHour = format(locale === 'en' ? '%I %p' : '%H');
  const formatWeek = format(locale === 'en' ? '%b %d' : '%d-%b');
  const formatDay = format('%a');
  const formatMonth = format('%B');
  const formatYear = format('%Y');

  const timeWeek = locale === 'en' ? timeSunday : timeMonday;
  const startOfThisWeek = timeWeek(new Date());
  return (
    timeSecond(date) < date
      ? formatMillisecond
      : timeMinute(date) < date
      ? formatSecond
      : timeHour(date) < date
      ? formatMinute
      : timeDay(date) < date
      ? formatHour
      : timeMonth(date) < date
      ? timeWeek(date) <= date && date >= startOfThisWeek
        ? formatDay
        : formatWeek
      : timeYear(date) < date
      ? formatMonth
      : formatYear
  )(date);
};
