import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import acknowledgmentReadModal from 'store/app/ui/modals/acknowledgment/read/action';
import analyticsDrawer from 'store/app/ui/drawers/analytics/action';
import isCompanyAdmin from 'store/selectors/isCompanyAdmin';
import isTeamAdmin from 'store/selectors/isTeamAdmin';
import authEmployee from 'store/selectors/authEmployee';

const mapStateToProps = state => ({
  isCompanyAdmin: isCompanyAdmin(state),
  isTeamAdmin: isTeamAdmin(state),
  authEmployee: authEmployee(state),
  message: state.modals.acknowledgment.read.message,
  employee: state.modals.acknowledgment.read.employee,
  isOpen: state.modals.acknowledgment.read.isOpen,
  log: state.modals.acknowledgment.read.log,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    acknowledgmentReadModal: bindActionCreators(acknowledgmentReadModal, dispatch),
    analyticsDrawer: bindActionCreators(analyticsDrawer, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
