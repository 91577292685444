/* eslint-disable class-methods-use-this */
import Http from 'services/Http';

class App {
  async find(id) {
    const { data } = await Http.get(`/v1/app/${id}`);
    return data.app;
  }

  async list(page = 0, perPage = 100) {
    const { data } = await Http.get(`/v1/app?page=${page}&perPage=${perPage}`);
    return data;
  }

  async create(form) {
    const response = await Http.post('/v1/app', form);
    return response.data.app;
  }

  async update(id, form) {
    const response = await Http.patch(`/v1/app/${id}`, form);
    return response.data.app;
  }

  async install(id, installed) {
    const { data } = await Http.patch(`/v1/app/${id}/${installed ? '' : 'un'}install`);
    return data;
  }

  async addEmployee(id) {
    const { data } = await Http.post(`/v1/app/${id}/employee`);
    return data;
  }

  async removeEmployee(id) {
    const { data } = await Http.delete(`/v1/app/${id}/employee`);
    return data;
  }

  async regenerateSecret(id, name) {
    const { data } = await Http.patch(`/v1/app/${id}/regenerate/${name}`);
    return data;
  }

  async destroy(id) {
    const { data } = await Http.delete(`/v1/app/${id}`);
    return data;
  }

  async upload(id, field, avatar) {
    const form = new FormData();
    form.append(field, avatar);
    const { data } = await Http.post(`/v1/app/${id}/file`, form);
    return data.app;
  }

  async getGroups(id) {
    const { data } = await Http.get(`/v1/app/${id}/groups`);
    return data;
  }
}

export default new App();
