import { Drawer } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FillFormDrawerContent from 'components/@home/drawers/FillFormDrawer/FillFormDrawerContent';
import Loading from 'components/common/Loading';
import isEqual from 'lodash/isEqual';
import { bool, func, object, string } from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import CloseButton from '../CloseButton';

import connector from './connector';
import formik from './formik';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    width: '100%',
    maxWidth: 'calc(100vw - 40px)',
    [theme.breakpoints.up('md')]: {
      width: 1100,
    },
  },
  container: {
    display: 'flex',
    flex: 1,
  },
  closeButtonContainer: {
    paddingTop: 20,
    paddingLeft: 20,
  },
}));

const FillFormDrawer = ({ form, formId, isOpen, actions, resetForm }) => {
  const classes = useStyles();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (isOpen && formId && !form.loading && !loaded) {
      actions.forms.find(formId);
      setLoaded(true);
    } else if (!isOpen) {
      setLoaded(false);
      resetForm();
    }
  }, [isOpen, form.loading, form.error, formId, actions, loaded]);

  const handleClose = () => {
    actions.drawers.fillForm.close();
    setLoaded(false);
    resetForm();
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose}>
      <div className={classes.root}>
        <div className={classes.closeButtonContainer}>
          <CloseButton onClick={handleClose} />
        </div>
        <div className={classes.container}>{!loaded ? <Loading /> : <FillFormDrawerContent />}</div>
      </div>
    </Drawer>
  );
};

FillFormDrawer.propTypes = {
  form: object,
  actions: object.isRequired,
  isOpen: bool.isRequired,
  resetForm: func.isRequired,
  formId: string,
};

FillFormDrawer.defaultProps = {
  form: { error: false },
  formId: null,
};

const areEqual = (prev, next) => {
  return ['form', 'isOpen', 'formId', 'message'].reduce(
    (acc, att) => acc && isEqual(prev[att], next[att]),
    true,
  );
};
export default connector(memo(formik(FillFormDrawer), areEqual));
