export const OPEN_PICTURE_MODAL = 'OPEN_PICTURE_MODAL';
export const CLOSE_PICTURE_MODAL = 'CLOSE_PICTURE_MODAL';

const open = src => ({
  type: OPEN_PICTURE_MODAL,
  payload: src,
});

const close = () => ({
  type: CLOSE_PICTURE_MODAL,
});

export default { open, close };
