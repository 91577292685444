import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import { bool, node, object, string } from 'prop-types';
import React from 'react';

const styles = {
  root: {},
  caption: {
    fontSize: 10,
    position: 'absolute',
    left: 45,
    bottom: -7,
  },

  captionRight: {
    left: 'auto',
    right: 45,
    bottom: -7,
  },
};

const Caption = ({ classes, right, children, className }) => (
  <Typography
    className={classNames({
      [classes.caption]: true,
      [classes.captionRight]: right,
      [className]: !!className,
    })}
    variant="caption"
  >
    {children}
  </Typography>
);
Caption.propTypes = {
  classes: object.isRequired,
  right: bool.isRequired,
  children: node,
  className: string,
};
Caption.defaultProps = {
  children: null,
  className: 'false',
};

export default withStyles(styles)(Caption);
