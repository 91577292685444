import { createApi } from '@reduxjs/toolkit/dist/query/react';
import baseQuery from 'store/app/entities/baseQuery';

const articleEndpoints = build => ({
  getArticle: build.query({
    query: ({ channelId, articleId }) => ({
      url: `v1/group/${channelId}/article/${articleId}`,
      method: 'GET',
    }),
    providesTags: result => (result ? [{ type: 'Article', id: result._id }] : []),
    transformResponse: response => response.data.article,
  }),
  updateArticle: build.mutation({
    query: ({ channelId, articleId, article }) => ({
      url: `v1/group/${channelId}/article/${articleId}`,
      method: 'PATCH',
      body: article,
    }),
    invalidatesTags: (result, error, { articleId }) => [
      { type: 'Article', id: 'LIST' },
      {
        type: 'Article',
        id: articleId,
      },
    ],
  }),
  deleteArticle: build.mutation({
    query: ({ channelId, articleId }) => ({
      url: `v1/group/${channelId}/article/${articleId}`,
      method: 'DELETE',
    }),
    invalidatesTags: (result, error, { articleId }) => [
      { type: 'Article', id: 'LIST' },
      {
        type: 'Article',
        id: articleId,
      },
    ],
  }),
});

export const articleApi = createApi({
  reducerPath: 'articles',
  baseQuery,
  tagTypes: ['Article'],
  endpoints: articleEndpoints,
});

export const { useDeleteArticleMutation, useGetArticleQuery, useUpdateArticleMutation } =
  articleApi;

export const { reducer: articlesReducer, middleware: articlesMiddleware } = articleApi;
