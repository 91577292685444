import sortBy from 'lodash/sortBy';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import auth from 'store/app/auth/action';

const mapStateToProps = state => ({
  companyId: state.auth.company_id,
  companies: sortBy(Object.values(state.companies), 'name').filter(c => c.name),
});

const mapDispatchToProps = dispatch => ({
  actions: {
    auth: bindActionCreators(auth, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
