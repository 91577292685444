import React from 'react';
import { object, arrayOf, string, func } from 'prop-types';
import { Chip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import CloseIcon from 'mdi-react/CloseIcon';

const styles = theme => ({
  root: {
    paddingTop: 5,
  },
  tag: {
    height: 35,
    borderRadius: 5,
    backgroundColor: theme.palette.secondary.ultraLight,
    marginRight: 5,
    marginBottom: 10,
  },
});

const TagList = ({ classes, tags, onDelete }) => (
  <div className={classes.root}>
    {tags.map(tag => (
      <Chip
        label={tag}
        key={tag}
        deleteIcon={<CloseIcon />}
        className={classes.tag}
        onDelete={onDelete(tag)}
      />
    ))}
  </div>
);
TagList.propTypes = {
  classes: object.isRequired,
  onDelete: func.isRequired,
  tags: arrayOf(string),
};

TagList.defaultProps = {
  tags: [],
};

export default withStyles(styles)(TagList);
