/**
 * @flow
 */
import { Button, SvgIcon, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import FillFormDrawerQuestions from 'components/@home/drawers/FillFormDrawer/FillFormDrawerQuestions';
import QuestionErrorMessage from 'components/@home/drawers/FormDrawer/QuestionErrorMessage';
import { FieldArray, Form, useFormikContext } from 'formik';
import ArrowRightIcon from 'mdi-react/ArrowRightIcon';
import LockIcon from 'mdi-react/LockIcon';
import LockOpenIcon from 'mdi-react/LockOpenIcon';
import React from 'react';
import { I18n } from 'utils/i18n';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: 20,
    maxWidth: 'calc(100% - 60px)',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    position: 'relative',
  },
  body: {
    overflow: 'auto',
    paddingBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingTop: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  error: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  arrowIcon: {
    marginTop: 1,
    marginLeft: 5,
    fontSize: 17,
  },
  submit: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    backgroundColor: theme.palette.tertiary.dark,
    marginTop: theme.spacing(4),
    '&:hover': {
      backgroundColor: theme.palette.tertiary.main,
    },
  },
  submitBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'opacity 300ms, transform 300ms',
    opacity: 0,
    height: 0,
    transform: 'translateY(500px)',
    overflow: 'visible',
    pointerEvents: 'none',
  },
  visible: {
    opacity: 1,
    transform: 'translateY(0%)',
    pointerEvents: 'initial',
  },
  title: {
    marginTop: theme.spacing(40),
    marginBottom: theme.spacing(2),
  },
  lock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& svg': {
      marginRight: theme.spacing(0.5),
      color: theme.palette.secondary.main,
    },
  },
  continue: {
    marginLeft: theme.spacing(2),
  },
  fieldSet: {
    border: 0,
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  when: {
    marginRight: '1em',
  },
  subheadingWrapper: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 20,
  },
  subheading: {
    fontSize: '12px',
    fontWeight: '500',
    textTransform: 'uppercase',
    marginBottom: '5px',
    color: theme.palette.primary.main,
  },
}));

const FillFormDrawerContent = () => {
  const classes = useStyles();
  const formik = useFormikContext();
  const {
    isSubmitting,
    isValid,
    submitCount,
    errors,
    values: { form, currentQuestion, disabled },
  } = formik;

  if (form?.error) {
    return (
      <div className={classes.error}>
        <Typography>{I18n.t('FillFormDrawer.Error loading form')}</Typography>
      </div>
    );
  }
  const showSubmit = currentQuestion === form.questions?.length;
  const lastQuestion = currentQuestion === form.questions?.length - 1;

  const showError = !isValid && submitCount > 0;

  return (
    <Form className={classes.root}>
      <Typography variant="h6" component="h1">
        {form.title}
      </Typography>
      <>
        {form.anonymous && (
          <div className={classes.subheadingWrapper}>
            <span className={classes.subheading}>{I18n.t('FillFormDrawer.AnonymousAnswers')}</span>
          </div>
        )}
        <div
          className={classNames(classes.submitBody, { [classes.visible]: showSubmit && !disabled })}
        >
          <Typography variant="h6" component="h6" className={classes.title}>
            {I18n.t('FillFormDrawer.I hereby confirm', { title: form.title })}
          </Typography>
          <Typography className={classes.lock}>
            <SvgIcon>{form.adminOnly ? <LockIcon /> : <LockOpenIcon />}</SvgIcon>
            {I18n.t(
              `FillFormDrawer.Answer${form.adminOnly ? 'Private' : 'Public'}${
                form.anonymous ? 'Anonymous' : ''
              }`,
            )}
          </Typography>
          <Button
            variant="contained"
            disabled={isSubmitting || showError}
            className={classes.submit}
            color="primary"
            type="submit"
          >
            {isSubmitting
              ? I18n.t('FillFormDrawer.Sending answers')
              : I18n.t('FillFormDrawer.Send answers')}
          </Button>
          <Typography variant="caption">
            {I18n.t('FillFormDrawer.When you click submit')}
          </Typography>
        </div>
        <div className={classes.body}>
          <fieldset className={classes.fieldSet} disabled={disabled}>
            <FieldArray name="questions" component={FillFormDrawerQuestions} />
          </fieldset>{' '}
        </div>
        {!showSubmit && (
          <div className={classes.footer}>
            <div className={classes.errors}>
              {showError ? (
                <QuestionErrorMessage>
                  {errors.server || I18n.t('FillFormDrawer.Please check errors above')}
                </QuestionErrorMessage>
              ) : null}
            </div>
            <div>
              {!disabled && (
                <Typography variant="caption" display="inline" className={classes.when}>
                  {I18n.t('FillFormDrawer.When you click continue')}
                </Typography>
              )}
              <Typography variant="caption" display="inline">
                {currentQuestion + 1}/{form.questions?.length}
              </Typography>
              <Button
                className={classes.continue}
                variant="contained"
                disabled={isSubmitting || showError}
                color="primary"
                type="sumbit"
              >
                {lastQuestion && disabled
                  ? I18n.t('FillFormDrawer.Close')
                  : I18n.t('FillFormDrawer.Continue')}
                {!(lastQuestion && disabled) && (
                  <SvgIcon className={classes.arrowIcon}>
                    <ArrowRightIcon />
                  </SvgIcon>
                )}
              </Button>
            </div>
          </div>
        )}
      </>
    </Form>
  );
};

export default FillFormDrawerContent;
