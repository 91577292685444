import withStyles from '@mui/styles/withStyles';
import Autosuggest from 'components/controls/Autosuggest';
import { arrayOf, func, object, string } from 'prop-types';
import React from 'react';
import TagList from './TagList';

const styles = {
  root: {},

  field: {
    flexGrow: 1,
    padding: 5,
  },
  form: {
    display: 'flex',
  },
  button: {
    margin: 5,
    minWidth: 100,
  },
};

class TagsField extends React.Component {
  state = {
    suggestion: {},
  };

  add = suggestion => {
    const { name, value, onChange } = this.props;
    if (!suggestion) return;

    value.push(suggestion.value);
    this.setState({ suggestion: '' });

    onChange(name, value);
  };

  delete = tag => () => {
    const { name, value, onChange } = this.props;
    const tags = value.filter(t => t !== tag);
    onChange(name, tags);
  };

  createTag = newTag => {
    const { name, value, onChange, createTag } = this.props;
    createTag(newTag);
    onChange(name, [...value, newTag]);
  };

  render() {
    const { classes, tags, value, ...props } = this.props;
    const { suggestion } = this.state;
    const suggestions = tags
      .filter(t => !value.includes(t))
      .map(tag => ({ value: tag, label: tag }));

    return (
      <div className={classes.root}>
        <div className={classes.form}>
          <Autosuggest
            {...props}
            creatable
            onCreate={this.createTag}
            className={classes.field}
            suggestions={suggestions}
            value={suggestion}
            onChange={this.add}
          />
        </div>
        <TagList tags={value} onDelete={this.delete} />
      </div>
    );
  }
}

TagsField.propTypes = {
  classes: object.isRequired,
  onChange: func,
  value: arrayOf(string),
  name: string.isRequired,
  tags: arrayOf(string),
  actions: object.isRequired,
  createTag: func,
};
TagsField.defaultProps = {
  onChange: () => {},
  value: [],
  createTag: () => {},
  tags: [],
};

export default withStyles(styles)(TagsField);
