import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import {
  createMigrate,
  createTransform,
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/hardSet';
import storage from 'redux-persist-indexeddb-storage';

import countryCodes from './countryCodes/reducer';
import languages from './languages/reducer';
import scopes from './scopes/reducer';
import timeZones from './timeZones/reducer';

const migrations = {
  0: state => {
    // prevent languages from versions lower than 1 to load
    return {
      ...state,
      languages: undefined,
      _persist: { ...state._persist, version: 1 },
    };
  },
};

const RemoveLoadingTransform = createTransform(
  inboundState => {
    if (inboundState.loading) {
      return {
        ...inboundState,
        loading: false,
      };
    }
    return inboundState;
  },
  outboundState => {
    if (outboundState.loading) {
      return {
        ...outboundState,
        loading: false,
      };
    }
    return outboundState;
  },
);

const persistConfig = {
  key: 'config',
  storage: storage('ommnio'),
  version: 0,
  stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
  debug: true,
  transforms: [RemoveLoadingTransform],
  migrate: createMigrate(migrations, { debug: true }),
};

const baseReducer = combineReducers({
  countryCodes,
  languages,
  scopes,
  timeZones,
});

const reducer = persistReducer(persistConfig, baseReducer);

const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      // Redux persist
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
export const persistor = persistStore(store);

export default reducer;
