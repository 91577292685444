import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import Info from '@mui/icons-material/Info';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { Button, ListItemIcon, Menu, MenuItem, SvgIcon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

import DeleteDialog from 'components/common/DeleteDialog';
import PinIcon from 'mdi-react/PinIcon';
import ReplyIcon from 'mdi-react/ReplyIcon';
import ShareIcon from 'mdi-react/ShareIcon';
import { bool, func, object, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { I18n } from 'utils/i18n';
import channelShape from 'shapes/channel';
import hasPreviews from 'utils/hasPreviews';
import isPicture from 'utils/isPicture';
import isVideo from 'utils/isVideo';
import connector from './connector';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    right: 7,
    top: 3,
    zIndex: 1,
  },
  buttonAdminMenu: {
    minWidth: '0',
    minHeight: '0',
    fontSize: '1em',
    padding: 0,
    transform: 'translateX(20px)',
    transition: 'all 0.1s ease 0.1s',
    opacity: 0,
    borderRadius: 17,
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  visible: {
    opacity: 1,
    transform: 'translateX(0)',
  },
  mine: {
    color: theme.palette.secondary.main,
  },
  white: {
    '&$mine': {
      color: theme.palette.white,
    },
  },
  backdrop: {
    transform: 'translateX(20px)',
    transition: 'all 0.1s ease 0.1s',
    opacity: 0,
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0,0,0,.8)',
    filter: 'blur(16px)',
    '&$visible': {
      opacity: 1,
      transform: 'translateX(0)',
    },
  },
  editContainer: {
    display: 'flex',
    width: 550,
  },
  editButtons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  editInput: {
    border: 0,
    padding: 10,
    color: 'inherit',
    flex: '1 0 auto',
  },
  editButton: {
    background: theme.palette.white,
    boxShadow: '0 1px 2px 0 rgba(2, 2, 3, 0.05)',
    border: `solid 1px ${theme.palette.secondary.extraLight}`,
    padding: '0 10px',
    margin: 10,
    '&:hover': {
      background: theme.palette.primary.ultraLight,
    },
  },
}));

const AdminMenu = ({
  message,
  canDelete,
  canForward,
  canEdit,
  canPin,
  canReply,
  actions,
  messageId,
  permissionsState,
  temp,
  onDeleteConfirmed,
  visible,
  currentChannel,
}) => {
  const [adminMenuEl, setAdminMenuEl] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const classes = useStyles();
  const { isMine } = message;

  const shouldBeWhite = isPicture(message) || isVideo(message) || hasPreviews(message);

  useEffect(() => {
    if (
      messageId &&
      permissionsState === null &&
      typeof canEdit === 'undefined' &&
      !temp &&
      messageId.length === 24
    ) {
      actions.messages.permissions(messageId);
    }
  }, [actions.messages, canEdit, messageId, permissionsState, temp]);

  const openAdminMenu = event => {
    setAdminMenuEl(event.currentTarget);
  };

  const closeAdminMenu = () => {
    setAdminMenuEl(null);
  };

  const infoClicked = () => {
    actions.drawers.employeeList.open(I18n.t('AdminMenu.Read by'), message);
    closeAdminMenu();
  };

  const replyClicked = () => {
    actions.chatPanel.reply({ message, currentChannel });
    closeAdminMenu();
  };

  const forwardClicked = () => {
    actions.chatPanel.forward({ messages: [message], currentChannel });
    closeAdminMenu();
  };

  const editClicked = () => {
    actions.chatPanel.edit({ message, currentChannel });
    closeAdminMenu();
  };

  const pinClicked = async () => {
    await actions.messages.pin(message);
  };

  const deleteClicked = () => {
    setShowDeleteDialog(true);
    closeAdminMenu();
  };

  const deleteConfirmed = () => {
    setShowDeleteDialog(false);
    closeAdminMenu();
    if (onDeleteConfirmed) {
      onDeleteConfirmed();
    }
  };

  const deleteCanceled = () => {
    setShowDeleteDialog(false);
    closeAdminMenu();
  };

  if (permissionsState !== 'loaded' && typeof canEdit === 'undefined') {
    return null;
  }
  return (
    <div className={classes.root}>
      <div className={classes.adminMenu}>
        <div
          className={classNames({
            [classes.backdrop]: shouldBeWhite && isMine,
            [classes.visible]: visible,
          })}
        />
        <Button
          className={classNames({
            [classes.buttonAdminMenu]: true,
            [classes.mine]: isMine,
            [classes.white]: shouldBeWhite,
            [classes.visible]: visible,
          })}
          onClick={openAdminMenu}
        >
          <KeyboardArrowDown />
        </Button>
        <Menu
          id="admin-edit-menu"
          anchorEl={adminMenuEl}
          onClose={closeAdminMenu}
          open={Boolean(adminMenuEl)}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: -24, horizontal: 'left' }}
        >
          <MenuItem onClick={infoClicked}>
            <ListItemIcon>
              <Info />
            </ListItemIcon>
            {I18n.t('AdminMenu.Info')}
          </MenuItem>
          {canReply ? (
            <MenuItem onClick={replyClicked}>
              <ListItemIcon>
                <SvgIcon className={classes.icon}>
                  <ReplyIcon />
                </SvgIcon>
              </ListItemIcon>
              {I18n.t('AdminMenu.Reply')}
            </MenuItem>
          ) : null}
          {canPin ? (
            <MenuItem onClick={pinClicked}>
              <ListItemIcon>
                <SvgIcon className={classes.icon}>
                  <PinIcon />
                </SvgIcon>
              </ListItemIcon>
              {I18n.t('AdminMenu.Pin')}
            </MenuItem>
          ) : null}
          {canEdit ? (
            <MenuItem onClick={editClicked}>
              <ListItemIcon>
                <Edit />
              </ListItemIcon>
              {I18n.t('AdminMenu.Edit')}
            </MenuItem>
          ) : null}
          {canForward ? (
            <MenuItem onClick={forwardClicked}>
              <ListItemIcon>
                <SvgIcon className={classes.icon}>
                  <ShareIcon />
                </SvgIcon>
              </ListItemIcon>
              {I18n.t('AdminMenu.Forward')}
            </MenuItem>
          ) : null}
          {canDelete ? (
            <MenuItem onClick={deleteClicked}>
              <ListItemIcon>
                <Delete />
              </ListItemIcon>
              {I18n.t('AdminMenu.Delete')}
            </MenuItem>
          ) : null}
        </Menu>
        <DeleteDialog
          message={I18n.t('AdminMenu.Are you sure')}
          isOpen={showDeleteDialog}
          onConfirm={deleteConfirmed}
          onClose={deleteCanceled}
        />
      </div>
    </div>
  );
};

AdminMenu.propTypes = {
  message: object.isRequired,
  canEdit: bool.isRequired,
  canForward: bool.isRequired,
  canDelete: bool.isRequired,
  canPin: bool.isRequired,
  canReply: bool.isRequired,
  onDeleteConfirmed: func.isRequired,
  visible: bool,
  actions: object.isRequired,
  messageId: string.isRequired,
  permissionsState: string.isRequired,
  temp: bool,
  currentChannel: channelShape.isRequired,
};

AdminMenu.defaultProps = {
  visible: true,
  temp: false,
};

export default connector(AdminMenu);
