import { Box, Button, ButtonBase, Grid, SvgIcon, Typography } from '@mui/material';
import UploadIcon from 'assets/icons/upload.svg';
import Loading from 'components/common/Loading';
import FormikAutosuggest from 'components/formik/Autosuggest';
import FormikChipInput from 'components/formik/ChipInput';
import FormikDropzoneBox from 'components/formik/DropzoneBox';
import Editor from 'components/formik/Editor';
import Language from 'components/formik/Language';
import Switch from 'components/formik/Switch';
import TextField from 'components/formik/TextField';
import TimeZone from 'components/formik/TimeZone';
import { Field, Form, useFormikContext } from 'formik';
import capitalize from 'lodash/capitalize';
import debounce from 'lodash/debounce';
import startCase from 'lodash/startCase';
import { array, bool, func, object, string } from 'prop-types';
import React, { Suspense, useEffect, useState } from 'react';
import { BACKEND_URL } from 'services/constants';
import companyShape from 'shapes/shortCompany';
import { I18n } from 'utils/i18n';
import removeAccents from 'utils/removeAccents';
import useFileUrl from 'utils/useFileUrl';

const EditorComponent = props => (
  <Suspense fallback={<Loading />}>
    <Editor {...props} />
  </Suspense>
);
const uploadPlaceholder = (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
    }}
  >
    <SvgIcon sx={{ mr: 1 }}>
      <UploadIcon />
    </SvgIcon>
    <Typography>{I18n.t('AcknowledgementCreate.Drag & drop file here or')}</Typography>
    &nbsp;
    <ButtonBase sx={{ color: 'primary.main' }}>
      {I18n.t('AcknowledgementCreate.Browse file')}
    </ButtonBase>
  </Box>
);
const ImagePreviewUpload = props => {
  const image = useFileUrl(props.form.values.onBoardingImage);

  let backgroundImage = 'none';
  if (props.field.name === 'logo') {
    backgroundImage = `linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4))`;
    if (image && props.field.value) {
      backgroundImage += `, url(${image})`;
    }
  }
  return (
    <Box
      sx={{
        borderRadius: 1.5,
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        backgroundColor: 'secondary.ultraLight',
        backgroundImage,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% auto',
        backgroundPosition: '0 100%',
        height: 80,
      }}
    >
      <FormikDropzoneBox
        sx={{
          height: 70,
          '& > div': {
            padding: 5,
          },
        }}
        accept="image/*"
        placeholder={uploadPlaceholder}
        {...props}
      />
    </Box>
  );
};

ImagePreviewUpload.propTypes = {
  form: object.isRequired,
  field: object.isRequired,
};

const FormField = ({ field, isLoading }) => {
  const { getFieldProps } = useFormikContext();
  const { value } = getFieldProps(field.name);
  return (
    <>
      <Grid item xs={2}>
        <Typography>{startCase(field.name)}</Typography>
      </Grid>
      <Grid item xs={10}>
        <Field
          disabled={isLoading && ['name', 'subDomain', 'logo', 'logoDark'].includes(field.name)}
          InputProps={{
            endAdornment: field.name === 'webSiteUrl' && isLoading ? <Loading size={20} /> : null,
          }}
          label={
            field.name === 'subDomain' && value ? `https://${value}.web.ommnio.com` : undefined
          }
          InputLabelProps={{ shrink: true }}
          name={field.name}
          component={field.component || TextField}
          {...(field.props || {})}
        />
      </Grid>
    </>
  );
};
FormField.propTypes = {
  field: object.isRequired,
  isLoading: bool.isRequired,
};

const fields = (templates, isNewCompany) => [
  { name: 'webSiteUrl' },
  { name: 'name' },
  { name: 'subDomain' },
  { name: 'contactEmail' },
  { name: 'dpoEmail' },
  { name: 'supportEmail' },
  {
    name: 'codeType',
    component: FormikAutosuggest,
    props: {
      label: I18n.t('CompanyForm.CodeType'),
      fullWidth: true,
      suggestions: [
        { label: I18n.t('CompanyForm.CodeTypes.Number'), value: 'number' },
        { label: I18n.t('CompanyForm.CodeTypes.Alphanumeric'), value: 'alphanumeric' },
        { label: I18n.t('CompanyForm.CodeTypes.SpanishSSN'), value: 'spanishSSN' },
        { label: I18n.t('CompanyForm.CodeTypes.String'), value: 'string' },
      ],
    },
  },
  { name: 'featureFlags', component: FormikChipInput, props: { blurBehavior: 'add-or-clear' } },
  { name: 'divisions', component: FormikChipInput, props: { blurBehavior: 'add-or-clear' } },
  {
    name: 'logoDark',
    component: ImagePreviewUpload,
  },
  {
    name: 'logo',
    component: ImagePreviewUpload,
  },
  {
    name: 'onBoardingImage',
    component: ImagePreviewUpload,
  },
  // Billing
  { name: 'businessName' },
  { name: 'billingContactName' },
  { name: 'billingContactSurname' },
  { name: 'address' },
  { name: 'city' },
  { name: 'zip' },
  { name: 'state' },
  { name: 'country' },
  { name: 'vatIDNumber' },
  { name: 'stripeCustomerId' },
  { name: 'stock.digitalSignatures' },
  // Subscriptoin
  {
    name: 'subscription.product.name',
    component: FormikAutosuggest,
    props: {
      isClearable: true,
      suggestions: [
        {
          label: '(None)',
          value: null,
        },
        {
          label: 'SME',
          value: 'SME',
        },
        {
          label: 'Big teams',
          value: 'Big teams',
        },
        {
          label: 'Enterprise',
          value: 'Enterprise',
        },
      ],
    },
  },
  {
    name: 'subscription.status',
    component: FormikAutosuggest,
    props: {
      isClearable: true,
      suggestions: [
        'active',
        'canceled',
        'incomplete',
        'incomplete_expired',
        'past_due',
        'trialing',
        'unpaid',
      ].map(value => ({
        label: value,
        value,
      })),
    },
  },
  // Configuration
  {
    name: 'language',
    component: Language,
  },
  {
    name: 'languages',
    component: Language,
    props: {
      isMulti: true,
    },
  },
  {
    name: 'timeZone',
    component: TimeZone,
  },
  {
    name: 'defaultMobileNotifications',
    component: FormikAutosuggest,
    props: {
      sx: { flexGrow: 1 },
      isClearable: true,
      suggestions: ['Always', 'Restricted', 'Never'].map(value => ({
        label: capitalize(value),
        value,
      })),
    },
  },
  { name: 'appName' },
  { name: 'appLogo' },
  {
    name: 'allowAnonymousEmployees',
    component: Switch,
  },
  {
    name: 'demo',
    component: Switch,
  },
  {
    name: 'template',
    component: Switch,
  },
  ...(isNewCompany
    ? [
        {
          name: 'fromTemplate',
          component: FormikAutosuggest,
          props: {
            isClearable: true,
            suggestions: templates.map(t => ({
              label: t.name,
              value: t._id,
            })),
          },
        },
      ]
    : []),
  // Legal
  {
    name: 'terms',
    component: EditorComponent,
  },
  {
    name: 'privacy',
    component: EditorComponent,
  },
];

const fetchCompanyInfo = url => {
  const request = new Request(`${BACKEND_URL}/api/v1/company-info?url=${encodeURIComponent(url)}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return fetch(request).then(response => response.json());
};

const fetchCompanyInfoDebounced = debounce(
  (company, values, touched, setFieldValue, setLoading) => {
    return new Promise(resolve => {
      if (company?.webSiteUrl !== values.webSiteUrl) {
        resolve(
          fetchCompanyInfo(values.webSiteUrl).then(info => {
            if (!touched.name || !values.name) {
              setFieldValue('name', info.companyName);
            }
            if (!touched.logo || !values.logo) {
              setFieldValue('logo', info.logo);
            }
            if (!touched.logoDark || !values.logoDark) {
              setFieldValue('logoDark', info.logo);
            }
            return info;
          }),
        );
      }
      resolve(null);
    })
      .catch(() => {})
      .then(() => {
        setLoading(false);
      });
  },
  1000,
);

export const CompanyForm = ({ company, templates, onClose, error, isLoading, isError }) => {
  const { values, touched, setFieldValue } = useFormikContext();
  const [isLoadingCompany, setLoadingCompany] = useState(false);
  useEffect(() => {
    if (company?.webSiteUrl !== values.webSiteUrl) {
      setLoadingCompany(true);
    }
    fetchCompanyInfoDebounced(company, values, touched, setFieldValue, setLoadingCompany);
  }, [values.webSiteUrl]);
  useEffect(() => {
    if ((!touched.subDomain || !values.subDomain) && values.name) {
      const subDomain = removeAccents(values.name).toLowerCase().replace(/\s/g, '-');
      setFieldValue('subDomain', subDomain);
    }
  }, [setFieldValue, touched.subDomain, values.name]);
  return (
    <Form
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          mt: 2,
          flex: '1 1 auto',
          overflowY: 'scroll',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Grid
          spacing={1}
          sx={{
            flex: '1 1 auto',
            display: 'flex',
            '& .MuiGrid-item': {
              display: 'flex',
              alignItems: 'center',
            },
          }}
          container
        >
          {company?._id && (
            <>
              <Grid item xs={2}>
                <Typography>{`${I18n.t('CompanyDrawer.Id')}`}</Typography>
              </Grid>
              <Grid item xs={10}>
                <Field name="_id" disabled component={TextField} />
              </Grid>
            </>
          )}
          {fields(templates, !values._id).map(field => (
            <FormField key={field.name} field={field} isLoading={isLoadingCompany} />
          ))}
        </Grid>
      </Box>
      <Box
        sx={{
          pt: 2,
          display: 'flex',
          gap: 1,
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        {isError && (
          <Typography sx={{ flexGrow: 1 }} color="error">
            {error?.data?.message || I18n.t('CompanyDrawer.Unexpected error')}
          </Typography>
        )}
        <Button
          onClick={onClose}
          variant="outlined"
          color="secondary"
          sx={{ color: 'primary.ultraDark' }}
        >
          {I18n.t('CompanyDrawer.Cancel')}
        </Button>
        <Button disabled={isLoading} type="submit" variant="contained" color="primary">
          {I18n.t('CompanyDrawer.Save')}
          {isLoading && <Loading sx={{ ml: 1 }} size={14} color="secondary" />}
        </Button>
      </Box>
    </Form>
  );
};

CompanyForm.propTypes = {
  company: companyShape.isRequired,
  onClose: func.isRequired,
  error: string.isRequired,
  isLoading: bool.isRequired,
  isError: bool.isRequired,
  templates: array.isRequired,
};
