import Config from 'services/api/Config';

export const LOAD_TIME_ZONES = 'LOAD_TIME_ZONES';
export const LOAD_TIME_ZONES_PENDING = 'LOAD_TIME_ZONES_PENDING';
export const LOAD_TIME_ZONES_FULFILLED = 'LOAD_TIME_ZONES_FULFILLED';
export const LOAD_TIME_ZONES_REJECTED = 'LOAD_TIME_ZONES_REJECTED';

const loadTimeZones = () => ({
  type: LOAD_TIME_ZONES,
  payload: Config.getTimeZones(),
});

export default { loadTimeZones };
