import Form from 'services/api/Form';

export const FIND_FORM = 'FIND_FORM';
export const FIND_FORM_PENDING = 'FIND_FORM_PENDING';
export const FIND_FORM_FULFILLED = 'FIND_FORM_FULFILLED';
export const FIND_FORM_REJECTED = 'FIND_FORM_REJECTED';

const find = formId => ({
  type: FIND_FORM,
  payload: Form.find(formId),
  meta: { formId },
});

export default { find };
