import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import analyticsDrawer from 'store/app/ui/drawers/analytics/action';
import formAnalytics from 'store/app/ui/drawers/formAnalytics/action';
import currentChannel from 'store/selectors/currentChannel';
import isCompanyAdmin from 'store/selectors/isCompanyAdmin';
import isTeamAdmin from 'store/selectors/isTeamAdmin';

const mapStateToProps = state => ({
  channel: currentChannel(state),
  isCompanyAdmin: isCompanyAdmin(state),
  isTeamAdmin: isTeamAdmin(state),
});

const mapDispatchToProps = dispatch => ({
  actions: {
    drawers: {
      analytics: bindActionCreators(analyticsDrawer, dispatch),
      formAnalytics: bindActionCreators(formAnalytics, dispatch),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
