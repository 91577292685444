/** @flow */
import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import isArray from 'lodash/isArray';
import React from 'react';
import isMac from 'utils/isMac';

const styles = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    gap: 3,
  },
  title: {
    fontWeight: 600,
  },
  shortcuts: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
  },
  shortcut: {
    display: 'inline-block',
    padding: '2px 4px',
    borderRadius: 1,
    backgroundColor: '#3C4E71',
    minWidth: 24,
    textAlign: 'center',
    py: 0.5,
    px: 0.75,
  },
};

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: '#27344E',
    color: '#fff',
    fontSize: 12,
    fontWeight: 500,
    borderRadius: 8,
    padding: '8px 12px',
  },
  arrow: {
    color: '#27344E',
  },
}));

type Props = {
  title: string;
  shortcut: string;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  arrow?: boolean;
  children: React.Node;
};

const ShortcutTooltip = ({ title, shortcut, placement, arrow, children, ...rest }: Props) => {
  const classes = useStyles();
  const shortcuts = isArray(shortcut) ? shortcut : [shortcut];
  return (
    <Tooltip
      classes={classes}
      title={
        <Box sx={styles.wrapper}>
          <Box sx={styles.title}>{title}</Box>
          <Box sx={styles.shortcuts}>
            {shortcuts.map(s => (
              <Box key={s} sx={styles.shortcut}>
                {isMac ? s.replace(/Ctrl/i, '⌘') : s}
              </Box>
            ))}
          </Box>
        </Box>
      }
      placement={placement}
      arrow={arrow}
      {...rest}
    >
      {children}
    </Tooltip>
  );
};

ShortcutTooltip.defaultProps = {
  placement: 'bottom',
  arrow: true,
};

export default ShortcutTooltip;
