import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import channels from 'store/app/entities/channels/action';
import { invalidateGroupsTags } from 'store/app/entities/GroupsSlice';
import alert from 'store/app/ui/Alert/action';
import channelDrawer from 'store/app/ui/drawers/channel/action';
import authEmployee from 'store/selectors/authEmployee';
import authUser from 'store/selectors/authUser';
import currentChannel from 'store/selectors/currentChannel';
import isCompanyAdmin from 'store/selectors/isCompanyAdmin';
import { populateAdminEmployees } from 'store/selectors/populateEmployees';

const mapStateToProps = state => {
  const employees = Object.values(populateAdminEmployees(state));
  const newVar = {
    isEdit: state.drawers.channel.type === 'edit',
    authEmployee: authEmployee(state),
    isCompanyAdmin: isCompanyAdmin(state),
    isSuperAdmin: authUser(state).isSuperAdmin,
    channel: currentChannel(state),
    employees: employees.filter(e => e._id !== authEmployee(state)?._id),
  };
  return newVar;
};

const mapDispatchToProps = dispatch => ({
  actions: {
    invalidateGroupsTags: (...args) => dispatch(invalidateGroupsTags(...args)),
    channels: bindActionCreators(channels, dispatch),
    alert: bindActionCreators(alert, dispatch),
    drawers: {
      channel: bindActionCreators(channelDrawer, dispatch),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
