import { Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import TagsField from 'components/formik/TagsField';
import { Field } from 'formik';
import { array, object } from 'prop-types';
import React from 'react';
import { I18n } from 'utils/i18n';

const styles = {
  root: {
    paddingTop: 20,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  field: {
    padding: 5,
  },
};

const AdditionalInfo = ({ classes, tags, createTag }) => (
  <div className={classes.root}>
    <Typography variant="caption">{I18n.t('EmployeeDrawer.ADDITIONAL INFO')}</Typography>

    <Grid container>
      <Grid item xs={2} className={classes.label}>
        <Typography>{I18n.t('EmployeeDrawer.Tags')}</Typography>
      </Grid>
      <Grid item xs={10}>
        <Field name="tags" tags={tags} createTag={createTag} component={TagsField} />
      </Grid>
    </Grid>
  </div>
);

AdditionalInfo.propTypes = {
  classes: object.isRequired,
  tags: array,
};

AdditionalInfo.defaultProps = {
  tags: [],
};

export default withStyles(styles)(AdditionalInfo);
