import { lighten } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import looksLikeDate from 'components/@home/drawers/HrBotDrawer/XMLViewer/looksLikeDate';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  value: ({ link }) =>
    link
      ? {
          borderRadius: 4,
          padding: 2,
          cursor: 'pointer',
          backgroundColor: '#eaeaac',
          '&:hover, &:focus': {
            backgroundColor: lighten('#ffffbb', 0.02),
          },
        }
      : {
          borderRadius: 4,
          padding: 2,
          '&:hover, &:focus': {
            backgroundColor: 'inherit',
          },
        },
}));

const Attributes = ({ attributes, theme, onClick, xpath }) => {
  const attributeList = [];
  const overflow = theme.overflowBreak ? { overflowWrap: 'break-word', whiteSpace: 'normal' } : {};
  const link = typeof onClick === 'function';
  const classes = useStyles({ link });
  for (const key in attributes) {
    const value = attributes[key]?.trim();
    const dateLike = looksLikeDate(value);
    attributeList.push(
      <span key={`attr-${key}[${attributes[key]}]`}>
        <span style={{ color: theme.attributeKeyColor }}>{` ${key}`}</span>
        <span style={{ color: theme.separatorColor }}>=</span>
        <span style={{ color: theme.attributeValueColor }}>
          <span>&quot;</span>
          {dateLike ? (
            attributes[key].split(/[-/]/).map((part, index) => (
              <span
                className={classes.value}
                onClick={() =>
                  onClick(
                    `${xpath}/tokenize(text(), '[-/]' )[position() = ${index + 1}]`,
                    attributes[key],
                  )
                }
              >
                {`${index > 0 ? '-' : ''}${part}`}
              </span>
            ))
          ) : (
            <span
              className={classes.value}
              onClick={() =>
                typeof onClick === 'function' && onClick(`${xpath}/@${key}`, attributes[key])
              }
            >
              ${attributes[key]}
            </span>
          )}
          <span>&quot;</span>
        </span>
      </span>,
    );
  }

  return <span style={overflow}>{attributeList}</span>;
};

Attributes.propTypes = {
  attributes: PropTypes.object,
  theme: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  xpath: PropTypes.string.isRequired,
};

Attributes.defaultProps = {
  attributes: null,
  onClick: null,
};

export default Attributes;
