import { arrayOf, bool, number, object, shape, string } from 'prop-types';
import acknowledgesShape from './acknowledge';
import attachmentsShape from './attachment';

export default shape({
  _id: string.isRequired,
  text: string.isRequired,
  title: string,
  acknowledges: arrayOf(acknowledgesShape),
  attachments: arrayOf(attachmentsShape),
  channel_id: string.isRequired,
  company_id: string.isRequired,
  previews: object,
  createdAt: string.isRequired,
  employee_id: string,
  user_id: string,
  isDeletedBy: arrayOf(string),
  isDraft: bool.isRequired,
  isEdited: bool.isRequired,
  softDeletedAt: number,
  isMandatory: bool.isRequired,
  isRead: bool.isRequired,
  isReadByMe: bool.isRequired,
  links: arrayOf(string),
  temp: bool.isRequired,
  dateTime: string,
  isMine: bool,
  seen: bool.isRequired,
  senderWasDeleted: bool.isRequired,
  sentAsChannel: bool.isRequired,
});
