/**
 * @flow
 */
import { InputAdornment, SvgIcon, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from 'assets/icons/search.svg';
import React, { memo } from 'react';
import { I18n } from 'utils/i18n';

const useStyles = makeStyles(theme => ({
  root: {
    border: 0,
    paddingRight: 0,
  },
  notchedOutline: {
    borderColor: theme.palette.secondary.light,
  },
  searchIcon: {
    paddingTop: 3,
    fontSize: 20,
    fontWeight: 'normal',
    color: theme.palette.secondary.main,
  },
  formControl: {
    paddingLeft: 0,
    borderColor: theme.palette.secondary.light,
    '&::placeholder': {
      color: theme.palette.secondary.main,
    },
  },
  input: {
    height: 16,
    '&::-webkit-search-cancel-button': {
      '-webkit-appearance': 'none',
      width: '1.2rem',
      height: '1.2rem',
      backgroundSize: 'cover',
      backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='${encodeURIComponent(
        theme.palette.secondary.main,
      )}' viewBox='0 0 512 512'%3E%3Cpath d='M400 145.49L366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49z'/%3E%3C/svg%3E")`,
    },
  },
}));

/* eslint-disable react/jsx-no-duplicate-props */
const SearchField = ({
  inputProps,
  InputProps,
  ...props
}: {
  inputProps?: Object;
  InputProps?: Object;
}) => {
  const classes = useStyles();
  return (
    <TextField
      autoComplete={false}
      name="search"
      type="search"
      fullWidth
      inputProps={{
        results: 5,
        ...(inputProps || {}),
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SvgIcon className={classes.searchIcon}>
              <SearchIcon />
            </SvgIcon>
          </InputAdornment>
        ),
        ...InputProps,
        classes: {
          formControl: classes.formControl,
          root: classes.root,
          input: classes.input,
          notchedOutline: classes.notchedOutline,
          ...(InputProps?.classes || {}),
        },
      }}
      placeholder={I18n.t('SearchField.search')}
      {...props}
    />
  );
};

SearchField.defaultProps = {
  inputProps: {},
  InputProps: {},
};

export default memo(SearchField);
