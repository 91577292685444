import { Button, SvgIcon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 0,
    minHeight: 0,
    padding: '6px 7px',
  },
  arrowIcon: {
    fontSize: 24,
    color: theme.palette.primary.dark,
  },
}));

function BackButton() {
  const classes = useStyles();
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <Button onClick={goBack} className={classes.root} color="secondary" variant="outlined">
      <SvgIcon className={classes.arrowIcon}>
        <ArrowLeftIcon />
      </SvgIcon>
    </Button>
  );
}

export default BackButton;
