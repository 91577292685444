import GoogleAnalytics, { trackEvent, trackPageView } from '@redux-beacon/google-analytics-gtag';
import { LOCATION_CHANGE } from 'connected-react-router';
import { createMetaReducer, createMiddleware } from 'redux-beacon';

import {
  DESTROY_MESSAGE_PENDING,
  SEND_MESSAGE_FULFILLED,
  UPDATE_MESSAGE_FULFILLED,
} from 'store/app/entities/messages/action';
import { OPEN_CHANNEL } from 'store/app/ui/ChannelsPanel/action';
import { CLOSE_CHANNEL_DRAWER, OPEN_CHANNEL_DRAWER } from 'store/app/ui/drawers/channel/action';
import { CLOSE_EMPLOYEE_DRAWER, OPEN_EMPLOYEE_DRAWER } from 'store/app/ui/drawers/employee/action';
import {
  CLOSE_EMPLOYEE_LIST_DRAWER,
  OPEN_EMPLOYEE_LIST_DRAWER,
} from 'store/app/ui/drawers/employeeList/action';
import {
  CHANGE_MEDIA_DRAWER,
  CLOSE_MEDIA_DRAWER,
  OPEN_MEDIA_DRAWER,
} from 'store/app/ui/drawers/media/action';
import { CLOSE_SETTINGS_DRAWER, OPEN_SETTINGS_DRAWER } from 'store/app/ui/drawers/settings/action';

import authCompany from 'store/selectors/authCompany';
import currentChannel from 'store/selectors/currentChannel';

import { getMessageType } from 'utils/getMessageType';
import reduxBeaconFirebaseTarget from 'utils/reduxBeaconFirebaseTarget';

const ga = GoogleAnalytics();

const dimensions = {
  company: 'dimension1',
  channel: 'dimension2',
  messageType: 'dimension3',
};

const chooseMessageType = action => {
  if (!action || !action.payload) {
    return null;
  }
  switch (action.type) {
    case SEND_MESSAGE_FULFILLED:
    case UPDATE_MESSAGE_FULFILLED:
    case DESTROY_MESSAGE_PENDING:
      return getMessageType(action.payload);
    default:
      return null;
  }
};

const getFieldsObject = (action, state) => ({
  userId: state.auth.user_id ? state.auth.user_id : null,
  [dimensions.company]: authCompany(state)?._id,
  [dimensions.channel]: currentChannel(state)?._id,
  [dimensions.messageType]: chooseMessageType(action),
});

const eventsMapper = actionIn => {
  switch (actionIn.type) {
    case LOCATION_CHANGE:
      // Channel page view will be tracked by the OPEN_CHANNEL action
      if (actionIn.payload.location.pathname !== '/home/messages') {
        return trackPageView((action, prevState, nextState) => ({
          path: action.payload.location.pathname,
          fieldsObject: getFieldsObject(action, nextState),
        }));
      }
      return [];

    case OPEN_CHANNEL:
      return trackPageView((action, prevState, nextState) => ({
        page: '/home/messages',
        fieldsObject: getFieldsObject(action, nextState),
      }));
    case SEND_MESSAGE_FULFILLED:
      return trackEvent((action, prevState, nextState) => ({
        category: 'messages',
        action: 'create_message',
        label: '',
        value: action.payload.channel_id,
        fieldsObject: getFieldsObject(action, nextState),
      }));
    case UPDATE_MESSAGE_FULFILLED:
      return trackEvent((action, prevState, nextState) => ({
        category: 'messages',
        action: 'update_message',
        label: '',
        value: action.payload.channel_id,
        fieldsObject: getFieldsObject(action, nextState),
      }));
    // no idea why, but we don't receive a DESTROY_MESSAGE_FULFILLED event so we use the PENDING
    case DESTROY_MESSAGE_PENDING:
      return trackEvent((action, prevState, nextState) => ({
        category: 'messages',
        action: 'delete_message',
        label: '',
        fieldsObject: getFieldsObject(action, nextState),
      }));
    case OPEN_CHANNEL_DRAWER:
      return trackPageView((action, prevState, nextState) => ({
        page: '/home/messages/edit-channel',
        fieldsObject: getFieldsObject(action, nextState),
      }));
    case CLOSE_CHANNEL_DRAWER:
      return trackPageView((action, prevState, nextState) => ({
        page: '/home/messages',
        fieldsObject: getFieldsObject(action, nextState),
      }));
    case OPEN_MEDIA_DRAWER:
      return trackPageView((action, prevState, nextState) => ({
        page: `/home/messages/media-drawer-${action.payload}`,
        fieldsObject: getFieldsObject(action, nextState),
      }));
    case CHANGE_MEDIA_DRAWER:
      return trackPageView((action, prevState, nextState) => ({
        page: `/home/messages/media-drawer-${action.payload}`,
        fieldsObject: getFieldsObject(action, nextState),
      }));
    case CLOSE_MEDIA_DRAWER:
      return trackPageView((action, prevState, nextState) => ({
        page: '/home/messages',
        fieldsObject: getFieldsObject(action, nextState),
      }));
    case OPEN_EMPLOYEE_LIST_DRAWER:
      return trackPageView((action, prevState, nextState) => ({
        page: '/home/messages/members-drawer',
        fieldsObject: getFieldsObject(action, nextState),
      }));
    case CLOSE_EMPLOYEE_LIST_DRAWER:
      return trackPageView((action, prevState, nextState) => ({
        page: '/home/messages',
        fieldsObject: getFieldsObject(action, nextState),
      }));
    case OPEN_EMPLOYEE_DRAWER:
      return trackPageView((action, prevState, nextState) => ({
        page: '/home/messages/employee-drawer',
        fieldsObject: getFieldsObject(action, nextState),
      }));
    case CLOSE_EMPLOYEE_DRAWER:
      return trackPageView((action, prevState, nextState) => ({
        page: '/home/messages',
        fieldsObject: getFieldsObject(action, nextState),
      }));
    case OPEN_SETTINGS_DRAWER:
      return trackPageView((action, prevState, nextState) => ({
        page: '/home/messages/settings',
        fieldsObject: getFieldsObject(action, nextState),
      }));
    case CLOSE_SETTINGS_DRAWER:
      return trackPageView((action, prevState, nextState) => ({
        page: '/home/messages',
        fieldsObject: getFieldsObject(action, nextState),
      }));
    default:
      return [];
  }
};

export const gaBeaconMiddleware = createMiddleware(eventsMapper, ga);
export const gaBeaconReducer = createMetaReducer(eventsMapper, ga);

export const firebaseBeaconMiddleware = createMiddleware(eventsMapper, reduxBeaconFirebaseTarget);
export const firebaseBeaconReducer = createMetaReducer(eventsMapper, reduxBeaconFirebaseTarget);
