/**
 * @flow
 */
import makeStyles from '@mui/styles/makeStyles';
import { Field } from 'formik';
import debounce from 'lodash/debounce';
import React from 'react';
import { I18n } from 'utils/i18n';
import focusToEnd from 'utils/focusToEnd';
import keyIs, { BACKSPACE, DOWN, ENTER, UP } from 'utils/keyIs';
import useFocusRef from 'utils/useFocusRef';

type Props = {
  form: {
    getFieldMeta: Function;
  };
  remove: Function;
  insert: Function;
  name: string;
};

const useStyles = makeStyles(theme => ({
  optionLabel: {
    color: theme.palette.text.primary,
    background: 'transparent',
    border: 'none',
    outline: 'none',
    width: '100%',
    padding: 0,
    lineHeight: '22px',
  },
  options: {
    listStyleType: 'none',
    padding: '0 0 0 10px',
    margin: 0,
  },
  option: {
    display: 'list-item',
    position: 'relative',
    '&:before': {
      content: `'-'`,
      position: 'absolute',
      marginLeft: -10,
    },
  },
}));

const QuestionOptions = ({ form: { getFieldMeta }, name, remove, insert }: Props) => {
  const { value } = getFieldMeta(name);
  const focusRef = useFocusRef();
  const classes = useStyles();

  const addOption = debounce(
    (index = value.length) => insert(index + 1, { _id: Math.random().toString(), label: '' }),
    200,
    {
      leading: true,
      trailing: false,
    },
  );

  const nextOnEnter = e => {
    const index = +e.target.dataset.index;
    if (e.target.value !== '' && index === value.length - 1) {
      addOption();
    }
    if (keyIs(e, ENTER) && index < value.length - 1) {
      const input = e.target.parentNode.nextSibling.querySelector('input');
      if (input && input.value !== '') {
        addOption(index);
      } else {
        focusToEnd(input);
      }
    } else if (keyIs(e, DOWN) && index < value.length - 1) {
      const input = e.target.parentNode.nextSibling.querySelector('input');
      focusToEnd(input);
    } else if (keyIs(e, UP)) {
      const input =
        index > 0
          ? e.target.parentNode.previousSibling.querySelector('input')
          : e.target.closest('div').querySelector('input');
      focusToEnd(input);
    }
  };

  const onKeyDown = e => {
    const index = +e.target.dataset.index;
    if (keyIs(e, UP, DOWN, ENTER)) {
      e.preventDefault();
    } else if (keyIs(e, BACKSPACE) && e.target.value === '' && index < value.length - 1) {
      e.preventDefault();
      const input =
        index > 0
          ? e.target.parentNode.previousSibling.querySelector('input')
          : e.target.parentNode.nextSibling.querySelector('input');
      focusToEnd(input);
      remove(index);
    }
  };

  return (
    <ul className={classes.options}>
      {value.map((option, index) => {
        return (
          <li className={classes.option} key={option._id}>
            <Field
              name={`${name}.${index}.label`}
              placeholder={I18n.t('FormDrawer.QuestionField.Option.placeholder')}
              className={classes.optionLabel}
              onKeyUp={nextOnEnter}
              onKeyDown={onKeyDown}
              innerRef={index < value.length - 1 ? focusRef : () => null}
              data-index={index}
            />
          </li>
        );
      })}
    </ul>
  );
};

export default QuestionOptions;
