import React from 'react';
import { object } from 'prop-types';
import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
  separator: {
    margin: '0 6px 0 20px',
    textIndent: '-999999px',
    borderRight: `1px solid ${theme.palette.secondary.light}`,
  },
});

const Separator = ({ classes }) => <div className={classes.separator}>|</div>;

Separator.propTypes = {
  classes: object.isRequired,
};

export default withStyles(styles)(Separator);
