import difference from 'lodash/difference';
import { createSelector } from 'reselect';
import getMoment from 'utils/getMoment';
import populateChannels from './populateChannels';

const filteredChannels = (channels, filtered) => {
  const channelsFiltered = difference(Object.keys(channels), filtered).map(_id => channels[_id]);
  const withoutDeletedChannels = channelsFiltered.filter(c => !c.isDeleted);

  return Object.values(withoutDeletedChannels).sort((a, b) => {
    if (!a.pinned !== !b.pinned) return !!b.pinned - !!a.pinned;
    if (!b.lastMessage?.createdAt) return -1;
    if (!a.lastMessage?.createdAt) return 1;
    return (
      getMoment(b.lastMessage?.createdAt).valueOf() - getMoment(a.lastMessage?.createdAt).valueOf()
    );
  });
};
export default createSelector(
  state => populateChannels(state),
  state => state.elements.ChannelsPanel.filtered,
  filteredChannels,
);
