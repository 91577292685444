import { TableHead, TableRow } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { bool, func, object, shape, string } from 'prop-types';
import React, { Component } from 'react';
import TitleCells from './TitleCells';

const styles = theme => ({
  root: {
    width: '100%',
    borderTop: `1px solid ${theme.palette.divider}`,
  },

  head: {
    height: 48,
  },
});

class EnhancedTableHead extends Component {
  render() {
    const { classes, order, onSort, showName } = this.props;

    return (
      <TableHead className={classes.root}>
        <TableRow classes={{ head: classes.head }}>
          <TitleCells showName={showName} order={order} onSort={onSort} />
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  classes: object.isRequired,
  order: shape({ by: string, isReverse: bool }).isRequired,
  onSort: func.isRequired,
  showName: bool.isRequired,
};

export default withStyles(styles)(EnhancedTableHead);
