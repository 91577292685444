import { makeStyles } from '@mui/styles';
import img from 'components/@auth/images/img.jpg';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { BACKEND_URL } from 'services/constants';
import getSubdomain from 'utils/getSubdomain';
import LoginNoInvitationLayout from './@login-no-invitation/LoginNoInvitationLayout';
import LoginLayout from './@login/LoginLayout';
import Logo from './Logo';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  background: {
    flexGrow: 1,
    backgroundImage: ({ onBoardingImage }) => `url(${onBoardingImage})`,
    backgroundSize: 'cover',
  },
  scene: {
    width: 500,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 30px',
    maxHeight: '100vh',
  },
}));

const isLogin = pathname => pathname.toString() === '/auth/login';

const AuthLayout = () => {
  const token = useSelector(state => state.auth?.token);
  const subdomain = getSubdomain();
  const onBoardingImage = subdomain ? `${BACKEND_URL}/api/v1/company/${subdomain}/logo` : img;
  const classes = useStyles({ onBoardingImage });
  const location = useLocation();
  const navigate = useNavigate();
  const [isBackVisible, setBackVisible] = useState(!isLogin(location.pathname));

  useEffect(() => {
    if (token) {
      navigate('/home/messages');
    }
  }, [navigate, token]);

  useEffect(() => {
    setBackVisible(!isLogin(location.pathname));
  }, [location.pathname]);

  return (
    <section className={classes.root}>
      <div className={classes.scene}>
        <Logo isBackVisible={isBackVisible} />
        <Outlet />
      </div>
      <div className={classes.background} />
    </section>
  );
};

export default AuthLayout;
