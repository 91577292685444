import { Drawer, Tab, Tabs, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import TabResponses from 'components/@home/drawers/FormAnalyticsDrawer/TabResponses';
import TabSummary from 'components/@home/drawers/FormAnalyticsDrawer/TabSummary';
import Loading from 'components/common/Loading';
import isEmpty from 'lodash/isEmpty';
import { bool, object, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { I18n } from 'utils/i18n';
import Form from 'services/api/Form';
import CloseButton from '../CloseButton';

import connector from './connector';

const tabs = [
  ['Summary', TabSummary],
  ['Responses', TabResponses],
];

const styles = theme => ({
  root: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    width: '100%',
    maxWidth: 'calc(100vw - 40px)',
    [theme.breakpoints.up('md')]: {
      width: 1100,
    },
  },
  container: {
    display: 'flex',
    flex: 1,
    padding: 20,
    paddingBottom: 0,
    flexDirection: 'column',
    height: '100vh',
  },
  closeButtonContainer: {
    paddingTop: 20,
    paddingLeft: 20,
  },
  indicator: {
    backgroundColor: theme.palette.black,
  },
  tabs: {
    marginTop: 20,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
});

const FormAnalytics = ({
  classes,
  formId,
  form,
  isOpen,
  actions,
  channel,
  message,
  authEmployee,
  otherEmployee,
}) => {
  const [summary, setSummary] = useState(null);
  const [status, setStatus] = useState(null);
  const [tab, setTab] = useState(0);
  const [question, setQuestion] = useState(form?.questions?.[0]);
  const [answer, setAnswer] = useState('All');

  useEffect(() => {
    if (isOpen && formId) {
      if (isEmpty(channel.users) && !channel.isDirect && !status) {
        actions.channels.find(channel._id);
      }
      if (!form.loaded && !form.loading) {
        actions.forms.find(formId);
      }
      if (form.loaded && !question) {
        setQuestion(form.questions[0]);
      }
      if (!status) {
        setStatus('loading');
        Form.summary(formId).then(data => {
          setSummary(data);
          setStatus(null);
        });
      }
    } else if (!isOpen) {
      setQuestion(null);
      setAnswer('All');
    }
  }, [isOpen, form, actions]);

  const handleClose = () => {
    setStatus(null);
    actions.drawers.formAnalytics.close();
    setTab(0);
  };

  const handleChange = (event, newTab) => {
    setTab(newTab);
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose}>
      <div className={classes.root}>
        <div className={classes.closeButtonContainer}>
          <CloseButton onClick={handleClose} />
        </div>
        <div className={classes.container}>
          {status === 'loading' || form?.loading ? (
            <Loading />
          ) : (
            <>
              <Typography variant="h5">{form.title}</Typography>
              <Tabs
                value={tab}
                onChange={handleChange}
                variant="fullWidth"
                classes={{ root: classes.tabs, indicator: classes.indicator }}
              >
                {tabs.map(([key]) => (
                  <Tab key={key} label={I18n.t(`FormAnalyticsDrawer.${key}`)} />
                ))}
              </Tabs>
              {tabs.map(([key, TabComponent], index) => (
                <TabComponent
                  key={key}
                  form={form}
                  summary={summary}
                  channel={channel}
                  otherEmployee={otherEmployee}
                  authEmployee={authEmployee}
                  message={message}
                  tab={tab}
                  setTab={setTab}
                  index={index}
                  isOpen={isOpen}
                  question={question}
                  setQuestion={setQuestion}
                  answer={answer}
                  setAnswer={setAnswer}
                />
              ))}
            </>
          )}
        </div>
      </div>
    </Drawer>
  );
};

FormAnalytics.propTypes = {
  classes: object.isRequired,
  form: object.isRequired,
  formId: string,
  actions: object.isRequired,
  isOpen: bool.isRequired,
  channel: object.isRequired,
  message: object,
  authEmployee: object.isRequired,
  otherEmployee: object.isRequired,
};

FormAnalytics.defaultProps = {
  formId: null,
  message: null,
};

export default withStyles(styles)(connector(FormAnalytics));
