/* eslint-disable react/no-danger */
import React from 'react';
import { object, node } from 'prop-types';
import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
  root: {
    color: theme.palette.text.primary,
    margin: '2em 0 1em 0',
    maxHeight: '80vh',
    overflow: 'auto',
    '& a': {
      color: theme.palette.link.main,
      textDecoration: 'underline',
      '&:visited': {
        color: theme.palette.link.main,
      },
      '&:active': {
        color: theme.palette.link.hover,
      },
    },
  },
});

const LegalDoc = ({ classes, children }) => (
  <div className={classes.root} dangerouslySetInnerHTML={{ __html: children }} />
);
LegalDoc.propTypes = {
  classes: object.isRequired,
  children: node,
};

LegalDoc.defaultProps = {
  children: null,
};

export default withStyles(styles)(LegalDoc);
