import ChatFlowPage from 'services/api/ChatFlowPage';

export const FIND_CHAT_FLOW_PAGE = 'FIND_CHAT_FLOW_PAGE';
export const FIND_CHAT_FLOW_PAGE_PENDING = 'FIND_CHAT_FLOW_PAGE_PENDING';
export const FIND_CHAT_FLOW_PAGE_FULFILLED = 'FIND_CHAT_FLOW_PAGE_FULFILLED';

export const LOAD_CHAT_FLOW_PAGES = 'LOAD_CHAT_FLOW_PAGES';
export const LOAD_CHAT_FLOW_PAGES_PENDING = 'LOAD_CHAT_FLOW_PAGES_PENDING';
export const LOAD_CHAT_FLOW_PAGES_FULFILLED = 'LOAD_CHAT_FLOW_PAGES_FULFILLED';

export const CREATE_CHAT_FLOW_PAGE = 'CREATE_CHAT_FLOW_PAGE';
export const CREATE_CHAT_FLOW_PAGE_PENDING = 'CREATE_CHAT_FLOW_PAGE_PENDING';
export const CREATE_CHAT_FLOW_PAGE_FULFILLED = 'CREATE_CHAT_FLOW_PAGE_FULFILLED';

export const UPDATE_CHAT_FLOW_PAGE = 'UPDATE_CHAT_FLOW_PAGE';
export const UPDATE_CHAT_FLOW_PAGE_PENDING = 'UPDATE_CHAT_FLOW_PAGE_PENDING';
export const UPDATE_CHAT_FLOW_PAGE_FULFILLED = 'UPDATE_CHAT_FLOW_PAGE_FULFILLED';

export const DESTROY_CHAT_FLOW_PAGE = 'DESTROY_CHAT_FLOW_PAGE';
export const DESTROY_CHAT_FLOW_PAGE_PENDING = 'DESTROY_CHAT_FLOW_PAGE_PENDING';
export const DESTROY_CHAT_FLOW_PAGE_FULFILLED = 'DESTROY_CHAT_FLOW_PAGE_FULFILLED';

export const REMOVE_ROUTE_CHAT_FLOW_PAGE = 'REMOVE_ROUTE_CHAT_FLOW_PAGE';
export const REMOVE_ROUTE_CHAT_FLOW_PAGE_PENDING = 'REMOVE_ROUTE_CHAT_FLOW_PAGE_PENDING';
export const REMOVE_ROUTE_CHAT_FLOW_PAGE_FULFILLED = 'REMOVE_ROUTE_CHAT_FLOW_PAGE_FULFILLED';

const load = flow => ({
  type: LOAD_CHAT_FLOW_PAGES,
  payload: ChatFlowPage.list(flow),
  meta: { chatFlowId: flow },
});

const create = (flow, form) => ({
  type: CREATE_CHAT_FLOW_PAGE,
  payload: ChatFlowPage.create(flow, form),
  meta: { chatFlowId: flow },
});

const update = (flow, id, form) => ({
  type: UPDATE_CHAT_FLOW_PAGE,
  payload: ChatFlowPage.update(flow, id, form),
  meta: { chatFlowId: flow, pageId: id },
});

const find = (flow, id) => ({
  type: FIND_CHAT_FLOW_PAGE,
  payload: ChatFlowPage.find(flow, id),
  meta: { chatFlowId: flow, pageId: id },
});

const destroy = (flow, id) => ({
  type: DESTROY_CHAT_FLOW_PAGE,
  payload: ChatFlowPage.destroy(flow, id),
  meta: { chatFlowId: flow, pageId: id },
});

const removeRoute = (flow, id, routeId) => ({
  type: REMOVE_ROUTE_CHAT_FLOW_PAGE,
  payload: ChatFlowPage.removeRoute(flow, id, routeId),
  meta: { chatFlowId: flow, pageId: id },
});

export default {
  create,
  destroy,
  find,
  load,
  removeRoute,
  update,
};
