import { OPEN_DOCUMENT_MODAL, CLOSE_DOCUMENT_MODAL } from './action';

const initialState = {
  isOpen: false,
  src: null,
};

const createModal = (state = initialState, { type, payload }) => {
  switch (type) {
    case OPEN_DOCUMENT_MODAL:
      return {
        ...state,
        isOpen: true,
        ...payload,
      };

    case CLOSE_DOCUMENT_MODAL:
      return {
        ...state,
        isOpen: false,
      };

    default:
      return state;
  }
};

export default createModal;
