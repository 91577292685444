import { combineReducers } from 'redux';
import acknowledgment from './acknowledgment';
import picture from './picture/reducer';
import video from './video/reducer';
import document from './document/reducer';

export default combineReducers({
  acknowledgment,
  picture,
  video,
  document,
});
