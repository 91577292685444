/* eslint-disable implicit-arrow-linebreak,react/jsx-wrap-multilines */
import { Button, Card, CardContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import img from 'components/@auth/images/img.jpg';
import React from 'react';

import { I18n } from 'utils/i18n';
import { Link } from 'react-router-dom';
import { BACKEND_URL } from 'services/constants';
import getSubdomain from 'utils/getSubdomain';

const useStyles = makeStyles({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: ({ onBoardingImage }) => `url(${onBoardingImage})`,
    backgroundSize: 'cover',
  },
});

const IndexScene = () => {
  const subdomain = getSubdomain();

  const onBoardingImage = subdomain ? `${BACKEND_URL}/api/v1/company/${subdomain}/logo` : img;

  const classes = useStyles({ onBoardingImage });

  return (
    <div className={classes.root}>
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h5">
            {I18n.t('Index.Welcome_Message')}
          </Typography>
          <Link to="/auth/login">
            <Button variant="contained" fullWidth color="primary">
              {I18n.t('Index.Go_To')}
            </Button>
          </Link>
        </CardContent>
      </Card>
    </div>
  );
};

export default IndexScene;
