export const SHOW_DIALOG = 'SHOW_DIALOG';
export const HIDE_DIALOG = 'HIDE_DIALOG';

const show = message => ({
  type: SHOW_DIALOG,
  payload: message,
});

const hide = () => ({
  type: HIDE_DIALOG,
});

export default { show, hide };
