export const OPEN_TIME_TRACKING_DRAWER = 'OPEN_TIME_TRACKING_DRAWER';
export const CLOSE_TIME_TRACKING_DRAWER = 'CLOSE_TIME_TRACKING_DRAWER';

const open = data => ({
  type: OPEN_TIME_TRACKING_DRAWER,
  payload: data,
});

const close = () => ({
  type: CLOSE_TIME_TRACKING_DRAWER,
});

export default { open, close };
