import { SvgIcon } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import PlayRoundedIcon from 'assets/icons/play-rounded.svg';
import AlertCircleIcon from 'mdi-react/AlertCircleIcon';
import { bool, func, object } from 'prop-types';
import React from 'react';

const styles = theme => ({
  root: {},
  errorContainer: {
    width: 37,
    height: 37,
    marginRight: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorIcon: {
    color: theme.palette.error.main,
  },
  playContainer: {
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.9,
    color: theme.palette.white,
  },
  playIcon: {
    fontSize: 40,
  },
});

const PlayButton = ({ classes, loaded, onClick }) => {
  if (!loaded) {
    return (
      <div className={classes.errorContainer}>
        <AlertCircleIcon className={classes.errorIcon} />
      </div>
    );
  }

  return (
    <div className={classes.playContainer}>
      <SvgIcon className={classes.playIcon}>
        <PlayRoundedIcon />
      </SvgIcon>
    </div>
  );
};

PlayButton.propTypes = {
  classes: object.isRequired,
  loaded: bool.isRequired,
  onClick: func.isRequired,
};

export default withStyles(styles)(PlayButton);
