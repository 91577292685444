import App from 'services/api/App';
import authCompany from 'store/selectors/authCompany';

export const FIND_APP = 'FIND_APP';
export const FIND_APP_PENDING = 'FIND_APP_PENDING';
export const FIND_APP_FULFILLED = 'FIND_APP_FULFILLED';

export const LOAD_APPS = 'LOAD_APPS';
export const LOAD_APPS_PENDING = 'LOAD_APPS_PENDING';
export const LOAD_APPS_FULFILLED = 'LOAD_APPS_FULFILLED';

export const CREATE_APP = 'CREATE_APP';
export const CREATE_APP_PENDING = 'CREATE_APP_PENDING';
export const CREATE_APP_FULFILLED = 'CREATE_APP_FULFILLED';

export const UPDATE_APP = 'UPDATE_APP';
export const UPDATE_APP_PENDING = 'UPDATE_APP_PENDING';
export const UPDATE_APP_FULFILLED = 'UPDATE_APP_FULFILLED';

export const DESTROY_APP = 'DESTROY_APP';
export const DESTROY_APP_PENDING = 'DESTROY_APP_PENDING';
export const DESTROY_APP_FULFILLED = 'DESTROY_APP_FULFILLED';

export const INSTALL_APP = 'INSTALL_APP';
export const INSTALL_APP_PENDING = 'INSTALL_APP_PENDING';
export const INSTALL_APP_FULFILLED = 'INSTALL_APP_FULFILLED';

export const ADD_APP_EMPLOYEE = 'ADD_APP_EMPLOYEE';
export const REMOVE_APP_EMPLOYEE = 'REMOVE_APP_EMPLOYEE';

export const REGENERATE_SECRET_APP = 'REGENERATE_SECRET_APP';
export const REGENERATE_SECRET_APP_PENDING = 'REGENERATE_SECRET_APP_PENDING';
export const REGENERATE_SECRET_APP_FULFILLED = 'REGENERATE_SECRET_APP_FULFILLED';

export const UPLOAD_FILE_APP = 'UPLOAD_FILE_APP';
export const UPLOAD_FILE_APP_PENDING = 'UPLOAD_FILE_APP_PENDING';
export const UPLOAD_FILE_APP_FULFILLED = 'UPLOAD_FILE_APP_FULFILLED';

export const GET_GROUPS_APP = 'GET_GROUPS_APP';
export const GET_GROUPS_APP_PENDING = 'GET_GROUPS_APP_PENDING';
export const GET_GROUPS_APP_FULFILLED = 'GET_GROUPS_APP_FULFILLED';

const load = (page, perPage) => ({
  type: LOAD_APPS,
  payload: App.list(page, perPage),
});

const create = form => ({
  type: CREATE_APP,
  payload: App.create(form),
});

const update = (id, form) => ({
  type: UPDATE_APP,
  payload: App.update(id, form),
});

const find = id => ({
  type: FIND_APP,
  payload: App.find(id),
});

const destroy = id => ({
  type: DESTROY_APP,
  payload: App.destroy(id),
  meta: { appId: id },
});

const install = (id, installed) => (dispatch, getState) => {
  const company = authCompany(getState());
  dispatch({
    type: INSTALL_APP,
    payload: App.install(id, installed),
    meta: {
      companyId: company._id,
      appId: id,
      installed,
    },
  });
};

const regenerateSecret = (id, name) => ({
  type: REGENERATE_SECRET_APP,
  payload: App.regenerateSecret(id, name),
  meta: { appId: id },
});

const uploadFile = (appId, field, avatar) => ({
  type: UPLOAD_FILE_APP,
  payload: App.upload(appId, field, avatar),
  meta: { appId },
});

const getGroups = appId => ({
  type: GET_GROUPS_APP,
  payload: App.getGroups(appId),
  meta: { appId },
});

const addEmployee = id => (dispatch, getState) => {
  const company = authCompany(getState());
  dispatch({
    type: ADD_APP_EMPLOYEE,
    payload: App.addEmployee(id),
    meta: {
      companyId: company._id,
      appId: id,
    },
  });
};

const removeEmployee = id => (dispatch, getState) => {
  const company = authCompany(getState());
  return dispatch({
    type: REMOVE_APP_EMPLOYEE,
    payload: App.removeEmployee(id),
    meta: {
      companyId: company._id,
      appId: id,
    },
  });
};

export default {
  addEmployee,
  create,
  destroy,
  find,
  getGroups,
  install,
  load,
  regenerateSecret,
  removeEmployee,
  update,
  uploadFile,
};
