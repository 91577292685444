const formatMentions = (inText, mentions = [], formatMentionNode = () => {}) => {
  /**
   * Format the Mentions
   * and display them with
   * the different styles
   */
  if (!inText || inText === '' || !mentions.length) return inText;
  const ret = [];
  let lastIndex = 0;
  mentions.forEach(({ employee: employeeId, user: userId, pos: [start, end] }, idx) => {
    const initialStr = start === 1 ? '' : inText.substring(lastIndex, start);
    lastIndex = end + 1;
    ret.push(initialStr);
    const formattedMention = formatMentionNode(
      inText.substr(start, end - start + 1),
      employeeId,
      userId,
      start,
    );
    ret.push(formattedMention);
    if (idx === mentions.length - 1) {
      const lastStr = inText.substr(lastIndex); // remaining string
      ret.push(lastStr);
    }
  });
  return ret;
};

export default formatMentions;
