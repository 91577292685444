import Autocomplete from 'components/controls/AutocompleteField';
import { string } from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'utils/i18n';
import languagesActions from 'store/app/entities/config/languages/action';
import authCompany from 'store/selectors/authCompany';
import isSuperAdminSelector from 'store/selectors/isSuperAdmin';
import useActions from 'utils/useActions';

const Language = ({ value, ...restProps }) => {
  const {
    languages: allLanguages,
    loaded,
    loading,
    error,
  } = useSelector(state => state.config.languages);
  const loadLanguages = useActions(languagesActions.loadLanguages);
  const company = useSelector(authCompany);
  const isSuperAdmin = useSelector(isSuperAdminSelector);
  const languages = useMemo(
    () =>
      isSuperAdmin
        ? allLanguages
        : allLanguages.filter(
            lang => !company.languages || company.languages.includes(lang.shortName),
          ),
    [allLanguages, company.languages, isSuperAdmin],
  );
  useEffect(() => {
    if (!loaded && !loading && !error) {
      loadLanguages();
    }
  }, [error, loadLanguages, loaded, loading]);

  const generateLanguageLabel = option =>
    option && `${option.name} (${option.shortName.toUpperCase()})`;

  const findLanguageValue = (options, val) => options.find(option => option.shortName === val);

  return (
    <Autocomplete
      autoWidth
      placeholder={loading ? I18n.t('Loading languages...') : I18n.t('Choose language')}
      options={languages.map(option => ({
        ...option,
        label: generateLanguageLabel(option),
        value: option.shortName,
      }))}
      value={value}
      labelGenerator={generateLanguageLabel}
      findValue={findLanguageValue}
      {...restProps}
    />
  );
};

Language.propTypes = {
  value: string.isRequired,
};

export default Language;
