import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { bool, string } from 'prop-types';
import React, { memo } from 'react';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end',
    paddingTop: 15,
    paddingLeft: 45,
  },
  right: {
    flexDirection: 'row-reverse',
    alignSelf: 'flex-end',
    paddingRight: 45,
  },
});

const EmployeeLabel = ({ right, children }) => {
  const classes = useStyles();
  const styleRoot = classNames({
    [classes.root]: true,
    [classes.right]: right,
  });
  if (!children) return null;
  return (
    <Typography className={styleRoot} align="center" variant="caption">
      <span>
        <strong>{children}</strong>
      </span>
    </Typography>
  );
};

EmployeeLabel.propTypes = {
  children: string,
  right: bool.isRequired,
};

EmployeeLabel.defaultProps = {
  children: null,
};

export default memo(EmployeeLabel);
