import ms from 'ms';
import Http from 'services/Http';

class EmployeeImport {
  async cancel(id) {
    const { data } = await Http.delete(`/v1/employee-import/${id}`);
    return data;
  }

  async list() {
    const { data } = await Http.get(`/v1/employee-import`);
    return data;
  }

  async resume(id) {
    const { data } = await Http.get(`/v1/employee-import/${id}/resume`, { timeout: ms('5m') });
    return data;
  }

  async getFileUrl(id) {
    const { data } = await Http.get(`/v1/employee-import/${id}/file-url`);
    return data;
  }

  downloadResumedImport(url) {
    return Http.getS3(url, { responseType: 'arraybuffer' });
  }

  async start(id) {
    const { data } = await Http.post(`/v1/employee-import/${id}/start`);
    return data;
  }
}

export default new EmployeeImport();
