/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React from 'react';
import { func, bool, node, object } from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import Switch from '@mui/material/Switch';

const styles = () => ({
  root: {
    display: 'flex',
    height: '44px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  heading: {
    height: '22px',
    fontSize: '15px',
    lineHeight: 1.47,
  },
});

const BrowserItem = ({ classes, headline, enabled, onClick }) => (
  <div className={classes.root}>
    <div className={classes.heading}>{headline}</div>
    <div className={classes.placeholder} onClick={() => onClick(!enabled)}>
      <Switch checked={enabled} />
    </div>
  </div>
);
BrowserItem.propTypes = {
  classes: object.isRequired,
  headline: node.isRequired,
  enabled: bool.isRequired,
  onClick: func.isRequired,
};

export default withStyles(styles)(BrowserItem);
