import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import media from 'store/app/ui/drawers/media/action';

const mapDispatchToProps = dispatch => ({
  actions: {
    media: bindActionCreators(media, dispatch),
  },
});

export default connect(null, mapDispatchToProps);
