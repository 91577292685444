import React from 'react';

const ForwardMessagesModalLazy = React.lazy(() => import('./ForwardMessagesModal'));

const ForwardMessagesModal = props => (
  <React.Suspense fallback={null}>
    <ForwardMessagesModalLazy {...props} />
  </React.Suspense>
);
export default ForwardMessagesModal;
