import React from 'react';
import { object, func, bool, string, array, shape, node } from 'prop-types';
import withStyles from '@mui/styles/withStyles';

const styles = {
  root: {
    paddingTop: 15,
    display: 'flex',
    alignItems: 'center',
  },
};

const Container = ({ classes, children }) => (
<div className={classes.root}>
    {children}
  </div>
);Container.propTypes = {
  classes: object.isRequired,
  children: node.isRequired,
};

export default withStyles(styles)(Container);
