import makeStyles from '@mui/styles/makeStyles';
import { number, object, shape, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import ProgressiveImage from 'react-progressive-image';
import getImageDimensions from 'utils/getImageDimensions';
import connector from './connector';

const useStyles = makeStyles(theme => ({
  a: ({ src }) => ({
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    '&::before': {
      position: 'absolute',
      content: `''`,
      backgroundImage: `url(${src})`,
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      filter: 'blur(30px)',
      transform: 'scale(1.3)',
      backgroundColor: theme.palette.black,
      backfaceVisibility: 'hidden',
    },
    '&>img': {
      zIndex: 1,
    },
  }),
  loaded: {
    backgroundImage: 'url(/icons/transparent-background.svg)',
    borderRadius: 0,
  },
  loading: {
    cursor: 'pointer',
    filter: 'blur(4px)',
    transform: 'scale(1.05)',
  },
}));

const PictureThumbnail = ({ actions, src: imgSrc, meta, maxWidth, maxHeight }) => {
  const openPicture = event => {
    actions.pictureModal.open(imgSrc);
    event.preventDefault();
  };
  const [dimensions, setDimensions] = useState(getImageDimensions(meta));
  useEffect(() => {
    setDimensions(getImageDimensions(meta));
  }, [meta]);
  let { width, height } = dimensions;
  if (maxWidth && width >= height && maxWidth < width) {
    const scale = maxWidth / width;
    width *= scale;
    height *= scale;
  }
  if (maxHeight && width <= height && maxHeight < height) {
    const scale = maxHeight / height;
    width *= scale;
    height *= scale;
  }
  const classes = useStyles({ src: meta.placeholder || imgSrc, width, height });
  return (
    <a href={imgSrc} className={classes.a} onClick={openPicture}>
      <ProgressiveImage style={{ width, height }} src={imgSrc} placeholder={meta.placeholder}>
        {(src, loading) => (
          // eslint-disable-next-line jsx-a11y/alt-text
          <img
            style={{ width, height, objectFit: 'cover' }}
            className={loading ? classes.loading : classes.loaded}
            src={src}
          />
        )}
      </ProgressiveImage>
    </a>
  );
};

PictureThumbnail.propTypes = {
  src: string.isRequired,
  actions: shape({
    pictureModal: object.isRequired,
  }).isRequired,
  meta: object.isRequired,
  maxWidth: number,
  maxHeight: number,
};

PictureThumbnail.defaultProps = {
  maxWidth: null,
  maxHeight: null,
};
export default connector(PictureThumbnail);
