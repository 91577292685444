/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { WATCH_CHANNEL } from 'store/app/entities/channels/action';
import Message from 'services/api/Message';

const initialState = {
  watchedChannel_id: null,
  contextMessage: {},
  forwardingMessages: null,
};

export const forwardMessages = createAsyncThunk(
  'messages/forward',
  async ({ messageIds, channelIds }) => {
    const response = await Message.forward(messageIds, channelIds);
    return response.data;
  },
);

const ChatPanel = createSlice({
  name: 'ChatPanel',
  initialState,
  reducers: {
    edit(state, { payload: { message, currentChannel } }) {
      state.contextMessage[currentChannel._id] = { message, type: 'edit' };
    },
    reply(state, { payload: { message, currentChannel } }) {
      state.contextMessage[currentChannel._id] = { message, type: 'reply' };
    },
    forward(state, { payload: { messages } }) {
      state.forwardingMessages = messages;
    },
    clearForwarding(state) {
      state.forwardingMessages = null;
    },
    removeContextMessage(state, action) {
      state.contextMessage[action.payload] = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(WATCH_CHANNEL, (state, action) => {
      state.watchedChannel_id = action.payload;
    });
    builder.addCase(forwardMessages.fulfilled, state => {
      state.forwardingMessages = null;
    });
  },
});

export const { edit, reply, removeContextMessage, forward, clearForwarding } = ChatPanel.actions;

export default ChatPanel.reducer;
