import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import appDrawer from 'store/app/ui/drawers/app/action';

const mapStateToProps = state => ({
  isOpen: state.drawers.app.isOpen,
  app: state.drawers.app.app,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    drawers: {
      app: bindActionCreators(appDrawer, dispatch),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
