import { FormControl, FormHelperText, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import resolveExif from 'exif-normalizer';
import { bool, func, number, object, oneOfType, string } from 'prop-types';
import React, { memo, useCallback, useRef, useState } from 'react';
import { I18n } from 'utils/i18n';
import Thumbnail from './Thumbnail';

const styles = {
  root: {},
  fileInput: {
    opacity: 0,
    position: 'absolute',
  },
  label: {
    paddingTop: 0.5,
    textAlign: 'center',
    color: 'primary.main',
    cursor: 'pointer',
  },
  labelPreview: {
    color: 'background.paper',
  },
};

const PhotoUpload = ({
  onChange,
  name,
  value,
  onBlur,
  helperText,
  circle,
  edit,
  progress,
  sx,
  classes,
}) => {
  const [preview, setPreview] = useState(typeof value === 'string' ? value : '');

  const handleAdd = useCallback(
    e => {
      console.log(e);
      const image = e.target.files[0];
      if (!image) return;
      if (image.type.match(/image.*/)) {
        const reader = new FileReader();
        reader.onload = async () => {
          setPreview(await resolveExif(reader.result));
        };

        reader.readAsDataURL(image);
      }

      onChange(e.target.files[0]);
    },
    [onChange],
  );

  const handleBlur = useCallback(() => {
    onBlur(name, true);
  }, [onBlur, name]);

  const fileInput = useRef(null);

  const clickInput = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
    fileInput.current?.click();
  }, []);

  return (
    <FormControl sx={styles.root} classes={classes}>
      <Thumbnail circle={circle} onClick={clickInput} src={preview} progress={progress} sx={sx}>
        <Typography
          sx={{ ...styles.label, ...(preview ? styles.labelPreview : {}) }}
          onClick={clickInput}
          variant="caption"
        >
          {preview || edit ? I18n.t('PhotoUpload.edit photo') : I18n.t('PhotoUpload.upload photo')}
        </Typography>
      </Thumbnail>
      <Box
        component="input"
        sx={styles.fileInput}
        ref={fileInput}
        onChange={handleAdd}
        onBlur={handleBlur}
        accept="image/*"
        name={name}
        type="file"
      />

      {helperText && <FormHelperText id="name-error-text">{helperText}</FormHelperText>}
    </FormControl>
  );
};

PhotoUpload.propTypes = {
  name: string.isRequired,
  value: oneOfType([string, object]).isRequired,
  helperText: string,
  onChange: func,
  onBlur: func,
  circle: bool,
  edit: bool,
  progress: number,
  sx: object,
  classes: object,
};
PhotoUpload.defaultProps = {
  circle: false,
  helperText: '',
  onChange: () => {},
  onBlur: () => {},
  edit: false,
  progress: null,
  sx: {},
  classes: {},
};

export default memo(PhotoUpload);
