import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import admins from 'store/app/entities/admins/action';
import apps from 'store/app/entities/apps/action';
import channels from 'store/app/entities/channels/action';
import chatFlowEntityTypes from 'store/app/entities/chatFlowEntityTypes/action';
import chatFlowIntents from 'store/app/entities/chatFlowIntents/action';
import chatFlowPages from 'store/app/entities/chatFlowPages/action';
import chatFlows from 'store/app/entities/chatFlows/action';
import chatFlowWebhooks from 'store/app/entities/chatFlowWebhooks/action';
import companies from 'store/app/entities/companies/action';
import countryCodes from 'store/app/entities/config/countryCodes/action';
import employees from 'store/app/entities/employees/action';
import messages from 'store/app/entities/messages/action';
import subscriptions from 'store/app/entities/subscriptions/action';
import teams from 'store/app/entities/teams/action';
import alert from 'store/app/ui/Alert/action';
import appDrawer from 'store/app/ui/drawers/app/action';
import hrBotDrawer from 'store/app/ui/drawers/hrBot/action';
import launcherAppDrawer from 'store/app/ui/drawers/launcherApp/action';
import remoteDrawer from 'store/app/ui/drawers/remote/action';
import rulesDrawer from 'store/app/ui/drawers/rules/action';
import teamDrawer from 'store/app/ui/drawers/team/action';
import timeTrackingDrawer from 'store/app/ui/drawers/timeTracking/action';
import whistleblowerBotDrawer from 'store/app/ui/drawers/whistleblowerBot/action';
import authCompany from 'store/selectors/authCompany';
import authUser from 'store/selectors/authUser';
import filteredChannels from 'store/selectors/filteredChannels';
import isCompanyAdmin from 'store/selectors/isCompanyAdmin';
import isSuperAdmin from 'store/selectors/isSuperAdmin';
import isTeamAdmin from 'store/selectors/isTeamAdmin';

const mapStateToProps = state => ({
  allChannels: filteredChannels(state),
  teams: state.teams.teams,
  apps: state.apps.apps,
  authUser: authUser(state),
  chatFlows: state.chatFlows.chatFlows,
  chatFlowEntityTypes: state.chatFlowEntityTypes.chatFlowEntityTypes,
  chatFlowIntents: state.chatFlowIntents.chatFlowIntents,
  chatFlowWebhooks: state.chatFlowWebhooks.chatFlowWebhooks,
  isCompanyAdmin: isCompanyAdmin(state),
  isTeamAdmin: isTeamAdmin(state),
  authCompany: authCompany(state),
  admins: state.admins.employees,
  adminsLoading: state.admins.loading,
  adminsLoadedAt: state.admins.loadedAt && new Date(state.admins.loadedAt),
  customer: state.subscriptions.customer,
  subscriptionsLoading: state.subscriptions.loading,
  subscriptionsLoadedAt: state.subscriptions.loadedAt && new Date(state.subscriptions.loadedAt),
  countryCodes: state.config.countryCodes,
  locale: state.i18n.locale,
  languages: state.config.languages.languages,
  clientSecret: state.companies.clientSecret,
  isSuperAdmin: isSuperAdmin(state),
});

const mapDispatchToProps = dispatch => ({
  actions: {
    alert: bindActionCreators(alert, dispatch),
    apps: bindActionCreators(apps, dispatch),
    chatFlows: bindActionCreators(chatFlows, dispatch),
    chatFlowEntityTypes: bindActionCreators(chatFlowEntityTypes, dispatch),
    chatFlowIntents: bindActionCreators(chatFlowIntents, dispatch),
    chatFlowPages: bindActionCreators(chatFlowPages, dispatch),
    chatFlowWebhooks: bindActionCreators(chatFlowWebhooks, dispatch),
    companies: bindActionCreators(companies, dispatch),
    employees: bindActionCreators(employees, dispatch),
    channels: bindActionCreators(channels, dispatch),
    messages: bindActionCreators(messages, dispatch),
    teams: bindActionCreators(teams, dispatch),
    drawers: {
      app: bindActionCreators(appDrawer, dispatch),
      hrBot: bindActionCreators(hrBotDrawer, dispatch),
      launcherApp: bindActionCreators(launcherAppDrawer, dispatch),
      whistleblowerBot: bindActionCreators(whistleblowerBotDrawer, dispatch),
      timeTracking: bindActionCreators(timeTrackingDrawer, dispatch),
      remote: bindActionCreators(remoteDrawer, dispatch),
      rules: bindActionCreators(rulesDrawer, dispatch),
      team: bindActionCreators(teamDrawer, dispatch),
    },
    admins: bindActionCreators(admins, dispatch),
    subscriptions: bindActionCreators(subscriptions, dispatch),
    config: {
      countryCodes: bindActionCreators(countryCodes, dispatch),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
