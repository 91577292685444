export const OPEN_ACKNOWLEDGE_CREATE_DRAWER = 'OPEN_ACKNOWLEDGE_CREATE_DRAWER';
export const CLOSE_ACKNOWLEDGE_CREATE_DRAWER = 'CLOSE_ACKNOWLEDGE_CREATE_DRAWER';
export const SAVE_UPLOADED_ACKNOWLEDGMENT_PDF = 'SAVE_UPLOADED_ACKNOWLEDGMENT_PDF';

const open = payload => ({
  type: OPEN_ACKNOWLEDGE_CREATE_DRAWER,
  payload,
});

const close = () => ({
  type: CLOSE_ACKNOWLEDGE_CREATE_DRAWER,
});

const saveTemporarytempAttachment = pdf => ({
  type: SAVE_UPLOADED_ACKNOWLEDGMENT_PDF,
  payload: pdf,
});

export default { open, close, saveTemporarytempAttachment };
