import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import Box from '@mui/material/Box';
import { bool, func, number, object } from 'prop-types';
import React, { useCallback } from 'react';
import ContentLoader from 'react-content-loader';
import InfiniteLoader from 'react-window-infinite-loader';
import EmployeeListItem from './EmployeeListItem';

const EmployeesList = ({ employeesRef, loadMoreItems, totalCount, isFetching }) => {
  const renderRow = useCallback(
    ({ index, key, style }) => {
      const employee = employeesRef.current[index];
      return employee ? (
        <EmployeeListItem key={key} employee={employee} style={style} />
      ) : (
        <Box key={key} sx={{ flexGrow: 1 }} style={style}>
          <ContentLoader width={400} height={80} viewBox="0 0 400 80">
            <circle cx={22} cy="32" r="22px" />
            <rect x={60} y="30" rx="3" ry="3" width="200" height="10" />
            <rect x={60} y="10" ry="3" width="100" height="10" />
          </ContentLoader>
        </Box>
      );
    },
    [employeesRef],
  );
  const isItemLoaded = useCallback(index => !!employeesRef.current[index], [employeesRef]);
  const renderEmployees = useCallback(
    ({ height, width }) => {
      return (
        <InfiniteLoader
          minimumBatchSize={100}
          isItemLoaded={isItemLoaded}
          itemCount={totalCount}
          loadMoreItems={loadMoreItems}
        >
          {({ onItemsRendered, ref }) => (
            <FixedSizeList
              onItemsRendered={onItemsRendered}
              ref={ref}
              height={height}
              width={width}
              itemCount={totalCount}
              itemSize={80}
            >
              {renderRow}
            </FixedSizeList>
          )}
        </InfiniteLoader>
      );
    },
    [isItemLoaded, loadMoreItems, renderRow, totalCount],
  );

  return (
    <Box
      sx={{
        flexGrow: 1,
        opacity: isFetching ? 0.5 : 1,
        pointerEvents: isFetching ? 'none' : 'initial',
      }}
    >
      <AutoSizer>{renderEmployees}</AutoSizer>
    </Box>
  );
};

EmployeesList.propTypes = {
  employeesRef: object.isRequired,
  loadMoreItems: func.isRequired,
  totalCount: number.isRequired,
  isFetching: bool.isRequired,
};

export default EmployeesList;
