import { GET_USER_DATA_FULFILLED } from 'store/app/auth/action';
import { SET_USER, TYPING } from 'store/app/entities/users/action';

const createUser = u => ({
  _id: u._id,
  phone: u.phone,
  role: u.role,
  lastSeen: u.lastSeen,
  createdAt: u.createdAt,
  isSuperAdmin: u.isSuperAdmin,
  isOnline: u.isOnline,
  isTyping: u.isTyping,
  isApp: u.isApp,
  loginType: u.loginType,
});

const usersReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case SET_USER:
    case GET_USER_DATA_FULFILLED: {
      const users = { ...state };
      users[payload._id] = createUser({
        ...payload,
        isSuperAdmin: payload.role === 2,
      });

      return users;
    }

    case TYPING: {
      const users = { ...state };
      const isTyping = users[payload.user._id]?.isTyping || {};
      isTyping[payload.channel] = payload.status;
      users[payload.user._id] = createUser({
        ...payload.user,
        isSuperAdmin: payload.role === 2,
        isTyping,
      });
      return users;
    }

    default:
      return state;
  }
};

export default usersReducer;
