import { LOAD_MONTH_FULFILLED, LOAD_MONTH_PENDING, LOAD_MONTH_REJECTED } from './action';

const initialState = {
  loaded: false,
  loading: false,
};

const timeTrackerLogReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_MONTH_PENDING:
      return {
        ...state,
        loading: true,
      };

    case LOAD_MONTH_FULFILLED:
      return {
        ...state,
        ...payload,
        loaded: true,
        loading: false,
      };

    case LOAD_MONTH_REJECTED:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default timeTrackerLogReducer;
