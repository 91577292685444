import { Box, Button, Grid, Typography } from '@mui/material';
import Loading from 'components/common/Loading';
import FormikAutosuggest from 'components/formik/Autosuggest';
import Switch from 'components/formik/Switch';
import TextField from 'components/formik/TextField';
import { Field, Form, useFormikContext } from 'formik';
import { bool, func, object, string } from 'prop-types';
import React from 'react';
import { I18n } from 'utils/i18n';
import userShape from 'shapes/user';

const FormField = ({ field, isLoading }) => {
  const { getFieldProps } = useFormikContext();
  const { value } = getFieldProps(field.name);
  return (
    <>
      <Grid item xs={2}>
        <Typography>{`${I18n.t(`UserDrawer.${field.name}`)}`}</Typography>
      </Grid>
      <Grid item xs={10}>
        <Field
          disabled={isLoading && ['name', 'subDomain', 'logo', 'logoDark'].includes(field.name)}
          InputProps={{
            endAdornment: field.name === 'webSiteUrl' && isLoading ? <Loading size={20} /> : null,
          }}
          label={
            field.name === 'subDomain' && value ? `https://${value}.web.ommnio.com` : undefined
          }
          InputLabelProps={{ shrink: true }}
          name={field.name}
          component={field.component || TextField}
          {...(field.props || {})}
        />
      </Grid>
    </>
  );
};
FormField.propTypes = {
  field: object.isRequired,
  isLoading: bool.isRequired,
};

const fields = [
  { name: 'phone' },
  {
    name: 'role',
    component: FormikAutosuggest,
    props: {
      isClearable: true,
      suggestions: [
        {
          label: 'User',
          value: 1,
        },
        {
          label: 'Superadmin',
          value: 2,
        },
      ],
    },
  },
  { name: 'isApp', component: Switch },
  { name: 'isReadOnly', component: Switch },
];

export const UserForm = ({ user, onClose, error, isLoading, isError }) => {
  return (
    <Form
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          mt: 2,
          flex: '1 1 auto',
          overflowY: 'scroll',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Grid
          spacing={1}
          sx={{
            flex: '1 1 auto',
            display: 'flex',
            '& .MuiGrid-item': {
              display: 'flex',
              alignItems: 'center',
            },
          }}
          container
        >
          {user?._id && (
            <>
              <Grid item xs={2}>
                <Typography>{`${I18n.t('UserDrawer.Id')}`}</Typography>
              </Grid>
              <Grid item xs={10}>
                <Field name="_id" disabled component={TextField} />
              </Grid>
            </>
          )}
          {fields.map(field => (
            <FormField key={field.name} field={field} />
          ))}
        </Grid>
      </Box>
      <Box
        sx={{
          pt: 2,
          display: 'flex',
          gap: 1,
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        {isError && (
          <Typography sx={{ flexGrow: 1 }} color="error">
            {error?.data?.message || I18n.t('UserDrawer.Unexpected error')}
          </Typography>
        )}
        <Button
          onClick={onClose}
          variant="outlined"
          color="secondary"
          sx={{ color: 'primary.ultraDark' }}
        >
          {I18n.t('UserDrawer.Cancel')}
        </Button>
        <Button disabled={isLoading} type="submit" variant="contained" color="primary">
          {I18n.t('UserDrawer.Save')}
          {isLoading && <Loading sx={{ ml: 1 }} size={14} color="secondary" />}
        </Button>
      </Box>
    </Form>
  );
};

UserForm.propTypes = {
  user: userShape.isRequired,
  onClose: func.isRequired,
  error: string.isRequired,
  isLoading: bool.isRequired,
  isError: bool.isRequired,
};
