import { ActionType } from 'redux-promise-middleware';
import { CREATE_IMPORT } from 'store/app/entities/employees/action';
import {
  CANCEL_EMPLOYEE_IMPORT,
  EMPLOYEE_IMPORT_FINISHED,
  LIST_EMPLOYEE_IMPORTS,
  START_EMPLOYEE_IMPORT,
} from './action';

const initialState = {
  data: null,
};

export const ImportStatus = {
  uploaded: 'uploaded',
  importing: 'importing',
  finished: 'finished',
};

const employeeImportsReducer = (state = initialState, { type, payload, meta }) => {
  switch (type) {
    case `${LIST_EMPLOYEE_IMPORTS}_${ActionType.Fulfilled}`: {
      return { ...state, data: payload.employeeImports };
    }

    case `${CREATE_IMPORT}_${ActionType.Fulfilled}`: {
      return { ...state, data: [...state.data, payload.data.employeeImport] };
    }

    case `${CANCEL_EMPLOYEE_IMPORT}_${ActionType.Pending}`: {
      const newData = (state.data || []).filter(ei => ei._id !== meta.employeeImportId);
      return { ...state, data: newData };
    }

    case `${START_EMPLOYEE_IMPORT}_${ActionType.Pending}`: {
      const newData = (state.data || []).map(ei => ({
        ...ei,
        status: ei._id === meta.employeeImportId ? 'importing' : ei.status,
      }));
      return { ...state, data: newData };
    }

    case EMPLOYEE_IMPORT_FINISHED: {
      const newData = (state.data || []).map(ei => ({
        ...ei,
        ...(ei._id === payload.employeeImportId
          ? { status: 'finished', result: payload.result }
          : {}),
      }));
      return { ...state, data: newData };
    }

    default:
      return state;
  }
};

export default employeeImportsReducer;
