import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Lottie from 'lottie-react';
import { node, object, string } from 'prop-types';
import React from 'react';
import signInTapYes from './signInTapYes.json';

const styles = theme => ({
  root: {
    marginBottom: 40,
  },
  link: {
    color: theme.palette.primary.dark,
  },
  heading: {
    maxWidth: 330,
  },
  subheading: {
    color: '#8b98ab',
    fontWeight: 'normal',
  },
  signinTypes: {
    height: 150,
  },
});

const Heading = ({ classes, headline, subheading, type }) => (
  <section className={classes.root}>
    <div>
      <Typography className={classes.heading} gutterBottom variant="h5">
        {headline}
      </Typography>
      {type === 'app' ? (
        <>
          <Lottie
            animationData={signInTapYes}
            loop
            style={{ height: 150, width: 87, marginBottom: 8 }}
          />
          <Typography dangerouslySetInnerHTML={{ __html: subheading }} />
        </>
      ) : (
        <Typography variant="h5" className={classes.subheading}>
          {subheading}
        </Typography>
      )}
    </div>
  </section>
);
Heading.propTypes = {
  classes: object.isRequired,
  headline: node.isRequired,
  subheading: node.isRequired,
  type: string.isRequired,
};

export default withStyles(styles)(Heading);
