import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MessageGroup from 'components/@home/@messages/ChatPanel/MessagesList/MessageGroup';
import { HrBotContext } from 'components/@home/drawers/HrBotDrawer/hrBotContext';
import { getFileName } from 'components/@home/drawers/HrBotDrawer/pdf-utils';
import IphonePreview from 'components/common/IphonePreview';
import LinearProgressWithLabel from 'components/controls/LinearProgressWithLabel';
import CheckCircleIcon from 'mdi-react/CheckCircleIcon';
import { func, object } from 'prop-types';
import React, { memo, useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'utils/i18n';
import { HR_BOT_APP_ID } from 'services/constants';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 40,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-around',
  },
  input: {
    width: 290,
  },
  chat: {
    zoom: 0.73,
    flexDirection: 'column',
    padding: 10,
    flexGrow: 1,
    backgroundColor: theme.palette.secondary.ultraLight,
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: 14,
  },
  summary: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: theme.spacing(2),
    },
  },
  cancel: {
    color: theme.palette.danger,
  },
  check: {
    color: theme.palette.ok,
  },
  progress: {
    marginTop: theme.spacing(5),
  },
  left: {
    width: '50%',
  },
}));

const StepSend = ({ setError, setNextEnabled, userItems }) => {
  const classes = useStyles();
  const bulkMessaging = useSelector(state => state.bulkMessaging.bulkMessaging);
  const app = useSelector(state => state.apps.apps[HR_BOT_APP_ID]);
  const { files, message, uploadedPct, format } = useContext(HrBotContext);
  const file = useMemo(
    () => ({ ...files?.find(f => !f.error && f.employee?.[format.sendBy]) }),
    [files, format.sendBy],
  );

  const numSend = useMemo(
    () => files?.filter(f => !f.error && f.employee && f.employee.user).length || 0,
    [files],
  );
  const numNotFound = useMemo(
    () => files?.filter(f => !f.error && (!f.employee || !f.employee.user)).length || 0,
    [files],
  );
  const name = useMemo(
    () => getFileName({ ...file, ...userItems }, format),
    [file, userItems, format],
  );

  useEffect(() => {
    setNextEnabled(!!message);
    setError(null);
  }, [message, setError, setNextEnabled]);

  return (
    <div className={classes.root}>
      <div className={classes.form}>
        <div className={classes.left}>
          <div className={classes.summary}>
            <Typography>{I18n.t('HrBot.will be sent', { count: numSend })}</Typography>
          </div>
          {numNotFound > 0 && (
            <div className={classes.summary}>
              <Typography>{I18n.t('HrBot.will not be sent', { count: numNotFound })}</Typography>
            </div>
          )}
          <div className={classes.progress}>
            <Typography>{I18n.t('HrBot.Upload progress')}</Typography>
            <LinearProgressWithLabel value={uploadedPct} />
          </div>
          <div className={classes.progress}>
            <Typography>{I18n.t('HrBot.Send progress')}</Typography>
            <LinearProgressWithLabel
              value={
                bulkMessaging
                  ? ((bulkMessaging.sent + bulkMessaging.error) / bulkMessaging.total) * 100
                  : 0
              }
            />
            {bulkMessaging?.error > 0 && (
              <Typography color="error">
                {I18n.t('HrBot.Error sending files', { count: bulkMessaging.error })}
              </Typography>
            )}
          </div>
          <div className={classes.summary}>
            <Typography>
              {I18n.t(bulkMessaging ? 'HrBot.You can now close' : 'HrBot.Please do not close')}
            </Typography>
          </div>
          {bulkMessaging?.status === 'FINISHED' && (
            <div className={classes.summary}>
              <CheckCircleIcon className={classes.check} />
              <Typography>{I18n.t('HrBot.Finished', { count: bulkMessaging.sent })}</Typography>
            </div>
          )}
        </div>
        <IphonePreview>
          <div className={classes.chat}>
            {file?.file && (
              <MessageGroup
                msgRef={() => {}}
                message={{
                  text: message,
                  attachments: [
                    {
                      name,
                      httpLink: name,
                      mimeType: 'application/pdf',
                      type: 'doc',
                      size: file.file.size,
                    },
                  ],
                  employee: {
                    avatar: app.avatar,
                    firstName: app.name,
                  },
                }}
              />
            )}
          </div>
        </IphonePreview>
      </div>
    </div>
  );
};

StepSend.propTypes = {
  setError: func.isRequired,
  setNextEnabled: func.isRequired,
  userItems: object.isRequired,
};

export default memo(StepSend);
