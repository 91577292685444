import DropzoneBox from 'components/common/DropzoneBox';
import { object } from 'prop-types';
import React from 'react';
import useFileUrl from 'utils/useFileUrl';

const FormikDropzoneBox = ({ field, form, ...props }) => {
  const { name, onBlur } = field;
  const { errors, submitCount, setFieldValue, getFieldProps } = form;
  const { value } = getFieldProps(name);
  const image = useFileUrl(value);

  return (
    <DropzoneBox
      {...props}
      onDrop={newDroppedFiles => setFieldValue(name, newDroppedFiles)}
      onBlur={onBlur}
      image={image}
      name={name}
      error={!!(errors[name] && submitCount > 0)}
      helperText={submitCount > 0 && errors[name]}
    />
  );
};

FormikDropzoneBox.propTypes = {
  field: object.isRequired,
  form: object.isRequired,
};

export default FormikDropzoneBox;
