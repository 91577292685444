import { Drawer } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { bool, object } from 'prop-types';
import React from 'react';
import CloseButton from '../CloseButton';

import connector from './connector';
import AppForm from './Form';

const styles = theme => ({
  root: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 1000,
    },
  },
  container: {
    display: 'flex',
  },
  closeButtonContainer: {
    paddingTop: 20,
    paddingLeft: 20,
  },
});

const AppDrawer = ({ actions, classes, isOpen }) => (
  <Drawer anchor="right" open={isOpen} onClose={actions.drawers.app.close}>
    <div className={classes.root}>
      <div className={classes.closeButtonContainer}>
        <CloseButton onClick={actions.drawers.app.close} />
      </div>
      <AppForm />
    </div>
  </Drawer>
);

AppDrawer.propTypes = {
  classes: object.isRequired,
  actions: object.isRequired,
  isOpen: bool.isRequired,
};

export default withStyles(styles)(connector(AppDrawer));
