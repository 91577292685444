import ListSubheader from '@mui/material/ListSubheader';
import { makeStyles } from '@mui/styles';
import ArchivedChannelsButton from 'components/@home/@messages/ChannelsPanel/ArchivedChannelsButton';
import isEqualWith from 'lodash/isEqualWith';
import { array, bool, func, number, object, string } from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as List } from 'react-window';
import channelsActions from 'store/app/entities/channels/action';
import messagesActions from 'store/app/entities/messages/action';
import authEmployeeSelector from 'store/selectors/authEmployee';
import currentChannelId from 'store/selectors/currentChannelId';
import Channel from './Channel';

const actions = {
  channels: channelsActions,
  messages: messagesActions,
};

const compareChannel = (o1, o2) =>
  isEqualWith(o1, o2, 'updatedAt') &&
  isEqualWith(o1, o2, 'lastMessage.createdAt') &&
  isEqualWith(o1, o2, 'messagesUnreadCount') &&
  isEqualWith(o1, o2, 'isActive') &&
  isEqualWith(o1, o2, 'avatar') &&
  isEqualWith(o1, o2, 'pinned');

const Row = ({ data, index, key, style }) => {
  const { visibleChannels, currentId, messageId, onSelect, onClickJoin, showArchived } = data;
  const channel = visibleChannels[index];
  if (channel.separator) {
    return (
      <div key={`${showArchived}-${key}`} style={style}>
        <ListSubheader>{channel.separator}</ListSubheader>
      </div>
    );
  }
  return (
    <div key={`${showArchived}-${key}`} style={style}>
      <Channel
        channel={channel}
        selected={channel._id === currentId && channel.messageId === messageId}
        onClick={onSelect(channel._id, channel.messageFromSearch ? channel.lastMessage._id : null)}
        onClickJoin={onClickJoin && onClickJoin(channel._id)}
        isLast={index === visibleChannels.length - 1 || visibleChannels[index + 1].separator}
      />
    </div>
  );
};

Row.propTypes = {
  data: object.isRequired,
  index: number.isRequired,
  key: string.isRequired,
  style: object.isRequired,
};

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    '& div': {
      outline: 'none',
    },
  },
}));

const Channels = ({
  channels,
  messageId,
  onSelect,
  onClickJoin,
  showArchived,
  loading,
  filter,
}) => {
  const classes = useStyles();
  const currentId = useSelector(currentChannelId);
  const authEmployee = useSelector(authEmployeeSelector);
  const [visibleChannels, setVisibleChannels] = useState([]);
  const [archivedChannels, setArchivedChannels] = useState([]);
  const dispatch = useDispatch();
  const { channelId } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const currentMessageId = useMemo(() => state?.messageId || null, [state]);

  useEffect(() => {
    if (loading) return;
    if (channelId) {
      if (!visibleChannels?.length) return;
      const foundChannel = visibleChannels.find(c => c._id === channelId) || state?.channel;
      if (foundChannel && (channelId !== currentId || messageId !== currentMessageId)) {
        dispatch(actions.channels.open(channelId, currentMessageId));
      } else if (!foundChannel) {
        navigate(`/home/messages/${visibleChannels[0]?._id || ''}`);
      }
    } else if (!channelId) {
      navigate(`/home/messages/${visibleChannels[0]?._id || ''}`);
    }
  }, [
    channelId,
    currentId,
    currentMessageId,
    dispatch,
    loading,
    messageId,
    navigate,
    state?.channel,
    state?.messageId,
    visibleChannels,
  ]);

  const filterChannels = useCallback(() => {
    const newVisibleChannels = channels.filter(
      c => filter || !showArchived === !authEmployee?.archivedChannels?.includes(c._id),
    );

    const newArchivedChannels = channels.filter(c =>
      authEmployee?.archivedChannels?.includes(c._id),
    );
    setArchivedChannels(newArchivedChannels);
    setVisibleChannels(newVisibleChannels);
  }, [channels, authEmployee, showArchived]);

  useEffect(() => {
    filterChannels();
  }, [authEmployee?.archivedChannels, channels, currentId, filterChannels, showArchived]);

  const getItemSize = useCallback(
    index => {
      return visibleChannels[index]?.separator ? 36 : 75;
    },
    [visibleChannels],
  );

  const itemData = useMemo(
    () => ({
      visibleChannels,
      currentId,
      messageId,
      onSelect,
      onClickJoin,
      showArchived,
    }),
    [currentId, messageId, onClickJoin, onSelect, showArchived, visibleChannels],
  );

  const renderChannels = useCallback(
    ({ height, width }) => {
      return (
        <List
          itemData={itemData}
          height={height}
          width={width}
          itemCount={visibleChannels.length}
          itemSize={getItemSize}
        >
          {Row}
        </List>
      );
    },
    [getItemSize, itemData, visibleChannels.length],
  );

  if (!visibleChannels) {
    return null;
  }

  return (
    <div className={classes.root}>
      {archivedChannels?.length > 0 && <ArchivedChannelsButton />}
      <div style={{ flex: '1 1 auto' }}>
        <AutoSizer>{renderChannels}</AutoSizer>
      </div>
    </div>
  );
};

Channels.propTypes = {
  channels: array.isRequired,
  messageId: string,
  onSelect: func.isRequired,
  onClickJoin: func,
  showArchived: bool,
  loading: bool.isRequired,
  filter: string,
};

Channels.defaultProps = {
  filter: null,
  messageId: null,
  onClickJoin: null,
  showArchived: null,
};

export default memo(
  Channels,
  (prevProps, nextProps) =>
    prevProps.loading === nextProps.loading &&
    prevProps.filter === nextProps.filter &&
    prevProps.messageId === nextProps.messageId &&
    prevProps.showArchived === nextProps.showArchived &&
    prevProps.channels.length === nextProps.channels.length &&
    prevProps.channels.every((c, i) => compareChannel(c, nextProps.channels[i])) &&
    prevProps.onSelect === nextProps.onSelect,
);
