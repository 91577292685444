import { LinearProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import FileThumbnail from 'components/@home/@messages/ChatPanel/MessagesList/MessageGroup/FileThumbnail';
import moment from 'moment';
import { bool, number, object } from 'prop-types';
/* eslint-disable jsx-a11y/media-has-caption,operator-linebreak,react/destructuring-assignment */
import React, { useEffect, useRef, useState } from 'react';
import PlayButton from './PlayButton';

const useStyles = makeStyles(() => ({
  root: {
    minWidth: ({ minWidth }) => minWidth,
    maxWidth: 250,
    flexGrow: 1,
  },
  container: {
    padding: 10,
    display: 'flex',
    alignItems: 'center',
  },
  progressContainer: {
    flexGrow: 1,
    alignItems: 'center',
    display: 'flex',
  },
  progress: {
    borderRadius: 10,
    flexGrow: 1,
  },
  time: {
    paddingLeft: 10,
    paddingRight: 5,
    fontSize: 14,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const AudioThumbnail = ({ data, right, minWidth }) => {
  const audio = useRef();
  const [loaded, setLoaded] = useState(false);
  const [paused, setPaused] = useState(true);
  const [duration, setDuration] = useState(null);
  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(0);
  const [error, setError] = useState(null);
  const classes = useStyles({ minWidth });

  const handleBuffer = e => {
    const elapsedTime = e.target.buffered.end(e.target.buffered.length - 1);
    const result = (elapsedTime / e.target.duration) * 100;
    setBuffer(result);
  };

  const handleProgress = e => {
    const elapsedTime = e.target.currentTime;
    const result = (elapsedTime / e.target.duration) * 100;
    setProgress(result);
    setDuration(elapsedTime);

    handleBuffer(e);
  };

  const handleMetadata = e => {
    setDuration(e.target.duration === Infinity ? 0 : e.target.duration);
  };

  const handleEnded = () => {
    setPaused(true);
  };

  const handleLoaded = () => {
    setLoaded(true);
    setError(null);
  };

  const play = () => audio.current.play();

  const pause = () => {
    audio.current.pause();
    setPaused(true);
  };

  useEffect(() => {
    if (paused) {
      pause();
    } else {
      play();
    }
  }, [paused]);

  const togglePlay = async () => {
    if (!loaded) {
      return;
    }
    setPaused(p => !p);
  };

  if (error) {
    return <FileThumbnail key={data._id} data={data} right={right} />;
  }

  return (
    <div className={classes.root}>
      <audio
        onTimeUpdate={handleProgress}
        onLoadedMetadata={handleMetadata}
        onLoadedData={handleLoaded}
        onEnded={handleEnded}
        onError={setError}
        ref={audio}
        src={data.httpLink}
        type={data.mimeType}
      >
        Your browser does not support the html5 audio.
      </audio>
      <div className={classes.container}>
        <div className={classes.progressContainer}>
          <LinearProgress
            sx={{ flexGrow: 1 }}
            variant={paused ? 'determinate' : 'buffer'}
            value={progress}
            className={classes.progress}
            valueBuffer={buffer}
          />
          <Typography
            variant="caption"
            className={classNames({
              [classes.time]: true,
              [classes.right]: right,
            })}
          >
            {moment.utc(moment.duration(duration, 's').asMilliseconds()).format('mm:ss')}
          </Typography>
        </div>
        <PlayButton right={right} paused={paused} loaded={loaded} onClick={togglePlay} />
      </div>
    </div>
  );
};

AudioThumbnail.propTypes = {
  right: bool,
  data: object.isRequired,
  minWidth: number,
};

AudioThumbnail.defaultProps = {
  minWidth: 100,
  right: false,
};

export default AudioThumbnail;
