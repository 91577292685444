import { ListItem, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AccessTimeIcon from 'assets/icons/invite.svg';
import classNames from 'classnames';
import EmployeeAvatar from 'components/@home/EmployeeAvatar';
import CheckIcon from 'mdi-react/CheckIcon';
import { bool, object } from 'prop-types';
import React, { memo } from 'react';

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: 15,
  },
  location: {
    justifyContent: 'flex-end',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  pending: {
    color: theme.palette.secondary.main,
  },
  status: {
    marginRight: 15,
    width: 24,
  },
  onColor: {
    color: theme.palette.onOffChartSeries[0],
  },
  offColor: {
    color: theme.palette.onOffChartSeries[1],
    width: 18,
    height: 18,
  },
  secondary: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const MemberListItem = ({ member, showReadStatus, style }) => {
  const classes = useStyles();
  let status = member.isAcceptTerms ? 'active' : 'pending';
  if (status === 'active' && member.isAdmin) {
    status = 'admin';
  }
  return (
    <ListItem disableGutters className={classes.root} style={style}>
      {showReadStatus && (
        <div className={classes.status}>
          {member.read ? (
            <CheckIcon className={classes.onColor} />
          ) : (
            <AccessTimeIcon className={classes.offColor} />
          )}
        </div>
      )}
      <div className={classes.avatar}>
        <EmployeeAvatar large employee={member} status={status} />
      </div>
      <ListItemText
        classes={{
          primary: classNames({
            [classes.pending]: !member.isAcceptTerms,
          }),
          secondary: classes.secondary,
        }}
        primary={member.name}
        secondary={`${member.jobTitle || ''}, ${member.department || ''}`}
      />
      <ListItemText
        sx={{ flexGrow: 0 }}
        secondary={member.location}
        classes={{ secondary: classes.location }}
      />
    </ListItem>
  );
};

MemberListItem.propTypes = {
  member: object.isRequired,
  showReadStatus: bool.isRequired,
  style: object,
};

MemberListItem.defaultProps = {
  style: {},
};

export default memo(MemberListItem);
