import { Box, Typography } from '@mui/material';
import isEqual from 'lodash/isEqual';
import { ColorPicker, createColor } from 'mui-color';
import { func, object } from 'prop-types';
import React, { memo, useCallback } from 'react';

const FormikColorPicker = ({ field, bindChange, form, disabled, ...props }) => {
  const { name, value, onChange, onBlur } = field;
  const { setFieldValue } = form;
  const handleBlur = useCallback(
    e => {
      onBlur(e);
      if (props.onBlur) {
        props.onBlur(e);
      }
    },
    [onBlur, props.onBlur],
  );
  const handleChange = useCallback(
    newValue => {
      bindChange(newValue);
      if (typeof newValue === 'string') {
        const color = createColor(newValue);
        setFieldValue(name, color);
      } else {
        setFieldValue(name, newValue);
      }
    },
    [bindChange, onChange],
  );
  return (
    <Box sx={{ '& > div': { alignItems: 'center' } }}>
      {disabled ? (
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Box
            sx={{ width: 24, minHeight: 24, borderRadius: 0.5, backgroundColor: `#${value.hex}` }}
          />
          <Typography>{`#${value.hex}`}</Typography>
        </Box>
      ) : (
        <ColorPicker
          {...props}
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
          name={name}
        />
      )}
    </Box>
  );
};

FormikColorPicker.propTypes = {
  field: object.isRequired,
  form: object.isRequired,
  bindChange: func,
  onBlur: func,
};
FormikColorPicker.defaultProps = {
  bindChange: () => {},
  onBlur: undefined,
};

const areEqual = (
  { form: { submitCount, errors }, field: { name, value }, type, disabled, InputProps },
  {
    form: { submitCount: newSubmitCount, errors: nextErrors },
    field: { value: nextValue },
    type: nextType,
    disabled: nextDisabled,
    InputProps: nextInputProps,
  },
) => {
  return (
    submitCount === newSubmitCount &&
    value === nextValue &&
    errors[name] === nextErrors[name] &&
    type === nextType &&
    disabled === nextDisabled &&
    isEqual(InputProps, nextInputProps)
  );
};

export default memo(FormikColorPicker, areEqual);
