import { withFormik } from 'formik';
import to from 'util-to';
import * as Yup from 'yup';

const formik = withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string().required('This field is required'),
    company: Yup.string().required('This field is required'),
    email: Yup.string().email('Email address is not valid').required('This field is required'),
  }),
  mapPropsToValues: ({ form }) => ({
    phone: form.phone,
    name: '',
    company: '',
    email: '',
  }),

  handleSubmit: async (values, { props: { actions }, setErrors, setSubmitting }) => {
    const [err, response] = await to(actions.login.sendEmail(values));

    setSubmitting(false);

    if (err) setErrors({ server: 'Something went wrong with server response.' });

    if (response) actions.login.invitationDialog.open();

    return true;
  },
  displayName: 'LoginForm',
});

export default formik;
