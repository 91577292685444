import makeStyles from '@mui/styles/makeStyles';
import BlockButton from 'components/@home/@messages/ChatPanel/MessagesList/MessageGroup/Blocks/BlockButton';
import BlockSlider from 'components/@home/@messages/ChatPanel/MessagesList/MessageGroup/Blocks/BlockSlider';
import React from 'react';
import messageShape from 'shapes/message';
import { ReactMarkdown } from 'simple-markdown';

const useStyles = makeStyles(theme => ({
  blocksWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    marginTop: theme.spacing(1),
  },
  sectionBlock: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  sectionMain: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  accessory: {
    flex: '0 0 auto',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  blockText: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  sectionFields: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, max-content)',
    gridColumnGap: theme.spacing(1),
    gridRowGap: theme.spacing(0.5),
  },
}));

const MessageBlocks = ({ message }) => {
  const classes = useStyles();

  const renderBlockElement = {
    button: (blockId, element) => (
      <BlockButton blockId={blockId} button={element} msgId={message._id} key={blockId} />
    ),
    slider: (blockId, element) => (
      <BlockSlider blockId={blockId} slider={element} msgId={message._id} key={blockId} />
    ),
  };

  const renderActionBlockElements = (blockId, elements) => (
    <div className={classes.actionsBlock}>
      {elements
        ? elements.map(element =>
            Object.prototype.hasOwnProperty.call(renderBlockElement, element.type)
              ? renderBlockElement[element.type](blockId, element)
              : null,
          )
        : null}
    </div>
  );

  // eslint-disable-next-line react/prop-types
  const Text = ({ text: { type, text } }) => {
    return type === 'mrkdwn' ? <ReactMarkdown source={text} /> : <p>{text}</p>;
  };

  const renderSectionBlock = block => {
    return (
      <div className={classes.sectionBlock}>
        <div className={classes.sectionMain}>
          {block.text ? (
            <div className={classes.blockText}>
              <Text text={block.text} />
            </div>
          ) : null}
          {!block.fields || (
            <div className={classes.sectionFields}>
              {block.fields.map((f, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <Text key={`${f}-${idx}`} text={f} />
              ))}
            </div>
          )}
        </div>
        {block.accessory ? (
          <div className={classes.accessory}>
            {renderActionBlockElements(block.block_id, [block.accessory])}
          </div>
        ) : null}
      </div>
    );
  };

  const renderBlock = {
    actions: b => renderActionBlockElements(b.block_id, b.elements),
    section: b => renderSectionBlock(b),
    divider: () => <hr />,
  };

  return message.blocks?.length ? (
    <div className={classes.blocksWrapper}>
      {message.blocks.map(block =>
        Object.prototype.hasOwnProperty.call(renderBlock, block.type)
          ? renderBlock[block.type](block)
          : null,
      )}
    </div>
  ) : null;
};

MessageBlocks.propTypes = {
  message: messageShape.isRequired,
};

export default MessageBlocks;
