import { any, node } from 'prop-types';
import React, { useState } from 'react';
import useOpenClose from 'utils/useOpenClose';

export const DrawerProvider = ({ children, context: { Provider } }) => {
  const [isOpen, openDrawer, closeDrawer] = useOpenClose();
  const [onSubmit, setOnSubmit] = useState(() => {});
  const [file, setFile] = useState(null);
  const [extraState, setExtraState] = useState({});
  const open = (newFile, newOnSubmit, newExtraState) => {
    setFile(newFile);
    setOnSubmit(() => newOnSubmit);
    setExtraState(newExtraState);
    openDrawer();
  };
  const close = () => {
    setFile(null);
    setOnSubmit(() => () => {});
    closeDrawer();
  };
  return (
    <Provider value={{ isOpen, open, close, file, setFile, onSubmit, ...extraState }}>
      {children}
    </Provider>
  );
};

DrawerProvider.propTypes = {
  children: node,
  context: any.isRequired,
};

DrawerProvider.defaultProps = {
  children: null,
};
