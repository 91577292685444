import { Button } from '@mui/material';
import { string } from 'prop-types';
import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'utils/i18n';
import editEmployeeDrawer from 'store/app/ui/drawers/employee/action';
import { useFeatureToggle } from '@flopflip/react-redux';
import isCompanyAdminSelector from 'store/selectors/isCompanyAdmin';

const EditProfileButton = ({ employeeId }) => {
  const integratedUsers = useFeatureToggle('integrated-users');
  const isCompanyAdmin = useSelector(isCompanyAdminSelector);

  const dispatch = useDispatch();
  const edit = useCallback(() => {
    dispatch(editEmployeeDrawer.open(employeeId));
  }, [dispatch, employeeId]);

  if (integratedUsers && !isCompanyAdmin) {
    return null;
  }

  return (
    <Button onClick={edit} variant="contained" color="primary">
      {I18n.t('ProfileDrawer.Edit profile')}
    </Button>
  );
};

EditProfileButton.propTypes = {
  employeeId: string.isRequired,
};

export default memo(EditProfileButton);
