/**
 * @flow
 */
import { Box, Button, Container, Grid, Switch, Typography } from '@mui/material';
import Control from 'components/@whistleblower-admin/pages/Portals/Control';
import LanguageControl from 'components/@whistleblower-admin/pages/Portals/LanguageControl';
import NameField from 'components/@whistleblower-admin/pages/Portals/NameField';
import SlugControl from 'components/@whistleblower-admin/pages/Portals/SlugControl';
import validateName from 'components/@whistleblower-admin/pages/Portals/validateName';
import validateSlug from 'components/@whistleblower-admin/pages/Portals/validateSlug';
import { useCanAdmin, useCanEdit } from 'components/@whistleblower-admin/WhistleblowerContext';
import DeleteDialog from 'components/common/DeleteDialog';
import FadeInOut from 'components/common/FadeInOut';
import FormikAutosuggest from 'components/formik/Autosuggest';
import FormikColorPicker from 'components/formik/ColorPicker';
import { Field, Formik } from 'formik';
import { createColor } from 'mui-color';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'utils/i18n';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeleteWbPortalMutation, useSaveWbPortalMutation } from 'store/app/entities/WbSlice';
import authCompany from 'store/selectors/authCompany';
import languages from 'utils/languages';
import useOpenClose from 'utils/useOpenClose';

let statusTimeout;
const CONFLICT = 409;

const PortalConfig = ({ data }: Object) => {
  const canEdit = useCanEdit('portals');
  const canAdmin = useCanAdmin('portals');
  const [isDeleteOpen, openDelete, closeDelete] = useOpenClose();
  const { slug } = useParams();
  const navigate = useNavigate();
  const [save] = useSaveWbPortalMutation();
  const [deletePortal] = useDeleteWbPortalMutation();
  const locale = useSelector(state => state.i18n.locale);
  const company = useSelector(authCompany);
  const [status, setStatus] = useState({});
  const setTempStatus = useCallback(newStatus => {
    if (statusTimeout) {
      clearTimeout(statusTimeout);
    }
    setStatus(newStatus);
    statusTimeout = setTimeout(() => setStatus({}), 3000);
  }, []);
  if (!data) {
    return null;
  }
  const initialDefaultLanguage = data.defaultLanguage || company.language || 'es';
  const handleDelete = () => {
    deletePortal(slug).then(() => {
      navigate('/wb-admin/portals', { replace: true });
    });
  };
  return (
    <Formik
      initialValues={{
        name: data.name || '',
        active: data.active ? ['on'] : [],
        defaultLanguage: initialDefaultLanguage,
        message: data.message || { [initialDefaultLanguage]: '' },
        slug: data.slug || '',
        topColor: createColor(data.topColor || '#999999'),
        buttonColor: createColor(data.buttonColor || '#666666'),
      }}
      validate={values => {
        let errors = {};
        if (!values.defaultLanguage) {
          errors.defaultLanguage = I18n.t('WhistleblowerPortalConfig.errors.Required', {
            field: I18n.t('WhistleblowerPortalConfig.atts.defaultLanguage'),
          });
        }
        errors = validateName(values, errors);
        errors = validateSlug(values, errors);
        return errors;
      }}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        let { active } = values;
        const { defaultLanguage, message, topColor, buttonColor, name } = values;
        active = active?.[0] === 'on';
        const { error } = await save({
          slug,
          body: {
            name,
            slug: values.slug,
            defaultLanguage,
            active,
            message,
            topColor: `#${topColor.hex}`,
            buttonColor: `#${buttonColor.hex}`,
          },
        });
        setSubmitting(false);
        if (error) {
          if (error.status === CONFLICT) {
            error.data?.key?.forEach(field => {
              setFieldError(
                field,
                I18n.t('WhistleblowerPortalConfig.errors.Duplicate', {
                  field: I18n.t(`WhistleblowerPortalConfig.atts.${field}`).toLowerCase(),
                }),
              );
            });
          }
        } else {
          setTempStatus({ id: 'success', text: I18n.t('WhistleblowerPortalConfig.saved') });
        }
      }}
    >
      {({ values, handleChange, handleSubmit, isSubmitting, isValid, submitCount }) => (
        <>
          <Box
            component="form"
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            <Container
              sx={{
                fontSize: 20,
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                width: '100%',
                overflowY: 'scroll',
                overflowX: 'hidden',
              }}
              maxWidth={false}
            >
              <Grid container spacing={2} sx={{ mt: 0, alignItems: 'baseline' }}>
                <Control
                  name="active"
                  control={
                    <Field
                      disabled={!canEdit}
                      name="active"
                      component={Switch}
                      id="active"
                      checked={values.active?.[0] === 'on'}
                      onChange={handleChange}
                    />
                  }
                />
                {values.active?.[0] === 'on' && (
                  <>
                    <Control
                      name="name"
                      control={
                        <Field disabled={!canEdit} name="name" id="name" component={NameField} />
                      }
                    />
                    <Control
                      name="defaultLanguage"
                      control={
                        <Field
                          disabled={!canEdit}
                          name="defaultLanguage"
                          component={FormikAutosuggest}
                          suggestions={languages[locale]}
                          id="defaultLanguage"
                        />
                      }
                    />
                    <Control
                      name="topColor"
                      control={
                        <Field
                          disabled={!canEdit}
                          name="topColor"
                          id="topColor"
                          component={FormikColorPicker}
                          disableAlpha
                        />
                      }
                    />
                    <Control
                      name="buttonColor"
                      control={
                        <Field
                          disabled={!canEdit}
                          name="buttonColor"
                          id="buttonColor"
                          component={FormikColorPicker}
                          disableAlpha
                        />
                      }
                    />
                    <SlugControl />
                    <LanguageControl />
                  </>
                )}
              </Grid>
            </Container>
            {canEdit && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: 2,
                  gap: 1,
                }}
              >
                <Button
                  type="button"
                  disabled={!canAdmin || isSubmitting}
                  variant="outlined"
                  color="error"
                  onClick={openDelete}
                >
                  {I18n.t('WhistleblowerPortalConfig.DeletePortal')}
                </Button>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <FadeInOut show={!!status.text || (!isValid && submitCount > 0)}>
                    <Typography color={!isValid && submitCount > 0 ? 'error' : `${status.id}.main`}>
                      {!isValid && submitCount > 0
                        ? I18n.t('WhistleblowerPortalConfig.ContainsErrors')
                        : status.text}
                    </Typography>
                  </FadeInOut>
                  <Button
                    type="submit"
                    disabled={isSubmitting || (!isValid && submitCount > 0)}
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    {I18n.t('WhistleblowerPortalConfig.Save')}
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
          <DeleteDialog
            message={I18n.t('WhistleblowerPortalConfig.ConfirmDelete', { name: values.name })}
            confirmText={I18n.t('WhistleblowerPortalConfig.DeletePortal')}
            typeBack={values.name}
            isOpen={isDeleteOpen}
            onConfirm={handleDelete}
            onClose={closeDelete}
          />
        </>
      )}
    </Formik>
  );
};

export default PortalConfig;
