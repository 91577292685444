import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from 'store/app/entities/baseQuery';

const initialState = {
  rules: [],
  rulesLoading: false,
  rulesError: null,
  rulesSaving: false,
  rulesSavingError: null,
};

const rulesEndpoints = build => ({
  saveRules: build.mutation({
    query: ({ teamId, body }) => ({
      url: `v1/team/${teamId}/rules`,
      method: 'PUT',
      body,
    }),
    transformResponse: response => response.data.rules,
    invalidatesTags: (result, error, { teamId }) => [{ type: 'TeamMembershipRules', teamId }],
  }),
  listRules: build.query({
    query: teamId => `v1/team/${teamId}/rules`,
    transformResponse: response => response.data.rules,
    providesTags: (result, error, { teamId }) => [{ type: 'TeamMembershipRules', teamId }],
  }),
});

const TeamMembershipRules = createApi({
  reducerPath: 'teamMembershipRules',
  baseQuery,
  tagTypes: ['TeamMembershipRules'],
  initialState,
  keepUnusedDataFor: 30,
  endpoints: rulesEndpoints,
});

export const {
  useListRulesQuery,
  useSaveRulesMutation,
  reducer: teamMembershipRulesReducer,
  middleware: teamMembershipRulesMiddleware,
} = TeamMembershipRules;
