import Employee from 'services/api/Employee';

export const LIST_ADMINS = 'LIST_ADMINS';
export const LIST_ADMINS_PENDING = 'LIST_ADMINS_PENDING';
export const LIST_ADMINS_FULFILLED = 'LIST_ADMINS_FULFILLED';

const list = () => ({
  type: LIST_ADMINS,
  payload: Employee.listAdmins(),
});

export default {
  list,
};
