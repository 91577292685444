import { connect } from 'react-redux';
import authUser from 'store/selectors/authUser';

const mapStateToProps = state => ({
  authUser: authUser(state),
});

const mapDispatchToProps = () => ({
  actions: {},
});

export default connect(mapStateToProps, mapDispatchToProps);
