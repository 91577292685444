import React from 'react';
import messageShape from 'shapes/message';
import Draft from './Draft';
import Read from './Read';

const Acknowledgement = props =>
  props.message.isDraft ? <Draft {...props} /> : <Read {...props} />;

Acknowledgement.propTypes = {
  message: messageShape.isRequired,
};

export default Acknowledgement;
