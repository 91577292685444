import React from 'react';
import { object, string, node } from 'prop-types';
import withStyles from '@mui/styles/withStyles';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
};

const TabContent = ({ classes, value, current, children }) => {
  if (value !== current) return null;
  return <div className={classes.root}>{children}</div>;
};

TabContent.propTypes = {
  classes: object.isRequired,
  value: string.isRequired,
  current: string.isRequired,
  children: node.isRequired,
};

export default withStyles(styles)(TabContent);
