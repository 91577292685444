/**
 * @flow
 */
import Box from '@mui/joy/Box';
import { Typography } from '@mui/material';
import React, { Node } from 'react';
import { Translate } from 'utils/i18n';

const styles = {
  box: {
    display: 'flex',
    flexDirection: 'column',
    borderColor: 'divider',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: '6px',
  },
  boxTitleWrapper: {
    p: '11px 16px 11px 16px',
    borderBottom: 1,
    borderColor: 'divider',
    display: 'flex',
    justifyContent: 'space-between',
  },
  boxTitle: {
    fontSize: 20,
    fontWeight: 500,
  },
};

type Props = { titleKey: String; titleRight?: Node; sx?: Object; children: Node };

const Section = ({ titleKey, titleRight, sx, children }: Props) => (
  <Box sx={{ ...styles.box, ...sx }}>
    <Box sx={styles.boxTitleWrapper}>
      <Typography sx={styles.boxTitle}>
        <Translate value={titleKey} />
      </Typography>
      {titleRight}
    </Box>
    {children}
  </Box>
);

Section.defaultProps = {
  titleRight: null,
  sx: {},
};

export default Section;
