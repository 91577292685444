/** @flow */
import EditTemplate from 'components/@home/drawers/HrBotDrawer/EditTemplate';
import HrBotConfig from 'components/@home/drawers/HrBotDrawer/HrBotConfig';
import HrBotEditTemplates from 'components/@home/drawers/HrBotDrawer/HrBotEditTemplates';
import HrBotSend from 'components/@home/drawers/HrBotDrawer/HrBotSend';
import React, { memo } from 'react';

const HrBotTab = ({ tab }: { tab: String }) => {
  switch (tab) {
    case 'send':
      return <HrBotSend />;
    case 'config':
      return <HrBotConfig />;
    case 'editTemplates':
      return <HrBotEditTemplates />;
    case 'editTemplate':
      return <EditTemplate />;
    default:
      return <HrBotEditTemplates />;
  }
};

export default memo(HrBotTab);
