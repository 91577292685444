import arrayToObject from 'utils/arrayToObject';
import merge from 'utils/merge';
import {
  CREATE_CHAT_FLOW_WEBHOOK_FULFILLED,
  DESTROY_CHAT_FLOW_WEBHOOK_FULFILLED,
  FIND_CHAT_FLOW_WEBHOOK_FULFILLED,
  FIND_CHAT_FLOW_WEBHOOK_PENDING,
  LOAD_CHAT_FLOW_WEBHOOKS_FULFILLED,
  LOAD_CHAT_FLOW_WEBHOOKS_PENDING,
  UPDATE_CHAT_FLOW_WEBHOOK_FULFILLED,
} from './action';

const chatFlowsReduceer = (
  state = { chatFlowWebhooks: {}, loading: false, loaded: false },
  { type, payload, meta },
) => {
  switch (type) {
    case LOAD_CHAT_FLOW_WEBHOOKS_FULFILLED: {
      const chatFlowWebhooks = payload.chatFlowWebhooks.map(c =>
        merge(state.chatFlowWebhooks[c._id], c),
      );

      return merge(state, {
        chatFlowWebhooks: arrayToObject(chatFlowWebhooks),
        loading: false,
        loaded: true,
      });
    }

    case FIND_CHAT_FLOW_WEBHOOK_PENDING: {
      return merge(state, { loading: true });
    }

    case CREATE_CHAT_FLOW_WEBHOOK_FULFILLED:
    case FIND_CHAT_FLOW_WEBHOOK_FULFILLED:
    case UPDATE_CHAT_FLOW_WEBHOOK_FULFILLED: {
      const chatFlowWebhook = merge({ ...state.chatFlowWebhooks[payload._id] }, payload);
      return merge(state, {
        loading: false,
        chatFlowWebhooks: { ...state.chatFlowWebhooks, [payload._id]: chatFlowWebhook },
      });
    }

    case DESTROY_CHAT_FLOW_WEBHOOK_FULFILLED: {
      const chatFlowWebhooks = { ...state.chatFlowWebhooks };
      delete chatFlowWebhooks[meta.WebhookId];
      return merge(state, { chatFlowWebhooks });
    }

    case LOAD_CHAT_FLOW_WEBHOOKS_PENDING:
      return merge(state, { loading: true, loaded: false });

    default:
      return state;
  }
};

export default chatFlowsReduceer;
