import React, { useEffect } from 'react';
import Cookie from 'services/Cookie';
import getSubdomain from 'utils/getSubdomain';
import redirect from 'utils/redirect';

// eslint-disable-next-line react/prop-types
const LoggedOutGate = ({ component: Component }) => {
  const subDomain = getSubdomain();

  useEffect(() => {
    const closed = Cookie.get('token') && subDomain;
    if (closed) {
      redirect('/home/messages', subDomain);
    }
  });

  return <Component />;
};

export default LoggedOutGate;
