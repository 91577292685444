/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import { object } from 'prop-types';
import { I18n } from 'utils/i18n';
import ChatIcon from 'assets/icons/chat.svg';
import DeleteMessageIcon from 'assets/icons/delete-message.svg';
import TypeRadio from '../../controls/TypeRadio';

const FormikTypeRadio = ({ field: { name }, form: { values, setFieldValue }, ...props }) => (
  <div {...props}>
    <TypeRadio
      selected={!values[name]}
      icon={<ChatIcon />}
      name={name}
      onChange={() => {
        setFieldValue(name, !values[name]);
      }}
      value="group"
      primary={I18n.t('ChannelDrawer.Switch.Open to chat')}
      secondary={I18n.t('ChannelDrawer.Switch.Employees can chat on the channel')}
    />
    <TypeRadio
      name={name}
      value="channel"
      icon={<DeleteMessageIcon />}
      onChange={() => {
        setFieldValue(name, !values[name]);
      }}
      selected={values[name]}
      primary={I18n.t('ChannelDrawer.Switch.Read-only')}
      secondary={I18n.t('ChannelDrawer.Switch.Employees can only read messages')}
    />
  </div>
);

FormikTypeRadio.propTypes = {
  field: object.isRequired,
  form: object.isRequired,
};

export default FormikTypeRadio;
