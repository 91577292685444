import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import employeesAction from 'store/app/entities/employees/action';

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  actions: {
    employees: bindActionCreators(employeesAction, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
