import { Drawer } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Loading from 'components/common/Loading';
import { bool, object } from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import channels from 'store/app/entities/channels/action';
import channelDrawer from 'store/app/ui/drawers/channel/action';
import currentChannel from 'store/selectors/currentChannel';
import employeesStatus from 'store/selectors/employeesStatus';
import CloseButton from '../CloseButton';
import ChannelForm from './Form';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    width: '100%',
    maxWidth: 'calc(100vw - 40px)',
    [theme.breakpoints.up('md')]: {
      width: 1100,
    },
  },
  container: {
    display: 'flex',
    flex: 1,
  },
  closeButtonContainer: {
    paddingTop: 20,
    paddingLeft: 20,
  },
}));

const ChannelFormComponent = ({ isEdit, channel }) => {
  const { isLoading } = useSelector(employeesStatus);
  if (isLoading || (isEdit && channel.loading)) {
    return <Loading />;
  }
  if (!isEdit || channel.loaded) {
    return <ChannelForm />;
  }
  return null;
};

ChannelFormComponent.propTypes = {
  isEdit: bool.isRequired,
  channel: object.isRequired,
};

const ChannelDrawer = () => {
  const isOpen = useSelector(state => state.drawers.channel.isOpen);
  const channel = useSelector(currentChannel);
  const isEdit = useSelector(state => state.drawers.channel.type === 'edit');
  const dispatch = useDispatch();

  const classes = useStyles();
  useEffect(() => {
    if (isEdit && isOpen && channel?._id && !channel.loading && !channel.loaded) {
      dispatch(channels.find(channel._id));
    }
  }, [isEdit, isOpen, channel, dispatch]);

  const handleClose = useCallback(() => {
    dispatch(channelDrawer.close());
  }, [dispatch]);

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose}>
      <div className={classes.root}>
        <div className={classes.closeButtonContainer}>
          <CloseButton onClick={handleClose} />
        </div>
        <div className={classes.container}>
          <ChannelFormComponent isEdit={isEdit} channel={channel} />
        </div>
      </div>
    </Drawer>
  );
};

export default ChannelDrawer;
