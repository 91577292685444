/**
 * @flow
 */
import {
  Box,
  Checkbox,
  FormControlLabel,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';
import { I18n } from 'utils/i18n';

type Props = {
  field: Object;
  form: Object;
};

const FormikPermissions = ({ field, form, ...props }: Props) => {
  const { name, onBlur } = field;
  const { setFieldValue, getFieldProps } = form;
  const { value } = getFieldProps(name);
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Table>
        <TableHead>
          <TableCell sx={{ width: '25%' }}>
            {I18n.t('WhistleblowerPortalConfig.permissionsTable.name')}
          </TableCell>
          <TableCell sx={{ width: '75%' }}>
            <Box
              sx={{
                width: '1005',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                position: 'relative',
                '& > span:nth-of-type(2)': {
                  position: 'absolute',
                  left: '50%',
                  transform: 'translate(-50%, 0)',
                },
              }}
            >
              {[1, 2, 3].map(level => (
                <span key={level}>{I18n.t(`WhistleblowerPortalConfig.levels.${level}`)}</span>
              ))}
            </Box>
          </TableCell>
        </TableHead>
        <TableBody>
          {['occurrences', 'portals', 'users'].map(permission => (
            <TableRow key={permission}>
              <TableCell>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...props}
                      {...field}
                      checked={value[permission] > 0}
                      onChange={e => {
                        setFieldValue(name, { ...value, [permission]: e.target.checked ? 1 : 0 });
                      }}
                      onBlur={onBlur}
                    />
                  }
                  label={I18n.t(`WhistleblowerPortalConfig.permissions.${permission}`)}
                />
              </TableCell>
              <TableCell sx={{ px: 5 }}>
                <Slider
                  sx={{
                    '&:not(.Mui-disabled) .MuiSlider-mark': {
                      backgroundColor: 'background.paper',
                      height: 4,
                      width: 4,
                      marginLeft: '-2px',
                      border: '2px solid',
                      borderColor: 'primary.light',
                      borderRadius: '50%',
                    },
                    '&:not(.Mui-disabled) .MuiSlider-markActive': {
                      height: 6,
                      width: 6,
                      marginLeft: '-3px',
                      opacity: 1,
                      backgroundColor: 'primary.main',
                      borderColor: 'primary.main',
                    },
                  }}
                  value={value[permission]}
                  step={1}
                  defaultValue={1}
                  min={1}
                  max={3}
                  marks
                  disabled={!(value[permission] > 0)}
                  onChange={(e, newValue) => {
                    setFieldValue(name, { ...value, [permission]: newValue });
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default FormikPermissions;
