export const OPEN_REMOTE_DRAWER = 'OPEN_REMOTE_DRAWER';
export const CLOSE_REMOTE_DRAWER = 'CLOSE_REMOTE_DRAWER';

const open = url => ({
  type: OPEN_REMOTE_DRAWER,
  payload: url,
});

const close = () => ({
  type: CLOSE_REMOTE_DRAWER,
});

export default { open, close };
