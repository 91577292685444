/* eslint-disable no-param-reassign */
import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from 'store/app/entities/baseQuery';

const initialState = {
  documents: {},
};

const documentFormatEndpoints = build => ({
  createDocumentFormat: build.mutation({
    query: body => ({
      url: 'v1/document-format',
      method: 'POST',
      body,
    }),
    invalidatesTags: () => [{ type: 'DocumentFormats', id: 'LIST' }],
  }),
  deleteDocumentFormat: build.mutation({
    query: id => ({
      url: `v1/document-format/${id}`,
      method: 'DELETE',
    }),
    invalidatesTags: (result, error, id) => [
      { type: 'DocumentFormats', id: `LIST` },
      { type: 'SharedFiles', id: 'LIST-deleted-' },
      { type: 'DocumentFormats', id },
    ],
  }),
  editDocumentFormat: build.mutation({
    query: ({ id, body }) => ({
      url: `v1/document-format/${id}`,
      method: 'PATCH',
      body,
    }),
    invalidatesTags: (result, error, queryArgs) => {
      return [
        {
          type: 'DocumentFormats',
          id: queryArgs.id,
        },
      ];
    },
  }),
  restoreDocumentFormat: build.mutation({
    query: id => ({
      url: `v1/document-format/undelete/${id}`,
      method: 'POST',
    }),
    invalidatesTags: (result, error, { id }) => [
      { type: 'SharedFiles', id: 'LIST-deleted-' },
      { type: 'DocumentFormats', id: 'LIST' },
      { type: 'DocumentFormats', id },
    ],
  }),
  listDocumentFormats: build.query({
    query: () => ({
      url: 'v1/document-format',
    }),
    transformResponse: response => response.data,
    providesTags: ({ documentFormats } = { documentFormats: [] }) => [
      ...(documentFormats || []).map(({ _id: id }) => ({
        type: 'DocumentFormats',
        id,
      })),
      {
        type: 'DocumentFormats',
        id: 'LIST',
      },
    ],
  }),
  listBulkMessaging: build.query({
    query: ({ id, search, page, perPage, sortBy, sort }) => ({
      url: `v1/document-format/${id}/bulk-messaging/${
        search ? `?search=${search}&` : '?'
      }page=${page}&perPage=${perPage}&sortBy=${sortBy}&sort=${sort}`,
    }),
    transformResponse: response => response.data,
    providesTags: ({ documentFormats } = { documentFormats: [] }, error, queryArgs) => [
      ...(documentFormats || []).map(({ _id: id }) => ({
        type: 'BulkMessaging',
        id,
      })),
      {
        type: 'BulkMessaging',
        id: `LIST-${queryArgs.id}`,
      },
    ],
  }),
  documentFormatSend: build.mutation({
    query: ({ id, body }) => ({
      url: `v1/document-format/${id}/send`,
      method: 'POST',
      body,
    }),
    invalidatesTags: (result, error, { id }) => {
      return [{ type: 'BulkMessaging', id: `LIST-${id}` }];
    },
  }),
  listReceivedDocumentFormats: build.query({
    query: () => ({
      url: 'v1/document-format/received',
    }),
    transformResponse: response => response.data,
    providesTags: ({ documentFormats } = { documentFormats: [] }) => [
      ...(documentFormats || []).map(({ _id: id }) => ({
        type: 'DocumentFormats',
        id,
      })),
      {
        type: 'DocumentFormats',
        id: 'LIST-received',
      },
    ],
  }),
  listReceivedFiles: build.query({
    query: ({ format, page, perPage, sortBy, sort, search }) => ({
      url: `v1/document-format/${format}/received/${
        search ? `?search=${search}&` : '?'
      }&page=${page}&perPage=${perPage}&sortBy=${sortBy}&sort=${sort}`,
    }),
    transformResponse: response => response.data,
    providesTags: ({ documentFormats } = { documentFormats: [] }, error, { format }) => [
      ...(documentFormats || []).map(({ _id: id }) => ({
        type: 'ReceivedFiles',
        id,
      })),
      {
        type: 'ReceivedFiles',
        id: `LIST-${format}`,
      },
    ],
  }),
});

const sharedFilesEndpoints = build => ({
  createSharedFile: build.mutation({
    query: body => ({
      url: 'v1/shared-file',
      method: 'POST',
      body,
    }),
    invalidatesTags: (result, error, queryArgs) => [
      { type: 'SharedFiles', id: `LIST-shared-${queryArgs.parent || ''}` },
    ],
  }),
  deleteSharedFile: build.mutation({
    query: id => ({
      url: `v1/shared-file/${id}`,
      method: 'DELETE',
    }),
    invalidatesTags: (result, error, id) => [
      { type: 'SharedFiles', id: `LIST-deleted-` },
      { type: 'SharedFiles', id: `LIST-shared-${id || ''}` },
      { type: 'SharedFiles', id: `LIST-shared-${result.parent || ''}` },
      { type: 'SharedFiles', id },
    ],
  }),
  restoreSharedFile: build.mutation({
    query: id => ({
      url: `v1/shared-file/undelete/${id}`,
      method: 'POST',
    }),
    invalidatesTags: (result, error, { id }) => [
      { type: 'SharedFiles', id: `LIST-deleted-${id || ''}` },
      { type: 'SharedFiles', id: `LIST-shared-${result.parent || ''}` },
      { type: 'SharedFiles', id: `LIST-shared-${id || ''}` },
      { type: 'SharedFiles', id },
    ],
  }),
  editSharedFile: build.mutation({
    query: ({ id, body }) => ({
      url: `v1/shared-file/${id}`,
      method: 'PATCH',
      body,
    }),
    invalidatesTags: (result, error, { id }) => [{ type: 'SharedFiles', id }],
  }),
  listSharedFiles: build.query({
    query: ({ folder, page, perPage, id, sortBy, sort, search }) => ({
      url: `v1/shared-file${id ? `/${id}` : ''}?${
        search ? `search=${search}` : ''
      }&folder=${folder}&page=${page}&perPage=${perPage}&sortBy=${sortBy}&sort=${sort}`,
    }),
    providesTags: ({ data } = { sharedFiles: [], totalCount: 0 }, err, queryArgs) => [
      ...(data.sharedFiles || []).map(({ _id: id }) => ({
        type: 'SharedFiles',
        id,
      })),
      {
        type: 'SharedFiles',
        id: queryArgs.id,
      },
      {
        type: 'SharedFiles',
        id: `LIST-${queryArgs.folder}-${queryArgs.id || ''}`,
      },
    ],
  }),
  sharedFilesCreateFolders: build.mutation({
    query: ({ id, body }) => ({
      url: `v1/shared-file/${id}/folders`,
      method: 'POST',
      body,
    }),
    invalidatesTags: (result, error, { folder, id }) => [
      {
        type: 'SharedFiles',
        id: `LIST-${folder}-${id || ''}`,
      },
      { type: 'SharedFiles', id },
    ],
  }),
  sendSharedFile: build.mutation({
    query: ({ id, sendTo, text, section }) => ({
      url: `v1/shared-file/${id}/send`,
      method: 'POST',
      body: { sendTo, text, section },
    }),
  }),
  sendReceived: build.mutation({
    query: ({ id, sendTo, text, section }) => ({
      url: `v1/received/${id}/send`,
      method: 'POST',
      body: { sendTo, text, section },
    }),
  }),
});

const SharedFiles = createApi({
  reducerPath: 'sharedFiles',
  baseQuery,
  tagTypes: ['SharedFiles', 'DocumentFormats', 'BulkMessaging', 'ReceivedFiles'],
  initialState,
  keepUnusedDataFor: 30,
  endpoints: build => ({ ...sharedFilesEndpoints(build), ...documentFormatEndpoints(build) }),
});

export const {
  useCreateDocumentFormatMutation,
  useCreateSharedFileMutation,
  useDeleteDocumentFormatMutation,
  useDeleteSharedFileMutation,
  useDocumentFormatSendMutation,
  useEditDocumentFormatMutation,
  useEditSharedFileMutation,
  useListBulkMessagingQuery,
  useListDocumentFormatsQuery,
  useListReceivedDocumentFormatsQuery,
  useListReceivedFilesQuery,
  useListSharedFilesQuery,
  useRestoreDocumentFormatMutation,
  useRestoreSharedFileMutation,
  useSendReceivedMutation,
  useSendSharedFileMutation,
  useSharedFilesCreateFoldersMutation,
  util: { invalidateTags: invalidateSharedFilesTags },
} = SharedFiles;

export const { reducer: sharedFilesReducer, middleware: sharedFilesMiddleware } = SharedFiles;
