import { I18n } from 'utils/i18n';

import {
  CHANGE_PAGE,
  CHANGE_ROWS_PER_PAGE,
  FILTER_EMPLOYEES,
  SELECT_ALL_EMPLOYEES,
  SELECT_EMPLOYEE,
  DESELECT_EMPLOYEE,
  SORT_EMPLOYEES,
} from './action';

const initialState = {
  order: 'asc',
  orderBy: 'name',
  filtered: [],
  selected: [],
  rows: [
    { key: 'name', disablePadding: false, label: I18n.t('ChannelDrawer.EmployeesTable.Name') },
    { key: 'code', disablePadding: false, label: I18n.t('ChannelDrawer.EmployeesTable.Code') },
    {
      key: 'department',
      disablePadding: false,
      label: I18n.t('ChannelDrawer.EmployeesTable.Department'),
    },
    {
      key: 'jobTitle',
      disablePadding: false,
      label: I18n.t('ChannelDrawer.EmployeesTable.Job Position'),
    },
    {
      key: 'location',
      disablePadding: false,
      label: I18n.t('ChannelDrawer.EmployeesTable.Location'),
    },
    {
      key: 'reportsTo',
      disablePadding: false,
      label: I18n.t('ChannelDrawer.EmployeesTable.Report to'),
    },
  ],
  rowsPerPage: 10,
  page: 0,
};

const employeesTableReducer = (stateRaw, { type, payload, meta }) => {
  const state = { ...initialState, ...stateRaw };

  switch (type) {
    case CHANGE_PAGE:
      return { ...state, page: payload };

    case CHANGE_ROWS_PER_PAGE:
      return { ...state, rowsPerPage: payload };

    case SELECT_ALL_EMPLOYEES: {
      return {
        ...state,
        page: 0,
        selected: payload,
      };
    }
    case SELECT_EMPLOYEE: {
      const selected = [...state.selected];
      selected.push(payload);

      return { ...state, selected };
    }

    case DESELECT_EMPLOYEE:
      return {
        ...state,
        selected: state.selected.filter(e => e !== payload),
      };

    case FILTER_EMPLOYEES: {
      const employees = meta.employees.filter(k => {
        const s = `${k.name} ${k.code} ${k.department} ${k.jobTitle} ${k.location} ${
          k.reportsTo
        } ${k.tags.join(' ')}`.toLowerCase();
        return !s.includes(payload.toLowerCase());
      });

      return { ...state, filtered: payload ? employees.map(e => e._id) : [] };
    }

    case SORT_EMPLOYEES: {
      return {
        ...state,
        order: payload.order,
        orderBy: payload.by,
      };
    }

    default:
      return state;
  }
};

export default employeesTableReducer;
