import { ListItem, SvgIcon, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ChevronRightIcon from 'assets/icons/chevron-right.svg';
import { string } from 'prop-types';
import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import linkPreviews from 'store/app/entities/linkPreviews/action';
import getLinkPreview from 'store/selectors/getLinkPreview';

const useStyles = makeStyles(theme => ({
  root: {
    height: '70px',
    alignItems: 'center',
    borderBottom: 'solid 1px #dde2eb',
  },
  link: {
    color: theme.palette.primary.main,
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  chevronIcon: {
    fontSize: 14,
  },
}));

const LinkItem = ({ link }) => {
  const classes = useStyles();
  const preview = useSelector(state => getLinkPreview(state, link));
  const dispatch = useDispatch();
  useEffect(() => {
    if (!preview) {
      dispatch(linkPreviews.fetchLinkPreview(link));
    }
  }, [preview]);
  return (
    <ListItem className={classes.root}>
      <a href={link} target="_blank" rel="noopener noreferrer" className={classes.textWrapper}>
        <Typography className={classes.nameText} component="div">
          <div>{preview?.title}</div>
          <div className={classes.link}>{link}</div>
        </Typography>
      </a>
      <SvgIcon className={classes.chevronIcon}>
        <ChevronRightIcon />
      </SvgIcon>
    </ListItem>
  );
};

LinkItem.propTypes = {
  link: string.isRequired,
};

export default memo(LinkItem);
