import {
  LIST_EMPLOYEES_FULFILLED,
  LIST_EMPLOYEES_PENDING,
  LIST_EMPLOYEES_REJECTED,
  TOGGLE_ACCEPT_COMPANY_TERMS_FULFILLED,
} from 'store/app/entities/companies/action';
import {
  BLOCK_EMPLOYEE_FULFILLED,
  CREATE_EMPLOYEE_FULFILLED,
  DESTROY_EMPLOYEE_FULFILLED,
  DESTROY_EMPLOYEE_PENDING,
  REMOVE_EMPLOYEE,
  UPDATE_EMPLOYEE_FULFILLED,
} from 'store/app/entities/employees/action';
import merge from 'utils/merge';

const initialState = {
  isLoading: false,
  isLoaded: false,
  loadedAt: null,
  error: false,
  employees: [],
  metadata: {},
};

const peopleReducer = (state = initialState, { type, payload, meta }) => {
  switch (type) {
    case LIST_EMPLOYEES_PENDING:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        error: false,
      };

    case LIST_EMPLOYEES_REJECTED:
      return { ...state, error: true, isLoaded: false, isLoading: false, loadedAt: null };

    case LIST_EMPLOYEES_FULFILLED: {
      const ret = payload.employees?.metadata
        ? { metadata: payload.employees.metadata, employees: payload.employees.data }
        : { metadata: { total: payload.employees.length, page: 0 }, employees: payload.employees };
      return {
        ...state,
        error: false,
        isLoaded: true,
        loadedAt: Date.now(),
        isLoading: false,
        ...ret,
      };
    }

    case REMOVE_EMPLOYEE:
    case DESTROY_EMPLOYEE_PENDING:
    case DESTROY_EMPLOYEE_FULFILLED: {
      const employees = state.employees.filter(e => e._id !== meta.employee_id);
      return { ...state, employees };
    }

    case BLOCK_EMPLOYEE_FULFILLED: {
      const employees = [...state.employees];
      const idx = employees.findIndex(e => e._id === meta.employeeId);
      if (idx >= 0) {
        employees[idx] = merge(employees[idx], { isActive: payload });
      }
      return { ...state, employees };
    }

    case CREATE_EMPLOYEE_FULFILLED:
    case UPDATE_EMPLOYEE_FULFILLED: {
      const employees = [...state.employees];
      let idx = employees.findIndex(e => e._id === payload._id);
      if (idx < 0) {
        idx = employees.length;
        employees.push({});
      }
      employees[idx] = merge(employees[idx], payload);
      return { ...state, employees };
    }

    case TOGGLE_ACCEPT_COMPANY_TERMS_FULFILLED: {
      const employees = [...state.employees];
      const idx = employees.findIndex(e => e._id === meta.employeeId);
      if (idx >= 0) {
        employees[idx] = merge(employees[idx], { isAcceptTerms: meta.accepted });
      }
      return { ...state, employees };
    }

    default:
      return state;
  }
};

export default peopleReducer;
