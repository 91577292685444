import { createApi } from '@reduxjs/toolkit/dist/query/react';
import baseQuery from 'store/app/entities/baseQuery';

const initialState = {
  whistleblowers: {},
};

const companyEndpoints = build => ({
  createWbPortal: build.mutation({
    query: body => ({
      url: `v1/wb/portal`,
      method: 'POST',
      body,
    }),
    invalidatesTags: (result, error, { slug }) => [{ type: 'Portal', slug }],
  }),
  saveWbPortal: build.mutation({
    query: ({ body, slug }) => ({
      url: `v1/wb/portal/${slug}`,
      method: 'PUT',
      body,
    }),
    invalidatesTags: (result, error, { slug }) => [{ type: 'Portal', slug }],
  }),
  deleteWbPortal: build.mutation({
    query: slug => ({
      url: `v1/wb/portal/${slug}`,
      method: 'DELETE',
    }),
    invalidatesTags: (result, error, slug) => [{ type: 'Portal', slug }],
  }),
  getWbPortals: build.query({
    query: () => ({
      url: 'v1/wb/portal',
    }),
    transformResponse: response => response.data.portals,
    providesTags: portals => [
      ...portals.map(({ slug }) => ({
        type: 'Portal',
        slug,
      })),
      { type: 'Portal', id: 'LIST' },
    ],
  }),
  getWbPortalBySlug: build.query({
    query: slug => ({
      url: `v1/wb/portal/${slug}`,
    }),
    transformResponse: response => response.data.portal,
    providesTags: portal => {
      return portal
        ? [
            {
              type: 'Portal',
              slug: portal.slug,
            },
          ]
        : [
            {
              type: 'Portal',
              slug: 'new',
            },
          ];
    },
  }),
  postOccurrence: build.mutation({
    query: ({ slug, body }) => ({
      url: `v1/wb/portal/${slug}/occurrence`,
      method: 'POST',
      body,
    }),
    invalidatesTags: () => [{ type: 'Occurrence', id: 'LIST' }],
  }),
  saveOccurrence: build.mutation({
    query: ({ id, body }) => ({
      url: `v1/wb/occurrence/${id}`,
      method: 'PUT',
      body,
    }),
    invalidatesTags: () => [
      {
        type: 'Occurrence',
        id: 'LIST',
      },
    ],
  }),
  getOccurrence: build.query({
    query: ({ slug, occurrence }) => ({
      url: `v1/wb/portal/${slug}/occurrence/${occurrence}`,
    }),
    transformResponse: response => response.data.occurrence,
    providesTags: occurrence =>
      occurrence
        ? [
            {
              type: 'Occurrence',
              id: occurrence._id,
            },
          ]
        : [
            {
              type: 'Occurrence',
              id: 'new',
            },
          ],
  }),
  getOccurrences: build.query({
    query: ({ body }) => ({
      url: `v1/wb/occurrence/list`,
      method: 'POST',
      body,
    }),
    transformResponse: response => response.data.occurrences,
    providesTags: ({ docs } = { docs: [] }) => [
      ...docs.map(doc => ({
        type: 'Occurrence',
        id: doc._id,
      })),
      {
        type: 'Occurrence',
        id: 'LIST',
      },
    ],
  }),
  getOccurrenceMessages: build.query({
    query: ({ id, body }) => ({
      url: `v1/wb/occurrence/${id}/message/list`,
      method: 'POST',
      body,
    }),
    transformResponse: response => response.data.messages,
    providesTags: messages => [
      ...messages.map(message => ({
        type: 'Message',
        id: message._id,
      })),
      {
        type: 'Message',
        id: 'LIST',
      },
    ],
  }),
  getOccurrenceMessagesPublic: build.query({
    query: ({ slug, occurrence }) => ({
      url: `v1/wb/portal/${slug}/occurrence/${occurrence}/message`,
      method: 'GET',
    }),
    transformResponse: response => response.data.messages,
    providesTags: messages => [
      ...messages.map(message => ({
        type: 'Message',
        id: message._id,
      })),
      {
        type: 'Message',
        id: 'LIST',
      },
    ],
  }),
  postOccurrenceMessage: build.mutation({
    query: ({ id, text, attachments }) => ({
      url: `v1/wb/occurrence/${id}/message`,
      method: 'POST',
      body: { text, attachments },
    }),
    invalidatesTags: () => [{ type: 'Message', id: 'LIST' }],
  }),
  setOccurrenceNotification: build.mutation({
    query: ({ slug, occurrence, email, locale }) => ({
      url: `v1/wb/portal/${slug}/occurrence/${occurrence}/notify`,
      method: 'PUT',
      body: { email, locale },
    }),
    invalidatesTags: () => [{ type: 'Message', id: 'LIST' }],
  }),
  postOccurrenceMessagePublic: build.mutation({
    query: ({ slug, occurrence, text, attachments }) => ({
      url: `v1/wb/portal/${slug}/occurrence/${occurrence}/message`,
      method: 'POST',
      body: { text, attachments },
    }),
    invalidatesTags: () => [{ type: 'Message', id: 'LIST' }],
  }),
  getMe: build.query({
    query: () => ({
      url: 'v1/wb/users/me',
    }),
    transformResponse: response => response.data.user,
    providesTags: user => [
      {
        type: 'User',
        id: user._id,
      },
    ],
  }),
  getUsers: build.query({
    query: () => ({
      url: 'v1/wb/users',
      method: 'GET',
    }),
    transformResponse: response => response.data.users,
    providesTags: users => [
      ...users.map(user => ({
        type: 'User',
        id: user._id,
      })),
      {
        type: 'User',
        id: 'LIST',
      },
    ],
  }),
  postUser: build.mutation({
    query: ({ permissions, employee }) => ({
      url: 'v1/wb/users',
      method: 'POST',
      body: { permissions, employee },
    }),
    invalidatesTags: () => [{ type: 'User', id: 'LIST' }],
  }),
  patchUser: build.mutation({
    query: ({ id, permissions }) => ({
      url: `v1/wb/users/${id}`,
      method: 'PATCH',
      body: { permissions },
    }),
    invalidatesTags: (result, error, { id }) => [
      {
        type: 'User',
        id,
      },
    ],
  }),
});

const Companies = createApi({
  reducerPath: 'wbSlice',
  baseQuery,
  tagTypes: ['Portal', 'Occurrence', 'Message', 'User'],
  initialState,
  keepUnusedDataFor: 30,
  endpoints: build => ({ ...companyEndpoints(build) }),
});

export const {
  useCreateWbPortalMutation,
  useDeleteWbPortalMutation,
  useGetMeQuery,
  useGetOccurrenceMessagesPublicQuery,
  useGetOccurrenceMessagesQuery,
  useGetOccurrenceQuery,
  useGetOccurrencesQuery,
  useGetUsersQuery,
  useGetWbPortalBySlugQuery,
  useGetWbPortalsQuery,
  usePatchUserMutation,
  usePostOccurrenceMessageMutation,
  usePostOccurrenceMessagePublicMutation,
  usePostOccurrenceMutation,
  usePostUserMutation,
  useSaveOccurrenceMutation,
  useSaveWbPortalMutation,
  useSetOccurrenceNotificationMutation,
} = Companies;

export const { reducer: wbReducer, middleware: wbMiddleware } = Companies;
