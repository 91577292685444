import { Breadcrumbs, Button, ButtonBase } from '@mui/material';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { SendDrawerContext } from 'components/@home/@documents/SentDocuments/SendDrawerContext';
import StepFiles from 'components/@home/@documents/SentDocuments/steps/StepFiles';
import StepMessage from 'components/@home/@documents/SentDocuments/steps/StepMessage';
import StepSend from 'components/@home/@documents/SentDocuments/steps/StepSend';
import { getFileName } from 'components/@home/drawers/HrBotDrawer/pdf-utils';
import throttle from 'lodash/throttle';
import moment from 'moment';
import { bool, func, object } from 'prop-types';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'utils/i18n';
import Attachments from 'services/api/Attachments';
import bulkMessagings from 'store/app/entities/bulkMessaging/action';
import { useDocumentFormatSendMutation } from 'store/app/entities/SharedFilesSlice';
import authUser from 'store/selectors/authUser';
import useActions from 'utils/useActions';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    overflow: 'hidden',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  error: {
    marginLeft: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    flexGrow: 1,
    display: 'flex',
    maxHeight: '100%',
    overflow: 'auto',
    flexDirection: 'column',
  },
  navigationButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    justifyContent: 'flex-end',
  },
  '@global': {
    '.textLayerDiv': {
      position: 'relative',
      display: 'block !important',
      '& span': {
        position: 'absolute',
        whiteSpace: 'pre',
        transformOrigin: '0% 0%',
      },
    },
  },
}));

const SendDrawerContent = ({ isOpenConfig, openConfig, closeConfig, currentFile }) => {
  const classes = useStyles();
  const [documentFormatSend] = useDocumentFormatSendMutation();
  const clearBulkMessagings = useActions(bulkMessagings.clear);
  const user = useSelector(authUser);
  const resets = useRef(0);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [parsingPct, setParsingPct] = useState(0);
  const [userItems, setUserItems] = useState({});
  const [error, setError] = useState(null);
  const [nextEnabled, setNextEnabled] = useState(false);
  const [uploadedPct, setUploadedPct] = useState('');
  const [files, setFiles] = useState(null);
  const [message, setMessage] = useState('');

  const { isOpen, file: format, close } = useContext(SendDrawerContext);

  useEffect(() => {
    if (!isOpen) {
      clearBulkMessagings();
      setUploadedPct('');
      setFiles(null);
      setError(null);
      setNextEnabled(false);
      setMessage('');
      setActiveStep(0);
      setCompleted({});
      setParsingPct(0);
      setUserItems({});
      closeConfig();
    }
  }, [closeConfig, isOpen]);

  useEffect(() => {
    setMessage(format?.defaultMessage || I18n.t('HrBot.defaultMessage'));
  }, [format?._id]);

  const steps = ['Files', 'Message', 'Send'].map(step => I18n.t(`HrBot.Steps.${step}`));

  useEffect(() => {
    if (format?.items) {
      format.items
        .filter(i => i.selector === 'user' && i.defaultValue)
        .forEach(item => {
          let date = moment();
          let value;
          if (item.defaultValue === 'previousMonth') {
            date = date.subtract(1, 'months');
          }
          if (item.name === 'month') {
            value = date.format('MM');
          } else if (item.name === 'year') {
            value = date.format('YYYY');
          } else if (item.name === 'day') {
            value = date.format('D');
          }
          setUserItems(ui => ({
            ...ui,
            [item.name]: value,
          }));
        });
    }
  }, [format]);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleSend = useCallback(async () => {
    setNextEnabled(false);
    const filesToSend = files.filter(
      f => !f.error && (format.sendBy === 'id' ? f.employee?.id : f.employee?.user),
    );
    const filesMap = filesToSend.reduce(
      (acc, file) => acc.set(file.file, [...(acc.get(file.file) || []), file]),
      new Map(),
    );
    const filesToUpload = Array.from(filesMap.entries());
    const uploaded = filesToUpload.map(() => 0);
    const updatePct = throttle(() => {
      const pct = Math.round(uploaded.reduce((acc, curr) => acc + curr, 0) / uploaded.length);
      setUploadedPct(pct);
      if (pct === 100) {
        setNextEnabled(true);
      }
    }, 200);
    const attachmentsArr = await Promise.all(
      filesToUpload.map(async ([file, origFiles], idx) => {
        const fileName = origFiles?.length === 1 ? origFiles[0].newFileName : null;
        const { _id: attachment } = await Attachments.upload(
          user.company,
          'docubot',
          file,
          null,
          progressEvent => {
            uploaded[idx] = (progressEvent.loaded * 100) / progressEvent.total;
            updatePct();
          },
          null,
          fileName,
        );
        return filesMap.get(file).map(f => ({
          ...f,
          attachment,
        }));
      }),
    );

    documentFormatSend({
      id: format._id,
      body: {
        text: message,
        files: attachmentsArr.flat().map(a => {
          return {
            attachment: a.attachment,
            ...(format.type === 'single-pdf' ? { pageNum: a.pageNum } : {}),
            ...(format.sendBy === 'id'
              ? { employeeId: a.employee.id }
              : { user: a.employee.user._id }),
            ...(a.dni ? { password: a.dni } : {}),
            fileName: getFileName({ ...a, ...userItems }, format),
          };
        }),
      },
    });
  }, [documentFormatSend, files, format, message, user._id, user.company, userItems]);

  const handleNext = async () => {
    if (isLastStep()) {
      await handleSend();
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleStep = step => () => {
    setActiveStep(step);
  };

  const handleComplete = async () => {
    setCompleted({ ...completed, [activeStep]: true });
    await handleNext();
  };

  const handleReset = () => {
    setFiles(null);
    setActiveStep(0);
    setCompleted({});
    setUploadedPct(0);
    setNextEnabled(false);
    resets.current += 1;
  };

  const getStepContent = step => {
    switch (step) {
      case 0:
        return (
          <StepFiles
            resets={resets}
            parsingPct={parsingPct}
            setParsingPct={setParsingPct}
            setError={setError}
            error={error}
            nextEnabled={nextEnabled}
            setNextEnabled={setNextEnabled}
            userItems={userItems}
            files={files}
            setFiles={setFiles}
            openConfig={openConfig}
            isOpenConfig={isOpenConfig}
            closeConfig={closeConfig}
            currentFile={currentFile}
            user={user}
          />
        );
      case 1:
        return (
          <StepMessage
            setError={setError}
            setNextEnabled={setNextEnabled}
            userItems={userItems}
            setUserItems={setUserItems}
            files={files}
            setMessage={setMessage}
            message={message}
          />
        );
      case 2:
        return (
          <StepSend
            message={message}
            uploadedPct={uploadedPct}
            setError={setError}
            setNextEnabled={setNextEnabled}
            userItems={userItems}
            files={files}
          />
        );
      default:
        return 'Unknown step';
    }
  };

  if (!isOpen || !format) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Breadcrumbs sx={{ mb: 3 }}>
        {(isOpenConfig && [
          <ButtonBase key="send" onClick={closeConfig}>
            <Typography variant="h6" sx={{ fontWeight: 'normal' }}>
              {I18n.t('HrBot.tabs.send.title', { name: format?.name })}
            </Typography>
          </ButtonBase>,
          <Typography key="config" variant="h6" component="h1">
            {I18n.t('HrBot.tabs.config.title')}
          </Typography>,
        ]) || (
          <Typography variant="h6" component="h1">
            {I18n.t('HrBot.tabs.send.title', { name: format?.name })}
          </Typography>
        )}
      </Breadcrumbs>

      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton onClick={handleStep(index)} completed={completed[index]}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>

      <div className={classes.instructions}>{getStepContent(activeStep)}</div>
      {!isOpenConfig && (
        <div className={classes.navigationButtons}>
          <Button
            disabled={activeStep === 0 && !files}
            onClick={allStepsCompleted() || activeStep === 0 ? handleReset : handleBack}
            className={classes.button}
          >
            {I18n.t(allStepsCompleted() || activeStep === 0 ? 'HrBot.Reset' : 'HrBot.Back')}
          </Button>
          {!allStepsCompleted() && (
            <Button
              disabled={!nextEnabled || !!error}
              variant="contained"
              color="primary"
              onClick={handleComplete}
            >
              {isLastStep() ? I18n.t('HrBot.Send') : I18n.t('HrBot.Next')}
            </Button>
          )}
          {allStepsCompleted() && (
            <Button disabled={!nextEnabled} variant="contained" color="primary" onClick={close}>
              {I18n.t('HrBot.Close')}
            </Button>
          )}
          {!!parsingPct && parsingPct < 100 && (
            <Typography variant="caption" className={classes.error}>
              {I18n.t('HrBot.parsing', { pct: parsingPct })}
            </Typography>
          )}
          {(!parsingPct || parsingPct === 100) && error && (
            <Typography color="error" variant="caption" className={classes.error}>
              {error}
            </Typography>
          )}
        </div>
      )}
    </div>
  );
};

SendDrawerContent.propTypes = {
  isOpenConfig: bool.isRequired,
  openConfig: func.isRequired,
  closeConfig: func.isRequired,
  currentFile: object,
};

SendDrawerContent.defaultProps = {
  currentFile: null,
};

export default SendDrawerContent;
