import React from 'react';
import { object } from 'prop-types';
import ChatSelect from '../../controls/ChatSelect';

const FormikChatSelect = ({ field, form, ...props }) => {
  const { name, value } = field;
  const { errors, submitCount, setFieldValue, setFieldTouched } = form;

  return (
    <ChatSelect
      {...props}
      onChange={setFieldValue}
      onBlur={setFieldTouched}
      value={value}
      name={name}
      error={!!(errors[name] && submitCount > 0)}
      helperText={submitCount > 0 && errors[name]}
    />
  );
};

FormikChatSelect.propTypes = {
  field: object.isRequired,
  form: object.isRequired,
};

export default FormikChatSelect;
