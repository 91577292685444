export const OPEN_INVITATION_DIALOG = 'OPEN_INVITATION_DIALOG';
export const CLOSE_INVITATION_DIALOG = 'CLOSE_INVITATION_DIALOG';

const open = options => ({
  type: OPEN_INVITATION_DIALOG,
  payload: options,
});

const close = () => ({
  type: CLOSE_INVITATION_DIALOG,
});

export default { open, close };
