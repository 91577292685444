import PhoneControl from 'components/controls/PhoneControl';
import { func, object } from 'prop-types';
import React from 'react';

const FormikPhone = ({ field, form, bindChange, ...props }) => {
  const { name, onBlur, onChange } = field;
  const { errors, values, submitCount, setFieldValue } = form;
  return (
    <PhoneControl
      fullWidth
      {...props}
      onChange={e => {
        const value = e ? `+${e}` : e;
        onChange(value);
        bindChange(value);
        return setFieldValue(name, value);
      }}
      onBlur={onBlur}
      value={values[name].substring(1)}
      name={name}
      error={!!(errors[name] && submitCount > 0)}
      helperText={submitCount > 0 && errors[name]}
    />
  );
};

FormikPhone.propTypes = {
  field: object.isRequired,
  form: object.isRequired,
  bindChange: func,
};

FormikPhone.defaultProps = {
  bindChange: () => {},
};

export default FormikPhone;
