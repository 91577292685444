/* eslint-disable react/jsx-props-no-spreading */
import withStyles from '@mui/styles/withStyles';
import Autosuggest from 'components/controls/Autosuggest';
import { array, object, string } from 'prop-types';
import React, { useEffect } from 'react';
import { I18n } from 'utils/i18n';
import connector from './connector';

const styles = () => ({
  root: {
    width: '100%',
  },
});

const Scope = ({
  classes,
  actions,
  scopes: { scopes, loading },
  value,
  filter,
  filterOut,
  ...props
}) => {
  useEffect(() => {
    if (!scopes.length) actions.config.scopes.loadScopes();
  }, []);

  let filteredScopes = filter
    ? scopes.filter(sc => value === sc || filter.includes(sc)) || scopes
    : scopes;

  filteredScopes = filterOut
    ? filteredScopes.filter(sc => value === sc || !filterOut.includes(sc))
    : filteredScopes;

  return (
    <div className={classes.root}>
      <Autosuggest
        {...props}
        fullWidth
        value={value}
        placeholder={loading ? I18n.t('Scope.Loading scopes...') : I18n.t('Scope.Choose scope')}
        suggestions={filteredScopes.map(scope => ({
          label: scope,
          value: scope,
        }))}
      />
    </div>
  );
};

Scope.propTypes = {
  classes: object.isRequired,
  actions: object.isRequired,
  value: string.isRequired,
  filter: array,
  filterOut: array,
  scopes: object.isRequired,
};

Scope.defaultProps = {
  filter: null,
  filterOut: null,
};

export default withStyles(styles)(connector(Scope));
