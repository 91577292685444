/**
 * @flow
 */
import { Box } from '@mui/material';
import React from 'react';

const styles = {
  root: {
    display: 'grid',
    margin: 0,
    gridTemplateColumns: 'fit-content(33%) auto',
    '& dd, & dt': {
      display: 'flex',
      alignItems: 'flex-start',
      margin: 0,
      padding: 2,
      borderTop: 'none',
      borderColor: 'secondary.ultraLight',
      '&:nth-of-type(n+2)': {
        borderTop: '1px solid',
        borderColor: 'secondary.ultraLight',
      },
    },
    '& dt': {
      paddingRight: 3,
    },
  },
};

const DefinitionList = ({ children, ...rest }: Object) => {
  return (
    <Box component="dl" {...rest} sx={{ ...styles.root, ...(rest.sx || {}) }}>
      {children}
    </Box>
  );
};
export default DefinitionList;
