import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import browseMediaDrawer from 'store/app/ui/drawers/media/action';
import currentChannel from 'store/selectors/currentChannel';

const mapStateToProps = state => ({
  type: state.drawers.media.type,
  isOpen: state.drawers.media.isOpen,
  channelName: currentChannel(state)?.name,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    mediaDrawer: bindActionCreators(browseMediaDrawer, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
