import { arrayOf, object, string } from 'prop-types';
import React, { memo } from 'react';
import employeeShape from 'shapes/employee';
import EmployeesTable from '../../controls/EmployeesTable';

const FormikEmployeesTable = ({ field, form, defaultRole, ...props }) => {
  const { name } = field;
  const { values, errors, setFieldValue, submitCount } = form;

  return (
    <EmployeesTable
      {...props}
      defaultRole={defaultRole}
      name={name}
      error={submitCount > 0 ? errors[name] : ''}
      value={values[name]}
      onChange={setFieldValue}
    />
  );
};

FormikEmployeesTable.propTypes = {
  field: object.isRequired,
  form: object.isRequired,
  defaultRole: string,
  employees: arrayOf(employeeShape).isRequired,
};

FormikEmployeesTable.defaultProps = {
  defaultRole: null,
};

const areEqual = (
  { form: { submitCount, values, errors }, field: { name }, defaultRole },
  props2,
) =>
  defaultRole === props2.defaultRole &&
  submitCount === props2.form.submitCount &&
  values[name] === props2.form.values[name] &&
  errors[name] === props2.form.errors[name];

export default memo(FormikEmployeesTable, areEqual);
