import { Button, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { func, number, object } from 'prop-types';
import React, { Component } from 'react';
import { I18n } from 'utils/i18n';

const styles = {
  sendAgain: {
    marginLeft: 15,
  },
  sendMessage: {
    padding: '0 15px',
    display: 'flex',
    alignItems: 'center',
  },
};

class SendToAllButton extends Component {
  state = {
    isSend: false,
  };

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  handleSend = () => {
    const { onSend } = this.props;
    this.setState({ isSend: true });
    this.timer = setTimeout(() => this.setState({ isSend: false }), 30000);
    onSend();
  };

  render() {
    const { classes, total } = this.props;
    const { isSend } = this.state;
    if (isSend) {
      return (
        <Typography className={classes.sendMessage}>
          {I18n.t('AnalyticsDrawer.Reminders are sent!')}
        </Typography>
      );
    }

    return (
      <Button
        color="secondary"
        variant="outlined"
        onClick={this.handleSend}
        className={classes.sendAgain}
      >
        {I18n.t('AnalyticsDrawer.Send again to %{count} pending', { count: total })}
      </Button>
    );
  }
}

SendToAllButton.propTypes = {
  classes: object.isRequired,
  onSend: func.isRequired,
  total: number.isRequired,
};

export default withStyles(styles)(SendToAllButton);
