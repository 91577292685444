/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { shape, bool, object, string } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Modal from '../Modal';
import connector from './connector';

const styles = {
  root: {},
  video: {
    width: '100%',
    maxWidth: window.innerWidth - 100,
    maxHeight: window.innerHeight - 100,
  },
};

const VideoModal = ({ classes, isOpen, actions, src }) => {
  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onClose={actions.videoModal.close}>
      <div className={classes.root}>
        <video className={classes.video} controls>
          <source src={src} type="video/mp4" />
        </video>
      </div>
    </Modal>
  );
};
VideoModal.propTypes = {
  classes: object.isRequired,
  src: string,
  isOpen: bool.isRequired,
  actions: shape({
    videoModal: object.isRequired,
  }).isRequired,
};

VideoModal.defaultProps = {
  src: null,
};

export default withStyles(styles)(connector(VideoModal));
