import withStyles from '@mui/styles/withStyles';
import Pagination from 'components/controls/Pagination';
import isEmpty from 'lodash/isEmpty';
import { arrayOf, shape, string, object, number } from 'prop-types';
import React, { Component } from 'react';
import LinkList from './LinkList';
import LinkItem from './LinkItem';
import connector from './connector';

const LINKS_PER_PAGE = 10;

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
});

class Links extends Component {
  state = {
    page: 0,
  };

  componentDidMount() {
    const { actions } = this.props;
    const { page } = this.state;

    actions.media.links(page + 1, LINKS_PER_PAGE);
  }

  changePage = ({ selected: page }) => {
    const { actions } = this.props;
    this.setState({ page });

    actions.media.links(page + 1, LINKS_PER_PAGE);
  };

  render() {
    const { classes, links, total } = this.props;
    const { page } = this.state;

    if (isEmpty(links)) return null;

    return (
      <div className={classes.root}>
        <LinkList>
          {links.map(link => (
            <LinkItem key={link._id} link={link} />
          ))}
        </LinkList>
        <Pagination perPage={LINKS_PER_PAGE} total={total} page={page} onChange={this.changePage} />
      </div>
    );
  }
}

Links.propTypes = {
  classes: object.isRequired,
  links: arrayOf(
    shape({
      description: string,
      favicon: string,
      title: string,
      url: string.isRequired,
    }),
  ).isRequired,
  total: number.isRequired,
  actions: object.isRequired,
};
export default withStyles(styles)(connector(Links));
