/** @flow */
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import LauncherAppLinkCard from 'components/@home/drawers/LauncherAppDrawer/LauncherAppLinkCard';
import React, { useMemo } from 'react';

type Props = {
  link: Object;
  overlay?: Boolean;
};

const LauncherAppSortableLinkCard = (props: Props) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: props.link.id,
  });

  const style = useMemo(
    () => ({
      cursor: props.overlay ? 'grabbing' : 'grab',
      transform: CSS.Transform.toString(transform),
      transition,
      opacity: isDragging ? 0.3 : 1,
    }),
    [isDragging, props.overlay, transform, transition],
  );

  return (
    <LauncherAppLinkCard ref={setNodeRef} sx={style} {...props} {...attributes} {...listeners} />
  );
};

LauncherAppSortableLinkCard.defaultProps = {
  overlay: false,
};

export default LauncherAppSortableLinkCard;
