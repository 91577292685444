import { createSelector } from 'reselect';
import currentChannel from 'store/selectors/currentChannel';

const getMedia = createSelector(
  state => currentChannel(state),
  state => state.media,
  (_, type) => type,
  (channel, media, type) => (media.channelId === channel?._id ? media[type] : null),
);

export default getMedia;
