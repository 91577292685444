import React, { Component } from 'react';
import Employee from 'services/api/Employee';
import { object, string } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import PhotoUpload from 'components/controls/PhotoUpload';
import connector from './connector';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  upload: {
    marginBottom: 0,
  },
};

class ChangeAvatar extends Component {
  uploadAvatar = async file => {
    const { actions, employee_id } = this.props;
    const employeeWithAvatar = await Employee.uploadAvatar(employee_id, file);

    actions.employees.modify(employee_id, {
      avatar: employeeWithAvatar.avatar,
    });
  };

  render() {
    const { className, classes, avatar } = this.props;
    return (
      <div className={classNames(classes.root, className)}>
        <PhotoUpload
          classes={{ root: classes.upload }}
          name="Edit photo"
          edit
          circle
          onChange={this.uploadAvatar}
          value={avatar}
        />
      </div>
    );
  }
}

ChangeAvatar.propTypes = {
  className: string,
  avatar: string,
  employee_id: string.isRequired,
  classes: object.isRequired,
  actions: object.isRequired,
};

ChangeAvatar.defaultProps = {
  className: '',
  avatar: '',
};

export default withStyles(styles)(connector(ChangeAvatar));
