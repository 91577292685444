import { func, object } from 'prop-types';
import React from 'react';
import AttachFile from '../../controls/AttachFile';

const FormikAttachFile = ({ field, form, onChange, ...props }) => {
  const { errors, values, submitCount, setFieldTouched, setFieldValue, isSubmitting } = form;

  const { name } = field;

  return (
    <AttachFile
      {...props}
      onChange={value => {
        setFieldValue(name, value);
        onChange(value);
      }}
      locale={values.locale}
      onBlur={setFieldTouched}
      value={values[name]}
      name={name}
      disabled={isSubmitting}
      error={!!(errors[name] && submitCount > 0)}
      helperText={submitCount > 0 && errors[name]}
      channel={values.channel}
      company={values.company}
    />
  );
};

FormikAttachFile.propTypes = {
  field: object.isRequired,
  form: object.isRequired,
  onChange: func.isRequired,
};

export default FormikAttachFile;
