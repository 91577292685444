import { IconButton } from '@mui/material';
import imageCompression from 'browser-image-compression';
import FileInput from 'components/@home/@messages/ChatPanel/SendForm/controls/AttachFile/FileInput';
import Loading from 'components/common/Loading';
import { useFormikContext } from 'formik';
import AttachmentIcon from 'mdi-react/AttachmentIcon';
import { string } from 'prop-types';
import React, { useRef, useState } from 'react';
import Attachments from 'services/api/Attachments';
import companyShape from 'shapes/company';
import parseFileName from 'utils/getFileExtension';
import connector from './connector';

const AttachmentButton = ({ field, authCompany, locale }) => {
  const fileInput = useRef();
  const { setFieldValue } = useFormikContext();
  const [progress, setProgress] = useState(undefined);

  const onUploadProgress = progressEvent => {
    let tpc = null;
    if (progressEvent?.lengthComputable) {
      tpc = (progressEvent.loaded * 100) / progressEvent.total;
    }
    setProgress(tpc);
  };

  const upload = async file => {
    const { type } = file;
    setProgress(0);
    let compressedFile;
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1600,
      useWebWorker: true,
      fileType: 'image/jpeg',
    };
    if (type.startsWith('image/') && type !== 'image/gif') {
      const { name: fileName } = parseFileName(file.name);
      const newFileName = `${fileName}.jpg`;
      const blob = await imageCompression(file, options);
      compressedFile = new File([blob], newFileName, {
        type: 'image/jpeg',
      });
    }
    const response = await Attachments.upload(
      authCompany._id,
      'none',
      compressedFile || file,
      locale,
      onUploadProgress,
    );
    setProgress(undefined);
    return response;
  };

  const openFiles = () => fileInput.current?.click();

  const clearInput = () => {
    if (fileInput.current) {
      fileInput.current.value = '';
    }
  };

  const handleError = () => {};

  const handleChange = async e => {
    try {
      const attachment = await upload(e.target.files[0]);
      clearInput();
      setFieldValue(`${field}.attachments[0]`, attachment);
      setFieldValue(`${field}.text`, 'attachments');
    } catch (error) {
      handleError(error);
    }

    clearInput();
  };
  return (
    <>
      <IconButton aria-label="attach" size="small" onClick={openFiles}>
        {typeof progress === 'undefined' ? (
          <AttachmentIcon />
        ) : (
          <Loading value={progress || null} debounce={0} size={20} />
        )}
      </IconButton>
      <FileInput style={{ visibility: 'hidden' }} inputRef={fileInput} onChange={handleChange} />
    </>
  );
};

AttachmentButton.propTypes = {
  authCompany: companyShape.isRequired,
  locale: string.isRequired,
  field: string.isRequired,
};

export default connector(AttachmentButton);
