import {
  LOAD_SETTINGS_PENDING,
  LOAD_SETTINGS_FULFILLED,
  LOAD_SETTINGS_REJECTED,
  UPDATE_SETTINGS_PENDING,
  UPDATE_SETTINGS_FULFILLED,
  UPDATE_SETTINGS_REJECTED,
} from './action';

const initialState = {
  timeZone: 'Europe/Berlin', // zone
  language: 'en', // shortName
  dnd: false,
  isOpenCommunication: true,
  workingDays: null,
  loaded: false,
  loading: false,
};

const settingsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_SETTINGS_PENDING:
      return {
        ...state,
        loading: true,
      };

    case LOAD_SETTINGS_FULFILLED:
      return {
        ...state,
        ...payload.settings,
        loaded: true,
        loading: false,
      };

    case UPDATE_SETTINGS_PENDING:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_SETTINGS_FULFILLED:
      return {
        ...state,
        ...payload.settings,
        loading: false,
      };

    case LOAD_SETTINGS_REJECTED:
    case UPDATE_SETTINGS_REJECTED:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default settingsReducer;
