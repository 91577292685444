import {
  Box,
  Button,
  Drawer,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import CloseButton from 'components/@home/drawers/CloseButton';
import { useConfirm } from 'components/common/GlobalConfirmDialog';
import OmmnioDataGrid from 'components/common/OmmnioDataGrid';
import React, { memo, useCallback, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'utils/i18n';
import populateChannels from 'store/selectors/populateChannels';
import removeAccentsQuickFilterFn from 'utils/removeAccentsQuickFilterFn';

export const SendDrawerContext = React.createContext({});

// eslint-disable-next-line react/prop-types
const MessageForm = memo(({ message, setMessage }) => {
  const handleChange = useCallback(event => {
    setMessage(event.target.value);
  }, []);
  return (
    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <Typography sx={{ my: 2 }}>{I18n.t('SendDrawer.AddMessage')}</Typography>
      <TextField value={message} onChange={handleChange} autoFocus multiline />
    </Box>
  );
});

// eslint-disable-next-line react/prop-types
const ChatsDataGrid = ({ chats, setSelectionModel, selectionModel }) => {
  const chatsColumns = [
    {
      field: 'name',
      flex: 1,
      editable: false,
      headerName: I18n.t('SendDrawer.ChatsTable.ChatName'),
      getApplyQuickFilterFn: removeAccentsQuickFilterFn,
    },
  ];
  return (
    <OmmnioDataGrid
      sx={{
        borderTop: 0,
        marginTop: 2,
        '& .MuiDataGrid-main': {
          marginTop: 2,
          borderTop: '1px solid',
          borderColor: 'divider',
        },
      }}
      getRowId={r => r._id}
      columns={chatsColumns}
      rows={chats}
      checkboxSelection
      showQuickFilter
      onRowSelectionModelChange={setSelectionModel}
      rowSelectionModel={selectionModel}
      disableEvenOdd
    />
  );
};
const SendDrawer = () => {
  const [text, setText] = useState('');
  const { file, isOpen, onSubmit, close } = useContext(SendDrawerContext);
  const [selectionModel, setSelectionModel] = useState([]);
  const chats = Object.values(useSelector(populateChannels));
  const { open: openConfirm, close: closeConfirm } = useConfirm();
  const [step, setStep] = useState(1);
  const handleClose = () => {
    setText('');
    setStep(1);
    setSelectionModel([]);
    close();
  };
  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      if (step === 1) {
        setStep(2);
      } else {
        openConfirm({
          message: I18n.t('SendDrawer.ConfirmSend', {
            count: selectionModel?.length || 0,
            name: file.name,
          }),
          confirmBtn: {
            text: I18n.t('SendDrawer.SendTo', { count: selectionModel?.length || 0 }),
            onClick: () => {
              closeConfirm();
              handleClose();
              onSubmit({
                file,
                sendTo: selectionModel,
                text,
              });
            },
          },
        });
      }
    },
    [step, openConfirm, selectionModel, file, closeConfirm, handleClose, onSubmit, text],
  );
  const renderStep = useCallback(
    () =>
      ({
        1: <MessageForm message={text} setMessage={setText} />,
        2: (
          <ChatsDataGrid
            chats={chats}
            setSelectionModel={setSelectionModel}
            selectionModel={selectionModel}
          />
        ),
      }[step]),
    [chats, text, selectionModel, step],
  );
  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose} onClick={e => e.stopPropagation()}>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          maxWidth: 'calc(100vw - 40px)',
          width: { xs: '100%', md: 1100 },
        }}
      >
        <Box sx={{ paddingTop: 2.5, paddingLeft: 2.5 }}>
          <CloseButton onClick={handleClose} />
        </Box>
        <Box
          sx={{ display: 'flex', flex: 1, minWidth: 500, flexDirection: 'column', padding: 2.5 }}
        >
          <Typography variant="h6" component="h1">
            {I18n.t('SendDrawer.Send to chats')}
          </Typography>
          <Stepper
            sx={{
              mt: 4,
              mb: 2,
            }}
            activeStep={step}
            alternativeLabel
          >
            <Step>
              <StepLabel>{I18n.t('SendDrawer.Steps.Caption')}</StepLabel>
            </Step>
            <Step>
              <StepLabel>{I18n.t('SendDrawer.Steps.Chats')}</StepLabel>
            </Step>
          </Stepper>
          <form
            style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
            onSubmit={handleSubmit}
          >
            {renderStep(step)}
            <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
              <Button
                onClick={handleClose}
                variant="outlined"
                color="secondary"
                sx={{ color: 'primary.ultraDark' }}
              >
                {I18n.t('SendDrawer.Cancel')}
              </Button>
              <Button
                disabled={step === 2 && !(selectionModel?.length > 0)}
                type="submit"
                variant="contained"
                color="primary"
              >
                {step === 1
                  ? I18n.t('SendDrawer.Next')
                  : I18n.t('SendDrawer.SendTo', { count: selectionModel?.length || 0 })}
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Drawer>
  );
};

export default SendDrawer;
