import { Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import PhoneControl from 'components/formik/PhoneControl';
import PhotoUpload from 'components/formik/PhotoUpload';
import TextField from 'components/formik/TextField';
import { connect, Field } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { object, string } from 'prop-types';
import React from 'react';
import { I18n } from 'utils/i18n';

const styles = {
  root: {
    paddingTop: 20,
  },
  inputs: {
    display: 'flex',
  },
  photo: {
    display: 'flex',
  },
  field: {
    margin: 5,
    '& textarea': {
      padding: 0,
    },
  },
};

const telChange = formik => async value => {
  if (isEmpty(value) || value.length < 9) {
    if (!formik.values.inviteDisabled) {
      formik.setFieldValue('inviteDisabled', true);
      formik.setFieldValue('invite', []);
    }
  } else if (formik.values.inviteDisabled) {
    formik.setFieldValue('inviteDisabled', false);
    formik.setFieldValue('invite', []);
  }
};

const PersonalDetailsControls = ({ classes, formik, country }) => {
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="caption" gutterBottom>
            {I18n.t('EmployeeDrawer.PERSONAL DETAILS')}
          </Typography>
        </Grid>
        <Grid item xs={2} className={classes.photo}>
          <Field component={PhotoUpload} circle name="avatar" />
        </Grid>
        <Grid item xs={10}>
          <div className={classes.inputs}>
            <Field
              component={TextField}
              className={classes.field}
              placeholder={`${I18n.t('EmployeeDrawer.First name')}${
                formik.values.allowAnonymousEmployees ? '' : '*'
              }`}
              name="firstName"
            />
            <Field
              component={TextField}
              className={classes.field}
              placeholder={`${I18n.t('EmployeeDrawer.Last name')}${
                formik.values.allowAnonymousEmployees ? '' : '*'
              }`}
              name="lastName"
            />
          </div>
          <div className={classes.inputs}>
            <Field
              country={country}
              component={PhoneControl}
              specialLabel={false}
              inputProps={{ type: 'tel' }}
              bindChange={telChange(formik)}
              formControlClass={classes.field}
              placeholder={I18n.t('EmployeeDrawer.Phone number')}
              name="phone"
            />
            <Field
              component={TextField}
              className={classes.field}
              placeholder="Email"
              name="email"
            />
          </div>
          <div className={classes.inputs}>
            <Field
              multiline
              component={TextField}
              className={classes.field}
              placeholder={I18n.t('EmployeeDrawer.Notes')}
              name="notes"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
PersonalDetailsControls.propTypes = {
  country: string,
  classes: object.isRequired,
  formik: object.isRequired,
};

PersonalDetailsControls.defaultProps = {
  country: 'es',
};

export default withStyles(styles)(connect(PersonalDetailsControls));
