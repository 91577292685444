import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import authCompany from 'store/selectors/authCompany';
import employeeDrawer from 'store/app/ui/drawers/employee/action';
import populateEmployees from 'store/selectors/populateEmployees';

const mapStateToProps = state => {
  const { employee_id: employeeId, isOpen } = state.drawers.employee;

  const allEmployees = populateEmployees(state);
  const employee = allEmployees[employeeId];

  return {
    isOpen,
    isEdit: !!employeeId,
    employeeId,
    employee,
    company: authCompany(state),
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    employeeDrawer: bindActionCreators(employeeDrawer, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
