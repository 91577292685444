import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import renderFolderCell from 'components/@home/@documents/renderFolderCell';
import OmmnioDataGrid from 'components/common/OmmnioDataGrid';
import { array, string } from 'prop-types';
import React, { useState } from 'react';
import { I18n } from 'utils/i18n';
import { useLocation } from 'react-router-dom';
import renderDateTimeCell from 'utils/renderDateTimeCell';
import connector from './connector';

const ChatsDocuments = ({ chats, folder }) => {
  const location = useLocation();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const columns = [
    {
      field: 'name',
      flex: 1,
      editable: false,
      renderCell: renderFolderCell(folder, { search: location.search }, 'chats'),
    },
    {
      field: 'updatedAt',
      type: 'string',
      flex: 1,
      editable: false,
      renderCell: renderDateTimeCell,
    },
  ];
  return (
    <Grid
      item
      sx={{
        padding: 2,
        paddingBottom: 0,
        flex: 1,
        overflow: 'scroll',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '100vh',
      }}
    >
      <Typography variant="h3">{I18n.t(`DocumentsScene.Folders.${folder}`)}</Typography>
      <OmmnioDataGrid
        loading={!chats?.length}
        sx={{ marginTop: 2 }}
        columns={columns}
        rows={chats || []}
        getRowId={r => r._id}
        updateHistory
        page={page}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        onPageChange={setPage}
        disableEvenOdd
        defaultSortModel={[{ field: 'updatedAt', sort: 'desc' }]}
      />
    </Grid>
  );
};

ChatsDocuments.propTypes = {
  chats: array.isRequired,
  folder: string.isRequired,
};

export default connector(ChatsDocuments);
