import React, { Component } from 'react';
import { Drawer } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { object, bool } from 'prop-types';

import CloseButton from '../CloseButton';
import SettingsForm from './SettingsForm';
import connector from './connector';

const styles = {
  root: {
    display: 'flex',
    height: '100%',
  },
  container: {
    display: 'flex',
  },
  closeButtonContainer: {
    paddingTop: 20,
    paddingLeft: 20,
  },
};

class SettingsDrawer extends Component {
  handleClose = () => {
    const { actions } = this.props;
    actions.drawers.settings.close();
  };

  render() {
    const { classes, isOpen } = this.props;

    return (
      <Drawer anchor="right" open={isOpen} onClose={this.handleClose}>
        <div className={classes.root}>
          <div className={classes.closeButtonContainer}>
            <CloseButton onClick={this.handleClose} />
          </div>
          <SettingsForm />
        </div>
      </Drawer>
    );
  }
}

SettingsDrawer.propTypes = {
  classes: object.isRequired,
  actions: object.isRequired,
  isOpen: bool.isRequired,
};

export default withStyles(styles)(connector(SettingsDrawer));
