/** @flow */
import DragIndicator from '@mui/icons-material/DragIndicator';
import { CardActionArea } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import React, { forwardRef } from 'react';

type Props = {
  link: Object;
  onClick?: Function;
  remove?: Function;
  overlay?: boolean;
  sx?: object;
};

const styles = {
  card: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: 140,
  },
  content: {
    p: 0,
  },
  actionArea: {
    p: 1,
    pt: 2,
    cursor: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: 1,
  },
  media: {
    height: 60,
    backgroundSize: 'contain',
  },
  handle: {
    position: 'absolute',
    margin: 'auto',
    top: 0,
    bottom: 0,
    left: 0,
    color: 'divider',
  },
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
    whiteSpace: 'nowrap',
  },
};

const LauncherAppLinkCard = forwardRef(({ link, remove, onClick, ...props }: Props, ref) => {
  const theme = useTheme();
  if (!link.text) {
    return null;
  }
  return (
    <Card ref={ref} {...props} sx={[styles.card, props.sx]}>
      <CardActionArea sx={styles.actionArea} onClick={onClick}>
        <CardMedia
          sx={styles.media}
          image={
            theme.palette.mode === 'dark'
              ? link.imageDark || link.imageLight
              : link.imageLight || link.imageDark
          }
          alt={link.text}
        />
        <CardContent sx={styles.content}>
          <Typography align="center" sx={styles.text}>
            {link.text}
          </Typography>
        </CardContent>
        <DragIndicator sx={styles.handle} />
      </CardActionArea>
    </Card>
  );
});

LauncherAppLinkCard.defaultProps = {
  remove: null,
  overlay: false,
  sx: {},
  onClick: () => {},
};

export default LauncherAppLinkCard;
