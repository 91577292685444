export const OPEN_LAUNCHER_APP_DRAWER = 'OPEN_LAUNCHER_APP_DRAWER';
export const CLOSE_LAUNCHER_APP_DRAWER = 'CLOSE_LAUNCHER_APP_DRAWER';

const open = data => ({
  type: OPEN_LAUNCHER_APP_DRAWER,
  payload: data,
});

const close = () => ({
  type: CLOSE_LAUNCHER_APP_DRAWER,
});

export default { open, close };
