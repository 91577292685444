/* eslint-disable */
import withStyles from '@mui/styles/withStyles';
import InfiniteScroll from 'components/common/InfiniteScroll';
import Loading from 'components/common/Loading';
import deferRender from 'defer-render-hoc';
import { bool, object, string } from 'prop-types';
import React, { createRef, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { MESSAGES_PER_PAGE } from 'services/api/Message';
import { useDebounceCallback } from 'utils/useDebounceCallback';
import connector from './connector';
import MessageGroup from './MessageGroup';

const styles = {
  root: {
    flexGrow: 1,
    padding: '15px 20px',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    overflowX: 'hidden',
    paddingBottom: 30,
  },
  loading: {
    height: 50,
  },
};

const MessagesList = ({ classes, channel, authUser, actions, hasFocus, selectedMessageId }) => {
  const [highlightedMessageId, setHighlightedMessageId] = useState(selectedMessageId);
  const messageList = useRef();
  const messages = channel.messages || [];
  const limitedMessages = channel.messagesTotalCount > MESSAGES_PER_PAGE;
  const [hasBelow, setHasBelow] = useState(true);
  const [hasAbove, setHasAbove] = useState(selectedMessageId && limitedMessages);
  const [messagesRef, setMessagesRefs] = useState(
    messages.reduce(
      (ret, message) => ({
        ...ret,
        [`message-${message._id}`]: createRef(),
      }),
      {},
    ),
  );

  useEffect(() => {
    setMessagesRefs(
      messages.reduce(
        (ret, message) => ({
          ...ret,
          [`message-${message._id}`]: messagesRef[`message-${message._id}`] || createRef(),
        }),
        {},
      ),
    );
  }, [messages]);

  const [loadingMoreMessages, setLoadingMoreMessages] = useState(null);
  const [scrollProps, setScrollProps] = useState({
    firstLoad: true,
    firstUnread: null,
    showUnreadLine: channel.messagesUnreadCount > 0,
  });

  const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  const prevChannel = usePrevious(channel);
  const prevHasFocus = usePrevious(hasFocus);
  const oldFirstUnread = usePrevious(scrollProps.firstUnread);
  const hasDifferentMessagesLength = prevChannel?.messages.length !== channel.messages.length;
  const hasChannelChanged = !!prevChannel && prevChannel._id !== channel._id;
  const prevLastMessageId = prevChannel?.lastMessage_id;
  const lastMessageId = channel.lastMessage_id;
  const prevSelectedMessageId = usePrevious(selectedMessageId);

  const computeFirstUnread = (channelChanged = false) => {
    const { messages } = channel;
    if ((!hasFocus || scrollProps.firstLoad) && (channelChanged || !scrollProps.firstUnread)) {
      const newFirstUnread =
        messages.find(m => !m.isMine && !m.isReadByMe) || scrollProps.firstUnread;
      let newScrollProps = {
        showUnreadLine: scrollProps.showUnreadLine || !!scrollProps.firstUnread || !!newFirstUnread,
        firstUnread: newFirstUnread,
        firstLoad: scrollProps.firstLoad,
      };
      setScrollProps(newScrollProps);
      return newScrollProps;
    }
    return scrollProps;
  };

  const scrollToMessage = (messageId, trial = 0) => {
    const node = messagesRef[`message-${messageId}`]?.current;
    if (messagesRef[`message-${messageId}`] && !node && trial < 10) {
      // Wait for messages to load
      setTimeout(() => scrollToMessage(messageId, trial + 1), 100);
    }
    if (!node) {
      return;
    }
    ReactDOM.findDOMNode(node).scrollIntoView({ block: 'center' });
    setHighlightedMessageId(messageId);
  };

  const scrollToSelectedMessage = (force = false) => {
    const element = messageList.current;
    if (element && (scrollProps.firstLoad || force)) {
      if (selectedMessageId) {
        scrollToMessage(selectedMessageId);
      } else {
        element.scrollTop = element.scrollHeight;
      }
    }
  };

  const debouncedRead = useDebounceCallback(actions.messages.read, 500);

  useEffect(() => {
    if (!loadingMoreMessages) {
      const newScrollProps = computeFirstUnread(hasChannelChanged);
      if (hasChannelChanged) {
        scrollToSelectedMessage(true);
        setScrollProps({
          firstLoad: true,
          firstUnread: newScrollProps.firstUnread,
          showUnreadLine: channel.messagesUnreadCount > 0,
        });
        if (channel.messagesUnreadCount > 0) {
          setTimeout(() => hasFocus && debouncedRead(channel._id), 3000);
        }
      } else if (selectedMessageId && prevSelectedMessageId !== selectedMessageId) {
        scrollToSelectedMessage(true);
      } else if (scrollProps.firstLoad && (hasDifferentMessagesLength || selectedMessageId)) {
        scrollToSelectedMessage(true);
        if (channel.messagesUnreadCount > 0) {
          setTimeout(() => hasFocus && debouncedRead(channel._id), 3000);
        }
      }
      if (channel.messagesUnreadCount > 0 && !prevHasFocus && hasFocus) {
        setTimeout(() => hasFocus && debouncedRead(channel._id), 3000);
      }
    }
  }, [
    hasFocus,
    loadingMoreMessages,
    lastMessageId,
    prevLastMessageId,
    hasChannelChanged,
    hasDifferentMessagesLength,
    selectedMessageId,
  ]);

  const jumpToOriginalPosition = oldScrollHeight => {
    const element = messageList.current;

    if (!element) return;

    element.scrollTop = element.scrollHeight - oldScrollHeight + element.scrollTop;
  };

  const loadMore = (messageId, loading) => {
    const lastMessage = channel?.messages?.[channel.messages.length - 1]?._id;
    const firstMessage = channel?.messages?.[0]?._id;
    const element = messageList.current;

    const oldScrollHeight = element.scrollHeight;
    const oldScrollTop = element.scrollTop;
    setLoadingMoreMessages(loading);
    if (scrollProps.firstLoad) {
      setScrollProps({ ...scrollProps, firstLoad: false });
    }

    const options = {
      [loading.loadBelow ? 'beforeId' : 'fromId']: messageId,
      sort: loading.loadBelow ? 'desc' : 'asc',
    };

    actions.messages.load(channel._id, options).then(() => {
      if (loading.loadBelow) {
        setHasBelow(limitedMessages);
      } else {
        setHasAbove(limitedMessages);
      }
      setLoadingMoreMessages(null);
      if (element.scrollTop === 0) {
        jumpToOriginalPosition(oldScrollHeight);
      }
    });
  };

  return (
    <div ref={messageList} className={classes.root}>
      <InfiniteScroll
        isReverse
        isLoading={loadingMoreMessages}
        initialLoad={false}
        firstLoadedId={channel?.messages?.[0]?._id}
        lastLoadedId={channel?.messages?.[channel.messages.length - 1]?._id}
        threshold={1}
        loadMore={loadMore}
        hasBelow={hasBelow}
        hasAbove={hasAbove}
        loader={
          <Loading
            classes={{ root: classes.loading }}
            size={40}
            key="loading"
            hide={!loadingMoreMessages}
          />
        }
      >
        {channel.messages.map(message => {
          let line = scrollProps.showUnreadLine && scrollProps.firstUnread?._id === message._id;
          return (
            <MessageGroup
              msgRef={messagesRef[`message-${message._id}`]}
              key={`${message._id}-${line}`}
              message={message}
              line={line}
              isSelected={message._id === highlightedMessageId}
              scrollToMessage={scrollToMessage}
            />
          );
        })}
        <div style={{ height: 25 }} />
      </InfiniteScroll>
    </div>
  );
};

MessagesList.propTypes = {
  classes: object.isRequired,
  channel: object.isRequired,
  actions: object.isRequired,
  hasFocus: bool.isRequired,
  selectedMessageId: string,
};

MessagesList.defaultProps = {
  selectedMessageId: null,
};

export default withStyles(styles)(connector(deferRender(MessagesList)));
