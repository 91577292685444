import { useEffect, useState } from 'react';
import getFileUrl from 'utils/getFileUrl';

const useFileUrl = value => {
  const [url, setUrl] = useState(null);

  useEffect(() => {
    getFileUrl(value)
      .then(newUrl => setUrl(newUrl))
      .catch(e => {
        console.log(e);
        setUrl(null);
      });
  }, [value]);

  return url;
};

export default useFileUrl;
