import React from 'react';
import { node, string } from 'prop-types';

const Wrapper = ({ marginTop, children }) => (
<div style={{ marginTop }}>
    {children}
  </div>
);Wrapper.propTypes = {
  marginTop: string.isRequired,
  children: node.isRequired,
};

export default Wrapper;
