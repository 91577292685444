export const OPEN_FORM_ANALYTICS_DRAWER = 'OPEN_FORM_ANALYTICS_DRAWER';
export const CLOSE_FORM_ANALYTICS_DRAWER = 'CLOSE_FORM_ANALYTICS_DRAWER';

const open = payload => ({
  type: OPEN_FORM_ANALYTICS_DRAWER,
  payload,
});

const close = () => ({
  type: CLOSE_FORM_ANALYTICS_DRAWER,
});

export default { open, close };
