import ChatFlowIntent from 'services/api/ChatFlowIntent';

export const FIND_CHAT_FLOW_INTENT = 'FIND_CHAT_FLOW_INTENT';
export const FIND_CHAT_FLOW_INTENT_PENDING = 'FIND_CHAT_FLOW_INTENT_PENDING';
export const FIND_CHAT_FLOW_INTENT_FULFILLED = 'FIND_CHAT_FLOW_INTENT_FULFILLED';

export const LOAD_CHAT_FLOW_INTENTS = 'LOAD_CHAT_FLOW_INTENTS';
export const LOAD_CHAT_FLOW_INTENTS_PENDING = 'LOAD_CHAT_FLOW_INTENTS_PENDING';
export const LOAD_CHAT_FLOW_INTENTS_FULFILLED = 'LOAD_CHAT_FLOW_INTENTS_FULFILLED';

export const CREATE_CHAT_FLOW_INTENT = 'CREATE_CHAT_FLOW_INTENT';
export const CREATE_CHAT_FLOW_INTENT_PENDING = 'CREATE_CHAT_FLOW_INTENT_PENDING';
export const CREATE_CHAT_FLOW_INTENT_FULFILLED = 'CREATE_CHAT_FLOW_INTENT_FULFILLED';

export const UPDATE_CHAT_FLOW_INTENT = 'UPDATE_CHAT_FLOW_INTENT';
export const UPDATE_CHAT_FLOW_INTENT_PENDING = 'UPDATE_CHAT_FLOW_INTENT_PENDING';
export const UPDATE_CHAT_FLOW_INTENT_FULFILLED = 'UPDATE_CHAT_FLOW_INTENT_FULFILLED';

export const DESTROY_CHAT_FLOW_INTENT = 'DESTROY_CHAT_FLOW_INTENT';
export const DESTROY_CHAT_FLOW_INTENT_PENDING = 'DESTROY_CHAT_FLOW_INTENT_PENDING';
export const DESTROY_CHAT_FLOW_INTENT_FULFILLED = 'DESTROY_CHAT_FLOW_INTENT_FULFILLED';

const load = () => ({
  type: LOAD_CHAT_FLOW_INTENTS,
  payload: ChatFlowIntent.list(),
});

const create = form => ({
  type: CREATE_CHAT_FLOW_INTENT,
  payload: ChatFlowIntent.create(form),
});

const update = (id, form) => ({
  type: UPDATE_CHAT_FLOW_INTENT,
  payload: ChatFlowIntent.update(id, form),
  meta: { intentId: id },
});

const find = id => ({
  type: FIND_CHAT_FLOW_INTENT,
  payload: ChatFlowIntent.find(id),
  meta: { intentId: id },
});

const destroy = id => ({
  type: DESTROY_CHAT_FLOW_INTENT,
  payload: ChatFlowIntent.destroy(id),
  meta: { intentId: id },
});

export default {
  create,
  destroy,
  find,
  load,
  update,
};
