import { useEffect, useState } from 'react';
import formatPhoneNumber from 'utils/formatPhoneNumber';

const FormatPhoneNumber = ({ number }) => {
  const [formattedNumber, setFormattedNumber] = useState('');

  useEffect(() => {
    formatPhoneNumber(number).then(setFormattedNumber);
  }, [number]);

  return formattedNumber;
};

export default FormatPhoneNumber;
