import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from 'store/app/entities/baseQuery';

const initialState = {};

const endpoints = build => ({
  createUser: build.mutation({
    query: body => ({
      url: 'v1/user',
      method: 'POST',
      body,
    }),
    invalidatesTags: () => [{ type: 'Users', id: 'LIST' }],
  }),
  deleteUser: build.mutation({
    query: id => ({
      url: `v1/user/${id}`,
      method: 'DELETE',
    }),
    invalidatesTags: (result, error, id) => [
      { type: 'Users', id: 'LIST' },
      { type: 'Users', id },
    ],
  }),
  editUser: build.mutation({
    query: ({ _id, body }) => ({
      url: `v1/user/${_id}`,
      method: 'PATCH',
      body,
    }),
    invalidatesTags: (result, error, { id }) => [{ type: 'Users', id }],
  }),
  listUsers: build.query({
    query: ({ page = '', perPage = '', sortBy = '', sort = '', search = '' }) => ({
      url: `v1/user?${search ? `search=${encodeURIComponent(search)}&searchBy=phone` : ''}&${
        page ? `page=${page}` : ''
      }&perPage=${perPage}&${sort ? `sortBy=${sortBy}&sort=${sort}` : ''}`,
    }),
    transformResponse: response => response.data,
    providesTags: ({ users = [] }) => [
      ...(users || []).map(({ _id: id }) => ({
        type: 'Users',
        id,
      })),
      {
        type: 'Users',
        id: 'LIST',
      },
    ],
  }),
});

const UsersSlice = createApi({
  reducerPath: 'usersReducer',
  baseQuery,
  tagTypes: ['Companies', 'Users'],
  initialState,
  keepUnusedDataFor: 30,
  endpoints,
});

export const {
  useCreateUserMutation,
  useDeleteUserMutation,
  useEditUserMutation,
  useListUsersQuery,
  endpoints: { me: getMe },
  util: { invalidateTags: invalidateUserTags },
} = UsersSlice;

export const { middleware: usersMiddleware, reducer: usersReducer } = UsersSlice;
