import { Drawer, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Loading from 'components/common/Loading';
import isEmpty from 'lodash/isEmpty';
import { bool, object } from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'utils/i18n';
import Attachments from 'services/api/Attachments';
import authCompanyId from 'store/selectors/authCompanyId';
import CloseButton from '../CloseButton';
import connector from './connector';
import DropzoneBox from './DropzoneBox';
import Form from './Form';

const styles = theme => ({
  root: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    width: 1100,
    maxWidth: 'calc(100vw - 40px)',
  },
  container: {
    flexGrow: 1,
    padding: 20,
    maxWidth: 'calc(100% - 60px)',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    position: 'relative',
  },
  closeButtonContainer: {
    paddingTop: 20,
    paddingLeft: 20,
  },
  dropzoneWrapper: {
    marginTop: theme.spacing(8),
    backgroundColor: theme.palette.primary.ultraLight,
    borderRadius: 4,
    width: '100%',
  },
});

const AcknowledgeCreateDrawer = ({ classes, isOpen, actions, channel, tempAttachment }) => {
  const companyId = useSelector(authCompanyId);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleClose = () => {
    actions.drawers.acknowledgeCreate.close();
    setLoading(false);
    setError(false);
  };

  const upload = async file => {
    try {
      const attachment = await Attachments.upload(companyId, channel._id, file);
      actions.drawers.acknowledgeCreate.saveTemporarytempAttachment(attachment);
    } catch (e) {
      console.log(e);
      setError(true);
    }
  };

  const onDrop = async files => {
    if (isEmpty(files)) return;
    setLoading(true);

    await upload(files[0]);

    setLoading(false);
  };

  const renderContent = () => {
    if (loading) return <Loading />;

    return tempAttachment ? (
      <Form onClose={actions.drawers.acknowledgeCreate.close} />
    ) : (
      <div className={classes.dropzoneWrapper}>
        <DropzoneBox onDrop={onDrop} error={error} loading={loading} />
      </div>
    );
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose}>
      <div className={classes.root}>
        <div className={classes.closeButtonContainer}>
          <CloseButton onClick={handleClose} />
        </div>
        <div className={classes.container}>
          <Typography className={classes.title} variant="h5" component="h1">
            {I18n.t('AcknowledgementCreate.Add acknowledge message')}
          </Typography>
          {renderContent()}
        </div>
      </div>
    </Drawer>
  );
};

AcknowledgeCreateDrawer.propTypes = {
  classes: object.isRequired,
  actions: object.isRequired,
  isOpen: bool.isRequired,
  channel: object.isRequired,
  tempAttachment: object,
};

AcknowledgeCreateDrawer.defaultProps = {
  tempAttachment: null,
};

export default withStyles(styles)(connector(AcknowledgeCreateDrawer));
