/* eslint-disable react/prop-types */
import { Paper, TextField, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useTheme } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { arrayOf, func, object, string } from 'prop-types';
import React from 'react';
import Select from 'react-select';

const styles = theme => ({
  root: {},
  input: {
    position: 'relative',
    display: 'flex',
    padding: 2,
    minHeight: 35,
    color: theme.palette.text.primary,
  },
  placeholder: {
    position: 'absolute',
    left: 10,
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: '-0.1px',
    color: theme.palette.text.secondary,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(0.5),
    left: 0,
    right: 0,
  },
  menu: {
    zIndex: 99,
  },
  selectInput: {},
});

const inputComponent = ({ inputRef, ...props }) => <div ref={inputRef} {...props} />;

function Placeholder(props) {
  return (
    <Typography className={props.selectProps.classes.placeholder} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

const Control = ({ selectProps, children, innerProps }) => {
  return (
    <TextField
      fullWidth
      error={selectProps.error}
      helperText={selectProps.helperText}
      InputProps={{
        inputComponent,
        inputProps: { className: selectProps.classes.input, children, ...innerProps },
      }}
    />
  );
};

const label = suggestion => `${suggestion.name} (${suggestion.code}) ${suggestion.dial_code}`;

const Menu = ({ selectProps, innerProps, children }) => (
  <Paper square className={selectProps.classes.paper} {...innerProps}>
    {children}
  </Paper>
);

const getOptions = suggestions =>
  suggestions.map(suggestion => ({
    label: label(suggestion),
    value: suggestion.dial_code,
  }));

const Autosuggest = ({ classes, onChange, value, suggestions, ...props }) => {
  const labelValue = suggestions.find(s => s.dial_code === value);
  const theme = useTheme();
  return (
    <Select
      classes={classes}
      className={classes.root}
      options={getOptions(suggestions)}
      components={{ Control, Menu, Placeholder }}
      value={value && { label: labelValue && label(labelValue), value }}
      onChange={onChange}
      {...props}
      theme={th => ({
        ...th,
        colors: {
          ...th.colors,
          primary:
            theme.palette.mode === 'dark'
              ? theme.palette.primary.lighter
              : theme.palette.primary.main,
          primary75: theme.palette.primary.lighter,
          primary50: theme.palette.primary.extraLight,
          primary25: theme.palette.primary.ultraLight,
          neutral10: alpha(theme.palette.text.primary, 0.1),
          neutral15: alpha(theme.palette.text.primary, 0.15),
          neutral20: alpha(theme.palette.text.primary, 0.2),
          neutral30: alpha(theme.palette.text.primary, 0.3),
          neutral40: alpha(theme.palette.text.primary, 0.4),
          neutral50: alpha(theme.palette.text.primary, 0.5),
          neutral60: alpha(theme.palette.text.primary, 0.6),
          neutral70: alpha(theme.palette.text.primary, 0.7),
          neutral80: alpha(theme.palette.text.primary, 0.8),
          neutral90: alpha(theme.palette.text.primary, 0.9),
        },
      })}
    />
  );
};

Autosuggest.propTypes = {
  classes: object.isRequired,
  suggestions: arrayOf(object).isRequired,
  value: string.isRequired,
  onChange: func.isRequired,
};

export default withStyles(styles)(Autosuggest);
