import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import currentChannel from 'store/selectors/currentChannel';
import authCompany from 'store/selectors/authCompany';
import authEmployee from 'store/selectors/authEmployee';
import messages from 'store/app/entities/messages/action';
import authUser from 'store/selectors/authUser';

const mapStateToProps = state => ({
  message: state.modals.acknowledgment.preview.message,
  tempAttachment: state.modals.acknowledgment.create.tempAttachment,
  authCompany: authCompany(state),
  authEmployee: authEmployee(state),
  authUser: authUser(state),
  channel: currentChannel(state),
});

const mapDispatchToProps = dispatch => ({
  actions: {
    messages: bindActionCreators(messages, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
