import Config from 'services/api/Config';

export const LOAD_SCOPES = 'LOAD_SCOPES';
export const LOAD_SCOPES_PENDING = 'LOAD_SCOPES_PENDING';
export const LOAD_SCOPES_FULFILLED = 'LOAD_SCOPES_FULFILLED';
export const LOAD_SCOPES_REJECTED = 'LOAD_SCOPES_REJECTED';

const loadScopes = () => ({
  type: LOAD_SCOPES,
  payload: Config.getScopes(),
});

export default { loadScopes };
