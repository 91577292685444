/**
 * @flow
 */
import { TableCell, TableSortLabel, Tooltip } from '@mui/material';
import React from 'react';

type Order = { by: string; isReverse: boolean };

type Props = {
  order: Order;
  setOrder: (newOrder: (old: Order) => Order) => void;
  name: string;
  label: string;
  padding?: string;
};

const TitleCell = ({ order, setOrder, name, label, padding }: Props) => {
  const handleClick = () =>
    setOrder(old => ({
      by: name,
      isReverse: old.by === name ? !old.isReverse : false,
    }));
  return (
    <TableCell padding={padding}>
      <Tooltip title="Sort" enterDelay={300}>
        <TableSortLabel
          active={order.by === name}
          onClick={handleClick}
          direction={order.isReverse ? 'desc' : 'asc'}
        >
          {label}
        </TableSortLabel>
      </Tooltip>
    </TableCell>
  );
};

TitleCell.defaultProps = {
  padding: 'normal',
};

export default TitleCell;
