/**
 * @flow
 */
import { Typography } from '@mui/material';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { CSSTransition } from 'react-transition-group'; // ES6

const useStyles = makeStyles(theme => ({
  root: {
    height: 40,
    backgroundColor: theme.palette.primary.ultraLight,
    borderRadius: 5,
    flexGrow: 1,
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '5px 0',
  },
  bar: {
    position: 'absolute',
    width: 0,
    height: '100%',
    background: theme.palette.primary.light4,
    transition: 'width 0.5s ease-out 0.25s',
    borderRadius: 2,
  },
  barLabel: {
    position: 'relative',
    padding: 10,
  },
  appear: ({ barWidth }) => ({
    width: `${barWidth}%`,
  }),
}));

type Props = {
  option: Object,
};

const AnswerBar = ({ option: { label, pct } }: Props) => {
  const classes = useStyles({ barWidth: pct });
  return (
    <div className={classes.root}>
      <CSSTransition
        in
        appear
        classNames={{
          appearDone: classes.appear,
        }}
      >
        <div className={classes.bar} />
      </CSSTransition>
      <Typography className={classes.barLabel}>{label}</Typography>
    </div>
  );
};

export default AnswerBar;
