export const OPEN_ANALYTICS_DRAWER = 'OPEN_ANALYTICS_DRAWER';
export const CLOSE_ANALYTICS_DRAWER = 'CLOSE_ANALYTICS_DRAWER';

const open = mandatory => ({
  type: OPEN_ANALYTICS_DRAWER,
  payload: mandatory,
});

const close = () => ({
  type: CLOSE_ANALYTICS_DRAWER,
});

export default { open, close };
