import { OPEN_ACKNOWLEDGMENT_PREVIEW_MODAL, CLOSE_ACKNOWLEDGMENT_PREVIEW_MODAL } from './action';

const initialState = {
  isOpen: false,
};

const createModal = (state = initialState, { type, payload }) => {
  switch (type) {
    case OPEN_ACKNOWLEDGMENT_PREVIEW_MODAL:
      return {
        ...state,
        isOpen: true,
        message: payload,
      };

    case CLOSE_ACKNOWLEDGMENT_PREVIEW_MODAL:
      return {
        ...state,
        isOpen: false,
      };

    default:
      return state;
  }
};

export default createModal;
