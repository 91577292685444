import { Button } from '@mui/material';
import { func } from 'prop-types';
import React from 'react';
import { I18n } from 'utils/i18n';

const IAcceptTermsButton = ({ onClick, ...rest }) => (
  <Button variant="contained" color="primary" onClick={onClick} {...rest}>
    {I18n.t('CompanyTerms.agreeBtnText')}
  </Button>
);
IAcceptTermsButton.propTypes = {
  onClick: func.isRequired,
};

export default IAcceptTermsButton;
