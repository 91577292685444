import { Box } from '@mui/material';
import Autosuggest from 'components/controls/Autosuggest';
import { array, object, string } from 'prop-types';
import React, { useEffect } from 'react';
import { I18n } from 'utils/i18n';
import connector from './connector';

const TimeZone = ({ actions, timeZones: { timeZones, loading }, value, filter, ...props }) => {
  useEffect(() => {
    if (!timeZones.length) actions.config.timeZones.loadTimeZones();
  }, []);

  const allSuggestions = timeZones.map(tz => ({
    label: `${tz.gmt} ${tz.zone}`,
    value: tz.zone,
  }));

  const filteredSuggestions = filter ? allSuggestions.filter(tz => filter.includes(tz.value)) : [];

  let suggestions;
  if (filteredSuggestions.length > 0) {
    suggestions = [
      {
        label: I18n.t(`TimeZone.Suggested`),
        options: filteredSuggestions,
      },
      {
        label: I18n.t(`TimeZone.All`),
        options: allSuggestions,
      },
    ];
  } else {
    suggestions = allSuggestions;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Autosuggest
        {...props}
        fullWidth
        value={value}
        placeholder={
          loading ? I18n.t(`TimeZone.Loading time zones...`) : I18n.t(`TimeZone.Choose time zone`)
        }
        suggestions={suggestions}
        isOptionDisabled={option => !option.value}
      />
    </Box>
  );
};

TimeZone.propTypes = {
  actions: object.isRequired,
  value: string.isRequired,
  filter: array,
  timeZones: object.isRequired,
};

TimeZone.defaultProps = {
  filter: null,
};

export default connector(TimeZone);
