import { Drawer } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { bool, object, string } from 'prop-types';
import React, { memo } from 'react';
import employeeShape from 'shapes/employee';
import CloseButton from '../CloseButton';
import connector from './connector';
import EmployeeForm from './Form';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 900,
    },
  },
  loading: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  container: {
    display: 'flex',
  },
  closeButtonContainer: {
    paddingTop: 20,
    paddingLeft: 20,
  },
}));

const EmployeeDrawer = ({ actions, employee, employeeId, isEdit, isOpen }) => {
  const classes = useStyles();
  const handleClose = () => {
    actions.employeeDrawer.close();
  };

  if (!isOpen) return null;

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose}>
      <div className={classes.root}>
        <div className={classes.closeButtonContainer}>
          <CloseButton onClick={handleClose} />
        </div>
        <EmployeeForm isEdit={isEdit} employee={employee} employeeId={employeeId} />
      </div>
    </Drawer>
  );
};

EmployeeDrawer.propTypes = {
  employee: employeeShape,
  employeeId: string,
  isOpen: bool.isRequired,
  isEdit: bool.isRequired,
  actions: object.isRequired,
};

EmployeeDrawer.defaultProps = {
  employee: null,
  employeeId: null,
};

const areEqual = (prevProps, nextProps) =>
  prevProps.company?.teams?.length === nextProps.company?.teams?.length &&
  prevProps.employee?._id === nextProps.employee?._id &&
  prevProps.employee?.loading === nextProps.employee?.loading &&
  prevProps.employee?.channels?.length === nextProps.employee?.channels?.length &&
  prevProps.isOpen === nextProps.isOpen &&
  prevProps.isEdit === nextProps.isEdit;

export default connector(memo(EmployeeDrawer, areEqual));
