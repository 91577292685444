import Config from './EncryptionConfig';
import subtle from './subtle';

const arrayBufferToBase64 = arrayBuffer => {
  const byteArray = new Uint8Array(arrayBuffer);
  let byteString = '';
  for (let i = 0; i < byteArray.byteLength; i += 1) {
    byteString += String.fromCharCode(byteArray[i]);
  }
  const b64 = Buffer.from(byteString, 'binary').toString('base64');

  return b64;
};

const base64ToArrayBuffer = str => {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i += 1) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
};

const addNewLines = strIn => {
  let str = strIn;
  let finalString = '';
  while (str.length > 0) {
    finalString += `${str.substring(0, 64)}\n`;
    str = str.substring(64);
  }

  return finalString;
};

const toPrivatePem = privateKey => {
  const b64 = addNewLines(arrayBufferToBase64(privateKey));
  const pem = `-----BEGIN PRIVATE KEY-----\n${b64}-----END PRIVATE KEY-----`;

  return pem;
};

const toPublicPem = publicKey => {
  const b64 = addNewLines(arrayBufferToBase64(publicKey));
  const pem = `-----BEGIN PUBLIC KEY-----\n${b64}-----END PUBLIC KEY-----`;

  return pem;
};

const encodeMessage = plainText => {
  const enc = new TextEncoder();
  return enc.encode(plainText);
};

const getPublicCryptoKey = async publicKey => {
  const pemHeader = '-----BEGIN PUBLIC KEY-----';
  const pemFooter = '-----END PUBLIC KEY-----';
  const pemContents = publicKey.substring(pemHeader.length, publicKey.length - pemFooter.length);
  const binaryDerString = Buffer.from(pemContents, 'base64').toString('binary');
  const spki = base64ToArrayBuffer(binaryDerString);

  const publicKeyFormat = Config.main.exports.public;

  const cryptoKey = subtle
    .importKey(
      publicKeyFormat,
      spki,
      {
        name: Config.main.name,
        hash: Config.main.hash,
      },
      true,
      ['encrypt'],
    )
    .catch(e => {
      console.log(e);
    });
  return cryptoKey;
};

const getPrivateCryptoKey = async privateKey => {
  const pemHeader = '-----BEGIN PRIVATE KEY-----';
  const pemFooter = '-----END PRIVATE KEY-----';
  const pemContents = privateKey.substring(pemHeader.length, privateKey.length - pemFooter.length);
  const binaryDerString = Buffer.from(pemContents, 'base64').toString('binary');
  const pkcs8 = base64ToArrayBuffer(binaryDerString);

  const privateKeyFormat = Config.main.exports.private;

  const cryptoKey = await subtle.importKey(
    privateKeyFormat,
    pkcs8,
    {
      name: Config.main.name,
      hash: Config.main.hash,
    },
    true,
    ['decrypt'],
  );
  return cryptoKey;
};

const getAESCryptoKey = async aesKey => {
  const raw = base64ToArrayBuffer(Buffer.from(aesKey, 'base64').toString('binary'));

  const preKeyFormat = Config.pre.exports;

  const aesCryptoKey = await subtle.importKey(
    preKeyFormat,
    raw,
    {
      name: Config.pre.name,
    },
    true,
    ['encrypt', 'decrypt'],
  );

  return aesCryptoKey;
};

const uIntToBase64 = u8 =>
  Buffer.from(String.fromCharCode.apply(null, u8), 'binary').toString('base64');

const base64ToUint8 = str =>
  new Uint8Array(
    Buffer.from(str, 'base64')
      .toString('binary')
      .split('')
      .map(c => c.charCodeAt(0)),
  );

export {
  arrayBufferToBase64,
  base64ToArrayBuffer,
  encodeMessage,
  toPrivatePem,
  toPublicPem,
  getPublicCryptoKey,
  getPrivateCryptoKey,
  getAESCryptoKey,
  uIntToBase64,
  base64ToUint8,
};
