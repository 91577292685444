import { IconButton, SvgIcon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SearchIcon from 'assets/icons/search.svg';
import Loading from 'components/common/Loading';
import SearchField from 'components/controls/SearchField';
import { bool, func } from 'prop-types';
import React, { memo, useEffect, useRef } from 'react';
import useOpenClose from 'utils/useOpenClose';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  searchIconButton: {
    marginLeft: 5,
  },
  searchIcon: {
    fontSize: 16,
  },
}));

const Search = ({ onChange, isFetching }) => {
  const classes = useStyles();
  const inputRef = useRef();
  const [isVisible, makeVisible] = useOpenClose(false);

  useEffect(() => {
    if (isVisible) {
      inputRef.current?.focus();
    }
  }, [isVisible]);

  return (
    <div className={classes.root}>
      {isVisible && (
        <SearchField
          inputRef={inputRef}
          onChange={onChange}
          InputProps={
            isFetching
              ? {
                  startAdornment: (
                    <Loading color="secondary" sx={{ width: 'auto', ml: 1 }} size={20} />
                  ),
                }
              : {}
          }
        />
      )}
      {!isVisible && (
        <IconButton className={classes.searchIconButton} onClick={makeVisible} size="large">
          <SvgIcon color="primary" className={classes.searchIcon}>
            <SearchIcon />
          </SvgIcon>
        </IconButton>
      )}
    </div>
  );
};

Search.propTypes = {
  onChange: func.isRequired,
  isFetching: bool,
};
Search.defaultProps = {
  isFetching: false,
};

export default memo(Search);
