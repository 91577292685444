const getValue = (obj, key, type) => {
  if (type === 'bool') {
    return obj[key] ? 1 : 0;
  }
  if (typeof type === 'function') {
    return type(obj[key]);
  }
  return obj[key];
};

const createForm = (obj, types) => {
  return Object.entries(types).reduce(
    (ret, [key, type]) =>
      Object.prototype.hasOwnProperty.call(obj, key)
        ? { ...ret, [key]: getValue(obj, key, type) }
        : ret,
    {},
  );
};

export default createForm;
