import { Button, SvgIcon, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Alert from 'components/alerts/Alert';
import FileUpload from 'mdi-react/FileUploadIcon';
import ms from 'ms';
import { number, object, string } from 'prop-types';
import React, { useRef, useState } from 'react';
import { I18n } from 'utils/i18n';
import connector from './connector';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'flex-end',
  },
  title: {
    fontWeight: 600,
  },
  buttonIcon: {
    fontSize: 18,
    marginRight: 5,
    color: theme.palette.white,
  },
  form: {
    display: 'none',
  },
  close: {
    marginRight: -12,
  },
}));

const alertInitialState = { isOpen: false };

const autoHideDuration = ms('10 seconds');

const Toolbar = ({ total, locale, actions, pendingImport }) => {
  const classes = useStyles();
  const form = useRef();
  const fileInput = useRef();
  const [alert, setAlert] = useState(alertInitialState);
  const onClose = () => {
    setAlert(a => ({ ...a, ...alertInitialState }));
  };
  const onClick = () => {
    form.current.reset();
    fileInput.current.value = '';
    fileInput.current.click();
  };
  const handleChange = async event => {
    try {
      const file = event.target.files[0];
      await actions.employees.createImport(file);
    } catch (e) {
      setAlert({
        isOpen: true,
        variant: 'error',
        message: e.data?.message || I18n.t('AttachFile.Errors.Unexpected error'),
        caption: '',
      });
    }
  };
  return (
    <div className={classes.root}>
      <Typography component="div" className={classes.title} variant="h4">
        {I18n.t('ImportsScene.ImportsTable.ImportsCount', { count: total.toLocaleString(locale) })}
      </Typography>
      <div className={classes.actions}>
        {!pendingImport && (
          <Button
            component={Button}
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={onClick}
          >
            <SvgIcon className={classes.buttonIcon}>
              <FileUpload />
            </SvgIcon>
            {I18n.t('ImportsScene.ImportsTable.New import')}
          </Button>
        )}
        <form className={classes.form} ref={form}>
          <input
            ref={fileInput}
            onChange={handleChange}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            type="file"
          />
        </form>
        <Alert
          isOpen={alert.isOpen}
          variant={alert.variant}
          onClose={onClose}
          label={I18n.t('ImportsScene.Import employees')}
          caption={alert.caption}
          autoHideDuration={autoHideDuration}
        >
          {alert.message}
        </Alert>
      </div>
    </div>
  );
};

Toolbar.propTypes = {
  total: number.isRequired,
  locale: string.isRequired,
  actions: object.isRequired,
  pendingImport: object,
};

Toolbar.defaultProps = {
  pendingImport: null,
};

export default connector(Toolbar);
