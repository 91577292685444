import { Box, Breadcrumbs, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import renderFolderCell from 'components/@home/@documents/renderFolderCell';
import OmmnioDataGrid from 'components/common/OmmnioDataGrid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'utils/i18n';
import { Link, useLocation, useParams } from 'react-router-dom';
import { HR_BOT_APP_ID } from 'services/constants';
import appsAction from 'store/app/entities/apps/action';
import { useListReceivedFilesQuery } from 'store/app/entities/SharedFilesSlice';
import renderDateTimeCell from 'utils/renderDateTimeCell';

const MyDocumentsFiles = ({ folder }) => {
  const [perPage, setPerPage] = useState(0);
  const [sortModel, setSortModel] = useState(undefined);
  const [filterModel, setFilterModel] = useState(undefined);
  const [page, setPage] = useState(0);
  const { id: format } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const app = useSelector(state => state.apps.apps?.[HR_BOT_APP_ID]);
  useEffect(() => {
    if (!app?._id) {
      dispatch(appsAction.load());
    }
  }, [app?._id]);
  const search = filterModel?.quickFilterValues.join(' ');
  const { data: { files, totalCount } = {}, isLoading } = useListReceivedFilesQuery({
    format,
    search,
    page: page + 1,
    perPage,
    sortBy: sortModel?.[0]?.field || 'receivedAt',
    sort: sortModel?.[0]?.sort || 'desc',
  });
  const columns = [
    {
      field: 'name',
      flex: 1,
      editable: false,
      renderCell: renderFolderCell(folder, { search: location.search }, 'home'),
      headerName: I18n.t('MyDocuments.Table.name'),
    },
    {
      field: 'receivedAt',
      type: 'string',
      flex: 1,
      editable: false,
      renderCell: renderDateTimeCell,
      headerName: I18n.t('MyDocuments.Table.receivedAt'),
    },
  ];
  return (
    <Grid
      item
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          p: 2,
          pb: 0,
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
            color="inherit"
            to={{ pathname: `/home/documents/${folder}/`, search: location.state?.search || '' }}
            onClick={() => setPage(0)}
          >
            <Typography variant="h3" sx={{ fontWeight: 'normal' }}>
              {I18n.t(`DocumentsScene.Folders.${folder}`)}
            </Typography>
          </Link>
          <Typography variant="h3">{location.state?.parentName}</Typography>
        </Breadcrumbs>
        <OmmnioDataGrid
          loading={isLoading}
          sx={{ marginTop: 2 }}
          columns={columns}
          rows={files}
          rowCount={totalCount || 0}
          paginationMode="server"
          getRowId={r => r._id}
          sortingMode="server"
          filterMode="server"
          page={page}
          sortModel={sortModel}
          filterModel={filterModel}
          onFilterModelChange={setFilterModel}
          onSortModelChange={setSortModel}
          onPageChange={setPage}
          onPageSizeChange={setPerPage}
          updateHistory
          disableEvenOdd
          defaultSortModel={[{ field: 'receivedAt', sort: 'desc' }]}
        />
      </Box>
    </Grid>
  );
};
export default MyDocumentsFiles;
