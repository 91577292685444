/* eslint-disable object-curly-newline */
import { createAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { setLocale } from 'react-redux-i18n';
import Auth from 'services/api/Auth';
import Login from 'services/api/Login';
import Cookie from 'services/Cookie';
import authCompany from 'store/selectors/authCompany';
import meStatus from 'store/selectors/meStatus';

export const SEND_AUTH_SMS = 'SEND_AUTH_SMS';
export const SEND_AUTH_SMS_PENDING = 'SEND_AUTH_SMS_PENDING';
export const SEND_AUTH_SMS_FULFILLED = 'SEND_AUTH_SMS_FULFILLED';
export const SEND_AUTH_SMS_REJECTED = 'SEND_AUTH_SMS_REJECTED';

export const SEND_AUTH_EMAIL = 'SEND_AUTH_EMAIL';
export const SEND_AUTH_EMAIL_PENDING = 'SEND_AUTH_EMAIL_PENDING';
export const SEND_AUTH_EMAIL_FULFILLED = 'SEND_AUTH_EMAIL_FULFILLED';
export const SEND_AUTH_EMAIL_REJECTED = 'SEND_AUTH_EMAIL_REJECTED';

export const GET_USER_DATA = 'GET_USER_DATA';
export const GET_USER_DATA_PENDING = 'GET_USER_DATA_PENDING';
export const GET_USER_DATA_FULFILLED = 'GET_USER_DATA_FULFILLED';
export const GET_USER_DATA_REJECTED = 'LOGIN_USER_REJECTED';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_PENDING = 'LOGIN_USER_PENDING';
export const LOGIN_USER_FULFILLED = 'LOGIN_USER_FULFILLED';
export const LOGIN_USER_REJECTED = 'LOGIN_USER_REJECTED';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_PENDING = 'LOGOUT_USER_PENDING';

export const FLUSH_USER_DATA = 'FLUSH_USER_DATA';

export const SET_USER_PHONE = 'SET_USER_PHONE';
export const ADD_COMPANY = 'ADD_COMPANY';
export const REMOVE_COMPANY = 'REMOVE_COMPANY';
export const CHANGE_AUTH_EMPLOYEE = 'CHANGE_AUTH_EMPLOYEE';

export const ACCEPT_INVITATION = 'ACCEPT_INVITATION';

export const TOKEN_REFRESHED = 'TOKEN_REFRESHED';

export const VERIFY = 'VERIFY';
export const VERIFY_PENDING = 'VERIFY_PENDING';
export const VERIFY_FULFILLED = 'VERIFY_FULFILLED';
export const VERIFY_REJECTED = 'VERIFY_REJECTED';

export const LIST_SESSIONS = 'LIST_SESSIONS';
export const LIST_SESSIONS_PENDING = 'LIST_SESSIONS_PENDING';
export const LIST_SESSIONS_FULFILLED = 'LIST_SESSIONS_FULFILLED';
export const LIST_SESSIONS_REJECTED = 'LIST_SESSIONS_REJECTED';

export const DELETE_SESSIONS = 'DELETE_SESSIONS';
export const DELETE_SESSIONS_PENDING = 'DELETE_SESSIONS_PENDING';
export const DELETE_SESSIONS_FULFILLED = 'DELETE_SESSIONS_FULFILLED';
export const DELETE_SESSIONS_REJECTED = 'DELETE_SESSIONS_REJECTED';

export const DELETE_SESSION = 'DELETE_SESSION';
export const DELETE_SESSION_PENDING = 'DELETE_SESSION_PENDING';
export const DELETE_SESSION_FULFILLED = 'DELETE_SESSION_FULFILLED';
export const DELETE_SESSION_REJECTED = 'DELETE_SESSION_REJECTED';

const company = Cookie.get('company');

const getCompany = companies =>
  companies.find(c => c._id === company?._id) || { employee: { _id: 'x' } };

const user = () => dispatch => {
  const response = dispatch({
    type: GET_USER_DATA,
    payload: Auth.user(),
  });

  return response.then(({ action: { payload } }) => {
    const {
      employee: { settings },
    } = getCompany(payload.companies);
    const language = settings?.language;
    if (language) {
      moment().locale(language);
      moment.locale(language);
      dispatch(setLocale(language));
    }
    return payload;
  });
};

const login = (credentials, returnUrl, keepSessionOpen) => ({
  type: LOGIN_USER,
  payload: Login.login(credentials),
  meta: { returnUrl, keepSessionOpen },
});

const loginSuccess = ({ user: u, token, keepSessionOpen }) => ({
  type: LOGIN_USER_FULFILLED,
  payload: { token, user: u },
  meta: { keepSessionOpen },
});

const tokenRefreshed = (token, keepSessionOpen) => ({
  type: TOKEN_REFRESHED,
  payload: { token, keepSessionOpen },
});

const logout = () => ({
  type: LOGOUT_USER,
  payload: Auth.logout(),
});

const flushUserData = createAction(FLUSH_USER_DATA);

const acceptInvite = (phone, token) => ({
  type: ACCEPT_INVITATION,
  payload: Auth.acceptInvite(phone, token),
});

const verify = () => ({
  type: VERIFY,
  payload: Auth.verify(),
});

const listSessions = userId => ({
  type: LIST_SESSIONS,
  payload: Auth.listSessions(userId),
});

const deleteSessions = userId => ({
  type: DELETE_SESSIONS,
  payload: Auth.deleteSessions(userId),
});

const deleteSession = (userId, sessionId) => ({
  type: DELETE_SESSION,
  payload: Auth.deleteSession(userId, sessionId),
});

export const useMe = () => {
  const dispatch = useDispatch();
  const c = useSelector(authCompany);
  const ret = useSelector(meStatus);
  if (!ret.isFetching && !ret.isError && (!c || ret.rehydrated || ret.isUninitialized)) {
    setTimeout(() => dispatch(user()), 1);
  }
  return ret;
};

export default {
  deleteSession,
  deleteSessions,
  flushUserData,
  user,
  listSessions,
  login,
  loginSuccess,
  logout,
  acceptInvite,
  tokenRefreshed,
  verify,
};
