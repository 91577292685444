/* eslint-disable import/named,class-methods-use-this,no-console */
import { SOCKETS_URL } from 'services/constants';
import io from 'socket.io-client';

let socket;

export const connect = (confirmToken, onLoginSuccess, onLoginRevoked) => {
  if (socket) {
    socket.disconnect();
  }
  socket = io(`${SOCKETS_URL}/loginConfirm?confirmToken=${confirmToken}`, {
    transports: ['websocket'],
    path: `/loginConfirm`,
    reconnect: true,
    secure: true,
    cookie: true,
    reconnection: true,
    reconnectionDelay: 10000,
    reconnectionDelayMax: 30000,
    reconnectionAttempts: Infinity,
  });
  socket.connect();
  socket.on('confirm', ({ token, user, keepSessionOpen }) => {
    onLoginSuccess({ token, user, keepSessionOpen });
  });
  socket.on('revoke', () => {
    onLoginRevoked();
  });
  return socket;
};

export const disconnect = () => {
  if (socket) {
    socket.disconnect();
  }
};

export const handleOn = (name, callback) => {
  return (
    socket &&
    socket.on(name, data => {
      // console.log('WS on:', name, data);
      callback(data);
    })
  );
};

export const on = (name, callback) => {
  if (Array.isArray(name)) {
    return name.map(n => handleOn(n, callback));
  }

  return handleOn(name, callback);
};

export const isConnected = () => {
  return !!socket?.connected;
};
