import { IconButton } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import PauseIcon from 'mdi-react/PauseIcon';
import PlayIcon from 'mdi-react/PlayIcon';
import { bool, func, object } from 'prop-types';
import React from 'react';

const styles = theme => ({
  root: {},
  errorContainer: {
    width: 37,
    height: 37,
    marginRight: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorIcon: {
    color: theme.palette.error.main,
    background: '#ffffff',
    borderRadius: '50%',
  },
  playContainer: {
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.white,
  },
  right: {
    backgroundColor: theme.palette.white,
    color: theme.palette.secondary.main,
  },
  playIcon: {
    fontSize: 10,
  },
  disabled: {
    color: theme.palette.secondary.extraLight,
    backgroundColor: theme.palette.secondary.light,
    '&$right': {
      backgroundColor: theme.palette.secondary.extraLight,
      color: theme.palette.secondary.light,
    },
  },
});

const PlayButton = ({ classes, paused, loaded, onClick, right }) => (
  <IconButton
    disabled={!loaded}
    onClick={onClick}
    aria-label="play"
    className={classes.root}
    size="large"
  >
    <div
      className={classNames({
        [classes.playContainer]: true,
        [classes.right]: right,
        [classes.disabled]: !loaded,
      })}
    >
      {paused ? (
        <PlayIcon className={classes.playIcon} />
      ) : (
        <PauseIcon className={classes.playIcon} />
      )}
    </div>
  </IconButton>
);

PlayButton.propTypes = {
  right: bool.isRequired,
  classes: object.isRequired,
  paused: bool.isRequired,
  loaded: bool.isRequired,
  onClick: func.isRequired,
};

export default withStyles(styles)(PlayButton);
