import { Button, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'formik';
import { bool, func, object } from 'prop-types';
import React from 'react';
import { I18n } from 'utils/i18n';
import InviteSwitch from './InviteSwitch';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttons: {
    display: 'flex',
    justifySelf: 'flex-end',
    padding: '20px 0 0 0',
  },
  cancel: {
    color: theme.palette.primary.ultraDark,
    marginRight: 10,
  },
  leaveContainer: {
    display: 'flex',
    alignItems: 'center',
    justifySelf: 'flex-start',
  },
  errorContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '20px 0 0 0',
  },
  errorMessage: {
    padding: '0 20px',
    alignSelf: 'center',
  },
});

const EmployeeActions = ({ classes, onReset, formik, isEdit }) => {
  const handleReset = e => {
    if (typeof onReset === 'function') {
      onReset(e);
    }
    formik.handleReset(e);
  };

  return (
    <div className={classes.root}>
      {!isEdit && <InviteSwitch />}
      <div className={classes.errorContainer}>
        {formik.errors.server && (
          <Typography align="right" color="error" className={classes.errorMessage}>
            {typeof formik.errors.server === 'object' && formik.errors.server.message
              ? formik.errors.server.message
              : typeof formik.errors.server === 'string'
              ? formik.errors.server
              : JSON.stringify(formik.errors.server)}
          </Typography>
        )}
      </div>
      <div className={classes.buttons}>
        <Button
          variant="outlined"
          onClick={handleReset}
          disabled={formik.isSubmitting}
          className={classes.cancel}
          type="reset"
          color="secondary"
        >
          {I18n.t('EmployeeDrawer.Cancel')}
        </Button>
        <Button variant="contained" disabled={formik.isSubmitting} color="primary" type="submit">
          {formik.isSubmitting
            ? `${
                isEdit
                  ? I18n.t('EmployeeDrawer.EmployeeButton.Updating')
                  : I18n.t('EmployeeDrawer.EmployeeButton.Creating')
              } ${I18n.t('EmployeeDrawer.EmployeeButton.employee')}...`
            : `${
                isEdit
                  ? I18n.t('EmployeeDrawer.EmployeeButton.Update')
                  : I18n.t('EmployeeDrawer.EmployeeButton.Create')
              } ${I18n.t('EmployeeDrawer.EmployeeButton.employee')}`}
        </Button>
      </div>
    </div>
  );
};

EmployeeActions.propTypes = {
  classes: object.isRequired,
  onReset: func.isRequired,
  isEdit: bool.isRequired,
  formik: object.isRequired,
};

export default withStyles(styles)(connect(EmployeeActions));
