import { OPEN_VIDEO_MODAL, CLOSE_VIDEO_MODAL } from './action';

const initialState = {
  isOpen: false,
};

const createModal = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_VIDEO_MODAL:
      return {
        ...state,
        isOpen: true,
        src: action.payload,
      };

    case CLOSE_VIDEO_MODAL:
      return { ...state, isOpen: false };

    default:
      return state;
  }
};

export default createModal;
