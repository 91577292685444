/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import withStyles from '@mui/styles/withStyles';
import ChatPanelContext from 'components/@home/@messages/ChatPanel/ChatPanelContext';
import { bool, func, number, object } from 'prop-types';
import React, { useContext, useRef } from 'react';
import compressImages from 'utils/compressImages';
import AttachmentButton from './AttachmentButton';
import FileInput from './FileInput';

const styles = {
  root: {
    display: 'flex',
    minWidth: 121,
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
};

const AttachFile = ({ disabled, classes, progress, onCancel }) => {
  const fileInput = useRef();

  const { setImagesToUpload } = useContext(ChatPanelContext);

  const handleChange = async e => {
    const images = await compressImages(Array.from(e.target.files));
    setImagesToUpload(images);
  };

  const openFiles = () => {
    if (!disabled) fileInput.current.click();
  };

  return (
    <div className={classes.root}>
      <AttachmentButton
        isLoading={false}
        progress={progress}
        onCancel={onCancel}
        isError={false}
        onClick={openFiles}
      />
      <FileInput inputRef={fileInput} onChange={handleChange} multiple />
    </div>
  );
};

AttachFile.propTypes = {
  classes: object.isRequired,
  disabled: bool.isRequired,
  progress: number,
  onCancel: func,
};

AttachFile.defaultProps = {
  progress: null,
  onCancel: null,
};

export default withStyles(styles)(AttachFile);
