import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { object, shape } from 'prop-types';
import React, { Component } from 'react';
import { I18n } from 'utils/i18n';
import channelShape from 'shapes/channel';
import employeeShape from 'shapes/employee';
import messageShape from 'shapes/message';
import connector from './connector';

const styles = theme => ({
  greenButton: {
    minWidth: '110px',
    fontSize: '15px',
    boxShadow: '0 1px 2px 0 rgba(2, 2, 3, 0.05)',
    borderColor: theme.palette.primary.main,
  },
});

class OpenButton extends Component {
  openModal = () => {
    const { actions, message, channel, employee } = this.props;
    if (message.form) {
      actions.fillFormDrawer.open({ message, channel, employee });
    } else {
      actions.acknowledgmentReadModal.open(message, channel, employee);
    }
  };

  render() {
    const { classes, message, employee } = this.props;

    const acknowledged =
      message?.acknowledges && message?.acknowledges.find(m => m.user === employee?.user?._id);

    return (
      <Button
        variant="contained"
        color="primary"
        className={classes.greenButton}
        onClick={this.openModal}
      >
        {I18n.t(`AcknowledgementMessage.${!message.form || acknowledged ? 'Open' : 'Respond'}`)}
      </Button>
    );
  }
}

OpenButton.propTypes = {
  classes: object.isRequired,
  message: messageShape.isRequired,
  employee: employeeShape.isRequired,
  channel: channelShape.isRequired,
  actions: shape({
    acknowledgmentReadModal: object.isRequired,
  }).isRequired,
};

export default withStyles(styles)(connector(OpenButton));
