/**
 * @flow
 */
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Alert } from '@mui/material';
import { I18n } from 'utils/i18n';
import React from 'react';

const useStyles = makeStyles(theme => ({
  alert: {
    marginBottom: theme.spacing(3),
  },
  errorDetails: {
    backgroundColor: theme.palette.secondary.ultraLight,
    borderRadius: 4,
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
  },
}));

type Props = {
  app: Object;
  message: any;
};

const OAuthError = ({ app, message }: Props) => {
  const classes = useStyles();
  return (
    <div>
      <Alert severity="error" className={classes.alert}>
        {I18n.t('OAuth.Something went wrong when authorizing', { name: app.name })}
      </Alert>
      <Typography>
        <strong>{I18n.t('OAuth.Error details')}</strong>
      </Typography>
      <div className={classes.errorDetails}>{message}</div>
    </div>
  );
};

export default OAuthError;
