import { Box, Button, Drawer, Typography } from '@mui/material';
import CloseButton from 'components/@home/drawers/CloseButton';
import FormikTableField from 'components/formik/TableField';
import { Field, Form, Formik } from 'formik';
import React, { useContext } from 'react';
import { I18n } from 'utils/i18n';
import { useLazyListTeamsQuery } from 'store/app/entities/TeamsSlice';
import loadOptionsLazyQuery from 'utils/loadOptionsLazyQuery';

export const ShareDrawerContext = React.createContext({});

const ShareDrawer = () => {
  const { isOpen, file: folder, onSubmit, close } = useContext(ShareDrawerContext);
  const [triggerListTeamsQuery] = useLazyListTeamsQuery();
  const handleClose = () => {
    close();
  };
  const handleSubmit = ({ permissions: permissionsIn, permissionsRemoved } = {}) => {
    if (!permissionsIn) {
      return;
    }
    const permissions = permissionsIn.map(p => ({ team: p.value._id, role: p.value.role }));
    const teamsRemoved = permissionsRemoved;
    onSubmit({ teamsRemoved, permissions });
    handleClose();
  };

  const teamsColumns = [
    {
      key: 'name',
      disablePadding: false,
      label: I18n.t('ShareDrawer.TeamsTable.TeamName'),
      xsVisible: true,
    },
    {
      key: 'role',
      disablePadding: false,
      label: I18n.t('ShareDrawer.TeamsTable.Permissions'),
      xsVisible: true,
      type: 'dropdown',
      options: ['read', 'admin'].map(value => ({
        value,
        label: I18n.t(`ShareDrawer.permissions.${value}`),
      })),
      defaultValue: 'read',
    },
    {
      key: 'delete',
      xsVisible: true,
      type: 'remove',
    },
  ];

  const permissions = (
    folder?.permissions
      ?.filter(p => p)
      .map(({ team: { _id, name }, role }) => ({
        label: name,
        value: { _id, name, role },
      })) || []
  ).filter(p => p.label);

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose} onClick={e => e.stopPropagation()}>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          maxWidth: 'calc(100vw - 40px)',
          width: { xs: '100%', md: 1100 },
        }}
      >
        <Box sx={{ paddingTop: 2.5, paddingLeft: 2.5 }}>
          <CloseButton onClick={handleClose} />
        </Box>
        <Box
          sx={{ display: 'flex', flex: 1, minWidth: 500, flexDirection: 'column', padding: 2.5 }}
        >
          <Typography variant="h6" component="h1">
            {I18n.t(`ShareDrawer.Share with teams`)}
          </Typography>
          <Formik initialValues={{ permissions, permissionsRemoved: [] }} onSubmit={handleSubmit}>
            <Form style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ flex: 1 }}>
                <Field
                  name="permissions"
                  component={FormikTableField}
                  buttonText={I18n.t('ShareDrawer.Add team')}
                  columns={teamsColumns}
                  loadOptions={loadOptionsLazyQuery}
                  additional={{ page: 1, trigger: triggerListTeamsQuery }}
                />
              </Box>
              <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  color="secondary"
                  sx={{ color: 'primary.ultraDark' }}
                >
                  {I18n.t('ShareDrawer.Cancel')}
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  {I18n.t('ShareDrawer.Save')}
                </Button>
              </Box>
            </Form>
          </Formik>
        </Box>
      </Box>
    </Drawer>
  );
};

export default ShareDrawer;
