import Http from 'services/Http';

const AppData = {
  async getSignatureParams(appId, payload) {
    const { data } = await Http.post(`/v1/app-data/${appId}/signature`, { payload });
    return data.params;
  },

  async regenerateSecret(appId) {
    const { data } = await Http.post(`/v1/app-data/${appId}/secret`);
    return data;
  },
};

export default AppData;
