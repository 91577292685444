import { Box, CircularProgress, Fab } from '@mui/material';
import CloseIcon from 'mdi-react/CloseIcon';
import { bool, func, node, number, object, string } from 'prop-types';
import React, { Component } from 'react';

const styles = {
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  closeIcon: {
    width: 26,
    height: 26,
    minHeight: 26,
    background: 'transparent',
    boxShadow: 'none',
    position: 'absolute',
  },
  progress: {
    marginLeft: 0,
  },
};

class Loading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: !props.debounce,
    };
  }

  componentDidMount() {
    const { debounce } = this.props;
    this.timer =
      !this.state.isVisible && setTimeout(() => this.setState({ isVisible: true }), debounce);
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  render() {
    const { size, color, loaded, children, className, hide, value, onCancel, sx } = this.props;
    const { isVisible } = this.state;

    const variantProps = {};
    if (value !== null && value < 100) {
      variantProps.variant = 'determinate';
      variantProps.value = value;
    }

    if (hide || !isVisible) {
      return null;
    }

    if (loaded) return children;

    return (
      <Box sx={{ ...styles.root, ...sx }} className={className}>
        <CircularProgress {...variantProps} color={color} sx={styles.progress} size={size} />
        {onCancel && (
          <Fab onClick={onCancel} sx={styles.closeIcon} aria-label="Close">
            <CloseIcon color={color} />
          </Fab>
        )}
      </Box>
    );
  }
}

Loading.propTypes = {
  size: number,
  debounce: number,
  color: string,
  loaded: bool,
  children: node,
  className: string,
  hide: bool,
  value: number,
  onCancel: func,
  sx: object,
};
Loading.defaultProps = {
  size: 80,
  debounce: 0,
  color: 'primary',
  loaded: false,
  children: null,
  className: null,
  hide: false,
  value: null,
  onCancel: null,
  sx: {},
};

export default Loading;
