import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import { node, string } from 'prop-types';
import React, { memo } from 'react';

const useStyles = makeStyles({
  root: {
    marginTop: '1.333em',
    marginBottom: '0.467em',
    fontSize: 15,
    fontWeight: 600,
  },
});

const Label = ({ children, className }) => {
  const classes = useStyles();
  return (
    <Typography className={classnames([classes.root, className])} variant="body1">
      {children}
    </Typography>
  );
};

Label.propTypes = {
  className: string,
  children: node.isRequired,
};

Label.defaultProps = {
  className: null,
};

export default memo(Label);
