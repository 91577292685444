import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import apps from 'store/app/entities/apps/action';
import chatFlows from 'store/app/entities/chatFlows/action';
import alert from 'store/app/ui/Alert/action';
import appDrawer from 'store/app/ui/drawers/app/action';
import authCompanyId from 'store/selectors/authCompanyId';
import authEmployee from 'store/selectors/authEmployee';
import authUser from 'store/selectors/authUser';

const mapStateToProps = state => ({
  isEdit: !!state.drawers.app.app._id,
  companyId: authCompanyId(state),
  authEmployee: authEmployee(state),
  isSuperAdmin: authUser(state).isSuperAdmin,
  app: state.apps.apps[state.drawers.app.app?._id],
  apps: state.apps.apps,
  loading: state.teams.loading,
  chatFlows: state.chatFlows.chatFlows,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    apps: bindActionCreators(apps, dispatch),
    alert: bindActionCreators(alert, dispatch),
    chatFlows: bindActionCreators(chatFlows, dispatch),
    drawers: {
      app: bindActionCreators(appDrawer, dispatch),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
