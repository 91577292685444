import withStyles from '@mui/styles/withStyles';
import { object } from 'prop-types';
import React from 'react';
import { Outlet } from 'react-router-dom';

const styles = {
  root: {
    flexGrow: 1,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
};

const LoginLayout = ({ classes }) => (
  <div className={classes.root}>
    <Outlet />
  </div>
);
LoginLayout.propTypes = {
  classes: object.isRequired,
};

export default withStyles(styles)(LoginLayout);
