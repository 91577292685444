import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import { func, number, object } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: -7,
  },
  selection: {
    border: '1px dashed #fff',
    position: 'absolute',
    margin: 7,
    cursor: 'move',
  },
  overlayWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 7,
    overflow: 'hidden',
    pointerEvents: 'none',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    boxShadow: '0 0 1000px 1000px rgba(0,0,0,0.3)',
  },
  handle: {
    position: 'absolute',
    width: 14,
    height: 14,
    zIndex: 1,
    top: 0,
    left: 0,
    '&$t, &$b': {
      marginLeft: -4,
    },
  },
  square: {
    border: '1px solid #000',
    position: 'absolute',
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: '#fff',
    '&$tl, &$t, &$tr': {
      top: -5,
    },
    '&$tl, &$l, &$bl': {
      left: -5,
    },
    '&$bl, &$b, &$br': {
      bottom: -5,
    },
    '&$tr, &$r, &$br': {
      right: -5,
    },
    '&$l, &$r': {
      marginTop: -5,
      top: '50%',
    },
    '&$t, &$b': {
      marginLeft: -5,
      left: '50%',
    },
  },
  tl: {
    cursor: 'nw-resize',
  },
  t: {
    cursor: 'n-resize',
  },
  tr: {
    cursor: 'ne-resize',
  },
  l: {
    cursor: 'w-resize',
  },
  r: {
    cursor: 'e-resize',
  },
  bl: {
    cursor: 'sw-resize',
  },
  b: {
    cursor: 's-resize',
  },
  br: {
    cursor: 'se-resize',
  },
}));

const direction = {
  t: 'y',
  b: 'y',
  r: 'x',
  l: 'x',
  tr: 'both',
  tl: 'both',
  br: 'both',
  bl: 'both',
};

const ResizableDraggable = ({ imgWidth, box, onResize }) => {
  const classes = useStyles();
  const rectRef = useRef();
  const [rect, setRect] = useState(box);
  const [rootRef] = useState({ current: null });

  const handleStop = () => {
    const { width, height } = rootRef.current.parentNode.getBoundingClientRect();
    const scale = imgWidth ? imgWidth / width : 1;
    const recWidth = width - rect.left - rect.right;
    const recHeight = height - rect.top - rect.bottom;
    onResize({
      left: rect.left,
      top: rect.top,
      bottom: rect.bottom,
      right: rect.right,
      width: recWidth,
      height: recHeight,
      scale,
      aspectRatio: recWidth / recHeight,
    });
  };

  const handleDrag = dir => (e, d) => {
    switch (dir) {
      case 'tl':
        setRect(r => ({ ...r, top: r.top + d.deltaY, left: r.left + d.deltaX }));
        break;
      case 't':
        setRect(r => ({ ...r, top: r.top + d.deltaY }));
        break;
      case 'tr':
        setRect(r => ({ ...r, top: r.top + d.deltaY, right: r.right - d.deltaX }));
        break;
      case 'l':
        setRect(r => ({ ...r, left: r.left + d.deltaX }));
        break;
      case 'r':
        setRect(r => ({ ...r, right: r.right - d.deltaX }));
        break;
      case 'bl':
        setRect(r => ({ ...r, bottom: r.bottom - d.deltaY, left: r.left + d.deltaX }));
        break;
      case 'b':
        setRect(r => ({ ...r, bottom: r.bottom - d.deltaY }));
        break;
      case 'br':
        setRect(r => ({ ...r, bottom: r.bottom - d.deltaY, right: r.right - d.deltaX }));
        break;
      case 'selection':
        setRect(r => ({
          ...r,
          top: r.top + d.deltaY,
          left: r.left + d.deltaX,
          right: r.right - d.deltaX,
          bottom: r.bottom - d.deltaY,
        }));
        break;
      default:
    }
  };

  const getPosition = dir => {
    if (!rootRef.current) {
      return { x: 0, y: 0 };
    }
    const { width, height } = rootRef.current.parentNode.getBoundingClientRect();
    const recWidth = width - rect.left - rect.right;
    const recHeight = height - rect.top - rect.bottom;
    switch (dir) {
      case 'tl':
        return { x: rect.left, y: rect.top };
      case 't':
        return { x: recWidth / 2 + rect.left, y: rect.top };
      case 'tr':
        return { x: width - rect.right, y: rect.top };
      case 'l':
        return { x: rect.left, y: recHeight / 2 + rect.top };
      case 'r':
        return { x: width - rect.right, y: recHeight / 2 + rect.top };
      case 'bl':
        return { x: rect.left, y: height - rect.bottom };
      case 'b':
        return { x: recWidth / 2 + rect.left, y: height - rect.bottom };
      case 'br':
        return { x: width - rect.right, y: height - rect.bottom };
      case 'selection':
        return { top: 0, left: 0, width: recWidth, height: recHeight };
      case 'overlay':
        return { top: rect.top, left: rect.left, width: recWidth, height: recHeight };

      default:
        return () => {};
    }
  };

  useEffect(() => {
    handleStop();
  }, []);

  return (
    <div className={classes.root} ref={rootRef}>
      {Object.entries(direction).map(([d, axis]) => {
        return (
          <Draggable
            position={getPosition(d)}
            axis={axis}
            bounds="parent"
            onDrag={handleDrag(d)}
            onStop={handleStop}
            key={d}
          >
            <div className={classnames(classes[d], classes.handle)} />
          </Draggable>
        );
      })}
      <div className={classes.overlayWrapper}>
        <div className={classes.overlay} style={getPosition('overlay')} />
      </div>
      <Draggable
        bounds="parent"
        axis="both"
        position={{ x: rect.left, y: rect.top }}
        onDrag={handleDrag('selection')}
        onStop={handleStop}
        ref={rectRef}
      >
        <div className={classes.selection} style={getPosition('selection')}>
          {Object.keys(direction).map(d => {
            return <div key={d} className={classnames(classes[d], classes.square)} />;
          })}
        </div>
      </Draggable>
    </div>
  );
};

ResizableDraggable.propTypes = {
  onResize: func.isRequired,
  box: object,
  imgWidth: number,
};

ResizableDraggable.defaultProps = {
  box: { top: 0, right: 0, bottom: 0, left: 0 },
  imgWidth: 0,
};

export default ResizableDraggable;
