import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import auth from 'store/app/auth/action';
import authConfig from 'store/app/authConfig/action';
import visibility from 'store/app/ui/focus/action';
import authToken from 'store/selectors/authToken';
import keepSessionOpen from 'store/selectors/keepSessionOpen';

const mapStateToProps = state => {
  return {
    keepSessionOpen: keepSessionOpen(state),
    authToken: authToken(state),
    clientSecret: state.companies.clientSecret,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    visibility: bindActionCreators(visibility, dispatch),
    auth: bindActionCreators(auth, dispatch),
    authConfig: bindActionCreators(authConfig, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
