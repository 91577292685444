import { bool, shape, string, instanceOf } from 'prop-types';

const appShape = shape({
  _id: string.isRequired,
  name: string.isRequired,
  description: string,
  featured: bool.isRequired,
  isActive: bool.isRequired,
  isPublished: bool.isRequired,
  creator: string.isRequired,
  appUser: string.isRequired,
  createdAt: instanceOf(Date).isRequired,
  updatedAt: instanceOf(Date).isRequired,
});

export default appShape;
