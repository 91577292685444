import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import login from 'store/app/ui/login/action';
import form from 'store/app/ui/login/form/action';
import invitationDialog from 'store/app/ui/login/invitationDialog/action';

const mapStateToProps = state => {
  return {
    login: state.login,
    form: state.login.form,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    login: {
      ...bindActionCreators(login, dispatch),
      invitationDialog: bindActionCreators(invitationDialog, dispatch),
      form: bindActionCreators(form, dispatch),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
