export const SET_KEEP_SESSION_OPEN = 'SET_KEEP_SESSION_OPEN';
export const SET_RETURN_URL = 'SET_RETURN_URL';

const setReturnURL = url => ({
  type: SET_RETURN_URL,
  payload: url,
});

const setKeepSessionOpen = payload => ({
  type: SET_KEEP_SESSION_OPEN,
  payload,
});

export default { setKeepSessionOpen, setReturnURL };
