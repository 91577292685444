/* eslint-disable class-methods-use-this */
import Http from 'services/Http';

class ChatFlowPage {
  async find(flow, id) {
    const { data } = await Http.get(`/v1/chat-flow/${flow}/page/${id}`);
    return data.chatFlowPage;
  }

  async list(flow) {
    const { data } = await Http.get(`/v1/chat-flow/${flow}/page`);
    return data;
  }

  async create(flow, form) {
    const response = await Http.post(`/v1/chat-flow/${flow}/page`, form);
    return response.data.chatFlowPage;
  }

  async update(flow, id, form) {
    const response = await Http.patch(`/v1/chat-flow/${flow}/page/${id}`, form);
    return response.data.chatFlowPage;
  }

  async destroy(flow, id) {
    const { data } = await Http.delete(`/v1/chat-flow/${flow}/page/${id}`);
    return data;
  }

  async removeRoute(flow, id, routeId) {
    const { data } = await Http.delete(`/v1/chat-flow/${flow}/page/${id}/route/${routeId}`);
    return data.chatFlowPage;
  }
}

export default new ChatFlowPage();
