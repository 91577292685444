import { alpha } from '@mantine/core';
import { EmojiEmotions } from '@mui/icons-material';
import { Box, IconButton, Popover } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import useOpenClose from 'utils/useOpenClose';
import ReactionsToolbar from './ReactionsToolbar';

const styles = {
  reactions: {
    mx: 1,
  },
  reactionsButton: theme => ({
    fontSize: '0.9em',
    backgroundColor: alpha(theme.palette.action.active, theme.palette.action.hoverOpacity),
    '&:hover': {
      backgroundColor: alpha(theme.palette.action.active, theme.palette.action.hoverOpacity),
    },
  }),
};

const ReactionsButton = ({ className, message }) => {
  const anchorEl = useRef();
  const [isOpen, open, close] = useOpenClose();
  return (
    <>
      <Box sx={styles.reactions} className={className}>
        <IconButton ref={anchorEl} color="secondary" sx={styles.reactionsButton} onClick={open}>
          <EmojiEmotions sx={{ fontSize: '1rem' }} />
        </IconButton>
      </Box>
      <Popover
        slots={{
          paper: ReactionsToolbar,
        }}
        slotProps={{
          paper: {
            message,
            isOpen,
            close,
          },
        }}
        anchorEl={anchorEl.current}
        open={isOpen}
        onClose={close}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      />
    </>
  );
};

ReactionsButton.propTypes = {
  className: PropTypes.string,
  message: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    reactions: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

ReactionsButton.defaultProps = {
  className: null,
};

export default ReactionsButton;
