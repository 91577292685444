import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import companiesAction from 'store/app/entities/companies/action';
import employeeImportsAction from 'store/app/entities/employeeImports/action';
import employeesAction from 'store/app/entities/employees/action';
import alert from 'store/app/ui/Alert/action';
import employeeDrawer from 'store/app/ui/drawers/employee/action';
import employeeImports from 'store/app/ui/drawers/employeeImports/action';
import profileDrawer from 'store/app/ui/drawers/profile/action';
import authCompany from 'store/selectors/authCompany';
import authUser from 'store/selectors/authUser';

const mapStateToProps = state => {
  const { importPreview, file, isOpen, downloadUrl, fileData, loading, summary, status, error } =
    state.drawers.employeeImports;
  return {
    isSuperAdmin: authUser(state).isSuperAdmin,
    hasDivisions: authCompany(state).divisions?.length > 0,
    employeeImports: state.employeeImports.data,
    importPreview,
    file,
    isOpen,
    downloadUrl,
    fileData,
    loading,
    summary,
    status,
    error,
    authCompany: authCompany(state),
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    alert: bindActionCreators(alert, dispatch),
    companies: bindActionCreators(companiesAction, dispatch),
    employeeImports: bindActionCreators(employeeImportsAction, dispatch),
    employees: bindActionCreators(employeesAction, dispatch),
    drawers: {
      employee: bindActionCreators(employeeDrawer, dispatch),
      employeeImports: bindActionCreators(employeeImports, dispatch),
      profile: bindActionCreators(profileDrawer, dispatch),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
