import { TableCell, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MyShowMoreText from 'components/common/MyShowMoreText';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone';
import { bool, object } from 'prop-types';
import React from 'react';
import { I18n } from 'utils/i18n';

const useStyles = makeStyles(theme => ({
  root: {
    border: 0,
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.secondary.ultraUltraLight,
    },
  },
  cell: {
    minWidth: 110,
    maxWidth: 400,
  },
  inactive: {
    minWidth: 110,
    maxWidth: 400,
    color: theme.palette.secondary.main,
  },
  checkbox: {
    position: 'relative',
    padding: 0,
    width: 'auto',
  },
  icon: {
    width: 16,
    top: 12,
    position: 'absolute',
    left: 42,
    color: theme.palette.secondary.main,
  },
}));

const EmployeeRow = ({ employee, showName }) => {
  const classes = useStyles();

  const getName = e => e.name + (isEmpty(e.code) ? '' : ` - ${e.code}`);

  const ResponseContent = () => {
    if (isArray(employee.response)) {
      return employee.response.map(({ label }) => label).join(', ');
    }
    if (typeof employee.response === 'string') {
      return <MyShowMoreText lines={2}>{employee.response}</MyShowMoreText>;
    }
    return employee.response || '';
  };

  return (
    <TableRow className={classes.root} key={employee._id}>
      {showName && (
        <TableCell className={classes.cell} padding="normal">
          {getName(employee)}
        </TableCell>
      )}
      <TableCell className={classes.cell} padding="normal">
        {moment(employee.respondedAt).calendar(null, {
          sameDay: `[${I18n.t('FormAnalyticsDrawer.Today')} ${I18n.t(
            'FormAnalyticsDrawer.at',
          )}] LT`,
          lastDay: `[${I18n.t('FormAnalyticsDrawer.Yesterday')} ${I18n.t(
            'FormAnalyticsDrawer.at',
          )}] LT`,
          lastWeek: `dddd [${I18n.t('FormAnalyticsDrawer.at')}] LT`,
          sameElse: 'LL',
        })}
      </TableCell>
      <TableCell className={classes.cell} padding="normal">
        <ResponseContent />
      </TableCell>
    </TableRow>
  );
};

EmployeeRow.propTypes = {
  employee: object.isRequired,
  showName: bool.isRequired,
};

export default EmployeeRow;
