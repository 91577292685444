import React, { useCallback } from 'react';
import { object } from 'prop-types';
import PhotoUpload from 'components/controls/PhotoUpload';

const FormikPhotoUpload = ({ field, form, ...props }) => {
  const { name } = field;
  const { errors, values, submitCount, setFieldValue, setFieldTouched } = form;

  const handleChange = useCallback(
    value => {
      setFieldValue(name, value);
    },
    [name, setFieldValue],
  );
  return (
    <PhotoUpload
      {...props}
      onChange={handleChange}
      onBlur={setFieldTouched}
      value={values[name]}
      name={name}
      error={!!(errors[name] && submitCount > 0)}
      helperText={submitCount > 0 ? errors[name] : null}
    />
  );
};

FormikPhotoUpload.propTypes = {
  field: object.isRequired,
  form: object.isRequired,
};

export default FormikPhotoUpload;
