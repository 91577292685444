import { Box, Button, IconButton, SvgIcon, Tab, Tabs, TextField, Typography } from '@mui/material';
import TabPanel from 'components/@home/drawers/FormAnalyticsDrawer/TabPanel';
import Control from 'components/@whistleblower-admin/pages/Portals/Control';
import { useCanEdit } from 'components/@whistleblower-admin/WhistleblowerContext';
import Autosuggest from 'components/controls/Autosuggest';
import { useFormikContext } from 'formik';
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'utils/i18n';
import languages from 'utils/languages';
import useOpenClose from 'utils/useOpenClose';

const compare = locale => (a, b) => {
  return a.localeCompare(b, locale, { sensitivity: 'base' });
};
const LanguageControl = () => {
  const canEdit = useCanEdit('portals');
  const inputRef = useRef();
  const [newLanguage, setNewLanguage] = useState('');
  const locale = useSelector(state => state.i18n.locale);
  const [isAddingLanguage, openAddLanguage, closeAddLanguage] = useOpenClose();
  const [tab, setTab] = useState(0);
  const { values, handleBlur, handleChange, setFieldValue } = useFormikContext();
  const tabs = [
    values.defaultLanguage,
    ...Object.keys(values.message).filter(v => v !== values.defaultLanguage),
  ].sort(compare(locale));
  const handleChangeTab = (e, value) => {
    setTab(value);
  };
  useEffect(() => {
    if (newLanguage) {
      const idx = tabs.indexOf(newLanguage);
      setTab(idx);
      setTimeout(() => {
        inputRef.current.focus();
        inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 10);
      setNewLanguage(null);
    }
  }, [newLanguage, tabs]);
  const handleAddLanguage = ({ value }) => {
    setFieldValue('message', { ...values.message, [value]: null });
    setNewLanguage(value);
    closeAddLanguage();
  };
  const handleRemoveLanguage = lang => () => {
    const { [lang]: _, ...newMessage } = values.message;
    setFieldValue('message', newMessage);
  };
  useEffect(() => {
    if (tabs?.length > 0 && tab > tabs.length - 1) {
      setTab(tabs.length - 1);
    }
  }, [tabs, tab]);
  return (
    <>
      <Control
        name="languages"
        control={
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
            {isAddingLanguage ? (
              <Box sx={{ width: 200 }}>
                <Autosuggest
                  autoFocus
                  defaultMenuIsOpen
                  suggestions={languages[locale].filter(s => !tabs.includes(s.value))}
                  onChange={handleAddLanguage}
                />
              </Box>
            ) : (
              canEdit && (
                <Button variant="outlined" color="secondary" onClick={openAddLanguage}>
                  <SvgIcon
                    sx={{
                      fontSize: 18,
                      marginRight: 0.5,
                    }}
                  >
                    <PlusCircleOutlineIcon />
                  </SvgIcon>
                  {I18n.t('WhistleblowerPortalConfig.AddLanguage')}
                </Button>
              )
            )}
          </Box>
        }
      />
      <Tabs
        onChange={handleChangeTab}
        value={tab}
        orientation="vertical"
        variant="scrollable"
        aria-label="Vertical tabs example"
        sx={{
          mt: 2,
          minWidth: 198,
          borderRight: 1,
          borderColor: 'divider',
        }}
      >
        {tabs.map((lang, idx) => (
          <Tab
            sx={{ '&.Mui-selected p ': { color: 'primary.main', fontWeight: 'bold' } }}
            key={lang}
            label={
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Typography>{languages[locale].find(l => l.value === lang).label}</Typography>
                {canEdit && (
                  <IconButton
                    disabled={lang === values.defaultLanguage}
                    onClick={handleRemoveLanguage(lang)}
                    color="secondary"
                    size="small"
                    variant="text"
                    sx={{ padding: 0, minWidth: 30, minHeight: 30 }}
                  >
                    <TrashCanOutlineIcon size={16} />
                  </IconButton>
                )}
              </Box>
            }
            value={idx}
          />
        ))}
      </Tabs>
      <Box sx={{ ml: 2, mt: 2, alignSelf: 'flex-start', flexGrow: 1 }}>
        {tabs.map((lang, idx) => {
          if (!values.message?.[lang]) {
            import(`components/@whistleblower/translations/${lang}.json`).then(messages => {
              setFieldValue(`message.${lang}`, messages.START_DESCRIPTION);
            });
          }
          return (
            <TabPanel key={lang} value={tab} index={idx}>
              <TextField
                disabled={!canEdit}
                inputRef={inputRef}
                minRows={8}
                fullWidth
                multiline
                name={`message.${lang}`}
                value={values.message[lang] || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                id={`message.${lang}`}
              />
            </TabPanel>
          );
        })}
      </Box>
    </>
  );
};

export default LanguageControl;
