/**
 * @flow
 */
import { Box, Typography } from '@mui/material';
import OccurrenceDrawerContent from 'components/@whistleblower-admin/drawers/OccurrenceDrawer/OccurrenceDrawerContent';
import columns from 'components/@whistleblower-admin/pages/OccurrenceList/columns';
import useDecrypt from 'components/@whistleblower-admin/pages/OccurrenceList/useDecrypt';
import { useWhistleblower } from 'components/@whistleblower-admin/WhistleblowerContext';
import { useDrawer } from 'components/common/Drawer/DrawerContext';
import DrawerProvider from 'components/common/Drawer/DrawerProvider';
import OmmnioDataGrid from 'components/common/OmmnioDataGrid';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'utils/i18n';
import { useNavigate, useOutletContext } from 'react-router';
import { Outlet, useParams } from 'react-router-dom';
import { useGetOccurrencesQuery } from 'store/app/entities/WbSlice';

export const OpenOccurrence = () => {
  const { rows } = useOutletContext();
  const { id } = useParams();
  const state = useDrawer('OccurrenceDrawer');
  const { show, hide, visible } = state;
  useEffect(() => {
    const occurrence = rows?.find(row => row._id === id);
    if (id && occurrence) {
      show(occurrence);
    } else if (!id && visible) {
      hide();
    }
  }, [id, rows, show]);
  return null;
};

const OccurrenceList = () => {
  const [pageSize, setPageSize] = useState(0);
  const { publicKey, privateKey } = useWhistleblower();
  const locale = useSelector(state => state.i18n.locale);
  const { data, isLoading, isUninitialized } = useGetOccurrencesQuery(
    { body: { publicKey } },
    { skip: !publicKey || !pageSize },
  );
  const { rows, decrypting } = useDecrypt(data?.docs, privateKey) || [];
  const navigate = useNavigate();

  moment.locale(locale);

  const handleRowClick = ({ id }) => {
    navigate(`/wb-admin/occurrences/${id}`);
  };

  return (
    <Box
      sx={{
        p: 3,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Typography variant="h2" gutterBottom>
        {I18n.t('WhistleblowerAdmin.OccurrenceList.title')}
      </Typography>
      <DrawerProvider
        name="OccurrenceDrawer"
        drawerContent={<OccurrenceDrawerContent />}
        onClose={() => navigate('/wb-admin/occurrences', { replace: true })}
      >
        <OmmnioDataGrid
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          sx={{ cursor: 'pointer' }}
          onRowClick={handleRowClick}
          loading={isUninitialized || isLoading || decrypting}
          autoPageSize
          columns={columns(locale)}
          rows={rows || []}
        />
        <Outlet context={{ rows }} />
      </DrawerProvider>
    </Box>
  );
};

export default OccurrenceList;
