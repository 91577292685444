import React from 'react';
import { object, string } from 'prop-types';
import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
  drawerTitle: {
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSize: '28px',
    fontWeight: 'bold',
    letterSpacing: '-0.7px',
    fontStyle: 'normal',
    fontStretch: 'normal',
    color: theme.palette.primary.ultraDark,
  },
});

const DrawerTitle = ({ classes, children }) => (
  <Typography className={classes.drawerTitle} component="div">
    {children}
  </Typography>
);
DrawerTitle.propTypes = {
  classes: object.isRequired,
  children: string.isRequired,
};

export default withStyles(styles)(DrawerTitle);
