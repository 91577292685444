import {
  CLOSE_ACKNOWLEDGMENT_READ_MODAL,
  OPEN_ACKNOWLEDGMENT_READ_MODAL,
  OPEN_DIGITAL_SIGNATURE_READ_MODAL_FULFILLED,
} from './action';

const initialState = {
  isOpen: false,
  isSignatureOpen: false,
};

const createModal = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_ACKNOWLEDGMENT_READ_MODAL:
      return {
        ...state,
        isOpen: true,
        message: action.payload.message,
        employee: action.payload.employee,
        log: action.payload.log,
      };

    case CLOSE_ACKNOWLEDGMENT_READ_MODAL:
      return initialState;

    case OPEN_DIGITAL_SIGNATURE_READ_MODAL_FULFILLED:
      return {
        ...state,
        isSignatureOpen: true,
        signUrl: action.payload.signUrl,
        message: action.meta.message,
        employee: action.meta.employee,
      };

    default:
      return state;
  }
};

export default createModal;
