import isArray from 'lodash/isArray';
import removeAccents from 'utils/removeAccents';

export default (searchString, employees, filters = {}, { fullDoc = false } = {}) => {
  const searchStrings = removeAccents(searchString)
    .toLowerCase()
    .split(/[^a-z0-9]+/)
    .filter(s => s);
  let ret = employees;
  if (filters.status) {
    ret = ret.filter(e => ['All', e.status].includes(filters.status || 'All'));
  }
  if (filters.answer) {
    ret = ret.filter(e =>
      ['All', e.response].some(value => {
        return isArray(value)
          ? value.some(v => v._id === filters.answer)
          : value === filters.answer;
      }),
    );
  }
  ret = ret.filter(e =>
    searchStrings.every(str =>
      e.user?._id === str || e._id === str || e.searchBlob
        ? e.searchBlob.includes(str)
        : removeAccents(
            `${e.firstName} ${e.surName} ${e.division} ${e.code} ${e.department} ${e.jobTitle} ${
              e.location
            } ${e.reportsTo} ${e.user?.phone} ${e.tags?.join(' ')} ${e.notes} ${e.teams
              ?.map(t => t.name)
              .join(' ')} ${e.response}`,
          )
            .toLowerCase()
            .includes(str),
    ),
  );
  if (!fullDoc) {
    return ret.map(e => e._id);
  }
  return ret;
};
