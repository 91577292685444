import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as chatPanel from 'store/app/ui/ChatPanelSlice';
import currentChannel from 'store/selectors/currentChannel';

const mapStateToProps = state => {
  return {
    currentChannel: currentChannel(state),
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    chatPanel: bindActionCreators(chatPanel, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
