/**
 * @flow
 */
import { SvgIcon, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import QuestionForm from 'components/@home/drawers/FillFormDrawer/QuestionForm';
import ArrowRightIcon from 'mdi-react/ArrowRightIcon';
import React from 'react';
import { Transition } from 'react-transition-group';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
  },
  question: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  title: {
    marginLeft: theme.spacing(1),
  },
  transition: {
    overflow: 'hidden',
    width: '100%',
    transition: 'opacity 300ms, transform 300ms',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  questionTitle: {
    marginBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  questionForm: {
    marginLeft: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  arrowIcon: {
    fontSize: 16,
    color: theme.palette.primary.dark,
  },
  num: {
    display: 'flex',
    flexDirection: 'row',
    width: theme.spacing(4),
    textAlign: 'right',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

const useTransitionStyles = makeStyles(() => ({
  exited: {
    transition: 'none',
    display: 'none',
    opacity: 0,
    transform: 'translateY(100%)',
  },
  entered: {
    opacity: 1,
    transform: 'translateY(0%)',
  },
  exiting: {
    position: 'absolute',
    opacity: 0,
    transform: 'translateY(-100%)',
  },
  entering: {
    opacity: 1,
    transform: 'translateY(100%)',
  },
}));
type Props = {
  form: Object,
};

const FillFormDrawerQuestions = ({
  form: {
    values: { form, currentQuestion },
  },
}: Props) => {
  const classes = useStyles();
  const transitionClasses = useTransitionStyles();
  if (!form.questions) {
    return null;
  }
  return form.questions.map((question, index) => (
    <Transition
      timeout={300}
      key={question._id}
      classNames={transitionClasses}
      in={index === currentQuestion}
    >
      {state => (
        <div className={classNames(transitionClasses[state], classes.transition)}>
          <div className={classes.question}>
            <div className={classes.questionTitle}>
              <div className={classes.num}>
                <Typography color="primary">{index + 1}</Typography>
                <SvgIcon className={classes.arrowIcon}>
                  <ArrowRightIcon />
                </SvgIcon>
              </div>
              <Typography variant="h6" component="h6" className={classes.title}>
                {question.title}
              </Typography>
            </div>
            <div className={classes.questionForm}>
              <QuestionForm question={question} />
            </div>
          </div>
        </div>
      )}
    </Transition>
  ));
};

export default FillFormDrawerQuestions;
