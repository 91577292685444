import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { arrayOf, bool, func, node, shape, string } from 'prop-types';
import React, { useCallback } from 'react';
import useKeyDown from 'utils/useKeyDown';

const ConfirmDialog = ({ message, onClose, buttons, isOpen, children, ...rest }) => {
  const handleEnter = useCallback(() => {
    if (isOpen) {
      buttons.find(({ type }) => type === 'submit')?.onClick?.();
    }
  }, [buttons, isOpen]);
  const dialogRef = useKeyDown({ Enter: handleEnter });
  return (
    <Dialog
      ref={dialogRef}
      scroll="body"
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...rest}
    >
      <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
      <DialogContent id="alert-dialog-description">{children}</DialogContent>
      <DialogActions>
        {buttons.map(
          ({
            text,
            onClick = onClose,
            variant = 'outlined',
            color = 'secondary',
            ...buttonRest
          }) => (
            <Button key={text} onClick={onClick} variant={variant} color={color} {...buttonRest}>
              {text}
            </Button>
          ),
        )}
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  message: node,
  onClose: func.isRequired,
  isOpen: bool.isRequired,
  children: node,
  buttons: arrayOf(
    shape({
      text: string.isRequired,
      onClick: func.isRequired,
    }),
  ).isRequired,
  onSubmit: func,
};

ConfirmDialog.defaultProps = {
  children: null,
  message: null,
  onSubmit: () => {},
};

export default ConfirmDialog;
