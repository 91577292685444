import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import media from 'store/app/ui/media/action';
import getMedia from 'store/selectors/getMedia';

const mapStateToProps = state => ({
  links: getMedia(state, 'links'),
  total: getMedia(state, 'links_total'),
});

const mapDispatchToProps = dispatch => ({
  actions: {
    media: bindActionCreators(media, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
