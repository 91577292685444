import React from 'react';

/**
 * This function applies a function to all text children of a React element traversing the tree recursively.
 * @param children The children of the React element
 * @param formattingFunction The function to apply to the text children
 * @returns {Array<Exclude<unknown, boolean | null | undefined>>}
 */
const applyToTextChildren = (children, formattingFunction) => {
  return React.Children.map(children, child => {
    if (typeof child === 'string') {
      return formattingFunction(child);
    }
    if (React.isValidElement(child) && child.props && child.props.children) {
      const formattedChildren = applyToTextChildren(child.props.children, formattingFunction);
      return React.cloneElement(child, child.props, formattedChildren);
    }
    return child;
  });
};

export default applyToTextChildren;
