import { LOAD_SCOPES_PENDING, LOAD_SCOPES_FULFILLED, LOAD_SCOPES_REJECTED } from './action';

const initialState = {
  scopes: [],
  loading: false,
};

const scopesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_SCOPES_PENDING:
      return {
        ...state,
        loading: true,
      };

    case LOAD_SCOPES_FULFILLED:
      return {
        ...state,
        loading: false,
        scopes: payload,
      };

    case LOAD_SCOPES_REJECTED:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default scopesReducer;
