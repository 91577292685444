const saintGobain = {
  primary: {
    '2': '#00061c',
    '5': '#000f2f',
    '10': '#001944',
    '20': '#002d6e',
    '30': '#19448e',
    '40': '#375ca7',
    '50': '#5275c2',
    '60': '#6c8fde',
    '70': '#87aafa',
    '80': '#b0c6ff',
    '90': '#d9e2ff',
    '95': '#edf0ff',
    '98': '#faf8ff',
    '99': '#fefbff',
  },
  secondary: {
    '2': '#05080a',
    '5': '#0e1114',
    '10': '#191c1f',
    '20': '#2e3134',
    '30': '#44474a',
    '40': '#5c5e62',
    '50': '#75777b',
    '60': '#8f9194',
    '70': '#a9abaf',
    '80': '#c5c6ca',
    '90': '#e1e2e6',
    '95': '#eff1f5',
    '98': '#f8f9fd',
    '99': '#fcfcff',
  },
  tertiary: {
    '2': '#000908',
    '5': '#001412',
    '10': '#00201d',
    '20': '#003732',
    '30': '#005049',
    '40': '#006a61',
    '50': '#00867b',
    '60': '#00a295',
    '70': '#1bbfb0',
    '80': '#4bdbcb',
    '90': '#6df8e7',
    '95': '#b3fff3',
    '98': '#e4fff9',
    '99': '#f2fffb',
  },
  neutral: {
    '2': '#07070a',
    '5': '#101114',
    '10': '#1b1b1e',
    '20': '#303033',
    '30': '#46464a',
    '40': '#5e5e62',
    '50': '#77777a',
    '60': '#919094',
    '70': '#acabaf',
    '80': '#c7c6ca',
    '90': '#e3e2e6',
    '95': '#f2f0f4',
    '98': '#fbf8fd',
    '99': '#fefbff',
  },
  neutralVariant: {
    '2': '#05070d',
    '5': '#0e1118',
    '10': '#191b22',
    '20': '#2e3038',
    '30': '#44464e',
    '40': '#5c5e66',
    '50': '#75777f',
    '60': '#8f9099',
    '70': '#a9abb4',
    '80': '#c5c6d0',
    '90': '#e1e2ec',
    '95': '#eff0fa',
    '98': '#faf8ff',
    '99': '#fefbff',
  },
  error: {
    '2': '#1a0000',
    '5': '#2d0001',
    '10': '#410002',
    '20': '#690005',
    '30': '#93000a',
    '40': '#ba1a1a',
    '50': '#de3730',
    '60': '#ff5449',
    '70': '#ff897d',
    '80': '#ffb4ab',
    '90': '#ffdad6',
    '95': '#ffedea',
    '98': '#fff8f7',
    '99': '#fffbff',
  },
  analogous: ['#52adc2', '#5275c2', '#6752c2'],
  tetradic: ['#37a74a', '#a78237', '#375ca7', '#a73794'],
  icons: ['#1cc238', '#c27d1c', '#375ca7', '#c21c29', '#621cc2'],
};

export default saintGobain;
