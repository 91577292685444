import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import acknowledgmentReadModal from 'store/app/ui/modals/acknowledgment/read/action';
import fillFormDrawer from 'store/app/ui/drawers/fillForm/action';
import currentChannel from 'store/selectors/currentChannel';
import authEmployee from 'store/selectors/authEmployee';

const mapStateToProps = state => ({
  channel: currentChannel(state),
  employee: authEmployee(state),
});

const mapDispatchToProps = dispatch => ({
  actions: {
    fillFormDrawer: bindActionCreators(fillFormDrawer, dispatch),
    acknowledgmentReadModal: bindActionCreators(acknowledgmentReadModal, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
