import isSensitive from 'components/@whistleblower/utils/isSensitive';
import { useEffect, useState } from 'react';
import { decrypt, rsaDecryptAes } from 'utils/e2eEncryption';

const decryptDocs = async (docs, privateKey) => {
  if (!docs) {
    return docs;
  }
  const newDocs = await Promise.all(
    docs.map(async occurrence => {
      const aesKey = await rsaDecryptAes(
        occurrence.encryptionKey.aesKeyDestRsaEncrypted,
        privateKey,
      );
      return {
        ...Object.fromEntries(
          await Promise.all(
            Object.entries(occurrence).map(async ([key, value]) => {
              if (isSensitive(key)) {
                const decryptedData = await decrypt(value, aesKey);
                return [key, decryptedData];
              }
              return [key, value];
            }),
          ),
        ),
        aesKey,
      };
    }),
    {},
  );
  return newDocs;
};

const useDecrypt = (docs, privateKey) => {
  const [decrypting, setDecrypting] = useState(true);
  const [decryptedDocs, setDecryptedDocs] = useState(null);
  useEffect(() => {
    if (docs && privateKey) {
      decryptDocs(docs, privateKey)
        .then(newDocs => {
          setDecryptedDocs(newDocs);
        })
        .finally(() => {
          setDecrypting(false);
        });
    }
  }, [docs, privateKey]);
  return { rows: decryptedDocs, decrypting };
};
export default useDecrypt;
