import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import EmployeeAvatar from 'components/@home/EmployeeAvatar';
import WbUserDialog from 'components/@whistleblower-admin/pages/Users/WbUserDialog';
import { useCanEdit } from 'components/@whistleblower-admin/WhistleblowerContext';
import Loading from 'components/common/Loading';
import PencilIcon from 'mdi-react/PencilIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import React from 'react';
import { I18n } from 'utils/i18n';
import { useGetUsersQuery } from 'store/app/entities/WbSlice';
import useOpenClose from 'utils/useOpenClose';

const Users = () => {
  const canEdit = useCanEdit('users');
  const [isAddNewOpen, openAddNew, closeAddNew, addNewArgs] = useOpenClose();
  const { data, isLoading } = useGetUsersQuery();
  return (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 3 }}>
        <Typography variant="h2">{I18n.t('WhistleblowerAdmin.Sections.users')}</Typography>
        {canEdit && (
          <Button variant="contained" startIcon={<PlusIcon size={16} />} onClick={openAddNew}>
            {I18n.t('WhistleblowerAdmin.AddNew')}
          </Button>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          flexGrow: 1,
          position: 'relative',
        }}
      >
        <TableContainer component={Paper} sx={{ flexGrow: 1, width: '100%' }}>
          <Table sx={{ minWidth: 650, borderTop: 1, '&, & td, & th': { borderColor: 'divider' } }}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={3}>{I18n.t('WhistleblowerPortalConfig.atts.user')}</TableCell>
                <TableCell colSpan={3} sx={{ borderLeft: 1 }}>
                  {I18n.t('WhistleblowerPortalConfig.atts.permissions')}
                </TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell />
                <TableCell>{I18n.t('WhistleblowerPortalConfig.atts.name')}</TableCell>
                <TableCell>{I18n.t('WhistleblowerPortalConfig.atts.code')}</TableCell>
                <TableCell sx={{ borderLeft: 1 }}>
                  {I18n.t('WhistleblowerPortalConfig.permissions.occurrences')}
                </TableCell>
                <TableCell>{I18n.t('WhistleblowerPortalConfig.permissions.portals')}</TableCell>
                <TableCell>{I18n.t('WhistleblowerPortalConfig.permissions.users')}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            {isLoading ? (
              <Loading size={60} sx={{ position: 'absolute' }} />
            ) : (
              data?.map(user => (
                <TableRow key={user._id}>
                  <TableCell>
                    <EmployeeAvatar employee={user.employee} />
                  </TableCell>
                  <TableCell>{user.employee.name}</TableCell>
                  <TableCell>{user.employee.code}</TableCell>
                  <TableCell sx={{ borderLeft: 1 }}>
                    {I18n.t(`WhistleblowerPortalConfig.levels.${user.permissions.occurrences}`)}
                  </TableCell>
                  <TableCell>
                    {I18n.t(`WhistleblowerPortalConfig.levels.${user.permissions.portals}`)}
                  </TableCell>
                  <TableCell>
                    {I18n.t(`WhistleblowerPortalConfig.levels.${user.permissions.users}`)}
                  </TableCell>
                  <TableCell>
                    {canEdit && (
                      <IconButton onClick={() => openAddNew({ user })}>
                        <PencilIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </Table>
        </TableContainer>
      </Box>
      <WbUserDialog isOpen={isAddNewOpen} close={closeAddNew} user={addNewArgs.user} />
    </Box>
  );
};

export default Users;
