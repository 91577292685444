/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { func, node, object } from 'prop-types';
import classNames from 'classnames';

const styles = () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: '44px',
    paddingRight: '20px',
    boxShadow: 'inset 0 -1px 0 0 #dde2eb',
  },
  heading: {
    height: '22px',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSize: '15px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: '-0.5px',
    lineHeight: 1.47,
  },
  placeholder: {
    marginLeft: 'auto',
  },
  active: {
    cursor: 'pointer',
  },
});

const Item = ({ classes, headline, checkbox, onClick }) => (
<div
    className={classNames({ [classes.root]: true, [classes.active]: onClick })}
    onClick={onClick}
  >
    <div className={classes.heading}>
      {headline}
    </div>
    {
      checkbox && (
        <div className={classes.placeholder}>
          {checkbox}
        </div>
      )
    }
  </div>
);Item.propTypes = {
  classes: object.isRequired,
  headline: node.isRequired,
  checkbox: node,
  onClick: func,
};

Item.defaultProps = {
  checkbox: null,
  onClick: null,
};

export default withStyles(styles)(Item);
