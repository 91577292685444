const touches = (a, b) => {
  // has horizontal gap
  if (a.left > b.right || b.left > a.right) return false;

  // has vertical gap
  if (a.top > b.bottom || b.top > a.bottom) return false;

  // vertical overlap more than 60% of b
  const overlapHeight = Math.min(a.bottom, b.bottom) - Math.max(a.top, b.top);
  const bHeight = b.bottom - b.top;
  return overlapHeight / bHeight > 0.6;
};

const lastWordIndex = (str, from) =>
  Array.from(str.substr(0, from).matchAll(new RegExp('(?<=\\w)[^\\w]', 'g')))?.pop()?.index || -1;

export const rectangleSelect = (inputElements, selectionRectangle) => {
  const elements = [];
  inputElements.forEach(element => {
    const e = element;
    const eRect = {
      left: e.offsetLeft,
      right: e.offsetLeft + e.offsetWidth,
      top: e.offsetTop,
      bottom: e.offsetTop + e.offsetHeight,
    };
    if (touches(selectionRectangle, eRect)) {
      elements.push(e);
    }
  });
  const selection = window.getSelection();
  selection.removeAllRanges();
  if (elements.length) {
    const range = document.createRange();
    let offsetStart = 0;
    const startEl = elements[0];
    const endEl = elements[elements.length - 1];
    const addX = startEl.offsetLeft - startEl.getBoundingClientRect().left;
    range.setStart(startEl.firstChild, offsetStart);
    let offsetEnd = endEl.innerText.length;
    range.setEnd(endEl.firstChild, offsetEnd);
    if (Math.abs(startEl.offsetTop - endEl.offsetTop) > 3) {
      selection.addRange(range);
      throw new Error('You selected multiple lines');
    }
    const selLeft = () => range.getBoundingClientRect().left + addX;
    const selRight = () => range.getBoundingClientRect().right + addX;
    while (selLeft() < selectionRectangle.left && offsetStart < startEl.innerText.length) {
      const nextWord = startEl.innerText.substr(offsetStart).match(/[^\w]\w/)?.index;
      offsetStart =
        typeof nextWord === 'number' ? offsetStart + nextWord + 1 : startEl.innerText.length;
      range.setStart(startEl.firstChild, offsetStart);
    }
    while (selRight() > selectionRectangle.right && offsetEnd > 0) {
      const prevWord = lastWordIndex(endEl.innerText, offsetEnd);
      offsetEnd = prevWord > 0 ? prevWord : 0;
      range.setEnd(endEl.firstChild, offsetEnd);
    }
    selection.addRange(range);
  }
  return elements;
};
