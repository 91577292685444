/* eslint-disable react/jsx-props-no-spreading */
/**
 * @flow
 */
import { FormControl, FormHelperText, OutlinedInput } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { makeStyles, styled, useTheme } from '@mui/styles';
import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import connector from './connector';

type Props = {
  localization: Object;
  formControlClass: String;
  error: boolean;
  helperText?: String;
  name?: String;
  inputProps?: Object;
};

const useStyles = makeStyles(theme => ({
  button: {
    border: '0 !important',
    borderRight: error => `solid 1px ${error ? '#f44336' : '#d5d6e3'}`,
    margin: -1,
    '&.flag-dropdown': {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
    },
    '&.open, &.open .selected-flag': {
      backgroundColor: 'transparent !important',
    },
    '&:focus .selected-flag, &:hover .selected-flag': {
      backgroundColor: 'inherit',
    },
  },
  dropdown: {
    '&.country-list': {
      backgroundColor: theme.palette.background.paper,
      backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))',
      boxShadow: 'rgba(0, 0, 0, 0.25) 1px 2px 18px;',
      borderRadius: 7,
      '& .country': {
        '&.highlight, &:hover': {
          backgroundColor: alpha(theme.palette.primary.main, 0.08),
        },
      },
    },
  },
}));

const Input = ({ name, error, localization, inputProps, props }) => {
  const classes = useStyles({ error });
  const theme = useTheme();
  return (
    <PhoneInput
      onKeyDown={e => {
        e.stopPropagation();
      }}
      localization={localization}
      buttonClass={classes.button}
      dropdownClass={classes.dropdown}
      style={{
        marginTop: 2,
        marginBottom: 2,
      }}
      inputProps={{ name, ...inputProps }}
      inputStyle={{
        color: theme.palette.text.primary,
        width: 'calc(100% - 58px)',
        border: 0,
        backgroundColor: 'transparent',
      }}
      {...props}
    />
  );
};

const PhoneControl = ({
  formControlClass,
  localization,
  error,
  helperText,
  name,
  inputProps,
  ...props
}: Props) => {
  return (
    <FormControl error={error} className={formControlClass} fullWidth>
      <OutlinedInput
        components={{
          Input,
        }}
        inputProps={{ name, error, localization, inputProps, props }}
      >
        {/* $FlowFixMe */}
      </OutlinedInput>
      {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    </FormControl>
  );
};

PhoneControl.defaultProps = {
  helperText: null,
  name: null,
  inputProps: {},
};

export default connector(
  styled(PhoneControl, { name: 'MuiFormControl', slot: 'Root' })({ padding: '0' }),
);
