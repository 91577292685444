import ChatFlowEntityType from 'services/api/ChatFlowEntityType';

export const FIND_CHAT_FLOW_ENTITY_TYPE = 'FIND_CHAT_FLOW_ENTITY_TYPE';
export const FIND_CHAT_FLOW_ENTITY_TYPE_PENDING = 'FIND_CHAT_FLOW_ENTITY_TYPE_PENDING';
export const FIND_CHAT_FLOW_ENTITY_TYPE_FULFILLED = 'FIND_CHAT_FLOW_ENTITY_TYPE_FULFILLED';

export const LOAD_CHAT_FLOW_ENTITY_TYPES = 'LOAD_CHAT_FLOW_ENTITY_TYPES';
export const LOAD_CHAT_FLOW_ENTITY_TYPES_PENDING = 'LOAD_CHAT_FLOW_ENTITY_TYPES_PENDING';
export const LOAD_CHAT_FLOW_ENTITY_TYPES_FULFILLED = 'LOAD_CHAT_FLOW_ENTITY_TYPES_FULFILLED';

export const CREATE_CHAT_FLOW_ENTITY_TYPE = 'CREATE_CHAT_FLOW_ENTITY_TYPE';
export const CREATE_CHAT_FLOW_ENTITY_TYPE_PENDING = 'CREATE_CHAT_FLOW_ENTITY_TYPE_PENDING';
export const CREATE_CHAT_FLOW_ENTITY_TYPE_FULFILLED = 'CREATE_CHAT_FLOW_ENTITY_TYPE_FULFILLED';

export const UPDATE_CHAT_FLOW_ENTITY_TYPE = 'UPDATE_CHAT_FLOW_ENTITY_TYPE';
export const UPDATE_CHAT_FLOW_ENTITY_TYPE_PENDING = 'UPDATE_CHAT_FLOW_ENTITY_TYPE_PENDING';
export const UPDATE_CHAT_FLOW_ENTITY_TYPE_FULFILLED = 'UPDATE_CHAT_FLOW_ENTITY_TYPE_FULFILLED';

export const DESTROY_CHAT_FLOW_ENTITY_TYPE = 'DESTROY_CHAT_FLOW_ENTITY_TYPE';
export const DESTROY_CHAT_FLOW_ENTITY_TYPE_PENDING = 'DESTROY_CHAT_FLOW_ENTITY_TYPE_PENDING';
export const DESTROY_CHAT_FLOW_ENTITY_TYPE_FULFILLED = 'DESTROY_CHAT_FLOW_ENTITY_TYPE_FULFILLED';

const load = () => ({
  type: LOAD_CHAT_FLOW_ENTITY_TYPES,
  payload: ChatFlowEntityType.list(),
});

const create = form => ({
  type: CREATE_CHAT_FLOW_ENTITY_TYPE,
  payload: ChatFlowEntityType.create(form),
});

const update = (id, form) => ({
  type: UPDATE_CHAT_FLOW_ENTITY_TYPE,
  payload: ChatFlowEntityType.update(id, form),
  meta: { entityTypeId: id },
});

const find = id => ({
  type: FIND_CHAT_FLOW_ENTITY_TYPE,
  payload: ChatFlowEntityType.find(id),
  meta: { entityTypeId: id },
});

const destroy = id => ({
  type: DESTROY_CHAT_FLOW_ENTITY_TYPE,
  payload: ChatFlowEntityType.destroy(id),
  meta: { entityTypeId: id },
});

export default {
  create,
  destroy,
  find,
  load,
  update,
};
