import { Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { number, string } from 'prop-types';
import React from 'react';
import { I18n } from 'utils/i18n';
import Line from './Line';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  progress: {
    display: 'flex',
    borderRadius: 5,
    overflow: 'hidden',
  },
  labels: {
    paddingBottom: 5,
    display: 'flex',
    justifyContent: 'space-between',
  },
  label: {
    fontWeight: 'bold',
    color: props => props.unreadColor || theme.palette.danger,
  },
}));

const ReadProgressBar = ({ read, total, readColor: readColorIn, unreadColor: unreadColorIn }) => {
  const theme = useTheme();
  const unreadColor = unreadColorIn || theme.palette.danger;
  const readColor = readColorIn || theme.palette.primary.main;
  const classes = useStyles({ unreadColor });
  const unread = total - read;
  const readPercent = parseInt((read * 100) / total, 10) || 0;
  const unreadPercent = parseInt(100 - readPercent, 10) || 0;

  return (
    <div className={classes.root}>
      <div className={classes.labels}>
        <Typography className={classes.label} style={{ color: readColor }}>
          {`${read} (${readPercent}%)`}
        </Typography>
        <Typography color="secondary">
          {I18n.t('AnalyticsDrawer.Total', { count: total })}
        </Typography>
        <Typography className={classes.label} style={{ color: unreadColor }}>
          {`${unread} (${unreadPercent}%)`}
        </Typography>
      </div>
      <div className={classes.progress}>
        <Line percent={readPercent} color={readColor} />
        {readPercent && unreadPercent ? <div style={{ width: 4 }} /> : null}
        <Line percent={unreadPercent} color={unreadColor} />
      </div>
    </div>
  );
};

ReadProgressBar.propTypes = {
  read: number.isRequired,
  total: number.isRequired,
  readColor: string,
  unreadColor: string,
};

ReadProgressBar.defaultProps = {
  readColor: null,
  unreadColor: null,
};

export default ReadProgressBar;
