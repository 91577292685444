import React from 'react';
import { bool, object, string } from 'prop-types';
import { SvgIcon, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import DotIcon from 'assets/icons/dot.svg';
import classNames from 'classnames';

const styles = theme => ({
  root: {
    paddingBottom: 10,
  },
  userName: {
    fontSize: 28,
    fontWeight: 'bold',
    color: theme.palette.primary.ultraDark,
    textTransform: 'capitalize',
  },
  userJob: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    textTransform: 'capitalize',
  },
  dotIcon: {
    fontSize: 4,
    marginRight: 10,
    marginLeft: 10,
  },
  tag: {
    fontSize: '0.5em',
    padding: '0 4px',
    backgroundColor: theme.palette.secondary.light,
    borderRadius: 4,
  },
});

const GeneralInfo = ({ className, classes, name, code, jobTitle, department, isApp }) => (
  <div className={classNames(classes.root, className)}>
    <Typography className={classes.userName}>
      {name} {isApp ? <span className={classes.tag}>App</span> : null}
    </Typography>
    <div className={classes.userJob}>
      {code}{' '}
      <SvgIcon className={classes.dotIcon}>
        <DotIcon />
      </SvgIcon>
      {jobTitle}{' '}
      <SvgIcon className={classes.dotIcon}>
        <DotIcon />
      </SvgIcon>{' '}
      {department}
    </div>
  </div>
);
GeneralInfo.propTypes = {
  isApp: bool.isRequired,
  className: string,
  classes: object.isRequired,
  name: string.isRequired,
  jobTitle: string,
  department: string,
  code: string,
};

GeneralInfo.defaultProps = {
  className: '',
  jobTitle: '',
  code: '',
  department: '',
};

export default withStyles(styles)(GeneralInfo);
