import { FormControl, FormControlLabel, FormHelperText, RadioGroup } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import StyledRadio from 'components/controls/StyledRadio';
import { array, object, string } from 'prop-types';
import React from 'react';

const useStyles = makeStyles(() => ({
  label: {
    lineHeight: '19px',
  },
}));

const FormikRadioGroup = ({
  field,
  form,
  items,
  groupClassName,
  itemClassName,
  itemSelectedClassName,
  ...props
}) => {
  const { errors, submitCount, getFieldProps } = form;
  const { name, onChange, onBlur } = field;
  const { value } = getFieldProps(name);
  const classes = useStyles();
  return (
    <FormControl
      error={!!(errors[name] && submitCount > 0)}
      fullWidth
      component="fieldset"
      {...props}
    >
      <RadioGroup
        aria-label={name}
        name={name}
        className={groupClassName}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      >
        {items.map(([key, val]) => (
          <FormControlLabel
            className={classNames({
              [itemClassName]: true,
              [itemSelectedClassName]: key === value,
            })}
            key={key}
            value={key}
            checked={key === value}
            control={<StyledRadio />}
            label={val}
            classes={{ label: classes.label }}
          />
        ))}
      </RadioGroup>
      <FormHelperText>{submitCount > 0 && errors[name]}</FormHelperText>
    </FormControl>
  );
};

FormikRadioGroup.propTypes = {
  field: object.isRequired,
  form: object.isRequired,
  groupClassName: string,
  items: array.isRequired,
  itemClassName: string,
  itemSelectedClassName: string,
};
FormikRadioGroup.defaultProps = {
  groupClassName: null,
  itemClassName: null,
  itemSelectedClassName: null,
};

export default FormikRadioGroup;
