import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import teams from 'store/app/entities/teams/action';
import { invalidateTeamsTags } from 'store/app/entities/TeamsSlice';
import alert from 'store/app/ui/Alert/action';
import teamDrawer from 'store/app/ui/drawers/team/action';
import authCompany from 'store/selectors/authCompany';
import authEmployee from 'store/selectors/authEmployee';
import authUser from 'store/selectors/authUser';
import { populateAdminEmployees } from 'store/selectors/populateEmployees';

const mapStateToProps = state => ({
  isEdit: !!state.drawers.team.team._id,
  authEmployee: authEmployee(state),
  authCompany: authCompany(state),
  isSuperAdmin: authUser(state).isSuperAdmin,
  teamId: state.drawers.team.team._id,
  team: state.teams.teams?.[state.drawers.team.team._id],
  employees: Object.values(populateAdminEmployees(state)).filter(
    e => e._id !== authEmployee(state)?._id,
  ),
  loading: state.teams.loading,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    teams: bindActionCreators(teams, dispatch),
    alert: bindActionCreators(alert, dispatch),
    drawers: {
      team: bindActionCreators(teamDrawer, dispatch),
    },
    invalidateTeamsTags: tags => dispatch(invalidateTeamsTags(tags)),
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
