import makeStyles from '@mui/styles/makeStyles';
import { element } from 'prop-types';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
  },
}));

const SectionBody = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
};

SectionBody.propTypes = {
  children: element.isRequired,
};

export default SectionBody;
