import { Box } from '@mui/material';
import AdminMenu from 'components/@admin/AdminMenu';
import React from 'react';
import { Outlet } from 'react-router-dom';

const AdminLayout = () => (
  <Box
    sx={{
      flexGrow: 1,
      position: 'relative',
      display: 'flex',
    }}
  >
    <Box
      sx={{
        display: {
          sx: 'none',
          md: 'block',
        },
        width: 250,
        height: '100%',
      }}
    >
      <AdminMenu />
    </Box>
    <Outlet />
  </Box>
);

export default AdminLayout;
