import { Avatar, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import InviteByEmail from 'components/@home/drawers/ProfileDrawer/Profile/EmployeeActions/InviteByEmail';
import { bool, object } from 'prop-types';
import React from 'react';
import employeeShape from 'shapes/employee';
import ChangeAvatar from './ChangeAvatar';
import EditProfileButton from './EditProfileButton';
import GeneralInfo from './GeneralInfo';
import InviteAgain from './InviteAgain';
import InviteLink from './InviteLink';
import InviteQRCode from './InviteQRCode';
import PrintInvitation from './PrintInvitation';

const styles = {
  root: {
    display: 'flex',
    marginBottom: 30,
  },
  block: {
    alignItems: 'center',
    display: 'flex',
  },
  profile: {
    flexGrow: 1,
    paddingLeft: 20,
  },
  inviteBlock: {
    display: 'flex',
  },
  avatar: {
    width: 80,
    height: 80,
  },
};

const EmployeeActions = ({ classes, employee, readOnlyAvatar }) => (
  <div className={classes.root}>
    {readOnlyAvatar || employee.user?.isApp ? (
      <Avatar src={employee.avatar} className={classes.avatar}>
        {employee.initials}
      </Avatar>
    ) : (
      <ChangeAvatar avatar={employee.avatar} employee_id={employee._id} />
    )}
    <div className={classes.profile}>
      <GeneralInfo
        isApp={!!employee.user?.isApp}
        code={employee.code}
        name={employee.name}
        department={employee.department}
        jobTitle={employee.jobTitle}
      />
      {!employee.user?.isApp ? (
        <div className={classes.block}>
          <div>
            <EditProfileButton employeeId={employee._id} />
          </div>
          {!employee.user && employee.inviteToken && <InviteLink employee={employee} />}
          {!employee.user && employee.inviteToken && <PrintInvitation employee={employee} />}
          {employee.email && <InviteByEmail employeeId={employee._id} />}
          {employee.user && <InviteAgain employeeId={employee._id} />}
        </div>
      ) : null}
    </div>
    <div>
      {!employee.user && employee.inviteToken && <InviteQRCode employee={employee} />}
      {!employee.user && employee.inviteToken ? (
        <Typography style={{ textAlign: 'center', fontSize: 10 }}>
          {`${employee.firstName?.trim()} ${employee.surName?.trim()}`}
        </Typography>
      ) : null}
    </div>
  </div>
);
EmployeeActions.propTypes = {
  classes: object.isRequired,
  employee: employeeShape.isRequired,
  readOnlyAvatar: bool,
};

EmployeeActions.defaultProps = {
  readOnlyAvatar: false,
};

export default withStyles(styles)(EmployeeActions);
