import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import convert from 'xml-js';

import DeclarationElement from './declaration-el';
import Elements from './elements';

const defaultIndentSize = 2;

const defaultTheme = {
  tagColor: '#d43900',
  textColor: '#333',
  attributeKeyColor: '#2a7ab0',
  attributeValueColor: '#008000',
  separatorColor: '#333',
  commentColor: '#aaa',
  cdataColor: '#1d781d',
  overflowBreak: false,
};

const defaultInvalidXml = <div>Invalid XML!</div>;

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.codeBackground,
    fontSize: 16,
    padding: theme.spacing(2),
    lineHeight: '1.5em',
  },
}));
const XMLViewer = ({ xml, theme, indentSize, invalidXml, onClick, ...props }) => {
  let json = null;
  const customTheme = { ...defaultTheme, ...theme };
  const classes = useStyles();
  try {
    json = convert.xml2js(xml, { compact: false, spaces: 0 });
    if (!Array.isArray(json.elements)) {
      return invalidXml;
    }
  } catch (e) {
    return invalidXml;
  }

  return (
    <div className={classes.root} {...props}>
      {json.declaration && (
        <DeclarationElement theme={customTheme} attributes={json.declaration.attributes} />
      )}
      <Elements
        onClick={onClick}
        elements={json.elements}
        theme={customTheme}
        indentSize={indentSize}
        indentation=""
        xpath=""
      />
    </div>
  );
};

XMLViewer.propTypes = {
  xml: PropTypes.string.isRequired,
  theme: PropTypes.object,
  indentSize: PropTypes.number,
  invalidXml: PropTypes.node,
  onClick: PropTypes.func,
};

XMLViewer.defaultProps = {
  theme: {},
  indentSize: defaultIndentSize,
  invalidXml: defaultInvalidXml,
  onClick: null,
};

export default XMLViewer;
