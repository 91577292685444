import React from 'react';
import { object, node, string } from 'prop-types';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';

const FormikCheckbox = ({ field, form, label, color, ...props }) => {
  const { name, onChange, onBlur } = field;
  const { errors, values, submitCount } = form;

  return (
    <FormControlLabel
      {...props}
      control={
        <Checkbox
          name={name}
          onBlur={onBlur}
          checked={values[name][0] === 'on'}
          value="on"
          onChange={onChange}
          color={color}
        />
      }
      label={
        <Typography color={submitCount > 0 && errors[name] ? 'error' : 'inherit'}>
          {label}
        </Typography>
      }
    />
  );
};

FormikCheckbox.propTypes = {
  color: string.isRequired,
  field: object.isRequired,
  form: object.isRequired,
  label: node.isRequired,
};

export default FormikCheckbox;
