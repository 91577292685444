import { useEffect, useState } from 'react';

import { getHandlerArgs, isSupported, visibility } from './utils';

const isSupportedLocal = isSupported && visibility;

function useWindowFocus(onChange) {
  const [isFocused, setIsFocused] = useState(document.hasFocus());

  useEffect(() => {
    if (isSupportedLocal) {
      const handler = () => {
        const [currentlyVisible, , currentlyFocused] = getHandlerArgs();
        const newIsFocused = currentlyVisible && currentlyFocused;
        setIsFocused(newIsFocused);
        onChange(newIsFocused);
      };
      handler();
      document.addEventListener(visibility.event, handler);
      window.addEventListener('focus', handler);
      window.addEventListener('blur', handler);

      return () => {
        document.removeEventListener(visibility.event, handler);
        window.removeEventListener('focus', handler);
        window.removeEventListener('blur', handler);
      };
    }
    return () => null;
  }, [onChange]);

  return isFocused;
}

export default useWindowFocus;
