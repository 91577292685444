import makeStyles from '@mui/styles/makeStyles';
import { SendDrawerContext } from 'components/@home/@documents/SentDocuments/SendDrawerContext';
import ConfigSubStep from 'components/@home/@documents/SentDocuments/steps/StepFiles/ConfigSubStep';
import DropzoneBox from 'components/common/DropzoneBox';
import Loading from 'components/common/Loading';
import { throttle } from 'lodash';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import { array, bool, func, number, object } from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'utils/i18n';
import { bindActionCreators } from 'redux';
import bulkMessagings from 'store/app/entities/bulkMessaging/action';
import employeesWithUser from 'store/selectors/employeesWithUser';
import getPdfjs from 'utils/pdfjs';
import DroppedFiles from './DroppedFiles';
import { getFileName, getParts } from './pdf-utils';

const useStyles = makeStyles(th => ({
  root: {
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: th.spacing(2),
    marginRight: th.spacing(2),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: th.spacing(1),
  },
  submit: {
    margin: th.spacing(3, 0, 2),
    height: 40,
    maxHeight: 40,
  },
  selectWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxHeight: '100%',
    overflow: 'hidden',
  },
  suggestWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  suggest: {
    minWidth: 200,
    marginLeft: th.spacing(3),
  },
  right: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  newTemplateBtn: {
    marginLeft: th.spacing(1),
    padding: 10,
  },
  dialog: {
    width: 600,
  },
  input: {},
  loading: {
    flexGrow: 1,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const readFile = file => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = e => resolve(e.target.result);
    reader.readAsArrayBuffer(file);
  });
};

const readFileAsString = file => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = e => resolve(e.target.result);
    reader.readAsText(file, 'utf8');
  });
};

const StepFiles = ({
  setError,
  parsingPct,
  setParsingPct,
  nextEnabled,
  setNextEnabled,
  resets,
  userItems,
  hasDivisions,
  files,
  setFiles,
  openConfig,
  isOpenConfig,
  closeConfig,
  user,
  currentFile,
}) => {
  const classes = useStyles();
  const { file: format } = useContext(SendDrawerContext);
  const employees = useSelector(employeesWithUser);
  const [droppedFiles, setDroppedFiles] = useState({});
  const dispatch = useDispatch();
  const actions = {
    bulkMessagings: bindActionCreators(bulkMessagings, dispatch),
  };

  const [loading, setLoading] = useState(false);

  const handleDrop = newDroppedFiles => {
    setDroppedFiles(newDroppedFiles.reduce((acc, f) => ({ ...acc, [f.name]: f }), {}));
  };

  const parseFiles = debounce(
    useCallback(() => {
      if (!format) {
        return;
      }
      setLoading(true);
      const initResets = resets.current;
      const newFiles = Object.values(droppedFiles);
      if (isEmpty(newFiles)) return;
      const parsed = newFiles.map(() => 0);
      let hasToReset = true;
      setError(null);
      Promise.all(
        newFiles
          .filter(f => format.type !== 'xml' || f?.name.toLowerCase().endsWith('.xml'))
          .map(async (file, idx) => {
            if (resets.current !== initResets) {
              setParsingPct(0);
              return [];
            }
            let xml;
            let pdf;
            let pdfLoadingTask = null;
            if (format.type === 'xml') {
              xml = await readFileAsString(file);
            } else {
              const arrayBuffer = await readFile(file);
              const fileContent = new Uint8Array(arrayBuffer);
              const pdfjs = await getPdfjs();
              pdfLoadingTask = pdfjs.getDocument(fileContent);
              pdf = await pdfLoadingTask.promise;
            }
            const ids = new Set();
            const fileMap = { xml, 'single-pdf': pdf, 'multi-pdf': pdf };
            await getParts(file.name, fileMap[format.type], format, async (parts, pageIdx) => {
              const field = format.sendBy || 'code';
              if (field === 'id' && Object.keys(parts).includes('code')) {
                // eslint-disable-next-line no-param-reassign
                delete parts.code;
              }
              if (parts.id) {
                ids.add(parts.id);
              }
              const employee =
                field === 'id'
                  ? { id: parts.id, name: '*****' }
                  : employees?.find(
                      e =>
                        e[field] &&
                        parts[field] &&
                        e[field].replace(/^0+/, '') === parts[field].replace(/^0+/, '') &&
                        (!hasDivisions ||
                          format.sendBy !== 'code' ||
                          e.division === parts.division),
                    ) || {};
              const error =
                Object.entries(parts)
                  .filter(([key]) => key !== 'code' || field !== 'id')
                  .some(([, value]) => value === '') || !parts[format.sendBy];
              if (resets.current !== initResets) {
                setParsingPct(0);
                return;
              }
              await setFiles(oldValue => [
                ...(hasToReset ? [] : oldValue || []),
                {
                  ...parts,
                  file: droppedFiles[parts.filename] || file,
                  xml,
                  pageNum: pageIdx,
                  newFileName: getFileName({ ...parts, ...userItems }, format),
                  error,
                  employee,
                },
              ]);
              hasToReset = false;
              const total = format?.type === 'single-pdf' ? pdf.numPages : 1;
              parsed[idx] = Math.max(parsed[idx], ((pageIdx + 1) / total) * 100);
              setParsingPct(
                Math.round(parsed.reduce((acc, curr) => acc + curr, 0) / parsed.length),
              );
            });
            pdfLoadingTask?.destroy();
            pdfLoadingTask?._worker.destroy();
            return ids;
          }),
      )
        .then(idSets => {
          const ids = Array.from(
            idSets.reduce((acc, curr) => new Set([...acc, ...curr]), new Set()),
          );
          if (format.sendBy === 'id' && ids?.length > 0) {
            return actions.bulkMessagings.checkIds({ ids });
          }
          return { value: {} };
        })
        .then(({ value: { nonExistingIds } }) => {
          if (nonExistingIds?.length > 0) {
            setFiles(oldValue =>
              oldValue.map(f => ({
                ...f,
                ...(nonExistingIds.includes(f.employee.id) ? { employee: {} } : {}),
              })),
            );
          }
          setLoading(false);
        });
    }, [
      actions.bulkMessagings,
      droppedFiles,
      employees,
      format,
      hasDivisions,
      resets,
      setError,
      setFiles,
      setParsingPct,
      userItems,
    ]),
    300,
  );

  useEffect(() => {
    if (nextEnabled && parsingPct < 100) {
      setNextEnabled(false);
    } else if (!nextEnabled && parsingPct >= 100) {
      setNextEnabled(true);
    }
  }, [nextEnabled, parsingPct]);

  const [parseFilesWhenFinished, setParseFilesWhenFinished] = useState(false);
  useEffect(() => {
    if ((!parsingPct || parsingPct >= 100) && parseFilesWhenFinished) {
      setParseFilesWhenFinished(false);
      parseFiles();
    }
  }, [parseFiles, parseFilesWhenFinished, parsingPct]);

  useEffect(() => {
    if (!isEmpty(droppedFiles)) {
      if (parsingPct && parsingPct < 100) {
        setParseFilesWhenFinished(true);
      } else {
        parseFiles();
      }
    }
  }, [format, droppedFiles, userItems]);

  const setErrors = useCallback(
    throttle(() => {
      const hasErrors = files?.filter(f => f.error).length > 0;
      const hasRecipients = files?.filter(f => f.employee?.[format?.sendBy]).length > 0;
      if (hasErrors) {
        setError(I18n.t('HrBot.Error parsing some files'));
      } else if (files?.length && !hasRecipients) {
        setError(I18n.t('HrBot.No recipients found'));
      } else {
        setError(null);
      }
      setNextEnabled(!hasErrors && hasRecipients);
    }, 300),
  );
  useEffect(setErrors, [files, format, setError, setNextEnabled]);

  const accept = {
    pdf: 'application/pdf',
    xml: 'application/pdf, application/xml, text/xml',
  };

  if (isOpenConfig && format) {
    return (
      <ConfigSubStep
        user={user}
        close={closeConfig}
        currentFile={currentFile}
        hasDivisions={hasDivisions}
      />
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {loading && <Loading className={classes.loading} debounce={20} size={64} />}
        {!loading &&
          format &&
          (files ? (
            <DroppedFiles files={files} openConfig={openConfig} />
          ) : (
            <DropzoneBox
              onDrop={handleDrop}
              accept={accept[format.type] || 'application/pdf'}
              multiple
            />
          ))}
      </div>
    </div>
  );
};

StepFiles.propTypes = {
  resets: object.isRequired,
  setError: func.isRequired,
  nextEnabled: bool.isRequired,
  setNextEnabled: func.isRequired,
  parsingPct: number.isRequired,
  setParsingPct: func.isRequired,
  userItems: object.isRequired,
  hasDivisions: bool.isRequired,
  files: array.isRequired,
  setFiles: func.isRequired,
  openConfig: func.isRequired,
  isOpenConfig: bool.isRequired,
  closeConfig: func.isRequired,
  currentFile: object,
  user: object.isRequired,
};

StepFiles.defaultProps = {
  currentFile: null,
};

export default StepFiles;
