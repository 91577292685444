/**
 * @flow
 */
import makeStyles from '@mui/styles/makeStyles';
import Label from 'components/@home/drawers/ChannelDrawer/Form/Label';
import AttachmentPreview from 'components/@home/drawers/FillFormDrawer/AttachmentPreview';
import EmployeesTable from 'components/@home/drawers/FormAnalyticsDrawer/EmployeesTable';
import TabPanel from 'components/@home/drawers/FormAnalyticsDrawer/TabPanel';
import {
  Confirm,
  confirmOptions,
  FileUpload,
  ImageUpload,
  MultipleChoice,
  SingleChoice,
  Text,
} from 'components/@home/drawers/FormDrawer/questionTypes';
import Autosuggest from 'components/controls/Autosuggest';
import React, { useEffect, useState } from 'react';
import { I18n } from 'utils/i18n';
import Form from 'services/api/Form';

const useStyles = makeStyles(() => ({
  root: {
    overflowY: 'scroll',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  controls: {
    display: 'flex',
    flexDirection: 'row',
  },
  half: {
    flexGrow: 1,
    '& ~ &': {
      marginLeft: 20,
    },
  },
}));

type Props = {
  form: Object;
  questions: Object;
  channel: Object;
  question: Object;
  setQuestion: Function;
  answer: String;
  setAnswer: Function;
  tab: number;
  index: number;
  isOpen: boolean;
  authEmployee: Object;
  otherEmployee: Object;
};

const getQuestionSuggestions = (questions = []) =>
  questions.map(question => ({
    label: question.title,
    value: question._id,
  }));

const getAnswerSuggestions = (answers = []) => {
  const ret = answers.map(answer => ({
    label: answer.label,
    value: answer._id,
  }));
  ret.unshift({ label: I18n.t('FormAnalyticsDrawer.All'), value: 'All' });
  return ret;
};

const TabResponses = ({
  form,
  channel,
  tab,
  index,
  isOpen,
  question,
  setQuestion,
  answer,
  setAnswer,
  authEmployee,
  otherEmployee,
}: Props) => {
  const classes = useStyles();
  const [status, setStatus] = useState(null);
  const [answers, setAnswers] = useState(null);
  const isSelected = isOpen && tab === index;
  useEffect(() => {
    if (isSelected && form?._id && !answers) {
      if (!status) {
        setStatus('loading');
        Form.answers(form._id).then(data => {
          setAnswers(data);
          setStatus(null);
        });
      }
    }
  }, [isSelected, isOpen, form._id, form, answers, setAnswer, setQuestion, status]);

  const handleChangeQuestion = ({ value }) => {
    setQuestion(form.questions.find(q => q._id === value));
    setAnswer('All');
  };

  const handleChangeAnswer = ({ value }) => {
    setAnswer(value);
  };

  const confirmOptionsTranslated = confirmOptions.map(([_id, val]) => ({ _id, label: val() }));
  const [answersByUser, setAnswersByUser] = useState({});
  useEffect(() => {
    const newAnswersByUser = {};

    if (!answers) {
      setAnswersByUser({});
      return;
    }
    const questionMap = form.questions.reduce((acc, q) => ({ ...acc, [q._id]: q }), {});

    const getResponse = ans => {
      const q = questionMap[ans.question];
      const findOption = id => {
        return q?.options?.find(o => o._id === id);
      };
      let response;
      switch (q?.type) {
        case Confirm:
          response = [confirmOptionsTranslated.find(({ _id }) => _id === ans.answer)];
          break;
        case SingleChoice:
          response = [findOption(ans.answer)];
          break;
        case MultipleChoice:
          response = ans.answer.map(a => findOption(a));
          break;
        case FileUpload:
        case ImageUpload:
          response = <AttachmentPreview attachment={ans.answer} />;
          break;
        case Text:
          response = ans.answer;
          break;
        default:
          response = ans.answer;
      }
      return response;
    };

    const addNewAnswer = (user, questionId, data, employee = {}) => {
      if (!newAnswersByUser[user]) {
        newAnswersByUser[user] = { answers: {}, employee };
      }
      newAnswersByUser[user].answers[questionId] = data;
      newAnswersByUser[user].employee = employee;
    };

    if (form.anonymous) {
      answers.forEach(ans => {
        addNewAnswer(ans.user, ans.question, {
          _id: ans._id,
          respondedAt: ans.createdAt,
          response: getResponse(ans),
        });
      });
      setAnswersByUser(newAnswersByUser);
    } else if (channel.isDirect) {
      answers.forEach(ans => {
        const e = [otherEmployee, authEmployee]
          .filter(v => v)
          .find(emp => emp.user?._id === ans.user);
        if (e) {
          addNewAnswer(
            ans.user,
            ans.question,
            {
              respondedAt: ans.createdAt,
              response: getResponse(ans),
            },
            e,
          );
        }
      });
    } else {
      channel.employees.forEach(e => {
        answers
          .filter(a => a.user === e.user?._id)
          .forEach(ans => {
            addNewAnswer(
              ans.user,
              ans.question,
              {
                respondedAt: ans.createdAt,
                response: getResponse(ans),
              },
              e,
            );
          });
      });
    }
    setAnswersByUser(newAnswersByUser);
  }, [channel?.employees, answers]);
  const options = question?.type === Confirm ? confirmOptionsTranslated : question?.options;
  return (
    <TabPanel value={tab} index={index} className={classes.root}>
      <div className={classes.controls}>
        <div className={classes.half}>
          <Label>{I18n.t('FormAnalyticsDrawer.Question')}</Label>
          <Autosuggest
            fullWidth
            value={question?._id}
            suggestions={getQuestionSuggestions(form.questions)}
            onChange={handleChangeQuestion}
          />
        </div>
        <div className={classes.half}>
          {!!options?.length && (
            <>
              <Label>{I18n.t('FormAnalyticsDrawer.Answer')}</Label>
              <Autosuggest
                fullWidth
                value={answer}
                suggestions={getAnswerSuggestions(options)}
                onChange={handleChangeAnswer}
              />
            </>
          )}
        </div>
      </div>
      {isSelected && !status && (
        <EmployeesTable
          form={form}
          answer={answer}
          question={question}
          answersByUser={answersByUser}
        />
      )}
    </TabPanel>
  );
};
export default TabResponses;
