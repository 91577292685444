import makeStyles from '@mui/styles/makeStyles';
import { object } from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  device: {
    position: 'relative',
    width: ({ width }) => width,
    height: ({ width }) => width * 2,
  },
  frame: {
    background: '#e0e3e4',
    borderRadius: ({ width }) => width * 0.158878505,
    boxShadow: 'inset 0 0 0px 5px #e0e3e4, inset 0 0 0 7px #f0f0f0',
    padding: ({ width }) => width * 0.065,
    width: ({ width }) => width,
    height: ({ width }) => width * 2,
  },
  content: {
    height: ({ width }) => width * 2 - width * 0.065 * 2,
    width: ({ width }) => width - width * 0.065 * 2,
    borderRadius: ({ width }) => width * 0.106666667,
    background: theme.palette.background.paper,
    overflow: 'hidden',
    display: 'flex',
  },
  stripe: {
    zIndex: 9,
    '&:after, &:before': {
      border: 'solid rgba(#333, .25)',
      borderWidth: ({ width }) => `0 ${width * 0.01635514}px`,
      content: `''`,
      height: ({ width }) => width * 0.01635514,
      left: 0,
      position: 'absolute',
      width: '100%',
      zIndex: 9,
    },
    '&:after': {
      top: ({ width }) => width / 20,
    },
    '&:before': {
      bottom: ({ width }) => width / 20,
    },
  },
  header: {
    zIndex: 10,
    background: '#e0e3e4',
    borderBottomLeftRadius: ({ width }) => width * 0.046728972,
    borderBottomRightRadius: ({ width }) => width * 0.046728972,
    height: ({ width }) => width * 0.07,
    left: '50%',
    marginLeft: ({ width }) => -width * 0.238317757,
    position: 'absolute',
    top: ({ width }) => width * 0.065,
    width: ({ width }) => width * 0.476635514,

    '&::after, &::before': {
      zIndex: 10,
      content: `''`,
      height: ({ width }) => width * 0.023364486,
      position: 'absolute',
      top: 0,
      width: ({ width }) => width * 0.023364486,
    },
    '&::after': {
      background:
        'radial-gradient(circle at bottom left, transparent 0, transparent 75%, #e0e3e4 75%, #e0e3e4 100%)',
      left: ({ width }) => -width * 0.023364486,
    },
    '&::before': {
      background:
        'radial-gradient(circle at bottom right, transparent 0, transparent 75%, #e0e3e4 75%, #e0e3e4 100%)',
      right: ({ width }) => -width * 0.023364486,
    },
  },
  sensors: {
    zIndex: 10,
    '&::after, &::before': {
      zIndex: 10,
      content: `''`,
      position: 'absolute',
    },
    '&::after': {
      background: '#fff',
      borderRadius: ({ width }) => width * 0.074766355,
      height: ({ width }) => width * 0.014,
      left: '50%',
      marginLeft: ({ width }) => -width * 0.058411215,
      top: ({ width }) => width * 0.074766355,
      width: ({ width }) => width * 0.11682243,
    },
    '&::before': {
      background: '#fff',
      borderRadius: '50%',
      height: ({ width }) => width * 0.03271028,
      left: '50%',
      marginLeft: ({ width }) => width * 0.093457944,
      top: ({ width }) => width * 0.065,
      width: ({ width }) => width * 0.03271028,
    },
  },
  buttons: {
    background: '#d6d7d9',
    height: ({ width }) => width * 0.074766355,
    left: ({ width }) => -width * 0.007009346,
    position: 'absolute',
    top: ({ width }) => width * 0.268691589,
    width: ({ width }) => width * 0.007009346,

    '&::after, &::before': {
      background: '#d6d7d9',
      content: `''`,
      height: ({ width }) => width * 0.144859813,
      left: 0,
      position: 'absolute',
      width: ({ width }) => width * 0.007009346,
    },

    '&::after': {
      top: ({ width }) => width * 0.140186916,
    },
    '&::before': {
      top: ({ width }) => width * 0.327102804,
    },
  },

  power: {
    background: '#d6d7d9',
    height: ({ width }) => width * 0.23364486,
    right: ({ width }) => -width * 0.007009346,
    position: 'absolute',
    top: ({ width }) => width * 0.46728972,
    width: ({ width }) => width * 0.007009346,
  },
}));

const IphonePreview = ({ children }) => {
  const classes = useStyles({ width: 280 });
  return (
    <div className={classes.device}>
      <div className={classes.frame}>
        <div className={classes.content}>{children}</div>
      </div>
      <div className={classes.stripe} />
      <div className={classes.header} />
      <div className={classes.sensors} />
      <div className={classes.buttons} />
      <div className={classes.power} />
    </div>
  );
};

IphonePreview.propTypes = {
  children: object,
};

IphonePreview.defaultProps = {
  children: null,
};
export default IphonePreview;
