import { Drawer } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import FormDrawerContent from 'components/@home/drawers/FormDrawer/FormDrawerContent';
import Loading from 'components/common/Loading';
import { bool, func, object } from 'prop-types';
import React, { useEffect } from 'react';
import CloseButton from '../CloseButton';

import connector from './connector';
import formik from './formik';

const styles = theme => ({
  root: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    width: '100%',
    maxWidth: 'calc(100vw - 40px)',
    [theme.breakpoints.up('md')]: {
      width: 1100,
    },
  },
  container: {
    display: 'flex',
    flex: 1,
  },
  closeButtonContainer: {
    paddingTop: 20,
    paddingLeft: 20,
  },
});

const FormDrawer = ({ classes, form, isOpen, isEdit, actions, resetForm }) => {
  useEffect(() => {
    if (isEdit && isOpen && form?._id && !form.loading && !form.loaded) {
      actions.forms.find(form._id);
    }
  }, [isEdit, isOpen, form, actions]);

  const handleClose = () => {
    actions.drawers.form.close();
    resetForm();
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose}>
      <div className={classes.root}>
        <div className={classes.closeButtonContainer}>
          <CloseButton onClick={handleClose} />
        </div>
        <div className={classes.container}>
          {isEdit && form.loading ? <Loading /> : <FormDrawerContent />}
        </div>
      </div>
    </Drawer>
  );
};

FormDrawer.propTypes = {
  classes: object.isRequired,
  form: object,
  actions: object.isRequired,
  isOpen: bool.isRequired,
  isEdit: bool.isRequired,
  resetForm: func.isRequired,
};

FormDrawer.defaultProps = {
  form: null,
};

export default withStyles(styles)(connector(formik(FormDrawer)));
