const getSortParametersFactory =
  ([{ field: fieldDefault, sort: sortDefault }]) =>
  sortModel => {
    const { field: sf, sort: sd } = sortModel?.[0] || {};
    const ret = {};
    if (sf !== fieldDefault) {
      ret.sf = sf;
    }
    if (sd !== sortDefault) {
      ret.sd = sd;
    }
    return ret;
  };

export default getSortParametersFactory;
