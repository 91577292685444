import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import scopes from 'store/app/entities/config/scopes/action';

const mapStateToProps = state => ({
  scopes: state.config.scopes,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    config: {
      scopes: bindActionCreators(scopes, dispatch),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
