import Attachments from 'services/api/Attachments';
import currentChannel from 'store/selectors/currentChannel';

export const LOAD_LINKS = 'LOAD_LINKS';
export const LOAD_LINKS_PENDING = 'LOAD_LINKS_PENDING';
export const LOAD_LINKS_FULFILLED = 'LOAD_LINKS_FULFILLED';

export const LOAD_DOCUMENTS = 'LOAD_DOCUMENTS';
export const LOAD_DOCUMENTS_PENDING = 'LOAD_DOCUMENTS_PENDING';
export const LOAD_DOCUMENTS_FULFILLED = 'LOAD_DOCUMENTS_FULFILLED';

export const LOAD_MEDIA = 'LOAD_MEDIA';
export const LOAD_MEDIA_PENDING = 'LOAD_MEDIA_PENDING';
export const LOAD_MEDIA_FULFILLED = 'LOAD_MEDIA_FULFILLED';

export const LOAD_FORMS = 'LOAD_FORMS';
export const LOAD_FORMS_PENDING = 'LOAD_FORMS_PENDING';
export const LOAD_FORMS_FULFILLED = 'LOAD_FORMS_FULFILLED';

export const RESET_MEDIA = 'RESET_MEDIA';

const getChannelId = state => currentChannel(state)._id;

const links = (page, perPage) => (dispatch, getState) =>
  dispatch({
    type: LOAD_LINKS,
    payload: Attachments.links(getChannelId(getState()), page, perPage),
    meta: { channel_id: getChannelId(getState()) },
  });

const media = (page, perPage) => (dispatch, getState) =>
  dispatch({
    type: LOAD_MEDIA,
    payload: Attachments.media(getChannelId(getState()), page, perPage),
    meta: { channel_id: getChannelId(getState()) },
  });

const docs = (page, perPage, channelId, field, sort, type) => (dispatch, getState) =>
  dispatch({
    type: LOAD_DOCUMENTS,
    payload: Attachments.docs(
      channelId || getChannelId(getState()),
      page,
      perPage,
      field,
      sort,
      type,
    ),
    meta: { channel_id: channelId || getChannelId(getState()) },
  });

const reset = () => ({
  type: RESET_MEDIA,
});

const forms = (page, perPage) => (dispatch, getState) =>
  dispatch({
    type: LOAD_FORMS,
    payload: Attachments.forms(getChannelId(getState()), page, perPage),
    meta: { channel_id: getChannelId(getState()) },
  });

export default {
  links,
  media,
  docs,
  forms,
  reset,
};
