import Mandatory from 'services/api/Mandatory';

export const LOAD_FULL_MANDATORY = 'LOAD_FULL_MANDATORY';
export const LOAD_FULL_MANDATORY_PENDING = 'LOAD_FULL_MANDATORY_PENDING';
export const LOAD_FULL_MANDATORY_FULFILLED = 'LOAD_FULL_MANDATORY_FULFILLED';
export const LOAD_FULL_MANDATORY_REJECTED = 'LOAD_FULL_MANDATORY_REJECTED';

export const REMIND_EMPLOYEE = 'REMIND_EMPLOYEE';
export const REMIND_EMPLOYEE_PENDING = 'REMIND_EMPLOYEE_PENDING';
export const REMIND_EMPLOYEE_FULFILLED = 'REMIND_EMPLOYEE_FULFILLED';
export const REMIND_EMPLOYEE_REJECTED = 'REMIND_EMPLOYEE_REJECTED';

const load = (messageId, page) => ({
  type: LOAD_FULL_MANDATORY,
  payload: Mandatory.find(messageId, { page }),
  meta: { page },
});

const remind = (messageId, employees) => ({
  type: REMIND_EMPLOYEE,
  payload: Mandatory.remind(messageId, employees),
});
export default { load, remind };
