import React from 'react';
import { object, node } from 'prop-types';
import withStyles from '@mui/styles/withStyles';

const styles = {
  root: {
    width: '100%',
    paddingTop: '20px',
  },
};

const DocumentList = ({ classes, children }) => <div className={classes.root}>{children}</div>;

DocumentList.propTypes = {
  classes: object.isRequired,
  children: node.isRequired,
};

export default withStyles(styles)(DocumentList);
