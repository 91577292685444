import { ListItem, ListItemText } from '@mui/material';
import Box from '@mui/material/Box';
import { array, bool, number } from 'prop-types';
import React, { memo, useCallback } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import ContentLoader from 'react-content-loader';
import MemberListItem from './MemberListItem';

const itemSize = () => 80;

const MemberList = ({ totalCount, membersRef, loadMoreItems, showReadStatus }) => {
  const renderRow = useCallback(
    ({ index, key, style }) => {
      const member = membersRef.current[index];
      return member ? (
        <MemberListItem key={key} member={member} showReadStatus={showReadStatus} style={style} />
      ) : (
        <Box sx={{ flexGrow: 1 }} style={style}>
          <ContentLoader width={400} height={80} viewBox="0 0 400 80">
            {!showReadStatus || <circle cx="9" cy="31" r="9px" />}
            <circle cx={showReadStatus ? 60 : 22} cy="32" r="22px" />
            <rect x={showReadStatus ? 99 : 60} y="30" rx="3" ry="3" width="200" height="10" />
            <rect x={showReadStatus ? 99 : 60} y="10" ry="3" width="100" height="10" />
          </ContentLoader>
        </Box>
      );
    },
    [membersRef, showReadStatus],
  );
  const isItemLoaded = useCallback(index => !!membersRef.current[index], [membersRef]);
  const renderChannels = useCallback(
    ({ height, width }) => {
      return (
        <InfiniteLoader
          minimumBatchSize={100}
          isItemLoaded={isItemLoaded}
          itemCount={totalCount}
          loadMoreItems={loadMoreItems}
        >
          {({ onItemsRendered, ref }) => (
            <List
              onItemsRendered={onItemsRendered}
              ref={ref}
              height={height}
              width={width}
              itemCount={totalCount}
              itemSize={itemSize}
            >
              {renderRow}
            </List>
          )}
        </InfiniteLoader>
      );
    },
    [isItemLoaded, loadMoreItems, renderRow, totalCount],
  );

  return (
    <Box sx={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column' }}>
      <AutoSizer>{renderChannels}</AutoSizer>
    </Box>
  );
};

MemberList.propTypes = {
  membersRef: array.isRequired,
  showReadStatus: bool.isRequired,
  loadMoreItems: array.isRequired,
  totalCount: number,
};

MemberList.defaultProps = {
  totalCount: 1000,
};

export default memo(MemberList);
