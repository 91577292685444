import { arrayOf, bool, instanceOf, shape, string } from 'prop-types';
import messageShape from './message';

export const chatFlowRouteShape = shape({
  intent: string.isRequired,
  condition: string.isRequired,
  description: string.isRequired,
  type: string.isRequired,
  transition: string,
  replies: arrayOf(messageShape),
  handoffTo: string,
});

export const chatFlowPageShape = shape({
  flow: string.isRequired,
  routes: arrayOf(chatFlowRouteShape).isRequired,
});

export const chatFlowShape = shape({
  _id: string.isRequired,
  name: string.isRequired,
  description: string.isRequired,
  isPublished: bool.isRequired,
  isActive: bool.isRequired,
  company: string.isRequired,
  createdAt: instanceOf(Date).isRequired,
  updatedAt: instanceOf(Date).isRequired,
  startPage: chatFlowPageShape,
  pages: arrayOf(chatFlowPageShape),
});

export const chatFlowIntentShape = shape({
  name: string.isRequired,
  description: string,
  phrases: arrayOf(
    shape({
      hidden: bool.isRequired,
      parts: arrayOf(
        shape({
          text: string,
          parameter: shape({
            id: string.isRequired,
            type: string.isRequired,
          }),
          auto: bool,
        }),
      ),
    }),
  ),
  company: string.isRequired,
  parameters: arrayOf(
    shape({
      id: string.isRequired,
      entityType: string.isRequired,
      isList: bool.isRequired,
    }),
  ),
  creator: string.isRequired,
});

export const chatFlowEntityTypeShape = shape({
  _id: string.isRequired,
  name: string.isRequired,
  description: string,
  language: string.isRequired,
  options: arrayOf(
    shape({
      name: string.isRequired,
      texts: arrayOf(string.isRequired).isRequired,
    }),
  ),
  company: string.isRequired,
  creator: string.isRequired,
});
