import { ADD_MESSAGE } from 'store/app/entities/messages/action';
import {
  LOAD_DOCUMENTS_FULFILLED,
  LOAD_DOCUMENTS_PENDING,
  LOAD_FORMS_FULFILLED,
  LOAD_FORMS_PENDING,
  LOAD_LINKS_FULFILLED,
  LOAD_LINKS_PENDING,
  LOAD_MEDIA_FULFILLED,
  LOAD_MEDIA_PENDING,
  RESET_MEDIA,
} from 'store/app/ui/media/action';
import generatePath from 'utils/generatePath';

const initialState = {
  isLoading: false,
  documents: [],
  media: [],
  links: [],
  forms: [],
  media_total: 0,
  links_total: 0,
  documents_total: 0,
  channelId: null,
};

const createAttachment = a => ({
  _id: a._id,
  user_id: a.user?._id || a.user,
  httpLink: generatePath(a.httpLink),
  meta: a.meta,
  name: a.name,
  size: a.size,
  type: a.type,
  mimeType: a.mimeType,
  createdAt: a.createdAt,
  isMandatory: a.isMandatory,
  messageId: a.messageId,
  message: a.message,
  title: a.title,
  confirmedAt: a.confirmedAt,
});

const mediaReducer = (state = initialState, { type, payload, meta }) => {
  switch (type) {
    case LOAD_LINKS_PENDING:
    case LOAD_DOCUMENTS_PENDING:
    case LOAD_MEDIA_PENDING:
    case LOAD_FORMS_PENDING:
      return {
        ...state,
        isLoading: true,
      };

    case LOAD_LINKS_FULFILLED: {
      return {
        ...state,
        isLoading: false,
        links_total: payload.totalCount,
        links: payload.links,
        channelId: meta.channel_id,
      };
    }

    case LOAD_MEDIA_FULFILLED:
      return {
        ...state,
        isLoading: false,
        media_total: payload.totalCount,
        media: payload.attachments.map(a => createAttachment(a)),
        channelId: meta.channel_id,
      };

    case LOAD_DOCUMENTS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        documents_total: payload.totalCount,
        documents: payload.attachments.map(a => createAttachment(a)),
        channelId: meta.channel_id,
      };

    case LOAD_FORMS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        forms_total: payload.totalCount,
        forms: payload.attachments,
        channelId: meta.channel_id,
      };

    case ADD_MESSAGE:
      return {
        ...state,
        forms: state.forms.map(f =>
          f._id === payload._id ? { ...f, acknowledges: payload.acknowledges } : f,
        ),
      };

    case RESET_MEDIA: {
      return initialState;
    }
    default:
      return state;
  }
};

export default mediaReducer;
