import { Button, SvgIcon, Toolbar } from '@mui/material';
import { SendDrawerContext } from 'components/@home/@documents/SentDocuments/SendDrawerContext';
import SendIcon from 'mdi-react/SendIcon';
import { object } from 'prop-types';
import React, { useContext } from 'react';
import { I18n } from 'utils/i18n';

const BulkMessagingToolbar = ({ documentFormat }) => {
  const sendDrawer = useContext(SendDrawerContext);
  const handleClick = () => {
    sendDrawer.open(documentFormat);
  };
  if (!documentFormat?.isAdmin) {
    return null;
  }
  return (
    <Toolbar
      sx={{
        minHeight: '0 !important',
        overflow: 'hidden',
        borderBottom: '1px solid',
        borderColor: 'divider',
        '& .MuiButton-root': {
          borderRadius: 0,
          minHeight: 49,
          whiteSpace: 'nowrap',
          '& .MuiCircularProgress-root': {
            marginLeft: 1,
          },
        },
      }}
    >
      <Button variant="text" color="secondary" onClick={handleClick}>
        <SvgIcon
          sx={{
            fontSize: 18,
            marginRight: 1,
            color: 'primary.main',
          }}
        >
          <SendIcon />
        </SvgIcon>
        {I18n.t('BulkMessaging.Send')}
      </Button>
    </Toolbar>
  );
};

BulkMessagingToolbar.propTypes = {
  documentFormat: object.isRequired,
};
export default BulkMessagingToolbar;
