/* eslint-disable react/destructuring-assignment */
import { Toolbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { bool, func, number, string } from 'prop-types';
import React, { memo } from 'react';
import { I18n } from 'utils/i18n';
import { useDebounceCallback } from 'utils/useDebounceCallback';
import ErrorCaption from './ErrorCaption';
import PeopleSelected from './PeopleSelected';
import Search from './Search';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(1),
  },
  actions: {
    display: 'flex',
    alignItem: 'center',
    color: theme.palette.text.secondary,
  },
  title: {
    fontWeight: 600,
  },
}));

const EnhancedTableToolbar = ({ onSearch, isFetching, selected, error }) => {
  const classes = useStyles();

  const handleSearch = useDebounceCallback(e => {
    onSearch(e.target.value);
  }, 700);

  return (
    <Toolbar className={classes.root}>
      <Typography component="div" className={classes.title} variant="subtitle1">
        {I18n.t('ChannelDrawer.EmployeesTable.Select member for this channel')}
      </Typography>
      <div className={classes.actions}>
        <ErrorCaption>{error}</ErrorCaption>
        <PeopleSelected selected={selected} />
        <Search onChange={handleSearch} isFetching={isFetching} />
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  selected: number.isRequired,
  error: string,
  onSearch: func.isRequired,
  isFetching: bool,
};

EnhancedTableToolbar.defaultProps = {
  error: '',
  isFetching: false,
};

export default memo(EnhancedTableToolbar);
