import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import ChannelsPanel from './ChannelsPanel';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    maxHeight: 'calc(100vh - 64px)',
  },
  channels: {
    display: 'none',
    width: 380,
    height: '100%',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  shown: {
    display: 'block',
  },
}));

const MessagesScene = () => {
  const channelsPanelOpen = useSelector(state => state.header.channelsPannelOpen);
  const classes = useStyles();
  return (
    <section className={classes.root}>
      <div className={classNames({ [classes.channels]: true, [classes.shown]: channelsPanelOpen })}>
        <ChannelsPanel />
      </div>
      <Outlet />
    </section>
  );
};

export default memo(MessagesScene, () => true);
