/* eslint-disable class-methods-use-this,no-nested-ternary */
import Http from 'services/Http';
import qs from 'querystring';

export const MANDATORY_ACKNOWLEDGES_LIMIT = 10;

class Mandatory {
  async find(messageId, params) {
    const { data } = await Http.get(`/v1/mandatory/${messageId}?${qs.stringify(params)}`);
    return {
      ...data.message,
      employeesTotalCount: data.message.employeesTotalCount,
    };
  }

  async remind(messageId, employee) {
    const { data } = await Http.post(`/v1/mandatory/${messageId}/remind`, {
      employees: employee ? [employee._id] : [],
    });
    return data.message;
  }
}

export default new Mandatory();
