import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import messages from 'store/app/entities/messages/action';
import authUser from 'store/selectors/authUser';
import currentChannel from 'store/selectors/currentChannel';
import selectedMessageId from 'store/selectors/selectedMessageId';

const mapStateToProps = state => ({
  channel: currentChannel(state),
  selectedMessageId: selectedMessageId(state),
  authUser: authUser(state),
  replyingTo: state.elements.ChatPanel.replyingTo,
  hasFocus: state.elements.Focus.hasFocus,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    messages: bindActionCreators(messages, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
