export const OPEN_EMPLOYEE_IMPORT_DRAWER = 'OPEN_EMPLOYEE_IMPORT_DRAWER';
export const CLOSE_EMPLOYEE_IMPORT_DRAWER = 'CLOSE_EMPLOYEE_IMPORT_DRAWER';
export const CLOSE_PREVIEW_EMPLOYEE_IMPORT_DRAWER = 'CLOSE_PREVIEW_EMPLOYEE_IMPORT_DRAWER';

const open = ei => ({
  type: OPEN_EMPLOYEE_IMPORT_DRAWER,
  payload: ei,
});

const close = () => ({
  type: CLOSE_EMPLOYEE_IMPORT_DRAWER,
});

const closePreview = () => ({
  type: CLOSE_PREVIEW_EMPLOYEE_IMPORT_DRAWER,
});

export default { open, close, closePreview };
