/* eslint-disable no-param-reassign */
import dagre from 'dagre';

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const getLayoutedElements = (nodes, edges = [], direction = 'LR') => {
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction, ranksep: 100 });

  nodes.forEach(node => {
    dagreGraph.setNode(node.id, { width: node.__rf.width, height: node.__rf.height });
  });
  edges.forEach(edge => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  return Array.prototype.concat(
    nodes.map(nodeIn => {
      const {
        id,
        type,
        data,
        __rf: { width, height },
      } = { ...nodeIn };
      const nodeWithPosition = dagreGraph.node(id);
      const targetPosition = isHorizontal ? 'left' : 'top';
      const sourcePosition = isHorizontal ? 'right' : 'bottom';

      // unfortunately we need this little hack to pass a slightly different position
      // to notify react flow about the change. Moreover we are shifting the dagre node position
      // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
      const position = {
        x: nodeWithPosition.x - width / 2 + Math.random() / 1000,
        y: nodeWithPosition.y - height / 2,
      };

      return { id, type, data, targetPosition, sourcePosition, position };
    }),
    edges,
  );
};
export default getLayoutedElements;
