import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import media from 'store/app/ui/media/action';

const mapStateToProps = state => ({
  media: state.media.media,
  total: state.media.media_total,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    media: bindActionCreators(media, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
