/* eslint-disable max-len */
import React from 'react';
import { bool, object, number } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { I18n } from 'utils/i18n';
import classNames from 'classnames';
import moment from 'moment';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: '15px',
  },
  subheading: {
    color: theme.palette.primary.dark,
    fontSize: '12px',
    fontWeight: 500,
    textTransform: 'uppercase',
    marginBottom: '8px',
  },
  description: {
    fontSize: '14px',
  },
  readDescription: {
    color: theme.palette.primary.dark,
  },
  unreadDescription: {
    color: '#8b98ab',
    textAlign: 'center',
    width: '70%',
    display: 'block',
  },
});

const Information = ({ classes, confirmedAt, digitalSignature, isMe }) => {
  let description = isMe
    ? I18n.t(
        'AcknowledgementReadModal.Please, read what follows and confirm reception by clicking the button below',
      )
    : '';
  if (confirmedAt && digitalSignature) {
    description = `${I18n.t('AcknowledgementReadModal.Digital signature made on')} ${moment
      .unix(confirmedAt)
      .format('LL')}`;
  } else if (confirmedAt) {
    description = `${I18n.t('AcknowledgementReadModal.Signed on')} ${moment
      .unix(confirmedAt)
      .format('LL')}`;
  }
  return (
    <div className={classes.root}>
      <span className={classes.subheading}>
        {digitalSignature
          ? I18n.t('AcknowledgementReadModal.DIGITAL SIGNATURE')
          : I18n.t('AcknowledgementReadModal.MANDATORY READ')}
      </span>
      <span
        className={classNames({
          [classes.description]: true,
          [classes.readDescription]: confirmedAt,
          [classes.unreadDescription]: !confirmedAt,
        })}
      >
        {description}
      </span>
    </div>
  );
};

Information.propTypes = {
  classes: object.isRequired,
  confirmedAt: number,
  digitalSignature: bool,
  isMe: bool,
};

Information.defaultProps = {
  confirmedAt: null,
  digitalSignature: false,
  isMe: true,
};

export default withStyles(styles)(Information);
