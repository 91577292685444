import { createApi } from '@reduxjs/toolkit/dist/query/react';
import baseQuery from 'store/app/entities/baseQuery';

const appDataEndpoints = build => ({
  uploadImage: build.mutation({
    query: ({ appId, image, name }) => {
      const formData = new FormData();
      formData.append(name, image);
      return {
        url: `v1/app-data/${appId}/image/`,
        method: 'POST',
        body: formData,
      };
    },
    transformResponse: ({ status, data }) => ({
      status,
      ...data,
    }),
  }),
  get: build.query({
    query: ({ appId, include }) => `v1/app-data/${appId}${include ? `?include=${include}` : ''}`,
    providesTags: (result, error, { appId }) => [{ type: 'AppData', id: appId }],
    transformResponse: ({ data }) => data?.appData,
  }),
  upsert: build.mutation({
    query: ({ appId, data }) => ({
      url: `v1/app-data/${appId}`,
      method: 'PUT',
      body: data,
    }),
    transformResponse: ({ data }) => data?.appData,
    invalidatesTags: (result, error, { appId }) => [
      {
        type: 'AppData',
        id: 'LIST',
      },
      {
        type: 'AppData',
        id: appId,
      },
    ],
  }),
  delete: build.mutation({
    query: ({ appId }) => ({
      url: `v1/app-data/${appId}`,
      method: 'DELETE',
    }),
    invalidatesTags: (result, error, { appId }) => [
      {
        type: 'AppData',
        id: 'LIST',
      },
      {
        type: 'AppData',
        id: appId,
      },
    ],
  }),
});

export const appDataApi = createApi({
  reducerPath: 'appData',
  baseQuery,
  tagTypes: ['AppData'],
  endpoints: appDataEndpoints,
});

export const { useUploadImageMutation, useUpsertMutation, useDeleteMutation, useGetQuery } =
  appDataApi;

export const { reducer: appDataReducer, middleware: appDataMiddleware } = appDataApi;
