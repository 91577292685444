import {
  FIND_FORM_FULFILLED,
  FIND_FORM_PENDING,
  FIND_FORM_REJECTED,
} from 'store/app/entities/forms/action';

const initialState = {
  data: {},
  summaries: {},
};

const formsReducer = (state = initialState, { type, payload, meta }) => {
  switch (type) {
    case FIND_FORM_PENDING: {
      const { formId } = meta;
      return {
        ...state,
        data: {
          ...state.data,
          [formId]: {
            ...state.data[formId],
            loading: true,
            loaded: false,
            error: false,
          },
        },
      };
    }

    case FIND_FORM_FULFILLED: {
      const { formId } = meta;
      return {
        ...state,
        data: {
          [formId]: {
            ...state.data[formId],
            ...payload,
            loading: false,
            loaded: true,
            error: false,
          },
        },
      };
    }

    case FIND_FORM_REJECTED: {
      const { formId } = meta;
      return {
        ...state,
        data: {
          [formId]: {
            ...state.data[formId],
            loading: false,
            loaded: false,
            error: true,
          },
        },
      };
    }

    default:
      return state;
  }
};

export default formsReducer;
