import { shape, string, number, object } from 'prop-types';

export default shape({
  _id: string.isRequired,
  user: string,
  httpLink: string.isRequired,
  meta: object,
  name: string.isRequired,
  size: number.isRequired,
  type: string.isRequired,
  mimeType: string.isRequired,
  createdAt: number.isRequired,
});
