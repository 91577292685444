import { withFormik } from 'formik';
import moment from 'moment';
import { I18n } from 'utils/i18n';
import { BACKEND_URL } from 'services/constants';
import baseUrl from 'utils/baseUrl';
import Yup from 'utils/CustomYup';

class AppSubmit {
  constructor(values, formikBag) {
    this.formikBag = formikBag;
    this.values = values;
  }

  assembleForm = () => {
    const {
      name,
      description,
      interactiveEndpoint,
      enableOauth,
      eventEndpoint,
      redirectUris,
      botScopes,
      userScopes,
      configPageUrl,
      chatFlow,
    } = this.values;
    return {
      name,
      description,
      isActive: 1,
      interactiveEndpoint,
      eventEndpoint,
      botScopes,
      redirectUris: enableOauth.length ? redirectUris : [],
      userScopes: enableOauth.length ? userScopes : [],
      configPageUrl,
      chatFlow,
    };
  };

  sendRequest = form => {
    const {
      props: { app, isEdit, actions },
    } = this.formikBag;
    if (isEdit) return actions.apps.update(app._id, form);
    return actions.apps.create(form);
  };

  uploadAvatar = appId => {
    const {
      setSubmitting,
      setErrors,
      props: { actions },
    } = this.formikBag;
    const { avatar } = this.values;
    if (avatar instanceof File) {
      actions.apps.uploadFile(appId, 'avatar', avatar).catch(() => {
        setSubmitting(false);
        setErrors({ server: I18n.t('Picture was not uploaded for some reason') });
      });
    }
  };

  showAlert = () => {
    const { name } = this.values;
    const {
      props: { actions, isEdit },
    } = this.formikBag;

    const label = I18n.t(`AppDrawer.App ${isEdit ? 'updated' : 'created'}`);
    actions.alert.show({
      label,
      message: name,
      variant: 'success',
    });
  };

  displayErrors = err => {
    const { name } = this.values;
    const { setErrors } = this.formikBag;

    // eslint-disable-next-line no-console
    console.error(err);
    if (err.status === 409) {
      setErrors({ server: `Channel "${name}" already exists` });
    } else {
      setErrors({ server: I18n.t('Something went wrong with sending data') });
    }
  };
}

const validURL = options =>
  Yup.string().isUrl(() => I18n.t('AppDrawer.Must be a valid URL'), options);

const userScopesRequired = () =>
  I18n.t('ValidationErrors.Required', {
    name: I18n.t('AppDrawer.UserScopesField.label'),
  });
const redirectUrisRequired = () =>
  I18n.t('ValidationErrors.Required', {
    name: I18n.t('AppDrawer.RedirectUrisField.label'),
  });

const botScopesRequired = () =>
  I18n.t('ValidationErrors.Required', {
    name: I18n.t('AppDrawer.BotScopesField.label'),
  });

export default withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .max(100, () => I18n.t('AppDrawer.Should not exceed', { count: 100 }))
      .trim()
      .required(() => I18n.t('AppDrawer.Please enter name for the app')),
    description: Yup.string()
      .max(200, () => I18n.t('AppDrawer.Should not exceed', { count: 200 }))
      .trim(),
    interactiveEndpoint: validURL(),
    eventEndpoint: validURL(),
    redirectUris: Yup.mixed().when('enableOauth', {
      is: ([enabled]) => enabled === 'on',
      then: Yup.array().of(
        validURL().required(redirectUrisRequired).required(redirectUrisRequired),
      ),
      otherwise: Yup.array().of(validURL().required(redirectUrisRequired)),
    }),
    botScopes: Yup.array().of(Yup.string().required(botScopesRequired)),
    userScopes: Yup.mixed().when('enableOauth', {
      is: ([enabled]) => enabled === 'on',
      then: Yup.array().of(Yup.string().required(userScopesRequired)).required(userScopesRequired),
      otherwise: Yup.array().of(Yup.string().required(userScopesRequired)),
    }),
    configPageUrl: validURL({ allowPath: true }),
  }),

  mapPropsToValues: ({ companyId, app, isEdit }) => {
    return {
      _id: isEdit ? app._id : '',
      createdAt: isEdit ? moment(app.createdAt).format('LL') : '',
      name: isEdit ? app.name : '',
      avatar: isEdit ? app.avatar : '',
      description: isEdit ? app.description : '',
      interactiveEndpoint: isEdit ? app.interactiveEndpoint : '',
      eventEndpoint: isEdit ? app.eventEndpoint : '',
      signingSecret: isEdit ? app.signingSecret : '',
      botToken: isEdit ? app.botToken : '',
      id: isEdit ? app.id : '',
      appSecret: isEdit ? app.appSecret : '',
      redirectUris: isEdit ? app.redirectUris : undefined,
      enableOauth: isEdit && app.redirectUris?.length ? ['on'] : [],
      chatbot: isEdit && app.chatFlow ? ['on'] : [],
      chatFlow: isEdit ? app.chatFlow : '',
      botScopes: isEdit ? app.botScopes : [],
      userScopes: isEdit ? app.userScopes : undefined,
      configPageUrl: isEdit ? app.configPageUrl : '',
      baseUrl: `${BACKEND_URL}/api/v1/`,
      appUser: isEdit ? app.appUser : '',
      appEmployee: isEdit ? app.appEmployee : '',
      companyId,
    };
  },

  handleSubmit: async (values, formikBag) => {
    const {
      props: { actions },
      setSubmitting,
    } = formikBag;

    const handler = new AppSubmit(values, formikBag);

    const form = handler.assembleForm();

    try {
      const rawApp = await handler.sendRequest(form);

      handler.uploadAvatar(rawApp.value._id);

      handler.showAlert();

      setSubmitting(false);

      actions.drawers.app.close();
    } catch (err) {
      setSubmitting(false);
      handler.displayErrors(err);
    }
  },
  displayName: 'AppForm',
  enableReinitialize: true,
});
