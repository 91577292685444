import Loading from 'components/common/Loading';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const PostSignatureRedirectionScene = () => {
  const { action } = useParams();
  useEffect(() => {
    const event = new CustomEvent('signatureRedirection', { detail: { action } });
    window.top.dispatchEvent(event);
  }, [action]);
  return <Loading size={40} key="loading" />;
};

export default PostSignatureRedirectionScene;
