import { Toolbar, Typography } from '@mui/material';
import React from 'react';
import { string, element } from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontWeight: 600,
  },
}));

const SectionTitle = ({ children, title }) => {
  const classes = useStyles();

  return (
    <Toolbar className={classes.root}>
      <Typography component="div" className={classes.title} variant="h6">
        {title}
      </Typography>
      {children}
    </Toolbar>
  );
};

SectionTitle.propTypes = {
  title: string.isRequired,
  children: element,
};

SectionTitle.defaultProps = {
  children: null,
};

export default SectionTitle;
