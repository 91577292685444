/**
 * @flow
 */
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { I18n } from 'utils/i18n';
import Header from 'components/@home/Header';
import OAuthAuthorize from 'components/@oauth/OAuthAuthorize';
import OAuthError from 'components/@oauth/OAuthError';
import Loading from 'components/common/Loading';
import deferRender from 'defer-render-hoc';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import connector from './connector';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
  },
  content: {
    marginTop: theme.spacing(4),
    maxWidth: 480,
    display: 'flex',
    flexDirection: 'column',
  },
}));

const useQuery = () => {
  const { search } = useLocation();
  return new URLSearchParams(search);
};

type Props = {
  apps: Object;
  actions: Object;
};

const OAuthScene = ({ apps, actions }: Props) => {
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const query = useQuery();
  const appId = query.get('client_id');
  const state = query.get('state') || '-';

  useEffect(() => {
    actions.apps.find(appId).then(() => {
      setLoading(false);
    });
  }, []);
  const app = apps?.[appId];

  const redirectUri = query.get('redirect_uri') || app?.redirectUris[0];
  const scope = query.get('scope') || (app && app.userScopes.join(' '));
  const scopes = scope ? scope.split(' ') : [];
  let error;
  const invalidScopes = scopes.filter(s => !app?.userScopes || !app.userScopes.includes(s)) || [];

  if (loading) {
    error = <Loading size={56} />;
  } else if (!app) {
    error = (
      <OAuthError
        app={app}
        message={<Typography>{I18n.t('OAuth.Invalid client_id parameter')}</Typography>}
      />
    );
  } else if (app.redirectUris && !app.redirectUris.includes(redirectUri)) {
    error = (
      <OAuthError
        app={app}
        message={
          <Typography>
            {I18n.t('OAuth.redirect_uri did not match any configured URIs', {
              redirectUri,
            })}
          </Typography>
        }
      />
    );
  } else if (!scopes.length) {
    error = (
      <OAuthError app={app} message={<Typography>{I18n.t('OAuth.No scopes found')}</Typography>} />
    );
  }
  if (invalidScopes.length) {
    error = (
      <OAuthError
        app={app}
        message={
          <div>
            <Typography>{I18n.t('OAuth.Invalid scopes requested')}</Typography>
            <ul>
              {invalidScopes.map(s => (
                <li key={s}>
                  <Typography>{s}</Typography>
                </li>
              ))}
            </ul>
          </div>
        }
      />
    );
  }

  return (
    <div className={classes.root}>
      <Header hideNavigation />
      <div className={classes.container}>
        <Grid spacing={4} className={classes.content}>
          {error || (
            <OAuthAuthorize app={app} scopes={scopes} redirectUri={redirectUri} state={state} />
          )}
        </Grid>
      </div>
    </div>
  );
};

export default deferRender(connector(OAuthScene));
