/* eslint-disable class-methods-use-this */
import Http from 'services/Http';

class Invite {
  async send(employeeId, forceChannel) {
    const body = forceChannel ? { forceChannel } : null;
    const { data } = await Http.post(`/v1/employee/${employeeId}/invite/sms`, body);
    return data;
  }
}

export default new Invite();
