import { useState } from 'react';

const { getKeys } = require('utils/e2eEncryption');
const { useEffect } = require('react');

const useKeys = () => {
  const [keys, setKeys] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchKeys = async () => {
      const newKeys = await getKeys();
      setKeys(newKeys);
    };
    fetchKeys()
      .catch(e => setError(e))
      .then(() => setLoading(false));
  }, []);

  return { ...keys, loading, error };
};

export default useKeys;
