import { Box, ListItemText, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import renderFolderCell from 'components/@home/@documents/renderFolderCell';
import OmmnioDataGrid from 'components/common/OmmnioDataGrid';
import { string } from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'utils/i18n';
import { useLocation } from 'react-router-dom';
import { HR_BOT_APP_ID } from 'services/constants';
import appsAction from 'store/app/entities/apps/action';
import { useListReceivedDocumentFormatsQuery } from 'store/app/entities/SharedFilesSlice';
import renderDateTimeCell from 'utils/renderDateTimeCell';

const MyDocumentsTemplates = ({ folder }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const app = useSelector(state => state.apps.apps?.[HR_BOT_APP_ID]);
  useEffect(() => {
    if (!app?._id) {
      dispatch(appsAction.load());
    }
  }, [app?._id]);
  const { data: { documentFormats } = {}, isLoading } = useListReceivedDocumentFormatsQuery();
  const columns = [
    {
      field: 'name',
      flex: 2,
      editable: false,
      renderCell: renderFolderCell(folder, { search: location.search }, 'home'),
      headerName: I18n.t('MyDocuments.Table.name'),
    },
    {
      field: 'count',
      type: 'number',
      flex: 1,
      editable: false,
      headerName: I18n.t('MyDocuments.Table.count'),
      maxWidth: 100,
    },
    {
      field: 'lastReceivedAt',
      type: 'string',
      flex: 1,
      editable: false,
      renderCell: renderDateTimeCell,
      headerName: I18n.t('MyDocuments.Table.lastReceivedAt'),
    },
  ];
  return (
    <Grid
      item
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          p: 2,
          pb: 0,
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h3">{I18n.t(`DocumentsScene.Folders.${folder}`)}</Typography>
        <OmmnioDataGrid
          loading={isLoading}
          sx={{ marginTop: 2 }}
          columns={columns}
          rows={documentFormats}
          getRowId={r => r._id}
          updateHistory
          disableEvenOdd
          defaultSortModel={[{ field: 'lastReceivedAt', sort: 'desc' }]}
        />
      </Box>
    </Grid>
  );
};

MyDocumentsTemplates.propTypes = {
  folder: string.isRequired,
};

export default MyDocumentsTemplates;
