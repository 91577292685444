export const OPEN_TEAM_DRAWER = 'OPEN_TEAM_DRAWER';
export const CLOSE_TEAM_DRAWER = 'CLOSE_TEAM_DRAWER';

const open = team => ({
  type: OPEN_TEAM_DRAWER,
  payload: team,
});

const close = () => ({
  type: CLOSE_TEAM_DRAWER,
});

export default { open, close };
