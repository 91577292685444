export const OPEN_FILL_FORM_DRAWER = 'OPEN_FILL_FORM_DRAWER';
export const CLOSE_FILL_FORM_DRAWER = 'CLOSE_FILL_FORM_DRAWER';

const open = payload => ({
  type: OPEN_FILL_FORM_DRAWER,
  payload,
});

const close = () => ({
  type: CLOSE_FILL_FORM_DRAWER,
});

export default { open, close };
