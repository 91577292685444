import { APP_DOMAIN, IS_DEV } from 'services/constants';

export const getMagicLink = (company, e) => {
  if (!e.inviteToken) {
    return null;
  }
  const subdomain = IS_DEV ? '' : `${company.subDomain}.`;

  return `https://${subdomain}${APP_DOMAIN}/invite/${e.inviteToken}`;
};
