import { CLOSE_WHISTLEBLOWER_BOT_DRAWER, OPEN_WHISTLEBLOWER_BOT_DRAWER } from './action';

const initialState = {
  isOpen: false,
};

export const isOpenSelector = state => state.drawers.whistleblowerBot.isOpen;

const whistleblowerBotDrawer = (state = initialState, { type }) => {
  switch (type) {
    case OPEN_WHISTLEBLOWER_BOT_DRAWER:
      return {
        ...state,
        isOpen: true,
      };

    case CLOSE_WHISTLEBLOWER_BOT_DRAWER:
      return {
        ...state,
        isOpen: false,
      };

    default:
      return state;
  }
};

export default whistleblowerBotDrawer;
