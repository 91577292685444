import {
  BACKEND_URL,
  HR_BOT_APP_ID,
  LAUNCHER_APP_ID,
  TIME_TRACKING_APP_ID,
  WHISTLEBLOWER_BOT_APP_ID,
} from 'services/constants';

const openAppConfig = (actions, appId) => () => {
  if (appId === HR_BOT_APP_ID) {
    return actions.drawers.hrBot.open();
  }
  if (appId === WHISTLEBLOWER_BOT_APP_ID) {
    return window.open('/wb-admin', '_blank');
  }
  if (appId === TIME_TRACKING_APP_ID) {
    return actions.drawers.timeTracking.open();
  }
  if (appId === LAUNCHER_APP_ID) {
    return actions.drawers.launcherApp.open();
  }
  return actions.drawers.remote.open(`${BACKEND_URL}/api/v1/app/${appId}/config`);
};

export default openAppConfig;
