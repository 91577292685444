import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import pictureModal from 'store/app/ui/modals/picture/action';

const mapStateToProps = state => ({
  src: state.modals.picture.src,
  isOpen: state.modals.picture.isOpen,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    pictureModal: bindActionCreators(pictureModal, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
