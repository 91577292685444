import React from 'react';
import { object, bool, func } from 'prop-types';
import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Loading from 'components/common/Loading';
import { I18n } from 'utils/i18n';

const styles = {
  root: {
    marginLeft: 8,
    marginRight: 5,
  },
  loading: {
    marginRight: 3,
  },
};

const ConfirmButton = ({ classes, isLoading, onClick }) => (
  <Button className={classes.root} onClick={onClick} variant="contained" color="primary" autoFocus>
    {isLoading && <Loading className={classes.loading} color="inherit" debounce={0} size={16} />}
    {I18n.t('Dialog.Send')}
  </Button>
);
ConfirmButton.propTypes = {
  classes: object.isRequired,
  isLoading: bool.isRequired,
  onClick: func.isRequired,
};

export default withStyles(styles)(ConfirmButton);
