import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddFormatButton from 'components/@home/drawers/HrBotDrawer/AddFormatButton';
import { HrBotContext } from 'components/@home/drawers/HrBotDrawer/hrBotContext';
import TemplateListHelp from 'components/@home/drawers/HrBotDrawer/TemplateListHelp';
import sortBy from 'lodash/sortBy';
import DeleteIcon from 'mdi-react/DeleteIcon';
import EditIcon from 'mdi-react/EditIcon';
import SendIcon from 'mdi-react/SendIcon';
import React, { memo, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { I18n } from 'utils/i18n';
import documentFormats from 'store/app/entities/documentFormats/action';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    maxHeight: '100%',
    overflow: 'scroll',
  },

  tableWrapper: {
    flexGrow: 1,
    maxHeight: '100%',
    marginTop: theme.spacing(2),
  },

  table: {
    maxHeight: '100%',
  },

  tableHead: {
    width: '100%',
    borderTop: `1px solid ${theme.palette.divider}`,
  },

  tableRow: {
    height: 40,
  },

  header: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.29,
    color: theme.palette.secondary.main,
  },

  tableBody: {},

  toolbar: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    flexDirection: 'column',
  },

  summary: {
    display: 'flex',
  },

  error: {
    color: theme.palette.danger,
  },

  errors: {
    marginLeft: theme.spacing(1),
  },

  cog: {
    padding: 4,
  },

  actions: {
    display: 'flex',
    alignItem: 'center',
    color: theme.palette.text.secondary,
  },

  plus: {
    fontSize: 18,
    marginRight: 5,
  },
}));

const TemplatesList = () => {
  const classes = useStyles();
  const { formats, setFormatId, navigateTo } = useContext(HrBotContext);
  const dispatch = useDispatch();
  const formatList = sortBy(Object.values(formats), 'name') || [];

  const sendFormat = id => () => {
    setFormatId(id);
    navigateTo('send');
  };

  const editFormat = id => () => {
    setFormatId(id);
    navigateTo('editTemplate');
  };

  const deleteFormat = id => async () => {
    await dispatch(documentFormats.destroy(id));
    setFormatId(null);
  };

  if (!formatList?.length) {
    return (
      <div>
        <TemplateListHelp isEmpty />
        <AddFormatButton onClick={editFormat()} />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <TemplateListHelp onAddFormat={editFormat()} />
      <div className={classes.tableWrapper}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.actions}>
            <AddFormatButton onClick={editFormat()} />
          </div>
        </Toolbar>
        <Table className={classes.table}>
          <TableHead className={classes.tableHead}>
            <TableRow classes={{ head: classes.tableRow }}>
              <TableCell name="name" padding="none">
                <Typography className={classes.header}>{I18n.t('HrBot.Name')}</Typography>
              </TableCell>
              <TableCell name="actions" align="right">
                <Typography className={classes.header}>{I18n.t('HrBot.Actions')}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {formatList.map(format => {
              return (
                <TableRow className={classes.tableRow} key={format.name}>
                  <TableCell padding="none">{format.name}</TableCell>
                  <TableCell align="right">
                    <IconButton onClick={sendFormat(format._id)} size="large">
                      <SendIcon />
                    </IconButton>
                    <IconButton onClick={editFormat(format._id)} size="large">
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={deleteFormat(format._id)} size="large">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default memo(TemplatesList);
