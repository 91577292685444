import Preview from 'services/api/Preview';
import getLinkPreview from 'store/selectors/getLinkPreview';

export const FETCH_LINK_PREVIEW = 'FETCH_LINK_PREVIEW';
export const FETCH_LINK_PREVIEW_PENDING = 'FETCH_LINK_PREVIEW_PENDING';
export const FETCH_LINK_PREVIEW_FULFILLED = 'FETCH_LINK_PREVIEW_FULFILLED';
export const FETCH_LINK_PREVIEW_REJECTED = 'FETCH_LINK_PREVIEW_REJECTED';

const fetchLinkPreview = url => (dispatch, getState) =>
  dispatch({
    type: FETCH_LINK_PREVIEW,
    payload: getLinkPreview(getState(), url)
      ? Promise.resolve(getLinkPreview(getState(), url))
      : Preview.generate(url),
    meta: { link: url },
  });

export default {
  fetchLinkPreview,
};
