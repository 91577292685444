/* eslint-disable class-methods-use-this */
import Http from 'services/Http';

class Team {
  async find(id) {
    const { data } = await Http.get(`/v1/team/${id}`);
    return data.team;
  }

  async list() {
    const { data } = await Http.get('/v1/team');
    return data;
  }

  async create(form) {
    const response = await Http.post('/v1/team', form);
    return response.data.team;
  }

  async update(id, form) {
    const response = await Http.patch(`/v1/team/${id}`, form);
    return response.data.team;
  }

  async destroy(id) {
    const { data } = await Http.delete(`/v1/team/${id}`);
    return data;
  }
  async addBonpreuSubteams() {
    const { data } = await Http.post(`/v1/team/add-bonpreu-subteams`);
    return data;
  }
}

export default new Team();
