class BaseResource {
  constructor() {
    this.init();
  }

  init() {
    this.data = undefined;
    this.status = 'pending';
    this.error = undefined;
    this.promise = null;
  }

  read() {
    switch (this.status) {
      case 'pending':
        throw this.promise;
      case 'error':
        throw this.error;
      default:
        return this.data;
    }
  }
}

export default BaseResource;
