import {
  LOAD_COUNTRY_CODES_FULFILLED,
  LOAD_COUNTRY_CODES_PENDING,
  LOAD_COUNTRY_CODES_REJECTED,
} from './action';

const initialState = {
  codes: [],
  loading: false,
};

const countryCodesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_COUNTRY_CODES_PENDING:
      return { ...state, loading: true };

    case LOAD_COUNTRY_CODES_FULFILLED:
      return { ...state, loading: false, codes: { ...state.codes, ...payload } };

    case LOAD_COUNTRY_CODES_REJECTED:
      return { ...state, loading: false, rejected: true };

    default:
      return state;
  }
};

export default countryCodesReducer;
