import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import ChipInput from 'components/common/ChipInput';
import { FieldArray } from 'formik';
import { object, string } from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    border: `solid 1px ${theme.palette.secondary.light}`,
    borderRadius: 6,
    padding: 5,
    paddingBottom: 0,
    '& $inputRoot': {
      paddingTop: 0,
      border: 0,
      '& $input': {
        padding: 5,
        paddingBottom: 10,
        margin: 0,
      },
    },
  },
  inputRoot: {
    padding: 0,
    border: 0,
  },
  input: {
    padding: 5,
  },
  chipContainer: {
    minHeight: 'initial',
    marginBottom: 0,
    alignItems: 'center',
    padding: 0,
  },
  chip: {
    height: 26,
    marginTop: 0,
    marginBottom: 5,
    '& svg': {
      marginRight: 2,
    },
  },
}));

const FormikChipInput = ({ field, form, className, ...props }) => {
  const { name, onBlur } = field;
  const { errors, submitCount, getFieldProps } = form;
  const { value } = getFieldProps(name);
  const classes = useStyles();
  return (
    <FieldArray
      name={name}
      render={arrayHelpers => (
        <ChipInput
          classes={{
            root: classnames(className, classes.root),
            inputRoot: classes.inputRoot,
            input: classes.input,
            chipContainer: classes.chipContainer,
            chip: classes.chip,
          }}
          disableUnderline
          fullWidth
          {...props}
          onAdd={chip => arrayHelpers.push(chip)}
          onDelete={(chip, index) => arrayHelpers.remove(index)}
          onBlur={onBlur}
          value={value}
          name={name}
          error={!!(errors[name] && submitCount > 0)}
          helperText={submitCount > 0 && errors[name]}
        />
      )}
    />
  );
};

FormikChipInput.propTypes = {
  field: object.isRequired,
  form: object.isRequired,
  className: string,
};

FormikChipInput.defaultProps = {
  className: null,
};

export default FormikChipInput;
