import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import analyticsDrawer from 'store/app/ui/drawers/analytics/action';
import mandatory from 'store/app/ui/drawers/analytics/mandatory/action';
import populateEmployees from 'store/selectors/populateEmployees';

const mapStateToProps = state => ({
  analyticsDrawer: state.drawers.analytics,
  employees: populateEmployees(state),
});

const mapDispatchToProps = dispatch => ({
  actions: {
    drawer: {
      analyticsDrawer: {
        ...bindActionCreators(analyticsDrawer, dispatch),
        fullMandatory: bindActionCreators(mandatory, dispatch),
      },
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
