import { ChatFlowContext } from 'components/@home/@settings/Tabs/ChatFlowContext';
import React, { useContext } from 'react';

const getDisplayName = WrappedComponent => {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
};

function withContext(WrappedComponent) {
  const WithContext = props => {
    const { context } = useContext(ChatFlowContext);
    return <WrappedComponent context={context} {...props} />;
  };
  WithContext.displayName = `WithContext(${getDisplayName(WrappedComponent)})`;
  return WithContext;
}

export default withContext;
