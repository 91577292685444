/* eslint-disable class-methods-use-this */
import Http from 'services/Http';

class ChatFlowWebhook {
  async find(id) {
    const { data } = await Http.get(`/v1/chat-flow-webhook/${id}`);
    return data.chatFlowWebhook;
  }

  async list() {
    const { data } = await Http.get(`/v1/chat-flow-webhook`);
    return data;
  }

  async create(form) {
    const response = await Http.post(`/v1/chat-flow-webhook`, form);
    return response.data.chatFlowWebhook;
  }

  async update(id, form) {
    const response = await Http.patch(`/v1/chat-flow-webhook/${id}`, form);
    return response.data.chatFlowWebhook;
  }

  async destroy(id) {
    const { data } = await Http.delete(`/v1/chat-flow-webhook/${id}`);
    return data;
  }
}

export default new ChatFlowWebhook();
