import {
  CREATE_CHAT_FLOW_PAGE_FULFILLED,
  DESTROY_CHAT_FLOW_PAGE_FULFILLED,
  FIND_CHAT_FLOW_PAGE_FULFILLED,
  FIND_CHAT_FLOW_PAGE_PENDING,
  LOAD_CHAT_FLOW_PAGES_FULFILLED,
  LOAD_CHAT_FLOW_PAGES_PENDING,
  REMOVE_ROUTE_CHAT_FLOW_PAGE_FULFILLED,
  UPDATE_CHAT_FLOW_PAGE_FULFILLED,
} from 'store/app/entities/chatFlowPages/action';
import {
  CREATE_CHAT_FLOW_FULFILLED,
  DESTROY_CHAT_FLOW_FULFILLED,
  FIND_CHAT_FLOW_FULFILLED,
  FIND_CHAT_FLOW_PENDING,
  LOAD_CHAT_FLOWS_FULFILLED,
  LOAD_CHAT_FLOWS_PENDING,
  TRAIN_CHAT_FLOW_FULFILLED,
  TRAIN_CHAT_FLOW_PENDING,
  UPDATE_CHAT_FLOW_FULFILLED,
} from 'store/app/entities/chatFlows/action';
import arrayToObject from 'utils/arrayToObject';
import merge from 'utils/merge';

const chatFlowsReducer = (
  state = { chatFlows: {}, loading: false, loaded: false },
  { type, payload, meta },
) => {
  switch (type) {
    case LOAD_CHAT_FLOWS_FULFILLED: {
      const chatFlows = payload.chatFlows.map(c => merge(state.chatFlows[c._id], c));

      return merge(state, {
        chatFlows: arrayToObject(chatFlows),
        loading: false,
        loaded: true,
      });
    }

    case FIND_CHAT_FLOW_PENDING: {
      return merge(state, { loading: true });
    }

    case CREATE_CHAT_FLOW_FULFILLED:
    case FIND_CHAT_FLOW_FULFILLED:
    case UPDATE_CHAT_FLOW_FULFILLED: {
      const chatFlow = merge({ ...state.chatFlows[payload._id] }, payload);
      return merge(state, {
        loading: false,
        chatFlows: { ...state.chatFlows, [payload._id]: chatFlow },
      });
    }

    case DESTROY_CHAT_FLOW_FULFILLED: {
      const chatFlows = { ...state.chatFlows };
      delete chatFlows[meta.chatFlowId];
      return merge(state, { chatFlows });
    }

    case LOAD_CHAT_FLOWS_PENDING:
      return merge(state, { loading: true, loaded: false });

    case LOAD_CHAT_FLOW_PAGES_FULFILLED: {
      const chatFlows = { ...state.chatFlows };
      const flow = chatFlows[meta.chatFlowId];
      flow.pages = payload;

      return merge(state, {
        chatFlows,
        loading: false,
        loaded: true,
      });
    }

    case FIND_CHAT_FLOW_PAGE_PENDING: {
      return merge(state, { loading: true });
    }

    case CREATE_CHAT_FLOW_PAGE_FULFILLED:
    case FIND_CHAT_FLOW_PAGE_FULFILLED:
    case UPDATE_CHAT_FLOW_PAGE_FULFILLED:
    case REMOVE_ROUTE_CHAT_FLOW_PAGE_FULFILLED: {
      const newFlow = { ...state.chatFlows[meta.chatFlowId] };
      const newPages = newFlow.pages.map(p => (p._id === meta.pageId ? payload : p));
      newFlow.pages = newPages;
      return {
        ...state,
        loading: false,
        chatFlows: {
          ...state.chatFlows,
          [meta.chatFlowId]: newFlow,
        },
      };
    }

    case DESTROY_CHAT_FLOW_PAGE_FULFILLED: {
      const chatFlows = { ...state.chatFlows };
      const flow = chatFlows[meta.chatFlowId];
      const idx = flow.pages.findIndex(p => p._id === meta.pageId);
      delete flow.pages[idx];
      return merge(state, { chatFlows });
    }

    case LOAD_CHAT_FLOW_PAGES_PENDING:
      return merge(state, { loading: true, loaded: false });

    case TRAIN_CHAT_FLOW_PENDING: {
      const chatFlows = { ...state.chatFlows };
      const flow = { ...chatFlows[meta.chatFlowId], training: true };
      return merge(state, { ...chatFlows, [chatFlows[meta.chatFlowId]]: flow });
    }

    case TRAIN_CHAT_FLOW_FULFILLED: {
      const chatFlows = {
        ...state.chatFlows,
        [meta.chatFlowId]: {
          ...state.chatFlows[meta.chatFlowId],
          training: false,
          lastVersionTrained: true,
        },
      };
      return merge(state, { chatFlows });
    }

    default:
      return state;
  }
};

export default chatFlowsReducer;
