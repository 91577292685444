import { Box, ButtonBase, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import FolderCellMenu from 'components/@home/@documents/FolderCellMenu';
import AudioThumbnail from 'components/@home/@messages/ChatPanel/MessagesList/MessageGroup/AudioThumbnail';
import MessageIcon from 'components/common/MessageIcon';
import FolderArrowRightIcon from 'mdi-react/FolderArrowRightIcon';
import FolderIcon from 'mdi-react/FolderIcon';
import { object, oneOf, string } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  useDeleteDocumentFormatMutation,
  useDeleteSharedFileMutation,
  useEditDocumentFormatMutation,
  useEditSharedFileMutation,
  useRestoreDocumentFormatMutation,
  useRestoreSharedFileMutation,
  useSendReceivedMutation,
  useSendSharedFileMutation,
} from 'store/app/entities/SharedFilesSlice';
import documentModalActions from 'store/app/ui/modals/document/action';
import pictureModalActions from 'store/app/ui/modals/picture/action';
import videoModalActions from 'store/app/ui/modals/video/action';
import { getMessageType, MESSAGE_TYPES } from 'utils/getMessageType';
import useActions from 'utils/useActions';
import useOpenClose from 'utils/useOpenClose';

const BaseItem = ({ folder, params, state, actions, section }) => {
  const [isOpen, open, close] = useOpenClose();
  const [documentModal, pictureModal, videoModal] = useActions([
    documentModalActions,
    pictureModalActions,
    videoModalActions,
  ]);
  const isNavigable = folder !== 'deleted' && params.row.kind !== 'Document';
  const isDocument = params.row.kind === 'Document';
  const type = getMessageType({ attachments: [params.row] });
  const modalMap = {
    [MESSAGE_TYPES.VIDEO]: videoModal,
    [MESSAGE_TYPES.IMAGE]: pictureModal,
    [MESSAGE_TYPES.AUDIO]: { open: () => null },
  };
  const modal = modalMap[type] || documentModal;
  return (
    <ListItem
      button
      component={isNavigable ? Link : ButtonBase}
      sx={{
        '@media screen and (min-width: 40em) and hover: hover': {
          '& .MuiListItemSecondaryAction-root': { display: isOpen ? 'block' : 'none' },
          '&:hover .MuiListItemSecondaryAction-root': { display: 'block' },
        },
        paddingLeft: 0,
        '& .MuiListItemIcon-root img': {
          marginLeft: '2px',
          width: 20,
          height: 20,
        },
        '& .MuiSvgIcon-root': {
          width: 24,
          height: 24,
        },
      }}
      secondaryAction={
        <FolderCellMenu
          folder={params.row}
          isOpen={isOpen}
          open={open}
          close={close}
          actions={actions}
          section={section}
        />
      }
      {...(isNavigable
        ? {
            to: {
              pathname: `/home/documents/${folder}/${params.row._id}/`,
            },
            state: { ...state, parentName: params.row.name },
          }
        : {})}
      {...(isDocument
        ? {
            onClick: () =>
              modal.open(params.row.httpLink, null, params.row.name, false, params.row.mimeType),
          }
        : {})}
    >
      <ListItemIcon>
        {(() => {
          switch (params.row.kind) {
            case 'Document':
              return <MessageIcon message={{ attachments: [params.row] }} />;
            case 'DocumentFormat':
              return folder === 'home' ? <FolderIcon /> : <FolderArrowRightIcon />;
            default:
              return <FolderIcon />;
          }
        })()}
      </ListItemIcon>
      <ListItemText
        sx={{
          borderBottom: 0,
          minHeight: 0,
          paddingTop: 0,
          paddingBottom: 0,
          flexShrink: 1,
          overflow: 'hidden',
          '& span': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }}
        primary={params.value}
      />
      {type === MESSAGE_TYPES.AUDIO && (
        <Box sx={{ flex: 0, minWidth: 300 }}>
          <AudioThumbnail data={params.row} />
        </Box>
      )}
    </ListItem>
  );
};

BaseItem.propTypes = {
  actions: object,
  folder: string.isRequired,
  params: object.isRequired,
  section: oneOf(['chats', 'home', 'sent', 'shared']).isRequired,
  state: object,
};

BaseItem.defaultProps = {
  actions: {},
  state: null,
};

const FolderItem = props => {
  const [destroy] = useDeleteSharedFileMutation();
  const [edit] = useEditSharedFileMutation();
  const [restore] = useRestoreSharedFileMutation();
  const [send] = useSendSharedFileMutation();
  const [sendMine] = useSendReceivedMutation();
  return <BaseItem {...props} actions={{ destroy, edit, restore, send, sendMine }} />;
};

const DocumentFormatItem = props => {
  const [destroy] = useDeleteDocumentFormatMutation();
  const [edit] = useEditDocumentFormatMutation();
  const [restore] = useRestoreDocumentFormatMutation();
  return <BaseItem {...props} actions={{ destroy, edit, restore }} />;
};

const renderFolderCell = (folder, state, section) => params =>
  params.row.kind === 'DocumentFormat' ? (
    <DocumentFormatItem key={params.row._id} folder={folder} params={params} state={state} />
  ) : (
    <FolderItem
      key={params.row._id}
      folder={folder}
      params={params}
      state={state}
      section={section}
    />
  );

export default renderFolderCell;
