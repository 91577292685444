/**
 * @flow
 */
/* eslint-disable react/jsx-props-no-spreading */
import { InputAdornment, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CopyButton from 'components/@home/drawers/ProfileDrawer/Profile/EmployeeActions/InviteLink/CopyButton';
import React from 'react';

const useStyles = makeStyles(theme => ({
  input: {
    backgroundColor: theme.palette.secondary.ultraLight,
  },
  buttonWrapper: { display: 'flex', height: 33.5, marginRight: -12 },
  button: {
    backgroundColor: theme.palette.white,
    minHeight: 'auto',
    marginRight: 2,
    '&:disabled $icon': {
      color: 'inherit',
    },
  },
}));

type Props = {
  value: string;
};

const CopyField = ({ value, ...rest }: Props) => {
  const classes = useStyles();

  const select = event => {
    // event properties must be copied to use async
    const { target } = event;
    setTimeout(() => target.select(), 10);
  };

  return (
    // $FlowFixMe
    <TextField
      value={value}
      InputProps={{
        className: classes.input,
        readOnly: true,
        onSelect: select,
        onMouseDown: select,
        endAdornment: (
          <InputAdornment position="end">
            <div className={classes.buttonWrapper}>
              <CopyButton
                value={value}
                size="small"
                ButtonProps={{
                  size: 'small',
                  className: classes.button,
                }}
              />
            </div>
          </InputAdornment>
        ),
      }}
      fullWidth
      {...rest}
    />
  );
};

export default CopyField;
