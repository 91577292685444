import FileSaver from 'file-saver';
import fetchAndDecrypt from 'utils/fetchAndDecrypt';

const downloadAttachment = (attachment, aesKey) => () => {
  fetchAndDecrypt(attachment, aesKey).then(blob => {
    FileSaver.saveAs(blob, attachment.name);
  });
};

export default downloadAttachment;
