import removeAccents from 'utils/removeAccents';

const matchStringArray = (strArray = [], searchString) => {
  const matchedValues = strArray.map(t => {
    const tag = removeAccents(t).toLowerCase();
    const search = removeAccents(searchString).toLowerCase();
    const matched = search.split(/[^a-z0-9]+/).reduce((ret, curr) => {
      return ret || tag.includes(curr);
    }, false);
    return {
      matched,
      value: t,
    };
  });

  const sortedValues = matchedValues
    .sort((a, b) => {
      const matchedCompare = b.matched - a.matched;
      return matchedCompare || a.value.localeCompare(b.value);
    })
    .map(t => t.value);
  return sortedValues;
};

export default matchStringArray;
