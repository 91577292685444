import { connect } from 'react-redux';

const mapStateToProps = state => {
  const { locale } = state.i18n;
  const localization = state.config.countryCodes.codes[locale]?.reduce((ret, country) => ({
    ...ret,
    [country.code.toLowerCase()]: country.name,
  }));
  return { locale, localization };
};

export default connect(mapStateToProps);
