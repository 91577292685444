import { makeStyles } from '@mui/styles';
import React from 'react';
import { func, object, string } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import SearchField from 'components/controls/SearchField';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 400,
    display: 'flex',
    alignItems: 'center',
  },
  searchIconButton: {
    marginLeft: 5,
  },
  searchIcon: {
    fontSize: 16,
  },
  search: {
    backgroundColor: theme.palette.background.paper,
    border: 'solid 1px #dde2eb',
  },
}));

const Search = props => {
  const { value, onChange } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <SearchField value={value} classes={{ formControl: classes.search }} onChange={onChange} />
    </div>
  );
};

Search.propTypes = {
  value: string,
  onChange: func.isRequired,
};
Search.defaultProps = {
  value: '',
};

export default Search;
