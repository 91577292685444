export const OPEN_CHANNEL_DRAWER = 'OPEN_CHANNEL_DRAWER';
export const CLOSE_CHANNEL_DRAWER = 'CLOSE_CHANNEL_DRAWER';

const open = options => ({
  type: OPEN_CHANNEL_DRAWER,
  payload: options,
});

const close = () => ({
  type: CLOSE_CHANNEL_DRAWER,
});

export default { open, close };
