export const OPEN_ACKNOWLEDGMENT_CREATE_MODAL = 'OPEN_ACKNOWLEDGMENT_CREATE_MODAL';
export const CLOSE_ACKNOWLEDGMENT_CREATE_MODAL = 'CLOSE_ACKNOWLEDGMENT_CREATE_MODAL';
export const SAVE_UPLOADED_ACKNOWLEDGMENT_PDF = 'SAVE_UPLOADED_ACKNOWLEDGMENT_PDF';

const open = () => ({
  type: OPEN_ACKNOWLEDGMENT_CREATE_MODAL,
});

const saveTemporarytempAttachment = pdf => ({
  type: SAVE_UPLOADED_ACKNOWLEDGMENT_PDF,
  payload: pdf,
});

const close = () => ({
  type: CLOSE_ACKNOWLEDGMENT_CREATE_MODAL,
});

export default { saveTemporarytempAttachment, open, close };
