import { SET_ACTIVE, SET_FOCUS } from './action';

const initialState = {
  hasFocus: true,
  isActive: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_FOCUS:
      return {
        ...state,
        hasFocus: action.focus,
      };

    case SET_ACTIVE:
      return {
        ...state,
        isActive: action.active,
      };
    default:
      return state;
  }
};
