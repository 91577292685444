import { Box, Button, IconButton, OutlinedInput, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AudioThumbnail from 'components/@home/@messages/ChatPanel/MessagesList/MessageGroup/AudioThumbnail';
import ConfirmMessageDialog from 'components/@whistleblower-admin/drawers/OccurrenceDrawer/ConfirmMessageDialog';
import { useOccurrence } from 'components/@whistleblower-admin/drawers/OccurrenceDrawer/OccurrenceContext';
import RecordAudio from 'components/@whistleblower-admin/drawers/OccurrenceDrawer/RecordAudio';
import Loading from 'components/common/Loading';
import MessageIcon from 'components/common/MessageIcon';
import ForumOutlineIcon from 'mdi-react/ForumOutlineIcon';
import PaperclipIcon from 'mdi-react/PaperclipIcon';
import SendIcon from 'mdi-react/SendIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';
import React, { useCallback, useRef } from 'react';
import { I18n, Translate } from 'utils/i18n';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import AttachmentsService from 'services/api/Attachments';
import {
  usePostOccurrenceMessageMutation,
  useSaveOccurrenceMutation,
} from 'store/app/entities/WbSlice';
import decryptAttachment from 'utils/decryptAttachment';
import { encrypt } from 'utils/e2eEncryption';
import filesize from 'utils/filesize';
import isAudio from 'utils/isAudio';
import useOpenClose from 'utils/useOpenClose';

const useStyles = makeStyles(() => ({
  enter: {
    opacity: 0,
    maxHeight: 0,
  },
  enterActive: {
    opacity: 1,
    maxHeight: 80,
    transition: 'all.5s ease-in-out',
  },
  exit: {
    opacity: 1,
    maxHeight: 80,
  },
  exitActive: {
    opacity: 0,
    maxHeight: 0,
    transition: 'all .5s ease-in-out',
  },
}));

const SendMessageForm = () => {
  const [confirmMessageIsOpen, confirmMessageOpen, confirmMessageClose] = useOpenClose();
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [audioProgress, setAudioProgress] = React.useState(0);
  const inputFileRef = useRef();
  const classes = useStyles();
  const [attachments, setAttachments] = React.useState([]);
  const occurrence = useOccurrence();
  const [plainText, setPlainText] = React.useState();
  const [sendMessage] = usePostOccurrenceMessageMutation();
  const [save] = useSaveOccurrenceMutation();
  const { aesKey } = useOccurrence();

  const onRemoveAttachment = id => () => {
    setAttachments(atts => atts.filter(a => a._id !== id));
  };

  const handleChange = e => {
    const { value } = e.target;
    setPlainText(value);
  };

  const handleAttach = () => {
    inputFileRef.current.click();
  };

  const handleFiles = useCallback(
    e => {
      const { files } = e.target;
      const progress = Array(files.length).fill(0);
      const onUploadProgress = Array(files.length)
        .fill(0)
        .map((_, idx) => progressEvent => {
          if (progressEvent?.lengthComputable) {
            progress[idx] = (progressEvent.loaded * 100) / progressEvent.total;
            setUploadProgress(progress.reduce((a, b) => a + b, 0) / files.length);
          }
        });
      const cancelFunc = Array(files.length).map(() => () => {});
      Promise.all(
        Array.from(files).map(async (file, idx) => {
          const encryptedAttachment = await AttachmentsService.uploadWb(
            occurrence.companyId,
            file,
            onUploadProgress[idx],
            cancelFunc[idx],
            file.name,
            null,
            aesKey,
          );
          const attachment = await decryptAttachment(encryptedAttachment, aesKey);
          setAttachments(atts => [...atts, attachment]);
        }),
      ).then(() => {
        setUploadProgress(0);
      });
    },
    [aesKey, occurrence.companyId],
  );

  const handleRecorded = async file => {
    setAudioProgress(0.001); // Disable button and show loader immediately
    const onUploadProgress = progressEvent => {
      if (progressEvent?.lengthComputable) {
        const newAudioProgress = (progressEvent.loaded * 100) / progressEvent.total;
        setAudioProgress(newAudioProgress);
      }
    };
    const cancelFunc = () => {};
    const encryptedAttachment = await AttachmentsService.uploadWb(
      occurrence.companyId,
      file,
      onUploadProgress,
      cancelFunc,
      file.name,
      null,
      aesKey,
    );
    const attachment = await decryptAttachment(encryptedAttachment, aesKey);
    setAttachments([...attachments, attachment]);
    setAudioProgress(0);
  };

  const doSendMessage = async () => {
    const text = await encrypt(plainText, occurrence.aesKey);
    await sendMessage({ id: occurrence._id, text, attachments: attachments.map(a => a._id) });
    setPlainText('');
    setAttachments([]);
  };

  const handleSendMessage = async e => {
    e.preventDefault();
    confirmMessageOpen();
  };

  const onConfirmSend = async status => {
    const promises = [doSendMessage()];
    if (status) {
      promises.push(save({ id: occurrence._id, body: { status } }));
    }
    await Promise.all(promises);
    confirmMessageClose();
  };

  const isUploadingFiles = uploadProgress > 0;
  return (
    <Box
      component="form"
      sx={{ m: 2, mt: 0, display: 'flex', alignItems: 'flex-end', flexDirection: 'column', gap: 2 }}
      onSubmit={handleSendMessage}
    >
      <OutlinedInput
        sx={{
          flexDirection: 'column',
        }}
        startAdornment={
          <Box
            sx={{
              display: 'flex',
              gap: 0.5,
              alignItems: 'center',
              width: '100%',
              color: 'secondary.main',
              mb: 1,
            }}
          >
            <ForumOutlineIcon size={16} />
            <Typography color="inherit" fontWeight={600}>
              <Translate value="OccurrenceDrawer.message" />
            </Typography>
          </Box>
        }
        fullWidth
        multiline
        minRows={7}
        placeholder={I18n.t('OccurrenceDrawer.messagePlaceholder')}
        value={plainText}
        onChange={handleChange}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%' }}>
        <TransitionGroup component={null}>
          {attachments.map(attachment => {
            return (
              <CSSTransition
                timeout={500}
                key={attachment._id}
                classNames={{
                  exit: classes.exit,
                  exitActive: classes.exitActive,
                  enter: classes.enter,
                  enterActive: classes.enterActive,
                }}
              >
                <Box
                  sx={{
                    border: 1,
                    borderColor: 'divider',
                    borderRadius: 1,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    overflow: 'hidden',
                  }}
                >
                  {isAudio(attachment) ? (
                    <AudioThumbnail data={attachment} />
                  ) : (
                    <Box
                      sx={{
                        borderRadius: 0,
                        minWidth: 250,
                        width: '100%',
                        maxWidth: 500,
                        display: 'flex',
                        alignItems: 'center',
                        padding: 1,
                        color: 'primary.main',
                      }}
                    >
                      <Box sx={{ fontSize: 36, cursor: 'pointer', color: 'primary.main' }}>
                        <MessageIcon message={{ attachments: [attachment] }} />
                      </Box>
                      <Box
                        sx={{
                          flexGrow: 1,
                          paddingLeft: 1,
                          paddingRight: 2,
                          color: 'inherit',
                        }}
                      >
                        <Typography sx={{ fontWeight: 500 }}>{attachment.name}</Typography>
                        <Typography variant="body2" color="text.secondary">
                          {filesize(attachment.size || 0)}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  <IconButton
                    size="large"
                    sx={{
                      m: 1.25,
                      p: 2,
                    }}
                    color="secondary"
                    onClick={onRemoveAttachment(attachment._id)}
                  >
                    <TrashCanOutlineIcon />
                  </IconButton>
                </Box>
              </CSSTransition>
            );
          })}
        </TransitionGroup>
      </Box>
      <Box sx={{ display: 'flex', gap: 1, width: '100%' }}>
        <Button
          sx={{ minHeight: 44 }}
          startIcon={
            <Box sx={{ color: 'primary.main' }}>
              {isUploadingFiles ? (
                <Loading value={uploadProgress} size={20} />
              ) : (
                <PaperclipIcon size={16} />
              )}
            </Box>
          }
          variant="outlined"
          color="secondary"
          onClick={handleAttach}
          disabled={isUploadingFiles}
        >
          {I18n.t('OccurrenceDrawer.attachFiles')}
        </Button>
        <input
          type="file"
          ref={inputFileRef}
          style={{ display: 'none' }}
          onChange={handleFiles}
          multiple
        />
        <RecordAudio progress={audioProgress} onRecorded={handleRecorded} />
        <Box sx={{ flexGrow: 1 }} />
        <Button
          disabled={!plainText && attachments.length === 0}
          startIcon={<SendIcon size={16} />}
          variant="contained"
          color="primary"
          type="submit"
          onClick={handleSendMessage}
        >
          <Translate value="OccurrenceDrawer.sendMessage" />
        </Button>
      </Box>
      <ConfirmMessageDialog
        onSubmit={onConfirmSend}
        close={confirmMessageClose}
        isOpen={confirmMessageIsOpen}
      />
    </Box>
  );
};

export default SendMessageForm;
