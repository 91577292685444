import makeStyles from '@mui/styles/makeStyles';
import { number, string } from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    height: 10,
    backgroundColor: props => props.color || theme.palette.primary.main,
  },
}));

const Line = ({ percent, color }) => {
  const classes = useStyles({ color });
  return <div className={classes.root} style={{ flexBasis: `${percent}%` }} />;
};

Line.propTypes = {
  percent: number.isRequired,
  color: string,
};

Line.defaultProps = {
  color: null,
};

export default Line;
