import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import channelsPanel from 'store/app/ui/ChannelsPanel/action';
import channel from 'store/app/ui/drawers/channel/action';

const mapStateToProps = state => ({
  showArchived: state.elements.ChannelsPanel.showArchived,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    channelsPanel: bindActionCreators(channelsPanel, dispatch),
    drawers: {
      channel: bindActionCreators(channel, dispatch),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
