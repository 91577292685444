/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { object, node } from 'prop-types';
import { Button, SvgIcon } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ArrowRightIcon from 'mdi-react/ArrowRightIcon';

const styles = {
  arrowIcon: {
    marginTop: 1,
    marginLeft: 5,
    fontSize: 17,
  },
};

const NextButton = ({ classes, children, ...props }) => (
  <Button {...props}>
    {children}
    <SvgIcon className={classes.arrowIcon}>
      <ArrowRightIcon />
    </SvgIcon>
  </Button>
);

NextButton.propTypes = {
  classes: object.isRequired,
  children: node.isRequired,
};

export default withStyles(styles)(NextButton);
