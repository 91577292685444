import { OPEN_DIRECTS_DRAWER, CLOSE_DIRECTS_DRAWER } from './action';

const initialState = {
  isOpen: false,
};

const directMessagesDrawer = (state = initialState, { type }) => {
  switch (type) {
    case OPEN_DIRECTS_DRAWER:
      return {
        ...state,
        isOpen: true,
      };

    case CLOSE_DIRECTS_DRAWER:
      return {
        ...state,
        isOpen: false,
      };

    default:
      return state;
  }
};

export default directMessagesDrawer;
