/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,padded-blocks */
import { Button, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import EmployeeAvatar from 'components/@home/EmployeeAvatar';
import { bool } from 'prop-types';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import auth from 'store/app/auth/action';
import settingsDrawer from 'store/app/ui/drawers/settings/action';
import authEmployee from 'store/selectors/authEmployee';
import useActions from 'utils/useActions';

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
};

const UserMenu = ({ disabled }) => {
  const [openSettings, logout] = useActions([settingsDrawer.open, auth.logout]);

  const employee = useSelector(authEmployee);

  if (!employee?._id) {
    return (
      <Button onClick={logout} variant="contained" color="primary">
        Logout
      </Button>
    );
  }

  return (
    <Box sx={styles.root}>
      <IconButton onClick={openSettings} color="inherit" disabled={disabled} size="large">
        <EmployeeAvatar employee={employee} />
      </IconButton>
    </Box>
  );
};

UserMenu.propTypes = {
  disabled: bool,
};

UserMenu.defaultProps = {
  disabled: false,
};

export default memo(UserMenu);
