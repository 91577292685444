import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import channels from 'store/app/entities/channels/action';
import messages from 'store/app/entities/messages/action';
import formDrawer from 'store/app/ui/drawers/form/action';
import authCompany from 'store/selectors/authCompany';
import authEmployee from 'store/selectors/authEmployee';
import authUser from 'store/selectors/authUser';
import currentChannel from 'store/selectors/currentChannel';

const mapStateToProps = state => ({
  isOpen: state.drawers.form.isOpen,
  isEdit: state.drawers.form.type === 'edit',
  sentAsChannel: state.drawers.form.sentAsChannel,
  channel: currentChannel(state),
  authCompany: authCompany(state),
  authEmployee: authEmployee(state),
  authUser: authUser(state),
});

const mapDispatchToProps = dispatch => ({
  actions: {
    forms: bindActionCreators(channels, dispatch),
    drawers: {
      form: bindActionCreators(formDrawer, dispatch),
    },
    messages: bindActionCreators(messages, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
