/* eslint-disable implicit-arrow-linebreak */
import withStyles from '@mui/styles/withStyles';
import { node, object, string } from 'prop-types';
import React from 'react';

const styles = () => ({
  root: {
    height: '100%',
    width: '100%',
  },

  container: {},
});

const Container = ({ children, className, classes }) => (
  <div className={`${classes.root} ${className}`}>{children}</div>
);

Container.propTypes = {
  classes: object.isRequired,
  children: node.isRequired,
  className: string,
};

Container.defaultProps = {
  className: '',
};

export default withStyles(styles)(Container);
