import {
  LOAD_FULL_MANDATORY_FULFILLED,
  LOAD_FULL_MANDATORY_PENDING,
  LOAD_FULL_MANDATORY_REJECTED,
  REMIND_EMPLOYEE_FULFILLED,
  REMIND_EMPLOYEE_REJECTED,
} from './action';

const initialState = {
  loading: false,
  mandatory: null,
};

const mandatoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_FULL_MANDATORY_PENDING:
      return { ...state, loading: true };

    case LOAD_FULL_MANDATORY_FULFILLED:
      return {
        ...state,
        page: action.meta.page,
        loading: false,
        mandatory: action.payload,
      };

    case LOAD_FULL_MANDATORY_REJECTED:
      return {
        ...state,
        error: true,
        loading: false,
      };

    case REMIND_EMPLOYEE_FULFILLED:
      return {
        ...state,
        loading: false,
        success: true,
      };

    case REMIND_EMPLOYEE_REJECTED:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};

export default mandatoryReducer;
