import { SvgIcon } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const ComposedIcon = ({ icon, extraIcon, position = 'bottom-end', size }) => (
  <div
    style={{
      position: 'relative',
      cursor: 'default',
    }}
  >
    <div style={{ lineHeight: '0px' }}>
      <SvgIcon
        sx={{
          fontSize: size,
          marginRight: 1,
          color: 'primary.main',
          position: 'relative',
        }}
      >
        {icon}
      </SvgIcon>
    </div>
    <div
      style={{
        lineHeight: '0px',
        position: 'absolute',
        textShadow:
          '0.75px 0px 0.5px #FFF, 0px 0.75px 0.5px #FFF, -0.75px 0px 0.5px #FFF, 0px -0.75px 0.5px #FFF',
        bottom: position.includes('bottom') ? '-4px' : null,
        top: position.includes('top') ? '-4px' : null,
        left: position.includes('start') ? '-4px' : null,
        right: position.includes('end') ? '-4px' : null,
      }}
    >
      <SvgIcon
        sx={{
          fontSize: size / 1.5,
          marginRight: 1,
          color: 'primary.main',
          position: 'relative',
          backgroundColor: 'background.paper',
          borderRadius: '50%',
        }}
      >
        {extraIcon}
      </SvgIcon>
    </div>
  </div>
);

ComposedIcon.propTypes = {
  icon: PropTypes.node.isRequired,
  extraIcon: PropTypes.node.isRequired,
  position: PropTypes.oneOf(['top-start', 'top-end', 'bottom-start', 'bottom-end']),
  size: PropTypes.number,
};

ComposedIcon.defaultProps = {
  position: 'bottom-end',
  size: 18,
};

export default ComposedIcon;
