import { MenuItem, Select } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowDown from 'assets/icons/arrow-down.svg';
import { arrayOf, string } from 'prop-types';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { IS_DEV } from 'services/constants';
import Cookie from 'services/Cookie';
import shortCompany from 'shapes/shortCompany';
import authActions from 'store/app/auth/action';
import redirect from 'utils/redirect';
import useActions from 'utils/useActions';
import connector from './connector';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 2,
    border: '1px solid transparent',
    borderRadius: 20,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  notchedOutline: {
    border: 0,
    display: 'none',
  },
  select: {
    paddingLeft: 10,
    paddingRight: 23,
    borderRadius: 20,
    color: theme.palette.secondary.main,
  },
  icon: {
    color: theme.palette.secondary.extraLight,
  },
  arrow: {
    display: 'inline-block',
    width: '12px',
    top: 'calc(50% - 6px)',
    right: '6px',
    position: 'absolute',
    pointerEvents: 'none',
  },
}));

const ArrowDownComponent = () => {
  const classes = useStyles();
  return <ArrowDown className={classes.arrow} />;
};

const CompaniesDropdown = ({ companies, companyId }) => {
  const classes = useStyles();
  const location = useLocation();
  const flushUserData = useActions(authActions.flushUserData);
  const handleChange = async e => {
    const { _id, language, name, subDomain, timeZone, workingHours } = companies.find(
      c => c._id === e.target.value,
    );

    const company = { _id, language, name, subDomain, timeZone, workingHours };

    Cookie.set('company', company, { secure: !IS_DEV });
    flushUserData();
    setTimeout(() => redirect(location.pathname, company.subDomain), 100);
  };

  if (companies?.length === 1) {
    return null;
  }

  return (
    <Select
      variant="standard"
      disableUnderline
      value={companyId}
      classes={{
        root: classes.root,
        select: classes.select,
        icon: classes.icon,
        notchedOutline: classes.notchedOutline,
      }}
      inputProps={{
        classes: {
          notchedOutline: classes.notchedOutline,
        },
      }}
      onChange={handleChange}
      IconComponent={ArrowDownComponent}
    >
      {companies.map(c => (
        <MenuItem key={c._id} value={c._id}>
          {c.name}
        </MenuItem>
      ))}
    </Select>
  );
};

CompaniesDropdown.propTypes = {
  companies: arrayOf(shortCompany).isRequired,
  companyId: string.isRequired,
};

export default connector(CompaniesDropdown);
