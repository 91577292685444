/* eslint-disable class-methods-use-this */
import Http from 'services/Http';

class ChatFlowIntent {
  async find(id) {
    const { data } = await Http.get(`/v1/chat-flow-intent/${id}`);
    return data.chatFlowIntent;
  }

  async list() {
    const { data } = await Http.get(`/v1/chat-flow-intent`);
    return data;
  }

  async create(form) {
    const response = await Http.post(`/v1/chat-flow-intent`, form);
    return response.data.chatFlowIntent;
  }

  async update(id, form) {
    const response = await Http.patch(`/v1/chat-flow-intent/${id}`, form);
    return response.data.chatFlowIntent;
  }

  async destroy(id) {
    const { data } = await Http.delete(`/v1/chat-flow-intent/${id}`);
    return data;
  }
}

export default new ChatFlowIntent();
