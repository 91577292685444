import Http from 'services/Http';

class HrBot {
  send = async body => Http.post(`/v1/hr-bot/send`, body);

  checkIds = async params => {
    const response = await Http.post(`/v1/hr-bot/check-ids`, params);
    return response.data;
  };
}

export default new HrBot();
