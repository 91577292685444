import authCompany from 'store/selectors/authCompany';
import populateEmployees from 'store/selectors/populateEmployees';
import { createSelector } from 'reselect';

const currentEmployee = (employees, auth, company) => {
  const emp = { ...company.employee, ...employees[auth.employee_id] };
  emp.archivedChannels = emp.archivedChannels || [];
  return emp;
};

export default createSelector(
  state => populateEmployees(state),
  state => state.auth,
  state => authCompany(state),
  currentEmployee,
);
