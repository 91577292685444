export const OPEN_VIDEO_MODAL = 'OPEN_VIDEO_MODAL';
export const CLOSE_VIDEO_MODAL = 'CLOSE_VIDEO_MODAL';

const open = url => ({
  type: OPEN_VIDEO_MODAL,
  payload: url,
});

const close = () => ({
  type: CLOSE_VIDEO_MODAL,
});

export default { open, close };
