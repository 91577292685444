import { OPEN_LAUNCHER_APP_DRAWER, CLOSE_LAUNCHER_APP_DRAWER } from './action';

const initialState = {
  isOpen: false,
};

const launcherAppDrawer = (state = initialState, { type }) => {
  switch (type) {
    case OPEN_LAUNCHER_APP_DRAWER:
      return {
        ...state,
        isOpen: true,
      };

    case CLOSE_LAUNCHER_APP_DRAWER:
      return {
        ...state,
        isOpen: false,
      };

    default:
      return state;
  }
};

export default launcherAppDrawer;
