import { connect } from 'react-redux';
import authEmployee from 'store/selectors/authEmployee';
import populateChannels from 'store/selectors/populateChannels';

const mapStateToProps = (state, ownProps) => {
  const allChannels = populateChannels(state);
  return {
    authEmployee: authEmployee(state),
    channel: allChannels[ownProps.message?.channel_id],
  };
};

export default connect(mapStateToProps);
