let resolved = false;
let supportsPassive = false;

export default function isPassiveSupported() {
  if (resolved) {
    return supportsPassive;
  }
  try {
    const opts = Object.defineProperty({}, 'passive', {
      // eslint-disable-next-line getter-return
      get() {
        supportsPassive = true;
      },
    });
    window.addEventListener('test', null, opts);
  } catch (e) {
    // Ignore error
  }

  resolved = true;
  return supportsPassive;
}
