export const OPEN_FORM_DRAWER = 'OPEN_FORM_DRAWER';
export const CLOSE_FORM_DRAWER = 'CLOSE_FORM_DRAWER';

const open = formId => ({
  type: OPEN_FORM_DRAWER,
  payload: formId,
});

const close = () => ({
  type: CLOSE_FORM_DRAWER,
});

export default { open, close };
