/* eslint-disable max-len,react/destructuring-assignment */
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import DownloadButton from 'components/@home/modals/DocumentModal/DownloadButton';
import Loading from 'components/common/Loading';
import moment from 'moment';
import { bool, object, shape } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { I18n } from 'utils/i18n';
import channelShape from 'shapes/channel';
import employeeShape from 'shapes/employee';
import messageShape from 'shapes/message';
import getFile from 'utils/getFile';
import Modal from '../../Modal';
import connector from './connector';
import Information from './Information';
import IUnderstoodButton from './IUnderstoodButton';

const styles = () => ({
  root: {
    position: 'relative',
    padding: '20px 0 10px',
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    margin: '0 0 15px',
    textAlign: 'center',
    width: '100%',
    wordBreak: 'break-all',
  },
  iframeShadow: {
    maxWidth: 860,
    width: '90vw',
    height: '70vh',
    maxHeight: 'calc(100vh - 250px)',
    position: 'relative',
    '&::before': {
      content: `''`,
      position: 'absolute',
      boxShadow: 'inset 0px -10px 10px -10px rgba(0,0,0,0.5)',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      pointerEvents: 'none',
    },
  },
  iframe: {
    zIndex: 'initial',
    border: 0,
    width: '100%',
    height: '100%',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: 20,
    height: 39,
  },
  download: {
    height: 39,
    marginRight: 10,
  },
});

const getPdf = message =>
  message?.attachments?.[0].httpLink || message?.attachments?.[0].toString();
const getPdfName = message => message.attachments?.[0]?.name;
const getMimeType = message => message.attachments?.[0]?.mimeType;

const ReadModal = ({
  classes,
  isOpen,
  actions,
  message,
  employee,
  channel: { isDirect, isAdmin } = {},
  authEmployee,
  isCompanyAdmin,
  isTeamAdmin,
  log,
}) => {
  const emp = employee || authEmployee;
  const isMe = employee?._id === authEmployee?._id;
  const [pdf, setPdf] = useState(null);
  const ack =
    message?.acknowledges.find(
      a => a.user.toString() === (emp.user_id || emp.user?._id.toString()),
    ) || {};
  const pdfUrl = message?.digitalSignature ? ack[log ? 'logUrl' : 'signedDocUrl'] : getPdf(message);
  const name = emp?.name || emp?.fullName;
  const acknowledge = message?.acknowledges?.find(m => m.user === emp?.user?._id);

  const openAnalyticsDrawer = () => {
    actions.acknowledgmentReadModal.close();
    actions.analyticsDrawer.open(message);
  };

  useEffect(() => {
    (() => {
      if (!isOpen || !pdfUrl) return;
      import('pdf-lib').then(async ({ degrees, PDFDocument, rgb, StandardFonts }) => {
        try {
          const pdfBuffer = await getFile(pdfUrl);
          const pdfDoc = await PDFDocument.load(pdfBuffer, { ignoreEncryption: true });
          if (acknowledge && !message?.digitalSignature) {
            const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
            const pages = pdfDoc.getPages();
            pages.forEach(p => {
              p.drawText(
                I18n.t('AcknowledgementReadModal.Acknowledged on', {
                  name,
                  date: moment.unix(acknowledge.confirmedAt).format('LLLL'),
                }),
                {
                  x: 15,
                  y: 50,
                  size: 10,
                  font: helveticaFont,
                  color: rgb(0.2, 0.2, 0.2),
                  rotate: degrees(90),
                },
              );
            });
          }
          const blobUrl = URL.createObjectURL(
            new Blob([await pdfDoc.save()], { type: 'application/pdf' }),
          );
          setPdf(blobUrl);
        } catch (error) {
          setPdf(pdfUrl);
        }
      });
    })();
  }, [acknowledge, isOpen, message?.digitalSignature, name, pdfUrl]);

  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onClose={actions.acknowledgmentReadModal.close}>
      <div className={classes.root}>
        <h3 className={classes.title}>{message.text || message.title}</h3>
        <Information
          isMe={isMe}
          digitalSignature={message.digitalSignature}
          confirmedAt={acknowledge?.confirmedAt}
        />
        {pdf ? (
          <div className={classes.iframeShadow}>
            <iframe className={classes.iframe} title="pdf" src={pdf} />
          </div>
        ) : (
          <Loading classes={classes.loading} size={40} key="loading" />
        )}
        <div className={classes.actions}>
          <DownloadButton
            color={acknowledge ? 'primary' : ''}
            variant={acknowledge ? 'contained' : 'outlined'}
            className={classes.download}
            src={pdf}
            name={getPdfName(message)}
            mimeType={getMimeType(message)}
          />
          {isCompanyAdmin || (isTeamAdmin && isDirect) || isAdmin ? (
            <Button variant="outlined" className={classes.showAcks} onClick={openAnalyticsDrawer}>
              {' '}
              {I18n.t('Documents.Monitor')}
            </Button>
          ) : null}
          {!acknowledge && isMe ? (
            <IUnderstoodButton
              onClose={actions.acknowledgmentReadModal.close}
              messageId={message._id}
            />
          ) : null}
        </div>
      </div>
    </Modal>
  );
};

ReadModal.propTypes = {
  isCompanyAdmin: bool.isRequired,
  isTeamAdmin: bool.isRequired,
  classes: object.isRequired,
  isOpen: bool.isRequired,
  message: messageShape,
  channel: channelShape,
  actions: shape({
    acknowledgmentReadModal: object.isRequired,
  }).isRequired,
  employee: employeeShape,
  authEmployee: employeeShape,
  log: bool,
};
ReadModal.defaultProps = {
  message: null,
  authEmployee: null,
  employee: null,
  channel: undefined,
  log: false,
};
export default withStyles(styles)(connector(ReadModal));
