import { withFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import authCompany from 'store/selectors/authCompany';
import uniqid from 'uniqid';

const formik = withFormik({
  mapPropsToValues: ({ channel, isMember, locale, contextMessage }) => {
    return {
      attachments: [],
      message:
        contextMessage?.type === 'edit'
          ? { text: contextMessage.message.text, markup: contextMessage.message.text }
          : { text: '', markup: '' },
      sentAsChannel: !isMember || !!channel.isReadOnly,
      locale,
      channel: channel._id,
      company: channel.company,
      contextMessage,
    };
  },
  enableReinitialize: true,
  handleSubmit: async (values, { props, setFieldValue, setSubmitting }) => {
    const { actions, authEmployee, authUser, channel } = props;
    const { message, sentAsChannel, attachments, contextMessage } = values;

    const sendMessage = async (text = '', mentions = [], atts = []) => {
      const msg = {
        _id: contextMessage?.type === 'edit' ? contextMessage.message._id : uniqid(),
        text,
        ...(contextMessage?.type !== 'edit'
          ? {
              mentions:
                mentions?.map(m => {
                  const [employee, user] = m.id.split('-');
                  return {
                    pos: [m.plainTextIndex, m.plainTextIndex + m.display.length - 1],
                    employee,
                    user,
                  };
                }) || [],
              title: undefined,
              acknowledges: [],
              attachments: atts,
              channel_id: channel._id,
              company_id: authCompany._id,
              previews: {},
              createdAt: new Date().toISOString(),
              employee_id: authEmployee?._id,
              user_id: authUser._id,
              isDeletedBy: [],
              isDraft: false,
              isEdited: false,
              isMandatory: false,
              temp: true,
              seen: false,
              senderWasDeleted: false,
              sentAsChannel,
            }
          : {}),
        ...(contextMessage?.type === 'reply' ? { replyTo: contextMessage.message._id } : {}),
      };

      if (contextMessage?.type === 'edit') {
        await actions.messages.update(msg);
      } else {
        await actions.messages.send(msg);
      }
      await actions.chatPanel.removeContextMessage(channel._id);
    };

    if (isEmpty(attachments) && isEmpty(message.text?.trim())) return;

    if (!isEmpty(attachments)) {
      // eslint-disable-next-line no-restricted-syntax
      for (const attachment of attachments) {
        // eslint-disable-next-line no-await-in-loop
        await sendMessage(message.text, message.mentions, [attachment]);
      }
    } else {
      await sendMessage(message.text, message.mentions);
    }

    if (isEmpty(attachments)) {
      setFieldValue('message', '');
    }

    setFieldValue('attachments', []);

    setSubmitting(false);
  },
  displayName: 'MessageForm',
});

export default formik;
