import { ListItemIcon, Typography } from '@mui/material';
import CircleIcon from 'mdi-react/CircleIcon';
import React from 'react';

export const colorScale = ['#57bb8a', '#9cc745', '#ffd666', '#f68013', '#e75448'];

export const severities = Array.from({ length: 5 }, (_, i) => i + 1).map(value => [
  value,
  <>
    <ListItemIcon key={value}>
      <CircleIcon color={colorScale[value - 1]} />
    </ListItemIcon>
    <Typography component="span">{value}</Typography>
  </>,
]);

export const statuses = {
  NEW: 'NEW',
  RECEIVED: 'RECEIVED',
  UNDER_INVESTIGATION: 'UNDER_INVESTIGATION',
  WAITING_FOR_WHISTLEBLOWER: 'WAITING_FOR_WHISTLEBLOWER',
  CLOSED: 'CLOSED',
};

export const OCCURRENCE_CLOSING_CODES = Array.from(
  { length: 5 },
  (_, i) => `OCCURRENCE_CLOSING_CODE_${i}`,
);
