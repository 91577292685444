import { Button, IconButton, SvgIcon, Toolbar, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ChatFlowContext } from 'components/@home/@settings/Tabs/ChatFlowContext';
import ChatFlowEdge from 'components/@home/@settings/Tabs/ChatFlowEdge';
import ChatFlowNode from 'components/@home/@settings/Tabs/ChatFlowNode';
import connector from 'components/@home/@settings/Tabs/connector';
import getLayoutedElements from 'components/@home/@settings/Tabs/getLayoutedElements';
import useZoomPanHelper from 'components/@home/@settings/Tabs/useZoomPanHelper';
import isEqual from 'lodash/isEqual';
import AlertCircleCheckOutlineIcon from 'mdi-react/AlertCircleCheckOutlineIcon';
import BrainFreezeOutlineIcon from 'mdi-react/BrainFreezeOutlineIcon';
import CommentTextMultipleIcon from 'mdi-react/CommentTextMultipleIcon';
import FitToPageOutlineIcon from 'mdi-react/FitToPageOutlineIcon';
import GraphOutlineIcon from 'mdi-react/GraphOutlineIcon';
import LoadingIcon from 'mdi-react/LoadingIcon';
import ZoomInOutlineIcon from 'mdi-react/ZoomInOutlineIcon';
import ZoomOutOutlineIcon from 'mdi-react/ZoomOutOutlineIcon';
import { object } from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import ReactFlow, {
  Background,
  MiniMap,
  ReactFlowProvider,
  removeElements,
  useStoreState,
} from 'react-flow-renderer';
import { I18n } from 'utils/i18n';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  toolbar: {
    minHeight: 49,
    overflow: 'hidden',
  },
  flow: {
    boxShadow: `0 0 0 0.5px ${theme.palette.secondary.light}`,
    '& .react-flow__node-page': {
      padding: 0,
      border: 0,
      minWidth: 150,
    },
  },
  route: {
    position: 'relative',
    marginTop: 10,
    marginBottom: 10,
    boxShadow: `0 0 0 0.5px ${theme.palette.secondary.light}`,
    borderRadius: 2,
  },
  handle: {
    right: -14,
  },
  item: {
    paddingTop: 0,
    paddingBottom: 0,
    borderBottom: 'none',
    minHeight: 0,
    marginTop: 0,
    marginBottom: 0,
  },
  background: { backgroundColor: theme.palette.secondary.ultraUltraLight },
  toolbarCenter: {
    flexGrow: 1,
  },
  '@keyframes myEffect': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '50%': {
      transform: 'rotate(360deg)',
    },
    '100%': {
      transform: 'rotate(720deg)',
    },
  },
  trainingIcon: {
    fontSize: 18,
    marginRight: 5,
    color: theme.palette.primary.main,
    animationName: '$myEffect',
    animationDuration: '2s',
    animationTimingFunction: theme.transitions.easing.easeIn,
    animationIterationCount: 'infinite',
  },
  icon: {
    fontSize: 18,
    marginRight: 5,
    color: theme.palette.primary.main,
  },
  errorMessage: {
    display: 'flex',
    alignItems: 'center',
  },
  warningIcon: {
    fontSize: 15,
    color: theme.palette.danger,
    marginRight: 5,
  },
}));

const position = { x: 0, y: 0 };

const ChatFlowEdit = ({ actions, flow }) => {
  const classes = useStyles();
  const { zoomIn, zoomOut, fitView, focusNode } = useZoomPanHelper();
  const { context } = useContext(ChatFlowContext);
  const [reactflowInstance, setReactflowInstance] = useState(null);
  const [elements, setElements] = useState([]);
  const [layoutState, setLayoutState] = useState('not done');
  const { nodes, edges } = useStoreState(
    ({ nodes: n, edges: e }) => ({ nodes: n, edges: e }),
    isEqual,
  );

  useEffect(() => {
    actions.chatFlowIntents.load();
  }, [actions.chatFlowIntents]);

  useEffect(() => {
    if (flow?._id) {
      actions.chatFlows.find(flow._id);
    }
  }, [actions.chatFlows, flow?._id]);

  useEffect(() => {
    const inNodes = {};
    const inEdges = [];
    if (!flow?.pages) {
      return;
    }
    flow.pages.forEach(page => {
      inNodes[page._id] = {
        id: page._id,
        type: 'page',
        data: {
          page,
          startPage: flow.startPage,
        },
        sourcePosition: 'right',
        targetPosition: 'left',
        position,
      };
      page.routes.forEach(r => {
        if (r.transition && r.transition !== flow.startPage) {
          inEdges.push({
            id: r._id,
            type: 'route',
            source: page._id,
            target: r.transition,
            arrowHeadType: 'arrow',
            sourceHandle: r._id,
          });
        }
      });
    });
    setElements(Array.prototype.concat(Object.values(inNodes), inEdges));
    setLayoutState('not done');
  }, [flow]);

  useEffect(() => {
    if (layoutState === 'not done') {
      setLayoutState('pending');
    }
  }, [nodes, edges, layoutState]);

  const layout = () => {
    const layoutedElements = getLayoutedElements(nodes, edges);
    setElements(layoutedElements);
  };

  useEffect(() => {
    if (layoutState === 'pending' && nodes[0]?.__rf.width > 0) {
      setLayoutState('done');
      layout();
      setTimeout(() => {
        reactflowInstance.fitView();
      }, 20);
    }
  }, [layoutState, nodes.length, nodes[0]?.__rf.width]);

  useEffect(() => {
    if (!reactflowInstance) {
      return;
    }
    setTimeout(() => {
      const selectedNode = context.stack[0] && nodes?.find(n => n.id === context.stack[0]?.page);
      focusNode(selectedNode);
    }, 100);
  }, [reactflowInstance, context.current]);

  const onElementsRemove = useCallback(
    elementsToRemove => setElements(els => removeElements(elementsToRemove, els)),
    [],
  );

  const onLoad = useCallback(
    rfi => {
      if (!reactflowInstance) {
        setReactflowInstance(rfi);
      }
    },
    [reactflowInstance],
  );

  const trainNLU = () => {
    actions.chatFlows.train(flow._id);
  };

  if (!flow) {
    return null;
  }
  return (
    <div className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <IconButton onClick={zoomIn} size="large">
          <ZoomInOutlineIcon />
        </IconButton>
        <IconButton onClick={zoomOut} size="large">
          <ZoomOutOutlineIcon />
        </IconButton>
        <IconButton onClick={fitView} size="large">
          <FitToPageOutlineIcon />
        </IconButton>
        <IconButton onClick={layout} size="large">
          <GraphOutlineIcon />
        </IconButton>
        <div className={classes.toolbarCenter} />
        {!flow.lastVersionTrained && (
          <Typography color="error" className={classes.errorMessage}>
            <SvgIcon className={classes.warningIcon}>
              <AlertCircleCheckOutlineIcon />
            </SvgIcon>
            {I18n.t('ChatFlowSettings.Needs training')}
          </Typography>
        )}
        <Button variant="text" color="secondary" onClick={trainNLU}>
          <SvgIcon className={flow.training ? classes.trainingIcon : classes.icon}>
            {flow.training ? <LoadingIcon /> : <BrainFreezeOutlineIcon />}
          </SvgIcon>
          {I18n.t('ChatFlowSettings.Train NLU')}
        </Button>
        <Button
          variant="text"
          color="secondary"
          onClick={() => context.type !== 'test' && context.push({ type: 'test' })}
        >
          <SvgIcon className={classes.icon}>
            <CommentTextMultipleIcon />
          </SvgIcon>
          {I18n.t('ChatFlowSettings.Test flow')}
        </Button>
      </Toolbar>
      <ReactFlow
        elementsSelectable
        nodeTypes={{ page: ChatFlowNode }}
        edgeTypes={{ route: ChatFlowEdge }}
        elements={elements}
        onElementsRemove={onElementsRemove}
        onLoad={onLoad}
        snapToGrid
        arrowHeadColor="currentColor"
        nodesConnectable={false}
        className={classes.flow}
        maxZoom={1}
      >
        {!context.current && <MiniMap />}
        <Background className={classes.background} color="#ccc" />
      </ReactFlow>
    </div>
  );
};
ChatFlowEdit.propTypes = {
  flow: object.isRequired,
  actions: object.isRequired,
};
export default connector(props => (
  <ReactFlowProvider>
    <ChatFlowEdit {...props} />
  </ReactFlowProvider>
));
