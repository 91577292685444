import Socket from 'services/Socket';

export const SET_FOCUS = 'SET_FOCUS';
export const SET_ACTIVE = 'SET_ACTIVE';

const setFocus = focus => dispatch => {
  Socket.emit('blurred', { blurred: !focus });
  dispatch({
    type: SET_FOCUS,
    focus,
  });
};

const setActive = active => dispatch => {
  Socket.emit('active', !!active);
  dispatch({
    type: SET_ACTIVE,
    active,
  });
};

const onConnect = () => (dispatch, getState) => {
  const { isActive, hasFocus } = getState().elements.Focus;
  Socket.emit('blurred', { blurred: !hasFocus });
  Socket.emit('active', !!isActive);
};

export default { onConnect, setActive, setFocus };
