import withStyles from '@mui/styles/withStyles';
import { object } from 'prop-types';
import React from 'react';
import { Outlet } from 'react-router-dom';
import NoInvitationHeading from './NoInvitationHeading';

const styles = {
  root: {},
};

const LoginNoInvitationLayout = ({ classes }) => (
  <div className={classes.root}>
    <NoInvitationHeading />
    <Outlet />
  </div>
);
LoginNoInvitationLayout.propTypes = {
  classes: object.isRequired,
};

export default withStyles(styles)(LoginNoInvitationLayout);
