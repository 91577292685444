import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { object, string } from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import connector from './connector';

const InvitationDialog = ({ message, actions, login: { invitationDialog } }) => {
  const navigate = useNavigate();
  return (
    <Dialog
      open={invitationDialog.isOpen}
      onClose={actions.login.invitationDialog.close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
      <DialogActions>
        <Button
          onClick={() => {
            navigate('/auth/login');
            actions.login.invitationDialog.close();
          }}
          variant="contained"
          color="primary"
          autoFocus
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
InvitationDialog.propTypes = {
  login: object.isRequired,
  message: string.isRequired,
  actions: object.isRequired,
};

export default connector(InvitationDialog);
