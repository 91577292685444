import { GlobalStyles, StyledEngineProvider, ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { caES as coreCaES, enUS as coreEnUS, esES as coreEsES } from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/system';
import { enUS as dgEnUS } from '@mui/x-data-grid/locales/enUS';
import { esES as dgEsES } from '@mui/x-data-grid/locales/esES';
import ErrorFallback from 'components/common/ErrorFallback';
import { GlobalConfirmDialogProvider } from 'components/common/GlobalConfirmDialog';
import React, { useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import theme from 'styles/theme';
import { caES as dgCaES } from 'styles/x-data-grid/locales/caES';
import GlobalAlert from '../GlobalAlert';
import Container from './Container';

const locales = {
  es: [
    {
      ...dgEsES,
      components: {
        ...dgEsES.components,
        MuiDataGrid: {
          defaultProps: { localeText: { noRowsLabel: 'Sin resultados.' } },
        },
      },
    },
    coreEsES,
  ],
  ca: [dgCaES, coreCaES],
  en: [
    {
      ...dgEnUS,
      components: {
        ...dgEnUS.components,
        MuiDataGrid: {
          defaultProps: { localeText: { noRowsLabel: 'No results.' } },
        },
      },
    },
    coreEnUS,
  ],
};

const LayoutScene = () => {
  const locale = useSelector(state => state.i18n.locale);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const themeWithLocale = useMemo(
    () => createTheme(theme(prefersDarkMode), ...(locales[locale] || [])),
    [locale, prefersDarkMode],
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themeWithLocale}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <GlobalStyles styles={{ body: { color: themeWithLocale.palette.background.paper } }} />
        <Container>
          <Helmet>
            <html lang={locale} />
            <meta httpEquiv="Content-Language" content={locale} />
          </Helmet>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <GlobalConfirmDialogProvider>
              <Outlet />
            </GlobalConfirmDialogProvider>
            <GlobalAlert />
          </ErrorBoundary>
        </Container>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default LayoutScene;
