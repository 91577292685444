import { createSelector } from 'reselect';
import arrayToObject from 'utils/arrayToObject';
import { getMagicLink } from 'utils/getMagicLink';
import getMoment from 'utils/getMoment';
import removeAccents from 'utils/removeAccents';

const populateEmployees = (employees, users, companies) => {
  const employeesArray = employees.map(e => {
    const company = companies[e.company_id || e.company];
    return {
      ...e,
      lastSeen: getMoment(e.lastSeen),
      name: e.firstName ? `${e.firstName} ${e.surName}` : '*****',
      user: users[e.user_id] || e.user ? { ...users[e.user_id], ...e.user } : null,
      company: companies[e.company_id],
      isAcceptTerms: e.isAcceptTerms,
      teams: e.teams || [],
      archivedChannels: e.archivedChannels,
      magicLink: getMagicLink(company, e),
    };
  });

  return arrayToObject(employeesArray);
};

export default createSelector(
  state => Object.values(state.employees.employees),
  state => state.users,
  state => state.companies,
  populateEmployees,
);

const addSearchBlob = employees => {
  return employees.map(e => ({
    ...e,
    searchBlob: removeAccents(
      `${e.firstName} ${e.surName} ${e.division} ${e.code} ${e.department} ${e.jobTitle} ${
        e.location
      } ${e.reportsTo} ${e.user?.phone} ${e.tags?.join(' ')} ${e.notes} ${e.teams
        ?.map(t => t.name)
        .join(' ')} ${e.response}`,
    ).toLowerCase(),
  }));
};

export const populateAdminEmployees = createSelector(
  state => addSearchBlob(Object.values(state.employees.employees).filter(e => e.canAdmin)),
  state => state.users,
  state => state.companies,
  populateEmployees,
);
