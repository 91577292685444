/**
 * @flow
 */
import Box from '@mui/joy/Box';
import { FormControlLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { I18n } from 'utils/i18n';

const styles = {
  label: {
    alignItems: 'flex-start',
  },
  labelText: {
    fontWeight: 500,
  },
  input: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      minWidth: 36,
    },
  },
};
const SelectField = ({
  name,
  disabled,
  options,
}: {
  name: String;
  disabled: boolean;
  options: Array;
}) => {
  const { control } = useFormContext();
  return (
    <FormControlLabel
      sx={styles.label}
      labelPlacement="top"
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Select {...field} inputProps={{ sx: styles.input }} fullWidth disabled={disabled}>
              {options.map(option => {
                const [value, label] = ['string', 'number'].includes(typeof option)
                  ? [option, I18n.t(`OccurrenceDrawer.${name}.${option}`)]
                  : option;
                return (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                );
              })}
            </Select>
          )}
        />
      }
      label={<Box sx={styles.labelText}>{I18n.t(`OccurrenceDrawer.fields.${name}`)}</Box>}
    />
  );
};

export default SelectField;
