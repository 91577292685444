import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ChatHeaderStatus from 'components/@home/@messages/ChatPanel/ChatHeader/ChatHeaderStatus';
import EmployeeAvatar from 'components/@home/EmployeeAvatar';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import currentChannel from 'store/selectors/currentChannel';
import DetailsButton from './DetailsButton';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 70,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '13px 0',
    margin: '0 20px',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  userContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  channelInfo: {
    paddingLeft: 12,
  },
  channelTitle: {
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  status: {
    paddingLeft: 12,
  },
}));

const limitedCurrentChannel = createSelector(currentChannel, ({ name, description, avatar }) => ({
  name,
  description,
  avatar,
}));

const ChatHeader = () => {
  const { name, avatar, description } = useSelector(limitedCurrentChannel);
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.userContainer}>
        <EmployeeAvatar
          large
          employee={{
            name,
            avatar,
          }}
        />
        <div className={classes.channelInfo}>
          <Typography className={classes.channelTitle}>
            {name + (description ? ` - ${description}` : '')}
          </Typography>

          <ChatHeaderStatus />
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <DetailsButton />
      </div>
    </div>
  );
};

export default memo(ChatHeader);
