import { Button, SvgIcon, Typography } from '@mui/material';
import { node, object } from 'prop-types';
import React, { memo } from 'react';

const styles = {
  root: {
    margin: 0.25,
    display: 'flex',
    alignItems: 'center',
    color: 'secondary.main',
    marginRight: 2,
  },
  icon: {
    paddingRight: 0.5,
    color: 'secondary.main',
  },
};

const OptionButton = ({ icon, children, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Button sx={styles.root} {...props}>
    <SvgIcon sx={styles.icon}>{icon}</SvgIcon>
    <Typography component="div" color="inherit">
      {children}
    </Typography>
  </Button>
);

OptionButton.propTypes = {
  classes: object.isRequired,
  icon: node.isRequired,
  children: node,
};

OptionButton.defaultProps = {
  children: null,
};

export default memo(OptionButton);
