/**
 * WhatsAppTextFormatter Component for React
 *
 * This component takes React children and formats the text content according to WhatsApp-like syntax:
 * - Text wrapped in * will be bold.
 * - Text wrapped in _ will be italicized.
 * - Text wrapped in ~ will be strikethrough.
 * - Text wrapped in ``` will be monospace.
 *
 * Example usage:
 * <WhatsAppTextFormatter>
 *   This is an *example* of _WhatsApp_ text ~formatting~ and ```monospace```.
 * </WhatsAppTextFormatter>
 *
 * Author: OpenAI (ChatGPT)
 *
 * @flow
 */

import * as React from 'react';
import applyToTextChildren from 'utils/applyToTextChildren';

// Regex patterns for each formatting type
const regexParts = {
  bold: '(?<=\\s|^)\\*((?!\\*).+?)\\*(?=\\s|$)',
  italic: '(?<=\\s|^|[^\\w])_(?!_)(.+?)_(?=\\s|$|[^\\w])',
  strikethrough: '(?<=\\s|^|[^\\w])~(?!~)(.+?)~(?=\\s|$|[^\\w])',
  monospace: '(?<=\\s|^)```((?!```).+?)```(?=\\s|$)',
};

// Array of formatting names
const formatNames = Object.keys(regexParts);

// Combine all regex patterns into a single regex
const regex = new RegExp(Object.values(regexParts).join('|'), 'g');

/**
 * Parses text looking for formatting patterns and returns an array of React components applying the formatting.
 * For example, the text "This is an *example* of _WhatsApp_ text ~formatting~ and ```monospace```." will be parsed
 * into the following array:
 * [
 *  'This is an ',
 *  <strong>example</strong>,
 *  ' of ',
 *  <em>WhatsApp</em>,
 *  ' text ',
 *  <del>formatting</del>,
 *  ' and ',
 *  <code>monospace</code>,
 *  '.'
 *  ]
 *  The array can then be rendered as React children.
 * @param inputText {string} - The text to parse
 * @returns {[]} - An array of React components
 */
const parseText = inputText => {
  const tokens = [];
  let currentIndex = 0;

  // Find all matches of formatting patterns in the input text
  const matches = [...inputText.matchAll(regex)];

  // Loop through each matched pattern
  matches.forEach(match => {
    // If there is text before the matched pattern, add it to the tokens array
    if (match.index > currentIndex) {
      tokens.push(inputText.slice(currentIndex, match.index));
    }

    // Extract the matched text, including the formatting characters
    const matchedText = match[0];

    // Get the index of the matched format in the formatNames array
    const idx = match.slice(1).findIndex(m => !!m);

    // Get the name of the matched format
    const matchedFormat = formatNames[idx];

    // Determine the number of characters to remove (1 or 3 for monospace)
    const number = matchedFormat === 'monospace' ? 3 : 1;

    // Remove formatting characters by slicing the string
    const innerText = matchedText.slice(number, -number);

    // Recursively parse the inner text (excluding monospace)
    const parsedInnerText = matchedFormat !== 'monospace' ? parseText(innerText) : innerText;

    // Create a styled component based on the matched format with key prop
    const component = {
      bold: <strong key={match.index}>{parsedInnerText}</strong>,
      italic: <em key={match.index}>{parsedInnerText}</em>,
      strikethrough: <del key={match.index}>{parsedInnerText}</del>,
      monospace: <code key={match.index}>{parsedInnerText}</code>,
    }[matchedFormat];

    // Add the styled component to the tokens array
    tokens.push(component);

    // Update the current index to the end of the matched text
    currentIndex = match.index + matchedText.length;
  });

  // If there is text left after the last matched pattern, add it to the tokens array
  if (currentIndex < inputText.length) {
    tokens.push(inputText.slice(currentIndex));
  }

  return tokens;
};

type Props = {
  children: React.Node;
};

/**
 * Applies WhatsApp-like formatting to text children.
 * @param children {React.Node} - The children to apply formatting to
 * @returns {Array<Exclude<*, boolean|null|undefined>>} - An array of React components
 * @constructor
 */
const WhatsAppTextFormatter = ({ children }: Props) => applyToTextChildren(children, parseText);

export default WhatsAppTextFormatter;
