import Box from '@mui/material/Box';
import LauncherAppDrawer from 'components/@home/drawers/LauncherAppDrawer/LauncherAppDrawer';
import RulesDrawer from 'components/@home/drawers/RulesDrawer';
import TimeTrackingDrawer from 'components/@home/drawers/TimeTrackingDrawer';
import useWindowFocus from 'components/common/WindowFocusHandler/useWindowFocus';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import visibility from 'store/app/ui/focus/action';
import authCompany from 'store/selectors/authCompany';
import useActions from 'utils/useActions';
import useConnecting from 'utils/useConnecting';
import AcknowledgeCreateDrawer from './drawers/AcknowledgeCreateDrawer';
import AnalyticsDrawer from './drawers/AnalyticsDrawer';
import AppDrawer from './drawers/AppDrawer';
import ChannelDrawer from './drawers/ChannelDrawer';
import EmployeesDrawer from './drawers/DirectDrawer';
import EmployeeDrawer from './drawers/EmployeeDrawer';
import EmployeeListDrawer from './drawers/EmployeeListDrawer';
import FillFormDrawer from './drawers/FillFormDrawer';
import FormAnalyticsDrawer from './drawers/FormAnalyticsDrawer';
import FormDrawer from './drawers/FormDrawer';
import HrBotDrawer from './drawers/HrBotDrawer';
import MediaDrawer from './drawers/MediaDrawer';
import ProfileDrawer from './drawers/ProfileDrawer';
import RemoteDrawer from './drawers/RemoteDrawer';
import SettingsDrawer from './drawers/SettingsDrawer';
import TeamDrawer from './drawers/TeamDrawer';
import Header from './Header';
import AcknowledgementCreateModal from './modals/acknowledgment/CreateModal';
import AcknowledgementPreviewModal from './modals/acknowledgment/PreviewModal';
import AcknowledgementReadModal from './modals/acknowledgment/ReadModal';
import AcknowledgementSignatureModal from './modals/acknowledgment/SignatureModal';
import DocumentModal from './modals/DocumentModal';
import PictureModal from './modals/PictureModal';
import VideoModal from './modals/VideoModal';
import NotificationsHandler from './NotificationsHandler';
import TitleHandler from './TitleHandler';

const styles = {
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    backgroundColor: 'background.paper',
  },
};

const HomeLayout = () => {
  const setFocus = useActions(visibility.setFocus);
  useConnecting();
  useWindowFocus(setFocus);

  return (
    <Box sx={styles.root}>
      <TitleHandler />
      <NotificationsHandler sound="/sounds/bip.mp3" />
      <Header />
      <Outlet />

      <AcknowledgeCreateDrawer />
      <AnalyticsDrawer />
      <AppDrawer />
      <ChannelDrawer />
      <EmployeesDrawer />
      <EmployeeDrawer />
      <EmployeeListDrawer />
      <FillFormDrawer />
      <FormDrawer />
      <FormAnalyticsDrawer />
      <HrBotDrawer />
      <LauncherAppDrawer />
      <MediaDrawer />
      <ProfileDrawer />
      <RemoteDrawer />
      <RulesDrawer />
      <SettingsDrawer />
      <TeamDrawer />
      <TimeTrackingDrawer />

      <AcknowledgementCreateModal />
      <AcknowledgementSignatureModal />
      <AcknowledgementPreviewModal />
      <AcknowledgementReadModal />
      <DocumentModal />
      <PictureModal />
      <VideoModal />
    </Box>
  );
};

export default HomeLayout;
