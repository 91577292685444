import { Avatar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { bool, oneOf, shape, string } from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import companies from 'store/app/entities/companies/action';
import pictureModal from 'store/app/ui/modals/picture/action';
import isSuperAdminSelector from 'store/selectors/isSuperAdmin';
import initialsFromUsername from 'utils/initialsFromUsername';
import AvatarBadge from './AvatarBadge';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0 1px 5px 0 rgba(36, 202, 165, 0.2)',
  },
  large: {
    width: 44,
    height: 44,
  },
  xl: {
    width: 80,
    height: 80,
  },
  isAvatar: {
    backgroundColor: 'transparent',
    boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.2)',
  },
  pending: {
    backgroundColor: theme.palette.secondary.extraLight,
  },
}));

const EmployeeAvatar = ({ xl, large, status, employee, className }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isSuperAdmin = useSelector(isSuperAdminSelector);
  const openModal = useCallback(() => {
    if (large && employee.avatar) {
      dispatch(pictureModal.open(employee.avatar));
    }
    if (xl && employee.avatar) {
      dispatch(pictureModal.open(employee.avatar));
    }
  }, [dispatch, employee.avatar, large, xl]);
  const handleClick = useCallback(
    e => {
      if (isSuperAdmin) {
        e.stopPropagation();
        dispatch(companies.toggleAcceptTerms(status === 'pending', employee._id));
      }
    },
    [dispatch, employee._id, isSuperAdmin, status],
  );

  const initials = useMemo(() => initialsFromUsername(employee.name), [employee.name]);

  return (
    <AvatarBadge
      onClick={handleClick}
      enable={isSuperAdmin || ['pending', 'admin'].includes(status)}
      className={className}
      status={status}
    >
      <Avatar
        onClick={openModal}
        classes={{
          root: classNames({
            [classes.root]: true,
            [classes.large]: large,
            [classes.xl]: xl,
            [classes.isAvatar]: employee.avatar,
            [classes.pending]: status === 'pending',
          }),
        }}
        src={employee.avatar}
      >
        {employee.avatar ? null : initials}
      </Avatar>
    </AvatarBadge>
  );
};

EmployeeAvatar.propTypes = {
  className: string,
  status: oneOf(['pending', 'active', 'admin']),
  large: bool,
  xl: bool,
  employee: shape({ name: string, avatar: string }).isRequired,
};
EmployeeAvatar.defaultProps = {
  large: false,
  xl: false,
  className: undefined,
  status: 'active',
};
const isEqual = (prev, next) => {
  if (prev.xl !== next.xl) return false;
  if (prev.large !== next.large) return false;
  if (prev.status !== next.status) return false;
  if (prev.employee.name !== next.employee.name) return false;
  return prev.employee.avatar === next.employee.avatar;
};
export default memo(EmployeeAvatar, isEqual);
