import { renderToStaticMarkup } from 'react-dom/server';

const svgToStaticMarkup = svg =>
  renderToStaticMarkup(svg)
    .replace('<svg', "<svg xmlns='http://www.w3.org/2000/svg'")
    .replaceAll('\n', ' ')
    .replaceAll('<', '%3C')
    .replaceAll('>', '%3E')
    .replaceAll('#', '%23')
    .replaceAll('"', "'");

export default svgToStaticMarkup;
