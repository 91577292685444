import { ListItem, ListItemText, SvgIcon, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import LogIcon from 'assets/icons/file-log.svg';
import PDFIcon from 'assets/icons/file-pdf.svg';
import AccessTimeIcon from 'assets/icons/invite.svg';
import EmployeeAvatar from 'components/@home/EmployeeAvatar';
import CheckIcon from 'mdi-react/CheckIcon';
import moment from 'moment';
import { bool, func, object } from 'prop-types';
import React, { memo } from 'react';
import { I18n } from 'utils/i18n';
import channelShape from 'shapes/channel';
import employeeShape from 'shapes/employee';
import connector from './connector';
import SendButton from './SendButton';

const useStyles = makeStyles(theme => ({
  employee: {
    borderBottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  date: {
    textAlign: 'right',
    justifySelf: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderBottom: 0,
    width: 100,
  },
  avatar: {
    marginRight: 15,
  },
  confirmed: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  pending: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 75,
  },
  rightText: {
    width: 100,
    textAlign: 'right',
  },
  status: {
    paddingRight: 15,
  },

  primaryColor: {
    color: theme.palette.primary.main,
  },
  errorColor: {
    color: theme.palette.error.main,
    width: 18,
    height: 18,
  },
  employeeInfo: {
    fontSize: 14,
  },
  timestampPrimary: {
    fontSize: 15,
  },
}));

const AcknowledgeListItem = ({ employee, onSend, selected, actions, message, channel }) => {
  const classes = useStyles();
  const openModal = () => {
    actions.acknowledgmentReadModal.open(message, channel, employee);
  };

  const openModalLog = () => {
    actions.acknowledgmentReadModal.open(message, channel, employee, true);
  };

  return (
    <ListItem selected={selected}>
      <div className={classes.status}>
        {employee.confirmedAt ? (
          <CheckIcon className={classes.primaryColor} />
        ) : (
          <AccessTimeIcon className={classes.errorColor} />
        )}
      </div>
      <EmployeeAvatar
        className={classes.avatar}
        large
        employee={employee}
        status={employee.isAcceptTerms ? 'active' : 'pending'}
      />
      <ListItemText
        className={classes.employee}
        classes={{ secondary: classes.employeeInfo }}
        primary={
          <Typography component="span" color={employee.isAcceptTerms ? 'default' : 'secondary'}>
            {[employee.firstName, employee.surName].filter(v => v).join(' ')}
          </Typography>
        }
        secondary={`${employee.jobTitle}, ${employee.department}`}
      />

      {employee.confirmedAt ? (
        <div className={classes.confirmed}>
          {message.digitalSignature && (
            <Button title={I18n.t('AnalyticsDrawer.OpenPDF')} onClick={openModalLog}>
              <SvgIcon className={classes.fileIcon}>
                <LogIcon />
              </SvgIcon>
            </Button>
          )}
          <Button title={I18n.t('AnalyticsDrawer.OpenPDF')} onClick={openModal}>
            <SvgIcon className={classes.fileIcon}>
              <PDFIcon />
            </SvgIcon>
          </Button>
          <ListItemText
            className={classes.date}
            classes={{ primary: classes.timestampPrimary }}
            primary={moment.unix(employee.confirmedAt).format('L')}
            secondary={moment.unix(employee.confirmedAt).format('LTS')}
          />
        </div>
      ) : (
        <div className={classes.pending}>
          <SendButton onSend={onSend} />
          <Typography className={classes.rightText} color="secondary">
            {I18n.t('AnalyticsDrawer.Pending')}
          </Typography>
        </div>
      )}
    </ListItem>
  );
};

AcknowledgeListItem.propTypes = {
  actions: object.isRequired,
  channel: channelShape.isRequired,
  employee: employeeShape.isRequired,
  message: object.isRequired,
  onSend: func.isRequired,
  selected: bool.isRequired,
};

export default connector(memo(AcknowledgeListItem));
