/**
 * @flow
 */
import EmbeddableButton from 'components/@home/drawers/AppDrawer/Form/EmbeddableButton';
import CopyField from 'components/controls/CopyField';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

type Props = {
  app: Object,
};

const EmbeddableButtonCopy = ({ app }: Props) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    const virtualDiv = document.createElement('div');
    ReactDOM.render(<EmbeddableButton app={app} />, virtualDiv, () => {
      setValue(virtualDiv.innerHTML);
    });
  }, []);

  return <CopyField value={value} />;
};

export default EmbeddableButtonCopy;
