import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import fillFormDrawer from 'store/app/ui/drawers/fillForm/action';
import media from 'store/app/ui/media/action';
import authEmployee from 'store/selectors/authEmployee';
import currentChannel from 'store/selectors/currentChannel';

const mapStateToProps = state => ({
  forms: state.media.forms,
  total: state.media.forms_total,
  channel: currentChannel(state),
  authEmployee: authEmployee(state),
});

const mapDispatchToProps = dispatch => ({
  actions: {
    fillFormDrawer: bindActionCreators(fillFormDrawer, dispatch),
    media: bindActionCreators(media, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
