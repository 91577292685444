const toOptions = {
  teams: team => ({
    label: team.name + (team.description ? ` - ${team.description}` : ''),
    value: {
      _id: team._id,
      name: team.name + (team.description ? ` - ${team.description}` : ''),
    },
  }),
  data: employee => ({
    label: `${employee.firstName} ${employee.surName}`,
    value: {
      _id: employee._id,
      name: `${employee.firstName} ${employee.surName}`,
    },
  }),
};

const loadOptionsLazyQuery = async (search, loadedOptions, additional) => {
  const { perPage = 99, exclude = [], trigger, page = 1, ...rest } = additional || {};
  const result = await trigger({
    search,
    page,
    perPage,
    ...rest,
  }).unwrap();
  const kind = Object.keys(result).find(key => Object.keys(toOptions).includes(key)) || 'data';
  return {
    options: result[kind]
      .filter(team => exclude?.every(c => (c?._id || c?.value?._id) !== team._id))
      .map(toOptions[kind]),
    hasMore: (result.totalCount || result.metadata?.total || 0) > (page + 1) * perPage,
    additional: {
      page: page + 1,
      exclude,
      trigger,
      perPage,
      ...rest,
    },
  };
};

export default loadOptionsLazyQuery;
