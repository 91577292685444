import { Typography } from '@mui/material';
import React from 'react';
import { APP_DOMAIN } from 'services/constants';
import Cookie from 'services/Cookie';

const NotFoundScene = () => {
  const company = Cookie.get('company');

  const subdomain = company ? `${company.subDomain}.` : '';

  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography component="div">
        <p>Page not found</p>
        <a
          href={`//${subdomain}${APP_DOMAIN}/`}
          style={{
            textAlign: 'center',
            color: '#313ad4',
          }}
        >
          Go home
        </a>
      </Typography>
    </div>
  );
};

export default NotFoundScene;
