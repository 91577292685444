import React from 'react';
import { object } from 'prop-types';
import Scope from 'components/controls/Scope';

const FormikScope = ({ field, form, ...props }) => {
  const { name, value, onBlur } = field;
  const { errors, submitCount, setFieldValue } = form;
  const { error } = form.getFieldMeta(name);

  return (
    <Scope
      fullWidth
      {...props}
      onChange={e => setFieldValue(name, e.value)}
      onBlur={onBlur}
      value={value}
      name={name}
      error={!!(error && submitCount > 0)}
      helperText={submitCount > 0 && errors[name]}
    />
  );
};

FormikScope.propTypes = {
  field: object.isRequired,
  form: object.isRequired,
};

export default FormikScope;
