/* eslint-disable react-hooks/exhaustive-deps, no-underscore-dangle */

import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import isPassiveSupported from 'utils/isPassiveSupported';

const InfiniteScroll = ({
  children,
  element,
  hasBelow,
  hasAbove,
  initialLoad,
  isReverse,
  loader,
  loadMore: loadMoreProp,
  firstLoadedId,
  lastLoadedId,
  ref,
  threshold,
  useCapture,
  getScrollParent,
  isLoading: isLoadingProp,
  ...props
}) => {
  const [options, setOptions] = useState({});
  const [scrollComponent, setScrollComponent] = useState(null);
  const [isLoading, setLoading] = useState(isLoadingProp);
  const loadMore =
    typeof loadMoreProp === 'function'
      ? debounce(loadMoreProp, 1000, {
          leading: true,
          trailing: false,
        })
      : null;

  const eventListenerOptions = () => {
    let ret;

    if (isPassiveSupported()) {
      ret = {
        useCapture,
        passive: true,
      };
    } else {
      ret = {
        passive: false,
      };
    }
    return ret;
  };

  const getParentElement = el => {
    const scrollParent = getScrollParent && getScrollParent();
    if (scrollParent != null) {
      return scrollParent;
    }
    return el && el.parentNode;
  };

  const _mousewheelListener = e => {
    // Prevents Chrome hangups
    // See: https://stackoverflow.com/questions/47524205/random-high-content-download-time-in-chrome/47684257#47684257
    if (e.deltaY === 1 && !isPassiveSupported()) {
      e.preventDefault();
    }
  };

  const mousewheelListener = debounce(_mousewheelListener, 200);

  const _scrollListener = () => {
    const el = scrollComponent;
    const parentNode = getParentElement(el);

    let loadAbove;
    let loadBelow;
    if (isReverse) {
      loadAbove =
        hasAbove &&
        el.scrollHeight - parentNode.scrollTop - parentNode.clientHeight < Number(threshold);
      loadBelow = hasBelow && parentNode.scrollTop < Number(threshold);
    } else {
      loadAbove = hasAbove && parentNode.scrollTop < Number(threshold);
      loadBelow =
        hasBelow &&
        el.scrollHeight - parentNode.scrollTop - parentNode.clientHeight < Number(threshold);
    }

    const load = loadAbove || loadBelow;

    // Here we make sure the element is visible as well as checking the offset
    if (!isLoading && load && el && el.offsetParent !== null) {
      // eslint-disable-next-line no-use-before-define
      detachScrollListener();
      // Call loadMore after detachScrollListener to allow for non-async loadMore functions
      if (load && typeof loadMore === 'function') {
        setLoading({ loadAbove, loadBelow });
        loadMore(loadAbove ? lastLoadedId : firstLoadedId, { loadAbove, loadBelow });
      }
    }
  };

  const scrollListener = debounce(_scrollListener, 200);

  const detachMousewheelListener = () => {
    const scrollEl = scrollComponent?.parentNode;
    if (scrollComponent?.parentNode) {
      scrollEl.removeEventListener('mousewheel', mousewheelListener, options || useCapture);
    }
  };

  const detachScrollListener = () => {
    const scrollEl = scrollComponent?.parentNode;
    if (scrollEl) {
      scrollEl.removeEventListener('scroll', scrollListener, options || useCapture);
      scrollEl.removeEventListener('resize', scrollListener, options || useCapture);
    }
  };

  const attachScrollListener = () => {
    const scrollEl = getParentElement(scrollComponent);

    if ((!hasBelow && !hasAbove) || !scrollEl) {
      return;
    }

    scrollEl.addEventListener('mousewheel', mousewheelListener, options || useCapture);
    scrollEl.addEventListener('scroll', scrollListener, options || useCapture);
    scrollEl.addEventListener('resize', scrollListener, options || useCapture);

    if (initialLoad) {
      scrollListener();
    }
  };

  useEffect(() => {
    setOptions(eventListenerOptions());
    attachScrollListener();
    return () => {
      detachScrollListener();
      detachMousewheelListener();
    };
  }, [scrollComponent]);

  useEffect(() => {
    if (!isLoading) {
      attachScrollListener();
    }
  }, [isLoading]);

  useEffect(() => {
    setLoading(isLoadingProp);
  }, [isLoadingProp]);

  const newProps = {
    ...props,
    ref: node => {
      setScrollComponent(node);
      if (ref) {
        ref(node);
      }
    },
  };

  const childrenArray = [children];
  if (isLoading && loader) {
    let below = isLoading.loadBelow;
    if (isReverse) {
      below = !below;
    }
    childrenArray[below ? 'push' : 'unshift'](loader);
  }
  return React.createElement(element, newProps, childrenArray);
};

InfiniteScroll.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.node.isRequired,
  element: PropTypes.node,
  hasBelow: PropTypes.bool,
  hasAbove: PropTypes.bool,
  initialLoad: PropTypes.bool,
  isReverse: PropTypes.bool,
  loader: PropTypes.node,
  loadMore: PropTypes.func.isRequired,
  firstLoadedId: PropTypes.string,
  lastLoadedId: PropTypes.string,
  ref: PropTypes.func,
  getScrollParent: PropTypes.func,
  threshold: PropTypes.number,
  useCapture: PropTypes.bool,
};

InfiniteScroll.defaultProps = {
  isLoading: false,
  element: 'div',
  hasBelow: false,
  hasAbove: false,
  initialLoad: true,
  firstLoadedId: null,
  lastLoadedId: null,
  ref: null,
  threshold: 250,
  isReverse: false,
  useCapture: false,
  loader: null,
  getScrollParent: null,
};

export default InfiniteScroll;
