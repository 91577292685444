import React from 'react';
import { object, shape, bool } from 'prop-types';
import Modal from '../../Modal';
import Form from './Form';
import connector from './connector';

const PreviewModal = ({ isOpen, actions }) => (
<Modal
    isOpen={isOpen}
    onClose={actions.acknowledgementPreviewModal.close}
  >
    <Form onClose={actions.acknowledgementPreviewModal.close} />
  </Modal>
);PreviewModal.propTypes = {
  isOpen: bool.isRequired,
  actions: shape({
    acknowledgementPreviewModal: object.isRequired,
  }).isRequired,
};

export default connector(PreviewModal);
