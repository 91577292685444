import React from 'react';
import { object } from 'prop-types';
import TagsField from 'components/controls/TagsField';

const FormikTagsField = ({ field, form, ...props }) => {
  const { name, onBlur } = field;
  const { errors, values, submitCount, setFieldValue } = form;

  return (
    <TagsField
      fullWidth
      {...props}
      onChange={setFieldValue}
      onBlur={onBlur}
      value={values[name]}
      name={name}
      error={!!(errors[name] && submitCount > 0)}
      helperText={submitCount > 0 && errors[name]}
    />
  );
};

FormikTagsField.propTypes = {
  field: object.isRequired,
  form: object.isRequired,
};

export default FormikTagsField;
