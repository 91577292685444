/* eslint-disable jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */
import React from 'react';
import { object, func, bool, string, node } from 'prop-types';
import { Typography, SvgIcon } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';

const styles = theme => ({
  root: {
    minWidth: 335,
    borderRadius: 6,
    display: 'flex',
    alignItem: 'center',
    cursor: 'pointer',
    padding: 10,
    border: `1px solid ${theme.palette.secondary.extraLight}`,
    [theme.breakpoints.down('md')]: {
      margin: '10px auto',
      minWidth: 'auto',
      width: '100%',
    },
  },
  selected: {
    border: `1.5px solid ${theme.palette.primary.main}`,
  },
  iconContainer: {
    paddingTop: 10,
    paddingRight: 10,
  },
  input: {
    position: 'absolute',
    opacity: 0,
  },
  primaryText: {
    fontWeight: 600,
  },
});

const TypeRadio = ({ classes, icon, primary, secondary, selected, onChange, name, value }) => (
  <label className={classNames({ [classes.root]: true, [classes.selected]: selected })}>
    <input
      className={classes.input}
      name={name}
      value={value}
      onChange={onChange}
      type="radio"
      checked={selected}
    />
    <div className={classes.iconContainer}>
      <SvgIcon color={selected ? 'primary' : 'secondary'}>{icon}</SvgIcon>
    </div>
    <div>
      <Typography className={classes.primaryText} variant="subtitle1">
        {primary}
      </Typography>
      <Typography color="secondary" variant="subtitle1">
        {secondary}
      </Typography>
    </div>
  </label>
);

TypeRadio.propTypes = {
  selected: bool.isRequired,
  classes: object.isRequired,
  name: string.isRequired,
  value: string.isRequired,
  icon: node.isRequired,
  primary: string.isRequired,
  secondary: string.isRequired,
  onChange: func.isRequired,
};

export default withStyles(styles)(TypeRadio);
