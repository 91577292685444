import { CLOSE_APP_DRAWER, OPEN_APP_DRAWER } from './action';

const initialState = {
  isOpen: false,
  app: {},
};

const appDrawer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_APP_DRAWER:
      return {
        ...state,
        isOpen: true,
        app: action.payload || {},
      };

    case CLOSE_APP_DRAWER:
      return { ...state, isOpen: false };

    default: {
      return {
        ...state,
      };
    }
  }
};

export default appDrawer;
