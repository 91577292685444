import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from '@mui/material';
import { bool, func, object } from 'prop-types';
import React, { useState } from 'react';
import { I18n } from 'utils/i18n';

const FolderDialog = ({ folder, open, onClose, onSubmit }) => {
  const [name, setName] = useState(folder?.name || '');
  const onChange = e => {
    setName(e.target.value);
  };
  const handleClose = () => {
    setName('');
    onClose();
  };
  const handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(name);
    handleClose();
  };
  return (
    <Dialog open={open} onClose={handleClose} onClick={e => e.stopPropagation()}>
      <DialogTitle>{I18n.t(`FolderDialog.${folder ? 'Edit' : 'Create'} a folder`)}</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent sx={{ minWidth: 500 }}>
          <FormControl style={{ padding: 24 }} fullWidth>
            {open && (
              <TextField
                autoFocus
                fullWidth
                onChange={onChange}
                value={name}
                placeholder={I18n.t('FolderDialog.Enter the folder name')}
              />
            )}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            color="secondary"
            sx={{ color: 'primary.ultraDark' }}
          >
            {I18n.t('FolderDialog.Cancel')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={folder?.name && folder.name === name}
          >
            {I18n.t(`FolderDialog.${folder ? 'Edit' : 'Create'}`)}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

FolderDialog.propTypes = {
  folder: object,
  onSubmit: func.isRequired,
  open: bool.isRequired,
  onClose: func.isRequired,
};

FolderDialog.defaultProps = {
  folder: null,
};
export default FolderDialog;
