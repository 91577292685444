/**
 * @flow
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { statuses } from 'components/@whistleblower-admin/drawers/OccurrenceDrawer/constants';
import { useOccurrence } from 'components/@whistleblower-admin/drawers/OccurrenceDrawer/OccurrenceContext';
import React, { useState } from 'react';
import { I18n } from 'utils/i18n';

const ConfirmMessageDialog = ({
  isOpen,
  close,
  onSubmit,
}: {
  isOpen: boolean;
  close: Function;
  onSubmit: Function;
}) => {
  const occurrence = useOccurrence();
  const [suggestChangeStatus, setSuggestChangeStatus] = useState(
    !occurrence.latestUserResponseAt && occurrence.status === statuses.NEW,
  );
  const [status, setStatus] = useState(statuses.RECEIVED);
  const handleChangeStatus = e => {
    setStatus(e.target.value);
  };
  const handleSubmit = async () => {
    setSuggestChangeStatus(false);
    onSubmit(suggestChangeStatus ? status : null);
  };
  return (
    <Dialog
      open={isOpen}
      onClose={close}
      aria-labelledby="change-state-title"
      aria-describedby="change-state-description"
    >
      <DialogTitle id="change-state-title">{I18n.t('ConfirmMessageDialog.title')}</DialogTitle>
      <DialogContent>
        <Typography gutterBottom={suggestChangeStatus}>
          {I18n.t('ConfirmMessageDialog.description')}
        </Typography>
        {suggestChangeStatus && (
          <>
            <Typography gutterBottom>{I18n.t('ConfirmMessageDialog.changeStatus')}</Typography>
            <Select
              value={status}
              onChange={handleChangeStatus}
              inputProps={{
                sx: {
                  display: 'flex',
                  alignItems: 'center',
                  '& > div': {
                    minWidth: 36,
                  },
                },
              }}
              fullWidth
            >
              {Object.values(statuses).map(option => {
                const [value, label] = [option, I18n.t(`OccurrenceDrawer.status.${option}`)];
                return (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                );
              })}
            </Select>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={close}
          variant="outlined"
          color="secondary"
          sx={{ color: 'primary.ultraDark' }}
        >
          {I18n.t('ConfirmMessageDialog.cancel')}
        </Button>
        <Button onClick={handleSubmit} type="button" variant="contained" color="primary">
          {I18n.t('ConfirmMessageDialog.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmMessageDialog;
