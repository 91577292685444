/**
 * @flow
 */
import { Box, Button, Typography } from '@mui/material';
import AudioAttachment from 'components/@whistleblower-admin/drawers/OccurrenceDrawer/AudioAttachment';
import MessageIcon from 'components/common/MessageIcon';
import React from 'react';
import { I18n } from 'utils/i18n';
import downloadAttachment from 'utils/downloadAttachment';
import isAudio from 'utils/isAudio';

const AttachmentsField = ({ attachments, aesKey }: { attachments: Array; aesKey: String }) => {
  if (!attachments?.length) {
    return null;
  }
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      {attachments?.map(attachment => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            border: 1,
            borderColor: 'divider',
            borderRadius: 0.75,
          }}
          key={attachment._id}
        >
          {isAudio(attachment) ? (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <AudioAttachment attachment={attachment} aesKey={aesKey} />
          ) : (
            <Box
              sx={{
                display: 'flex',
                pl: 2,
                alignItems: 'center',
                gap: 1,
              }}
            >
              <MessageIcon message={{ attachments: [attachment] }} />
              <Typography fontSize="sm">{attachment.name}</Typography>
            </Box>
          )}
          <Box>
            <Button variant="text" color="primary" onClick={downloadAttachment(attachment, aesKey)}>
              {I18n.t('OccurrenceDrawer.download')}
            </Button>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default AttachmentsField;
