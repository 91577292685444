/* eslint-disable react/no-did-update-set-state */
import { Drawer } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Loading from 'components/common/Loading';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import employeesAction from 'store/app/entities/employees/action';
import profileDrawer from 'store/app/ui/drawers/profile/action';
import populateEmployees from 'store/selectors/populateEmployees';
import useActions from 'utils/useActions';
import CloseButton from '../CloseButton';
import Profile from './Profile';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      width: 1000,
    },
  },
  container: {
    display: 'flex',
  },
  closeButtonContainer: {
    paddingTop: 20,
    paddingLeft: 20,
  },
  loading: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}));

const ProfileDrawer = () => {
  const isOpen = useSelector(state => state.drawers.profile.isOpen);
  const { employee_id: employeeId } = useSelector(state => state.drawers.profile);
  const handleClose = useActions(profileDrawer.close);
  const classes = useStyles();
  const loadEmployee = useActions(employeesAction.load);
  const employees = useSelector(populateEmployees);
  const employee = useMemo(() => employees[employeeId], [employees, employeeId]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!isOpen) {
      setIsLoading(false);
    }
  }, [isOpen]);
  useEffect(() => {
    if (!isLoading && !employee?.settings && employeeId && isOpen) {
      setIsLoading(true);
      loadEmployee(employeeId);
    }
  }, [employee, employeeId, isLoading, isOpen, loadEmployee]);
  if (!isOpen) return null;
  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose}>
      <div className={classes.root}>
        <div className={classes.closeButtonContainer}>
          <CloseButton onClick={handleClose} />
        </div>
        {employee ? <Profile employee={employee} /> : <Loading />}
      </div>
    </Drawer>
  );
};

export default memo(ProfileDrawer);
