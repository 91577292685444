import removeAccents from 'utils/removeAccents';

export default (searchString, files) =>
  searchString
    ? files.filter(f =>
        removeAccents(searchString)
          .toLowerCase()
          .split(/[^a-z0-9]+/)
          .reduce(
            (ret, str) =>
              ret &&
              removeAccents(
                `${f.employee?.name} ${f.code} ${f.file.name} ${f.newFileName} ${f.dni}}`,
              )
                .toLowerCase()
                .includes(removeAccents(str)),
            true,
          ),
      )
    : files;
