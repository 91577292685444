export const OPEN_ACKNOWLEDGMENT_PREVIEW_MODAL = 'OPEN_ACKNOWLEDGMENT_PREVIEW_MODAL';
export const CLOSE_ACKNOWLEDGMENT_PREVIEW_MODAL = 'CLOSE_ACKNOWLEDGMENT_PREVIEW_MODAL';

const open = message => ({
  type: OPEN_ACKNOWLEDGMENT_PREVIEW_MODAL,
  payload: message,
});

const close = () => ({
  type: CLOSE_ACKNOWLEDGMENT_PREVIEW_MODAL,
});

export default { open, close };
