import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import timeZones from 'store/app/entities/config/timeZones/action';

const mapStateToProps = state => ({
  timeZones: state.config.timeZones,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    config: {
      timeZones: bindActionCreators(timeZones, dispatch),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
