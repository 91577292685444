import { bool, object } from 'prop-types';
import React from 'react';
import { Navigate } from 'react-router-dom';
import connector from './connector';

const TeamAdminGate = ({ isTeamAdmin, component: Component }) => {
  if (isTeamAdmin) {
    return <Component />;
  }

  return <Navigate replace to="/home/messages" />;
};

TeamAdminGate.propTypes = {
  isTeamAdmin: bool.isRequired,
  component: object.isRequired,
};

export default connector(TeamAdminGate);
