import ChatFlow from 'services/api/ChatFlow';

export const FIND_CHAT_FLOW = 'FIND_CHAT_FLOW';
export const FIND_CHAT_FLOW_PENDING = 'FIND_CHAT_FLOW_PENDING';
export const FIND_CHAT_FLOW_FULFILLED = 'FIND_CHAT_FLOW_FULFILLED';

export const LOAD_CHAT_FLOWS = 'LOAD_CHAT_FLOWS';
export const LOAD_CHAT_FLOWS_PENDING = 'LOAD_CHAT_FLOWS_PENDING';
export const LOAD_CHAT_FLOWS_FULFILLED = 'LOAD_CHAT_FLOWS_FULFILLED';

export const CREATE_CHAT_FLOW = 'CREATE_CHAT_FLOW';
export const CREATE_CHAT_FLOW_PENDING = 'CREATE_CHAT_FLOW_PENDING';
export const CREATE_CHAT_FLOW_FULFILLED = 'CREATE_CHAT_FLOW_FULFILLED';

export const UPDATE_CHAT_FLOW = 'UPDATE_CHAT_FLOW';
export const UPDATE_CHAT_FLOW_PENDING = 'UPDATE_CHAT_FLOW_PENDING';
export const UPDATE_CHAT_FLOW_FULFILLED = 'UPDATE_CHAT_FLOW_FULFILLED';

export const DESTROY_CHAT_FLOW = 'DESTROY_CHAT_FLOW';
export const DESTROY_CHAT_FLOW_PENDING = 'DESTROY_CHAT_FLOW_PENDING';
export const DESTROY_CHAT_FLOW_FULFILLED = 'DESTROY_CHAT_FLOW_FULFILLED';

export const MESSAGE_CHAT_FLOW = 'MESSAGE_CHAT_FLOW';
export const MESSAGE_CHAT_FLOW_PENDING = 'MESSAGE_CHAT_FLOW_PENDING';
export const MESSAGE_CHAT_FLOW_FULFILLED = 'MESSAGE_CHAT_FLOW_FULFILLED';

export const EXPORT_CHAT_FLOW = 'EXPORT_CHAT_FLOW';
export const EXPORT_CHAT_FLOW_PENDING = 'EXPORT_CHAT_FLOW_PENDING';
export const EXPORT_CHAT_FLOW_FULFILLED = 'EXPORT_CHAT_FLOW_FULFILLED';

export const IMPORT_CHAT_FLOW = 'IMPORT_CHAT_FLOW';
export const IMPORT_CHAT_FLOW_PENDING = 'IMPORT_CHAT_FLOW_PENDING';
export const IMPORT_CHAT_FLOW_FULFILLED = 'IMPORT_CHAT_FLOW_FULFILLED';

export const TRAIN_CHAT_FLOW = 'TRAIN_CHAT_FLOW';
export const TRAIN_CHAT_FLOW_PENDING = 'TRAIN_CHAT_FLOW_PENDING';
export const TRAIN_CHAT_FLOW_FULFILLED = 'TRAIN_CHAT_FLOW_FULFILLED';

export const CHAT_FLOW_CONVERSATION_RESTART = 'CHAT_FLOW_CONVERSATION_RESTART';
export const CHAT_FLOW_CONVERSATION_DELETE_PENDING = 'CHAT_FLOW_CONVERSATION_DELETE_PENDING';
export const CHAT_FLOW_CONVERSATION_DELETE_FULFILLED = 'CHAT_FLOW_CONVERSATION_DELETE_FULFILLED';

const load = () => ({
  type: LOAD_CHAT_FLOWS,
  payload: ChatFlow.list(),
});

const create = form => ({
  type: CREATE_CHAT_FLOW,
  payload: ChatFlow.create(form),
});

const update = (id, form) => ({
  type: UPDATE_CHAT_FLOW,
  payload: ChatFlow.update(id, form),
});

const find = id => ({
  type: FIND_CHAT_FLOW,
  payload: ChatFlow.find(id),
});

const destroy = id => ({
  type: DESTROY_CHAT_FLOW,
  payload: ChatFlow.destroy(id),
  meta: { chatFlowId: id },
});

const message = (id, text, pageId, context) => ({
  type: MESSAGE_CHAT_FLOW,
  payload: ChatFlow.message(id, text, pageId, context),
  meta: { chatFlowId: id },
});

const exportFlow = id => ({
  type: EXPORT_CHAT_FLOW,
  payload: ChatFlow.exportFlow(id),
  meta: { chatFlowId: id },
});

const importFlow = flow => ({
  type: IMPORT_CHAT_FLOW,
  payload: ChatFlow.importFlow(flow),
});

const train = id => ({
  type: TRAIN_CHAT_FLOW,
  payload: ChatFlow.train(id),
  meta: { chatFlowId: id },
});

const conversationRestart = id => ({
  type: CHAT_FLOW_CONVERSATION_RESTART,
  payload: ChatFlow.conversationRestart(id),
  meta: { conversationId: id },
});

export default {
  create,
  conversationRestart,
  destroy,
  exportFlow,
  importFlow,
  find,
  load,
  message,
  train,
  update,
};
