import { useState } from 'react';

const useArray = (initial = []) => {
  const [arr, setArr] = useState(initial);

  const push = val => setArr(a => [...a, val]);

  const pop = () => {
    let ret;
    setArr(a => {
      if (a.length > 0) {
        ret = a[a.length - 1];
        return a.slice(0, -1);
      }
      return a;
    });
    return ret;
  };

  const reset = () => setArr(initial);

  return [arr, arr[arr.length - 1], push, pop, reset, setArr];
};

export default useArray;
