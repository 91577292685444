import { ImageListItem } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Pagination from 'components/controls/Pagination';
import isEmpty from 'lodash/isEmpty';
import { object, number, arrayOf } from 'prop-types';
import attachmentShape from 'shapes/attachment';
import React, { Component } from 'react';
import MediaGrid from './MediaGrid';
import connector from './connector';
import VideoTile from './tile/VideoTile';
import PictureTile from './tile/PictureTile';

const MEDIA_PER_PAGE = 30;

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  list: {
    width: '100%',
    paddingTop: '20px',
  },
});

class Documents extends Component {
  state = {
    page: 0,
  };

  componentDidMount() {
    const { actions } = this.props;
    const { page } = this.state;

    actions.media.media(page + 1, MEDIA_PER_PAGE);
  }

  changePage = ({ selected: page }) => {
    const { actions } = this.props;
    this.setState({ page });

    actions.media.media(page + 1, MEDIA_PER_PAGE);
  };

  render() {
    const { classes, media, total } = this.props;
    const { page } = this.state;

    if (isEmpty(media)) return null;

    return (
      <div className={classes.root}>
        <MediaGrid>
          {media.map(m => (
            <ImageListItem key={m._id}>
              {m.mimeType.includes('video') ? <VideoTile video={m} /> : <PictureTile picture={m} />}
            </ImageListItem>
          ))}
        </MediaGrid>
        <Pagination perPage={MEDIA_PER_PAGE} total={total} page={page} onChange={this.changePage} />
      </div>
    );
  }
}

Documents.propTypes = {
  classes: object.isRequired,
  media: arrayOf(attachmentShape).isRequired,
  total: number.isRequired,
  actions: object.isRequired,
};
export default withStyles(styles)(connector(Documents));
