import { object } from 'prop-types';
import React, { memo } from 'react';
import EmployeesTable from '../../controls/EmployeesTable';

const FormikEmployeesTable = ({ field, form, ...props }) => {
  const { name } = field;
  const { values, errors, setFieldValue, submitCount } = form;

  const handleChange = (fieldIn, valueIn, shouldValidateIn = false) =>
    setFieldValue(fieldIn, valueIn, shouldValidateIn);

  return (
    <EmployeesTable
      {...props}
      name={name}
      error={submitCount > 0 ? errors[name] : ''}
      value={values[name]}
      onChange={handleChange}
    />
  );
};

FormikEmployeesTable.propTypes = {
  field: object.isRequired,
  form: object.isRequired,
};

const areEqual = ({ form: { submitCount, values, errors }, field: { name } }, props2) =>
  submitCount === props2.form.submitCount &&
  values[name] === props2.form.values[name] &&
  errors[name] === props2.form.errors[name];

export default memo(FormikEmployeesTable, areEqual);
