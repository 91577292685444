const ommnio = {
  primary: {
    '2': '#0a0b10',
    '5': '#151626',
    '10': '#2d2f4f',
    '20': '#393c65',
    '30': '#45487c',
    '40': '#5154a0',
    '50': '#6269e5',
    '60': '#7d84ff',
    '70': '#9ea3ff',
    '80': '#bfc2ff',
    '90': '#e0e0ff',
    '95': '#f1efff',
    '98': '#fbf8ff',
    '99': '#fffbff',
  },
  secondary: {
    '2': '#05080a',
    '5': '#0e1114',
    '10': '#191c1f',
    '20': '#2e3134',
    '30': '#44474a',
    '40': '#5c5e62',
    '50': '#75777b',
    '60': '#8f9194',
    '70': '#a9abaf',
    '80': '#c5c6ca',
    '90': '#e1e2e6',
    '95': '#eff1f5',
    '98': '#f8f9fd',
    '99': '#fcfcff',
  },
  tertiary: {
    '2': '#000a07',
    '5': '#00140f',
    '10': '#00201a',
    '20': '#00382d',
    '30': '#005143',
    '40': '#006b59',
    '50': '#008771',
    '60': '#00a389',
    '70': '#00c1a2',
    '80': '#35debd',
    '90': '#5dfbd9',
    '95': '#b8ffea',
    '98': '#e6fff6',
    '99': '#f3fff9',
  },
  neutral: {
    '2': '#07070a',
    '5': '#111114',
    '10': '#1b1b1f',
    '20': '#303034',
    '30': '#47464a',
    '40': '#5f5e62',
    '50': '#78767a',
    '60': '#929094',
    '70': '#adaaaf',
    '80': '#c8c5ca',
    '90': '#e5e1e6',
    '95': '#f3eff4',
    '98': '#fcf8fd',
    '99': '#fffbff',
  },
  neutralVariant: {
    '2': '#07070e',
    '5': '#101018',
    '10': '#1b1b23',
    '20': '#303038',
    '30': '#46464f',
    '40': '#5e5d67',
    '50': '#777680',
    '60': '#918f9a',
    '70': '#acaab4',
    '80': '#c7c5d0',
    '90': '#e4e1ec',
    '95': '#f2effa',
    '98': '#fbf8ff',
    '99': '#fffbff',
  },
  error: {
    '2': '#1a0000',
    '5': '#2d0001',
    '10': '#410002',
    '20': '#690005',
    '30': '#93000a',
    '40': '#ba1a1a',
    '50': '#de3730',
    '60': '#ff5449',
    '70': '#ff897d',
    '80': '#ffb4ab',
    '90': '#ffdad6',
    '95': '#ffedea',
    '98': '#fff8f7',
    '99': '#fffbff',
  },
  analogous: ['#62aae5', '#6269e5', '#9d62e5'],
  tetradic: ['#48ca82', '#cac348', '#484fca', '#ca4890'],
  icons: ['#31e17f', '#e1c931', '#484fca', '#e15331', '#ac31e1'],
};
export default ommnio;
