import debounce from 'lodash/debounce';
import Socket from 'services/Socket';
import getMoment from 'utils/getMoment';
// Fire immediately and every 2s while typing
const debounceTypingActive = debounce(
  (actions, channel, user, status) => {
    actions.users.typing(channel, user, status);
  },
  2000,
  { leading: true, maxWait: 2000, trailing: false },
);

// Fire after 2,5s of inactivity
const debounceTypingInactive = debounce(
  (actions, channel, user, status) => {
    actions.users.typing(channel, user, status);
  },
  2500,
  { leading: false, trailing: true },
);

const initializeSockets = ({ actions, channels, authEmployee, authCompany } = {}) => {
  if (Socket.isConnected()) {
    return Socket;
  }

  const fixOneToOneChannelId = rawMessage => {
    if (rawMessage.channel === authEmployee?.user?._id) {
      return rawMessage.from._id;
    }
    return rawMessage.channel;
  };
  Socket.connect();

  Socket.on('connect', () => {
    actions.visibility.onConnect();
  });

  Socket.on('newMessage', ({ message: rawMessage }) => {
    const fixedRawMessage = {
      ...rawMessage,
      channel: fixOneToOneChannelId(rawMessage),
      createdAt: getMoment(rawMessage.createdAt).toISOString(),
      updatedAt: getMoment(rawMessage.updatedAt).toISOString(),
    };
    // If we receive a message in the current channel open, we should mark it as read
    // only if the document is visible (hence `maybe` it will be mark as read)
    actions.messages.addAndMaybeRead(fixedRawMessage);
  });

  Socket.on('bulkMessagingSent', ({ bulkMessaging, lastSent }) => {
    actions.bulkMessagings.add({
      ...bulkMessaging,
      lastSent,
    });
  });

  Socket.on('bulkMessagingError', ({ bulkMessaging, lastSent }) => {
    actions.bulkMessagings.add({
      ...bulkMessaging,
      lastSent,
    });
  });

  Socket.on('userOnline', ({ user }) => {
    actions.users.set(user);
  });

  Socket.on('userOffline', ({ user }) => {
    actions.users.set(user);
  });

  Socket.on('typing', ({ channel, status, user }) => {
    if (status) {
      debounceTypingActive(actions, channel, user, true);
      debounceTypingInactive(actions, channel, user, false);
    } else {
      actions.users.typing(channel, user, false);
    }
  });

  Socket.on('newEmployee', ({ employee }) => {
    actions.employees.add(employee._id, employee);
  });

  Socket.on('employeeUpdated', async ({ employee }) => {
    actions.employees.modify(employee._id, employee);
  });

  Socket.on(['newCompany', 'employeeUnblocked', 'companyUnblocked'], ({ company }) => {
    actions.companies.set(company);
  });

  Socket.on(['companyBlocked'], async data => {
    actions.companies.remove(data.company._id);

    if (authCompany._id === data.company._id) {
      actions.auth.logout();
    }
  });

  Socket.on(['userBlocked', 'userDeleted'], data => {
    if (authEmployee.user_id === data.user._id) {
      actions.auth.logout();
    }
  });

  Socket.on(['employeeDeleted', 'employeeBlocked'], async data => {
    if (data.employee.user._id === authEmployee.user_id) {
      actions.companies.remove(data.company._id);
    }

    if (authEmployee._id === data.employee._id) {
      actions.auth.logout();
    }
  });

  Socket.on('companyDeleted', async data => {
    actions.companies.remove(data.company._id);
    if (authCompany._id === data.company._id) {
      actions.auth.logout();
    }
  });

  Socket.on('companyUpdated', data => {
    if (authCompany._id === data.company) {
      actions.auth.user();
    }
  });

  Socket.on(['newChannel', 'channelUpdated'], ({ channel }) => {
    if (channel._id !== authEmployee.user._id) {
      actions.channels.set({
        ...channel,
        isMember: Object.prototype.hasOwnProperty.call(channel, 'isMember')
          ? channel.isMember
          : true,
      });
    }
  });

  Socket.on(['channelClosed'], ({ channel }) => {
    if (channel._id !== authEmployee.user._id) {
      actions.channels.set({ ...channel, isMember: false });
    }
  });

  Socket.on('joinChannel', ({ user, channel, status }) => {
    const channelId = channels[channel]?._id || user?._id;

    // eslint-disable-next-line camelcase
    if (status && authEmployee?.user_id !== user?._id) {
      actions.channels.watch(channelId);
      actions.messages.markAsRead(channelId);
    }
  });

  Socket.on('deleteMessage', ({ message }) => {
    actions.messages.destroyFulfilled(message);
  });

  Socket.on('readMessage', payload => {
    actions.messages.onReadMessage(payload);
  });

  Socket.on('checkSession', () => {
    actions.auth.verify();
  });

  Socket.on('importFinished', payload => {
    actions.employeeImports.finished(payload);
  });

  Socket.on('importDryRunFinished', payload => {
    actions.employees.importDryRunFinished(payload);
  });

  return Socket;
};

export default initializeSockets;
