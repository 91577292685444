/**
 * @flow
 */
import React from 'react';
import baseUrl from 'utils/baseUrl';

type Props = {
  app: Object,
};

export const getShareableURL = (app: Object) => `${baseUrl}/oauth/authorize?client_id=${app._id}`;

const EmbeddableButton = ({ app }: Props) => (
  <a href={getShareableURL(app)}>
    <img
      alt="Add to Ommnio"
      height="40"
      width="160"
      src={`${baseUrl}/add_to_ommnio.png`}
      srcSet={`${baseUrl}/add_to_ommnio.png 1x, ${baseUrl}/add_to_ommnio@2x.png 2x`}
    />
  </a>
);

export default EmbeddableButton;
