import { SHOW_DIALOG, HIDE_DIALOG } from './action';

const initialState = {
  isVisible: false,
  options: null,
};

const dialogReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_DIALOG:
      return {
        ...state,
        isVisible: true,
        options: payload,
      };

    case HIDE_DIALOG:
      return initialState;

    default:
      return state;
  }
};

export default dialogReducer;
