import { Button, SvgIcon } from '@mui/material';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import DownloadIcon from 'assets/icons/download_icon.svg';
import Next from 'assets/icons/next.svg';
import QrCodeIcon from 'assets/icons/qrCode_icon.svg';
import AppleDownload from 'assets/images/apple_download.svg';
import GoogleDownload from 'assets/images/google_download.svg';
import logo from 'assets/logos/logo';
import classNames from 'classnames';
import android from 'is-android';
import iOS from 'is-ios';
import React, { useEffect, useMemo } from 'react';
import { I18n, Translate } from 'utils/i18n';
import { Link, useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: 500,
    margin: '0 auto',
    minHeight: '100vh',
    padding: '20px 20px',
    textAlign: 'center',
  },

  Logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 67,
  },
  LogoImg: {
    display: 'block',
    height: 52,
    filter: theme.palette.mode === 'dark' ? 'invert(1) hue-rotate(180deg)' : 'invert(0)',
  },
  RedirectHeading: {
    fontSize: 28,
    fontWeight: 'bold',
    color: theme.palette.primary.ultraDark,
    letterSpacing: -0.7,
    marginTop: 0,
    marginBottom: 21,
  },
  AppBtns: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  AppBtnsLink: {
    display: 'block',
  },
  AppBtnsLinkIcon: {
    display: 'block',
    height: 42,
    width: '100%',
  },
  NextBlock: {},
  NextBlockText: {
    fontSize: 20,
    margin: 0,
    marginBottom: 14,
    fontWeight: 'bold',
    lineHeight: 1.3,
    letterSpacing: -0.5,
    color: theme.palette.primary.ultraDark,
  },
  NextBlockBtns: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  NextBlockBtnsItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 160,
    height: 160,
    padding: '38px 20px 0',
    backgroundColor: theme.palette.secondary.ultraLight,
    border: '1px solid transparent',
    borderRadius: 8,
  },
  NextBlockBtnsItemIcon: {
    display: 'block',
    width: 32,
    height: 32,
    marginBottom: 14,
  },
  NextBlockBtnsItemText: {
    fontSize: 15,
    lineHeight: 1.47,
    letterSpacing: -0.5,
    textAlign: 'center',
    color: theme.palette.black,
    margin: 0,
  },
  picture: {
    margin: '0 auto',
    textAlign: 'center',
  },
  title: {
    margin: '1em 0 0.5em 0',
  },
  description: {
    color: '#8b98ab',
    fontWeight: 'normal',
    marginBottom: '2em',
  },
  icon: {
    width: 16,
    height: 16,
  },
  link: {
    placeSelf: 'center',
  },
  arrowButton: {
    '& :first-child': {
      marginRight: '0.25em',
    },
  },
  enterButton: {
    padding: '0 3em',
    marginBottom: '4em',
    '& *': {
      verticalAlign: 'center',
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: '1px',
    margin: '-1px',
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    width: '1px',
    whiteSpace: 'nowrap',
  },
}));

const CUSTOM_SCHEME = 'ommnio://';

const APP_CONFIGS = {
  default: {
    GOOGLE_PLAY_URL: 'https://play.google.com/store/apps/details?id=com.ommnio',
    APPLE_STORE_URL: 'https://apps.apple.com/es/app/ommnio/id1481362031',
    APP_ID: 'P47KDCH457.com.ommnio.communications.ommnioapp',
  },
  'saint-gobain': {
    GOOGLE_PLAY_URL: 'https://play.google.com/store/apps/details?id=com.sg.R20B.OmmnioConnectia',
    APPLE_STORE_URL: 'https://apps.apple.com/app/id6596770709',
    APP_ID: 'ZN75HV5A8E.com.sg.R20B.OmmnioConnectia',
  },
};

const getAppConfig = () => {
  const { hostname } = window.location;
  if (hostname.startsWith('saint-gobain')) {
    return APP_CONFIGS['saint-gobain'];
  }
  return APP_CONFIGS.default;
};

const InviteScene = () => {
  const classes = useStyles();
  const location = useLocation();
  const appConfig = useMemo(() => getAppConfig(), []);
  const token = useMemo(() => location.pathname.replace('/invite/', '').trim(), [location]);

  const customSchemeURL = useMemo(
    () =>
      `${CUSTOM_SCHEME}invite?token=${encodeURIComponent(token)}&host=${encodeURIComponent(
        window.location.hostname,
      )}`,
    [token],
  );

  const redirectURL = useMemo(() => {
    if (iOS) {
      const encodedCustomSchemeURL = encodeURIComponent(customSchemeURL);
      return `${appConfig.APPLE_STORE_URL}?ct=${encodedCustomSchemeURL}`;
    }
    if (android) {
      return `intent://${window.location.host}/invite/${token}#Intent;scheme=https;package=${
        appConfig.GOOGLE_PLAY_URL.split('id=')[1]
      };S.browser_fallback_url=${encodeURIComponent(appConfig.GOOGLE_PLAY_URL)};end`;
    }
    return null;
  }, [customSchemeURL, appConfig, token]);

  useEffect(() => {
    const redirectTimer = setTimeout(() => {
      if (iOS || android) {
        window.location.href = redirectURL;
      }
    }, 2000);

    return () => clearTimeout(redirectTimer);
  }, [redirectURL]);

  return (
    <div className={classes.root}>
      <div className={classes.Logo}>
        <img src={logo} className={classes.LogoImg} alt="Ommnio" />
      </div>
      {iOS || android ? (
        <h3 className={classes.RedirectHeading}>
          <Translate value="InviteScene.Redirecting to" />
        </h3>
      ) : (
        <section>
          <Typography className={classes.title} gutterBottom variant="h5">
            {I18n.t('InviteScene.Title')}
          </Typography>
          <Typography variant="body1" className={classes.description}>
            {I18n.t('InviteScene.Description')}
          </Typography>
        </section>
      )}
      <div className={classes.AppBtns}>
        {android || iOS ? (
          <a
            href={redirectURL}
            className={classes.AppBtnsLink}
            aria-label={I18n.t('InviteScene.Install the app')}
          >
            {android ? (
              <GoogleDownload className={classes.AppBtnsLinkIcon} aria-hidden="true" />
            ) : (
              <AppleDownload className={classes.AppBtnsLinkIcon} aria-hidden="true" />
            )}
            <span className={classes.visuallyHidden}>{I18n.t('InviteScene.Install the app')}</span>
          </a>
        ) : null}
      </div>
      {!iOS && !android ? (
        <Link to={`/accept-invite/${token}`} className={classes.link}>
          <Button
            variant="contained"
            className={classNames(classes.enterButton, classes.arrowButton)}
            color="primary"
          >
            <span>
              <Translate value="InviteScene.Button Text" />
            </span>
            <SvgIcon className={classes.icon}>
              <Next />
            </SvgIcon>
          </Button>
        </Link>
      ) : null}
      {iOS || android ? (
        <div className={classes.NextBlock}>
          <p className={classes.NextBlockText}>
            <Translate value="InviteScene.Whats next" />
          </p>
          <div className={classes.NextBlockBtns}>
            <div className={classes.NextBlockBtnsItem}>
              <DownloadIcon className={classes.NextBlockBtnsItemIcon} />
              <p className={classes.NextBlockBtnsItemText}>
                1.
                <Translate value="InviteScene.Install the app" />
              </p>
            </div>
            <div className={classes.NextBlockBtnsItem}>
              <QrCodeIcon className={classes.NextBlockBtnsItemIcon} />
              <p className={classes.NextBlockBtnsItemText}>
                2.
                <Translate value="InviteScene.Scan QR" />
              </p>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default InviteScene;
