import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { LinearProgress, Box } from '@mui/material';

const LoadingBar = ({ loading }) => {
  const [progress, setProgress] = useState(0);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!loading && show) {
      setProgress(100);
      setTimeout(() => {
        setShow(false);
      }, 2000); // Display 100% green bar for 2 seconds before disappearing
    } else if (loading && !show) {
      setShow(true);
      setProgress(0);
    }
  }, [loading, show]); // Effect runs on changes to `loading` or `show`

  return (
    <Box sx={{ width: '100%', opacity: show ? 1 : 0, transition: 'opacity 0.5s ease-out' }}>
      <LinearProgress
        sx={{ height: 2 }}
        variant={progress < 100 ? 'indeterminate' : 'determinate'}
        value={progress}
        color={progress < 100 ? 'primary' : 'success'}
      />
    </Box>
  );
};

LoadingBar.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default LoadingBar;
