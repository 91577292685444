const focusToEnd = input => {
  if (input && input.focus) {
    const len = input.value.length;
    input.focus();
    if (input.setSelectionRange) {
      input.setSelectionRange(len, len);
    } else if (input.createTextRange) {
      const t = input.createTextRange();
      t.collapse(true);
      t.moveEnd('character', len);
      t.moveStart('character', len);
      t.select();
    }
  }
};
export default focusToEnd;
