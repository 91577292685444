import { REACTION_EMOJIS } from 'constants/reactions';
import PropTypes from 'prop-types';
import React from 'react';

const ReactionIcon = ({ name }) => {
  if (REACTION_EMOJIS[name]) {
    return REACTION_EMOJIS[name];
  }
  return (
    <img
      src={`/reactions/${name}.svg`}
      alt={name}
      style={{ width: '1em', height: '1em', verticalAlign: 'middle' }}
    />
  );
};

ReactionIcon.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ReactionIcon;
