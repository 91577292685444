import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import settingsDrawer from 'store/app/ui/drawers/settings/action';

const mapStateToProps = state => ({
  isOpen: state.drawers.settings.isOpen,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    drawers: {
      settings: bindActionCreators(settingsDrawer, dispatch),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
