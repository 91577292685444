import { createTheme, ThemeProvider } from '@mui/material';
import React, { forwardRef, useMemo } from 'react';
import theme from 'styles/theme';
import InvitationToPrint from './InvitationToPrint';

const PrintContent = forwardRef(({ employee }, ref) => {
  const lightTheme = useMemo(() => createTheme(theme(false)), []);
  return (
    <ThemeProvider theme={lightTheme}>
      <div ref={ref} data-mui-color-scheme="light">
        <InvitationToPrint employee={employee} />
      </div>
    </ThemeProvider>
  );
});

export default PrintContent;
