/* eslint-disable class-methods-use-this */
import Http from 'services/Http';

class DocumentFormat {
  async find(id) {
    const { data } = await Http.get(`/v1/document-format/${id}`);
    return data.app;
  }

  async list() {
    const { data } = await Http.get('/v1/document-format');
    return data;
  }

  async create(form) {
    const { data } = await Http.post('/v1/document-format', form);
    return data;
  }

  async update(id, form) {
    const { data } = await Http.patch(`/v1/document-format/${id}`, form);
    return data;
  }

  async destroy(id) {
    const { data } = await Http.delete(`/v1/document-format/${id}`);
    return data;
  }
}

export default new DocumentFormat();
