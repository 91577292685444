import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import channels from 'store/app/entities/channels/action';
import linkPreviews from 'store/app/entities/linkPreviews/action';
import messages from 'store/app/entities/messages/action';
import authEmployee from 'store/selectors/authEmployee';
import authUser from 'store/selectors/authUser';
import availableEmployees from 'store/selectors/availableEmployees';
import currentChannel from 'store/selectors/currentChannel';
import isCompanyAdmin from 'store/selectors/isCompanyAdmin';

const mapStateToProps = state => ({
  authEmployee: authEmployee(state),
  channel: currentChannel(state),
  isCompanyAdmin: isCompanyAdmin(state),
  availableEmployees: availableEmployees(state),
  isSuperAdmin: authUser(state).isSuperAdmin,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    linkPreviews: bindActionCreators(linkPreviews, dispatch),
    messages: bindActionCreators(messages, dispatch),
    channels: bindActionCreators(channels, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
