import withStyles from '@mui/styles/withStyles';
import SearchField from 'components/controls/SearchField';
import { func, object, string } from 'prop-types';
import React from 'react';

const styles = {
  root: {
    minWidth: 200,
    flex: '1 0 auto',
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
  },
  searchIconButton: {
    marginLeft: 5,
  },
  searchIcon: {
    fontSize: 16,
  },
};

const Search = props => {
  const { classes, value, onChange } = props;
  return (
    <div className={classes.root}>
      <SearchField value={value} classes={{ formControl: classes.search }} onChange={onChange} />
    </div>
  );
};

Search.propTypes = {
  classes: object.isRequired,
  value: string,
  onChange: func.isRequired,
};
Search.defaultProps = {
  value: '',
};

export default withStyles(styles)(Search);
