import { withFormik } from 'formik';
import * as Yup from 'yup';
import uniqid from 'uniqid';

class AcknowledgmentSubmit {
  constructor(values, formikBag) {
    this.values = values;
    this.formikBag = formikBag;
  }

  createForm = () => {
    const { title, isDraft, sentAsChannel, digitalSignature } = this.values;
    const {
      props: { message, tempAttachment, channel, authCompany, authEmployee, authUser },
    } = this.formikBag;

    return {
      _id: uniqid(),
      text: title,
      title,
      acknowledges: [],
      attachments: message?.attachments || [tempAttachment],
      channel_id: channel._id,
      company_id: authCompany._id,
      previews: {},
      createdAt: Date.now(),
      employee_id: authEmployee?._id,
      user_id: authUser._id,
      isDeletedBy: [],
      isDraft,
      isEdited: false,
      isMandatory: true,
      links: [],
      temp: true,
      seen: false,
      updatedAt: Date.now(),
      senderWasDeleted: false,
      sentAsChannel,
      digitalSignature: digitalSignature[0] === 'on',
    };
  };

  sendRequest = async form => {
    const { actions, message } = this.formikBag.props;
    if (message?.isDraft) {
      await actions.messages.update({ ...form, _id: message._id });
    } else {
      await actions.messages.send(form);
    }
  };
}

const formik = withFormik({
  validationSchema: Yup.object().shape({
    title: Yup.string().trim().required('Please enter the title'),
  }),

  mapPropsToValues: ({ message, channel }) => ({
    title: message?.title || message?.text || '',
    isDraft: false,
    sentAsChannel: channel.isReadOnly,
    digitalSignature: message?.digitalSignature ? ['on'] : [],
  }),

  enableReinitialize: true,
  handleSubmit: async (values, formikBag) => {
    const { onClose } = formikBag.props;

    const submit = new AcknowledgmentSubmit(values, formikBag);

    const form = submit.createForm();

    await submit.sendRequest(form);

    onClose();
  },
  displayName: 'AcknowledgementForm',
});

export default formik;
