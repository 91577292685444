import { Chip } from '@mui/material';
import AttachmentsField from 'components/@whistleblower-admin/drawers/OccurrenceDrawer/AttachmentsField';
import { useOccurrence } from 'components/@whistleblower-admin/drawers/OccurrenceDrawer/OccurrenceContext';
import Section from 'components/@whistleblower-admin/drawers/Section';
import DefinitionList from 'components/common/DefinitionList';
import DefinitionListItem from 'components/common/DefinitionListItem';
import FormatPhoneNumber from 'components/common/FormatPhoneNumber';
import BadgeAccountHorizontalOutlineIcon from 'mdi-react/BadgeAccountHorizontalOutlineIcon';
import moment from 'moment';
import React from 'react';
import { I18n } from 'utils/i18n';

const styles = {
  chip: {
    borderRadius: '6px',
    height: 'auto',
  },
};

const OccurrenceDetails = () => {
  const occurrence = useOccurrence();
  return (
    <Section
      titleKey="OccurrenceDrawer.details"
      titleRight={
        <Chip
          icon={<BadgeAccountHorizontalOutlineIcon size={16} />}
          variant="soft"
          label={I18n.t(`OccurrenceDrawer.privacyTypes.${occurrence.privacyType}`)}
          sx={styles.chip}
        />
      }
    >
      <DefinitionList sx={{ borderBottom: 0 }}>
        <DefinitionListItem
          dt={I18n.t('OccurrenceDrawer.fields.createdAt')}
          dd={moment(occurrence.createdAt).format('LLLL')}
        />
        <DefinitionListItem dt={I18n.t('OccurrenceDrawer.fields.subject')} dd={occurrence.title} />
        <DefinitionListItem
          dt={I18n.t('OccurrenceDrawer.fields.description')}
          dd={occurrence.description}
        />
        {!!occurrence.phone && (
          <DefinitionListItem dt={I18n.t('OccurrenceDrawer.fields.name')} dd={occurrence.name} />
        )}
        {!!occurrence.email && (
          <DefinitionListItem dt={I18n.t('OccurrenceDrawer.fields.email')} dd={occurrence.email} />
        )}
        {!!occurrence.phone && (
          <DefinitionListItem
            dt={I18n.t('OccurrenceDrawer.fields.phone')}
            dd={<FormatPhoneNumber number={`+${occurrence.phone}`} />}
          />
        )}
        {!!occurrence.files && (
          <DefinitionListItem dt={I18n.t('OccurrenceDrawer.fields.files')} dd={occurrence.files} />
        )}
        <DefinitionListItem
          dt={I18n.t('OccurrenceDrawer.fields.channel')}
          dd={occurrence.portal?.name}
        />
        <DefinitionListItem
          dt={I18n.t('OccurrenceDrawer.fields.attachments')}
          dd={<AttachmentsField attachments={occurrence.attachments} aesKey={occurrence.aesKey} />}
        />
      </DefinitionList>
    </Section>
  );
};

export default OccurrenceDetails;
