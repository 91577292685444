import ConfirmDialog from 'components/common/ConfirmDialog';
import DeleteDialog from 'components/common/DeleteDialog';
import { node } from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';
import { I18n } from 'utils/i18n';
import useOpenClose from 'utils/useOpenClose';

export const ConfirmDialogContext = React.createContext({});

const GlobalConfirmDialogProvider = ({ children }) => {
  const confirmRef = useRef();
  const rippleRef = useRef();
  const [isOpen, openDialog, closeDialog, args] = useOpenClose();
  const [buttons, setButtons] = useState([]);
  const onClose = useRef(null);
  const close = useCallback(() => {
    setTimeout(() => {
      setButtons([]);
      onClose.current = null;
    }, 500);
    if (typeof onClose.current === 'function') {
      onClose.current();
    }
    closeDialog();
  }, [closeDialog, onClose]);
  const open = useCallback(
    newArgs => {
      if (isOpen) {
        return;
      }
      onClose.current = newArgs.onClose;
      setButtons([
        {
          text: newArgs.cancelText || I18n.t('ConfirmDialog.Cancel'),
          onClick: close,
          ...(newArgs.cancelBtn || {}),
        },
        {
          text: newArgs.confirmText || I18n.t('ConfirmDialog.Confirm'),
          onClick: newArgs.onConfirm || close,
          variant: 'contained',
          color: 'primary',
          ...(newArgs.confirmBtn || {}),
          type: 'submit',
          ref: confirmRef,
          touchRippleRef: rippleRef,
        },
      ]);
      openDialog(newArgs);
      setTimeout(() => {
        confirmRef.current?.focus();
        rippleRef.current?.stop();
        rippleRef.current?.pulsate();
      }, 100);
    },
    [isOpen, openDialog, close, confirmRef],
  );
  return (
    <ConfirmDialogContext.Provider value={{ open, close, isOpen }}>
      {children}
      {args?.isDelete ? (
        <DeleteDialog
          message={args?.message}
          typeBack={args?.typeBack}
          onClose={close}
          isOpen={isOpen}
          onConfirm={args?.onConfirm}
        >
          {args?.children}
        </DeleteDialog>
      ) : (
        <ConfirmDialog message={args?.message} buttons={buttons} onClose={close} isOpen={isOpen}>
          {args?.children}
        </ConfirmDialog>
      )}
    </ConfirmDialogContext.Provider>
  );
};

GlobalConfirmDialogProvider.propTypes = {
  children: node.isRequired,
};

export default GlobalConfirmDialogProvider;
