import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { I18n } from 'utils/i18n';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: 20,
    paddingRight: 40,
    justifyContent: 'flex-end',
  },
}));

const LoginRejectedScene = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const onRetry = () => {
    navigate('/auth/login', { replace: true });
  };
  return (
    <div className={classes.root}>
      <Typography className={classes.heading} gutterBottom variant="h5">
        {I18n.t(`LoginRejectedScene.headline`)}
      </Typography>
      <Typography gutterBottom>{I18n.t(`LoginRejectedScene.You told us`)}</Typography>
      <Typography gutterBottom>{I18n.t(`LoginRejectedScene.If it was`)}</Typography>
      <div className={classes.buttonWrapper}>
        <Button variant="contained" type="button" color="primary" onClick={onRetry}>
          {I18n.t(`LoginRejectedScene.Try again`)}
        </Button>
      </div>
    </div>
  );
};

export default LoginRejectedScene;
