import {
  Box,
  Button,
  Drawer,
  FormControlLabel,
  Grid,
  RadioGroup,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Switch from '@mui/material/Switch';
import makeStyles from '@mui/styles/makeStyles';
import CloseButton from 'components/@home/drawers/CloseButton';
import Autosuggest from 'components/controls/Autosuggest';
import StyledRadio from 'components/controls/StyledRadio';
import InfoCircleIcon from 'mdi-react/InfoCircleIcon';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'utils/i18n';
import { bindActionCreators } from 'redux';
import { HR_BOT_APP_ID } from 'services/constants';
import apps from 'store/app/entities/apps/action';
import {
  useCreateDocumentFormatMutation,
  useEditDocumentFormatMutation,
} from 'store/app/entities/SharedFilesSlice';
import employeesWithUser from 'store/selectors/employeesWithUser';

export const EditDrawerContext = React.createContext({});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'hidden',
  },
  content: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    flexGrow: 1,
    display: 'flex',
    maxHeight: '100%',
    overflow: 'auto',
    alignItems: 'flex-start',
  },
  navigationButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'flex-end',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  radio: {
    flexDirection: 'row',
    '& label': {
      marginRight: theme.spacing(4),
    },
  },
  switch: {
    margin: 0,
  },
  info: {
    fontSize: 17,
    color: theme.palette.greyBlue,
    verticalAlign: 'text-bottom',
    display: 'inline',
    marginLeft: theme.spacing(1),
  },
  message: {
    minWidth: 300,
  },
  button: {
    marginRight: theme.spacing(1),
  },
}));

const EditDrawer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { file: format, isOpen, close } = useContext(EditDrawerContext);
  const [editDocumentFormat] = useEditDocumentFormatMutation();
  const [createDocumentFormat] = useCreateDocumentFormatMutation();

  const actions = {
    apps: bindActionCreators(apps, dispatch),
  };
  const app = useSelector(state => state.apps.apps?.[HR_BOT_APP_ID]);
  const employees = useSelector(employeesWithUser);
  useEffect(() => {
    if (!loading && app?._id && !app?.groups) {
      setLoading(true);
      actions.apps.getGroups(app._id).then(() => setLoading(false));
    }
  }, [actions.apps, app?._id]);
  let sendReportTo = format ? { value: -1 } : {};
  if (format?.sendReportToGroup) {
    sendReportTo = { type: 'group', value: format.sendReportToGroup };
  }
  if (format?.sendReportToEmployee) {
    sendReportTo = { type: 'employee', value: format.sendReportToEmployee };
  }
  const [newTemplate, setNewTemplate] = useState({});
  useEffect(() => {
    if (format) {
      setNewTemplate({
        ...format,
        sendReportTo,
        type: format.type?.split('-').pop() || 'pdf',
        split: format.type === 'single-pdf',
      });
    } else {
      setNewTemplate({
        name: '',
        type: 'pdf',
        sendReportTo,
        prefix: '',
        sendBy: 'code',
        defautMessage: I18n.t('HrBot.defaultMessage'),
        split: false,
      });
    }
  }, [format]);

  const handleSubmit = async () => {
    let type;
    if (newTemplate.type === 'xml') {
      type = 'xml';
    } else if (newTemplate.split) {
      type = 'single-pdf';
    } else {
      type = 'multi-pdf';
    }
    const form = {
      name: newTemplate.name,
      type,
      sendBy: newTemplate.sendBy,
      prefix: newTemplate.prefix,
      defaultMessage: newTemplate.defaultMessage,
    };
    if (newTemplate.sendReportTo?.type === 'group' && newTemplate.sendReportTo.value !== -1) {
      form.sendReportToGroup = newTemplate.sendReportTo.value;
    }
    if (newTemplate.sendReportTo?.type === 'employee' && newTemplate.sendReportTo.value !== -1) {
      form.sendReportToEmployee = newTemplate.sendReportTo.value;
    }
    if (format) {
      await editDocumentFormat({ id: format?._id, body: form });
    } else {
      await createDocumentFormat(form);
    }
    close();
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={close} onClick={e => e.stopPropagation()}>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          maxWidth: 'calc(100vw - 40px)',
          width: { xs: '100%', md: 1100 },
        }}
      >
        <Box sx={{ paddingTop: 2.5, paddingLeft: 2.5 }}>
          <CloseButton onClick={close} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            minWidth: 500,
            flexDirection: 'column',
            padding: 2.5,
            height: '100vh',
          }}
        >
          <div className={classes.root}>
            <Typography variant="h6" component="h1">
              {I18n.t(`HrBot.tabs.${format ? 'editTemplate' : 'newTemplate'}.title`, {
                appName: app?.name,
                name: format?.name,
              })}
            </Typography>
            <div className={classes.content}>
              <Grid container spacing={2}>
                <Grid item xs={2} className={classes.label}>
                  <Typography>{`${I18n.t('HrBot.EditTemplate.Name')}`}</Typography>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    value={newTemplate.name}
                    onChange={e => setNewTemplate(t => ({ ...t, name: e.target.value }))}
                    className={classes.input}
                  />
                </Grid>
                <Grid item xs={2} className={classes.label}>
                  <Typography>{`${I18n.t('HrBot.EditTemplate.Send report to')}`}</Typography>
                </Grid>
                <Grid item xs={10}>
                  <Autosuggest
                    fullWidth
                    value={newTemplate.sendReportTo?.value}
                    onChange={v => {
                      setNewTemplate(t => ({ ...t, sendReportTo: v }));
                    }}
                    suggestions={[
                      {
                        label: I18n.t('HrBot.EditTemplate.Do not send'),
                        value: -1,
                      },
                      {
                        label: I18n.t('HrBot.EditTemplate.Groups'),
                        options:
                          app?.groups?.map(channel => ({
                            value: channel._id,
                            type: 'group',
                            label: channel.name,
                          })) || [],
                      },
                      {
                        label: I18n.t('HrBot.EditTemplate.People'),
                        options:
                          employees?.map(e => ({
                            label: e.name,
                            type: 'employee',
                            value: e._id,
                          })) || [],
                      },
                    ]}
                    className={classes.select}
                  />
                </Grid>
                <Grid item xs={2} className={classes.label}>
                  <Typography>{`${I18n.t('HrBot.EditTemplate.Type')}`}</Typography>
                </Grid>
                <Grid item xs={10}>
                  <RadioGroup
                    className={classes.radio}
                    onChange={e => setNewTemplate(t => ({ ...t, type: e.target.value }))}
                  >
                    {['pdf', 'xml'].map(type => (
                      <FormControlLabel
                        key={type}
                        value={type}
                        checked={newTemplate.type === type}
                        control={<StyledRadio />}
                        label={I18n.t(`HrBot.EditTemplate.Types.${type}`)}
                      />
                    ))}
                  </RadioGroup>
                </Grid>
                {newTemplate.type === 'pdf' && (
                  <>
                    <Grid item xs={2} className={classes.label}>
                      <Typography>{`${I18n.t('HrBot.EditTemplate.Split pages')}`}</Typography>
                      <Tooltip
                        title={I18n.t('HrBot.EditTemplate.Split pages info')}
                        enterDelay={300}
                      >
                        <SvgIcon className={classes.info}>
                          <InfoCircleIcon />
                        </SvgIcon>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={10}>
                      <Switch
                        checked={newTemplate.split}
                        onChange={e => setNewTemplate(t => ({ ...t, split: e.target.checked }))}
                        className={classes.switch}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={2} className={classes.label}>
                  <Typography>{`${I18n.t('HrBot.EditTemplate.SendBy')}`}</Typography>
                </Grid>
                <Grid item xs={10}>
                  <RadioGroup
                    className={classes.radio}
                    onChange={e => setNewTemplate(t => ({ ...t, sendBy: e.target.value }))}
                  >
                    {['code', 'id'].map(sendBy => (
                      <FormControlLabel
                        key={sendBy}
                        value={sendBy}
                        checked={newTemplate.sendBy === sendBy}
                        control={<StyledRadio />}
                        label={I18n.t(`HrBot.EditTemplate.SendByOptions.${sendBy}`)}
                      />
                    ))}
                  </RadioGroup>
                </Grid>
                <Grid item xs={2} className={classes.label}>
                  <Typography>{`${I18n.t('HrBot.EditTemplate.Prefix')}`}</Typography>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    placeholder="Prefix"
                    value={newTemplate.prefix}
                    onChange={e => setNewTemplate(t => ({ ...t, prefix: e.target.value }))}
                  />
                </Grid>
                <Grid item xs={2} className={classes.label}>
                  <Typography>{`${I18n.t('HrBot.EditTemplate.Default message')}`}</Typography>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    className={classes.message}
                    multiline
                    rows={14}
                    value={newTemplate.defaultMessage}
                    onChange={e => setNewTemplate(t => ({ ...t, defaultMessage: e.target.value }))}
                  />
                </Grid>
              </Grid>
            </div>
            <div className={classes.navigationButtons}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={close}
                className={classes.button}
              >
                {I18n.t('HrBot.Cancel')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={!newTemplate.name || !newTemplate.sendReportTo?.value}
                onClick={handleSubmit}
                className={classes.button}
              >
                {I18n.t('HrBot.Save')}
              </Button>
            </div>
          </div>
        </Box>
      </Box>
    </Drawer>
  );
};

export default EditDrawer;
