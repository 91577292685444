import { useRef, useInsertionEffect, useCallback } from 'react';

// The useEffectEvent API has not yet been added to React
const useEffectEvent = fn => {
  const ref = useRef(null);
  useInsertionEffect(() => {
    ref.current = fn;
  }, [fn]);
  return useCallback((...args) => ref.current(...args), []);
};

export default useEffectEvent;
