/**
 * @flow
 */
import { InputAdornment, TextField, Typography } from '@mui/material';
import React from 'react';
import baseUrl from 'utils/baseUrl';

const SlugField = ({ sx, disabled, ...props }: Object) => {
  return (
    <TextField
      sx={{ maxWidth: 700 }}
      fullWidth
      defaultValue={props.value}
      InputProps={{
        sx: { padding: 0 },
        startAdornment: (
          <InputAdornment
            position="start"
            sx={{
              margin: 0,
              borderRadius: '6px 0 0 6px',
              backgroundColor: 'secondary.ultraLight',
              borderRight: '1px solid',
              borderColor: 'secondary.light',
              height: '100%',
              padding: '10px',
              maxHeight: 'none',
            }}
          >
            <Typography color="secondary.main" fontWeight="bold">
              {baseUrl}/secure/
            </Typography>
          </InputAdornment>
        ),
      }}
      disabled={disabled}
      {...props.field}
    />
  );
};

export default SlugField;
