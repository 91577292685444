/* eslint-disable max-len,no-nested-ternary,react/destructuring-assignment,no-console,no-param-reassign */
import { Chip, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import CopyId from 'components/common/CopyId';
import { object, shape } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { I18n } from 'utils/i18n';
import employeeShape from 'shapes/employee';
import connector from './connector';
import EmployeeActions from './EmployeeActions';
import InfoList from './InfoList';
import Mandatories from './Mandatories';
import Statistics from './Statistics';

const DOCS_PER_PAGE = 10;

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 415,
    width: '100%',
    height: '100%',
    padding: '20px 30px 20px 40px',
    overflowY: 'auto',
  },
  tag: {
    height: 35,
    borderRadius: 5,
    backgroundColor: theme.palette.secondary.ultraLight,
    marginRight: 5,
    marginBottom: 10,
  },
  channels: {
    marginTop: 20,
  },
  divider: {
    margin: '30px 0 30px 0',
    backgroundColor: theme.palette.secondary.extraLight,
    height: 1,
    border: 0,
    flex: 'none',
  },
  id: {
    paddingTop: 20,
  },
});

const Profile = ({ employee, actions, classes }) => {
  const [page, setPage] = useState(0);

  const onChangePage = ({ selected } = {}) => {
    setPage(selected);
  };

  useEffect(() => {
    actions.employees.mandatories(employee._id, page + 1, DOCS_PER_PAGE);
  }, [actions.employees, employee._id, page]);

  return (
    <div className={classes.root}>
      <EmployeeActions employee={employee} readOnlyAvatar />
      <InfoList
        email={employee.email}
        location={employee.location}
        phone={employee.phone}
        tags={employee.tags}
      />
      <Statistics
        mandatoriesCount={employee.isAdmin ? employee.mandatorySent : employee.mandatoryRead}
        messagesSent={employee.messagesSent}
        lastSeen={employee.lastSeen}
      />
      <div className={classes.channels}>
        <Typography variant="caption" gutterBottom>
          {I18n.t('ProfileDrawer.CHANNELS')}
        </Typography>
        {employee.channels?.map(c => (
          <Chip
            label={c.name + (c.description ? ` - ${c.description}` : '')}
            key={c.name}
            className={classes.tag}
          />
        ))}
      </div>
      {employee.mandatories && employee.mandatories.totalCount ? (
        <>
          <hr className={classes.divider} />
          <Typography variant="caption" gutterBottom>
            {I18n.t('ProfileDrawer.mandatory reads read')}
          </Typography>
          <Mandatories
            employee={employee}
            total={employee.mandatories.totalCount}
            documents={employee.mandatories.mandatoryRead}
            page={page}
            perPage={DOCS_PER_PAGE}
            changePage={onChangePage}
          />
        </>
      ) : null}

      {!employee.user_id || <CopyId id={employee.user_id} className={classes.id} />}
    </div>
  );
};

Profile.propTypes = {
  classes: object.isRequired,
  employee: employeeShape.isRequired,
  actions: shape({
    employees: object.isRequired,
  }).isRequired,
};

export default withStyles(styles)(connector(Profile));
