import { Avatar, Box, ButtonBase, Typography } from '@mui/material';
import MessageFrom from 'components/@home/@messages/ChannelsPanel/MessageFrom/MessageFrom';
import MessagePreview from 'components/@home/@messages/ChatPanel/PinnedMessage/MessagePreview';
import { func } from 'prop-types';
import React, { useCallback } from 'react';
import messageShape from 'shapes/message';

const MessageReplied = ({ message: { replyTo: message }, scrollToMessage }) => {
  const handleScroll = useCallback(() => {
    if (message?._id) {
      scrollToMessage(message._id);
    }
  }, [message?._id, scrollToMessage]);
  if (!message) {
    return null;
  }
  return (
    <ButtonBase
      sx={{ margin: 0.25, marginTop: 1, textAlign: 'left', borderRadius: 1 }}
      onClick={handleScroll}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
          px: 1.5,
          py: 1,
          alignItems: 'stretch',
        }}
      >
        <Box
          sx={{
            width: '3px',
            flex: '0 0 auto',
            margin: 0,
            backgroundColor: 'primary.light',
            borderRadius: 3,
            height: 'auto',
          }}
        />
        {message.attachments?.[0]?.meta?.thumb && (
          <Box>
            <Avatar
              variant="rounded"
              sx={{ width: 42, height: 42 }}
              src={message.attachments[0].meta.thumb}
            />
          </Box>
        )}
        <Box
          sx={{
            flexGrow: 1,
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            overflowWrap: 'break-word',
            color: 'secondary.main',
          }}
        >
          <Typography sx={{ fontWeight: 500, color: 'primary.main' }}>
            {/* eslint-disable-next-line camelcase */}
            <MessageFrom message={message} />
          </Typography>
          <MessagePreview message={message} showImages={false} maxLines={1} />
        </Box>
      </Box>
    </ButtonBase>
  );
};

MessageReplied.propTypes = {
  scrollToMessage: func.isRequired,
  message: messageShape.isRequired,
};

export default MessageReplied;
