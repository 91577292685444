import {
  LOAD_LANGUAGES_PENDING,
  LOAD_LANGUAGES_FULFILLED,
  LOAD_LANGUAGES_REJECTED,
} from './action';

const initialState = {
  languages: [],
  error: null,
  loaded: false,
  loading: false,
};

const languagesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_LANGUAGES_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
        loaded: false,
      };

    case LOAD_LANGUAGES_FULFILLED:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        languages: payload,
      };

    case LOAD_LANGUAGES_REJECTED:
      return {
        ...state,
        loaded: false,
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default languagesReducer;
