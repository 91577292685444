import { CLOSE_ANALYTICS_DRAWER, OPEN_ANALYTICS_DRAWER } from './action';
import mandatoryReducer from './mandatory/reducer';

const initialState = {
  isOpen: false,
  mandatory: undefined,
  fullMandatory: undefined,
};

const AnalyticsDrawer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_ANALYTICS_DRAWER:
      return {
        ...state,
        isOpen: true,
        mandatory: action.payload,
      };

    case CLOSE_ANALYTICS_DRAWER:
      return { ...state, isOpen: false };

    default:
      return {
        ...state,
        fullMandatory: mandatoryReducer(state.fullMandatory, action),
      };
  }
};

export default AnalyticsDrawer;
