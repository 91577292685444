import { Box, Drawer } from '@mui/material';
import CloseButton from 'components/@home/drawers/CloseButton';
import RulesForm from 'components/@home/drawers/RulesDrawer/RulesForm';
import Loading from 'components/common/Loading';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useListRulesQuery } from 'store/app/entities/TeamMembershipRulesSlice';
import rulesActions from 'store/app/ui/drawers/rules/action';
import useActions from 'utils/useActions';

const styles = {
  root: {
    display: 'flex',
    width: {
      xs: '100%',
      md: 1000,
    },
    flex: 1,
    height: '100vh',
  },
  container: {
    display: 'flex',
  },
  closeButtonContainer: {
    paddingTop: 2.5,
    paddingLeft: 2.5,
  },
};

const RulesDrawer = () => {
  const team = useSelector(state => state.drawers.rules.team);
  const close = useActions(rulesActions.close);
  const isOpen = useSelector(state => state.drawers.rules.isOpen);
  const { data: rules = [], isLoading } = useListRulesQuery(team._id, {
    skip: !team?._id || !isOpen,
  });
  const form = useForm({ values: { rules } });
  return (
    <Drawer anchor="right" open={isOpen} onClose={close}>
      <Box sx={styles.root}>
        <Box sx={styles.closeButtonContainer}>
          <CloseButton onClick={close} />
        </Box>
        <FormProvider {...form}>
          <RulesForm team={team} isLoading={isLoading} />
        </FormProvider>
      </Box>
    </Drawer>
  );
};

export default RulesDrawer;
