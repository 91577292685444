import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import logo from 'assets/logos/logo';
import { bool, object } from 'prop-types';
import React from 'react';
import BackButton from './BackButton';

const styles = theme => ({
  root: {
    display: 'flex',
    padding: '30px 0',
    textAlign: 'center',
    marginBottom: 27,
  },
  counterBlock: {
    width: 40,
    height: 38,
  },
  text: {
    color: theme.palette.primary.main,
    paddingLeft: 16,
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSize: 22,
    fontWeight: 'bold',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    filter: theme.palette.mode === 'dark' ? 'invert(1) hue-rotate(180deg)' : 'invert(0)',
  },
});

const Logo = ({ classes, isBackVisible }) => (
  <Grid container alignItems="center" justifyContent="space-between" className={classes.root}>
    {isBackVisible ? (
      <div>
        <BackButton />
      </div>
    ) : (
      <div className={classes.counterBlock} />
    )}
    <div className={classes.container}>
      <img src={logo} alt="logo" width={127} className={classes.logo} />
    </div>

    <div className={classes.counterBlock} />
  </Grid>
);
Logo.propTypes = {
  classes: object.isRequired,
  isBackVisible: bool.isRequired,
};

export default withStyles(styles)(Logo);
