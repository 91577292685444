import { OPEN_PROFILE_DRAWER, CLOSE_PROFILE_DRAWER } from './action';

const initialState = {
  isOpen: false,
};

const profileDrawer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_PROFILE_DRAWER:
      return {
        ...state,
        isOpen: true,
        employee_id: action.payload,
      };

    case CLOSE_PROFILE_DRAWER:
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
};

export default profileDrawer;
