import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { bool, number, object, oneOfType, string } from 'prop-types';
import React, { memo } from 'react';
import { I18n } from 'utils/i18n';
import getMoment from 'utils/getMoment';
import Badge from './Badge';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  channelName: {
    width: '210px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  timeCaption: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: 84,
  },
  time: {
    textAlign: 'right',
    width: 60,
  },
});

const Label = ({ messagesUnreadCount, mandatoriesUnreadCount, pinned, createdAt, children }) => {
  const classes = useStyles();
  const date = getMoment(createdAt);
  const time = date?.calendar(null, {
    sameDay: 'LT',
    lastDay: `[${I18n.t('ChannelsPanel.Yesterday')}]`,
    lastWeek: 'dddd',
    sameElse: 'L',
  });
  return (
    <div className={classes.root}>
      <Typography className={classes.channelName} component="div">
        {children}
      </Typography>
      <Typography variant="caption" className={classes.timeCaption}>
        <span className={classes.time}>{time}</span>
        <Badge
          mandatoriesUnreadCount={mandatoriesUnreadCount}
          messagesUnreadCount={messagesUnreadCount}
          pinned={pinned}
        />
      </Typography>
    </div>
  );
};

Label.propTypes = {
  messagesUnreadCount: number.isRequired,
  mandatoriesUnreadCount: number,
  createdAt: oneOfType([string, number]),
  children: string.isRequired,
  pinned: bool.isRequired,
};
Label.defaultProps = {
  createdAt: null,
  mandatoriesUnreadCount: 0,
};
const isEqual = (prev, next) => {
  if (prev.children !== next.children) return false;
  if (prev.createdAt !== next.createdAt) return false;
  if (prev.pinned !== next.pinned) return false;
  if (prev.mandatoriesUnreadCount !== next.mandatoriesUnreadCount) return false;
  return prev.messagesUnreadCount === next.messagesUnreadCount;
};

export default memo(Label, isEqual);
