import { CLOSE_EMPLOYEE_LIST_DRAWER, OPEN_EMPLOYEE_LIST_DRAWER } from './action';

const initialState = {
  isOpen: false,
  title: null,
};

const employeeListReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_EMPLOYEE_LIST_DRAWER:
      return {
        ...state,
        title: action.title,
        isOpen: true,
        message: action.message,
      };

    case CLOSE_EMPLOYEE_LIST_DRAWER:
      return { ...initialState };

    default: {
      return state;
    }
  }
};

export default employeeListReducer;
