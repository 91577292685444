import { Typography } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { I18n } from 'utils/i18n';
import connector from './connector';

const ChatHeaderStatus = props => {
  const { lastSeen, isOnline, typing, isDirect, loading, userCount } = props;
  const getStatus = () => {
    if (loading) {
      return I18n.t('ChatHeaderStatus.Loading');
    }
    if (typing) {
      return I18n.t('ChatHeaderStatus.Typing', {
        name: typing,
      });
    }
    if (!isDirect) {
      if (userCount < 1) {
        return null;
      }
      return I18n.t('ChatHeaderStatus.N_Members', {
        num: userCount,
      });
    }
    if (isOnline) {
      return I18n.t('ChatHeaderStatus.Online');
    }
    if (lastSeen) {
      return I18n.t('ChatHeaderStatus.Last_seen', {
        ago: moment.unix(lastSeen).calendar(null, {
          sameDay: 'LT',
          lastDay: `[${I18n.t('ChatHeaderStatus.Yesterday')} ${I18n.t('ChatHeaderStatus.at')}] LT`,
          lastWeek: `dddd [${I18n.t('ChatHeaderStatus.at')}] LT`,
          sameElse: 'LL',
        }),
      });
    }
    return null;
  };
  const status = getStatus();
  return status ? (
    <Typography variant="caption" display="block">
      {status}
    </Typography>
  ) : null;
};

ChatHeaderStatus.propTypes = {
  userCount: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  isDirect: PropTypes.bool,
  lastSeen: PropTypes.number,
  isOnline: PropTypes.bool,
  typing: PropTypes.bool,
};

ChatHeaderStatus.defaultProps = {
  typing: false,
  loading: false,
  isDirect: false,
  lastSeen: null,
  isOnline: false,
};

export default connector(ChatHeaderStatus);
