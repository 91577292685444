/* eslint-disable react/jsx-props-no-spreading */
import { SvgIcon, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import UploadIcon from 'assets/icons/upload.svg';
import classNames from 'classnames';
import Loading from 'components/common/Loading';
import { bool, func, object, string } from 'prop-types';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { I18n } from 'utils/i18n';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 210,
    width: '100%',
  },
  icon: {
    color: theme.palette.greyBlue,
    fontSize: '40px',
  },
  input: {
    display: 'none',
  },
  button: {
    outline: 'none',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.white,
    border: `solid 1px ${theme.palette.secondary.light}`,
    padding: '5px 15px',
    cursor: 'pointer',
    borderRadius: '5px',
    fontSize: '16px',
  },
  dropzone: {
    borderRadius: 4,
    padding: '50px 60px 50px 60px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.text.primary,
    cursor: 'pointer',
    outlineColor: theme.palette.primary.main,
    width: '100%',
    height: '100%',
    border: '2px solid transparent',
  },
  activeDropzone: {
    background: theme.palette.primary.lighter,
    borderColor: theme.palette.primary.main,
  },
  rejectDropzone: {
    background: theme.palette.dangerBack,
    borderColor: theme.palette.danger,
  },
});

class DropzoneBox extends Component {
  dropzoneRef = React.createRef();

  render() {
    const { classes, onDrop, loading, error, accept } = this.props;
    return (
      <div className={classes.root}>
        {loading ? (
          <Loading size={80} />
        ) : (
          <Dropzone
            accept={accept}
            ref={this.dropzoneRef}
            onDrop={onDrop}
            disableClick
            onClick={e => e.preventDefault()}
            multiple={false}
          >
            {({
              getRootProps,
              getInputProps,
              isDragActive,
              draggedFiles,
              isDragAccept,
              isDragReject,
            }) => (
              <div
                {...getRootProps()}
                className={classNames(classes.dropzone, {
                  [classes.activeDropzone]: isDragAccept,
                  [classes.rejectDropzone]: isDragReject,
                })}
              >
                <input {...getInputProps()} />
                {isDragAccept && <p>{I18n.t('AcknowledgementCreate.Drop the file to upload')}</p>}
                {isDragReject && <p>{I18n.t('AcknowledgementCreate.File type not accepted')}</p>}
                {!isDragActive && (
                  <>
                    <SvgIcon className={classes.icon}>
                      <UploadIcon />
                    </SvgIcon>
                    <p>{I18n.t('AcknowledgementCreate.Drag & drop file here or')}</p>
                    <button type="button" className={classes.button}>
                      {I18n.t('AcknowledgementCreate.Browse file')}
                    </button>
                  </>
                )}
              </div>
            )}
          </Dropzone>
        )}
        {error && (
          <Typography color="error" align="center">
            {I18n.t('Something went wrong with sending data')}
          </Typography>
        )}
      </div>
    );
  }
}

DropzoneBox.propTypes = {
  classes: object.isRequired,
  onDrop: func.isRequired,
  loading: bool.isRequired,
  error: bool.isRequired,
  accept: string,
};

DropzoneBox.defaultProps = {
  accept: 'application/pdf',
};

export default withStyles(styles)(DropzoneBox);
