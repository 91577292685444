import Box from '@mui/material/Box';
import { node, object, string } from 'prop-types';
import React from 'react';

const classes = {
  picture: {
    display: 'block',
    margin: '0 auto',
    cursor: 'pointer',
    borderRadius: 0,
    minHeight: 150,
    minWidth: 150,
    maxHeight: 300,
    maxWidth: 300,
    width: '100%',
    objectFit: 'cover',
  },
};

const Image = ({ src, sx, fallback = null, ...rest }) => {
  if (!src?.startsWith('https://')) {
    return fallback;
  }

  return (
    <Box
      component="img"
      alt="preview"
      className={classes.picture}
      src={src}
      sx={{
        ...classes.picture,
        ...sx,
      }}
      {...rest}
    />
  );
};

Image.propTypes = {
  src: string,
  sx: object,
  fallback: node,
};

Image.defaultProps = {
  src: null,
  sx: {},
  fallback: null,
};

export default Image;
