import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import media from 'store/app/ui/media/action';
import populateChannels from 'store/selectors/populateChannels';

const mapStateToProps = state => ({
  chats: populateChannels(state),
  documents: state.media.documents,
  channelId: state.media.channelId,
  total: state.media.documents_total,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    media: bindActionCreators(media, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
