/* eslint-disable react/destructuring-assignment */
import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Loading from 'components/common/Loading';
import PDFPreviewLazy from 'components/common/PDFPreviewLazy';
import { any, object } from 'prop-types';
import React from 'react';
import { I18n } from 'utils/i18n';

const styles = theme => ({
  root: {
    margin: '6px',
    backgroundColor: '#f2f4f7',
    minWidth: '500px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'hidden',
  },

  pdf: {
    width: 189,
    height: 250,
    overflow: 'hidden',
  },
  page: {
    padding: 10,
    width: 189,
  },

  loading: {
    marginTop: 20,
  },

  mobile: {
    backgroundImage: 'url(/images/iPhone.png)',
    backgroundPosition: 'center 50px',
    backgroundSize: '220px 420px',
    backgroundRepeat: 'no-repeat',
    minHeight: '368px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },

  text: {
    paddingTop: 15,
    paddingLeft: 15,
    fontSize: 13,
    textTransform: 'uppercase',
    fontWeight: 500,
    color: theme.palette.secondary.main,
  },

  mobileContainer: {
    width: 189,
    height: 267,
  },
});

const PdfPreview = ({ classes, src }) => (
  <div className={classes.root}>
    <Typography className={classes.text}>
      {I18n.t('AcknowledgementCreate.This is a message preview')}
    </Typography>
    <div className={classes.mobile}>
      <div className={classes.mobileContainer}>
        <PDFPreviewLazy
          file={src}
          docClassName={classes.pdf}
          loading={<Loading classes={{ root: classes.loading }} size={60} />}
          pageClassName={classes.page}
          width={169}
        />
      </div>
    </div>
  </div>
);

PdfPreview.propTypes = {
  classes: object.isRequired,
  src: any.isRequired,
};

export default withStyles(styles)(PdfPreview);
