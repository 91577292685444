import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import EmployeeAvatar from 'components/@home/EmployeeAvatar';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import { arrayOf, func, object, oneOfType, shape, string } from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import Socket from 'services/Socket';
import channelShape from 'shapes/channel';
import employeeShape from 'shapes/employee';
import { useLazyListVisibleQuery } from 'store/app/entities/EmployeesApiSlice';
import useDebouncePromise from 'utils/useDebouncePromise';
import connector from './connector';

const debounceTypingActive = throttle(
  (authEmployee, channel, typing) => {
    if (!authEmployee || !channel || typing.current) return;
    const data = { user: authEmployee.user_id, channel: channel._id };
    Socket.emit('typing', { ...data, status: true });
    // eslint-disable-next-line no-param-reassign
    typing.current = true;
  },
  2000,
  { leading: true, maxWait: 2000, trailing: false },
);

// Fire after 2,5s of inactivity
const debounceTypingInactive = debounce(
  (authEmployee, channel, typing) => {
    if (!authEmployee || !channel || !typing.current) return;
    const data = { user: authEmployee.user_id, channel: channel._id };
    Socket.emit('typing', { ...data, status: false });
    // eslint-disable-next-line no-param-reassign
    typing.current = false;
  },
  2500,
  { leading: false, trailing: true },
);

const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: 72,
    padding: '6px 0 7px',
    paddingLeft: 15,
  },
  mentions: {
    position: 'relative',
    width: '100%',
    color: theme.typography.body1.color,
    cursor: 'text',
    fontSize: '0.8125rem',
    boxSizing: 'border-box',
    fontFamily: 'inherit',
    fontWeight: 400,
    lineHeight: '1.1876em',
  },
  mentions__input: {
    lineHeight: '1.3em',
    padding: 0,
    resize: 'none',
    font: 'inherit',
    color: 'currentColor',
    width: '100%',
    border: '1px solid transparent',
    height: '1.1876em',
    margin: 0,
    display: 'block',
    minWidth: 0,
    '-webkit-tap-highlight-color': 'transparent',
    '&:focus': {
      outline: 0,
    },
    '&::placeholder': {
      color: 'currentColor',
      opacity: 0.42,
      transition: 'opacity 200ms cubic-bezier(0.4,0,0.2,1) 0ms',
    },
  },
  mentions__suggestions: {
    position: 'static !important',
  },
  mentions__highlighter: {
    zIndex: 1,
    pointerEvents: 'none',
    lineHeight: '1.3em',
  },
  mention: {
    color: theme.palette.mention,
    background: theme.palette.mentionBack,
    borderRadius: 3,
  },
  user: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px',
    background: theme.palette.background.paper,
  },
  focused: {
    background: theme.palette.primary.extraLight,
  },
  userName: {
    marginLeft: 10,
  },
}));

const ChatInput = ({
  authEmployee,
  channel,
  onKeyPress,
  name,
  onChange,
  suggestionsPortal,
  onPaste,
  placeholder,
  value,
  inputRef,
}) => {
  const classes = useStyles();
  const typing = useRef(false);
  const [triggerListVisibleQuery] = useLazyListVisibleQuery();

  const listVisibleQuery = useDebouncePromise(
    useCallback(
      (search, cb) =>
        triggerListVisibleQuery({ search })
          .unwrap()
          .then(result => {
            cb(
              result.employees.map(e => {
                const display = [e.firstName, e.surName].filter(n => n).join(' ');
                return {
                  ...e,
                  id: `${e._id}-${e.user?._id || ''}`,
                  name: display,
                  user: e.user,
                  display,
                };
              }),
            );
          }),
      [triggerListVisibleQuery],
    ),
    300,
  );

  const handleKeyDown = useCallback(
    e => {
      debounceTypingActive(authEmployee, channel, typing);
      debounceTypingInactive(authEmployee, channel, typing);
      onKeyPress(e);
    },
    [authEmployee, channel, onKeyPress],
  );

  const handleOnChange = (event, markup, text, mentions) => {
    onChange({ target: { ...event.target, value: { markup, text, mentions }, name } });
  };

  const isVisible = (ele, container) => {
    const eleTop = ele.offsetTop;
    const eleBottom = eleTop + ele.clientHeight;

    const containerTop = container.scrollTop;
    const containerBottom = containerTop + container.clientHeight;

    return eleTop >= containerTop && eleBottom <= containerBottom;
  };

  const renderUserSuggestion = (suggestion, query, highlightedDisplay, index, focused) => {
    let elemRef;
    setTimeout(() => {
      if (elemRef && focused) {
        const visible = isVisible(elemRef, suggestionsPortal.current);
        if (!visible) {
          elemRef.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
        }
      }
    }, 10);
    return (
      <div
        ref={r => {
          elemRef = r;
        }}
        className={classNames({ [classes.user]: true, [classes.focused]: focused })}
      >
        <EmployeeAvatar employee={suggestion} />
        <Typography className={classes.userName}>{suggestion.name}</Typography>
      </div>
    );
  };

  useEffect(() => {
    if (inputRef.current) {
      setTimeout(inputRef.current.focus(), 100);
    }
  }, [inputRef, channel?._id]);

  return (
    <div className={classes.root}>
      <MentionsInput
        placeholder={placeholder}
        className="mentions"
        classNames={classes}
        name={name}
        value={value.markup || ''}
        autoComplete="off"
        fullWidth
        multiline
        onKeyDown={handleKeyDown}
        onChange={handleOnChange}
        onPaste={onPaste}
        inputRef={inputRef}
        suggestionsPortalHost={suggestionsPortal.current}
        autoFocus
        allowSpaceInQuery
      >
        <Mention
          trigger="@"
          data={listVisibleQuery}
          classNames={classes}
          className="mention"
          displayTransform={(id, display) => `@${display}`}
          renderSuggestion={renderUserSuggestion}
        />
      </MentionsInput>
    </div>
  );
};

ChatInput.propTypes = {
  placeholder: string.isRequired,
  name: string.isRequired,
  value: string.isRequired,
  authEmployee: employeeShape,
  channel: channelShape.isRequired,
  onChange: func.isRequired,
  onPaste: func.isRequired,
  onKeyPress: func.isRequired,
  inputRef: oneOfType([func, object]),
  suggestionsPortal: object.isRequired,
};

ChatInput.defaultProps = {
  inputRef: () => {},
  authEmployee: null,
};

export default connector(ChatInput);
