/** @flow */
import { ArrowBack, Lock } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigation } from 'react-router';
import { useLocation, useNavigate } from 'react-router-dom';

const styles = {
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  iframe: {
    width: '100%',
    height: '100%',
    border: 'none',
  },
  navBar: {
    width: '100vw',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px',
    borderBottomColor: 'divider',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    overflow: 'hidden',
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  },
  lock: {
    fontSize: 8,
    verticalAlign: 'middle',
  },
  right: {
    width: 40,
  },
};

const LauncherEmbedded = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const url = useMemo(
    () => (location.state?.url ? new URL(location.state?.url) : null),
    [location],
  );
  const handleBack = useCallback(() => navigate(-1), [navigate]);
  const [title, setTitle] = useState('');
  const iframeRef = useRef(null);

  useEffect(() => {
    const iframe = iframeRef.current;
    const updateTitle = () => {
      setTitle(iframe.contentDocument.title);
    };
    iframe?.addEventListener('load', updateTitle);
    return () => {
      iframe?.removeEventListener('load', updateTitle);
    };
  }, [iframeRef]);

  useEffect(() => {
    if (!url) {
      navigate('/home/launcher');
    }
  }, [navigate, url]);

  if (!url) {
    return null;
  }
  return (
    <Box sx={styles.root}>
      <Box sx={styles.navBar}>
        <IconButton onClick={handleBack}>
          <ArrowBack />
        </IconButton>
        <Box sx={styles.titleWrapper}>
          <Typography sx={styles.title}>{title || url.toString()}</Typography>
          <Typography variant="caption">
            <Lock sx={styles.lock} />
            {url.host}
          </Typography>
        </Box>
        <Box sx={styles.right} />
      </Box>
      <Box ref={iframeRef} src={url} sx={styles.iframe} component="iframe" allowFullScreen />
    </Box>
  );
};

export default LauncherEmbedded;
