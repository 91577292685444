import LauncherCard from 'components/@home/@launcher/LauncherCard';
import React from 'react';
import { useOutletContext } from 'react-router';

const LauncherIndex = () => {
  const { links } = useOutletContext();
  return links.map(link => <LauncherCard key={link._id} link={link} />);
};

export default LauncherIndex;
