/**
 * @flow
 */
import useKeys from 'components/@whistleblower-admin/useKeys';
import React from 'react';

const WhistleblowerContext = React.createContext({});

export const WhistleblowerProvider = ({ children, me }: { children: React.Node; me: Object }) => {
  const { publicKey, privateKey } = useKeys();
  return (
    <WhistleblowerContext.Provider value={{ publicKey, privateKey, me }}>
      {children}
    </WhistleblowerContext.Provider>
  );
};
export const useWhistleblower = () => React.useContext(WhistleblowerContext);

export const READ = 1;
export const EDIT = 2;
export const ADMIN = 3;

export const useCanEdit = item => {
  const { me } = useWhistleblower();
  return me.permissions?.[item] >= EDIT;
};

export const useCanAdmin = item => {
  const { me } = useWhistleblower();
  return me.permissions?.[item] >= ADMIN;
};

export default WhistleblowerContext;
