export const OPEN_APP_DRAWER = 'OPEN_APP_DRAWER';
export const CLOSE_APP_DRAWER = 'CLOSE_APP_DRAWER';

const open = app => ({
  type: OPEN_APP_DRAWER,
  payload: app,
});

const close = () => ({
  type: CLOSE_APP_DRAWER,
});

export default { open, close };
