/* eslint-disable class-methods-use-this */
import Http from 'services/Http';

class ChatFlow {
  async find(id) {
    const { data } = await Http.get(`/v1/chat-flow/${id}`);
    return data.chatFlow;
  }

  async list() {
    const { data } = await Http.get('/v1/chat-flow');
    return data;
  }

  async create(form) {
    const response = await Http.post('/v1/chat-flow', form);
    return response.data.chatFlow;
  }

  async update(id, form) {
    const response = await Http.patch(`/v1/chat-flow/${id}`, form);
    return response.data.chatFlow;
  }

  async destroy(id) {
    const { data } = await Http.delete(`/v1/chat-flow/${id}`);
    return data;
  }

  async message(id, text, pageId, context) {
    const { data } = await Http.post(`/v1/chat-flow/${id}/message`, { text, pageId, context });
    return data;
  }

  async exportFlow(id) {
    const { data } = await Http.get(`/v1/chat-flow/${id}/export`);
    return data;
  }

  async importFlow(file) {
    const formData = new FormData();
    formData.append('chatFlow', file);
    const { data } = await Http.post(`/v1/chat-flow/import`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  }

  async train(id) {
    const { data } = await Http.patch(`/v1/chat-flow/${id}/train`);
    return data;
  }

  async conversationRestart(id) {
    const { data } = await Http.patch(`/v1/chat-flow/conversation/${id}/restart`);
    return data;
  }
}

export default new ChatFlow();
