import {
  LOAD_TIME_ZONES_PENDING,
  LOAD_TIME_ZONES_FULFILLED,
  LOAD_TIME_ZONES_REJECTED,
} from './action';

const initialState = {
  timeZones: [],
  loading: false,
};

const timeZonesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_TIME_ZONES_PENDING:
      return {
        ...state,
        loading: true,
      };

    case LOAD_TIME_ZONES_FULFILLED:
      return {
        ...state,
        loading: false,
        timeZones: payload,
      };

    case LOAD_TIME_ZONES_REJECTED:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default timeZonesReducer;
