/* eslint-disable react/no-danger */
import { FormControl } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import img0 from 'components/@welcome/OnboardingScene/images/onboarding-mock-0.png';
import deferRender from 'defer-render-hoc';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'utils/i18n';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IS_DEV } from 'services/constants';
import Cookie from 'services/Cookie';
import authActions from 'store/app/auth/action';
import companiesActions from 'store/app/entities/companies/action';
import employeesActions from 'store/app/entities/employees/action';
import authCompany from 'store/selectors/authCompany';
import authEmployee from 'store/selectors/authEmployee';
import useActions from 'utils/useActions';
import IAcceptTermsButton from './IAcceptTermsButton';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  termsBlock: {
    width: 580,
  },
  actions: {
    marginTop: '2em',
    paddingBottom: '2em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    width: '580px',
    minHeight: '720px',
    maxWidth: '100%',
    maxHeight: '720px',
    margin: '1em 0',
    display: 'flex',
    flexDirection: 'column',
    boxShadow:
      '0px 11px 15px -7px rgba(0,0,0,0.05), ' +
      '0px 24px 38px 3px rgba(0,0,0,0.02), ' +
      '0px 9px 46px 8px rgba(0,0,0,0.01)',
    flexGrow: 1,
    overflow: 'hidden',
  },
  imgWrapper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1.75em',
  },
  img: {
    backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.0) 70%, rgba(0, 0, 0, 0.4)), url(${img0})`,
    flexGrow: 1,
    minHeight: '200px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    width: '100%',
  },
  bottom: {
    padding: '0 3em',
    marginTop: 24,
  },
  title: {
    margin: '1em 0 0.5em 0',
  },
  description: {
    color: theme.palette.text.secondary,
    fontWeight: 'normal',
    textAlign: 'left',
    '& a': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  logo: {
    maxHeight: 60,
    maxWidth: 200,
    textAlign: 'center',
    marginBottom: 20,
  },
  input: {
    alignItems: 'baseline !important',
    marginBottom: 10,
  },
  check: {
    position: 'relative',
    top: '0.35rem',
  },
}));

const LegalScene = () => {
  const classes = useStyles();
  const [auth, companies, employees] = useActions([
    authActions,
    companiesActions,
    employeesActions,
  ]);
  const actions = { auth, companies, employees };
  const company = useSelector(authCompany);
  const employee = useSelector(authEmployee);
  const location = useLocation();
  const navigate = useNavigate();
  const [accepted, setAccepted] = useState(location.state);
  const [firstName, setFirstName] = useState(employee?.firstName);
  const [surName, setSurName] = useState(employee?.surName);

  const onCheckboxChange = () => {
    setAccepted(a => !a);
  };

  const onButtonClick = () => {
    if (!accepted) {
      return;
    }
    const shortCompany = Cookie.get('company');

    Cookie.set(
      'company',
      {
        ...shortCompany,
        isAcceptTerms: true,
      },
      { secure: !IS_DEV },
    );

    if (employee && (!employee.firstName || !employee.surName)) {
      actions.employees.update(employee._id, {
        firstName,
        surName,
      });
    }
    actions.companies.acceptTerms(company._id);

    navigate('/home/messages');
  };

  const changeName = func => event => {
    func(event.target.value);
  };

  const canSubmit = accepted && firstName && surName;

  const { name, logo, onBoardingImage } = company;
  // const classes = makeStyles(styles)(props);
  return (
    <section className={classes.root}>
      <Paper className={classes.paper} elevation={24}>
        <div className={classes.imgWrapper}>
          <div
            className={classes.img}
            style={
              onBoardingImage && {
                backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.0) 70%, rgba(0, 0, 0, 0.4)), url(${onBoardingImage})`,
              }
            }
          >
            {logo && <img className={classes.logo} src={logo} alt={name} />}
          </div>
        </div>
        <section className={classes.bottom}>
          <Typography className={classes.title} gutterBottom variant="h5">
            {employee && !employee.firstName
              ? I18n.t('CompanyTerms.Whats your name')
              : I18n.t('CompanyTerms.title', { name })}
          </Typography>
          {employee && !employee.firstName && (
            <div>
              <FormControl fullWidth className={classes.input}>
                <TextField
                  className={classes.field}
                  name="firstName"
                  value={firstName}
                  type="text"
                  onChange={changeName(setFirstName)}
                  placeholder={`${I18n.t('CompanyTerms.First name')}*`}
                  fullWidth
                  required
                />
              </FormControl>
              <FormControl fullWidth className={classes.input}>
                <TextField
                  className={classes.field}
                  name="firstName"
                  value={surName}
                  type="text"
                  onChange={changeName(setSurName)}
                  placeholder={`${I18n.t('CompanyTerms.Last name')}*`}
                  fullWidth
                  required
                />
              </FormControl>
            </div>
          )}
          <FormControlLabel
            className={classes.input}
            control={
              <Checkbox
                className={classes.check}
                name="terms"
                checked={!!accepted}
                onChange={onCheckboxChange}
                value="terms"
              />
            }
            label={
              <Typography variant="body1" className={classes.description}>
                {I18n.t('CompanyTerms.Accept_Terms')}
                <Link to="/welcome/legal/terms">{I18n.t('CompanyTerms.Terms_And_Conditions')}</Link>
                {I18n.t('CompanyTerms.and')}
                <Link to="/welcome/legal/privacy">
                  {I18n.t('CompanyTerms.Privacy_Policy', { name })}
                </Link>
              </Typography>
            }
          />
        </section>
        <div className={classes.actions}>
          <IAcceptTermsButton disabled={!canSubmit} onClick={onButtonClick} />
        </div>
      </Paper>
    </section>
  );
};
export default deferRender(LegalScene);
