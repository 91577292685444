import AuthGate from 'components/gates/AuthGate';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import authCompany from 'store/selectors/authCompany';
import meStatus from 'store/selectors/meStatus';

const TermsAcceptedGate = ({ component }) => {
  const company = useSelector(authCompany);
  const { isUninitialized } = useSelector(meStatus);

  if (!company) {
    return null;
  }
  if (company.isAcceptTerms === false) {
    return <Navigate replace to="/welcome" />;
  }

  return !isUninitialized && <AuthGate component={component} />;
};

TermsAcceptedGate.propTypes = {
  component: PropTypes.elementType.isRequired,
};
export default TermsAcceptedGate;
