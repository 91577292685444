import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import countryCodes from 'store/app/entities/config/countryCodes/action';

const mapStateToProps = state => {
  const { locale } = state.i18n;
  return {
    locale,
    countryCodes: state.config.countryCodes,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    config: {
      countryCodes: bindActionCreators(countryCodes, dispatch),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
