import loginFormReducer from './form/reducer';
import invitationDialogReducer from './invitationDialog/reducer';

import {
  SEND_LOGIN_SMS_FULFILLED,
  SEND_LOGIN_SMS_PENDING,
  SEND_LOGIN_SMS_REJECTED,
  SET_USER_PHONE,
} from './action';

const initialState = {
  phone: null,
  form: {
    prefix: '',
    phone: '',
    terms: false,
    content: {},
  },
};

const loginReducer = (state = initialState, { type, payload, meta }) => {
  switch (type) {
    case SET_USER_PHONE:
      return {
        ...state,
        phone: payload,
      };

    case SEND_LOGIN_SMS_PENDING:
      return {
        ...state,
        loading: true,
      };

    case SEND_LOGIN_SMS_FULFILLED:
      return {
        ...state,
        authRequestUser: payload.data.user,
        confirmToken: payload.data.confirmToken,
        deviceNames: payload.data.deviceNames,
        loading: false,
      };

    case SEND_LOGIN_SMS_REJECTED:
      return {
        ...state,
        rejected: true,
        errors: payload,
      };

    default: {
      return {
        ...state,
        invitationDialog: invitationDialogReducer(state.invitationDialog, { type, payload, meta }),
        form: loginFormReducer(state.form, { type, payload, meta }),
      };
    }
  }
};

export default loginReducer;
