let pdfjs;

const getPdfjs = async () => {
  if (pdfjs) {
    return pdfjs;
  }
  await import('react-pdf/dist/cjs/Page/TextLayer.css');
  ({ pdfjs } = await import('react-pdf'));
  if (typeof window !== 'undefined' && 'Worker' in window) {
    console.log('pdfjs', import.meta.url);
    pdfjs.GlobalWorkerOptions.workerSrc = new URL(
      'pdfjs-dist/build/pdf.worker.js',
      import.meta.url,
    ).toString();
  }
  console.log('pdfjs', pdfjs.GlobalWorkerOptions.workerSrc);
  return pdfjs;
};

export default getPdfjs;
