import { withFormik } from 'formik';
import { I18n } from 'utils/i18n';

class FormSubmit {
  constructor(values, formikBag) {
    this.formikBag = formikBag;
    this.values = values;
  }

  assembleForm = () => {
    const {
      values: {
        intent,
        condition,
        transition,
        replies,
        utteranceParameter,
        handoffTo,
        transitionType,
        outputParameters,
        repliesSuggestions,
        webhook,
        enableWebhook,
        emailTo,
        enableEmailTo,
      },
      formikBag,
    } = this;
    const {
      props: {
        flow,
        context: { current },
      },
    } = formikBag;
    const page = flow.pages.find(p => p._id === current.page);
    const newRoutes = page.routes.map(r => ({ ...r }));
    const newRoute = { intent, condition, replies, utteranceParameter, webhook, emailTo };
    newRoute.outputParameters = outputParameters.filter(o => o.id);
    if (transitionType === 'page') {
      newRoute.handoffTo = null;
      if (transition._id === 'new') {
        newRoute.transition = { name: transition.name };
      } else {
        newRoute.transition = transition._id;
      }
    } else if (transitionType === 'handoff') {
      newRoute.transition = null;
      newRoute.handoffTo = handoffTo._id;
    }
    newRoutes.forEach((r, idx) => {
      if (r.intent && typeof r.intent === 'object') {
        newRoutes[idx].intent = r.intent._id;
      }
      if (r.webhook && typeof r.webhook === 'object') {
        newRoutes[idx].webhook = r.webhook._id;
      }
      newRoutes[idx].webhook = newRoutes[idx].webhook || null;
      // eslint-disable-next-line no-param-reassign
      delete r.context;
      if (r.transition && typeof r.transition === 'object') {
        newRoutes[idx].transition = r.transition._id;
      }
      if (r.handoffTo?._id) {
        newRoutes[idx].handoffTo = r.handoffTo._id;
      }
      // eslint-disable-next-line no-param-reassign
      r.replies = r.replies.map(rep => ({
        ...rep,
        attachments: rep.attachments?.map(a => (typeof a === 'string' ? a : a._id)),
      }));
    });

    if (intent._id === 'new') {
      newRoute.intent = { name: intent.name };
    } else {
      newRoute.intent = intent._id;
    }
    newRoute.replies = newRoute.replies
      .filter(r => r.text)
      .map(r => ({
        ...r,
        attachments: r.attachments?.map(a => (a?._id ? a._id : a)) || [],
      }));

    const lastMessageIdx = newRoute.replies.length - 1;
    if (lastMessageIdx > -1) {
      newRoute.replies[lastMessageIdx].suggestions = repliesSuggestions;
    }

    if (newRoute.webhook && typeof newRoute.webhook === 'object') {
      newRoute.webhook = newRoute.webhook._id;
    }
    newRoute.webhook = newRoute.webhook || null;
    if (enableWebhook.length === 0) {
      newRoute.webhook = null;
    }
    newRoute.emailTo = newRoute.emailTo || null;
    if (enableEmailTo.length === 0) {
      newRoute.emailTo = null;
    }
    if (current.route === 'new') {
      newRoutes.push(newRoute);
    } else {
      const idx = newRoutes.findIndex(r => r._id === current.route);
      newRoutes[idx] = { ...newRoute, _id: newRoutes[idx]._id };
    }
    return { routes: newRoutes };
  };

  sendRequest = async form => {
    let ret;
    const {
      flow: { _id },
      context: {
        current: { page },
      },
      actions,
    } = this.formikBag.props;
    if (_id === 'new') {
      const newForm = { ...form };
      delete newForm._id;
      ret = await actions.chatFlowPages.create(_id, form);
    } else {
      ret = await actions.chatFlowPages.update(_id, page, form);
    }
    actions.chatFlows.find(_id);
    return ret;
  };

  displayErrors = () => {
    const { setErrors } = this.formikBag;
    setErrors({ server: I18n.t('Something went wrong with sending data') });
  };
}

const chatFlowRouteFormik = withFormik({
  enableReinitialize: true,
  validateOnChange: true,
  mapPropsToValues: ({ flow, context, selectedIntent, selectedWebhook, teams }) => {
    const { route: routeId, page: pageId } = context.current;
    const page = flow?.pages?.find(p => p._id === pageId);
    const route = page?.routes.find(r => r._id === routeId);
    const transition = flow?.pages?.find(p => p._id === route?.transition);
    const handoffTo = route?.handoffTo?._id ? route.handoffTo : teams[route?.handoffTo];
    const lastMessageIdx = route?.replies.length - 1;
    const repliesSuggestions =
      lastMessageIdx > -1 ? route?.replies[lastMessageIdx].suggestions : [];
    const enableWebhook = route?.webhook || selectedWebhook ? ['on'] : [];
    const enableEmailTo = route?.emailTo ? ['on'] : [];
    return {
      _id: route?._id || 'new',
      intent: route?.intent || selectedIntent || {},
      condition: route?.condition || '',
      transition: transition || {},
      replies: route?.replies || [],
      utteranceParameter: route?.utteranceParameter || route?.context || '',
      outputParameters: route?.outputParameters || [],
      handoffTo: handoffTo || {},
      transitionType: handoffTo ? 'handoff' : 'page',
      repliesSuggestions,
      webhook: route?.webhook || selectedWebhook || '',
      enableWebhook,
      emailTo: route?.emailTo || [],
      enableEmailTo,
    };
  },
  handleSubmit: async (values, formikBag) => {
    const {
      props: { context },
      setSubmitting,
      resetForm,
    } = formikBag;

    const handler = new FormSubmit(values, formikBag);
    const form = handler.assembleForm();

    try {
      await handler.sendRequest(form);
      context.clear();
      resetForm();
    } catch (err) {
      setSubmitting(false);
      handler.displayErrors(err);
    }
    return true;
  },
});

export default chatFlowRouteFormik;
