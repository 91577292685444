export const OPEN_HR_BOT_DRAWER = 'OPEN_HR_BOT_DRAWER';
export const CLOSE_HR_BOT_DRAWER = 'CLOSE_HR_BOT_DRAWER';

const open = data => ({
  type: OPEN_HR_BOT_DRAWER,
  payload: data,
});

const close = () => ({
  type: CLOSE_HR_BOT_DRAWER,
});

export default { open, close };
