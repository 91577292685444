import React, { Component } from 'react';
import { bool, func, object } from 'prop-types';
import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'formik';
import { I18n } from 'utils/i18n';

const styles = theme => ({
  buttons: {
    display: 'flex',
    justifySelf: 'flex-end',
    padding: '20px 0',
  },
  cancel: {
    color: theme.palette.primary.ultraDark,
    marginRight: 10,
  },
});

class ActionButtons extends Component {
  handleReset = e => {
    const { onReset, formik } = this.props;
    onReset();
    formik.handleReset(e);
  };

  render() {
    const { classes, formik, isEdit } = this.props;
    return (
      <div className={classes.buttons}>
        <Button
          variant="outlined"
          onClick={this.handleReset}
          disabled={formik.isSubmitting}
          className={classes.cancel}
          type="reset"
          color="secondary"
        >
          {I18n.t('ChannelDrawer.Cancel')}
        </Button>
        <Button variant="contained" disabled={formik.isSubmitting} color="primary" type="submit">
          {formik.isSubmitting
            ? `${
                isEdit
                  ? I18n.t('ChannelDrawer.Updating channel')
                  : I18n.t('ChannelDrawer.Creating channel')
              }...`
            : `${
                isEdit
                  ? I18n.t('ChannelDrawer.Update channel')
                  : I18n.t('ChannelDrawer.Create channel')
              }`}
        </Button>
      </div>
    );
  }
}

ActionButtons.propTypes = {
  classes: object.isRequired,
  formik: object.isRequired,
  onReset: func.isRequired,
  isEdit: bool.isRequired,
};

export default withStyles(styles)(connect(ActionButtons));
