/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import { IconButton, SvgIcon, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowForwardIcon from 'assets/icons/arrow-foward.svg';
import classNames from 'classnames';
import MessageIcon from 'components/common/MessageIcon';
import { bool } from 'prop-types';
import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import attachmentShape from 'shapes/attachment';
import documentModal from 'store/app/ui/modals/document/action';
import filesize from 'utils/filesize';
import connector from './connector';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 0,
    minWidth: 250,
    width: '100%',
    maxWidth: 500,
    display: 'flex',
    alignItems: 'center',
    padding: 10,
    color: theme.palette.primary.main,
  },
  fileIcon: {
    fontSize: 36,
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  title: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '1.38',
    letterSpacing: '-0.4px',
    color: 'inherit',
  },
  subheading: {
    fontSize: 12,
    color: 'inherit',
  },
  description: {
    flexGrow: 1,
    paddingLeft: 10,
    paddingRight: 20,
    color: 'inherit',
    cursor: 'pointer',
  },
  forwardIcon: {
    cursor: 'pointer',
    fontSize: 12,
  },
}));

const FileThumbnail = ({ data, right }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const openDocument = useCallback(() => {
    if (data.httpLink) {
      dispatch(documentModal.open(data.httpLink, null, data.name, false, data.mimeType));
    }
  }, [data.httpLink, data.mimeType, data.name, dispatch]);

  return (
    <div
      className={classNames({
        [classes.root]: true,
        [classes.right]: right,
      })}
    >
      <IconButton onClick={openDocument} size="large">
        <SvgIcon
          className={classNames({
            [classes.fileIcon]: true,
            [classes.fileIconRight]: right,
          })}
        >
          <MessageIcon message={{ attachments: [data] }} />
        </SvgIcon>
      </IconButton>
      <div className={classes.description} onClick={openDocument}>
        <Typography className={classes.title}>{data.name}</Typography>
        <Typography className={classes.subheading}>{filesize(data.size || 0)}</Typography>
      </div>
      {data.httpLink && (
        <SvgIcon onClick={openDocument} className={classes.forwardIcon}>
          <ArrowForwardIcon />
        </SvgIcon>
      )}
    </div>
  );
};

FileThumbnail.propTypes = {
  right: bool.isRequired,
  data: attachmentShape.isRequired,
};

export default connector(memo(FileThumbnail));
