/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
import withStyles from '@mui/styles/withStyles';
import { object } from 'prop-types';
import React, { Component } from 'react';
import ProgressiveImage from 'react-progressive-image';
import attachmentShape from 'shapes/attachment';
import connector from './connector';

const styles = {
  root: {
    width: '100%',
    height: '100%',
    minHeight: 100,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    cursor: 'pointer',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      aspectRatio: '1/1',
    },
  },
  loading: {
    filter: 'blur(4px)',
    transform: 'scale(1.05)',
  },
};

class PictureTile extends Component {
  preview = () => {
    const { actions, picture } = this.props;
    actions.pictureModal.open(picture.httpLink);
  };

  render() {
    const { classes, picture } = this.props;
    return (
      <div onClick={this.preview} className={classes.root}>
        <ProgressiveImage
          onClick={this.openPicture}
          src={picture.meta.thumb || picture.httpLink}
          placeholder={picture.meta.placeholder}
        >
          {(src, loading) => (
            // eslint-disable-next-line jsx-a11y/alt-text
            <img className={loading ? classes.loading : classes.loaded} src={src} />
          )}
        </ProgressiveImage>
      </div>
    );
  }
}

PictureTile.propTypes = {
  classes: object.isRequired,
  picture: attachmentShape.isRequired,
  actions: object.isRequired,
};

export default withStyles(styles)(connector(PictureTile));
