import { ClickAwayListener, Fab, Modal } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import CloseIcon from 'mdi-react/CloseIcon';
import { bool, func, node, object, string } from 'prop-types';
import React from 'react';

function getModalStyle() {
  return {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  };
}

const styles = theme => ({
  paper: {},
  relativeContainer: {
    borderRadius: 3,
    backgroundColor: theme.palette.background.paper,
    maxWidth: '90vw',
    boxShadow: theme.shadows[5],
    '&:focus': {
      outline: 'none',
    },
    position: 'relative',
    padding: 5,
    [theme.breakpoints.up('sm')]: {
      padding: 15,
    },
  },
  closeIcon: {
    display: 'none',
    width: 40,
    height: 40,
    alignItems: 'center',
    borderRadius: '100%',
    top: -15,
    right: -15,
    position: 'absolute',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
});

const MyModal = ({ classes, isOpen, onClose, children, className, sx }) => (
  <Modal
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
    open={isOpen}
    onClose={onClose}
    sx={{ sx }}
  >
    <div style={getModalStyle()}>
      <ClickAwayListener onClickAway={onClose}>
        <div className={classNames(classes.relativeContainer, className)}>
          {children}
          <Fab onClick={onClose} className={classes.closeIcon} aria-label="Close">
            <CloseIcon />
          </Fab>
        </div>
      </ClickAwayListener>
    </div>
  </Modal>
);

MyModal.propTypes = {
  classes: object.isRequired,
  children: node.isRequired,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  className: string,
  sx: object,
};

MyModal.defaultProps = {
  className: null,
  sx: null,
};

export default withStyles(styles)(MyModal);
