import { Drawer, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import ReadProgressBar from 'components/@home/drawers/AnalyticsDrawer/Analytics/ReadProgressBar';
import { bool, object, string } from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import Channel from 'services/api/Channel';
import messageShape from 'shapes/message';
import CloseButton from '../CloseButton';
import connector from './connector';
import MemberList from './MemberList';

const styles = () => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  container: {
    paddingTop: 20,
    paddingLeft: 30,
    paddingRight: 30,
    minWidth: '60vw',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  closeButtonContainer: {
    paddingTop: 20,
    paddingLeft: 20,
  },
  progressSection: {
    borderRadius: 5,
    display: 'flex',
    border: '1px solid #ebeff0',
    padding: 15,
  },
});

const EmployeeListDrawer = ({
  actions,
  channel,
  classes,
  isOpen,
  isCurrentChannel,
  message,
  title,
}) => {
  const theme = useTheme();
  const handleClose = useCallback(() => {
    actions.drawers.employeeList.close();
  }, [actions.drawers.employeeList]);

  const membersRef = useRef({});
  const [loading, setLoading] = React.useState(false);
  const [counters, setCounters] = React.useState({});
  useEffect(() => {
    if (isOpen) {
      setCounters({});
      membersRef.current = {};
    }
  }, [channel?._id, message?._id, isOpen]);
  const loadMoreItems = useCallback(
    async (startIndex, stopIndex) => {
      try {
        if (loading && !channel?._id) {
          return;
        }
        setLoading(true);
        const res = await Channel.getMembers(channel._id, {
          message: message?._id,
          skip: startIndex,
          limit: stopIndex - startIndex + 1,
        });
        res.members.forEach((member, index) => {
          membersRef.current[startIndex + index] = member;
        });
        setCounters({
          read: res.read,
          totalCount: res.totalCount,
          active: res.active,
        });
      } finally {
        setLoading(false);
      }
    },
    [channel?._id, loading, message?._id],
  );

  if (!channel || !isCurrentChannel) {
    return null;
  }

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose}>
      <div className={classes.root}>
        <div className={classes.closeButtonContainer}>
          <CloseButton onClick={handleClose} />
        </div>
        <div className={classes.container}>
          <div className={classes.title}>
            <Typography variant="h5">{title}</Typography>
          </div>
          <div className={classes.progressSection}>
            <ReadProgressBar
              read={message ? counters.read || 0 : counters.active || 0}
              total={counters.totalCount || 0}
              readColor={theme.palette.onOffChartSeries[0]}
              unreadColor={theme.palette.onOffChartSeries[1]}
            />
          </div>
          <MemberList
            totalCount={counters.totalCount}
            membersRef={membersRef}
            loadMoreItems={loadMoreItems}
            showReadStatus={!!message}
          />
        </div>
      </div>
    </Drawer>
  );
};

EmployeeListDrawer.propTypes = {
  title: string.isRequired,
  channel: object,
  classes: object.isRequired,
  actions: object.isRequired,
  isOpen: bool.isRequired,
  message: messageShape,
  isCurrentChannel: bool.isRequired,
};
EmployeeListDrawer.defaultProps = {
  channel: null,
  message: null,
};

export default withStyles(styles)(connector(EmployeeListDrawer));
