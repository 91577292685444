import { useCallback } from 'react';

const useFocusRef = () =>
  useCallback(node => {
    setTimeout(() => {
      if (node) node.focus();
    }, 100);
  }, []);

export default useFocusRef;
