import withStyles from '@mui/styles/withStyles';
import SearchField from 'components/controls/SearchField';
import { func, object, string } from 'prop-types';
import React from 'react';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  searchIconButton: {
    marginLeft: 5,
  },
  searchIcon: {
    fontSize: 16,
  },
};

const Search = ({ classes, value, onChange }) => (
  <div className={classes.root}>
    <SearchField onChange={onChange} value={value} />
  </div>
);

Search.propTypes = {
  classes: object.isRequired,
  value: string,
  onChange: func.isRequired,
};
Search.defaultProps = {
  value: '',
};

export default withStyles(styles)(Search);
