import moment from 'moment';
import { setLocale } from 'react-redux-i18n';
import Settings from 'services/api/Settings';
import { invalidateUserTags } from 'store/app/entities/UsersSlice';

export const LOAD_SETTINGS = 'LOAD_SETTINGS';
export const LOAD_SETTINGS_PENDING = 'LOAD_SETTINGS_PENDING';
export const LOAD_SETTINGS_FULFILLED = 'LOAD_SETTINGS_FULFILLED';
export const LOAD_SETTINGS_REJECTED = 'LOAD_SETTINGS_REJECTED';

export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const UPDATE_SETTINGS_PENDING = 'UPDATE_SETTINGS_PENDING';
export const UPDATE_SETTINGS_FULFILLED = 'UPDATE_SETTINGS_FULFILLED';
export const UPDATE_SETTINGS_REJECTED = 'UPDATE_SETTINGS_REJECTED';

const load = employeeId => ({
  type: LOAD_SETTINGS,
  payload: Settings.load(employeeId),
});

const update = (employeeId, settings) => dispatch => {
  const response = dispatch({
    type: UPDATE_SETTINGS,
    payload: Settings.update(employeeId, settings),
    meta: { employeeId },
  });

  return response.then(({ action: { payload } }) => {
    const {
      settings: { language },
    } = payload;
    moment().locale(language);
    moment.locale(language);
    dispatch(setLocale(language));
    dispatch(invalidateUserTags([{ type: 'Users' }]));
  });
};

export default {
  load,
  update,
};
