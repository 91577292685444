import { useFeatureToggle } from '@flopflip/react-redux';
import { Button, SvgIcon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PlusCircleIcon from 'mdi-react/PlusCircleIcon';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import employeeDrawer from 'store/app/ui/drawers/employee/action';
import isCompanyAdminSelector from 'store/selectors/isCompanyAdmin';
import { I18n } from 'utils/i18n';

const useStyles = makeStyles({
  plus: {
    fontSize: 18,
    marginRight: 5,
  },
});

const AddEmployeeButton = () => {
  const integratedUsers = useFeatureToggle('integrated-users');
  const isCompanyAdmin = useSelector(isCompanyAdminSelector);
  const classes = useStyles();
  const dispatch = useDispatch();
  const openDrawer = useCallback(() => {
    dispatch(employeeDrawer.open());
  }, [dispatch]);

  if (integratedUsers && !isCompanyAdmin) {
    return null;
  }

  return (
    <Button onClick={openDrawer} variant="contained" color="primary" className={classes.root}>
      <SvgIcon className={classes.plus}>
        <PlusCircleIcon />
      </SvgIcon>
      {I18n.t('PeoplesScene.Add employee')}
    </Button>
  );
};

export default AddEmployeeButton;
