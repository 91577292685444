export const toJson = (XLSX, workbook) => {
  const result = {};
  workbook.SheetNames.forEach(sheetName => {
    const sheet = workbook.Sheets[sheetName];
    const roa = XLSX.utils.sheet_to_json(sheet, { raw: false });
    if (roa.length > 0) result[sheetName] = roa;
  });
  return result;
};

const rABS =
  typeof FileReader !== 'undefined' &&
  FileReader.prototype &&
  FileReader.prototype.readAsBinaryString;

const fixdata = data => {
  let o = '';
  let l = 0;
  const w = 10240;
  for (; l < data.byteLength / w; ++l)
    o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w, l * w + w)));
  o += String.fromCharCode.apply(null, new Uint8Array(data.slice(o.length)));
  return o;
};

export const readXlsxFromFileData = async (fileDataIn, readtype = { type: 'array' }) => {
  let fileData = fileDataIn;
  if (!fileData) {
    return {};
  }
  let arr;
  if (!rABS) {
    arr = fixdata(fileData);
    fileData = btoa(arr);
  }
  const XLSX = await import('xlsx');
  const wb = XLSX.read(fileData, readtype);
  return {
    XLSX,
    wb,
  };
};

export const readXlsxFromFile = file => {
  const reader = new FileReader();
  const readtype = { type: rABS ? 'binary' : 'base64' };
  return new Promise(resolve => {
    reader.onload = e => {
      const fileData = e.target.result;
      const ret = readXlsxFromFileData(fileData, readtype);
      resolve(ret);
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  });
};
