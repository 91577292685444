import makeStyles from '@mui/styles/makeStyles';
import { number, object } from 'prop-types';
import React, { useRef, useState } from 'react';
import getImageDimensions from 'utils/getImageDimensions';
import PlayButton from './PlayButton';

const useStyles = makeStyles(() => ({
  root: ({ maxWidth, maxHeight }) => ({
    position: 'relative',
    width: 'fit-content(300px)',
    marginBottom: -6,
    overflow: 'hidden',
    maxWidth,
    maxHeight,
  }),
  container: ({ maxWidth, maxHeight }) => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    zIndex: 10,
    justifyContent: 'center',
    background: 'transparent',
    '& img': {
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    border: 'none',
    maxHeight,
    maxWidth,
  }),
  video: ({ maxHeight }) => ({
    maxHeight,
  }),
  loading: {
    filter: 'blur(4px)',
    transform: 'scale(1.05)',
  },
}));

const VideoThumbnail = ({ data, maxWidth, maxHeight }) => {
  let { width, height } = getImageDimensions(data.meta);
  if (maxWidth && width >= height && maxWidth < width) {
    const scale = maxWidth / width;
    width *= scale;
    height *= scale;
  }
  if (maxHeight && width <= height && maxHeight < height) {
    const scale = maxHeight / height;
    width *= scale;
    height *= scale;
  }
  const classes = useStyles({ maxWidth: width, maxHeight: height });
  const video = useRef();
  const [paused, setPaused] = useState(true);
  const [error, setError] = useState(false);
  const [firstPlay, setFirstPlay] = useState(true);

  const play = () => {
    setPaused(false);
    return video.current.play();
  };

  const handleLoaded = () => {
    setError(false);
  };

  const handleEnded = () => {
    setFirstPlay(true);
    setPaused(true);
  };

  const pause = () => {
    video.pause();
    setPaused(true);
  };

  const togglePlay = event => {
    event.preventDefault();
    setFirstPlay(false);
    if (paused) {
      play();
    } else {
      pause();
    }
  };

  const handleError = () => {
    setError(true);
  };

  return (
    <div className={classes.root}>
      {firstPlay && (
        <button type="button" className={classes.container} onClick={togglePlay}>
          <PlayButton paused={paused} loaded={!error} onClick={togglePlay} />
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
        </button>
      )}
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        className={classes.video}
        width="100%"
        onError={handleError}
        onEnded={handleEnded}
        onLoadedData={handleLoaded}
        controls={!paused}
        poster={data.meta?.thumb}
        preload="none"
        ref={video}
      >
        <source src={data.httpLink} type={data.mimeType} />
        Your browser does not support the html5 audio.
      </video>
    </div>
  );
};

VideoThumbnail.propTypes = {
  data: object.isRequired,
  maxWidth: number,
  maxHeight: number,
};

VideoThumbnail.defaultProps = {
  maxWidth: null,
  maxHeight: null,
};

export default VideoThumbnail;
