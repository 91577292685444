import { Button, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ChatFlowCaption from 'components/@home/@settings/Tabs/ChatFlowCaption';
import chatFlowPageFormik from 'components/@home/@settings/Tabs/chatFlowPageFormik';
import ChatFlowParametersTable from 'components/@home/@settings/Tabs/ChatFlowParametersTable';
import connector from 'components/@home/@settings/Tabs/connector';
import MessageArray from 'components/@home/@settings/Tabs/MessageArray';
import withContext from 'components/@home/@settings/Tabs/withContext';
import CloseIcon from 'mdi-react/CloseIcon';
import { func, object } from 'prop-types';
import React, { memo } from 'react';
import { I18n } from 'utils/i18n';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    flexDirection: 'column',
    height: 'calc(100vh - 64px)',
    borderLeft: `solid 1px ${theme.palette.secondary.extraLight}`,
  },
  title: {
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    color: theme.palette.primary.ultraDark,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  close: {
    color: theme.palette.primary.ultraDark,
  },
  body: {
    flexGrow: 1,
    overflow: 'auto',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(3),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingTop: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
    marginTop: theme.spacing(4),
  },
  details: {
    flexDirection: 'column',
  },
  field: {
    marginTop: theme.spacing(2),
  },
  gutterTop: {
    marginTop: theme.spacing(2),
  },
}));

const ChatFlowEntryFulfillment = ({ context, flow, handleSubmit }) => {
  const classes = useStyles();

  if (!flow) {
    context.pop();
    return null;
  }

  return (
    <form onSubmit={handleSubmit} className={classes.root}>
      <header className={classes.header}>
        <Typography size="small" className={classes.title}>
          {I18n.t('ChatFlowSettings.Fulfillment')}
        </Typography>
        <div>
          <IconButton
            className={classes.close}
            edge="end"
            aria-label="close"
            onClick={context.clear}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </header>
      <section className={classes.body}>
        <ChatFlowCaption section="Entry fulfillment" />
        <Typography className={classes.heading}>
          {I18n.t('ChatFlowSettings.Fulfillment')}
        </Typography>
        <MessageArray field="entryMessages" />
        <Typography className={classes.heading}>
          {I18n.t('ChatFlowSettings.Parameter presets')}
        </Typography>
        <ChatFlowCaption section="Parameter presets" className={classes.gutterTop} />
        <ChatFlowParametersTable name="parameterPresets" />
      </section>
      <footer className={classes.footer}>
        <div />
        <Button variant="contained" color="primary" type="submit">
          {I18n.t('ChatFlowSettings.Save')}
        </Button>
      </footer>
    </form>
  );
};

ChatFlowEntryFulfillment.propTypes = {
  flow: object.isRequired,
  handleSubmit: func.isRequired,
  context: object.isRequired,
};
const areEqual = (prev, next) => {
  return prev.context?.page === next.context?.page;
};
export default connector(withContext(memo(chatFlowPageFormik(ChatFlowEntryFulfillment), areEqual)));
