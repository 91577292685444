import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import modal from 'store/app/ui/modals/acknowledgment/preview/action';

const mapStateToProps = state => ({
  isOpen: state.modals.acknowledgment.preview.isOpen,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    acknowledgementPreviewModal: bindActionCreators(modal, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
