import { createSelector } from 'reselect';

const getForm = createSelector(
  (state, props) => props.formId,
  state => state.forms.data,
  (formId, data) => {
    return data[formId];
  },
);

export default getForm;
