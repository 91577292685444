import {
  CANCEL_EMPLOYEE_IMPORT,
  DOWNLOAD_RESUMED_EMPLOYEE_IMPORT,
  EMPLOYEE_IMPORT_FINISHED,
  RESUME_EMPLOYEE_IMPORT,
  SHOW_RESULTS_EMPLOYEE_IMPORT,
  START_EMPLOYEE_IMPORT,
} from 'store/app/entities/employeeImports/action';

import { ActionType } from 'redux-promise-middleware';
import { CREATE_IMPORT, IMPORT_DRY_RUN_FINISHED } from 'store/app/entities/employees/action';
import {
  CLOSE_EMPLOYEE_IMPORT_DRAWER,
  CLOSE_PREVIEW_EMPLOYEE_IMPORT_DRAWER,
  OPEN_EMPLOYEE_IMPORT_DRAWER,
} from './action';

const initialState = {
  isOpen: false,
  file: null,
  fileData: null,
  importPreview: null,
  loading: false,
  summary: null,
  status: null,
  error: null,
};

const employeeImportDrawer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_EMPLOYEE_IMPORT_DRAWER:
      return {
        ...initialState,
        isOpen: true,
      };

    case CLOSE_EMPLOYEE_IMPORT_DRAWER:
      return initialState;

    case CLOSE_PREVIEW_EMPLOYEE_IMPORT_DRAWER:
      return { ...state, file: null, importPreview: null, fileData: null, status: null };

    case `${CREATE_IMPORT}_${ActionType.Pending}`: {
      return {
        ...initialState,
        file: action.meta.file,
        isOpen: state.isOpen,
        loading: true,
        status: null,
        error: null,
      };
    }

    case IMPORT_DRY_RUN_FINISHED: {
      const importPreview = action.payload;
      return { ...state, importPreview, loading: false, error: null };
    }

    case `${CREATE_IMPORT}_${ActionType.Rejected}`: {
      return { ...state, loading: false, error: action.payload };
    }

    case `${CANCEL_EMPLOYEE_IMPORT}_${ActionType.Pending}`: {
      return {
        ...state,
        file: null,
        importPreview: null,
        fileData: null,
        status: null,
        error: null,
      };
    }

    case `${RESUME_EMPLOYEE_IMPORT}_${ActionType.Pending}`: {
      return { ...state, loading: true, error: null };
    }

    case `${RESUME_EMPLOYEE_IMPORT}_${ActionType.Rejected}`: {
      return { ...state, loading: false, error: action.payload };
    }

    case `${RESUME_EMPLOYEE_IMPORT}_${ActionType.Fulfilled}`: {
      return {
        ...state,
        file: null,
        importPreview: { employeeImport: action.payload.employeeImport },
        fileData: null,
        loading: false,
        status: null,
        error: null,
      };
    }

    case `${SHOW_RESULTS_EMPLOYEE_IMPORT}_${ActionType.Fulfilled}`: {
      return {
        ...state,
        file: null,
        importPreview: action.payload.importPreview,
        fileData: null,
        loading: false,
        status: action.meta.status,
      };
    }

    case `${DOWNLOAD_RESUMED_EMPLOYEE_IMPORT}_${ActionType.Fulfilled}`: {
      return { ...state, fileData: action.payload.data };
    }

    case `${START_EMPLOYEE_IMPORT}_${ActionType.Pending}`: {
      return {
        ...state,
        summary: null,
        file: null,
        importPreview: null,
        fileData: null,
        loading: false,
        status: null,
      };
    }

    case `${START_EMPLOYEE_IMPORT}_${ActionType.Fulfilled}`: {
      return { ...state };
    }

    case EMPLOYEE_IMPORT_FINISHED: {
      return { ...state, summary: action.payload.data };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default employeeImportDrawer;
