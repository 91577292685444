/**
 * @flow
 */
/* eslint-disable react/require-default-props */
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import FormikCheckboxGroup from 'components/formik/CheckboxGroup';
import FormikRadioGroup from 'components/formik/RadioGroup';
import { Field } from 'formik';
import React from 'react';

const useStyles = makeStyles(theme => ({
  option: {
    backgroundColor: theme.palette.primary.ultraLight,
    borderRadius: 4,
    border: '2px solid transparent',
    margin: 0,
    padding: theme.spacing(1.25),
    marginTop: theme.spacing(0.75),
    marginBottom: theme.spacing(0.75),
    '&:hover': {
      border: `2px solid ${theme.palette.primary.lighter}`,
    },
  },
  optionSelected: {
    borderColor: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.extraLight,
    '&:hover': {
      borderColor: theme.palette.primary.light,
    },
  },
  optionHorizontal: {
    marginRight: theme.spacing(2),
    paddingRight: theme.spacing(4),
  },
  horizontal: {
    flexDirection: 'row',
  },
}));

type Props = {
  question: Object,
  multiple?: boolean,
  items?: Array<[string, string]>,
  horizontal?: boolean,
};

const QuestionFormChoice = ({
  question,
  items = question.options.map(({ _id, label }) => [_id, label]),
  multiple = false,
  horizontal = false,
}: Props) => {
  const classes = useStyles();
  const Component = multiple ? FormikCheckboxGroup : FormikRadioGroup;
  return (
    <Field
      type={multiple ? 'checkbox' : 'radio'}
      component={Component}
      itemClassName={classNames(classes.option, { [classes.optionHorizontal]: horizontal })}
      itemSelectedClassName={classes.optionSelected}
      name={`answers.${question._id}`}
      items={items}
      groupClassName={classNames({ [classes.horizontal]: horizontal })}
    />
  );
};

export default QuestionFormChoice;
