import isArray from 'lodash/isArray';
import { useCallback, useMemo, useRef } from 'react';

const inputTags = ['INPUT', 'SELECT', 'TEXTAREA'];

const isInputDOMNode = event => {
  // using composed path for handling shadow dom
  const target = event.composedPath?.()?.[0] || event.target;
  const isInput = inputTags.includes(target?.nodeName) || target?.hasAttribute('contenteditable');

  // when an input field is focused we don't want to trigger deletion or movement of nodes
  return isInput || !!target?.closest('.nokey');
};

const getMatchingCallback = (actions, pressedKeys, isUp) =>
  Object.entries(actions).find(([keyCodes]) =>
    keyCodes
      .split(',')
      .map(keys =>
        keys
          .split('+')
          .map(k => k.trim())
          .filter(k => k),
      )
      // we only want to compare same sizes of keyCode definitions
      // and pressed keys. When the user specified 'Meta' as a key somewhere
      // this would also be truthy without this filter when user presses 'Meta' + 'r'
      .filter(keys => isUp || keys.length === pressedKeys.size)
      // since we want to support multiple possibilities only one of the
      // combinations need to be part of the pressed keys
      .some(keys => keys.every(k => pressedKeys.has(k))),
  )?.[1];

const getKeyOrCode = (eventCode, keysToWatch) => (keysToWatch.includes(eventCode) ? 'code' : 'key');

/**
 * Hook for handling key events.
 *
 * @public
 * @param actions - An object with keyCodes as keys and callbacks as values. KeyCodes is a comma separated String of single keys or combinations of keys separated by "+". Callbacks is a function that will be called when any of the keyCodes is pressed.
 * @param options - Options for the hook { actInsideInput: boolean } to specify if the key events should be triggered inside input fields.
 * @returns ref - A ref that should be passed to the target element.
 */
const useKeyDown = (actions, options = { actInsideInput: true }) => {
  // we need to remember if a modifier key is pressed in order to track it
  const modifierPressed = useRef(false);

  // we need to remember the pressed keys in order to support combinations
  const pressedKeys = useRef(new Set([]));

  // keysToWatch = array with all keys flattened ['a', 'd', 'ShiftLeft']
  // used to check if we store event.code or event.key. When the code is in the list of keysToWatch
  // we use the code otherwise the key. Explainer: When you press the left "command" key, the code is "MetaLeft"
  // and the key is "Meta". We want users to be able to pass keys and codes so we assume that the key is meant when
  // we can't find it in the list of keysToWatch.
  const keysToWatch = useMemo(() => {
    const ret = new Set();
    Object.keys(actions)
      .map(ks => ks.split(/[,+]/))
      .flat()
      .forEach(k => ret.add(k));
    return Array.from(ret);
  }, [actions]);

  return useCallback(
    target => {
      if (!target) {
        return null;
      }
      const downHandler = event => {
        modifierPressed.current = event.ctrlKey || event.metaKey || event.shiftKey;
        const preventAction = !options.actInsideInput && isInputDOMNode(event);

        if (preventAction) {
          return false;
        }
        const keyOrCode = getKeyOrCode(event.code, keysToWatch);
        pressedKeys.current.add(event[keyOrCode]);

        const cb = getMatchingCallback(actions, pressedKeys.current, false);
        if (cb) {
          event.preventDefault();
          cb(event);
        }
        return true;
      };

      const upHandler = event => {
        const preventAction = !options.actInsideInput && isInputDOMNode(event);

        if (preventAction) {
          return false;
        }
        const keyOrCode = getKeyOrCode(event.code, keysToWatch);

        const cb = getMatchingCallback(actions, pressedKeys.current, true);
        if (cb) {
          pressedKeys.current.clear();
        } else {
          pressedKeys.current.delete(event[keyOrCode]);
        }

        // fix for Mac: when cmd key is pressed, keyup is not triggered for any other key, see: https://stackoverflow.com/questions/27380018/when-cmd-key-is-kept-pressed-keyup-is-not-triggered-for-any-other-key
        if (event.key === 'Meta') {
          pressedKeys.current.clear();
        }

        modifierPressed.current = false;
        return true;
      };

      const resetHandler = () => {
        pressedKeys.current.clear();
      };

      target?.addEventListener('keydown', downHandler);
      target?.addEventListener('keyup', upHandler);
      window.addEventListener('blur', resetHandler);

      return () => {
        target?.removeEventListener('keydown', downHandler);
        target?.removeEventListener('keyup', upHandler);
        window.removeEventListener('blur', resetHandler);
      };
    },
    [actions, keysToWatch, options.actInsideInput],
  );
};

export default useKeyDown;
