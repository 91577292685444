import { Tabs } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { node, object } from 'prop-types';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const styles = theme => ({
  root: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      margin: '0 auto',
    },
    flexGrow: 1,
  },
});

const HeaderTabs = props => {
  const { classes, children } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const onChangeTab = (e, value) => {
    navigate(value);
  };
  return (
    <Tabs
      value={location.pathname.replace(/\/home\/([^/]+)\/.+$/, '/home/$1')}
      onChange={onChangeTab}
      indicatorColor="primary"
      className={classes.root}
      centered
    >
      {children}
    </Tabs>
  );
};

HeaderTabs.propTypes = {
  classes: object.isRequired,
  children: node.isRequired,
};
export default withStyles(styles)(HeaderTabs);
