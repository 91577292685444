import { CLOSE_FORM_DRAWER, OPEN_FORM_DRAWER } from './action';

const initialState = {
  isOpen: false,
};

const formDrawer = (state = initialState, { type, payload }) => {
  switch (type) {
    case OPEN_FORM_DRAWER:
      return {
        ...state,
        ...(typeof payload === 'object' ? payload : {}),
        isOpen: true,
        formId: typeof payload !== 'object' ? payload : payload?._id,
      };

    case CLOSE_FORM_DRAWER:
      return initialState;

    default:
      return state;
  }
};

export default formDrawer;
