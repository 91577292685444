import { OPEN_FILL_FORM_DRAWER, CLOSE_FILL_FORM_DRAWER } from './action';

const initialState = {
  isOpen: false,
};

const formDrawer = (state = initialState, { type, payload }) => {
  switch (type) {
    case OPEN_FILL_FORM_DRAWER:
      return {
        ...state,
        ...payload,
        isOpen: true,
      };

    case CLOSE_FILL_FORM_DRAWER:
      return {
        ...state,
        isOpen: false,
      };

    default:
      return state;
  }
};

export default formDrawer;
