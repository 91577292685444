import { makeStyles } from '@mui/styles';
import ChannelsPanelHead from 'components/@home/@messages/ChannelsPanel/ChannelsPanelHead';
import LoadingBar from 'components/common/LoadingBar/LoadingBar';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'utils/i18n';
import { useNavigate, useParams } from 'react-router-dom';
import Message from 'services/api/Message';
import { useGetMemberChannels } from 'store/app/entities/channels/action';
import messagesActions from 'store/app/entities/messages/action';
import channelsPanel from 'store/app/ui/ChannelsPanel/action';
import authEmployeeSelector from 'store/selectors/authEmployee';
import authUserSelector from 'store/selectors/authUser';
import filteredChannelsSelector from 'store/selectors/filteredChannels';
import populateChannels from 'store/selectors/populateChannels';
import useActions from 'utils/useActions';
import { useDebounceCallback } from 'utils/useDebounceCallback';
import Channels from './Channels';
import NewChannelButton from './NewChannelButton';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    width: 380,
  },
}));

const ChannelsPanel = () => {
  const allFilteredChannels = useSelector(filteredChannelsSelector);
  const filteredChannels = useMemo(
    () => allFilteredChannels.filter(c => c.isDirect || c.isMember),
    [allFilteredChannels],
  );
  const allChannels = useSelector(populateChannels);
  const { isLoading, isFetching } = useGetMemberChannels();
  const params = useParams();
  const { channelId } = params;
  const messageId = useSelector(state => state.elements.ChannelsPanel.messageId);
  const filter = useSelector(state => state.elements.ChannelsPanel.filter);
  const authUser = useSelector(authUserSelector);
  const authEmployee = useSelector(authEmployeeSelector);
  const showArchived = useSelector(state => state.elements.ChannelsPanel.showArchived);
  const [messagesLoad, channelsPanelFilter] = useActions([
    messagesActions.load,
    channelsPanel.filter,
  ]);

  const navigate = useNavigate();
  const classes = useStyles();
  const [searchingMessages, setSearchingMessages] = useState(false);
  const [foundMessages, setFoundMessages] = useState([]);

  const messageSearch = useCallback(
    async search => {
      if (!search?.length > 0) {
        setFoundMessages([]);
        return;
      }
      try {
        setSearchingMessages(true);
        const channels = Object.values(allChannels);
        const { messages } = await Message.search(search);
        const newFoundMessages = messages
          .map(m => {
            const ch =
              channels.find(c => c._id === m.channel) ||
              (m.channel === authUser._id && channels.find(c => c._id === m.from?._id));
            return {
              ...ch,
              messageFromSearch: true,
              messageId: m._id,
              lastMessage: { ...m, employee: m.from },
            };
          })
          .filter(c => c._id);
        setFoundMessages(newFoundMessages);
      } finally {
        setSearchingMessages(false);
      }
    },
    [allChannels, authUser._id],
  );

  const messageSearchDebounced = useDebounceCallback(messageSearch, 500);

  const onSelect = (chId, msgId) => () => {
    const selectedChannel = allChannels[chId];
    if (msgId || channelId !== chId) {
      navigate(`/home/messages/${chId}`, { state: { messageId: msgId } });
    } else {
      const loadMessages =
        (!msgId && selectedChannel && !selectedChannel.complete) ||
        (msgId && !selectedChannel.messages_ids.includes(msgId));
      setTimeout(() => {
        if (loadMessages) {
          messagesLoad(chId, { aroundId: msgId });
        }
      }, 10);
    }
  };

  const handleSearch = useCallback(
    async e => {
      channelsPanelFilter(e.target.value, filteredChannels);
      messageSearchDebounced(e.target.value);
    },
    [channelsPanelFilter, filteredChannels, messageSearchDebounced],
  );

  const channels = useMemo(() => {
    const channelArray = [];

    if (filteredChannels.length > 0) {
      if (filter) {
        channelArray.push({ separator: I18n.t('ChannelsPanel.Chats') });
      }
      channelArray.push(...filteredChannels);
    }

    if (foundMessages.length > 0) {
      channelArray.push({ separator: I18n.t('ChannelsPanel.Messages') });
      channelArray.push(...foundMessages);
    }

    return channelArray;
  }, [filter, filteredChannels, foundMessages]);

  return (
    <section className={classes.root}>
      <ChannelsPanelHead onSearch={handleSearch} />
      <LoadingBar loading={isLoading || isFetching || searchingMessages} />
      {!showArchived && <NewChannelButton />}
      {!isLoading && (
        <Channels
          loading={isFetching}
          filter={filter}
          channels={channels}
          messageId={messageId}
          onSelect={onSelect}
          authEmployee={authEmployee}
          showArchived={showArchived}
        />
      )}
    </section>
  );
};

export default memo(ChannelsPanel);
