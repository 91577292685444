import { Drawer } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Loading from 'components/common/Loading';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import teamDrawer from 'store/app/ui/drawers/team/action';
import employeesStatus from 'store/selectors/employeesStatus';
import useActions from 'utils/useActions';
import CloseButton from '../CloseButton';
import TeamForm from './Form';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 1000,
    },
    flex: 1,
  },
  container: {
    display: 'flex',
  },
  closeButtonContainer: {
    paddingTop: 20,
    paddingLeft: 20,
  },
  loaderWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
}));

const TeamDrawer = () => {
  const classes = useStyles();
  const isOpen = useSelector(state => state.drawers.team.isOpen);
  const { isLoading } = useSelector(employeesStatus);
  const close = useActions(teamDrawer.close);
  return (
    <Drawer anchor="right" open={isOpen} onClose={close}>
      <div className={classes.root}>
        <div className={classes.closeButtonContainer}>
          <CloseButton onClick={close} />
        </div>
        {isLoading ? (
          <div className={classes.loaderWrapper}>
            <Loading />
          </div>
        ) : (
          <TeamForm />
        )}
      </div>
    </Drawer>
  );
};

export default memo(TeamDrawer);
