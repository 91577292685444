import {
  CREATE_APP_FULFILLED,
  DESTROY_APP_FULFILLED,
  FIND_APP_FULFILLED,
  FIND_APP_PENDING,
  GET_GROUPS_APP_FULFILLED,
  GET_GROUPS_APP_PENDING,
  INSTALL_APP_FULFILLED,
  LOAD_APPS_FULFILLED,
  LOAD_APPS_PENDING,
  REGENERATE_SECRET_APP_FULFILLED,
  UPDATE_APP_FULFILLED,
  UPLOAD_FILE_APP_FULFILLED,
} from 'store/app/entities/apps/action';
import arrayToObject from 'utils/arrayToObject';
import merge from 'utils/merge';

const appsReducer = (
  state = { apps: {}, loading: false, loaded: false },
  { type, payload, meta },
) => {
  switch (type) {
    case LOAD_APPS_FULFILLED: {
      const apps = payload.apps.map(c => merge(state.apps[c._id], c));

      return merge(state, { apps: arrayToObject(apps), loading: false, loaded: true });
    }

    case FIND_APP_PENDING:
    case GET_GROUPS_APP_PENDING: {
      return merge(state, { loading: true });
    }

    case CREATE_APP_FULFILLED:
    case FIND_APP_FULFILLED:
    case GET_GROUPS_APP_FULFILLED:
    case REGENERATE_SECRET_APP_FULFILLED:
    case UPDATE_APP_FULFILLED:
    case UPLOAD_FILE_APP_FULFILLED: {
      const app = merge({ ...state.apps[payload._id] }, payload);
      return merge(state, { loading: false, apps: { ...state.apps, [payload._id]: app } });
    }

    case INSTALL_APP_FULFILLED: {
      const app = { ...state.apps[meta.appId], installed: meta.installed };
      return merge(state, { loading: false, apps: { ...state.apps, [meta.appId]: app } });
    }

    case DESTROY_APP_FULFILLED: {
      const apps = { ...state.apps };
      delete apps[meta.appId];
      return merge(state, { apps });
    }

    case LOAD_APPS_PENDING:
      return merge(state, { loading: true, loaded: false });

    default:
      return state;
  }
};

export default appsReducer;
