import Paper from '@mui/material/Paper';
import React from 'react';
import { object, node } from 'prop-types';
import withStyles from '@mui/styles/withStyles';

const styles = {
  root: {
    padding: '1.5em',
    margin: '1em 0',
    boxShadow:
      '0px 11px 15px -7px rgba(0,0,0,0.05), ' +
      '0px 24px 38px 3px rgba(0,0,0,0.02), ' +
      '0px 9px 46px 8px rgba(0,0,0,0.01)',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
};

const CustomPaper = ({ classes, children }) => (
  <Paper className={classes.root} elevation={24}>
    {children}
  </Paper>
);
CustomPaper.propTypes = {
  classes: object.isRequired,
  children: node.isRequired,
};

export default withStyles(styles)(CustomPaper);
