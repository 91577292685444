import makeStyles from '@mui/styles/makeStyles';
import Pagination from 'components/controls/Pagination';
import isEmpty from 'lodash/isEmpty';
import { arrayOf, number, object, shape, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import connector from './connector';
import FormItem from './FormItem';
import FormsList from './FormsList';

const LINKS_PER_PAGE = 10;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
}));

const Forms = ({ actions, forms, total }) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);

  useEffect(() => {
    actions.media.forms(page + 1, LINKS_PER_PAGE);
  }, [page]);

  const changePage = ({ selected }) => {
    setPage(selected);
  };

  if (isEmpty(forms)) return null;

  return (
    <div className={classes.root}>
      <FormsList>
        {forms.map(form => (
          <FormItem key={form._id} form={form}>
            {form.text}
          </FormItem>
        ))}
      </FormsList>
      <Pagination perPage={LINKS_PER_PAGE} total={total} page={page} onChange={changePage} />
    </div>
  );
};

Forms.propTypes = {
  forms: arrayOf(
    shape({
      description: string,
      favicon: string,
      title: string,
      url: string.isRequired,
    }),
  ).isRequired,
  total: number.isRequired,
  actions: object.isRequired,
};
export default connector(Forms);
