/* eslint-disable react/destructuring-assignment, react/jsx-props-no-spreading */
import makeStyles from '@mui/styles/makeStyles';
import Loading from 'components/common/Loading';
import PDFPreviewLazy from 'components/common/PDFPreviewLazy';
import { any } from 'prop-types';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    backgroundColor: '#f2f4f7',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    position: 'relative',
    '&::after': {
      content: `''`,
      position: 'absolute',
      boxShadow: 'inset 0px 0px 10px rgba(0,0,0,0.1)',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      pointerEvents: 'none',
    },
  },
  scroll: {
    height: '100%',
    position: 'relative',
    overflow: 'scroll',
  },

  pdf: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    alignItems: 'center',
    flex: 1,
  },
  page: {
    width: 'fit-content',
    margin: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#fff',
    boxShadow:
      '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)',
    overflow: 'hidden',
  },

  loading: {
    marginTop: 20,
  },
}));

const PdfPreview = ({ src, ...rest }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.scroll}>
        <PDFPreviewLazy
          {...rest}
          pageClassName={classes.page}
          docClassName={classes.pdf}
          file={src}
          loading={<Loading classes={{ root: classes.loading }} size={60} />}
        />
      </div>
    </div>
  );
};

PdfPreview.propTypes = {
  src: any.isRequired,
};

export default PdfPreview;
