/* eslint-disable react/destructuring-assignment,jsx-a11y/anchor-is-valid */
import { makeStyles } from '@mui/styles';
import logo from 'assets/logos/logo';
import deferRender from 'defer-render-hoc';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import auth from 'store/app/auth/action';
import authUser from 'store/selectors/authUser';
import { Translate } from 'utils/i18n';
import useActions from 'utils/useActions';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: 375,
    margin: '0 auto',
    height: '100%',
    padding: '20px 20px',
  },
  Logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 67,
    color: theme.palette.primary.main,
  },
  LogoImg: {
    display: 'block',
    height: 52,
    filter: theme.palette.mode === 'dark' ? 'invert(1) hue-rotate(180deg)' : 'invert(0)',
  },
  RedirectHeading: {
    fontSize: 28,
    fontWeight: 'bold',
    color: theme.palette.primary.ultraDark,
    letterSpacing: -0.7,
    marginTop: 0,
    marginBottom: 21,
    textAlign: 'center',
  },
}));

const AcceptInviteScene = () => {
  const classes = useStyles();

  const params = useParams();

  const user = useSelector(authUser);

  const acceptInvite = useActions(auth.acceptInvite);

  useEffect(() => {
    if (user?.phone && params?.token) {
      acceptInvite(user.phone, params.token);
    }
  }, [acceptInvite, params.token, user?.phone]);

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.Logo}>
          <img src={logo} className={classes.LogoImg} alt="Ommnio" />
        </div>
        <h3 className={classes.RedirectHeading}>
          <Translate value="AcceptInviteScene.Accepting invitation" />
        </h3>
      </div>
    </div>
  );
};

export default deferRender(AcceptInviteScene);
