import makeStyles from '@mui/styles/makeStyles';
import CdataElement from 'components/@home/drawers/HrBotDrawer/XMLViewer/cdata-el';
import CommentElement from 'components/@home/drawers/HrBotDrawer/XMLViewer/comment-el';
import InstructionElement from 'components/@home/drawers/HrBotDrawer/XMLViewer/instruction-el';
import TextElement from 'components/@home/drawers/HrBotDrawer/XMLViewer/text-el';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import Attributes from './attributes';

function getIndentationString(size) {
  return new Array(size + 1).join(' ');
}

function isTextElement(elements) {
  return elements.length === 1 && elements[0].type === 'text';
}

function onSelectText(e) {
  e.stopPropagation();
}
const useStyles = makeStyles(() => ({
  root: {
    whiteSpace: 'pre',
  },
}));

const Element = memo(
  ({ name, elements, attributes, theme, indentation, indentSize, xpath, onClick, index = 0 }) => {
    const classes = useStyles();
    return (
      <div className={classes.root}>
        <span style={{ color: theme.separatorColor }}>{`${indentation}<`}</span>
        <span style={{ color: theme.tagColor }}>{name}</span>
        <Attributes
          xpath={`${xpath}${index > 0 ? `[${index}]` : ''}/${name}`}
          attributes={attributes}
          theme={theme}
          onClick={onClick}
        />
        <span style={{ color: theme.separatorColor }}>{elements ? '>' : '/>'}</span>
        {elements && (
          <span className={classes.button} onClick={onSelectText}>
            <Elements
              xpath={`${xpath}${index > 0 ? `[${index}]` : ''}/${name}`}
              onClick={onClick}
              elements={elements}
              theme={theme}
              indentation={indentation + getIndentationString(indentSize)}
              indentSize={indentSize}
            />
          </span>
        )}
        {elements && (
          <span style={{ color: theme.separatorColor }}>{`${
            isTextElement(elements) ? '' : indentation
          }</`}</span>
        )}
        {elements && <span style={{ color: theme.tagColor }}>{name}</span>}
        {elements && <span style={{ color: theme.separatorColor }}>{'>'}</span>}
      </div>
    );
  },
);

Element.propTypes = {
  name: PropTypes.string.isRequired,
  elements: PropTypes.arrayOf(PropTypes.object),
  attributes: PropTypes.object,
  theme: PropTypes.object.isRequired,
  indentation: PropTypes.string.isRequired,
  indentSize: PropTypes.number.isRequired,
  xpath: PropTypes.string.isRequired,
  num: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

Element.defaultProps = {
  elements: null,
  attributes: null,
};

const getElement = (theme, indentation, indentSize, onClick, xpath, element, index) => {
  switch (element.type) {
    case 'text':
      return (
        <TextElement
          key={`el-${element.text}-${index}`}
          text={element.text}
          theme={theme}
          onClick={onClick}
          xpath={xpath}
        />
      );
    case 'element':
      return (
        <Element
          key={`el-${element.name}-${index}`}
          name={element.name}
          elements={element.elements}
          attributes={element.attributes}
          theme={theme}
          indentation={indentation}
          indentSize={indentSize}
          onClick={onClick}
          xpath={xpath}
          index={index}
        />
      );
    case 'comment':
      return (
        <CommentElement
          key={`el-${element.comment}-${index}`}
          comment={element.comment}
          theme={theme}
          indentation={indentation}
          onClick={onClick}
          xpath={xpath}
        />
      );
    case 'cdata':
      return (
        <CdataElement
          key={`el-${element.cdata}-${index}`}
          cdata={element.cdata}
          theme={theme}
          indentation={indentation}
          onClick={onClick}
          xpath={xpath}
        />
      );
    case 'instruction':
      return (
        <InstructionElement
          key={`el-${element.instruction}-${index}`}
          instruction={element.instruction}
          name={element.name}
          theme={theme}
          indentation={indentation}
          onClick={onClick}
          xpath={xpath}
        />
      );
    default:
      return null;
  }
};

const Elements = memo(({ elements, theme, indentation, indentSize, onClick, xpath }) => {
  let index = 0;
  let prev = null;
  return elements.map((e, i) => {
    index = e.name && prev === e.name ? index + 1 : 0;
    prev = e.name;
    return getElement(theme, indentation, indentSize, onClick, xpath, e, index);
  });
});

Elements.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.object),
  theme: PropTypes.object.isRequired,
  indentation: PropTypes.string.isRequired,
  indentSize: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  xpath: PropTypes.string.isRequired,
};

Elements.defaultProps = {
  elements: null,
};

export default Elements;
