import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import messages from 'store/app/entities/messages/action';
import acknowledgeCreateDrawer from 'store/app/ui/drawers/acknowledgeCreate/action';
import authCompany from 'store/selectors/authCompany';
import authEmployee from 'store/selectors/authEmployee';
import authUser from 'store/selectors/authUser';
import currentChannel from 'store/selectors/currentChannel';

const mapStateToProps = state => {
  return {
    isOpen: state.drawers.acknowledgeCreate.isOpen,
    tempAttachment: state.drawers.acknowledgeCreate.tempAttachment,
    channel: currentChannel(state),
    authCompany: authCompany(state),
    authEmployee: authEmployee(state),
    authUser: authUser(state),
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    drawers: {
      acknowledgeCreate: bindActionCreators(acknowledgeCreateDrawer, dispatch),
    },
    messages: bindActionCreators(messages, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
