import { arrayOf, number, shape, string } from 'prop-types';

export default shape({
  source: shape({
    last4: string.isRequired,
    expiration: string.isRequired,
    brand: string.isRequired,
    object: string.isRequired,
  }),
  digitalSignaturesBought: {
    date: string.isRequired,
    quantity: number.isRequired,
  },
  subscriptions: arrayOf({
    interval: string.isRequired,
    nextBilling: string.isRequired,
    product: shape({
      id: string.isRequired,
      name: string.isRequired,
    }),
  }),
});
