/** @flow */
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import hasFocusState from 'store/selectors/hasFocusState';
import totalUnreadMessages from 'store/selectors/totalUnreadMessages';

const NotificationsHandler = ({ sound }: { sound: String }) => {
  const totalUnRead = useSelector(totalUnreadMessages);
  const hasFocus = useSelector(hasFocusState);
  const audio = useMemo(() => new Audio(sound), [sound]);

  const prevTotalUnRead = useRef();
  const hasFocusRef = useRef(hasFocus);

  useEffect(() => {
    hasFocusRef.current = hasFocus;
  }, [hasFocus]);

  useEffect(() => {
    prevTotalUnRead.current = totalUnRead;
  }, [totalUnRead]); // Only re-run if value changes

  const listener = useCallback(() => {
    audio.play().then(() => audio.pause());
    window.removeEventListener('click', listener);
  }, [audio]);

  useEffect(() => {
    window.addEventListener('click', listener);
    return () => window.removeEventListener('click', listener);
  }, [listener]);

  useEffect(() => {
    if (totalUnRead > prevTotalUnRead.current && !hasFocusRef.current) {
      audio.play();
    }
  }, [audio, totalUnRead]);

  return null;
};

export default NotificationsHandler;
