import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import channels from 'store/app/entities/channels/action';
import analyticsDrawer from 'store/app/ui/drawers/analytics/action';
import acknowledgmentReadModal from 'store/app/ui/modals/acknowledgment/read/action';
import authEmployee from 'store/selectors/authEmployee';
import currentChannel from 'store/selectors/currentChannel';
import populateEmployees from 'store/selectors/populateEmployees';

const mapStateToProps = state => {
  const channel = currentChannel(state);
  const employeeIds = channel?.employees_ids || [];
  const employees = Object.values(populateEmployees(state));
  const members = employees.filter(e => employeeIds.find(eid => eid === e._id && e.isActive));
  return {
    analyticsDrawer: state.drawers.analytics,
    channel: currentChannel(state),
    employee: authEmployee(state),
    members,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    channels: bindActionCreators(channels, dispatch),
    acknowledgmentReadModal: bindActionCreators(acknowledgmentReadModal, dispatch),
    drawer: {
      analyticsDrawer: bindActionCreators(analyticsDrawer, dispatch),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
