import BaseResource from 'utils/BaseResource';

class AsyncResource extends BaseResource {
  constructor(promise) {
    super();
    this.setPromise(promise);
  }

  setPromise(promise) {
    this.init();
    this.promise = promise
      .then(data => {
        this.status = 'success';
        this.data = data;
      })
      .catch(error => {
        this.status = 'error';
        this.error = error;
      });
  }
}

export default AsyncResource;
