import User from 'services/api/User';

export const SET_USER = 'SET_USER';
export const TYPING = 'TYPING';

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_PENDING = 'UPDATE_PASSWORD_PENDING';
export const UPDATE_PASSWORD_FULFILLED = 'UPDATE_PASSWORD_FULFILLED';

const set = user => ({
  type: SET_USER,
  payload: user,
});

const typing = (channel, user, status) => ({
  type: TYPING,
  payload: { channel, user, status },
});

const updatePassword = (currentPassword, user, newPassword, loginType) => ({
  type: UPDATE_PASSWORD,
  payload: User.updatePassword(currentPassword, user, newPassword, loginType),
});

export default {
  set,
  typing,
  updatePassword,
};
