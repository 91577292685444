import { SET_HEADER_ICON, TOGGLE_CHANNELS_PANEL } from './action';

const initialState = {
  icon: 'menu',
  url: null,
  channelsPannelOpen: false,
};

const headerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_HEADER_ICON:
      return {
        ...state,
        url: payload.url,
        icon: payload.icon,
      };
    case TOGGLE_CHANNELS_PANEL:
      return {
        ...state,
        channelsPannelOpen: !state.channelsPannelOpen,
      };
    default:
      return state;
  }
};

export default headerReducer;
