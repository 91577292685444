import { IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SquareEditOutlineIcon from 'mdi-react/SquareEditOutlineIcon';
import React, { memo } from 'react';
import { useListVisibleQuery } from 'store/app/entities/EmployeesApiSlice';
import direct from 'store/app/ui/drawers/direct/action';
import useActions from 'utils/useActions';

const useStyles = makeStyles(theme => ({
  root: {},
  editIcon: {
    color: theme.palette.primary.main,
  },
}));

const DirectButton = () => {
  const openDirectDrawer = useActions(direct.open);
  const { data: { metadata } = {}, isLoading } = useListVisibleQuery({
    skip: 0,
    limit: 99,
    search: '',
  }); // Keep these parameters to match the DirectDrawer query and prevent an additional call
  const classes = useStyles();
  if (isLoading || !metadata.total) {
    return null;
  }
  return (
    <div className={classes.root}>
      <IconButton onClick={openDirectDrawer} size="large">
        <SquareEditOutlineIcon className={classes.editIcon} />
      </IconButton>
    </div>
  );
};

export default memo(DirectButton);
