import arrayToObject from 'utils/arrayToObject';
import merge from 'utils/merge';
import {
  CREATE_CHAT_FLOW_ENTITY_TYPE_FULFILLED,
  DESTROY_CHAT_FLOW_ENTITY_TYPE_FULFILLED,
  FIND_CHAT_FLOW_ENTITY_TYPE_FULFILLED,
  FIND_CHAT_FLOW_ENTITY_TYPE_PENDING,
  LOAD_CHAT_FLOW_ENTITY_TYPES_FULFILLED,
  LOAD_CHAT_FLOW_ENTITY_TYPES_PENDING,
  UPDATE_CHAT_FLOW_ENTITY_TYPE_FULFILLED,
} from './action';

const chatFlowsReduceer = (
  state = { chatFlowEntityTypes: {}, loading: false, loaded: false },
  { type, payload, meta },
) => {
  switch (type) {
    case LOAD_CHAT_FLOW_ENTITY_TYPES_FULFILLED: {
      const chatFlowEntityTypes = payload.chatFlowEntityTypes.map(c =>
        merge(state.chatFlowEntityTypes[c._id], c),
      );

      return merge(state, {
        chatFlowEntityTypes: arrayToObject(chatFlowEntityTypes),
        loading: false,
        loaded: true,
      });
    }

    case FIND_CHAT_FLOW_ENTITY_TYPE_PENDING: {
      return merge(state, { loading: true });
    }

    case CREATE_CHAT_FLOW_ENTITY_TYPE_FULFILLED:
    case FIND_CHAT_FLOW_ENTITY_TYPE_FULFILLED:
    case UPDATE_CHAT_FLOW_ENTITY_TYPE_FULFILLED: {
      const chatFlowEntityType = merge({ ...state.chatFlowEntityTypes[payload._id] }, payload);
      return merge(state, {
        loading: false,
        chatFlowEntityTypes: { ...state.chatFlowEntityTypes, [payload._id]: chatFlowEntityType },
      });
    }

    case DESTROY_CHAT_FLOW_ENTITY_TYPE_FULFILLED: {
      const chatFlowEntityTypes = { ...state.chatFlowEntityTypes };
      delete chatFlowEntityTypes[meta.entityTypeId];
      return merge(state, { chatFlowEntityTypes });
    }

    case LOAD_CHAT_FLOW_ENTITY_TYPES_PENDING:
      return merge(state, { loading: true, loaded: false });

    default:
      return state;
  }
};

export default chatFlowsReduceer;
