import { connect } from 'react-redux';
import authEmployee from 'store/selectors/authEmployee';
import availableEmployees from 'store/selectors/availableEmployees';
import currentChannel from 'store/selectors/currentChannel';

const mapStateToProps = state => ({
  channel: currentChannel(state),
  authEmployee: authEmployee(state),
  availableEmployees: availableEmployees(state)
    .filter(e => e.isAcceptTerms)
    .map(e => {
      const { name, _id: id, avatar, isAcceptTerms } = e;
      return { name, id, display: name, avatar, isAcceptTerms };
    }),
});

const mapDispatchToProps = () => ({
  actions: {},
});

export default connect(mapStateToProps, mapDispatchToProps);
