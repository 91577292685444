import { connect } from 'react-redux';
import currentChannel from 'store/selectors/currentChannel';
import authEmployee from 'store/selectors/authEmployee';
import isMember from 'store/selectors/isMember';

const mapStateToProps = state => ({
  employee: authEmployee(state),
  channel: currentChannel(state),
  isMember: isMember(state),
});

const mapDispatchToProps = () => ({
  actions: {},
});

export default connect(mapStateToProps, mapDispatchToProps);
