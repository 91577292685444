import withStyles from '@mui/styles/withStyles';
import { func, object, oneOfType, string } from 'prop-types';
import React from 'react';

const styles = {
  root: {
    position: 'absolute',
    top: 0,
    opacity: 0,
  },
};

const acceptDefault = `
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/gif',
    'audio/mp3',
    'audio/mpeg',
    'audio/aac',
    'audio/ogg',
    'audio/m4a',
    'video/mp4',
    'video/avi',
    'video/mpeg',
    'application/pdf',
    'application/msword',
    'application/vnd.ms-excel',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/wps-office.docx'
  `.replace(/\s/g, '');

const FileInput = ({ classes, onChange, inputRef, accept, ...rest }) => {
  return (
    <input
      type="file"
      ref={inputRef}
      onChange={onChange}
      className={classes.root}
      accept={accept}
      {...rest}
    />
  );
};

FileInput.propTypes = {
  classes: object.isRequired,
  inputRef: oneOfType([func, object]).isRequired,
  onChange: func.isRequired,
  accept: string,
};

FileInput.defaultProps = {
  accept: acceptDefault,
};

export default withStyles(styles)(FileInput);
