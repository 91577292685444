/* eslint-disable react/jsx-boolean-value */
import { MenuItem, Select } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import EmployeeAvatar from 'components/@home/EmployeeAvatar';
import { bool, func, object, string } from 'prop-types';
import React, { Component } from 'react';
import channelShape from 'shapes/channel';
import employeeShape from 'shapes/employee';
import connector from './connector';

const styles = theme => ({
  root: {
    marginLeft: theme.spacing(1.5),
    border: `1px solid ${theme.palette.secondary.extraLight}`,
    borderRadius: 5,
    boxShadow: '0 1px 2px 0 rgba(2, 2, 3, 0.05)',
    '& .MuiOutlinedInput-notchedOutline': {
      display: 'none',
    },
  },
  select: {
    padding: 3,
    paddingRight: '24px !important',
  },
  input: {
    paddingRight: 24,
  },
  icon: {
    right: 0,
  },
});

class ChatSelect extends Component {
  handleChange = ({ target: { name, value } }) => {
    const { onChange } = this.props;
    onChange(name, value);
  };

  render() {
    const { classes, name, value, employee, channel, isMember } = this.props;
    if (!employee) return null;

    return (
      <Select
        className={classes.root}
        classes={classes}
        name={name}
        value={!isMember || value}
        onChange={this.handleChange}
      >
        {isMember && (
          <MenuItem value={false}>
            <EmployeeAvatar employee={employee} />
          </MenuItem>
        )}
        <MenuItem value={true}>
          <EmployeeAvatar employee={{ name: channel.name, avatar: channel.avatar }} />
        </MenuItem>
      </Select>
    );
  }
}

ChatSelect.propTypes = {
  classes: object.isRequired,
  employee: employeeShape,
  channel: channelShape.isRequired,
  name: string.isRequired,
  value: bool.isRequired,
  onChange: func.isRequired,
  isMember: bool.isRequired,
};

ChatSelect.defaultProps = {
  employee: null,
};

export default withStyles(styles)(connector(ChatSelect));
