export const OPEN_SETTINGS_DRAWER = 'OPEN_SETTINGS_DRAWER';
export const CLOSE_SETTINGS_DRAWER = 'CLOSE_SETTINGS_DRAWER';

const open = () => ({
  type: OPEN_SETTINGS_DRAWER,
});

const close = () => ({
  type: CLOSE_SETTINGS_DRAWER,
});

export default { open, close };
