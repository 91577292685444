import Http from 'services/Http';
import store from 'store';
import authCompanyId from 'store/selectors/authCompanyId';
import parseFileName from 'utils/getFileExtension';

class SharedFile {
  async upload(folderId, file, onUploadProgress, getCancelFunc, fileName) {
    const companyId = authCompanyId(store.getState());
    if (!companyId) {
      throw new Error('No company');
    }
    const { type: mimeType } = file;
    const name = fileName || file.name;
    const { ext } = parseFileName(name);
    const {
      data: { id, url },
    } = await Http.post(`/v1/shared-file/${folderId}/sign-url`, { mimeType, ext, folderId });
    await Http.putS3(url, file, {
      headers: {
        'x-amz-tagging': `company=${companyId}`,
        'Content-Type': mimeType,
        'Content-Length': file.size,
        'Cache-Control': 'max-age=1209600',
      },
      timeout: 0,
      onUploadProgress,
      getCancelFunc,
    });
    const response = await Http.patch(`/v1/shared-file/${folderId}/sign-url/uploaded`, {
      id,
      name,
      folderId,
    });
    return response.data;
  }
}

export default new SharedFile();
