import { connect } from 'react-redux';
import authCompany from 'store/selectors/authCompany';
import { getMagicLink } from 'utils/getMagicLink';

const mapStateToProps = (state, { employee }) => {
  const company = authCompany(state);
  return { company, magicLink: employee.magicLink || getMagicLink(company, employee) };
};

const mapDispatchToProps = () => ({
  actions: {},
});

export default connect(mapStateToProps, mapDispatchToProps);
