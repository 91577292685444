import { makeStyles } from '@mui/styles';
import Autocomplete from 'components/controls/AutocompleteField';
import { func } from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'utils/i18n';
import languagesActions from 'store/app/entities/config/languages/action';
import authCompany from 'store/selectors/authCompany';
import isSuperAdminSelector from 'store/selectors/isSuperAdmin';
import useActions from 'utils/useActions';

import SelectTitle from '../SelectTitle';

const useStyles = makeStyles(() => ({
  root: {
    paddingRight: '20px',
  },
}));

const generateLanguageLabel = option => `${option.name} (${option.shortName.toUpperCase()})`;

const findLanguageValue = (options, value) => options.find(option => option.shortName === value);

const Language = ({ onChange }) => {
  const loadLanguages = useActions(languagesActions.loadLanguages);
  const classes = useStyles();
  const {
    languages: allLanguages,
    loaded,
    loading,
    error,
  } = useSelector(state => state.config.languages);
  const locale = useSelector(state => state.i18n.locale);
  const company = useSelector(authCompany);
  const isSuperAdmin = useSelector(isSuperAdminSelector);
  const languages = useMemo(
    () =>
      isSuperAdmin
        ? allLanguages
        : allLanguages.filter(
            lang => !company.languages || company.languages.includes(lang.shortName),
          ),
    [allLanguages, company.languages, isSuperAdmin],
  );
  useEffect(() => {
    if (!loaded && !loading && !error) {
      loadLanguages();
    }
  }, [error, loadLanguages, loaded, loading]);

  return (
    <div className={classes.root}>
      <SelectTitle headline={I18n.t('SettingsDrawer.Language')} />
      <Autocomplete
        autoWidth
        placeholder={loading ? I18n.t('Loading languages...') : I18n.t('Choose language')}
        options={languages.map(option => ({
          ...option,
          label: generateLanguageLabel(option),
          value: option.shortName,
        }))}
        value={locale}
        onChange={onChange}
        labelGenerator={generateLanguageLabel}
        findValue={findLanguageValue}
      />
    </div>
  );
};

Language.propTypes = {
  onChange: func.isRequired,
};

Language.defaultProps = {};

export default Language;
