import generatePath from 'utils/generatePath';
import {
  OPEN_ACKNOWLEDGMENT_CREATE_MODAL,
  CLOSE_ACKNOWLEDGMENT_CREATE_MODAL,
  SAVE_UPLOADED_ACKNOWLEDGMENT_PDF,
} from './action';

const initialState = {
  isOpen: false,
  tempAttachment: null,
};

const createAttachment = a => ({
  _id: a._id,
  user_id: a.user?._id || a.user,
  httpLink: generatePath(a.httpLink),
  meta: a.meta,
  name: a.name,
  size: a.size,
  type: a.type,
  mimeType: a.mimeType,
  createdAt: a.createdAt,
});

const createModal = (state = initialState, { type, payload }) => {
  switch (type) {
    case OPEN_ACKNOWLEDGMENT_CREATE_MODAL:
      return {
        ...state,
        isOpen: true,
      };

    case CLOSE_ACKNOWLEDGMENT_CREATE_MODAL:
      return {
        ...state,
        isOpen: false,
      };

    case SAVE_UPLOADED_ACKNOWLEDGMENT_PDF:
      return {
        ...state,
        tempAttachment: createAttachment(payload),
      };

    default:
      return state;
  }
};

export default createModal;
