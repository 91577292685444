import Message from 'services/api/Message';

export const OPEN_DIGITAL_SIGNATURE_READ_MODAL = 'OPEN_DIGITAL_SIGNATURE_READ_MODAL';
export const OPEN_DIGITAL_SIGNATURE_READ_MODAL_FULFILLED =
  'OPEN_DIGITAL_SIGNATURE_READ_MODAL_FULFILLED';
export const OPEN_ACKNOWLEDGMENT_READ_MODAL = 'OPEN_ACKNOWLEDGMENT_READ_MODAL';
export const CLOSE_ACKNOWLEDGMENT_READ_MODAL = 'CLOSE_ACKNOWLEDGMENT_READ_MODAL';

const open = (message, channel, employee, log = false) =>
  message.digitalSignature &&
  !message.acknowledges.find(
    a => a.user.toString() === employee.user_id || employee.user?._id.toString(),
  )
    ? {
        type: OPEN_DIGITAL_SIGNATURE_READ_MODAL,
        payload: Message.digitalSignature(message._id),
        meta: { message, employee, channel },
      }
    : {
        type: OPEN_ACKNOWLEDGMENT_READ_MODAL,
        payload: {
          message,
          employee,
          channel,
          log,
        },
      };

const close = () => ({
  type: CLOSE_ACKNOWLEDGMENT_READ_MODAL,
});

export default { open, close };
