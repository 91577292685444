import Box from '@mui/material/Box';
import Loading from 'components/common/Loading';
import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { LAUNCHER_APP_ID } from 'services/constants';
import { useGetQuery } from 'store/app/entities/AppDataSlice';
import authCompanySelector from 'store/selectors/authCompany';

const styles = {
  root: {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
};

const LauncherScene = () => {
  const authCompany = useSelector(authCompanySelector);
  const isLauncherAppInstalled = authCompany?.apps?.includes(LAUNCHER_APP_ID);
  const { data, isFetching } = useGetQuery(
    { appId: LAUNCHER_APP_ID, include: 'launcher-links' },
    { skip: !isLauncherAppInstalled },
  );
  if (!isLauncherAppInstalled) {
    return null;
  }
  return (
    <Box sx={styles.root}>
      {isFetching ? <Loading /> : <Outlet context={data || { links: [] }} />}
    </Box>
  );
};

export default LauncherScene;
