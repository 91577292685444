import { Grid } from '@mui/material';
import moment from 'moment';
import { array, bool, func } from 'prop-types';
import React from 'react';
import WorkingHours from '../WorkingHours';

const WorkingDays = props => {
  const { value, onChange, loading } = props;

  const wds =
    value &&
    value.map((v, idx) => {
      const wd = moment().weekday(idx);
      return {
        shifts: value[wd.isoWeekday() - 1],
        day: wd.format('dddd'),
        idx: wd.isoWeekday() - 1,
      };
    });

  const handleOnChange = (idx, v) => {
    const newValue = [...value];
    newValue[idx] = v;
    onChange(newValue);
  };

  return (
    <Grid item xs={12}>
      {wds &&
        wds.map(wd => (
          <WorkingHours
            loading={loading}
            key={wd.day}
            onChange={v => handleOnChange(wd.idx, v)}
            {...wd}
          />
        ))}
    </Grid>
  );
};

WorkingDays.propTypes = {
  value: array.isRequired,
  onChange: func.isRequired,
  loading: bool.isRequired,
};

export default WorkingDays;
