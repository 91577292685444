import makeStyles from '@mui/styles/makeStyles';
import SearchField from 'components/controls/SearchField';
import { func, string } from 'prop-types';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 20,
  },
  searchIconButton: {
    marginLeft: 5,
  },
  searchIcon: {
    fontSize: 16,
  },
}));

const Search = ({ value, onChange }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SearchField onChange={onChange} value={value} />
    </div>
  );
};

Search.propTypes = {
  value: string,
  onChange: func.isRequired,
};
Search.defaultProps = {
  value: '',
};

export default Search;
