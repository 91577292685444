export const SET_HEADER_ICON = 'SET_HEADER_ICON';
export const TOGGLE_CHANNELS_PANEL = 'TOGGLE_CHANNELS_PANEL';

const back = url => ({
  type: SET_HEADER_ICON,
  payload: { icon: 'back', url },
});

const menu = () => ({
  type: SET_HEADER_ICON,
  payload: { icon: 'menu' },
});

const toggleChannelsPanel = () => ({
  type: TOGGLE_CHANNELS_PANEL,
});

export default { back, menu, toggleChannelsPanel };
