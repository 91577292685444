import { Tooltip } from '@mui/material';
import { any, bool } from 'prop-types';
import React from 'react';

const TooltipIfDisabled = ({ children, disabled, ...rest }) =>
  // eslint-disable-next-line react/jsx-props-no-spreading
  disabled ? <Tooltip {...rest}>{children}</Tooltip> : <>{children}</>;

TooltipIfDisabled.propTypes = {
  children: any.isRequired,
  disabled: bool,
};

TooltipIfDisabled.defaultProps = {
  disabled: false,
};
export default TooltipIfDisabled;
