import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import media from 'store/app/ui/media/action';
import acknowledgmentReadModal from 'store/app/ui/modals/acknowledgment/read/action';
import documentModal from 'store/app/ui/modals/document/action';
import currentChannel from 'store/selectors/currentChannel';
import authUser from 'store/selectors/authUser';
import authEmployee from 'store/selectors/authEmployee';

const mapStateToProps = state => ({
  channel: currentChannel(state),
  authUser: authUser(state),
  employee: authEmployee(state),
});

const mapDispatchToProps = dispatch => ({
  actions: {
    documentModal: bindActionCreators(documentModal, dispatch),
    acknowledgmentReadModal: bindActionCreators(acknowledgmentReadModal, dispatch),
    media: bindActionCreators(media, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
