import { Box, Drawer } from '@mui/material';
import SendDrawerContent from 'components/@home/@documents/SentDocuments/SendDrawerContent';
import { SendDrawerContext } from 'components/@home/@documents/SentDocuments/SendDrawerContext';
import BackButton from 'components/@home/drawers/BackButton';
import CloseButton from 'components/@home/drawers/CloseButton';
import React, { useContext } from 'react';
import useOpenClose from 'utils/useOpenClose';

const SendDrawer = () => {
  const { isOpen, close } = useContext(SendDrawerContext);
  const [isOpenConfig, openConfig, closeConfig, { currentFile }] = useOpenClose();

  return (
    <Drawer anchor="right" open={isOpen} onClose={close} onClick={e => e.stopPropagation()}>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          maxWidth: 'calc(100vw - 40px)',
          width: { xs: '100%', md: 1280 },
        }}
      >
        <Box sx={{ paddingTop: 2.5, paddingLeft: 2.5 }}>
          {isOpenConfig ? <BackButton onClick={closeConfig} /> : <CloseButton onClick={close} />}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            minWidth: 500,
            flexDirection: 'column',
            padding: 2.5,
            height: '100vh',
          }}
        >
          <SendDrawerContent
            isOpen={isOpen}
            isOpenConfig={isOpenConfig}
            openConfig={openConfig}
            closeConfig={closeConfig}
            currentFile={currentFile}
          />
        </Box>
      </Box>
    </Drawer>
  );
};

export default SendDrawer;
