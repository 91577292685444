import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Box, ButtonBase, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    gap: 2,
  },
  spacer: {
    width: 4,
  },
  stepsContainer: {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
  },
  stepButton: isActive => ({
    padding: 0,
    minWidth: 'auto',
    bgcolor: 'secondary.light',
    transition: 'all 0.5s ease',
    width: isActive ? '50px' : '8px',
    height: '8px',
    borderRadius: '8px',
    position: 'relative',
    overflow: 'hidden',
    cursor: isActive ? 'default' : 'pointer',
    '&:hover': {
      bgcolor: isActive ? 'secondary.light' : 'secondary.main',
    },
  }),
  progressBar: (stepDuration, progress) => ({
    height: '100%',
    bgcolor: 'primary.main',
    position: 'absolute',
    top: 0,
    left: 0,
    transition: `width ${stepDuration / 100000}s linear`,
    borderRadius: '8px',
    width: `${progress}%`,
  }),
};

const CSSStyledStepper = ({ steps, stepDuration, activeStep, setActiveStep }) => {
  const [paused, setPaused] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let interval;

    if (!paused) {
      interval = setInterval(() => {
        setProgress(prevProgress => prevProgress + 1);
      }, stepDuration / 100); // Update progress every 1% of the step duration
    }

    if (progress >= 100) {
      setActiveStep(prevStep => (prevStep + 1) % steps);
      setProgress(0); // Reset progress after completing the step
    }

    return () => clearInterval(interval);
  }, [progress, paused, stepDuration, setActiveStep, steps]);

  const handlePause = () => {
    setPaused(!paused);
  };

  const handleStepClick = index => {
    setActiveStep(index);
    setProgress(0);
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.spacer} />
      <Box sx={styles.stepsContainer}>
        {[...Array(steps)].map((_, index) => (
          <ButtonBase
            key={`step-${index + 1}`}
            sx={styles.stepButton(index === activeStep)}
            onClick={() => index !== activeStep && handleStepClick(index)}
            disabled={index === activeStep}
            aria-label={`Go to step ${index + 1}`}
          >
            {index === activeStep && <Box sx={styles.progressBar(stepDuration, progress)} />}
          </ButtonBase>
        ))}
      </Box>

      <IconButton sx={{ color: 'secondary.light' }} onClick={handlePause}>
        {paused ? <PlayArrowIcon /> : <PauseIcon />}
      </IconButton>
    </Box>
  );
};

CSSStyledStepper.propTypes = {
  steps: PropTypes.number.isRequired,
  stepDuration: PropTypes.number.isRequired,
  activeStep: PropTypes.number.isRequired,
  setActiveStep: PropTypes.func.isRequired,
};

export default CSSStyledStepper;
