import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import remoteDrawer from 'store/app/ui/drawers/remote/action';

const mapStateToProps = state => ({
  isOpen: state.drawers.remote.isOpen,
  url: state.drawers.remote.url,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    drawers: {
      remote: bindActionCreators(remoteDrawer, dispatch),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
