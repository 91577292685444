import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    border: 'none',
    boxShadow: 'none',
    backgroundColor: theme.palette.secondary.ultraUltraLight,
    borderBottom: `1px solid ${theme.palette.divider}`,
    width: '100%',
    textAlign: 'left',
  },
  imgWrapper: {
    marginTop: 4,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  contentWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  content: {
    flex: 1,
    color: theme.palette.secondary.main,
  },
  border: {
    width: 3,
    marginRight: 5,
    borderRadius: 3,
    backgroundColor: theme.palette.primary.light,
    margin: '8px 4px 4px 0px',
  },
  image: {
    width: 35,
    height: 35,
    marginRight: 5,
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  iconWrapper: {
    flexDirection: 'row',
  },
  unpin: {
    marginRight: 10,
  },
}));

export default useStyles;
