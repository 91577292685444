import DocumentFormat from 'services/api/DocumentFormat';

export const CREATE_FORMAT = 'CREATE_FORMAT';
export const CREATE_FORMAT_FULFILLED = 'CREATE_FORMAT_FULFILLED';
export const CREATE_FORMAT_PENDING = 'CREATE_FORMAT_PENDING';

export const DESTROY_FORMAT = 'DESTROY_FORMAT';
export const DESTROY_FORMAT_FULFILLED = 'DESTROY_FORMAT_FULFILLED';
export const DESTROY_FORMAT_PENDING = 'DESTROY_FORMAT_PENDING';

export const LOAD_FORMATS = 'LOAD_FORMATS';
export const LOAD_FORMATS_FULFILLED = 'LOAD_FORMATS_FULFILLED';
export const LOAD_FORMATS_PENDING = 'LOAD_FORMATS_PENDING';

export const SET_FORMATS = 'SET_FORMATS';

export const UPDATE_FORMAT = 'UPDATE_FORMAT';
export const UPDATE_FORMAT_FULFILLED = 'UPDATE_FORMAT_FULFILLED';
export const UPDATE_FORMAT_PENDING = 'UPDATE_FORMAT_PENDING';

const load = () => ({
  type: LOAD_FORMATS,
  payload: DocumentFormat.list(),
});

const create = form => ({
  type: CREATE_FORMAT,
  payload: DocumentFormat.create(form),
});

const set = formats => ({
  type: SET_FORMATS,
  payload: formats,
});

const update = (id, form) => ({
  type: UPDATE_FORMAT,
  payload: DocumentFormat.update(id, form),
});

const destroy = id => ({
  type: DESTROY_FORMAT,
  payload: DocumentFormat.destroy(id),
  meta: {
    formatId: id,
  },
});

export default { create, destroy, load, set, update };
