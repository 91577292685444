import { SAVE_UPLOADED_ACKNOWLEDGMENT_PDF } from 'store/app/ui/modals/acknowledgment/create/action';
import generatePath from 'utils/generatePath';
import { CLOSE_ACKNOWLEDGE_CREATE_DRAWER, OPEN_ACKNOWLEDGE_CREATE_DRAWER } from './action';

const initialState = {
  isOpen: false,
  tempAttachment: null,
};

const createAttachment = a => ({
  _id: a._id,
  user_id: a.user?._id || a.user,
  httpLink: generatePath(a.httpLink),
  meta: a.meta,
  name: a.name,
  size: a.size,
  type: a.type,
  mimeType: a.mimeType,
  createdAt: a.createdAt,
});

const formDrawer = (state = initialState, { type, payload }) => {
  switch (type) {
    case OPEN_ACKNOWLEDGE_CREATE_DRAWER:
      return {
        ...state,
        ...payload,
        isOpen: true,
      };

    case CLOSE_ACKNOWLEDGE_CREATE_DRAWER:
      return {
        ...state,
        isOpen: false,
        tempAttachment: null,
      };

    case SAVE_UPLOADED_ACKNOWLEDGMENT_PDF:
      return {
        ...state,
        tempAttachment: createAttachment(payload),
      };

    default:
      return state;
  }
};

export default formDrawer;
