import { debounce } from '@mui/material/utils';
import { GridToolbarContainer, useGridApiContext, useGridSelector } from '@mui/x-data-grid';
import SearchField from 'components/controls/SearchField';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useState } from 'react';

const defaultSearchValueParser = searchText =>
  searchText
    .split(' ')
    .map(word => word.trim())
    .filter(word => word !== '');

const GridToolbarSearch = ({ debounceMs = 500, forceSearch, children, ...other }) => {
  const apiRef = useGridApiContext();
  const gridState = useGridSelector(
    apiRef,
    ({
      filter: {
        filterModel: { quickFilterValues },
      },
      pagination: { pageCount },
    }) => ({
      quickFilterValues,
      pageCount,
    }),
  );

  const [value, setValue] = useState(gridState.quickFilterValues.join(' '));
  const updateSearchValue = React.useCallback(
    newSearchValue => {
      apiRef.current.setQuickFilterValues(defaultSearchValueParser(newSearchValue));
    },
    [apiRef],
  );

  const debouncedUpdateSearchValue = React.useMemo(
    () => debounce(updateSearchValue, debounceMs),
    [updateSearchValue, debounceMs],
  );

  const handleSearchValueChange = React.useCallback(
    event => {
      const newSearchValue = event.target.value;
      debouncedUpdateSearchValue(newSearchValue);
      setValue(newSearchValue);
    },
    [debouncedUpdateSearchValue],
  );

  if (!forceSearch && !value && gridState.pageCount <= 1) {
    return null;
  }

  return (
    <GridToolbarContainer sx={{ mt: 3 }}>
      <SearchField value={value} onChange={handleSearchValueChange} {...other} />
      {children}
    </GridToolbarContainer>
  );
};

GridToolbarSearch.propTypes = {
  /**
   * The debounce time in milliseconds.
   * @default 500
   */
  debounceMs: PropTypes.number,
  children: PropTypes.node,
  forceSearch: PropTypes.bool,
};

GridToolbarSearch.defaultProps = {
  debounceMs: 500,
  children: null,
  forceSearch: false,
};

export default GridToolbarSearch;
