import withStyles from '@mui/styles/withStyles';
import { bool, object, shape, string } from 'prop-types';
import React from 'react';
import Modal from '../Modal';
import connector from './connector';

const styles = {
  root: {},
  picture: {
    width: '100%',
    height: '100%',
    maxWidth: '90vw',
    maxHeight: '90vh',
  },
};

const PictureModal = ({ classes, src, actions, isOpen }) => {
  if (!src && !isOpen) return null;

  return (
    <Modal isOpen={isOpen} onClose={actions.pictureModal.close}>
      <div className={classes.root}>
        <img className={classes.picture} alt="modal" src={src} />
      </div>
    </Modal>
  );
};

PictureModal.propTypes = {
  classes: object.isRequired,
  src: string,
  isOpen: bool.isRequired,
  actions: shape({
    pictureModal: object.isRequired,
  }).isRequired,
};

PictureModal.defaultProps = {
  src: null,
};

export default withStyles(styles)(connector(PictureModal));
