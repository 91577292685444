/* eslint-disable react/jsx-props-no-spreading */
import Autocomplete from 'components/controls/AutocompleteField';
import { array, bool, func, object, shape, string } from 'prop-types';
import React, { useEffect } from 'react';
import { I18n } from 'utils/i18n';
import connector from './connector';

const CountryCode = ({
  countryCodes: { codes, loading },
  locale,
  actions: {
    config: { countryCodes },
  },
  onLoad,
  value,
  ...restProps
}) => {
  const loaded = codes[locale]?.length > 0 && codes[locale][0].tz;
  useEffect(() => {
    if (!loaded && !loading) {
      countryCodes.load(locale);
    } else if (loaded && !loading) {
      onLoad(codes);
    }
  }, [countryCodes, loaded, locale]);

  const generateLabel = suggestion =>
    `${suggestion.name} (${suggestion.code}) ${suggestion.dial_code}`;

  const findLanguageValue = (options, val) => {
    return options?.find(option => option.code === val);
  };

  return (
    <Autocomplete
      autoWidth
      placeholder={
        loading ? I18n.t('Login.Loading country codes') : I18n.t('Login.Choose country prefix')
      }
      options={codes[locale]?.map(option => ({
        ...option,
        label: generateLabel(option),
        value: option,
      }))}
      value={value}
      labelGenerator={generateLabel}
      findValue={findLanguageValue}
      {...restProps}
    />
  );
};

CountryCode.propTypes = {
  locale: string.isRequired,
  actions: object.isRequired,
  value: string.isRequired,
  countryCodes: shape({
    codes: array,
    loading: bool,
  }).isRequired,
  onLoad: func,
};

CountryCode.defaultProps = {
  onLoad: () => {},
};

export default connector(CountryCode);
