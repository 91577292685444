import { combineReducers } from 'redux';
import Alert from './Alert/reducer';
import ChannelsPanel from './ChannelsPanel/reducer';
import ChatPanel from './ChatPanelSlice';
import Dialog from './Dialog/reducer';
import Focus from './focus/reducer';
import People from './People/reducer';

export default combineReducers({
  Alert,
  Dialog,
  ChannelsPanel,
  ChatPanel,
  Focus,
  People,
});
