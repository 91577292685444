import { Button, SvgIcon } from '@mui/material';
import CloseIcon from 'mdi-react/CloseIcon';
import { func, object } from 'prop-types';
import React, { memo } from 'react';

const styles = {
  root: {
    minWidth: 0,
    minHeight: 0,
    padding: '6px 7px',
    backgroundColor: 'transparent',
  },
  arrowIcon: {
    fontSize: 24,
    color: 'primary.dark',
  },
};

const CloseButton = ({ onClick, sx }) => (
  <Button onClick={onClick} sx={{ ...styles.root, ...sx }} color="secondary" variant="outlined">
    <SvgIcon sx={styles.arrowIcon}>
      <CloseIcon />
    </SvgIcon>
  </Button>
);
CloseButton.propTypes = {
  onClick: func.isRequired,
  sx: object,
};
CloseButton.defaultProps = {
  sx: {},
};
export default memo(CloseButton);
