import {
  CREATE_TEAM_FULFILLED,
  DESTROY_TEAM_FULFILLED,
  FIND_TEAM_FULFILLED,
  FIND_TEAM_PENDING,
  FIND_TEAM_REJECTED,
  LOAD_TEAMS_FULFILLED,
  LOAD_TEAMS_PENDING,
  LOAD_TEAMS_REJECTED,
  UPDATE_TEAM_FULFILLED,
} from 'store/app/entities/teams/action';
import arrayToObject from 'utils/arrayToObject';
import merge from 'utils/merge';

export const createTeam = t => ({
  _id: t._id,
  name: t.name,
  description: t.description,
  initials: t.name
    .match(/(?:^| )./g)
    .slice(0, 2)
    .join('')
    .replace(' ', '')
    .toUpperCase(),
  updatedAt: t.updatedAt,
  users: t.users,
  parent: t.parent,
  groups: t.groups,
  children: t.children,
  defaultRole: t.defaultRole,
});

const initialState = {
  error: null,
  fulfilledTimeStamp: null,
  isError: false,
  isFetching: false,
  isLoading: false,
  isSuccess: false,
  isUninitialized: true,
  startedTimeStamp: null,
  teams: {},
  metadata: {},
  rehydrated: false,
};
const teamsReducer = (state = initialState, { type, payload, meta }) => {
  switch (type) {
    case LOAD_TEAMS_PENDING:
      return merge(state, {
        isLoading: !!state.isUninitialized,
        isFetching: true,
        isSuccess: false,
        isError: false,
        error: null,
        startedTimeStamp: Date.now(),
      });

    case LOAD_TEAMS_FULFILLED: {
      const teams = payload.teams.map(c => createTeam(merge(state.teams[c._id], c)));

      return merge(state, {
        teams: arrayToObject(teams),
        fulfilledTimeStamp: Date.now(),
        isError: false,
        isFetching: false,
        isLoading: false,
        isSuccess: true,
        isUninitialized: false,
        rehydrated: false,
      });
    }

    case LOAD_TEAMS_REJECTED:
      return {
        ...state,
        loading: false,
        isLoading: false,
        error: payload,
        fulfilledTimeStamp: null,
        isFetching: false,
        isError: true,
        isSuccess: false,
      };

    case FIND_TEAM_PENDING: {
      return merge(state, { isFetching: true });
    }

    case CREATE_TEAM_FULFILLED:
    case UPDATE_TEAM_FULFILLED:
    case FIND_TEAM_FULFILLED: {
      const team = createTeam(merge({ ...state.teams[payload._id] }, payload));
      return merge(state, { isFetching: false, teams: { ...state.teams, [payload._id]: team } });
    }

    case FIND_TEAM_REJECTED:
      return {
        ...state,
        isFetching: false,
      };

    case DESTROY_TEAM_FULFILLED: {
      const teams = { ...state.teams };
      payload.teams.forEach(teamId => delete teams[teamId]);
      return merge(state, { teams });
    }

    default:
      return state;
  }
};

export default teamsReducer;
