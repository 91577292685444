import PropTypes from 'prop-types';
import React from 'react';
import PhotoUpload from 'components/controls/PhotoUpload';
import { useFormContext } from 'react-hook-form';
import { LAUNCHER_APP_ID } from 'services/constants';
import { useUploadImageMutation } from 'store/app/entities/AppDataSlice';

const LauncherAppImageUpload = props => {
  const [uploadImage] = useUploadImageMutation();
  const { watch } = useFormContext();
  const value = watch(props.name);
  const handleChange = async image => {
    const { data } = await uploadImage({ appId: LAUNCHER_APP_ID, image, name: props.name });
    if (data.status) {
      props.onChange({
        target: { value: data.url, name: props.name },
      });
    }
  };
  return <PhotoUpload {...props} value={value} onChange={handleChange} />;
};

LauncherAppImageUpload.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

LauncherAppImageUpload.defaultProps = {
  onChange: () => {},
};

export default LauncherAppImageUpload;
