import { Avatar, Box, IconButton, SvgIcon, Typography } from '@mui/material';
import MessageFrom from 'components/@home/@messages/ChannelsPanel/MessageFrom/MessageFrom';
import MessagePreview from 'components/@home/@messages/ChatPanel/PinnedMessage/MessagePreview';
import CloseIcon from 'mdi-react/CloseIcon';
import EditIcon from 'mdi-react/EditIcon';
import ReplyIcon from 'mdi-react/ReplyIcon';
import { object, oneOf } from 'prop-types';
import React from 'react';
import channelShape from 'shapes/channel';
import messageShape from 'shapes/message';

import connector from './connector';

const icons = {
  reply: ReplyIcon,
  edit: EditIcon,
};

const ContextMessage = ({ actions, message, variant, currentChannel }) => {
  const Icon = icons[variant];
  return (
    <Box
      sx={{
        borderTop: 1,
        borderColor: 'secondary.extraLight',
        display: 'flex',
        flexDirection: 'row',
        gap: 1,
        background: theme => theme.palette.white,
        px: 1.5,
        py: 1,
        alignItems: 'center',
      }}
    >
      <SvgIcon sx={{ color: 'primary.main', fontSize: 32 }}>
        <Icon />
      </SvgIcon>
      {message.attachments[0]?.meta?.thumb && (
        <Box>
          <Avatar
            variant="rounded"
            sx={{ width: 42, height: 42 }}
            src={message.attachments[0].meta.thumb}
          />
        </Box>
      )}
      <Box sx={{ flexGrow: 1 }}>
        <Typography sx={{ fontWeight: 500, color: 'primary.main' }}>
          <MessageFrom message={message} />
        </Typography>
        <MessagePreview message={message} showImages={false} />
      </Box>
      <IconButton onClick={() => actions.chatPanel.removeContextMessage(currentChannel._id)}>
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

ContextMessage.propTypes = {
  actions: object.isRequired,
  message: messageShape.isRequired,
  variant: oneOf(['edit', 'reply']).isRequired,
  currentChannel: channelShape.isRequired,
};

export default connector(ContextMessage);
