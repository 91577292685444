import { Snackbar, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MuiAlert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { bool, func, number, oneOf, string } from 'prop-types';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  title: {
    marginBottom: '0.5em',
  },
}));

const Alert = ({ autoHideDuration, isOpen, onClose, variant, children, label, caption }) => {
  const classes = useStyles();

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isOpen}
      onClose={onClose}
      autoHideDuration={autoHideDuration}
    >
      <MuiAlert elevation={6} severity={variant} onClose={onClose}>
        <div className={classes.root}>
          <div>
            <AlertTitle className={classes.title}>{label}</AlertTitle>
            <Typography color="inherit" variant="body1">
              {children}
            </Typography>
            <Typography color="inherit" variant="caption">
              {caption}
            </Typography>
          </div>
        </div>
      </MuiAlert>
    </Snackbar>
  );
};

Alert.propTypes = {
  isOpen: bool.isRequired,
  variant: oneOf(['success', 'warning', 'error', 'info']).isRequired,
  children: string,
  label: string,
  caption: string,
  onClose: func,
  autoHideDuration: number,
};

Alert.defaultProps = {
  label: '',
  children: null,
  onClose: () => {},
  caption: '',
  autoHideDuration: 3000,
};
export default Alert;
