import { Box, Typography } from '@mui/material';
import MessageList from 'components/@whistleblower-admin/drawers/OccurrenceDrawer/MessageList';
import SendMessageForm from 'components/@whistleblower-admin/drawers/OccurrenceDrawer/SendMessageForm';
import Section from 'components/@whistleblower-admin/drawers/Section';
import { useCanEdit } from 'components/@whistleblower-admin/WhistleblowerContext';
import ShieldTickIcon from 'mdi-react/ShieldTickIcon';
import React from 'react';
import { Translate } from 'utils/i18n';

const styles = {
  encrypted: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
};

const OccurrenceMessages = () => {
  const canEdit = useCanEdit('occurrences');
  return (
    <Section
      titleKey="OccurrenceDrawer.messages"
      titleRight={
        <Box sx={styles.encrypted}>
          <Box sx={{ color: 'info.main', display: 'flex' }}>
            <ShieldTickIcon size={16} />
          </Box>
          <Typography>
            <Translate value="OccurrenceDrawer.encrypted" />
          </Typography>
        </Box>
      }
    >
      <MessageList />
      {canEdit && <SendMessageForm />}
    </Section>
  );
};

export default OccurrenceMessages;
