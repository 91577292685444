import { Chip, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MessageGroup from 'components/@home/@messages/ChatPanel/MessagesList/MessageGroup';
import SendButton from 'components/@home/@messages/ChatPanel/SendForm/buttons/SendButton';
import ChatInput from 'components/@home/@messages/ChatPanel/SendForm/controls/ChatField/ChatInput';
import { ChatFlowContext } from 'components/@home/@settings/Tabs/ChatFlowContext';
import merge from 'lodash/merge';
import pickBy from 'lodash/pickBy';
import ArrowBackIcon from 'mdi-react/ArrowBackIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import ReloadIcon from 'mdi-react/ReloadIcon';
import { object } from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { I18n } from 'utils/i18n';
import connector from './connector';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    flexDirection: 'column',
    height: 'calc(100vh - 64px)',
    borderLeft: `solid 1px ${theme.palette.secondary.extraLight}`,
  },
  title: {
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    color: theme.palette.primary.ultraDark,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  close: {
    color: theme.palette.primary.ultraDark,
  },
  bodyWrapper: {
    flexGrow: 1,
    display: 'flex',
    position: 'relative',
    overflow: 'hidden',
    '&:before': {
      content: `''`,
      position: 'absolute',
      boxShadow: 'inset -2px 0 6px 1px rgba(0,0,0,0.2)',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 14,
      pointerEvents: 'none',
    },
  },
  body: {
    flexGrow: 1,
    overflow: 'auto',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    backgroundColor: theme.palette.secondary.ultraLight,
  },
  context: {
    padding: theme.spacing(2),
    fontSize: '0.7em',
  },
  chatInput: {
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingTop: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  send: {
    flexGrow: 1,
  },
  suggestions: {
    padding: theme.spacing(2),
    lineHeight: 2.5,
  },
  suggestion: {
    marginRight: theme.spacing(1),
  },
}));

const ChatFlowTest = ({ flow, actions }) => {
  const initialValue = { text: '' };
  const bodyRef = useRef();
  const [page, setPage] = useState(null);
  const [inputValue, setInputValue] = useState(initialValue);
  const [messages, setMessages] = useState([]);
  const [conversationContext, setConversationContext] = useState({});
  const suggestionsPortal = useRef();
  const classes = useStyles();
  const { context } = useContext(ChatFlowContext);
  const pushMessage = (message, isMine = false) =>
    setMessages(m => [
      ...m,
      { _id: `${Math.random()}`, employeeName: isMine ? undefined : flow.name, ...message, isMine },
    ]);
  const mergeContext = ctx => setConversationContext(prevCtx => merge(prevCtx, ctx));
  const removeFromContext = value => {
    setConversationContext(c => {
      const newContext = { ...c };
      delete newContext[value];
      return newContext;
    });
  };
  const setPageById = pageId => {
    if (!pageId) {
      return;
    }
    const newPage = flow.pages?.find(p => p._id === pageId);
    if (newPage) {
      setPage(newPage);
    }
  };

  useEffect(() => {
    setPageById(flow.startPage);
  }, []);

  useEffect(() => {
    const element = bodyRef.current;
    element.scrollTop = element.scrollHeight;
  }, [messages]);

  useEffect(() => {
    if (page?.entryMessages) {
      page.entryMessages.forEach(m => {
        pushMessage(m);
      });
    }
  }, [page]);

  const handleSubmit = async (e, textIn) => {
    e.preventDefault();
    const text = textIn || inputValue.text;
    setInputValue(initialValue);
    pushMessage({ text }, true);
    const { value } = await actions.chatFlows.message(
      flow._id,
      text,
      page._id,
      conversationContext,
    );
    mergeContext(value.context);
    if (value.entities) {
      mergeContext({ entities: value.entities });
    }
    if (value.slotFill) {
      mergeContext({ slotFill: value.slotFill });
    } else {
      removeFromContext('slotFill');
    }
    const route = value.matchedRoute;
    if (route) {
      route.replies.forEach(r => {
        pushMessage(r);
      });
      setPageById(route.transition);
      if (route.transition === flow.startPage) {
        setConversationContext({});
      }
    } else {
      pushMessage({ text: 'Lo siento no te he entendido :(' });
    }
  };
  const listenForEnter = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit(e);
    }
  };
  const lastMessage = messages[messages.length - 1];
  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <header className={classes.header}>
        <div className={classes.headerLeft}>
          {context.length > 1 && (
            <IconButton
              className={classes.close}
              edge="start"
              aria-label="close"
              onClick={context.pop}
              size="large"
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          <Typography size="small" className={classes.title}>
            {I18n.t('ChatFlowSettings.Test flow')}
          </Typography>
          <IconButton
            className={classes.close}
            edge="end"
            aria-label="close"
            onClick={() => {
              setPage(null);
              setConversationContext({});
              setMessages([]);
              setTimeout(() => {
                setPageById(flow.startPage);
              }, 10);
            }}
            size="large"
          >
            <ReloadIcon />
          </IconButton>
        </div>
        <div>
          <IconButton
            className={classes.close}
            edge="end"
            aria-label="close"
            onClick={context.clear}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </header>
      <div className={classes.context}>
        Context:
        <pre>
          {JSON.stringify(
            pickBy(conversationContext, (v, k) => k !== 'slotFill' && k !== 'entities'),
            undefined,
            2,
          )}
        </pre>
        {page && <div>Page: {page.name}</div>}
      </div>
      <section className={classes.bodyWrapper}>
        <div className={classes.body} ref={bodyRef}>
          <br />
          {messages.map(message => (
            <MessageGroup key={message._id} message={message} />
          ))}
        </div>
      </section>
      <footer>
        <div ref={suggestionsPortal} />
        {!!lastMessage?.suggestions?.length && (
          <div className={classes.suggestions}>
            {lastMessage.suggestions.map(s => (
              <Chip
                className={classes.suggestion}
                key={s}
                label={s}
                clickable
                onClick={async e => {
                  await handleSubmit(e, s);
                }}
              />
            ))}
          </div>
        )}
        <div className={classes.chatInput}>
          <div className={classes.send}>
            <ChatInput
              autoFocus
              onKeyPress={listenForEnter}
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
              name="text"
              placeholder={I18n.t('ChatFlowSettings.Write message')}
              suggestionsPortal={suggestionsPortal}
            />
          </div>
          <SendButton />
        </div>
      </footer>
    </form>
  );
};

ChatFlowTest.propTypes = {
  actions: object.isRequired,
  flow: object.isRequired,
};
export default connector(ChatFlowTest);
