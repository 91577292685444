export const OPEN_PROFILE_DRAWER = 'OPEN_PROFILE_DRAWER';
export const CLOSE_PROFILE_DRAWER = 'CLOSE_PROFILE_DRAWER';

const open = employee => ({
  type: OPEN_PROFILE_DRAWER,
  payload: employee,
});

const close = () => ({
  type: CLOSE_PROFILE_DRAWER,
});

export default { open, close };
