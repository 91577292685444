/**
 * @flow
 */
import Grid from '@mui/material/Unstable_Grid2';
import DrawerTitle from 'components/@home/drawers/MediaDrawer/DrawerTitle';
import OccurrenceContext from 'components/@whistleblower-admin/drawers/OccurrenceDrawer/OccurrenceContext';
import OccurrenceDetails from 'components/@whistleblower-admin/drawers/OccurrenceDrawer/OccurrenceDetails';
import OccurrenceMessages from 'components/@whistleblower-admin/drawers/OccurrenceDrawer/OccurrenceMessages';
import OccurrenceTools from 'components/@whistleblower-admin/drawers/OccurrenceDrawer/OccurrenceTools';
import { useDrawer } from 'components/common/Drawer/DrawerContext';
import React, { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSaveOccurrenceMutation } from 'store/app/entities/WbSlice';

const styles = {
  root: { width: '80vw', position: 'relative' },
  left: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
  },
};

const getDefaultValues = occurrence => {
  const { category, severity = '', status, closingCode, closingComment } = occurrence;
  return { category, severity, status, closingCode, closingComment };
};

const OccurrenceDrawerContent = () => {
  const { state: occurrence, setTitle, setOnSubmit } = useDrawer('OccurrenceDrawer');
  const methods = useForm({ defaultValues: getDefaultValues(occurrence) });
  const [save] = useSaveOccurrenceMutation();
  const onSubmit = useCallback(
    async body => {
      await save({ id: occurrence._id, body });
    },
    [occurrence._id, save],
  );
  useEffect(() => {
    setOnSubmit(methods.handleSubmit(onSubmit));
  }, [methods, onSubmit, setOnSubmit]);
  useEffect(() => {
    setTitle(<DrawerTitle title={occurrence.title} number={occurrence.number} />);
  }, [occurrence.number, occurrence.title, setTitle]);
  return (
    <OccurrenceContext.Provider value={occurrence}>
      <FormProvider {...methods}>
        <Grid container sx={styles.root} spacing={4}>
          <Grid xs={7} sx={styles.left}>
            <OccurrenceDetails />
            <OccurrenceMessages />
          </Grid>
          <Grid xs={5} sx={{ pt: 0 }}>
            <OccurrenceTools />
          </Grid>
        </Grid>
      </FormProvider>
    </OccurrenceContext.Provider>
  );
};

export default OccurrenceDrawerContent;
