import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { node, object } from 'prop-types';

const styles = theme => ({
  root: {
    height: '22px',
    marginTop: '5px',
    marginBottom: '5px',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSize: '15px',
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.47,
    letterSpacing: '-0.4px',
    color: theme.palette.text.primary,
  },
});

const SelectTitle = ({ classes, headline }) => <div className={classes.root}>{headline}</div>;

SelectTitle.propTypes = {
  classes: object.isRequired,
  headline: node.isRequired,
};

export default withStyles(styles)(SelectTitle);
