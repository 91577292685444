import { SvgIcon, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TimeTrackingBot from 'assets/time-tracking-bot.svg';
import React from 'react';
import { I18n } from 'utils/i18n';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: theme.spacing(3),
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '50%',
  },
  icon: {
    fontSize: 96,
  },
  title: {
    margin: '20px 0 20px 0',
    whiteSpace: 'pre-line',
  },
  infoBox: {
    background: theme.palette.secondary.ultraUltraLight,
    border: `1px solid ${theme.palette.secondary.extraLight}`,
    borderRadius: 6,
    padding: theme.spacing(4),
    '& ul': {
      padding: '0 0 0 40px',
      listStylePosition: 'outside',
      marginBottom: 0,
      color: theme.palette.secondary.light,
    },
  },
  characteristic: {
    color: theme.palette.black,
    verticalAlign: 'middle',
  },
  spaceAbove: {
    marginTop: theme.spacing(3),
  },
}));

const TimeTrackingBotHelp = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.column}>
        <SvgIcon className={classes.icon}>
          <TimeTrackingBot />
        </SvgIcon>
        <Typography variant="h5" component="h1" className={classes.title}>
          {I18n.t('TimeTrackingDrawer.title')}
        </Typography>
      </div>
      <div className={classes.column}>
        <div className={classes.infoBox}>
          <ul>
            {[1, 2, 3].map(char => (
              <li key={`char.${char}`}>
                <Typography display="inline" className={classes.characteristic}>
                  {I18n.t(`TimeTrackingDrawer.infoBox.items.${char}`)}
                </Typography>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TimeTrackingBotHelp;
