import React from 'react';
import { object } from 'prop-types';
import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { I18n } from 'utils/i18n';

const styles = theme => ({
  sendButton: {
    color: theme.palette.primary.dark,
    fontWeight: 'bold',
  },
});

const SendButton = ({ classes }) => (
  <Button className={classes.sendButton} type="submit">
    {I18n.t('SendMessageForm.Send')}
  </Button>
);
SendButton.propTypes = {
  classes: object.isRequired,
};

export default withStyles(styles)(SendButton);
