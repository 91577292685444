import Http from 'services/Http';

class Config {
  // async countryCodes(language) {
  //   const { data } = await Http.get(`/v1/config/countries/codes/${language}`)
  //   return { [language]: data }
  // }

  async countryCodes(language) {
    const { data } = await Http.get(`/v1/config/countries/codes/${language}`);
    return { [language]: data };
  }

  async getLanguages() {
    const { data } = await Http.get('/v1/config/languages');
    return data;
  }

  async getTimeZones() {
    const { data } = await Http.get('/v1/config/time/zones');
    return data;
  }

  async getScopes() {
    const { data } = await Http.get('/v1/config/scopes');
    return data;
  }
}

export default new Config();
