export default (ms, val = true) => {
  let timeoutId;
  const promise = new Promise(resolve => {
    timeoutId = setTimeout(resolve, ms, val);
  });

  promise.cancelPromise = () => {
    clearTimeout(timeoutId);
  };

  return promise;
};
