import { CLOSE_REMOTE_DRAWER, OPEN_REMOTE_DRAWER } from './action';

const initialState = {
  isOpen: false,
  url: null,
};

const remoteDrawer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_REMOTE_DRAWER:
      return {
        ...state,
        isOpen: true,
        url: action.payload || null,
      };

    case CLOSE_REMOTE_DRAWER:
      return { ...state, isOpen: false };

    default: {
      return {
        ...state,
      };
    }
  }
};

export default remoteDrawer;
