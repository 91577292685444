import ms from 'ms';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import Socket from 'services/Socket';
import auth from 'store/app/auth/action';
import bulkMessagings from 'store/app/entities/bulkMessaging/action';
import channels from 'store/app/entities/channels/action';
import companies from 'store/app/entities/companies/action';
import employeeImports from 'store/app/entities/employeeImports/action';
import employees from 'store/app/entities/employees/action';
import messages from 'store/app/entities/messages/action';
import users from 'store/app/entities/users/action';
import visibility from 'store/app/ui/focus/action';
import authCompany from 'store/selectors/authCompany';
import authEmployee from 'store/selectors/authEmployee';
import populateChannels from 'store/selectors/populateChannels';
import initializeSockets from 'utils/initializeSockets';

const useConnecting = () => {
  const dispatch = useDispatch();
  const props = useSelector(state => ({
    actions: {
      visibility: bindActionCreators(visibility, dispatch),
      auth: bindActionCreators(auth, dispatch),
      users: bindActionCreators(users, dispatch),
      employeeImports: bindActionCreators(employeeImports, dispatch),
      employees: bindActionCreators(employees, dispatch),
      companies: bindActionCreators(companies, dispatch),
      messages: bindActionCreators(messages, dispatch),
      channels: bindActionCreators(channels, dispatch),
      bulkMessagings: bindActionCreators(bulkMessagings, dispatch),
    },
    channels: populateChannels(state),
    authEmployee: authEmployee(state),
    users: state.users,
    authCompany: authCompany(state),
  }));
  const initialized = Socket.initialized();
  const [connecting, setConnecting] = useState(!Socket.isConnected());
  const waited = useRef(0);

  useEffect(() => {
    if (!initialized) {
      initializeSockets(props);
    }
    if (connecting) {
      const interval = setInterval(() => {
        waited.current += 500;
        const socketConnected = Socket.isConnected();
        if (socketConnected) {
          clearInterval(interval);
          setConnecting(false);
        } else if (waited.current > ms('4 seconds')) {
          waited.current = 0;
          Socket.connect();
        }
      }, 500);
    }
  }, [connecting]);

  return connecting;
};

export default useConnecting;
