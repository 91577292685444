import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookie from 'services/Cookie';
import authActions from 'store/app/auth/action';
import useActions from 'utils/useActions';

const LogoutScene = () => {
  const navigate = useNavigate();
  const logout = useActions(authActions.logout);

  useEffect(() => {
    setTimeout(() => {
      Cookie.set('token', '');
      logout();
      setTimeout(() => navigate('/auth/login', { replace: true }), 10);
    }, 10);
  }, [logout, navigate]);

  return null;
};

export default LogoutScene;
