/* eslint-disable react/jsx-props-no-spreading */
/**
 * @flow
 */

import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    border: 0,
    cursor: 'pointer',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.secondary.ultraUltraLight,
    },
  },
  lineClamp: ({ lines }) => ({
    whiteSpace: 'break-spaces',
    display: lines > 1 ? '-webkit-box' : 'inline',
    lineClamp: lines,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
}));

type Props = {
  children: Object;
  title?: string;
  lines?: number;
};

const LineClampTooltip = ({ children, lines, title, ...rest }: Props) => {
  const classes = useStyles({ lines });
  return (
    <Tooltip title={title || children} {...rest}>
      <span className={classes.lineClamp}>{children}</span>
    </Tooltip>
  );
};

LineClampTooltip.defaultProps = {
  lines: 2,
  title: null,
};

export default LineClampTooltip;
