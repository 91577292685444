import React from 'react';
import { bool, object } from 'prop-types';
import Language from 'components/controls/Language';

const FormikLanguage = ({ field, form, ...props }) => {
  const { name, onBlur } = field;
  const { errors, values, submitCount, setFieldValue } = form;
  return (
    <Language
      fullWidth
      {...props}
      onChange={e => {
        return setFieldValue(name, props.isMulti ? e?.map(v => v.value) : e.value);
      }}
      onBlur={onBlur}
      value={values[name]}
      name={name}
      error={!!(errors[name] && submitCount > 0)}
      helperText={submitCount > 0 && errors[name]}
    />
  );
};

FormikLanguage.propTypes = {
  field: object.isRequired,
  form: object.isRequired,
  isMulti: bool,
};

FormikLanguage.defaultProps = {
  isMulti: false,
};

export default FormikLanguage;
