import { Typography } from '@mui/material';
import { string } from 'prop-types';
import React, { memo } from 'react';

const MessageLabel = ({ className, children }) => {
  if (!children) return null;
  return (
    <Typography className={className} color="secondary" align="center" variant="caption">
      <span>
        <strong>{children}</strong>
      </span>
    </Typography>
  );
};

MessageLabel.propTypes = {
  children: string,
  className: string,
};

MessageLabel.defaultProps = {
  children: null,
  className: '',
};

export default memo(MessageLabel);
