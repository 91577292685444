const getFileUrl = value => {
  return new Promise(resolve => {
    if (!value) {
      resolve(value);
    } else if (typeof value === 'string') {
      resolve(value);
    } else if (value?.length === 1 && value[0] instanceof File) {
      const formData = new FormData();
      formData.append('image', value[0]);
      const fr = new FileReader();
      fr.addEventListener('load', e => {
        resolve(e.target.result);
      });
      fr.readAsDataURL(value[0]);
    } else {
      throw new Error('Unexpected type');
    }
  });
};

export default getFileUrl;
