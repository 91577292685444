export const OPEN_CHANNEL = 'OPEN_CHANNEL';
export const FILTER_CHANNELS = 'FILTER_CHANNELS';
export const SHOW_ARCHIVED = 'SHOW_ARCHIVED';

const filter = value => (dispatch, getState) =>
  dispatch({
    type: FILTER_CHANNELS,
    payload: value,
    meta: { channels: getState().channels.channels },
  });

const showArchived = value => ({
  type: SHOW_ARCHIVED,
  payload: value,
});

export default { filter, showArchived };
