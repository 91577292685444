import { createApi } from '@reduxjs/toolkit/dist/query/react';
import baseQuery from 'store/app/entities/baseQuery';

const analyticsEndpoints = build => ({
  getActivations: build.query({
    query: ({ team = '' } = {}) => `v1/analytics/activations${team ? `?team=${team}` : ''}`,
    transformResponse: response => response.data,
    providesTags: [{ type: 'AnalyticsChart', id: 'activations' }],
  }),
  getActiveUsers: build.query({
    query: () => 'v1/analytics/active-users',
    transformResponse: response => response.data,
    providesTags: [{ type: 'AnalyticsChart', id: 'activeUsers' }],
  }),
  getMessageCounts: build.query({
    query: () => 'v1/analytics/message-counts',
    transformResponse: response => response.data,
    providesTags: [{ type: 'AnalyticsChart', id: 'messageCounts' }],
  }),
  getSessionsDaily: build.query({
    query: () => 'v1/analytics/sessions-daily',
    transformResponse: response => response.data,
    providesTags: [{ type: 'AnalyticsChart', id: 'sessionsDaily' }],
  }),
  getUsersByPlatform: build.query({
    query: () => 'v1/analytics/users-by-platform',
    transformResponse: response => response.data,
    providesTags: [{ type: 'AnalyticsChart', id: 'usersByPlatform' }],
  }),
});

export const analyticsApi = createApi({
  reducerPath: 'analytics',
  baseQuery,
  tagTypes: ['AnalyticsChart'],
  endpoints: analyticsEndpoints,
});

export const {
  useGetActivationsQuery,
  useGetActiveUsersQuery,
  useGetMessageCountsQuery,
  useGetSessionsDailyQuery,
  useGetUsersByPlatformQuery,
} = analyticsApi;

export const { reducer: analyticsReducer, middleware: analyticsMiddleware } = analyticsApi;
