import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { object } from 'prop-types';
import React from 'react';
import isYoutube from 'utils/isYoutube';
import Image from './Image';
import YoutubeIframe from './YoutubeIFrame';

const styles = theme => ({
  root: {
    textDecoration: 'none !important',
    '&:hover, &:active, &:visited, &:focus': {
      textDecoration: 'none !important',
    },
  },
  text: {
    padding: 15,
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
  title: {
    paddingBottom: 5,
    fontWeight: 'bold',
  },
  description: {
    color: theme.palette.secondary.main,
  },
});

const LinkPreview = ({ classes, preview }) => (
  <a href={preview.link} rel="noopener noreferrer" target="_blank" className={classes.root}>
    {isYoutube(preview.link) && preview.link.split('v=')[1] ? (
      <YoutubeIframe url={preview.link} />
    ) : (
      <Image src={preview.image} />
    )}
    <div className={classes.text}>
      <Typography className={classes.title}>{preview.title}</Typography>
      <Typography variant="caption" className={classes.description}>
        {preview.description}
      </Typography>
    </div>
  </a>
);

LinkPreview.propTypes = {
  classes: object.isRequired,
  preview: object.isRequired,
};

export default withStyles(styles)(LinkPreview);
