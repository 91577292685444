/* eslint-disable class-methods-use-this */
import Http from 'services/Http';
import createForm from 'utils/createForm';

class Settings {
  async load(employeeId) {
    const { data } = await Http.get(`/v1/employee/${employeeId}/settings`);
    return data;
  }

  async update(employeeId, settings) {
    const { data } = await Http.put(
      `/v1/employee/${employeeId}/settings`,
      createForm(settings, {
        timeZone: 'string',
        language: 'string',
        dnd: 'bool',
        isOpenCommunication: 'bool',
        workingDays: 'object',
        isTrackingTime: 'bool',
        browser: browser => ({
          sound: !!browser.sound,
        }),
      }),
    );
    return data;
  }
}

export default new Settings();
