import { zoomIdentity } from 'd3-zoom';
import { autoPlay, Easing, Tween } from 'es6-tween';
import { useCallback, useMemo } from 'react';
import {
  getRectOfNodes,
  getTransformForBounds,
  useStore,
  useStoreState,
} from 'react-flow-renderer';

autoPlay(true);

const DEFAULT_PADDING = 0.1;
const TRANSITION_TIME = 700;
const EASING = Easing.Cubic.InOut;

const useZoomPanHelper = () => {
  const store = useStore();
  const d3Zoom = useStoreState(s => s.d3Zoom);
  const d3Selection = useStoreState(s => s.d3Selection);

  const handleTransform = useCallback(
    transform => {
      const {
        transform: [currX, currY, currZoom],
      } = store.getState() || { transform: [] };
      new Tween({ x: currX, y: currY, zoom: currZoom })
        .to(transform, TRANSITION_TIME)
        .easing(EASING)
        .on('update', ({ x: nx, y: ny, zoom: nz }) => {
          const zoomTransform = zoomIdentity.translate(nx, ny).scale(nz);
          d3Zoom.transform(d3Selection, zoomTransform);
        })
        .start();
    },
    [store, d3Zoom, d3Selection],
  );

  const zoomPanHelperFunctions = useMemo(
    () => ({
      zoomIn: () => d3Zoom.scaleBy(d3Selection, 1.2),
      zoomOut: () => d3Zoom.scaleBy(d3Selection, 1 / 1.2),
      fitView: (options = { padding: DEFAULT_PADDING, includeHiddenNodes: false }) => {
        const { nodes, width, height, minZoom, maxZoom } = store.getState();

        if (!nodes.length) {
          return;
        }

        const bounds = getRectOfNodes(
          options.includeHiddenNodes ? nodes : nodes.filter(node => !node.isHidden),
        );
        const [x, y, zoom] = getTransformForBounds(
          bounds,
          width,
          height,
          options.minZoom ?? minZoom,
          options.maxZoom ?? maxZoom,
          options.padding ?? DEFAULT_PADDING,
        );

        handleTransform({ x, y, zoom });
      },
      setCenter: (x, y, zoom) => {
        const { width, height, maxZoom } = store.getState();

        const nextZoom = typeof zoom !== 'undefined' ? zoom : maxZoom;
        const centerX = width / 2 - x * nextZoom;
        const centerY = height / 2 - y * nextZoom;
        handleTransform({ x: centerX, y: centerY, zoom: nextZoom });
      },
      focusNode: node => {
        if (!node) {
          zoomPanHelperFunctions.fitView();
          return;
        }
        const { nodes } = store.getState();
        const selectedNode = nodes?.find(n => n.id === node.id);
        const x = selectedNode.__rf.position.x + selectedNode.__rf.width / 2;
        const y = selectedNode.__rf.position.y + selectedNode.__rf.height / 2;
        zoomPanHelperFunctions.setCenter(x, y, 1);
      },
    }),
    [d3Zoom, d3Selection],
  );

  return zoomPanHelperFunctions;
};

export default useZoomPanHelper;
