/**
 * @flow
 */
import QuestionFormChoice from 'components/@home/drawers/FillFormDrawer/QuestionFormChoice';
import QuestionFormText from 'components/@home/drawers/FillFormDrawer/QuestionFormText';
import QuestionFormUpload from 'components/@home/drawers/FillFormDrawer/QuestionFormUpload';
import {
  Confirm,
  confirmOptions,
  FileUpload,
  ImageUpload,
  MultipleChoice,
  SingleChoice,
  Text,
} from 'components/@home/drawers/FormDrawer/questionTypes';
import React from 'react';

const QuestionForm = ({ question }: { question: Object }) => {
  switch (question.type) {
    case SingleChoice:
      return <QuestionFormChoice question={question} />;
    case MultipleChoice:
      return <QuestionFormChoice question={question} multiple />;
    case ImageUpload:
      return <QuestionFormUpload question={question} accept="image/jpeg, image/png" />;
    case FileUpload:
      return <QuestionFormUpload question={question} />;
    case Confirm: {
      const items = confirmOptions.map(([key, f]) => [key, f()]);
      return <QuestionFormChoice question={question} items={items} horizontal />;
    }
    case Text: {
      return <QuestionFormText question={question} />;
    }
    default:
      return null;
  }
};

export default QuestionForm;
