/* eslint-disable no-console */
import { withFormik } from 'formik';
import { I18n } from 'utils/i18n';
import Cookie from 'services/Cookie';
import * as Yup from 'yup';

const formik = withFormik({
  validationSchema: Yup.object().shape({
    code: Yup.string()
      .min(6, 'Code is must be 6 characters long')
      .required('Please enter your code'),
    terms: Yup.array().required('You need to accept Terms and Conditions'),
  }),

  validate: values => {
    const errors = {};

    if (!values.terms) {
      errors.terms = 'You need to accept Terms and Conditions';
    }

    return errors;
  },

  mapPropsToValues: ({ keepSessionOpen, isAcceptTerms, form }) => ({
    code: '',
    terms: isAcceptTerms || form?.terms ? ['on'] : [],
    keepSessionOpen: keepSessionOpen ? ['on'] : [],
  }),

  handleSubmit: async (values, { props, setSubmitting, setErrors }) => {
    const credentials = {
      phone: props.login.phone,
      password: values.code,
      loginType: props.loginType,
      terms: values.terms[0] === 'on',
      keepSessionOpen: values.keepSessionOpen[0] === 'on',
    };
    setSubmitting(true);

    props.actions.authConfig.setKeepSessionOpen(values.keepSessionOpen[0] === 'on');

    if (!props.login.phone) {
      return props.navigate('/auth/login');
    }
    try {
      const { returnUrl } = props;
      if (returnUrl) {
        props.actions.authConfig.setReturnURL(null);
      }
      Cookie.set('impersonate', '');
      await props.actions.auth.login(credentials, returnUrl, values.keepSessionOpen[0] === 'on');
    } catch (err) {
      console.error(err);
      setSubmitting(false);
      const errCode = err.data?.key || 'Unauthorized';
      setErrors({ code: I18n.t(`AuthErrors.${errCode}`) });
      return null;
    }

    return null;
  },
  displayName: 'ConfirmationForm',
  enableReinitialize: true,
});

export default formik;
