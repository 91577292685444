import { OPEN_SETTINGS_DRAWER, CLOSE_SETTINGS_DRAWER } from './action';

const initialState = {
  isOpen: false,
};

const settingsDrawer = (state = initialState, { type }) => {
  switch (type) {
    case OPEN_SETTINGS_DRAWER:
      return {
        ...state,
        isOpen: true,
      };

    case CLOSE_SETTINGS_DRAWER:
      return {
        ...state,
        isOpen: false,
      };

    default:
      return state;
  }
};

export default settingsDrawer;
