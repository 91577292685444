import { createApi } from '@reduxjs/toolkit/dist/query/react';
import baseQuery from 'store/app/entities/baseQuery';

const initialState = {
  companies: {},
};
const endpoints = build => ({
  findById: build.query({
    query: id => `v2/company/${id}`,
    transformResponse: response => response.data,
    providesTags: (result, error, id) => [{ type: 'Companies', id }],
  }),
  createCompany: build.mutation({
    query: body => ({
      url: 'v1/company',
      method: 'POST',
      body,
    }),
    invalidatesTags: () => [{ type: 'Companies', id: 'LIST' }],
  }),
  deleteCompany: build.mutation({
    query: id => ({
      url: `v1/company/${id}`,
      method: 'DELETE',
    }),
    invalidatesTags: (result, error, id) => [
      { type: 'Companies', id: 'LIST' },
      { type: 'Companies', id },
    ],
  }),
  editCompany: build.mutation({
    query: ({ _id, body }) => ({
      url: `v1/company/${_id}`,
      method: 'PATCH',
      body,
    }),
    invalidatesTags: (result, error, { id }) => [{ type: 'Companies', id }],
  }),
  listCompanies: build.query({
    query: ({
      page = '',
      perPage = '',
      sortBy = '',
      sort = '',
      search = '',
      template = false,
    }) => ({
      url: `v1/company?${search ? `search=${search}&searchBy=name` : ''}${
        template ? `template=1` : ''
      }&${page ? `page=${page}` : ''}&perPage=${perPage}&${
        sort ? `sortBy=${sortBy}&sort=${sort}` : ''
      }`,
    }),
    transformResponse: response => response.data,
    providesTags: ({ companies = [] }) => [
      ...(companies || []).map(({ _id: id }) => ({
        type: 'Companies',
        id,
      })),
      {
        type: 'Companies',
        id: 'LIST',
      },
    ],
  }),
});

const CompaniesSlice = createApi({
  reducerPath: 'companiesReducer',
  baseQuery,
  tagTypes: ['Companies'],
  initialState,
  keepUnusedDataFor: 30,
  endpoints,
});

export const {
  useFindByIdQuery,
  useCreateCompanyMutation,
  useDeleteCompanyMutation,
  useEditCompanyMutation,
  useListCompaniesQuery,
  util: { invalidateTags: invalidateCompaniesTags },
} = CompaniesSlice;

export const { reducer: companiesReducer, middleware: companiesMiddleware } = CompaniesSlice;
