import { Box, SvgIcon } from '@mui/material';
import BoltIcon from 'assets/icons/bolt.svg';
import StarSignIcon from 'assets/icons/star-sign.svg';
import StarIcon from 'assets/icons/star.svg';
import FileExcelBoxIcon from 'mdi-react/FileExcelBoxIcon';
import FileIcon from 'mdi-react/FileIcon';
import FilePdfBoxIcon from 'mdi-react/FilePdfBoxIcon';
import FilePowerpointBoxIcon from 'mdi-react/FilePowerpointBoxIcon';
import FileWordBoxIcon from 'mdi-react/FileWordBoxIcon';
import ForbidIcon from 'mdi-react/ForbidIcon';
import ImageIcon from 'mdi-react/ImageIcon';
import MicrophoneIcon from 'mdi-react/MicrophoneIcon';
import VideocamIcon from 'mdi-react/VideocamIcon';
import PropTypes from 'prop-types';
import React from 'react';
import messageShape from 'shapes/message';
import parseFileName from 'utils/getFileExtension';
import { getMessageType, MESSAGE_TYPES } from 'utils/getMessageType';

const styles = {
  img: { width: '1.3em', height: '1.3em', objectFit: 'cover', borderRadius: 3 },
  icon: { fontSize: '1.1em', verticalAlign: 'text-bottom' },
  iconDeleted: { color: 'secondary.main', fontSize: 18 },
};

const MessageIcon = ({ message, showImages }) => {
  if (!message) {
    return null;
  }
  const extIcons = {
    pdf: FilePdfBoxIcon,
    doc: FileWordBoxIcon,
    docx: FileWordBoxIcon,
    xls: FileExcelBoxIcon,
    xlsx: FileExcelBoxIcon,
    ppt: FilePowerpointBoxIcon,
    pptx: FilePowerpointBoxIcon,
  };
  const { ext } = parseFileName(message.attachments?.[0]?.name || '');
  const icons = {
    [MESSAGE_TYPES.ACKNOWLEDGE]: message.digitalSignature ? StarSignIcon : StarIcon,
    [MESSAGE_TYPES.FORM]: BoltIcon,
    [MESSAGE_TYPES.AUDIO]: MicrophoneIcon,
    [MESSAGE_TYPES.IMAGE]: ImageIcon,
    [MESSAGE_TYPES.VIDEO]: VideocamIcon,
    [MESSAGE_TYPES.FILE]: extIcons[ext] || FileIcon,
  };
  const image = message.attachments?.[0]?.meta?.thumb;
  const type = getMessageType(message);
  const Icon = message.softDeletedAt ? ForbidIcon : icons[type];
  if (!image && Icon) {
    return (
      <SvgIcon sx={message.softDeletedAt ? styles.iconDeleted : styles.icon}>
        <Icon />
      </SvgIcon>
    );
  }
  if (showImages && image) {
    return <Box component="img" alt="" src={image} sx={styles.img} />;
  }
  return null;
};

MessageIcon.propTypes = {
  message: messageShape,
  showImages: PropTypes.bool,
};
MessageIcon.defaultProps = {
  message: {},
  showImages: true,
};
export default MessageIcon;
