import AdminCompaniesScene from 'components/@admin/@companies/AdminCompaniesScene';
import AdminContentScene from 'components/@admin/@content/AdminContentScene';
import AdminUsersScene from 'components/@admin/@users/AdminUsersScene';
import AdminLayout from 'components/@admin/AdminLayout';
import FormScene from 'components/@auth/@login-no-invitation/@form/FormScene';
import LoginNoInvitationLayout from 'components/@auth/@login-no-invitation/LoginNoInvitationLayout';
import ConfirmationScene from 'components/@auth/@login/@confirmation/ConfirmationScene';
import PrivacyScene from 'components/@auth/@login/@terms/PrivacyScene';
import TermsScene from 'components/@auth/@login/@terms/TermsScene';
import LoginLayout from 'components/@auth/@login/LoginLayout';
import LoginRejectedScene from 'components/@auth/@login/LoginRejectedScene';
import LoginScene from 'components/@auth/@login/LoginScene';
import AuthLayout from 'components/@auth/AuthLayout';
import AnalyticsScene from 'components/@home/@analytics/AnalyticsScene';
import BulkMessaging from 'components/@home/@documents/BulkMessaging';
import ChatDocuments from 'components/@home/@documents/ChatDocuments';
import ChatsDocuments from 'components/@home/@documents/ChatsDocuments';
import DocumentsScene from 'components/@home/@documents/DocumentsScene';
import MyDocumentsFiles from 'components/@home/@documents/MyDocumentsFiles';
import MyDocumentsTemplates from 'components/@home/@documents/MyDocumentsTemplates';
import SentDocuments from 'components/@home/@documents/SentDocuments';
import SharedDocuments from 'components/@home/@documents/SharedDocuments';
import LauncherEmbedded from 'components/@home/@launcher/LauncherEmbedded';
import LauncherIndex from 'components/@home/@launcher/LauncherIndex';
import LauncherScene from 'components/@home/@launcher/LauncherScene';
import ArticlePanel from 'components/@home/@messages/ArticlePanel';
import ChatPanel from 'components/@home/@messages/ChatPanel';
import MessagesScene from 'components/@home/@messages/MessagesScene';
import PeopleScene from 'components/@home/@people/PeopleScene';
import SettingsScene from 'components/@home/@settings/SettingsScene';
import AppsSettings from 'components/@home/@settings/Tabs/AppsSettings';
import ChatFlowSettings from 'components/@home/@settings/Tabs/ChatFlowSettings';
import ChatsSettings from 'components/@home/@settings/Tabs/ChatsSettings';
import GeneralSettings from 'components/@home/@settings/Tabs/GeneralSettings';
import TeamsSettings from 'components/@home/@settings/Tabs/TeamsSettings';
import TimeTrackingScene from 'components/@home/@time-tracking/TimeTrackingScene';
import HomeLayout from 'components/@home/HomeLayout';
import AcceptInviteScene from 'components/@invite/AcceptInviteScene';
import InviteScene from 'components/@invite/InviteScene';
import LogoutScene from 'components/@logout/LogoutScene';
import OAuthScene from 'components/@oauth/OAuthScene';
import PostSignatureRedirectionScene from 'components/@signature/PostSignatureRedirectionScene';
import LegalDocScene from 'components/@welcome/@terms/LegalDocScene';
import LegalScene from 'components/@welcome/@terms/LegalScene';
import OnboardingScene from 'components/@welcome/OnboardingScene';
import WelcomeLayout from 'components/@welcome/WelcomeLayout';
import OccurrenceList, {
  OpenOccurrence,
} from 'components/@whistleblower-admin/pages/OccurrenceList/OccurrenceList';
import Portals from 'components/@whistleblower-admin/pages/Portals/Portals';
import Users from 'components/@whistleblower-admin/pages/Users/Users';
import WhistleblowerAdminLayout from 'components/@whistleblower-admin/WhistleblowerAdminLayout';
import Loading from 'components/common/Loading';
import AuthGate from 'components/gates/AuthGate';
import GuestGate from 'components/gates/GuestGate';
import HomeGate from 'components/gates/HomeGate';
import LoggedOutGate from 'components/gates/LoggedOutGate';
import SuperAdminGate from 'components/gates/SuperAdminGate';
import TeamAdminGate from 'components/gates/TeamAdminGate';
import IndexScene from 'components/IndexScene';
import LayoutScene from 'components/LayoutScene';
import NotFound from 'components/NotFoundScene';
import TimeAgo from 'javascript-time-ago';
import ca from 'javascript-time-ago/locale/ca.json';
import en from 'javascript-time-ago/locale/en.json';
import es from 'javascript-time-ago/locale/es.json';
import eu from 'javascript-time-ago/locale/eu.json';
import pt from 'javascript-time-ago/locale/pt.json';
import 'moment/locale/ca.js';
import 'moment/locale/en-gb.js';
import 'moment/locale/es.js';
import 'moment/locale/eu.js';
import 'moment/locale/pt.js';
import moment from 'moment';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createRoutesFromElements, RouterProvider } from 'react-router';
import { createBrowserRouter, Navigate, Route } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import ConfigureFeatureToggles from 'src/ConfigureFeatureToggles';
import store, { persistor } from 'store';
import './index.css';

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(es);
TimeAgo.addLocale(ca);
TimeAgo.addLocale(eu);
TimeAgo.addLocale(pt);

moment().locale('en');
moment.locale('en');

const errorsToWarn = ['Warning:'];
const oldConsError = console.error;
global.console.error = (...args) => {
  let toWarn = false;

  if (typeof args[0] === 'string') {
    errorsToWarn.forEach(_s => {
      if (args[0].startsWith(_s)) {
        toWarn = true;
      }
    });
  }

  if (toWarn) {
    console.warn(...args);
  } else {
    oldConsError(...args);
  }
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<LayoutScene />}>
      <Route path="" element={<LoggedOutGate component={IndexScene} />} />
      <Route path="auth/*" element={<GuestGate component={AuthLayout} />}>
        <Route path="login/*" element={<LoginLayout />}>
          <Route path="" element={<LoginScene />} />
          <Route path="rejected" element={<LoginRejectedScene />} />
          <Route path="terms" element={<TermsScene />} />
          <Route path="privacy" element={<PrivacyScene />} />
          <Route path="confirmation" element={<ConfirmationScene />} />
        </Route>
        <Route path="login-no-invitation" element={<LoginNoInvitationLayout />}>
          <Route path="form" component={FormScene} />
        </Route>
        <Route path="*" element={<Navigate replace to="/auth/login" />} />
      </Route>
      <Route path="welcome/*" element={<AuthGate component={WelcomeLayout} />}>
        <Route path="" element={<OnboardingScene />} />
        <Route path="legal" element={<LegalScene />} />
        <Route path="legal/terms" element={<LegalDocScene doc="terms" />} />
        <Route path="legal/privacy" element={<LegalDocScene doc="privacy" />} />
      </Route>
      <Route path="home" element={<HomeGate component={HomeLayout} />}>
        <Route path="messages/*" element={<MessagesScene />}>
          <Route path=":channelId/*" element={<ChatPanel />}>
            <Route path="article/:articleId" element={<ArticlePanel />} />
          </Route>
        </Route>
        <Route path="documents/*" element={<DocumentsScene />}>
          <Route path="" element={() => <Navigate replace to="home" />} />
          <Route path="home" element={<MyDocumentsTemplates folder="home" />} />
          <Route path="home/:id" element={<MyDocumentsFiles folder="home" />} />
          <Route path="chats" element={<ChatsDocuments folder="chats" />} />
          <Route path="chats/:id" element={<ChatDocuments folder="chats" />} />
          <Route path="shared" element={<SharedDocuments folder="shared" />} />
          <Route path="shared/:id" element={<SharedDocuments folder="shared" />} />
          <Route path="deleted" element={<SharedDocuments folder="deleted" />} />
          <Route path="sent" element={<SentDocuments folder="sent" />} />
          <Route path="sent/:id" element={<BulkMessaging folder="sent" />} />
        </Route>
        <Route path="analytics" element={<TeamAdminGate component={AnalyticsScene} />} />
        <Route path="people" element={<TeamAdminGate component={PeopleScene} />} />
        <Route path="launcher/*" element={<LauncherScene />}>
          <Route path="" element={<LauncherIndex />} />
          <Route path=":id" element={<LauncherEmbedded />} />
        </Route>
        <Route path="settings/*" element={<TeamAdminGate component={SettingsScene} />}>
          <Route path="general" element={<GeneralSettings />} />
          <Route path="teams" element={<TeamsSettings />} />
          <Route path="chats" element={<ChatsSettings />} />
          <Route path="apps" element={<AppsSettings />} />
          <Route path="chatFlow" element={<ChatFlowSettings />} />
        </Route>
        <Route path="time-tracking" element={<TeamAdminGate component={TimeTrackingScene} />} />
        <Route path="admin/*" element={<SuperAdminGate component={AdminLayout} />}>
          <Route path="companies" element={<AdminCompaniesScene />} />
          <Route path="users" element={<AdminUsersScene />} />
          <Route path="content" element={<AdminContentScene />} />
          <Route path="*" element={<Navigate to="companies" />} />
        </Route>
      </Route>
      <Route path="wb-admin/*" element={<AuthGate component={WhistleblowerAdminLayout} />}>
        <Route path="users" element={<Users />} />
        <Route path="portals" element={<Portals />} />
        <Route path="portals/:slug" element={<Portals />} />
        <Route path="occurrences/*" element={<OccurrenceList />}>
          <Route path=":id" element={<OpenOccurrence />} />
        </Route>
        <Route path="*" element={<Navigate to="occurrences" replace />} />
      </Route>
      <Route path="oauth/authorize/*" element={<HomeGate component={OAuthScene} />} />
      <Route path="invite/*" element={<InviteScene />} />
      <Route path="accept-invite/:token" element={<AuthGate component={AcceptInviteScene} />} />
      <Route path="logout" element={<LogoutScene />} />
      <Route path="sr/:action" element={<PostSignatureRedirectionScene />} />
      <Route path="*" element={<NotFound />} />
    </Route>,
  ),
);

const Application = () => (
  <Provider store={store}>
    <PersistGate loading={<Loading />} persistor={persistor}>
      <ConfigureFeatureToggles>
        <RouterProvider router={router} />
      </ConfigureFeatureToggles>
    </PersistGate>
  </Provider>
);

createRoot(document.getElementById('root')).render(<Application />);

module.hot?.accept();
