import arrayToObject from 'utils/arrayToObject';
import {
  CREATE_FORMAT_FULFILLED,
  DESTROY_FORMAT_FULFILLED,
  LOAD_FORMATS_FULFILLED,
  LOAD_FORMATS_PENDING,
  SET_FORMATS,
  UPDATE_FORMAT_FULFILLED,
} from './action';

const appsReducer = (
  state = { formats: {}, loading: false, loaded: false },
  { type, payload, meta },
) => {
  switch (type) {
    case DESTROY_FORMAT_FULFILLED: {
      const newFormats = { ...state.formats };
      delete newFormats[meta.formatId];
      return {
        ...state,
        formats: newFormats,
      };
    }

    case LOAD_FORMATS_FULFILLED: {
      return {
        ...state,
        formats: arrayToObject(payload.documentFormats),
        loading: false,
        loaded: true,
      };
    }

    case LOAD_FORMATS_PENDING:
      return { ...state, loading: true, loaded: false };

    case CREATE_FORMAT_FULFILLED:
    case UPDATE_FORMAT_FULFILLED: {
      const formats = { ...state.formats, [payload.documentFormat._id]: payload.documentFormat };
      return { ...state, formats };
    }

    case SET_FORMATS: {
      return { ...state, formats: payload };
    }

    default:
      return state;
  }
};

export default appsReducer;
