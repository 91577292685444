import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { node } from 'prop-types';
import React, { memo } from 'react';

const useStyles = makeStyles({
  root: {
    marginTop: '1.333em',
    marginBottom: '0.467em',
    fontSize: 15,
    fontWeight: 600,
  },
});

const Label = ({ children }) => {
  const classes = useStyles();
  return (
    <Typography className={classes.root} variant="body1">
      {children}
    </Typography>
  );
};

Label.propTypes = {
  children: node.isRequired,
};

export default memo(Label);
