import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { func, object, oneOfType, string } from 'prop-types';
import React, { useCallback } from 'react';
import ChatInput from './ChatInput';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    maxHeight: 72,
    overflow: 'auto',
  },
}));

const ChatField = ({
  name,
  value,
  placeholder,
  onChange,
  onPaste,
  inputRef,
  suggestionsPortal,
  onSend,
}) => {
  const classes = useStyles();
  const listenForEnter = useCallback(
    async e => {
      const code = e.keyCode ? e.keyCode : e.which;

      if (code === 13 && !e.shiftKey) {
        onSend(e);
      }
    },
    [onSend],
  );
  return (
    <div className={classes.root}>
      <ChatInput
        name={name}
        value={value}
        placeholder={placeholder}
        className={classes.root}
        onKeyPress={listenForEnter}
        onChange={onChange}
        onPaste={onPaste}
        inputRef={inputRef}
        suggestionsPortal={suggestionsPortal}
      />
    </div>
  );
};

ChatField.defaultProps = {
  inputRef: () => {},
};

ChatField.propTypes = {
  placeholder: string.isRequired,
  name: string.isRequired,
  value: string.isRequired,
  onSend: func.isRequired,
  onChange: func.isRequired,
  onPaste: func.isRequired,
  inputRef: oneOfType([object, func]),
  suggestionsPortal: object.isRequired,
};

export default ChatField;
