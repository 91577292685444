import { Error } from '@mui/icons-material';
import { Button, Card, CardContent, Link, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import img from 'components/@auth/images/img.jpg';
import React from 'react';
import { I18n } from 'utils/i18n';
import { BACKEND_URL } from 'services/constants';
import getSubdomain from 'utils/getSubdomain';
import { Link as aLink } from 'react-router-dom';

const classes = {
  root: ({ onBoardingImage }) => ({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: `url(${onBoardingImage})`,
    backgroundSize: 'cover',
  }),
  card: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 2,
    flexGrow: 1,
    color: 'error.main',
  },
};

const ErrorFallback = () => {
  const subdomain = getSubdomain();

  const onBoardingImage = subdomain ? `${BACKEND_URL}/api/v1/company/${subdomain}/logo` : img;

  return (
    <Box sx={classes.root({ onBoardingImage })}>
      <Card>
        <CardContent sx={classes.card}>
          <Box sx={classes.title}>
            <Error fontSize="large" />
            <Typography variant="h5">{I18n.t('ErrorFallback.Title')}</Typography>
          </Box>
          <Typography>{I18n.t('ErrorFallback.Description')}</Typography>
          <Button variant="contained" component={Link} href="/">
            {I18n.t('ErrorFallback.Go to the home page')}
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ErrorFallback;
