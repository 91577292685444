/* eslint-disable jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */
import { Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import FormikAutosuggest from 'components/formik/Autosuggest';
import Language from 'components/formik/Language';
import TextField from 'components/formik/TextField';
import TimeZone from 'components/formik/TimeZone';
import { Field, useFormikContext } from 'formik';
import { arrayOf, object, string } from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import { I18n } from 'utils/i18n';

const styles = {
  root: {
    paddingTop: 20,
  },
  field: {
    padding: 5,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  workingDaysLabel: {
    display: 'flex',
    minHeight: 100,
    paddingTop: 23,
  },
  workingDaysWrapper: {
    display: 'flex',
    justifyContent: 'stretch',
    paddingTop: 10,
  },
};

const JobDetails = ({ classes, divisions }) => {
  const { values, errors, touched } = useFormikContext();
  const codeInputRef = useRef();

  const validateCode = useCallback(
    value => {
      if (!value) {
        return undefined;
      }

      const pattern = {
        number: /^[0-9]*$/,
        alphanumeric: /^[a-zA-Z0-9]*$/,
        spanishSSN: /^(\d{2})\/(\d{8})(-(\d{2}))?$/,
      }[values.codeType];

      if (pattern && !pattern.test(value)) {
        return I18n.t(`ValidationErrors.${values.codeType}`, {
          field: I18n.t('EmployeeDrawer.Code'),
          value,
        });
      }

      return undefined;
    },
    [values.codeType],
  );

  useEffect(() => {
    const { current } = codeInputRef;
    if (!current) return () => {};

    const handleInput = () => {
      const errorMessage = validateCode(current.value);
      current.setCustomValidity(errorMessage || '');
    };

    current.addEventListener('input', handleInput);
    return () => {
      current.removeEventListener('input', handleInput);
    };
  }, [validateCode]);

  return (
    <div className={classes.root}>
      <Typography variant="caption">{I18n.t('EmployeeDrawer.JOB DETAILS')}</Typography>
      <Grid container>
        {divisions.length > 0 && (
          <>
            <Grid item xs={2} className={classes.label}>
              <Typography>{`${I18n.t('EmployeeDrawer.Division')}*`}</Typography>
            </Grid>
            <Grid item xs={10}>
              <Field
                component={FormikAutosuggest}
                suggestions={divisions.map(s => ({ label: s, value: s }))}
                className={classes.field}
                placeholder={I18n.t('EmployeeDrawer.Start typing')}
                name="division"
              />
            </Grid>
          </>
        )}
        <Grid item xs={2} className={classes.label}>
          <Typography>{`${I18n.t('EmployeeDrawer.Code')}`}</Typography>
        </Grid>
        <Grid item xs={10}>
          <Field
            component={TextField}
            className={classes.field}
            placeholder={I18n.t('EmployeeDrawer.Start typing')}
            name="code"
            validate={validateCode}
            error={touched.code && errors.code}
            helperText={touched.code && errors.code}
            inputRef={codeInputRef}
            inputProps={{
              inputMode: values.codeType === 'number' ? 'numeric' : 'text',
              pattern: {
                number: '^[0-9]*$',
                alphanumeric: '^[a-zA-Z0-9]*$',
                spanishSSN: '^(\\d{2})\\/(\\d{8})(-(\\d{2}))?$',
              }[values.codeType],
            }}
          />
        </Grid>
        <Grid item xs={2} className={classes.label}>
          <Typography>{`${I18n.t('EmployeeDrawer.Id')}`}</Typography>
        </Grid>
        <Grid item xs={10}>
          <Field
            component={TextField}
            className={classes.field}
            placeholder={I18n.t('EmployeeDrawer.Start typing')}
            name="id"
          />
        </Grid>
        <Grid item xs={2} className={classes.label}>
          <Typography>{`${I18n.t('EmployeeDrawer.Job title')}*`}</Typography>
        </Grid>
        <Grid item xs={10}>
          <Field
            component={TextField}
            className={classes.field}
            placeholder={I18n.t('EmployeeDrawer.Start typing')}
            name="jobTitle"
          />
        </Grid>
        <Grid item xs={2} className={classes.label}>
          <Typography>{`${I18n.t('EmployeeDrawer.Department')}*`}</Typography>
        </Grid>
        <Grid item xs={10}>
          <Field
            component={TextField}
            className={classes.field}
            placeholder={I18n.t('EmployeeDrawer.Start typing')}
            name="department"
          />
        </Grid>
        <Grid item xs={2} className={classes.label}>
          <Typography>{I18n.t('EmployeeDrawer.Location')}</Typography>
        </Grid>
        <Grid item xs={10}>
          <Field
            component={TextField}
            className={classes.field}
            placeholder={I18n.t('EmployeeDrawer.Start typing')}
            name="location"
          />
        </Grid>
        <Grid item xs={2} className={classes.label}>
          <Typography>{I18n.t('EmployeeDrawer.Report to')}</Typography>
        </Grid>
        <Grid item xs={10}>
          <Field
            component={TextField}
            className={classes.field}
            placeholder={I18n.t('EmployeeDrawer.Start typing')}
            name="reportsTo"
          />
        </Grid>
        <Grid item xs={2} className={classes.label}>
          <Typography>{I18n.t('EmployeeDrawer.Language')}</Typography>
        </Grid>
        <Grid item xs={10}>
          <Field component={Language} className={classes.field} name="language" />
        </Grid>
        <Grid item xs={2} className={classes.label}>
          <Typography>{I18n.t('EmployeeDrawer.Time zone')}</Typography>
        </Grid>
        <Grid item xs={10}>
          <Field component={TimeZone} className={classes.field} name="timeZone" />
        </Grid>
      </Grid>
    </div>
  );
};

JobDetails.propTypes = {
  classes: object.isRequired,
  divisions: arrayOf(string),
};

JobDetails.defaultProps = {
  divisions: [],
};

export default withStyles(styles)(JobDetails);
