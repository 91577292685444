/* eslint-disable function-paren-newline,react/destructuring-assignment */
import { useFeatureToggle } from '@flopflip/react-redux';
import { Alert, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { GridActionsCellItem } from '@mui/x-data-grid';
import Toolbar from 'components/@home/@people/PeoplesTable/Toolbar';
import EmployeeAvatar from 'components/@home/EmployeeAvatar';
import { useConfirm } from 'components/common/GlobalConfirmDialog';
import OmmnioDataGrid from 'components/common/OmmnioDataGrid';
import DeleteIcon from 'mdi-react/DeleteIcon';
import EditIcon from 'mdi-react/EditIcon';
import IncognitoIcon from 'mdi-react/IncognitoIcon';
import LockIcon from 'mdi-react/LockIcon';
import LockOpenIcon from 'mdi-react/LockOpenIcon';
import ShieldAccountIcon from 'mdi-react/ShieldAccountIcon';
import { bool, func, object } from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Cookie from 'services/Cookie';
import { STATUS_ACTIVE } from 'store/app/entities/employees/reducer';
import {
  useBlockMutation,
  useDeleteMutation,
  useListQuery,
} from 'store/app/entities/EmployeesApiSlice';
import authCompany from 'store/selectors/authCompany';
import isCompanyAdminSelector from 'store/selectors/isCompanyAdmin';
import { I18n } from 'utils/i18n';
import { useDebounceValue } from 'utils/useDebounceValue';
import useQuery from 'utils/useQuery';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    paddingTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  tableWrapper: {
    overflowX: 'auto',
    flex: 1,
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  table: {
    borderTop: `1px solid ${theme.palette.divider}`,
    flexGrow: 1,
  },
  row: {
    cursor: 'pointer',
    '& .MuiDataGrid-cell:nth-of-type(1)': {
      paddingLeft: theme.spacing(3),
    },
  },
  firstCell: {},
  user: {
    display: 'flex',
    alignItems: 'center',
  },
  userName: {
    marginLeft: 10,
  },
  xsHidden: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  edit: {
    marginRight: 5,
    color: '#8b98ab',
    fontSize: 11,
  },
  delete: {
    color: '#8b98ab',
    fontSize: 11,
  },
  pending: {
    '& .MuiDataGrid-cell': {
      color: theme.palette.secondary.main,
    },
  },
  tag: {
    margin: theme.spacing(1),
  },
  columnHeaders: {
    borderTop: '1px solid',
    borderColor: theme.palette.divider,
    '& .MuiDataGrid-columnHeader:nth-of-type(1)': {
      paddingLeft: theme.spacing(3),
    },
  },
  danger: {
    color: theme.palette.danger,
  },
  warningIcon: {
    position: 'absolute',
  },
}));

const defaultSortModel = [{ field: 'name', sort: 'asc' }];

const PeoplesTable = ({ onOpen, onEdit, additionalControls, isSuperAdmin, showSessions }) => {
  const integratedUsers = useFeatureToggle('integrated-users');
  const isCompanyAdmin = useSelector(isCompanyAdminSelector);
  const classes = useStyles();
  const [sortModel, setSortModel] = useState(defaultSortModel);
  const [filterModel, setFilterModel] = useState(undefined);
  const { p = 0 } = useQuery();
  const [page, setPage] = useState(p);
  const [rowsPerPage, setRowsPerPage] = useState(-1);
  const [debouncedFilterModel] = useDebounceValue(filterModel, 500);
  const [debouncedRowsPerPage] = useDebounceValue(rowsPerPage, 500);
  const [block] = useBlockMutation();
  const [remove] = useDeleteMutation();
  const { open: openConfirm, close: closeConfirm } = useConfirm();
  const confirmDelete = useCallback(
    employeeToDelete =>
      openConfirm({
        message: I18n.t('PeoplesScene.ConfirmDelete', {
          name: `${employeeToDelete?.firstName} ${employeeToDelete?.surName}`.trim(),
        }),
        isDelete: true,
        typeBack: employeeToDelete?.surName?.trim(),
        children: (
          <Alert severity="warning">
            <Typography display="inline">{I18n.t('PeoplesScene.DeleteWarning')}</Typography>
            <span className={classes.warning}>
              <LockOpenIcon className={classes.warningIcon} size={18} />
            </span>
          </Alert>
        ),
        onConfirm: () => {
          closeConfirm();
          remove({ _id: employeeToDelete._id });
        },
      }),
    [classes.warning, classes.warningIcon, closeConfirm, openConfirm, remove],
  );
  const company = useSelector(authCompany);
  const { data, isFetching, isLoading, isUninitialized } = useListQuery(
    {
      companyId: company._id,
      search: debouncedFilterModel?.quickFilterValues.join(' ') || '',
      skip: page * debouncedRowsPerPage,
      limit: debouncedRowsPerPage,
      filters: (filterModel?.items || []).reduce(
        (acc, { field, value }) => ({
          ...acc,
          [field]: value,
        }),
        {},
      ),
      order: sortModel[0]
        ? `${sortModel[0].sort === 'desc' ? '-' : ''}${sortModel[0].field}`
        : 'name',
    },
    { skip: debouncedRowsPerPage < 2 },
  );
  const employees = useMemo(() => data?.data || [], [data?.data]);
  const total = useMemo(() => data?.metadata?.total || 0, [data?.metadata?.total]);

  const columns = useMemo(() => {
    return [
      {
        field: 'status',
        width: 0,
      },
      {
        field: 'name',
        flex: 2,
        headerName: I18n.t('ChannelDrawer.EmployeesTable.Name'),
        valueGetter: (v, { firstName, surName }) => `${firstName} ${surName}`,
        cellClassName: classes.firstCell,
        renderCell: ({ row: employee }) => (
          <div className={classes.user}>
            <EmployeeAvatar
              status={employee.isAcceptTerms ? 'active' : 'pending'}
              employee={employee}
            />
            <div className={classes.userName}>
              {`${employee.firstName?.trim()} ${employee.surName?.trim()}`.trim()}
              {employee.user?.isApp ? <span className={classes.appTag}>App</span> : null}
            </div>
          </div>
        ),
      },
      ...(isSuperAdmin
        ? [
            {
              field: 'phone',
              flex: 1,
              headerName: I18n.t('ChannelDrawer.EmployeesTable.Phone'),
              valueGetter: (v, { user, phone }) => user?.phone || phone,
            },
          ]
        : []),
      {
        field: 'code',
        flex: 1,
        headerName: I18n.t('ChannelDrawer.EmployeesTable.Code'),
        valueGetter: (v, { code, division }) => (division ? `${code} - ${division}` : code),
        renderCell: ({ row: { code, division } }) => (
          <Box
            sx={{
              lineHeight: 1.5,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            {!code || <Box>{code}</Box>}
            {!division || <Box sx={{ color: 'text.secondary' }}>({division})</Box>}
          </Box>
        ),
      },
      {
        field: 'department',
        flex: 1,
        headerName: I18n.t('ChannelDrawer.EmployeesTable.Department'),
      },
      {
        field: 'jobTitle',
        flex: 1,
        headerName: I18n.t('ChannelDrawer.EmployeesTable.Job Position'),
      },
      {
        field: 'location',
        flex: 1,
        headerName: I18n.t('ChannelDrawer.EmployeesTable.Location'),
      },
      {
        field: 'teams',
        flex: 2,
        headerName: I18n.t('ChannelDrawer.EmployeesTable.Teams'),
        valueGetter: (v, { teams }) =>
          teams
            ?.map(({ name }) => name)
            .sort((a, b) => a.localeCompare(b))
            .join(', '),
      },
      {
        field: 'tags',
        flex: 1,
        headerName: I18n.t('ChannelDrawer.EmployeesTable.Tags'),
      },
      {
        field: 'actions',
        type: 'actions',
        flex: 1,
        minWidth: isSuperAdmin ? 228 : 148,
        headerName: '',
        getActions: ({ row: employee }) => [
          ...(isSuperAdmin
            ? [
                <GridActionsCellItem
                  key="impersonate"
                  disabled={!employee.user || employee.user.isApp}
                  icon={<IncognitoIcon />}
                  onClick={() => {
                    Cookie.set('impersonate', employee.user._id);
                    window.location = '/home/messages';
                  }}
                  label=""
                />,
                <GridActionsCellItem
                  key="showSessions"
                  icon={<ShieldAccountIcon />}
                  onClick={() => {
                    showSessions(employee);
                  }}
                  label=""
                />,
              ]
            : []),
          <GridActionsCellItem
            key="block"
            disabled={employee.user?.isApp}
            icon={employee.isActive ? <LockOpenIcon /> : <LockIcon className={classes.danger} />}
            onClick={() => {
              block({
                _id: employee._id,
                isActive: !employee.isActive,
              });
            }}
            label={I18n.t('ChannelDrawer.EmployeesTable.Buttons.Block')}
          />,
          ...(!integratedUsers || isCompanyAdmin
            ? [
                <GridActionsCellItem
                  key="edit"
                  icon={<EditIcon />}
                  onClick={() => {
                    onEdit(employee);
                  }}
                  label={I18n.t('ChannelDrawer.EmployeesTable.Buttons.Edit')}
                />,
                <GridActionsCellItem
                  key="delete"
                  icon={<DeleteIcon />}
                  onClick={() => confirmDelete(employee)}
                  label={I18n.t('ChannelDrawer.EmployeesTable.Buttons.Delete')}
                />,
              ]
            : []),
        ],
      },
    ];
  }, [
    block,
    classes.appTag,
    classes.danger,
    classes.firstCell,
    classes.user,
    classes.userName,
    confirmDelete,
    integratedUsers,
    isCompanyAdmin,
    isSuperAdmin,
    onEdit,
    showSessions,
  ]);

  const onFilterModelChange = useCallback(
    fm => {
      setFilterModel(fm);
      setPage(p);
    },
    [p],
  );

  return (
    <OmmnioDataGrid
      loading={isFetching || isLoading || isUninitialized}
      sx={{ marginTop: 3, borderWidth: 0 }}
      columnHeaderHeight={48}
      columns={columns}
      columnVisibilityModel={{ status: false }}
      rows={employees}
      rowCount={total || 0}
      paginationMode="server"
      getRowId={r => r._id}
      sortingMode="server"
      filterMode="server"
      filterModel={filterModel}
      onFilterModelChange={onFilterModelChange}
      onSortModelChange={setSortModel}
      onPageChange={setPage}
      rowHeight={52}
      getRowHeight={() => 52}
      pageSize={rowsPerPage}
      onPageSizeChange={setRowsPerPage}
      updateHistory
      defaultSortModel={defaultSortModel}
      onCellClick={cell => {
        if (cell.field !== 'actions') {
          onOpen(cell.row);
        }
      }}
      slots={{
        toolbar: Toolbar,
      }}
      slotProps={{
        toolbar: {
          total,
          children: additionalControls,
          search: debouncedFilterModel?.quickFilterValues.join(' ') || '',
          filters: (filterModel?.items || []).reduce(
            (acc, { field, value }) => ({
              ...acc,
              [field]: value,
            }),
            {},
          ),
        },
      }}
      classes={{
        root: classes.root,
        row: classes.row,
        columnHeaders: classes.columnHeaders,
      }}
      getRowClassName={params =>
        classes[params.row.status !== STATUS_ACTIVE ? 'pending' : 'active']
      }
    />
  );
};

PeoplesTable.propTypes = {
  onOpen: func.isRequired,
  onEdit: func.isRequired,
  additionalControls: object,
  isSuperAdmin: bool.isRequired,
  showSessions: func.isRequired,
};

PeoplesTable.defaultProps = {
  additionalControls: null,
};

export default PeoplesTable;
