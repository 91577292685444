import { ListItem, SvgIcon, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ChevronRightIcon from 'assets/icons/chevron-right.svg';
import FileIcon from 'assets/icons/file-empty.svg';
import FileSignIcon from 'assets/icons/file-sign.svg';
import FileAckIcon from 'assets/icons/file.svg';
import moment from 'moment';
import { node, object, string } from 'prop-types';
import React, { Component } from 'react';
import { I18n } from 'utils/i18n';
import channelShape from 'shapes/channel';
import employeeShape from 'shapes/employee';
import connector from './connector';

const styles = theme => ({
  root: {
    height: '70px',
    alignItems: 'center',
    borderBottom: 'solid 1px #dde2eb',
    cursor: 'pointer',
  },
  fileIcon: {
    fontSize: 24,
    marginRight: 10,
  },
  chevronIcon: {
    fontSize: 14,
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  nameText: {
    fontSize: '15px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.47',
    letterSpacing: '-0.5px',
    color: theme.palette.black,
  },
  ack: {
    fontSize: '14px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.47',
    letterSpacing: '-0.5px',
    color: theme.palette.primary.main,
  },
});

class DocumentItem extends Component {
  preview = () => {
    const { actions, url, doc, channel, employee } = this.props;
    const { messageId, name, isMandatory } = doc;
    const { message } = doc;
    if (isMandatory) {
      actions.acknowledgmentReadModal.open({ ...message, attachments: [doc] }, channel, employee);
    } else {
      actions.documentModal.open(url, messageId, name, false, doc.mimeType);
    }
  };

  render() {
    const {
      classes,
      children,
      doc: {
        isMandatory,
        confirmedAt,
        message: { digitalSignature },
      },
    } = this.props;
    let Icon = FileIcon;
    if (isMandatory && digitalSignature) {
      Icon = FileSignIcon;
    } else if (isMandatory) {
      Icon = FileAckIcon;
    }
    return (
      <ListItem className={classes.root} onClick={this.preview}>
        <SvgIcon className={classes.fileIcon}>
          <Icon />
        </SvgIcon>
        <div className={classes.textWrapper}>
          <Typography className={classes.nameText} component="div">
            {children}
          </Typography>
          {confirmedAt && (
            <Typography className={classes.ack} component="div">
              {I18n.t(
                digitalSignature
                  ? 'Documents.Digital signature made on'
                  : 'Documents.Acknowledge made on',
                {
                  date: moment.unix(confirmedAt).format('LL'),
                },
              )}
            </Typography>
          )}
        </div>
        <SvgIcon className={classes.chevronIcon}>
          <ChevronRightIcon />
        </SvgIcon>
      </ListItem>
    );
  }
}

DocumentItem.propTypes = {
  employee: employeeShape.isRequired,
  classes: object.isRequired,
  children: node.isRequired,
  actions: object.isRequired,
  url: string.isRequired,
  doc: object.isRequired,
  channel: channelShape.isRequired,
};

export default withStyles(styles)(connector(DocumentItem));
