import { Box, ButtonBase } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import ReactionIcon from 'components/common/ReactionIcon';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import useOpenClose from 'utils/useOpenClose';
import ReactionDetails from './ReactionDetails';

const useStyles = makeStyles(theme => ({
  reactionBox: {
    minWidth: 'unset',
    padding: '5px 7px 5px 6px',
    borderRadius: '999px',
    display: 'inline-flex',
    alignItems: 'center',
    gap: 4,
    fontSize: '14px',
    lineHeight: 1,
    backgroundColor:
      theme.palette.mode === 'light'
        ? alpha(theme.palette.action.hover, 0.05)
        : alpha(theme.palette.action.hover, 0.08),
    color: theme.palette.text.primary,
    margin: '2px 4px 2px 0',
    transition: 'all 100ms ease-in-out',
    'button&:hover': {
      backgroundColor:
        theme.palette.mode === 'light'
          ? alpha(theme.palette.action.hover, 0.08)
          : alpha(theme.palette.action.hover, 0.13),
    },
    'button&.active': {
      backgroundColor:
        theme.palette.mode === 'light'
          ? alpha(theme.palette.primary.main, 0.12)
          : alpha(theme.palette.primary.main, 0.2),
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor:
          theme.palette.mode === 'light'
            ? alpha(theme.palette.primary.main, 0.15)
            : alpha(theme.palette.primary.main, 0.25),
      },
    },
  },
  reactionIcon: {
    display: 'flex',
    alignItems: 'center',
    height: 14,
  },
  reactionCount: {
    fontSize: 'inherit',
    color: theme.palette.text.primary,
    display: 'flex',
    alignItems: 'center',
    height: 14,
  },
}));

const ReactionCounter = ({ messageId, reaction, count, active, canSeeDetails }) => {
  const classes = useStyles();
  const anchorRef = useRef(null);
  const [isOpen, open, close] = useOpenClose(false);

  const handleClick = event => {
    event.stopPropagation();
    open();
  };

  const Component = canSeeDetails ? ButtonBase : Box;
  return (
    <>
      <Component
        ref={anchorRef}
        onClick={handleClick}
        className={classNames(classes.reactionBox, { active })}
      >
        <span className={classes.reactionIcon}>
          <ReactionIcon name={reaction} size={13} />
        </span>
        <span className={classes.reactionCount}>{count}</span>
      </Component>
      {canSeeDetails && (
        <ReactionDetails
          messageId={messageId}
          reaction={reaction}
          count={count}
          anchorRef={anchorRef}
          isOpen={isOpen}
          close={close}
        />
      )}
    </>
  );
};

ReactionCounter.propTypes = {
  messageId: PropTypes.string.isRequired,
  reaction: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  canSeeDetails: PropTypes.bool.isRequired,
};

export default ReactionCounter;
