/**
 * @flow
 */
import makeStyles from '@mui/styles/makeStyles';
import Attachments from 'components/@home/@messages/ChatPanel/MessagesList/MessageGroup/Attachments';
import React from 'react';

const maxWidth = 500;
const maxHeight = 221;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: 'fit-content',
    backgroundColor: theme.palette.primary.lighter,
    borderRadius: 4,
    overflow: 'hidden',
  },
  img: {
    maxWidth,
    maxHeight,
  },
}));

const AttachmentPreview = ({ attachment }: { attachment: Object }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Attachments attachments={[attachment]} maxWidth={maxWidth} maxHeight={maxHeight} />
    </div>
  );
};

export default AttachmentPreview;
