/* eslint-disable jsx-a11y/media-has-caption */
import CropRotate from '@mui/icons-material/CropRotate';
import {
  Button,
  ButtonBase,
  IconButton,
  SvgIcon,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import ChatPanelContext from 'components/@home/@messages/ChatPanel/ChatPanelContext';
import FileThumbnail from 'components/@home/@messages/ChatPanel/MessagesList/MessageGroup/FileThumbnail';
import Loading from 'components/common/Loading';
import ResizableDraggable from 'components/common/ResizableDraggable';
import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import CloseIcon from 'mdi-react/CloseIcon';
import EditIcon from 'mdi-react/EditIcon';
import FileIcon from 'mdi-react/FileIcon';
import MicrophoneIcon from 'mdi-react/MicrophoneIcon';
import PlayIcon from 'mdi-react/PlayIcon';
import UndoIcon from 'mdi-react/UndoIcon';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import CanvasDraw from 'react-canvas-draw';
import drawImage from 'react-canvas-draw/lib/drawImage';
import useDimensions from 'react-cool-dimensions';
import { I18n } from 'utils/i18n';
import filesize from 'utils/filesize';

const useStyles = makeStyles(theme => ({
  root: {
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    display: 'flex',
    flex: '1 0 auto',
    flexDirection: 'row',
    gap: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  images: {
    flex: '1 0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'relative',
  },
  image: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
  },
  video: {
    maxWidth: '90%',
    maxHeight: '90%',
  },
  hidden: {
    visibility: 'hidden',
  },
  close: {
    color: theme.palette.primary.ultraDark,
  },
  bottomToolbar: {
    display: 'flex',
    justifyContent: 'center',
    background: theme.palette.white,
  },
  previews: {
    flex: '1 0 auto',
    display: 'flex',
    justifyContent: 'center',
    gap: 10,
  },
  fileIcon: {
    width: 64,
    height: 64,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.primary.light,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.extraLight,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  thumb: {
    width: 64,
    height: 64,
    objectFit: 'cover',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.extraLight,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selected: {
    borderColor: theme.palette.primary.main,
    borderWidth: 3,
  },
  sendButton: {
    color: theme.palette.primary.dark,
    fontWeight: 'bold',
  },
  doneButton: {
    color: theme.palette.primary.dark,
    fontWeight: 'normal',
  },
  bottom: {
    flex: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  captionWrapper: {
    marginBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  caption: {
    width: '70%',
  },
  multiline: {
    padding: 0,
  },
  toggle: {
    borderRadius: '50%',
    border: 0,
  },
  brushProperties: {
    display: 'flex',
    flex: '1 0 auto',
    gap: '25%',
    justifyContent: 'center',
  },
  colors: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  radii: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  radiusWrapper: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  radius: {
    borderRadius: '50%',
  },
  groupRoot: {
    gap: theme.spacing(1),
  },
  grouped: {
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: '50%',
    },
    '&:first-child': {
      borderRadius: '50%',
    },
    '&:not(:last-child)': {
      borderRadius: '50%',
    },
  },
  crop: {
    border: '1px solid black',
    display: 'grid',
    gridTemplateColumns: '50% 50%',
  },
  loading: {
    padding: '6px 8px',
  },
  fileName: {
    flex: '1 0 auto',
    textAlign: 'center',
  },
  title: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '1.38',
    letterSpacing: '-0.4px',
    color: 'inherit',
  },
  subheading: {
    fontSize: 12,
    color: 'inherit',
  },
  description: {
    flexGrow: 1,
    paddingLeft: 10,
    paddingRight: 20,
    color: 'inherit',
    cursor: 'pointer',
  },
  audio: {
    display: 'flex',
    color: theme.palette.primary.main,
  },
  audioWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  playIcon: {
    position: 'absolute',
    padding: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(0,0,0,0.3)',
    borderRadius: '50%',
    color: theme.palette.white,
  },
  videoThumb: {
    width: 58,
    height: 58,
  },
}));

const colors = [
  '#434343',
  '#9DA0A9',
  '#FFFFFF',
  '#33CEFF',
  '#64DC2F',
  '#FFEA3B',
  '#F49226',
  '#FF4A4A',
];

const DEFAULT_COLOR = colors[3];

const radii = [3, 5, 7, 11];

const DEFAULT_RADIUS = radii[0];

const ImageUploadPreview = ({ onSend, isUploading, progress }) => {
  const classes = useStyles();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0, aspectRatio: 0 });
  const { observe } = useDimensions({
    onResize: ({ width, height }) => {
      setDimensions({ width, height, aspectRatio: width / height });
    },
  });
  const { imagesToUpload, setImagesToUpload, setProps } = useContext(ChatPanelContext);
  const [currentImageIdx, setCurrentImageIdx] = useState(0);
  const canvasRefs = useRef([]);
  const addRef = idx => el => {
    canvasRefs.current[idx] = el;
  };
  const [state, setState] = useState('none');
  const drawing = state === 'drawing';
  const cropping = state === 'cropping';
  const [radius, setRadius] = useState(DEFAULT_RADIUS);
  const [color, setColor] = useState(DEFAULT_COLOR);
  const currentImage = imagesToUpload[currentImageIdx];
  const changeState = useCallback(
    (e, newState) => {
      setState(oldState => (newState === oldState ? 'none' : newState));
    },
    [setState],
  );
  const exportImage = useCallback(() => {
    setTimeout(() => {
      const img = document.createElement('img');
      img.crossOrigin = 'anonymous';
      img.src = currentImage.origSrc;
      img.onload = () => {
        const srcCanvas = canvasRefs.current[currentImageIdx].canvas.drawing;
        const destCanvas = document.createElement('canvas');
        const croppingBox = mapValues(currentImage.croppingBox, (v, k, o) => v * o.scale);
        destCanvas.width = croppingBox.width;
        destCanvas.height = croppingBox.height;
        const destCtx = destCanvas.getContext('2d');
        destCtx.globalCompositeOperation = 'source-over';
        drawImage({
          ctx: destCtx,
          img,
          x: -croppingBox.left,
          y: -croppingBox.top,
          w: currentImage.width,
          h: currentImage.height,
        });
        const croppedDataUrl = destCanvas.toDataURL('image/png');
        destCtx.drawImage(srcCanvas, 0, 0, destCanvas.width, destCanvas.height);
        const dataURL = destCanvas.toDataURL('image/png');
        setProps({ src: dataURL, croppedSrc: croppedDataUrl }, currentImageIdx);
      };
    }, 0);
  }, [
    currentImage.croppingBox,
    currentImage.height,
    currentImage.origSrc,
    currentImage.width,
    currentImageIdx,
    setProps,
  ]);
  const handleResize = box => {
    canvasRefs.current[currentImageIdx].clear();
    setProps({ croppingBox: box }, currentImageIdx);
  };

  useEffect(() => {
    if (currentImage) {
      exportImage();
    }
  }, [currentImage?.croppingBox]);

  const handleDrawEnd = useCallback(() => {
    exportImage();
  }, [exportImage]);
  const undo = () => {
    canvasRefs.current?.[currentImageIdx]?.undo();
    handleDrawEnd();
  };
  useEffect(() => {
    if (!canvasRefs.current[currentImageIdx]) {
      return () => {};
    }
    const canvas = canvasRefs.current[currentImageIdx].canvas.interface;
    if (state === 'drawing') {
      canvas.addEventListener('mouseup', handleDrawEnd);
      canvas.addEventListener('mouseout', handleDrawEnd);
      canvas.addEventListener('touchend', handleDrawEnd);
      canvas.addEventListener('touchcancel', handleDrawEnd);
    }
    return () => {
      canvas.removeEventListener('mouseup', handleDrawEnd);
      canvas.removeEventListener('mouseout', handleDrawEnd);
      canvas.removeEventListener('touchend', handleDrawEnd);
      canvas.removeEventListener('touchcancel', handleDrawEnd);
    };
  }, [state, currentImageIdx, dimensions.aspectRatio, handleDrawEnd]);
  const close = useCallback(() => {
    setImagesToUpload([]);
  }, []);
  const handleChange = useCallback(
    e => {
      setProps({ caption: e.target.value }, currentImageIdx);
    },
    [currentImageIdx],
  );
  const select = idx => () => setCurrentImageIdx(idx);
  return (
    <div className={classes.root}>
      <Toolbar>
        <IconButton
          className={classes.close}
          edge="end"
          aria-label="close"
          onClick={close}
          size="large"
        >
          <CloseIcon />
        </IconButton>
        {currentImage.src ? (
          <div className={classes.actions}>
            <ToggleButtonGroup
              classes={{ root: classes.groupRoot, grouped: classes.grouped }}
              onChange={changeState}
              value={state}
              exclusive
            >
              <ToggleButton classes={{ root: classes.toggle }} value="drawing">
                <EditIcon />
              </ToggleButton>
              <ToggleButton classes={{ root: classes.toggle }} value="cropping">
                <CropRotate />
              </ToggleButton>
            </ToggleButtonGroup>
            <IconButton
              onClick={undo}
              disabled={
                isEmpty(canvasRefs.current?.[currentImageIdx]?.lines) &&
                isEmpty(canvasRefs.current?.[currentImageIdx]?.erasedLines)
              }
              size="large"
            >
              <UndoIcon />
            </IconButton>
          </div>
        ) : null}
        <Button
          className={classes.doneButton}
          style={{ visibility: cropping || drawing ? 'visible' : 'hidden' }}
          type="button"
          onClick={() => setState('none')}
        >
          {I18n.t('SendMessageForm.Done')}
        </Button>
      </Toolbar>
      <div className={classes.images} ref={observe}>
        {!!dimensions.aspectRatio &&
          imagesToUpload.map(img => {
            if (img.src) {
              const aspectRatio = cropping ? img.aspectRatio : img.croppingBox.aspectRatio;
              const src = cropping ? img.origSrc : img.croppedSrc;
              const width =
                aspectRatio > dimensions.aspectRatio
                  ? dimensions.width * 0.8
                  : dimensions.height * 0.8 * aspectRatio;
              const height =
                aspectRatio > dimensions.aspectRatio
                  ? (dimensions.width * 0.8) / aspectRatio
                  : dimensions.height * 0.8;
              return (
                <div
                  key={img.idx}
                  className={classnames(classes.image, {
                    [classes.hidden]: currentImageIdx !== img.idx,
                  })}
                >
                  <CanvasDraw
                    className={cropping ? classes.hidden : null}
                    hideGrid
                    hideInterface={!drawing}
                    lazyRadius={10}
                    canvasWidth={width}
                    canvasHeight={height}
                    catenaryColor="transparent"
                    disabled={!drawing || currentImageIdx !== img.idx}
                    brushRadius={radius}
                    brushColor={color}
                    imgSrc={src}
                    ref={addRef(img.idx)}
                  />
                  {cropping && (
                    <>
                      <img
                        src={img.origSrc}
                        alt=""
                        className={classes.image}
                        style={{ width, height }}
                      />
                      <ResizableDraggable
                        imgWidth={img.width}
                        box={img.croppingBox}
                        onResize={handleResize}
                      />
                    </>
                  )}
                </div>
              );
            }
            if (img.file.type.startsWith('video/')) {
              return (
                <video
                  key={img.idx}
                  className={classnames(classes.video, classes.image, {
                    [classes.hidden]: currentImageIdx !== img.idx,
                  })}
                  controls
                >
                  <source src={URL.createObjectURL(img.file)} />
                </video>
              );
            }
            if (img.file.type.startsWith('audio/')) {
              return (
                <div
                  key={img.idx}
                  className={classnames(
                    {
                      [classes.hidden]: currentImageIdx !== img.idx,
                    },
                    classes.audioWrapper,
                  )}
                >
                  <div className={classes.audio}>
                    <SvgIcon>
                      <MicrophoneIcon />
                    </SvgIcon>
                    <div className={classes.description}>
                      <Typography className={classes.title}>{img.file.name}</Typography>
                      <Typography className={classes.subheading}>
                        {filesize(img.file.size || 0)}
                      </Typography>
                    </div>
                  </div>
                  <audio controls>
                    <source src={URL.createObjectURL(img.file)} />
                  </audio>
                </div>
              );
            }
            return (
              <div
                key={img.idx}
                className={classnames(classes.image, {
                  [classes.hidden]: currentImageIdx !== img.idx,
                })}
              >
                <FileThumbnail key={img.idx} data={currentImage.file} />
              </div>
            );
          })}
      </div>
      <div className={classes.bottom}>
        <div className={classes.captionWrapper}>
          {drawing ? (
            <div className={classes.brushProperties}>
              <div className={classes.colors}>
                {colors.map(backgroundColor => (
                  <IconButton
                    key={backgroundColor}
                    style={{
                      backgroundColor,
                      transform: `scale(${backgroundColor === color ? 1.2 : 0.9})`,
                    }}
                    onClick={() => setColor(backgroundColor)}
                    size="large"
                  />
                ))}
              </div>
              <div className={classes.radii}>
                {radii.map(rad => (
                  <ButtonBase
                    className={classes.radiusWrapper}
                    key={rad}
                    onClick={() => setRadius(rad)}
                  >
                    <div
                      className={classes.radius}
                      style={{
                        backgroundColor: radius === rad ? '#fff' : '#ccc',
                        width: rad * window.devicePixelRatio,
                        height: rad * window.devicePixelRatio,
                      }}
                    />
                  </ButtonBase>
                ))}
              </div>
            </div>
          ) : (
            currentImage.src && (
              <TextField
                className={classes.caption}
                InputProps={{ className: classes.multiline }}
                type="text"
                value={currentImage.caption || ''}
                onChange={handleChange}
                placeholder={I18n.t('ImageUploadPreview.Caption')}
                multiline
              />
            )
          )}
        </div>
        <Toolbar className={classes.bottomToolbar}>
          <div className={classes.previews}>
            {imagesToUpload.map((img, idx) => (
              <Button key={img.idx} onClick={select(idx)}>
                {(() => {
                  if (img.src) {
                    return (
                      <img
                        className={classnames(classes.thumb, {
                          [classes.selected]: idx === currentImageIdx,
                        })}
                        alt=""
                        src={img.src}
                      />
                    );
                  }
                  if (img.file.type.startsWith('video/')) {
                    return (
                      <div
                        className={classnames(classes.thumb, {
                          [classes.selected]: idx === currentImageIdx,
                        })}
                      >
                        <div className={classes.playIcon}>
                          <PlayIcon />
                        </div>
                        <video className={classes.videoThumb}>
                          <source src={URL.createObjectURL(img.file)} />
                        </video>
                      </div>
                    );
                  }
                  if (img.file.type.startsWith('audio/')) {
                    return (
                      <div
                        className={classnames(classes.fileIcon, {
                          [classes.selected]: idx === currentImageIdx,
                        })}
                      >
                        <MicrophoneIcon />
                      </div>
                    );
                  }
                  return (
                    <div
                      className={classnames(classes.fileIcon, {
                        [classes.selected]: idx === currentImageIdx,
                      })}
                    >
                      <FileIcon />
                    </div>
                  );
                })()}
              </Button>
            ))}
          </div>
          {isUploading ? (
            <div className={classes.loading}>
              <Loading value={progress} debounce={0} size={30} />
            </div>
          ) : (
            <Button className={classes.sendButton} type="button" onClick={onSend}>
              {I18n.t('SendMessageForm.Send')}
            </Button>
          )}
        </Toolbar>
      </div>
    </div>
  );
};

ImageUploadPreview.propTypes = {
  onSend: PropTypes.func.isRequired,
  isUploading: PropTypes.bool,
  progress: PropTypes.number,
};

ImageUploadPreview.defaultProps = {
  isUploading: false,
  progress: null,
};
export default ImageUploadPreview;
