import React, { Component } from 'react';
import { string, object, shape, bool, func } from 'prop-types';
import { TableCell, Tooltip, TableSortLabel } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { I18n } from 'utils/i18n';

const style = {
  head: {
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 13,
  },
};

class Cell extends Component {
  sortBy = name => () => {
    const { onSort } = this.props;
    onSort(name);
  };

  render() {
    const { classes, order, label, name, sortable } = this.props;

    return (
      <TableCell classes={{ head: classes.head }}>
        {sortable ? (
          <Tooltip title={I18n.t('Sort')} enterDelay={300}>
            <TableSortLabel
              hideSortIcon={!sortable}
              active={order.by === name}
              onClick={this.sortBy(name)}
              direction={order.isReverse ? 'desc' : 'asc'}
            >
              {label}
            </TableSortLabel>
          </Tooltip>
        ) : (
          label
        )}
      </TableCell>
    );
  }
}

Cell.propTypes = {
  sortable: bool.isRequired,
  classes: object.isRequired,
  label: string.isRequired,
  name: string.isRequired,
  onSort: func.isRequired,
  order: shape({
    by: string.isRequired,
    isReverse: bool.isRequired,
  }).isRequired,
};

export default withStyles(style)(Cell);
