import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import auth from 'store/app/auth/action';
import companies from 'store/app/entities/companies/action';
import employees from 'store/app/entities/employees/action';
import authCompany from 'store/selectors/authCompany';
import authEmployee from 'store/selectors/authEmployee';
import populateChannels from 'store/selectors/populateChannels';

const mapStateToProps = state => ({
  company: authCompany(state),
  channels: populateChannels(state),
  employee: authEmployee(state),
});

const mapDispatchToProps = dispatch => ({
  actions: {
    auth: bindActionCreators(auth, dispatch),
    companies: bindActionCreators(companies, dispatch),
    employees: bindActionCreators(employees, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
