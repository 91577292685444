import { TableCell, TableHead, TableRow } from '@mui/material';
import { bool, func, number, shape, string } from 'prop-types';
import React, { memo } from 'react';
import SelectAllCheckbox from './SelectAllCheckbox';
import TitleCells from './TitleCells';

const styles = {
  root: {
    width: '100%',
    borderTop: '1px solid',
    borderColor: 'divider',
  },
};

const EnhancedTableHead = ({ onSelectAll, rows, selected, order, onSort, search }) => {
  return (
    <TableHead sx={styles.root}>
      <TableRow>
        <TableCell padding="checkbox">
          <SelectAllCheckbox
            search={search}
            rows={rows}
            key="checkbox"
            selected={selected}
            onChange={onSelectAll}
          />
        </TableCell>
        <TitleCells order={order} onSort={onSort} />
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  rows: number.isRequired,
  selected: number.isRequired,
  order: shape({ by: string, isReverse: bool }).isRequired,
  onSort: func.isRequired,
  onSelectAll: func.isRequired,
  search: string.isRequired,
};

export default memo(EnhancedTableHead);
