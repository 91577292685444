/**
 * Truncates string to fit within given length with appended ellipsis.
 */
const stringTruncate = (str, length) => {
  if (!str || str?.length <= length) return str;

  const ellipsis = '…';

  const truncated = str.substring(0, length - ellipsis.length);
  return `${truncated}${ellipsis}`;
};

export default stringTruncate;
