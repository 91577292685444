import isURL from 'validator/lib/isURL';

const defaultOptions = {
  protocols: ['https'],
  require_tld: true,
  require_protocol: true,
  require_host: true,
  require_valid_protocol: true,
  allow_underscores: false,
  allow_trailing_dot: false,
  allow_protocol_relative_urls: false,
  validate_length: true,
};

const isUrl = (url, options = {}) => isURL(url, { ...defaultOptions, ...options });

export default isUrl;
