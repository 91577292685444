import { PushPin } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { bool, number } from 'prop-types';
import React, { memo } from 'react';
import connector from './connector';

const useStyles = makeStyles(theme => ({
  root: {},
  badge: {
    padding: 3,
    minWidth: 20,
    height: 20,
    marginLeft: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: '1em',
    backgroundColor: theme.palette.primary.dark,
    borderRadius: 100,
    textAlign: ' center',
    fontSize: '10px',
    color: theme.palette.white,
    boxShadow: '0 1px 5px 0 rgba(36, 202, 165, 0.2)',
  },
  noBadge: {
    marginLeft: 0,
    width: 0,
    display: 'flex',
  },
}));

const MessagesBadge = ({ isCompanyAdmin, messagesUnreadCount, mandatoriesUnreadCount, pinned }) => {
  const classes = useStyles();
  if (!pinned && !(messagesUnreadCount + mandatoriesUnreadCount))
    return <span className={classes.noBadge} />;

  if (isCompanyAdmin) {
    if (!messagesUnreadCount)
      return pinned ? (
        <PushPin color="disabled" fontSize="small" />
      ) : (
        <span className={classes.noBadge} />
      );

    return <span className={classes.badge}>{messagesUnreadCount}</span>;
  }

  if (!(messagesUnreadCount + mandatoriesUnreadCount))
    return pinned ? (
      <PushPin color="disabled" fontSize="small" />
    ) : (
      <span className={classes.noBadge} />
    );

  return <span className={classes.badge}>{messagesUnreadCount + mandatoriesUnreadCount}</span>;
};

MessagesBadge.propTypes = {
  messagesUnreadCount: number,
  mandatoriesUnreadCount: number,
  isCompanyAdmin: bool.isRequired,
  pinned: bool.isRequired,
};
MessagesBadge.defaultProps = {
  messagesUnreadCount: 0,
  mandatoriesUnreadCount: 0,
};

const isEqual = (prev, next) => {
  if (prev.pinned !== next.pinned) return false;
  if (prev.messagesUnreadCount !== next.messagesUnreadCount) return false;
  if (prev.mandatoriesUnreadCount !== next.mandatoriesUnreadCount) return false;
  return prev.isCompanyAdmin === next.isCompanyAdmin;
};

export default connector(memo(MessagesBadge, isEqual));
