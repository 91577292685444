const formats = {
  '+1': '(###) ###-####',
  '+7': '(###) ###-##-##',
  '+31': '## ########',
  '+32': '### ## ## ##',
  '+33': '# ## ## ## ##',
  '+34': '### ### ###',
  '+39': '## #### ####',
  '+41': '## ### ## ##',
  '+44': '#### ######',
  '+45': '## ## ## ##',
  '+46': '(###) ###-###',
  '+47': '### ## ###',
  '+48': '###-###-###',
  '+49': '#### ########',
  '+52': '### ### ####',
  '+54': '(##) ########',
  '+55': '(##) #########',
  '+57': '### ### ####',
  '+60': '##-####-####',
  '+61': '(##) #### ####',
  '+63': '#### #######',
  '+64': '###-###-####',
  '+65': '####-####',
  '+81': '## #### ####',
  '+82': '### #### ####',
  '+86': '##-#########',
  '+90': '### ### ## ##',
  '+91': '#####-#####',
  '+92': '###-#######',
  '+98': '### ### ####',
  '+225': '## ## ## ##',
  '+353': '## #######',
  '+354': '### ####',
  '+357': '## ######',
  '+358': '## ### ## ##',
  '+372': '#### ######',
  '+373': '(##) ##-##-##',
  '+375': '(##) ### ## ##',
  '+380': '(##) ### ## ##',
  '+502': '####-####',
  '+503': '####-####',
  '+506': '####-####',
  '+509': '####-####',
  '+852': '#### ####',
  '+972': '### ### ####',
  '+994': '(##) ### ## ##',
};

export const DEFAULT_FORMAT = '### ### ###';

const prefixFormat = prefix => formats[prefix] || DEFAULT_FORMAT;

export default prefixFormat;
