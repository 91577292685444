import { makeStyles } from '@mui/styles';
import BrowserNotifications from 'components/@home/drawers/SettingsDrawer/SettingsForm/BrowserNotifications';
import MobileNotifications from 'components/@home/drawers/SettingsDrawer/SettingsForm/MobileNotifications';
import WorkingDays from 'components/@home/drawers/SettingsDrawer/SettingsForm/WorkingDays';
import cloneDeep from 'lodash/cloneDeep';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'utils/i18n';
import auth from 'store/app/auth/action';
import employees from 'store/app/entities/employees/action';
import settingsAction from 'store/app/entities/settings/action';
import authCompany from 'store/selectors/authCompany';
import authEmployee from 'store/selectors/authEmployee';
import currentEmployee from 'store/selectors/currentEmployee';
import useActions from 'utils/useActions';
import { useDebounceCallback } from 'utils/useDebounceCallback';
import Company from './Company';
import Employee from './Employee';
import Headline from './Headline';
import Item from './Item';
import Language from './Language';
import TimeZone from './TimeZone';
import Title from './Title';
import Wrapper from './Wrapper';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 455,
    padding: '20px 45px 45px 40px',
  },
}));

const DELAY = 1000;

const SettingsForm = () => {
  const classes = useStyles();
  const company = useSelector(authCompany);
  const { _id } = useSelector(authEmployee);
  const storeEmployee = useSelector(currentEmployee);
  const employee = useMemo(() => cloneDeep(storeEmployee), [storeEmployee]);
  const [changed, setChanged] = useState(null);
  const [settingsUpdate, employeesModify, logout] = useActions([
    settingsAction.update,
    employees.modify,
    auth.logout,
  ]);

  const settingsUpdateDebounced = useDebounceCallback(
    useCallback(
      (...args) => {
        settingsUpdate(...args);
        setChanged(null);
      },
      [settingsUpdate],
    ),
    DELAY,
  );

  const settings = useMemo(() => ({ ...employee.settings, ...changed }), [employee, changed]);

  useEffect(() => {
    if (changed) {
      settingsUpdateDebounced(_id, changed);
    }
  }, [_id, settings, settingsUpdateDebounced, changed]);

  const changeEmployeeSettings = useCallback(
    update => {
      employeesModify(_id, {
        settings: {
          ...settings,
          ...update,
        },
      });
      setChanged(c => {
        const newVar = { ...c, ...update };
        console.log('------', c, update, newVar);
        return newVar;
      });
    },
    [employeesModify, _id, settings, setChanged],
  );

  const changeNotifications = useCallback(
    value => {
      const update = {
        isOpenCommunication: value === 'Always',
        dnd: value === 'Never',
      };
      changeEmployeeSettings(update);
    },
    [changeEmployeeSettings],
  );

  const changeBrowserNotifications = useCallback(
    value => {
      changeEmployeeSettings({ browser: value });
    },
    [changeEmployeeSettings],
  );

  const changeLanguage = useCallback(
    event => {
      changeEmployeeSettings({
        language: event.value,
      });
    },
    [changeEmployeeSettings],
  );

  const changeTimeZone = useCallback(
    event => {
      changeEmployeeSettings({
        timeZone: event.value,
      });
    },
    [changeEmployeeSettings],
  );

  const changeWorkingDays = useCallback(
    workingDays => {
      changeEmployeeSettings({
        workingDays,
      });
    },
    [changeEmployeeSettings],
  );

  return (
    <form className={classes.root}>
      <Headline title={I18n.t('SettingsDrawer.Settings')} />

      <Wrapper marginTop="40px">
        <Employee name={employee.firstName} surname={employee.surName} />
        <Company headline={company.name} />
        {employee.code ? <Item headline={employee.code} /> : null}
        <Item headline={employee.jobTitle} />
        <Item headline={employee.department} />
      </Wrapper>

      <Wrapper marginTop="40px">
        <Title headline={I18n.t('SettingsDrawer.Notifications')} />
        <BrowserNotifications
          settings={settings.browser || { sound: true }}
          onChange={changeBrowserNotifications}
        />
        <MobileNotifications settings={settings} onChange={changeNotifications} />
        {!settings.dnd && !settings.isOpenCommunication ? (
          <WorkingDays value={settings.workingDays} onChange={changeWorkingDays} />
        ) : null}
      </Wrapper>

      <Wrapper marginTop="40px">
        <Title headline={I18n.t('SettingsDrawer.Language & Region')} />
        <Language onChange={changeLanguage} />
        <TimeZone onChange={changeTimeZone} />
      </Wrapper>

      <Wrapper marginTop="auto">
        <Title headline={I18n.t('SettingsDrawer.Account')} />
        <Item headline={I18n.t('SettingsDrawer.Logout')} onClick={logout} />
      </Wrapper>
    </form>
  );
};

export default SettingsForm;
