/* eslint-disable max-len,react/destructuring-assignment */
import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Loading from 'components/common/Loading';
import { bool, object, shape, string } from 'prop-types';
import React, { useEffect, useCallback } from 'react';
import { I18n } from 'utils/i18n';
import channelShape from 'shapes/channel';
import employeeShape from 'shapes/employee';
import messageShape from 'shapes/message';
import Modal from '../../Modal';
import connector from './connector';
import Information from './Information';

const styles = () => ({
  root: {
    position: 'relative',
    padding: '20px 0 10px',
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    margin: '0 0 15px',
    textAlign: 'center',
    width: '100%',
    wordBreak: 'break-all',
  },
  iframeShadow: {
    maxWidth: 860,
    width: '90vw',
    height: '70vh',
    maxHeight: 'calc(100vh - 250px)',
    position: 'relative',
    '&::before': {
      content: `''`,
      position: 'absolute',
      boxShadow: 'inset 0px -10px 10px -10px rgba(0,0,0,0.5)',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      pointerEvents: 'none',
    },
  },
  iframe: {
    zIndex: 'initial',
    border: 0,
    width: '100%',
    height: '100%',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: 20,
    height: 39,
  },
  download: {
    height: 39,
    marginRight: 10,
  },
});

const SignatureModal = ({
  classes,
  isSignatureOpen,
  actions,
  message,
  employee,
  channel: { isDirect, isAdmin } = {},
  authEmployee,
  isCompanyAdmin,
  isTeamAdmin,
  signUrl,
}) => {
  const emp = employee || authEmployee;
  const acknowledge = message?.acknowledges?.find(m => m.user === emp?.user?._id);

  const openAnalyticsDrawer = () => {
    actions.acknowledgmentReadModal.close();
    actions.analyticsDrawer.open(message);
  };

  const handleSignatureRedirection = useCallback(() => {
    actions.acknowledgmentReadModal.close();
  }, [message?._id, actions.acknowledgmentReadModal.close]);

  useEffect(() => {
    if (isSignatureOpen) {
      window.addEventListener('signatureRedirection', handleSignatureRedirection);
    } else {
      window.removeEventListener('signatureRedirection', handleSignatureRedirection);
    }
    return () => {
      window.removeEventListener('signatureRedirection', handleSignatureRedirection);
    };
  }, [handleSignatureRedirection]);

  if (!isSignatureOpen) return null;

  return (
    <Modal isOpen={isSignatureOpen} onClose={actions.acknowledgmentReadModal.close}>
      <div className={classes.root}>
        <h3 className={classes.title}>{message.text || message.title}</h3>
        <Information confirmedAt={acknowledge?.confirmedAt} isDigitalSignature />
        {signUrl ? (
          <div className={classes.iframeShadow}>
            <iframe className={classes.iframe} title="pdf" src={signUrl} />
          </div>
        ) : (
          <Loading classes={classes.loading} size={40} key="loading" />
        )}
        <div className={classes.actions}>
          {isCompanyAdmin || (isTeamAdmin && isDirect) || isAdmin ? (
            <Button variant="outlined" className={classes.showAcks} onClick={openAnalyticsDrawer}>
              {' '}
              {I18n.t('Documents.Monitor')}
            </Button>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};

SignatureModal.propTypes = {
  isCompanyAdmin: bool.isRequired,
  isTeamAdmin: bool.isRequired,
  classes: object.isRequired,
  isSignatureOpen: bool.isRequired,
  message: messageShape,
  channel: channelShape,
  actions: shape({
    acknowledgmentReadModal: object.isRequired,
  }).isRequired,
  employee: employeeShape,
  authEmployee: employeeShape,
  signUrl: string,
};
SignatureModal.defaultProps = {
  message: null,
  authEmployee: null,
  employee: null,
  signUrl: null,
  channel: undefined,
};
export default withStyles(styles)(connector(SignatureModal));
