import { createApi } from '@reduxjs/toolkit/dist/query/react';
import baseQuery from 'store/app/entities/baseQuery';

const initialState = {
  contents: {},
};

const endpoints = build => ({
  createContent: build.mutation({
    query: body => ({
      url: 'v1/content',
      method: 'POST',
      body,
    }),
    invalidatesTags: () => [{ type: 'Contents', id: 'LIST' }],
  }),
  deleteContent: build.mutation({
    query: id => ({
      url: `v1/content/${id}`,
      method: 'DELETE',
    }),
    invalidatesTags: (result, error, id) => [
      { type: 'Contents', id: 'LIST' },
      { type: 'Contents', id },
    ],
  }),
  editContent: build.mutation({
    query: ({ _id, body }) => ({
      url: `v1/content/${_id}`,
      method: 'PATCH',
      body,
    }),
    invalidatesTags: (result, error, { id }) => [{ type: 'Contents', id }],
  }),
  listContents: build.query({
    query: ({
      page = '',
      perPage = '',
      sortBy = '',
      sort = '',
      search = '',
      template = false,
    }) => ({
      url: `v1/content?${search ? `search=${search}&searchBy=name` : ''}${
        template ? `template=1` : ''
      }&${page ? `page=${page}` : ''}&perPage=${perPage}&${
        sort ? `sortBy=${sortBy}&sort=${sort}` : ''
      }`,
    }),
    transformResponse: response => response.data,
    providesTags: ({ contents = [] }) => [
      ...(contents || []).map(({ _id: id }) => ({
        type: 'Contents',
        id,
      })),
      {
        type: 'Contents',
        id: 'LIST',
      },
    ],
  }),
});

const ContentsSlice = createApi({
  reducerPath: 'contentsReducer',
  baseQuery,
  tagTypes: ['Contents'],
  initialState,
  keepUnusedDataFor: 30,
  endpoints,
});

export const {
  useListContentsQuery,
  useCreateContentMutation,
  useEditContentMutation,
  useDeleteContentMutation,
} = ContentsSlice;

export const { reducer: contentsReducer, middleware: contentsMiddleware } = ContentsSlice;
