import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const UNMOUNTED = 'unmounted';
const EXITED = 'exited';
const ENTERING = 'entering';
const ENTERED = 'entered';
const EXITING = 'exiting';

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

const FadeInOut = ({ show, duration, children: childrenProp, style, ...rest }) => {
  const [children, setChildren] = useState(childrenProp);
  const [status, setStatus] = useState(UNMOUNTED);

  useEffect(() => {
    if (show || [UNMOUNTED, EXITED].includes(status)) {
      setChildren(childrenProp);
    }
  }, [childrenProp, show, status]);

  useEffect(() => {
    if (status === ENTERING) {
      setTimeout(() => {
        setStatus(ENTERED);
      }, 0);
    } else if (status === EXITING) {
      setTimeout(() => {
        setStatus(EXITED);
      }, duration);
    }
  }, [duration, status]);

  useEffect(() => {
    if (show) {
      if (status !== ENTERING && status !== ENTERED) {
        setStatus(ENTERING);
      }
    } else if (status === ENTERING || status === ENTERED) {
      setStatus(EXITING);
    }
  }, [show, status]);

  if (status === UNMOUNTED) {
    return null;
  }

  return (
    <Box
      style={{
        ...style,
        transition: `opacity ${duration}ms ease-in-out`,
        opacity: 0.1,
        ...transitionStyles[status],
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

FadeInOut.propTypes = {
  show: PropTypes.bool,
  duration: PropTypes.number,
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};
FadeInOut.defaultProps = {
  show: false,
  duration: 500,
  style: {},
};

export default FadeInOut;
