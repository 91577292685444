export const OPEN_DIRECTS_DRAWER = 'OPEN_DIRECTS_DRAWER';
export const CLOSE_DIRECTS_DRAWER = 'CLOSE_DIRECTS_DRAWER';

const open = data => ({
  type: OPEN_DIRECTS_DRAWER,
  payload: data,
});

const close = () => ({
  type: CLOSE_DIRECTS_DRAWER,
});

export default { open, close };
