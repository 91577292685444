import { SvgIcon } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import AttachIcon from 'assets/icons/attach.svg';
import Loading from 'components/common/Loading';
import AlertCircleIcon from 'mdi-react/AlertCircleIcon';
import { bool, func, number } from 'prop-types';
import React from 'react';
import { I18n } from 'utils/i18n';
import OptionButton from '../../buttons/OptionButton';

const styles = {
  root: {},
};

const AttachmentButton = ({ isLoading, isError, onClick, progress, onCancel }) => {
  const onCancelProp = onCancel ? { onCancel } : {};
  if (isLoading) return <Loading value={progress} debounce={0} size={30} {...onCancelProp} />;
  if (isError)
    return (
      <SvgIcon color="error">
        <AlertCircleIcon />
      </SvgIcon>
    );

  return (
    <OptionButton icon={<AttachIcon />} onClick={onClick}>
      {I18n.t('SendMessageForm.Attach file')}
    </OptionButton>
  );
};

AttachmentButton.propTypes = {
  onClick: func.isRequired,
  isLoading: bool.isRequired,
  isError: bool.isRequired,
  progress: number,
  onCancel: func,
};

AttachmentButton.defaultProps = {
  progress: null,
  onCancel: null,
};

export default withStyles(styles)(AttachmentButton);
