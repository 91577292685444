/**
 * @flow
 */
import { Box, List, Typography } from '@mui/material';
import NoMessages from 'assets/images/no-messages.svg';
import MessageListItem from 'components/@whistleblower-admin/drawers/OccurrenceDrawer/MessageListItem';
import { useWhistleblower } from 'components/@whistleblower-admin/WhistleblowerContext';
import Loading from 'components/common/Loading';
import React from 'react';
import { Translate } from 'utils/i18n';
import { useParams } from 'react-router-dom';
import { useGetOccurrenceMessagesQuery } from 'store/app/entities/WbSlice';

const EmptyMessageList = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',
      p: 4,
    }}
  >
    <NoMessages style={{ height: 50, width: 'auto', opacity: 0.5 }} />
    <Typography sx={{ fontSize: '1.25rem', mt: 1 }}>
      <Translate value="OccurrenceDrawer.noResponses" />
    </Typography>
    <Typography>
      <Translate value="OccurrenceDrawer.noOneHasResponded" />
    </Typography>
  </Box>
);
const MessageList = () => {
  const { '*': id } = useParams();
  const { publicKey } = useWhistleblower();
  const { data: messages, isLoading } = useGetOccurrenceMessagesQuery({ id, body: { publicKey } });
  if (isLoading) {
    return <Loading size={20} />;
  }
  if (messages.length === 0) {
    return <EmptyMessageList />;
  }
  return (
    <Box sx={{ mb: 2 }}>
      <List sx={{ py: 0 }}>
        {messages.map(message => (
          <MessageListItem key={message._id} message={message} />
        ))}
      </List>
    </Box>
  );
};
MessageList.defaultProps = {
  messages: [],
};
export default MessageList;
