import { lighten } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import looksLikeDate from 'components/@home/drawers/HrBotDrawer/XMLViewer/looksLikeDate';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: ({ link }) =>
    link
      ? {
          borderRadius: 4,
          padding: 2,
          cursor: 'pointer',
          backgroundColor: '#eaeaac',
          '&:hover, &:focus': {
            backgroundColor: lighten('#ffffbb', 0.02),
          },
        }
      : {
          padding: 2,
          '&:hover, &:focus': {
            backgroundColor: 'inherit',
          },
        },
}));

const TextElement = ({ text, theme, xpath, onClick }) => {
  const classes = useStyles({ link: typeof onClick === 'function' });
  const overflow = theme.overflowBreak ? { overflowWrap: 'break-word', whiteSpace: 'normal' } : {};
  const dateLike = looksLikeDate(text);
  return dateLike ? (
    text.split(/[-/]/).map((part, index) => (
      <>
        {index > 0 && <span>-</span>}
        <span
          className={classes.root}
          onClick={() =>
            typeof onClick === 'function' &&
            onClick(`${xpath}/tokenize(text(), '[-/]' )[position() = ${index + 1}]`, text)
          }
        >
          {part}
        </span>
      </>
    ))
  ) : (
    <span
      onClick={() => typeof onClick === 'function' && onClick(`${xpath}/text()`, text)}
      className={classes.root}
      style={{ color: theme.textColor, ...overflow }}
    >
      {text}
    </span>
  );
};

TextElement.propTypes = {
  text: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  xpath: PropTypes.string.isRequired,
};

export default TextElement;
