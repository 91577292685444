import withStyles from '@mui/styles/withStyles';
import { object } from 'prop-types';
import React from 'react';
import { I18n } from 'utils/i18n';
import employeeShape from 'shapes/employee';
import CopyButton from './CopyButton';

const styles = {
  root: {
    paddingLeft: 15,
  },
};

const InviteLink = ({ employee, classes }) => (
  <div className={classes.root}>
    <CopyButton value={employee.magicLink} text={I18n.t('ProfileDrawer.Copy invite link')} />
  </div>
);

InviteLink.propTypes = {
  employee: employeeShape.isRequired,
  classes: object.isRequired,
};

export default withStyles(styles)(InviteLink);
