import { TableCell, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import React, { useCallback } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  cell: {
    border: 0,
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 13,
    '&:first-child': {
      minWidth: 250,
    },
  },
  head: {
    height: 48,
  },
  xsHidden: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  header: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.29,
    color: theme.palette.secondary.main,
  },
}));

const EnhancedTableHead = ({ order, columns, onSort }) => {
  const classes = useStyles();
  const sort = useCallback(
    property => () => {
      onSort(property);
    },
    [onSort],
  );

  const direction = order.isReverse ? 'desc' : 'asc';
  return (
    <TableHead className={classes.root}>
      <TableRow classes={{ head: classes.head }}>
        {columns.map(header => (
          <TableCell
            classes={{
              root: header.xsVisible ? undefined : classes.xsHidden,
              head: classes.cell,
            }}
            key={header.key}
            sortDirection={order.by === header.key ? direction : false}
          >
            {header.notSortable ? (
              <Typography className={classes.header}>{header.label}</Typography>
            ) : (
              <TableSortLabel
                active={order.by === header.key}
                direction={direction}
                onClick={sort(header.key)}
              >
                <Typography className={classes.header}>{header.label}</Typography>
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export const columnsShape = arrayOf(
  shape({
    key: string.isRequired,
    disablePadding: bool,
    label: string.isRequired,
    xsVisible: bool,
    notSortable: bool,
  }),
);

EnhancedTableHead.defaultProps = {
  order: { by: null, isReverse: false },
};

EnhancedTableHead.propTypes = {
  order: shape({
    by: string.isRequired,
    isReverse: bool.isRequired,
  }),
  columns: columnsShape.isRequired,
  onSort: func.isRequired,
};

export default EnhancedTableHead;
