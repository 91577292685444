/* eslint-disable no-param-reassign */

import delay from 'utils/delay';

export default async (input, text, cbSetValue) => {
  const tagName = (input.tagName || '').toLowerCase();
  const isTextArea = tagName === 'textarea';
  const isText = tagName === 'input' && input.type.toLowerCase() === 'text';
  if (!(isTextArea || isText)) {
    return;
  }

  const scrollPos = input.scrollTop;
  let strPosStart = input.selectionStart;
  let strPosEnd = input.selectionEnd;

  if (strPosEnd < strPosStart) strPosEnd = strPosStart;

  const before = input.value.substring(0, strPosStart);
  const after = input.value.substring(strPosEnd, input.value.length);
  cbSetValue(before + text + after);
  await delay(1);
  strPosStart += text.length;

  input.selectionStart = strPosStart;
  input.selectionEnd = strPosStart;

  input.scrollTop = scrollPos;
};
