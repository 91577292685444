/** @flow */
import React from 'react';

const contexts: { [key: string]: React$Context } = {};

export const createContext = name => {
  contexts[name] = React.createContext();
  return contexts[name];
};

export const useDrawer = name => React.useContext(contexts[name]);

export default contexts;
