import moment from 'moment';
import {
  FETCH_LINK_PREVIEW_FULFILLED,
  FETCH_LINK_PREVIEW_PENDING,
  FETCH_LINK_PREVIEW_REJECTED,
} from 'store/app/entities/linkPreviews/action';
import {
  GENERATE_MESSAGE_PREVIEW_FULFILLED,
  GENERATE_MESSAGE_PREVIEW_PENDING,
  GENERATE_MESSAGE_PREVIEW_REJECTED,
} from 'store/app/entities/messages/action';

const linkPreviewsReducer = (state = {}, { type, payload = { data: {} }, meta }) => {
  switch (type) {
    case GENERATE_MESSAGE_PREVIEW_PENDING:
    case FETCH_LINK_PREVIEW_PENDING:
      return {
        ...state,
        [meta.link]: { isFetching: true, expires: moment().add(30, 'minutes').valueOf() },
      };
    case GENERATE_MESSAGE_PREVIEW_FULFILLED:
    case GENERATE_MESSAGE_PREVIEW_REJECTED:
    case FETCH_LINK_PREVIEW_REJECTED:
    case FETCH_LINK_PREVIEW_FULFILLED:
      return {
        ...state,
        [meta.link]: { ...payload, expires: moment().add(30, 'minutes').valueOf() },
      };
    default:
      return state;
  }
};

export default linkPreviewsReducer;
