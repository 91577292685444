/* eslint-disable object-curly-newline */
import Login from 'services/api/Login';

export const SEND_LOGIN_SMS = 'SEND_LOGIN_SMS';
export const SEND_LOGIN_SMS_PENDING = 'SEND_LOGIN_SMS_PENDING';
export const SEND_LOGIN_SMS_FULFILLED = 'SEND_LOGIN_SMS_FULFILLED';
export const SEND_LOGIN_SMS_REJECTED = 'SEND_LOGIN_SMS_REJECTED';

export const SEND_LOGIN_EMAIL = 'SEND_LOGIN_EMAIL';
export const SEND_LOGIN_EMAIL_PENDING = 'SEND_LOGIN_EMAIL_PENDING';
export const SEND_LOGIN_EMAIL_FULFILLED = 'SEND_LOGIN_EMAIL_FULFILLED';
export const SEND_LOGIN_EMAIL_REJECTED = 'SEND_LOGIN_EMAIL_REJECTED';

export const SET_USER_PHONE = 'SET_USER_PHONE';

const sendSMS = (phone, options) => dispatch => {
  dispatch({
    type: SET_USER_PHONE,
    payload: phone,
  });

  return dispatch({
    type: SEND_LOGIN_SMS,
    payload: Login.sendSMS(phone, options),
    meta: { phone },
  });
};

const sendEmail = form => ({
  type: SEND_LOGIN_EMAIL,
  payload: Login.sendEmail(form),
});

export default { sendSMS, sendEmail };
