import { TextField } from '@mui/material';
import isEqual from 'lodash/isEqual';
import { func, object } from 'prop-types';
import React, { memo, useCallback } from 'react';

const FormikTextField = ({ field, bindChange, form, ...props }) => {
  const { name, value, onChange, onBlur } = field;
  const { submitCount } = form;
  let error = form.errors[name];
  if (error?.length === 1) {
    // Bug when a ListField has a global, this shows the first character of it
    error = null;
  }
  const handleBlur = useCallback(
    e => {
      onBlur(e);
      if (props.onBlur) {
        props.onBlur(e);
      }
    },
    [onBlur, props.onBlur],
  );
  const handleChange = useCallback(
    e => {
      bindChange(e);
      onChange(e);
    },
    [bindChange, onChange],
  );
  return (
    <TextField
      fullWidth
      {...props}
      onChange={handleChange}
      onBlur={handleBlur}
      value={value}
      name={name}
      error={!!(error && submitCount > 0)}
      helperText={submitCount > 0 && error}
    />
  );
};

FormikTextField.propTypes = {
  field: object.isRequired,
  form: object.isRequired,
  bindChange: func,
  onBlur: func,
};
FormikTextField.defaultProps = {
  bindChange: () => {},
  onBlur: undefined,
};

const areEqual = (
  { form: { submitCount, errors }, field: { name, value }, type, disabled, InputProps },
  {
    form: { submitCount: newSubmitCount, errors: nextErrors },
    field: { value: nextValue },
    type: nextType,
    disabled: nextDisabled,
    InputProps: nextInputProps,
  },
) => {
  return (
    submitCount === newSubmitCount &&
    value === nextValue &&
    errors[name] === nextErrors[name] &&
    type === nextType &&
    disabled === nextDisabled &&
    isEqual(InputProps, nextInputProps)
  );
};

export default memo(FormikTextField, areEqual);
