import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import form from 'store/app/ui/login/form/action';

const mapStateToProps = state => {
  return {
    form: state.login.form,
    locale: state.i18n.locale,
    content: state.login.form.content,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    form: bindActionCreators(form, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
