import Cookies from 'cookies-js';
import { persistReducer } from 'redux-persist';
import { CookieStorage } from 'redux-persist-cookie-storage';
import { APP_DOMAIN } from 'services/constants';
import { SET_KEEP_SESSION_OPEN, SET_RETURN_URL } from 'store/app/authConfig/action';
import ms from 'ms';

const setCookieOptions = {
  path: '/',
  domain: APP_DOMAIN.replace(/:.*$/, ''),
  secure: true,
  sameSite: 'Lax',
};

const persistConfig = {
  key: 'authConfig',
  storage: new CookieStorage(Cookies, {
    setCookieOptions,
    expiration: { default: ms('10m') },
  }),
};

const initialState = {
  returnUrl: null,
  keepSessionOpen: false,
};

const authConfigReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_RETURN_URL:
      return {
        ...state,
        returnUrl: payload,
      };

    case SET_KEEP_SESSION_OPEN:
      return {
        ...state,
        keepSessionOpen: !!payload,
      };

    default:
      return state;
  }
};

export default persistReducer(persistConfig, authConfigReducer);
