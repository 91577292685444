import adapter from '@flopflip/graphql-adapter';
import { ConfigureFlopFlip } from '@flopflip/react-redux';
import Loading from 'components/common/Loading';
import ms from 'ms';
import React from 'react';
import { useSelector } from 'react-redux';
import { BACKEND_URL } from 'services/constants';
import Cookie from 'services/Cookie';
import authCompany from 'store/selectors/authCompany';

// eslint-disable-next-line react/prop-types
const ConfigureFeatureToggles = ({ children }) => {
  const token = useSelector(state => state.auth?.token);
  const company = useSelector(authCompany);

  if (!token || !company) {
    return children;
  }

  const getRequestHeaders = () => {
    const headers = {
      authorization: `Bearer ${token.hash}`,
      'x-company': company?._id,
    };
    const impersonate = Cookie.get('impersonate');
    if (impersonate) {
      headers['x-impersonate'] = impersonate;
    }
    return headers;
  };
  return (
    <ConfigureFlopFlip
      adapter={adapter}
      adapterArgs={{
        uri: `${BACKEND_URL}/api/v1/graphql`,
        query: '{ flags { name value } }',
        getRequestHeaders,
        parseFlags: res =>
          res.flags.reduce((acc, { name, value }) => ({ ...acc, [name]: value }), {}),
        pollingInteralMs: ms('6 hours'),
      }}
    >
      {isAdapterConfigured => (isAdapterConfigured ? children : <Loading />)}
    </ConfigureFlopFlip>
  );
};
export default ConfigureFeatureToggles;
