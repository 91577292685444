import { number, shape, string } from 'prop-types';

export default shape({
  _id: string.isRequired,
  QRCode: string.isRequired,
  company_id: string,
  createdAt: number.isRequired,
  magicLink: string.isRequired,
  token: string.isRequired,
});
