import { connect } from 'react-redux';
import authCompany from 'store/selectors/authCompany';
import populateEmployees from 'store/selectors/populateEmployees';
import populateTimeTrackerLog from 'store/selectors/populateTimeTrackerLog';

const mapStateToProps = state => ({
  isCompanyLoaded: !!authCompany(state),
  adminsLoaded: !!authCompany(state),
  timeTrackerLog: populateTimeTrackerLog(state),
  employees: populateEmployees(state),
  locale: state.i18n.locale,
  clientSecret: state.companies.clientSecret,
});

const mapDispatchToProps = () => ({
  actions: {},
});

export default connect(mapStateToProps, mapDispatchToProps);
