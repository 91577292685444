import { makeStyles } from '@mui/styles';
import Tail from 'assets/images/tail.svg';
import { bool, node } from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: ({ right, isMandatory }) => {
    const rightBg =
      theme.palette.mode === 'light'
        ? theme.palette.primary.lighter
        : theme.palette.primary.ultraLight;
    const backgroundColor = right && !isMandatory ? rightBg : theme.palette.background.paper;
    if (isMandatory) {
      return {
        borderRadius: 20,
      };
    }
    return {
      borderRadius: 20,
      maxWidth: 550,
      boxShadow: '2px 2px 3px -1px rgba(2, 2, 3, 0.2)',
      position: 'relative',
      border: '2px solid white',
      backgroundColor,
      borderColor: backgroundColor,
    };
  },

  overflowContainer: {
    position: 'relative',
    borderRadius: 17,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    wordBreak: 'break-word',
  },

  tail: ({ right }) => {
    const rightBg =
      theme.palette.mode === 'light'
        ? theme.palette.primary.lighter
        : theme.palette.primary.ultraLight;
    return {
      zIndex: 11,
      width: '19px',
      color: right ? rightBg : theme.palette.background.paper,
      position: 'absolute',
      bottom: '-2px',
      left: '-9px',
      ...(right
        ? {
            left: 'auto',
            right: '-9px',
            transform: 'scaleX(-1)',
          }
        : {}),
    };
  },
}));

const BaseMessage = ({ right, attachments, isMandatory, children }) => {
  const classes = useStyles({ right, attachments, isMandatory });
  return (
    <div style={{ position: 'relative' }}>
      <div className={classes.root}>
        <div className={classes.overflowContainer}>{children}</div>
      </div>
      {!isMandatory && <Tail className={classes.tail} />}
    </div>
  );
};
BaseMessage.propTypes = {
  attachments: bool.isRequired,
  children: node.isRequired,
  right: bool.isRequired,
  isMandatory: bool.isRequired,
};

export default BaseMessage;
