import { Typography } from '@mui/material';
import shadows from '@mui/material/styles/shadows';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { array, func, string } from 'prop-types';
import React, { useRef } from 'react';
import { Mention, MentionsInput as MentionsInputOrig } from 'react-mentions';

const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: 72,
    padding: '6px 0 7px',
    paddingLeft: 15,
    position: 'relative',
    width: '100%',
  },
  suggestionsPortal: {
    position: 'absolute',
    minHeight: 100,
    minWidth: 200,
    backgroundColor: theme.white,
  },
  mentions: {
    position: 'relative',
    width: '100%',
    color: theme.typography.body1.color,
    cursor: 'text',
    fontSize: '0.8125rem',
    boxSizing: 'border-box',
    fontFamily: 'inherit',
    fontWeight: 400,
    lineHeight: '1.1876em',
  },
  mentions__input: {
    lineHeight: '1.3em',
    padding: 0,
    resize: 'none',
    font: 'inherit',
    color: 'currentColor',
    width: '100%',
    border: '1px solid transparent',
    height: '1.1876em',
    margin: 0,
    display: 'block',
    minWidth: 0,
    '-webkit-tap-highlight-color': 'transparent',
    '&:focus': {
      outline: 0,
    },
    '&::placeholder': {
      color: 'currentColor',
      opacity: 0.42,
      transition: 'opacity 200ms cubic-bezier(0.4,0,0.2,1) 0ms',
    },
  },
  mentions__suggestions: {
    padding: `${theme.spacing(1)} 0`,
    backgroundColor: theme.palette.white,
    borderRadius: 6,
    boxShadow: shadows[3],
  },
  mentions__highlighter: {
    zIndex: 1,
    pointerEvents: 'none',
    lineHeight: '1.3em',
  },
  mention: {
    color: theme.palette.mention,
    background: theme.palette.mentionBack,
    borderRadius: 3,
  },
  suggestionsPortalHost: {
    zIndex: 10,
    position: 'absolute',
  },
  suggestion: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px',
    background: theme.white,
  },
  focused: {
    background: theme.palette.primary.extraLight,
  },
  display: {
    marginLeft: 10,
  },
}));

const MentionsInput = ({ data, onChange, name, onAdd, ...args }) => {
  const suggestionsPortalHost = useRef();
  const inputRef = useRef();
  const classes = useStyles();
  const handleOnChange = (event, markup, text, mentions) => {
    if (typeof onChange === 'function') {
      // eslint-disable-next-line no-param-reassign
      event.target.name = name;
      // eslint-disable-next-line no-param-reassign
      event.target.value = text;
      onChange(event, markup, text, mentions);
    }
  };
  const renderUserSuggestion = (suggestion, query, highlightedDisplay, index, focused) => {
    return (
      <div className={classNames({ [classes.suggestion]: true, [classes.focused]: focused })}>
        <Typography className={classes.display}>{suggestion.display}</Typography>
      </div>
    );
  };
  const handleAdd = (id, display, ...rest) => {
    const startPos = inputRef.current.selectionStart;
    if (typeof onAdd === 'function') {
      onAdd(id, display, ...rest);
    }
    setTimeout(() => {
      const el = document.getElementsByName(name)?.[0];
      if (el) {
        el.focus();
        el.selectionStart = startPos + display.length + 1;
        el.selectionEnd = startPos + display.length + 1;
      }
    }, 10);
  };
  return (
    <div className={classes.root}>
      <div ref={suggestionsPortalHost} className={classes.suggestionsPortalHost} />
      <MentionsInputOrig
        name={name}
        onChange={handleOnChange}
        {...args}
        inputRef={inputRef}
        className="mentions"
        classNames={classes}
        suggestionsPortalHost={suggestionsPortalHost.current}
      >
        <Mention
          appendSpaceOnAdd
          trigger="@"
          data={data}
          classNames={classes}
          className="mention"
          displayTransform={(id, display) => `@${display}`}
          renderSuggestion={renderUserSuggestion}
          onAdd={handleAdd}
        />
      </MentionsInputOrig>
    </div>
  );
};

MentionsInput.propTypes = {
  name: string.isRequired,
  data: array.isRequired,
  onChange: func,
  onAdd: func,
};

MentionsInput.defaultProps = {
  onChange: undefined,
  onAdd: undefined,
};

export default MentionsInput;
