import React from 'react';
import { object, node } from 'prop-types';
import withStyles from '@mui/styles/withStyles';

const styles = {
  root: {
    minHeight: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

const Actions = ({ classes, children }) => <div className={classes.root}>{children}</div>;
Actions.propTypes = {
  classes: object.isRequired,
  children: node.isRequired,
};

export default withStyles(styles)(Actions);
