import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Popover,
  Tab,
  Tabs,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import EmployeeAvatar from 'components/@home/EmployeeAvatar';
import ReactionIcon from 'components/common/ReactionIcon';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import Message from 'services/api/Message';
import messagesActions from 'store/app/entities/messages/action';
import authUserId from 'store/selectors/authUserId';
import { I18n } from 'utils/i18n';

const useStyles = makeStyles(theme => ({
  paper: {
    maxWidth: 300,
    minWidth: 250,
    position: 'relative',
    overflow: 'visible',
    backgroundColor: 'transparent',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: -4,
      left: '50%',
      transform: 'translateX(-50%) rotate(45deg)',
      width: 8,
      height: 8,
      border: `1px solid ${theme.palette.divider}`,
      borderTop: 'none',
      borderLeft: 'none',
      backgroundColor: 'transparent',
    },
  },
  container: {
    backdropFilter: 'blur(16px)',
    borderColor: theme.palette.divider,
    borderWidth: 1,
    borderStyle: 'solid',
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(26, 28, 31, 0.7)' : 'rgba(248, 249, 253, 0.4)',
    borderRadius: 8,
    clipPath:
      'polygon(0 0, 100% 0, 100% 100%, calc(50% + 5px) 100%, calc(50% + 5px) calc(100% - 1px), calc(50% - 5px) calc(100% - 1px), calc(50% - 5px) 100%, 0 100%)',
  },
  usersList: {
    padding: theme.spacing(1),
  },
  userItem: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  avatar: {
    minWidth: 40,
  },
  userInfo: {
    flex: 1,
  },
  userName: {
    fontSize: '0.95rem',
    fontWeight: 500,
  },
  userSubtext: {
    fontSize: '0.85rem',
    color: theme.palette.text.secondary,
  },
  reactionIcon: {
    width: 20,
    height: 20,
  },
  removeText: {
    fontSize: '0.85rem',
    color: theme.palette.text.secondary,
  },
  list: {
    padding: theme.spacing(0.5),
    maxHeight: 'calc(100vh - 100px)',
    overflowY: 'auto',
  },
  listItem: {
    padding: 0,
  },
  listItemButton: {
    padding: `${theme.spacing(0.25)} ${theme.spacing(0.5)}`,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  listItemText: {
    margin: 0,
    padding: `${theme.spacing(0.25)} 0`,
    minHeight: 0,
    '& .MuiTypography-primary': {
      fontSize: '0.95rem',
    },
    '& .MuiTypography-secondary': {
      fontSize: '0.85rem',
      color: theme.palette.text.secondary,
    },
  },
  reactionContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  tab: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
}));

const ReactionDetails = ({ messageId, anchorRef, isOpen, close }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [reactions, setReactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState('all');
  const [usersMap, setUsersMap] = useState({});
  const currentUserId = useSelector(authUserId);

  const handleRemoveReaction = useCallback(
    async reaction => {
      try {
        await dispatch(messagesActions.removeReaction(messageId, reaction));

        // Refresh reactions after removing
        const { reactions: messageReactions, users } = await Message.getReactions(messageId);
        setReactions(messageReactions);
        setUsersMap(users);
      } catch (error) {
        console.error('Failed to remove reaction:', error);
      }
    },
    [messageId, dispatch],
  );

  useEffect(() => {
    const loadReactions = async () => {
      if (!isOpen) return;
      try {
        const { reactions: messageReactions, users } = await Message.getReactions(messageId);
        setReactions(messageReactions);
        setUsersMap(users);
      } finally {
        setIsLoading(false);
      }
    };

    loadReactions();
  }, [messageId, isOpen]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const totalReactions = useMemo(
    () => reactions.reduce((sum, r) => sum + r.users.length, 0),
    [reactions],
  );

  const filteredUsers = useMemo(
    () =>
      selectedTab === 'all'
        ? reactions.flatMap(r =>
            r.users.map(userId => ({
              id: userId,
              reaction: r.name,
              ...usersMap[userId],
            })),
          )
        : reactions
            .find(r => r.name === selectedTab)
            ?.users.map(userId => ({
              id: userId,
              ...usersMap[userId],
            })) || [],
    [selectedTab, reactions, usersMap],
  );

  return (
    <Popover
      open={isOpen && !isLoading}
      anchorEl={anchorRef.current}
      onClose={close}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      slotProps={{
        paper: {
          className: classes.paper,
          elevation: 0,
          style: { transform: 'translateY(-4px)' },
        },
      }}
      style={{ top: '-4px' }}
    >
      <Box className={classes.container}>
        <Box className={classes.content}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            className={classes.tabs}
            TabIndicatorProps={{ style: { height: 2 } }}
          >
            <Tab
              value="all"
              label={
                <span className={classes.tab}>
                  <Translate value="ReactionDetails.All" />
                  <span className={classes.tabCount}>{totalReactions}</span>
                </span>
              }
            />
            {reactions.map(r => (
              <Tab
                key={r.name}
                value={r.name}
                label={
                  <span className={classes.tab}>
                    <ReactionIcon name={r.name} size={16} />
                    <span className={classes.tabCount}>{r.users.length}</span>
                  </span>
                }
              />
            ))}
          </Tabs>
          <List className={classes.list}>
            {isLoading ? (
              <Box display="flex" justifyContent="center" alignItems="center" height={100}>
                <CircularProgress size={24} />
              </Box>
            ) : (
              filteredUsers.map(user => (
                <ListItem
                  key={`${user.id}-${user.reaction || ''}`}
                  className={classes.listItem}
                  disablePadding
                >
                  <ListItemButton
                    className={classes.listItemButton}
                    onClick={() =>
                      user.id === currentUserId ? handleRemoveReaction(user.reaction) : null
                    }
                  >
                    <ListItemAvatar classes={{ root: classes.avatar }}>
                      <EmployeeAvatar employee={user} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={user.name}
                      secondary={
                        user.id === currentUserId ? I18n.t('ReactionDetails.ClickToRemove') : null
                      }
                      className={classes.listItemText}
                    />
                    <ListItemSecondaryAction>
                      <ReactionIcon
                        name={selectedTab === 'all' ? user.reaction : selectedTab}
                        className={classes.reactionIcon}
                      />
                    </ListItemSecondaryAction>
                  </ListItemButton>
                </ListItem>
              ))
            )}
          </List>
        </Box>
      </Box>
    </Popover>
  );
};

ReactionDetails.propTypes = {
  messageId: PropTypes.string.isRequired,
  anchorRef: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default ReactionDetails;
