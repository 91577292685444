import { CLOSE_TEAM_DRAWER, OPEN_TEAM_DRAWER } from './action';
import employeesTableReducer from './employeesTable/reducer';

const initialState = {
  isOpen: false,
  team: {},
  employeesTable: undefined,
};

const channelDrawer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_TEAM_DRAWER:
      return {
        ...state,
        isOpen: true,
        team: action.payload || {},
      };

    case CLOSE_TEAM_DRAWER:
      return { ...state, isOpen: false };

    default: {
      const employeesTable = employeesTableReducer(state.employeesTable, action);
      return {
        ...state,
        employeesTable,
      };
    }
  }
};

export default channelDrawer;
