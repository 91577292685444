import { useState } from 'react';
import Message from 'services/api/Message';

const useSendAction = (element, msgId, blockId, onResponseOk) => {
  const [sending, setSending] = useState(false);

  const send = async (value = element.value) => {
    const { actionId } = element;
    setSending(true);
    try {
      const response = await Message.messageAction(msgId, blockId, actionId, {
        value,
      });
      if (response?.ok && response.data?.data?.message) {
        onResponseOk(response.data.data.message);
      }
      if (response?.status === true && response.data.openUrl) {
        window.open(response.data.openUrl, response.data.target);
      }
    } finally {
      setSending(false);
    }
  };

  return [sending, send];
};

export default useSendAction;
