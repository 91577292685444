import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
} from '@mui/material';
import React from 'react';
import { I18n } from 'utils/i18n';

const ConfirmRecordingDialog = ({ isOpen, close, onSubmit }) => {
  const [distort, setDistort] = React.useState(false);

  const handleSubmit = e => {
    e.stopPropagation();
    e.preventDefault();
    onSubmit({ distort });
  };

  const onCheckboxChange = () => {
    setDistort(d => !d);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      aria-labelledby="confirm-recording-title"
      aria-describedby="confirm-recording-description"
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id="confirm-recording-title">
          {I18n.t('ConfirmRecordingDialog.confirmAudioRecording')}
        </DialogTitle>
        <DialogContent>
          <FormControlLabel
            control={
              <Checkbox name="distort" checked={distort} onChange={onCheckboxChange} value="on" />
            }
            label={I18n.t('ConfirmRecordingDialog.distortAudio')}
          />
          <DialogContentText id="confirm-recording-description">
            {I18n.t('ConfirmRecordingDialog.confirmAudioRecordingDescription')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={close}
            variant="outlined"
            color="secondary"
            sx={{ color: 'primary.ultraDark' }}
          >
            {I18n.t('ConfirmRecordingDialog.cancel')}
          </Button>
          <Button type="submit" variant="contained" color="primary">
            {I18n.t('ConfirmRecordingDialog.confirm')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ConfirmRecordingDialog;
