import { SvgIcon, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FileBot from 'assets/file-bot.svg';
import { bool } from 'prop-types';
import React from 'react';
import { I18n } from 'utils/i18n';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: theme.spacing(3),
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '50%',
  },
  icon: {
    fontSize: 96,
    color: theme.palette.greyBlue,
  },
  title: {
    margin: '20px 0 20px 0',
    whiteSpace: 'pre-line',
  },
  infoBox: {
    background: theme.palette.secondary.ultraUltraLight,
    border: `1px solid ${theme.palette.secondary.extraLight}`,
    borderRadius: 6,
    padding: theme.spacing(4),
    '& ul': {
      padding: '0 0 0 40px',
      listStylePosition: 'outside',
      marginBottom: 0,
      color: theme.palette.secondary.light,
    },
  },
  characteristic: {
    color: theme.palette.black,
    verticalAlign: 'middle',
  },
  spaceAbove: {
    marginTop: theme.spacing(3),
  },
}));

const TemplateListHelp = ({ isEmpty }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.column}>
        <SvgIcon className={classes.icon}>
          <FileBot />
        </SvgIcon>
        <Typography variant="h5" component="h1" className={classes.title}>
          {I18n.t('HrBot.Hello human')}
        </Typography>
      </div>
      <div className={classes.column}>
        {!isEmpty ? (
          <div className={classes.infoBox}>
            <Typography>
              <strong>{I18n.t('HrBot.infoBox.sendTitle')}</strong>
            </Typography>
            <ul>
              {[1, 2, 3, 4].map(char => (
                <li key={`char.${char}`}>
                  <Typography display="inline" className={classes.characteristic}>
                    {I18n.t(`HrBot.infoBox.sendItems.${char}`)}
                  </Typography>
                </li>
              ))}
            </ul>
            <Typography className={classes.spaceAbove}>
              <strong>{I18n.t('HrBot.infoBox.newTemplateTitle')}</strong>
            </Typography>
            <ul>
              {[1, 2, 3, 4].map(char => (
                <li key={`char.${char}`}>
                  <Typography display="inline" className={classes.characteristic}>
                    {I18n.t(`HrBot.infoBox.newTemplateItems.${char}`)}
                  </Typography>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className={classes.infoBox}>
            <Typography>
              <strong>{I18n.t('HrBot.infoBoxEmpty.title')}</strong>
            </Typography>
            <ul>
              {[1, 2, 3].map(char => (
                <li key={`char.${char}`}>
                  <Typography display="inline" className={classes.characteristic}>
                    {I18n.t(`HrBot.infoBoxEmpty.characteristics.${char}`)}
                  </Typography>
                </li>
              ))}
            </ul>
            <Typography className={classes.spaceAbove}>
              {I18n.t('HrBot.infoBoxEmpty.footer')}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

TemplateListHelp.propTypes = {
  isEmpty: bool,
};

TemplateListHelp.defaultProps = {
  isEmpty: false,
};

export default TemplateListHelp;
