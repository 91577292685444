import withStyles from '@mui/styles/withStyles';
import { object } from 'prop-types';
import React from 'react';
import { QRCode } from 'react-qr-svg';

const styles = {
  root: {},
  qr: {
    padding: 5,
    width: 120,
    height: 120,
  },
};

const InviteQRCode = ({ classes, employee: { magicLink } }) => (
  <div className={classes.root}>
    {magicLink && <QRCode value={magicLink} size={120} level="H" className={classes.qr} />}
  </div>
);
InviteQRCode.propTypes = {
  employee: object,
  classes: object.isRequired,
};
InviteQRCode.defaultProps = {
  employee: {},
};

export default withStyles(styles)(InviteQRCode);
