import { TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { SendDrawerContext } from 'components/@home/@documents/SentDocuments/SendDrawerContext';
import MessageGroup from 'components/@home/@messages/ChatPanel/MessagesList/MessageGroup';
import Label from 'components/@home/drawers/AppDrawer/Form/Label';
import { getFileName } from 'components/@home/drawers/HrBotDrawer/pdf-utils';
import IphonePreview from 'components/common/IphonePreview';
import { array, func, object, string } from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'utils/i18n';
import { HR_BOT_APP_ID } from 'services/constants';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 40,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-around',
  },
  input: {
    width: 290,
  },
  label: {
    textTransform: 'capitalize',
  },
  chat: {
    zoom: 0.73,
    flexDirection: 'column',
    padding: 10,
    flexGrow: 1,
    backgroundColor: theme.palette.secondary.ultraLight,
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: 14,
  },
}));

const StepMessage = ({
  setError,
  setNextEnabled,
  userItems,
  setUserItems,
  message,
  setMessage,
  files,
}) => {
  const classes = useStyles();
  const { file: format } = useContext(SendDrawerContext);
  const app = useSelector(state => state.apps.apps?.[HR_BOT_APP_ID]);

  const file = files?.find(f => !f.error && f.employee?.[format.sendBy]) || { file: {} };
  const {
    file: { size },
  } = file;
  const name = getFileName({ ...file, ...userItems }, format);
  const formatUserItems = format.items.filter(i => i.selector === 'user');
  useEffect(() => {
    setNextEnabled(!!message && formatUserItems.every(i => userItems[i.name]));
    setError(null);
  }, [message, userItems]);
  return (
    <div className={classes.root}>
      <div className={classes.form}>
        <div>
          <Label>{I18n.t('HrBot.Message')}</Label>
          <TextField
            className={classes.input}
            multiline
            rows={20}
            value={message}
            onChange={e => setMessage(e.target.value)}
          />
          {formatUserItems.map(item => (
            <>
              <Label className={classes.label}>{I18n.t(`HrBot.fields.${item.name}`)}</Label>
              <TextField
                key={item.name}
                className={classes.input}
                rows={20}
                value={userItems[item.name]}
                onChange={e => {
                  const val = e.target?.value || '';
                  setUserItems(ui => {
                    return {
                      ...ui,
                      [item.name]: val,
                    };
                  });
                }}
              />
            </>
          ))}
        </div>
        <IphonePreview>
          <div className={classes.chat}>
            {file && (
              <MessageGroup
                msgRef={() => {}}
                message={{
                  text: message,
                  attachments: [
                    {
                      name,
                      httpLink: name,
                      mimeType: 'application/pdf',
                      type: 'doc',
                      size,
                    },
                  ],
                  employee: {
                    avatar: app.avatar,
                    firstName: app.name,
                  },
                }}
              />
            )}
          </div>
        </IphonePreview>
      </div>
    </div>
  );
};

StepMessage.propTypes = {
  setError: func.isRequired,
  setNextEnabled: func.isRequired,
  userItems: object.isRequired,
  setUserItems: func.isRequired,
  message: string.isRequired,
  files: array.isRequired,
  setMessage: func.isRequired,
};

export default StepMessage;
