import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import createModal from 'store/app/ui/modals/acknowledgment/create/action';
import previewModal from 'store/app/ui/modals/acknowledgment/preview/action';
import authCompanyId from 'store/selectors/authCompanyId';
import currentChannel from 'store/selectors/currentChannel';

const mapStateToProps = state => ({
  isOpen: state.modals.acknowledgment.create.isOpen,
  channel: currentChannel(state),
  companyId: authCompanyId(state),
});

const mapDispatchToProps = dispatch => ({
  actions: {
    acknowledgmentCreateModal: bindActionCreators(createModal, dispatch),
    acknowledgmentPreviewModal: bindActionCreators(previewModal, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
