import Config from 'services/api/Config';

export const LOAD_COUNTRY_CODES = 'LOAD_COUNTRY_CODES';
export const LOAD_COUNTRY_CODES_PENDING = 'LOAD_COUNTRY_CODES_PENDING';
export const LOAD_COUNTRY_CODES_FULFILLED = 'LOAD_COUNTRY_CODES_FULFILLED';
export const LOAD_COUNTRY_CODES_REJECTED = 'LOAD_COUNTRY_CODES_REJECTED';

const load = language => ({
  type: LOAD_COUNTRY_CODES,
  payload: Config.countryCodes(language),
});

export default { load };
