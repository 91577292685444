import Loading from 'components/common/Loading';
import React, { Suspense } from 'react';

const PersonReport = React.lazy(() => import('./index'));

const PersonReportLazy = props => (
  <Suspense fallback={<Loading size={60} />}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <PersonReport {...props} />
  </Suspense>
);
export default PersonReportLazy;
