export const OPEN_DOCUMENT_MODAL = 'OPEN_DOCUMENT_MODAL';
export const CLOSE_DOCUMENT_MODAL = 'CLOSE_DOCUMENT_MODAL';

const open = (src, messageId, messageText, isMandatory, mimeType) => ({
  type: OPEN_DOCUMENT_MODAL,
  payload: {
    src,
    messageId,
    messageText,
    isMandatory,
    mimeType,
  },
});

const close = () => ({
  type: CLOSE_DOCUMENT_MODAL,
});

export default {
  open,
  close,
};
