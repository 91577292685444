import { createSelector } from 'reselect';
import authCompany from './authCompany';
import currentChannel from './currentChannel';

const currentChannelEmployee = (channel, company) => {
  const employee = company.employees?.find(e => e.user?._id === channel?._id);

  return employee;
};

export default createSelector(
  state => currentChannel(state),
  state => authCompany(state),
  currentChannelEmployee,
);
