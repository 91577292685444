import Http from 'services/Http';

class User {
  async updatePassword(password, user, newPassword, loginType) {
    const params = {
      password,
      loginType,
    };
    if (loginType === 'password') {
      params.newPassword = newPassword;
    }
    const { data } = await Http.patch(`/v1/user/${user}/updatePassword`, params);
    return data;
  }
}

export default new User();
