import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import videoModal from 'store/app/ui/modals/video/action';

const mapStateToProps = state => ({
  src: state.modals.video.src,
  isOpen: state.modals.video.isOpen,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    videoModal: bindActionCreators(videoModal, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
