import { Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ConfirmDialog from 'components/common/ConfirmDialog';
import { func, object, string } from 'prop-types';
import React, { useState } from 'react';
import { I18n } from 'utils/i18n';
import connector from './connector';

const useStyles = makeStyles(() => ({
  root: {
    minWidth: '110px',
    margin: 'auto',
    transform: 'translateX(-50%)',
  },
}));

const IUnderstoodButton = ({ actions, onClose, messageId }) => {
  const [isOpen, setOpen] = useState(false);
  const confirmAcknowledge = () => {
    actions.messages.confirmAcknowledge(messageId);
    onClose();
  };

  const confirmDialog = () => {
    setOpen(true);
  };

  const onCloseConfirmDialog = () => {
    setOpen(false);
  };

  const classes = useStyles();

  return (
    <>
      <Button variant="contained" color="primary" onClick={confirmDialog} className={classes.root}>
        {I18n.t('AcknowledgementReadModal.I read and understood')}
      </Button>
      <ConfirmDialog
        message={I18n.t('AcknowledgementReadModal.Alert_Title')}
        buttons={[
          {
            text: I18n.t('AcknowledgementReadModal.Alert_Confirm_Btn'),
            onClick: confirmAcknowledge,
          },
          { text: I18n.t('AcknowledgementReadModal.Alert_Cancel_Btn') },
        ]}
        isOpen={isOpen}
        onClose={onCloseConfirmDialog}
      >
        <Typography>{I18n.t('AcknowledgementReadModal.Alert_Message')}</Typography>
      </ConfirmDialog>
    </>
  );
};

IUnderstoodButton.propTypes = {
  messageId: string.isRequired,
  actions: object.isRequired,
  onClose: func.isRequired,
};

export default connector(IUnderstoodButton);
