/** @flow */
import SecretFieldControl from 'components/controls/SecretField';
import TextField from 'components/formik/TextField';
import React from 'react';

const SecretField = ({ ...props }: { field: Object }) => (
  <SecretFieldControl
    name={props.field.name}
    value={props.field.value}
    {...props}
    input={TextField}
  />
);

export default SecretField;
