/**
 * @flow
 */
import {
  Box,
  Button,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Stack,
  Typography,
} from '@mui/material';
import Modal from 'components/@home/modals/Modal';
import levels from 'components/@whistleblower-admin/levels';
import FormikPermissions from 'components/@whistleblower-admin/pages/Users/FormikPermissions';
import FormikAutosuggest from 'components/formik/Autosuggest';
import { Field, Formik } from 'formik';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'utils/i18n';
import { usePatchUserMutation, usePostUserMutation } from 'store/app/entities/WbSlice';
import { populateAdminEmployees } from 'store/selectors/populateEmployees';

const CONFLICT = 409;

type Props = {
  isOpen: boolean;
  close: Function;
  user: Object;
};

const WbUserDialog = ({ isOpen, close, user }: Props) => {
  const isEdit = !!user;
  const [create] = usePostUserMutation();
  const [patch] = usePatchUserMutation();
  const employees = useSelector(populateAdminEmployees);
  const employeeSuggestions = Object.values(employees)
    .filter(e => !e.user?.isApp)
    .map(employee => ({
      label: employee.name,
      value: employee._id,
    }));
  return (
    <Modal isOpen={isOpen} onClose={close} aria-labelledby="wb-user-dialog-title">
      <Box sx={{ minWidth: 600, maxWidth: '90vh' }}>
        <Typography
          id="wb-user-dialog-title"
          component="h2"
          level="inherit"
          fontSize="1.25em"
          mb="0.25em"
        >
          {I18n.t(`WhistleblowerPortalConfig.WbUserDialog.Title${isEdit ? 'Edit' : 'Add'}`, {
            name: user?.employee?.name || '',
          })}
        </Typography>
        {!!isOpen && (
          <Formik
            initialValues={{
              employee: isEdit ? user.employee : '',
              permissions: isEdit
                ? user.permissions
                : {
                    portals: levels.none,
                    occurrences: levels.writer,
                    users: levels.none,
                  },
            }}
            validate={values => {
              let errors = {};
              if (!values.employee) {
                errors = {
                  ...errors,
                  user: I18n.t('WhistleblowerPortalConfig.errors.Required', {
                    field: I18n.t('WhistleblowerPortalConfig.atts.user'),
                  }),
                };
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting, setFieldError }) => {
              const { error } = isEdit
                ? await patch({ id: user._id, permissions: values.permissions })
                : await create(values);
              setSubmitting(false);
              if (error) {
                if (error.status === CONFLICT) {
                  error.data?.key?.forEach(field => {
                    setFieldError(
                      field,
                      I18n.t('WhistleblowerPortalConfig.errors.Duplicate', {
                        field: I18n.t(`WhistleblowerPortalConfig.atts.${field}`).toLowerCase(),
                      }),
                    );
                  });
                }
              } else {
                close();
              }
            }}
          >
            {({ errors, handleSubmit, isSubmitting, submitCount }) => (
              <form onSubmit={handleSubmit}>
                <Stack spacing={1} sx={{ mt: 4, mb: 2 }}>
                  {!isEdit && (
                    <FormControlLabel
                      sx={{ alignItems: 'flex-start', m: 0 }}
                      labelPlacement="top"
                      control={
                        <>
                          <FormHelperText error>
                            {errors?.user && submitCount > 0 ? errors.user : ' '}
                          </FormHelperText>
                          <Field
                            component={FormikAutosuggest}
                            suggestions={employeeSuggestions}
                            name="employee"
                          />
                        </>
                      }
                      label={
                        <Typography sx={{ fontWeight: 500 }}>
                          {I18n.t('WhistleblowerPortalConfig.atts.user')}
                        </Typography>
                      }
                    />
                  )}

                  <FormLabel sx={{ fontWeight: 500 }}>
                    {I18n.t('WhistleblowerPortalConfig.atts.permissions')}
                  </FormLabel>
                  <Field component={FormikPermissions} name="permissions" />

                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                    <Button type="button" onClick={close} variant="outlined" color="secondary">
                      {I18n.t('WhistleblowerPortalConfig.WbUserDialog.Cancel')}
                    </Button>
                    <Button disabled={isSubmitting} type="submit" variant="contained">
                      {I18n.t(
                        `WhistleblowerPortalConfig.WbUserDialog.${isEdit}` ? 'Edit' : 'Create',
                      )}
                    </Button>
                  </Box>
                </Stack>
              </form>
            )}
          </Formik>
        )}
      </Box>
    </Modal>
  );
};

export default WbUserDialog;
