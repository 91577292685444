import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { object, string } from 'prop-types';

const styles = theme => ({
  root: {
    height: '38px',
    marginTop: '1px',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSize: '28px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '38px',
    letterSpacing: '-0.7px',
    color: theme.palette.primary.ultraDark,
  },
});

const Headline = ({ classes, title }) => (
<div className={classes.root}>
    {title}
  </div>
);Headline.propTypes = {
  classes: object.isRequired,
  title: string.isRequired,
};

export default withStyles(styles)(Headline);
