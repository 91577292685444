export const OPEN_EMPLOYEE_LIST_DRAWER = 'OPEN_EMPLOYEE_LIST_DRAWER';
export const CLOSE_EMPLOYEE_LIST_DRAWER = 'CLOSE_EMPLOYEE_LIST_DRAWER';

const open = (title, message) => ({
  type: OPEN_EMPLOYEE_LIST_DRAWER,
  message,
  title,
});

const close = () => ({
  type: CLOSE_EMPLOYEE_LIST_DRAWER,
});

export default { open, close };
