/* eslint-disable react/jsx-props-no-spreading */
import { FormControl, FormHelperText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AttachmentPreview from 'components/@home/drawers/FillFormDrawer/AttachmentPreview';
import DropzoneBox from 'components/common/DropzoneBox';
import { useFormikContext } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'utils/i18n';
import Attachments from 'services/api/Attachments';
import authCompanyId from 'store/selectors/authCompanyId';

const useStyles = makeStyles(theme => ({
  dropzoneWrapper: {
    backgroundColor: theme.palette.primary.ultraLight,
    borderRadius: 4,
    width: '100%',
  },
}));

const QuestionFormUpload = ({ question, accept }: { question: Object; accept?: string }) => {
  const formik = useFormikContext();
  const companyId = useSelector(authCompanyId);
  const {
    values: { channel },
    errors,
    submitCount,
    getFieldHelpers,
    getFieldProps,
  } = formik;
  const name = `answers.${question._id}`;
  const helpers = getFieldHelpers(name);
  const { value } = getFieldProps(name);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [attachment, setAttachment] = useState(value);
  const classes = useStyles();

  const upload = async file => {
    try {
      const att = await Attachments.upload(companyId, channel._id, file);
      helpers.setValue(att._id);
      setAttachment(att);
    } catch (err) {
      console.error(err);
      helpers.setError(I18n.t('FillFormDrawer.Error uploading file'));
      setError(true);
    }
  };

  const onDrop = async files => {
    if (isEmpty(files)) return;
    setLoading(true);
    await upload(files[0]);
    setLoading(false);
  };
  return (
    <FormControl error={!!(errors[name] && submitCount > 0)} fullWidth>
      {attachment ? (
        <AttachmentPreview attachment={attachment} />
      ) : (
        <div className={classes.dropzoneWrapper}>
          <DropzoneBox accept={accept} onDrop={onDrop} error={error} loading={loading} />
        </div>
      )}

      <FormHelperText>{submitCount > 0 && errors[name]}</FormHelperText>
    </FormControl>
  );
};

QuestionFormUpload.defaultProps = {
  accept: [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/gif',
    'audio/mp3',
    'audio/mpeg',
    'audio/aac',
    'audio/ogg',
    'audio/m4a',
    'video/mp4',
    'video/avi',
    'video/mpeg',
    'application/pdf',
    'application/msword',
    'application/vnd.ms-excel',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/wps-office.docx',
  ].join(', '),
};

export default QuestionFormUpload;
