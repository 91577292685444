import React from 'react';
import { I18n } from 'utils/i18n';
import { func, string, bool, shape } from 'prop-types';
import Cell from './Cell';

const TitleCells = ({ onSort, order }) => {
  const cells = [
    { key: 'name', disablePadding: false, label: I18n.t('TeamDrawer.EmployeesTable.Name') },
    {
      key: 'department',
      disablePadding: false,
      label: I18n.t('TeamDrawer.EmployeesTable.Department'),
    },
    {
      key: 'jobTitle',
      disablePadding: false,
      label: I18n.t('TeamDrawer.EmployeesTable.Job Position'),
    },
    {
      key: 'location',
      disablePadding: false,
      label: I18n.t('TeamDrawer.EmployeesTable.Location'),
    },
    {
      key: 'role',
      disablePadding: false,
      label: I18n.t('TeamDrawer.EmployeesTable.Role'),
    },
  ];

  return cells.map(cell => (
    <Cell key={cell.key} name={cell.key} order={order} onSort={onSort} label={cell.label} />
  ));
};

TitleCells.propTypes = {
  onSort: func.isRequired,
  order: shape({
    by: string,
    isReverse: bool,
  }).isRequired,
};

export default TitleCells;
