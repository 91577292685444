/* eslint-disable no-underscore-dangle */
import Company from 'services/api/Company';
import { invalidateEmployeesTags } from 'store/app/entities/EmployeesApiSlice';
import authCompany from 'store/selectors/authCompany';

export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const UPDATE_COMPANY_FULFILLED = 'UPDATE_COMPANY_FULFILLED';

export const SET_COMPANY = 'SET_COMPANY';
export const REMOVE_COMPANY = 'REMOVE_COMPANY';

export const ADD_COMPANY_TAG = 'ADD_COMPANY_TAG';
export const ADD_COMPANY_TAG_PENDING = 'ADD_COMPANY_TAG_PENDING';
export const ADD_COMPANY_TAG_FULFILLED = 'ADD_COMPANY_TAG_FULFILLED';

export const ACCEPT_COMPANY_TERMS = 'ACCEPT_COMPANY_TERMS';
export const ACCEPT_COMPANY_TERMS_PENDING = 'ACCEPT_COMPANY_TERMS_PENDING';
export const ACCEPT_COMPANY_TERMS_FULFILLED = 'ACCEPT_COMPANY_TERMS_FULFILLED';
export const ACCEPT_COMPANY_TERMS_REJECTED = 'ACCEPT_COMPANY_TERMS_REJECTED';

export const TOGGLE_ACCEPT_COMPANY_TERMS = 'TOGGLE_ACCEPT_COMPANY_TERMS';
export const TOGGLE_ACCEPT_COMPANY_TERMS_PENDING = 'TOGGLE_ACCEPT_COMPANY_TERMS_PENDING';
export const TOGGLE_ACCEPT_COMPANY_TERMS_FULFILLED = 'TOGGLE_ACCEPT_COMPANY_TERMS_FULFILLED';
export const TOGGLE_ACCEPT_COMPANY_TERMS_REJECTED = 'TOGGLE_ACCEPT_COMPANY_TERMS_REJECTED';

export const LIST_EMPLOYEES = 'LIST_EMPLOYEES';
export const LIST_EMPLOYEES_PENDING = 'LIST_EMPLOYEES_PENDING';
export const LIST_EMPLOYEES_FULFILLED = 'LIST_EMPLOYEES_FULFILLED';
export const LIST_EMPLOYEES_REJECTED = 'LIST_EMPLOYEES_REJECTED';

export const CREATE_PAYMENT_INTENT = 'CREATE_PAYMENT_INTENT';
export const CREATE_PAYMENT_INTENT_PENDING = 'CREATE_PAYMENT_INTENT_PENDING';
export const CREATE_PAYMENT_INTENT_FULFILLED = 'CREATE_PAYMENT_INTENT_FULFILLED';
export const CREATE_PAYMENT_INTENT_REJECTED = 'CREATE_PAYMENT_INTENT_REJECTED';

export const LOAD_ONBOARDING = 'LOAD_ONBOARDING';
export const LOAD_ONBOARDING_PENDING = 'LOAD_ONBOARDING_PENDING';
export const LOAD_ONBOARDING_FULFILLED = 'LOAD_ONBOARDING_FULFILLED';
export const LOAD_ONBOARDING_REJECTED = 'LOAD_ONBOARDING_REJECTED';

const set = company => ({
  type: SET_COMPANY,
  payload: company,
});

const remove = companyId => ({
  type: REMOVE_COMPANY,
  payload: companyId,
});

const addTag = tag => (dispatch, getState) => {
  const company = authCompany(getState());

  return dispatch({
    type: ADD_COMPANY_TAG,
    payload: Company.addTag(company._id, tag),
    meta: { company_id: company._id, tag },
  });
};

const update = values => (dispatch, getState) => {
  const company = authCompany(getState());
  return dispatch({
    type: UPDATE_COMPANY,
    payload: Company.update(company._id, values),
    meta: { company_id: company._id },
  });
};

const acceptTerms = () => (dispatch, getState) => {
  const company = authCompany(getState());

  return dispatch({
    type: ACCEPT_COMPANY_TERMS,
    payload: Company.acceptTerms(company._id),
    meta: { company_id: company._id },
  });
};

const toggleAcceptTerms = (accepted, employeeId) => (dispatch, getState) => {
  const company = authCompany(getState());
  return dispatch({
    type: TOGGLE_ACCEPT_COMPANY_TERMS,
    payload: Company.toggleAcceptTerms(company._id, accepted, employeeId),
    meta: { company_id: company._id, accepted, employeeId },
  }).then(() => {
    dispatch(invalidateEmployeesTags([{ type: 'Employee', _id: employeeId }]));
  });
};

const listEmployees = options => (dispatch, getState) => {
  const company = authCompany(getState());
  return dispatch({
    type: LIST_EMPLOYEES,
    payload: Company.employees(company._id, options),
    meta: { companyId: company._id },
  });
};

const createPaymentIntent = () => (dispatch, getState) => {
  const company = authCompany(getState());
  return dispatch({
    type: CREATE_PAYMENT_INTENT,
    payload: Company.createPaymentInent(company._id),
    meta: { companyId: company._id },
  });
};

const loadOnboarding = () => dispatch => {
  return dispatch({
    type: LOAD_ONBOARDING,
    payload: Company.onboarding(),
  });
};

export default {
  acceptTerms,
  addTag,
  createPaymentIntent,
  listEmployees,
  loadOnboarding,
  remove,
  set,
  toggleAcceptTerms,
  update,
};
