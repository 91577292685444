export const OPEN_MEDIA_DRAWER = 'OPEN_MEDIA_DRAWER';
export const CHANGE_MEDIA_DRAWER = 'CHANGE_MEDIA_DRAWER';
export const CLOSE_MEDIA_DRAWER = 'CLOSE_MEDIA_DRAWER';

const open = ({ type }) => ({
  type: OPEN_MEDIA_DRAWER,
  payload: type,
});

const close = () => ({
  type: CLOSE_MEDIA_DRAWER,
});

const change = ({ type }) => ({
  type: CHANGE_MEDIA_DRAWER,
  payload: type,
});

export default { open, change, close };
