import { Box } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useTheme } from '@mui/styles';
import { DataGrid, getDefaultGridFilterModel, useGridApiContext } from '@mui/x-data-grid';
import getSortParametersFactory from 'components/@home/@documents/getSortParametersFactory';
import GridToolbarSearch from 'components/common/GridToolbarSearch';
import Loading from 'components/common/Loading';
import Pagination from 'components/controls/Pagination';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import { array, bool, func, number, object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import toQueryString from 'utils/toQueryString';

const LoadingOverlay = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        backgroundColor: alpha(theme.palette.background.paper, 0.8),
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Loading size={30} />
    </Box>
  );
};

const CustomFooter = () => {
  const apiRef = useGridApiContext();
  const {
    setPage,
    state: {
      pagination: {
        paginationModel: { page, pageSize },
      },
      rows: { totalRowCount },
    },
  } = apiRef.current;
  const handlePageChange = ({ selected }) => {
    setPage(selected);
  };
  return (
    <Pagination total={totalRowCount} page={page} perPage={pageSize} onChange={handlePageChange} />
  );
};

export const parseURLQuery = (location, defaultSortModel) => {
  const { p, sf, sd, t, s, f } = Object.fromEntries(new URLSearchParams(location.search)) || {};
  const page = +p || 0;
  const field = sf || defaultSortModel?.[0].field;
  const sort = sd || defaultSortModel?.[0].sort;
  const filterModel = getDefaultGridFilterModel();
  if (f) {
    try {
      const parsedFilterModel = JSON.parse(f);
      if (Array.isArray(parsedFilterModel)) {
        filterModel.items = parsedFilterModel;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }
  return {
    sortModel: [{ field, sort }],
    page,
    ...(t ? { type: t } : {}),
    filterModel: {
      ...filterModel,
      quickFilterValues: s ? s.split(',') : filterModel?.quickFilterValues || [],
    },
  };
};

const OmmnioDataGrid = ({
  slots,
  sx,
  onPageSizeChange,
  onPageChange,
  onSortModelChange,
  onFilterModelChange,
  initialState,
  updateHistory,
  defaultSortModel,
  page: pageProp,
  pageSize: pageSizeProp,
  rows,
  disableEvenOdd,
  extraUrlParams,
  ...props
}) => {
  const location = useLocation();
  const getSortParameters = getSortParametersFactory(defaultSortModel);
  const [page, setPage] = useState(pageProp);
  const [pageSize, setPageSize] = useState(pageSizeProp);
  const [sortModel, setSortModel] = useState(defaultSortModel);
  const { filterModel: defaultGridFilterModel } = getDefaultGridFilterModel();
  const [filterModel, setFilterModel] = useState(defaultGridFilterModel);
  const navigate = useNavigate();
  const [isUpdating, setUpdating] = useState(false);
  useEffect(() => {
    if (updateHistory) {
      setUpdating(true);
      setTimeout(() => {
        const query = parseURLQuery(location, defaultSortModel);
        const {
          page: parsedPage = 0,
          sortModel: parsedSortModel,
          filterModel: parsedFilterModel,
        } = query;
        if (page !== parsedPage) {
          setPage(parsedPage);
          if (typeof onPageChange === 'function') {
            onPageChange(parsedPage);
          }
        }
        if (!isEqual(sortModel, parsedSortModel)) {
          setSortModel(parsedSortModel);
          if (typeof onSortModelChange === 'function') {
            onSortModelChange(parsedSortModel);
          }
        }
        if (!isEqual(filterModel, parsedFilterModel)) {
          setFilterModel(parsedFilterModel);
          if (typeof onFilterModelChange === 'function') {
            onFilterModelChange(parsedFilterModel);
          }
        }
        setUpdating(false);
      }, 0);
    }
  }, [
    defaultGridFilterModel,
    defaultSortModel,
    filterModel,
    location,
    onFilterModelChange,
    onPageChange,
    onSortModelChange,
    page,
    sortModel,
    updateHistory,
  ]);

  const handlePaginationModelChange = ({ page: newPage, pageSize: newPageSize }) => {
    if (newPageSize > 1) {
      if (typeof onPageSizeChange === 'function') {
        onPageSizeChange(newPageSize);
      }
      setPageSize(newPageSize);
    }
    if (typeof onPageChange === 'function') {
      onPageChange(newPage);
    }
    if (updateHistory && newPage !== page) {
      navigate({
        search: toQueryString({
          ...(newPage === 0 ? {} : { p: newPage }),
          ...getSortParameters(sortModel),
          ...(isEmpty(filterModel?.quickFilterValues) ? {} : { s: filterModel.quickFilterValues }),
          ...(filterModel?.items?.length
            ? {
                f: JSON.stringify(
                  filterModel.items.map(({ field, operator, value }) => ({
                    field,
                    operator,
                    value,
                  })),
                ),
              }
            : {}),
          ...extraUrlParams,
        }),
        state: location.state,
      });
    } else {
      setPage(newPage);
    }
  };

  const handleSortModelChange = (newSortModel, ...rest) => {
    if (typeof onSortModelChange === 'function') {
      onSortModelChange(newSortModel, ...rest);
    }
    if (updateHistory && !isEqual(newSortModel, sortModel)) {
      navigate(
        {
          search: toQueryString({
            ...getSortParameters(newSortModel),
            ...(isEmpty(filterModel?.quickFilterValues)
              ? {}
              : { s: filterModel.quickFilterValues }),
            ...(filterModel?.items?.length
              ? {
                  f: JSON.stringify(
                    filterModel.items.map(({ field, operator, value }) => ({
                      field,
                      operator,
                      value,
                    })),
                  ),
                }
              : {}),
            ...extraUrlParams,
          }),
          state: location.state,
        },
        { replace: true },
      );
    } else {
      setSortModel(newSortModel);
    }
  };

  const handleFilterModelChange = (newFilterModel, ...rest) => {
    if (typeof onFilterModelChange === 'function') {
      onFilterModelChange(newFilterModel, ...rest);
    }
    if (updateHistory && !isEqual(newFilterModel, filterModel)) {
      navigate(
        {
          search: toQueryString({
            ...getSortParameters(sortModel),
            ...(isEmpty(newFilterModel?.quickFilterValues)
              ? {}
              : { s: newFilterModel.quickFilterValues }),
            ...(newFilterModel?.items?.length
              ? {
                  f: JSON.stringify(
                    newFilterModel.items.map(({ field, operator, value }) => ({
                      field,
                      operator,
                      value,
                    })),
                  ),
                }
              : {}),
            ...extraUrlParams,
          }),
          state: location.state,
        },
        { replace: true },
      );
    }
    setFilterModel(newFilterModel);
  };
  return (
    <DataGrid
      sx={{
        ...sx,
        ...(disableEvenOdd
          ? {
              '& .MuiDataGrid-main': {
                '& .MuiDataGrid-row': {
                  '&:nth-of-type(odd), &:hover, &.Mui-selected': {
                    backgroundColor: 'transparent',
                  },
                },
              },
            }
          : {}),
        paddingBottom: 4,
      }}
      initialState={merge({ sorting: { sortModel: defaultSortModel } }, initialState)}
      sortingOrder={['asc', 'desc']}
      autoPageSize
      disableColumnMenu
      slots={{
        footer: CustomFooter,
        toolbar: GridToolbarSearch,
        loadingOverlay: LoadingOverlay,
        ...slots,
      }}
      {...props}
      loading={isUpdating || props.loading}
      rows={rows}
      paginationModel={{ page, pageSize }}
      onPaginationModelChange={handlePaginationModelChange}
      sortModel={sortModel}
      filterModel={filterModel}
      onFilterModelChange={handleFilterModelChange}
      onSortModelChange={handleSortModelChange}
    />
  );
};

OmmnioDataGrid.propTypes = {
  sx: object,
  slots: object,
  rowCount: number,
  rows: array,
  onPageSizeChange: func,
  onPageChange: func,
  onSortModelChange: func,
  onFilterModelChange: func,
  initialState: object,
  updateHistory: bool,
  defaultSortModel: array,
  page: number,
  pageSize: number,
  disableEvenOdd: bool,
  loading: bool,
  extraUrlParams: object,
};
OmmnioDataGrid.defaultProps = {
  sx: {},
  slots: {},
  rowCount: undefined,
  rows: [],
  onPageSizeChange: null,
  onPageChange: null,
  onSortModelChange: null,
  onFilterModelChange: null,
  initialState: {},
  updateHistory: false,
  defaultSortModel: [{ field: 'name', sort: 'asc' }],
  page: 0,
  pageSize: 10,
  disableEvenOdd: false,
  loading: false,
  extraUrlParams: {},
};

export default OmmnioDataGrid;
