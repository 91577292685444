/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
import { ImageListItemBar } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { object } from 'prop-types';
import React, { Component } from 'react';
import ProgressiveImage from 'react-progressive-image';
import attachmentShape from 'shapes/attachment';
import connector from './connector';

const styles = {
  preview: {
    width: '100%',
    height: '100%',
    background: 'transparent',
    border: 0,
    padding: 0,
    margin: 0,
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      aspectRatio: '1/1',
    },
  },
  loading: {
    filter: 'blur(4px)',
    transform: 'scale(1.05)',
  },
  duration: {
    height: 30,
    textAlign: 'right',
  },
  durationSubtitle: {
    display: 'flex',
    alignItems: 'center',
  },
};

class VideoTile extends Component {
  preview = () => {
    const { actions, video } = this.props;
    actions.videoModal.open(video.httpLink);
  };

  render() {
    const { classes, video } = this.props;
    return (
      <ProgressiveImage src={video.meta.thumb} placeholder={video.meta.placeholder}>
        {(src, loading) => (
          <button className={classes.preview} type="button" onClick={this.preview}>
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img className={loading ? classes.loading : classes.loaded} src={src} />
            <ImageListItemBar
              onClick={this.preview}
              className={classes.duration}
              classes={{ subtitle: classes.durationSubtitle }}
              subtitle={`▶ ${
                video.meta.duration?.match(/\d\d:\d\d/)
                  ? video.meta.duration.replace(/^00:0?(\d?\d:\d\d)$/, '$1')
                  : ''
              }`}
            />
          </button>
        )}
      </ProgressiveImage>
    );
  }
}

VideoTile.propTypes = {
  video: attachmentShape.isRequired,
  classes: object.isRequired,
  actions: object.isRequired,
};

export default withStyles(styles)(connector(VideoTile));
