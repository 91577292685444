import { createSelector } from 'reselect';
import currentChannel from 'store/selectors/currentChannel';

const isMember = (channel, user) => {
  const ret =
    channel &&
    (channel.isDirect ||
      !!channel.isMember ||
      !!channel.employees.find(e => e.user_id === user.user_id));
  return ret;
};

export default createSelector(
  state => currentChannel(state),
  state => state.auth,
  isMember,
);
