import Http from 'services/Http';

class TimeTrackerLog {
  async loadMonth(date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const { data } = await Http.get(`/v1/time-tracker-log/${year}/${month}`);
    return data;
  }
}

export default new TimeTrackerLog();
