import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { getBezierPath, getMarkerEnd, useStoreState } from 'react-flow-renderer';
import { string, object, number } from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    fill: 'none',
    stroke: ({ selected }) =>
      selected ? theme.palette.primary.extraDark : theme.palette.primary.main,
    strokeWidth: ({ selected }) => (selected ? 3 : 1),
  },
}));

const ChatFlowEdge = ({
  id,
  style,
  sourceX,
  sourceY,
  sourcePosition,
  targetX,
  targetY,
  targetPosition,
  arrowHeadType,
  markerEndId,
}) => {
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
  const selectedElements = useStoreState(store => store.selectedElements);
  const selected = selectedElements?.find(el => el.id === id);
  const classes = useStyles({ selected });
  return <path id={id} style={style} className={classes.root} d={edgePath} markerEnd={markerEnd} />;
};

ChatFlowEdge.propTypes = {
  id: string.isRequired,
  style: object,
  sourceX: number.isRequired,
  sourceY: number.isRequired,
  sourcePosition: object,
  targetX: number.isRequired,
  targetY: number.isRequired,
  targetPosition: object,
  arrowHeadType: string,
  markerEndId: string,
};

ChatFlowEdge.defaultProps = {
  style: {},
  sourcePosition: null,
  targetPosition: null,
  arrowHeadType: null,
  markerEndId: null,
};

export default ChatFlowEdge;
