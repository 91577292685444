import { Drawer } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { bool, object, string } from 'prop-types';
import React from 'react';
import useEventListener from 'utils/useEventListener';
import CloseButton from '../CloseButton';

import connector from './connector';

const styles = theme => ({
  root: {
    display: 'flex',
    width: '90vw',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      width: 1000,
    },
  },
  container: {
    display: 'flex',
  },
  closeButtonContainer: {
    paddingTop: 20,
    paddingLeft: 20,
  },
  frame: {
    width: '100%',
    height: '100%',
    border: 'none',
  },
});

const RemoteDrawer = ({ url, actions, classes, isOpen }) => {
  useEventListener('message', e => {
    if (e.data === 'close-drawer' || e.message === 'close-drawer') {
      actions.drawers.remote.close();
    }
  });

  return (
    <Drawer anchor="right" open={isOpen} onClose={actions.drawers.remote.close}>
      <div className={classes.root}>
        <div className={classes.closeButtonContainer}>
          <CloseButton onClick={actions.drawers.remote.close} />
        </div>
        {url ? <iframe title="config" src={url} className={classes.frame} /> : null}
      </div>
    </Drawer>
  );
};

RemoteDrawer.propTypes = {
  url: string,
  classes: object.isRequired,
  actions: object.isRequired,
  isOpen: bool.isRequired,
};

RemoteDrawer.defaultProps = {
  url: null,
};

export default withStyles(styles)(connector(RemoteDrawer));
