/**
 * @flow
 */
import { Box, Typography } from '@mui/material';
import React, { Node } from 'react';

const DefinitionListItem = ({ dt, dd }: { dt: Node; dd: Node }) => {
  return (
    <>
      <Box component="dt">
        <Typography
          sx={{
            color: 'secondary.main',
            fontWeight: 600,
          }}
        >
          {dt}
        </Typography>
      </Box>
      <Box component="dd">{typeof dd === 'string' ? <Typography>{dd}</Typography> : dd}</Box>
    </>
  );
};

export default DefinitionListItem;
