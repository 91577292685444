import { Box, IconButton } from '@mui/material';
import ReactionIcon from 'components/common/ReactionIcon';
import REACTIONS from 'constants/reactions';
import PropTypes from 'prop-types';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import messagesActions from 'store/app/entities/messages/action';
import authUserId from 'store/selectors/authUserId';
import Message from 'services/api/Message';

const styles = {
  root: theme => ({
    borderRadius: '20px',
    backdropFilter: 'blur(16px)',
    color: 'text.primary',
    position: 'absolute',
    borderColor: 'divider',
    borderWidth: 1,
    borderStyle: 'solid',
    display: 'flex',
    padding: 0.5,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(26, 28, 31, 0.7)' : 'rgba(248, 249, 253, 0.4)',
  }),
  reaction: {
    color: 'text.primary',
    width: 32,
    height: 32,
  },
  activeReaction: {
    backgroundColor: 'action.selected',
  },
};

const ReactionsToolbar = forwardRef(({ message, isOpen, close }, ref) => {
  const dispatch = useDispatch();
  const currentUserId = useSelector(authUserId);
  const [reactions, setReactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadReactions = async () => {
      if (!isOpen) return;
      setIsLoading(true);
      try {
        const { reactions: messageReactions } = await Message.getReactions(message._id);
        setReactions(messageReactions);
      } finally {
        setIsLoading(false);
      }
    };

    loadReactions();
  }, [isOpen, message._id]);

  const handleReactionClick = useCallback(
    reaction => async () => {
      const existingReaction = reactions.find(r => r.name === reaction);
      const hasReacted = existingReaction?.users.includes(currentUserId);

      close();

      if (hasReacted) {
        await dispatch(messagesActions.removeReaction(message._id, reaction));
      } else {
        await dispatch(messagesActions.addReaction(message._id, reaction));
      }

      // Refresh reactions after adding/removing
      const { reactions: updatedReactions } = await Message.getReactions(message._id);
      setReactions(updatedReactions);
    },
    [reactions, currentUserId, message._id, close, dispatch],
  );

  return (
    <Box ref={ref} sx={styles.root}>
      {!isOpen ||
        REACTIONS.map(r => {
          const reactionData = reactions?.find(reaction => reaction.name === r);
          const hasReacted = reactionData?.users.includes(currentUserId);

          return (
            <IconButton
              disabled={isLoading}
              size="small"
              key={r}
              sx={{
                ...styles.reaction,
                ...(hasReacted && styles.activeReaction),
              }}
              onClick={handleReactionClick(r)}
            >
              <ReactionIcon name={r} />
            </IconButton>
          );
        })}
    </Box>
  );
});

ReactionsToolbar.propTypes = {
  message: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default ReactionsToolbar;
