import makeStyles from '@mui/styles/makeStyles';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SettingsMenu from 'components/@home/@settings/SettingsMenu';
import Loading from 'components/common/Loading';
import deferRender from 'defer-render-hoc';
import { bool } from 'prop-types';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { STRIPE_KEY } from 'services/constants';
import connector from './connector';

const stripePromise = loadStripe(STRIPE_KEY);

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
  },
  loading: {
    flexGrow: 1,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  left: {
    display: 'none',
    width: 250,
    height: '100%',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
}));

const SettingsScene = props => {
  const { isCompanyLoaded, clientSecret } = props;

  const classes = useStyles();

  if (!isCompanyLoaded) {
    return (
      <div className={classes.loading}>
        <Loading size={56} />
      </div>
    );
  }

  return (
    <Elements stripe={stripePromise} options={{ clientSecret }}>
      <section className={classes.root}>
        <div className={classes.left}>
          <SettingsMenu />
        </div>
        <Outlet />
      </section>
    </Elements>
  );
};

SettingsScene.propTypes = {
  isCompanyLoaded: bool.isRequired,
};

export default deferRender(connector(SettingsScene));
