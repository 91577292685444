import TimeTrackerLog from 'services/api/TimeTrackerLog';

export const LOAD_MONTH = 'LOAD_MONTH';
export const LOAD_MONTH_PENDING = 'LOAD_MONTH_PENDING';
export const LOAD_MONTH_FULFILLED = 'LOAD_MONTH_FULFILLED';
export const LOAD_MONTH_REJECTED = 'LOAD_MONTH_REJECTED';

const loadMonth = date => ({
  type: LOAD_MONTH,
  payload: TimeTrackerLog.loadMonth(date),
});

export default {
  loadMonth,
};
