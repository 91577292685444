import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import login from 'store/app/ui/login/action';
import invitationDialog from 'store/app/ui/login/invitationDialog/action';

const mapStateToProps = state => ({
  login: state.login,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    login: {
      ...bindActionCreators(login, dispatch),
      invitationDialog: bindActionCreators(invitationDialog, dispatch),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
