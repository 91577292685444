import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import Loading from 'components/common/Loading';
import { func, object, string } from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import messages from 'store/app/entities/messages/action';
import useConnecting from 'utils/useConnecting';
import useSendAction from 'utils/useSendAction';

const useStyles = makeStyles(theme => ({
  buttonsContainer: {
    flex: '1 0 auto',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 0,
  },
  buttonBack: {
    alignItems: 'center',
    padding: 10,
    flex: 1,
  },
  disabled: {
    opacity: 0.4,
  },
  buttonText: {},
  buttonBackprimary: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  buttonTextprimary: {
    color: theme.palette.white,
  },
  buttonBacksecondary: {
    borderColor: theme.palette.tertiary.main,
  },
  buttonTextsecondary: {
    color: theme.palette.tertiary.main,
  },
  buttonBackdanger: {
    borderColor: theme.palette.danger,
  },
  buttonTextdanger: {
    color: theme.palette.danger,
  },
}));

const BlockButton = ({ button, msgId, blockId, updateMessage }) => {
  const classes = useStyles();
  const onResponseOk = msg => {
    updateMessage(msg);
  };
  const [disabled, sendButtonAction] = useSendAction(button, msgId, blockId, onResponseOk);
  const [hidden] = useState(false);
  const connecting = useConnecting();

  const style = button.style || 'primary';
  return hidden ? null : (
    <div key={`button-${button.actionId}`} className={classes.buttonsContainer}>
      <Button
        disabled={disabled || connecting || button.disabled}
        variant={style === 'primary' ? 'contained' : 'outlined'}
        color={style === 'primary' ? 'primary' : 'secondary'}
        className={classnames(
          classes.buttonBack,
          { [classes.disabled]: disabled || button.disabled },
          classes[`buttonBack${style}`],
        )}
        onClick={() => sendButtonAction()}
      >
        {connecting ? (
          <Loading size={30} />
        ) : (
          <div className={classnames(classes.buttonText, classes[`buttonText${style}`])}>
            {button.text.text}
          </div>
        )}
      </Button>
    </div>
  );
};

BlockButton.propTypes = {
  button: object.isRequired,
  msgId: string.isRequired,
  blockId: string.isRequired,
  updateMessage: func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  updateMessage: messages.updateSuccess,
};
export default connect(mapStateToProps, mapDispatchToProps)(BlockButton);
