import Content from 'services/api/Content';

export const UPDATE_LOGIN_FORM = 'UPDATE_LOGIN_FORM';
export const LOAD_CONTENT = 'LOAD_CONTENT';
export const LOAD_CONTENT_FULFILLED = 'LOAD_CONTENT_FULFILLED';

const update = form => ({
  type: UPDATE_LOGIN_FORM,
  payload: form,
});

const loadContent = (contentId, locale) => ({
  type: LOAD_CONTENT,
  payload: Content.render(contentId, locale),
  meta: { contentId },
});

export default { update, loadContent };
