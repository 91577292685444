import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { node, object } from 'prop-types';

const styles = () => ({
  root: {
    height: '22px',
    marginBottom: '5px',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSize: '12px',
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: '-0.3px',
    color: '#8b98ab',
    lineHeight: 1.83,
    textTransform: 'uppercase',
  },
});

const Title = ({ classes, headline }) => (
<div className={classes.root}>
    {headline}
  </div>
);Title.propTypes = {
  classes: object.isRequired,
  headline: node.isRequired,
};

export default withStyles(styles)(Title);
