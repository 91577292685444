import React from 'react';
import { object, func } from 'prop-types';
import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { I18n } from 'utils/i18n';

const styles = {
  root: {},
};

const CancelButton = ({ classes, onClick }) => (
  <Button classes={classes} onClick={onClick} variant="contained" color="secondary">
    {I18n.t('Dialog.Cancel')}
  </Button>
);
CancelButton.propTypes = {
  classes: object.isRequired,
  onClick: func.isRequired,
};

export default withStyles(styles)(CancelButton);
