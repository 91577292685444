import { arrayOf, bool, number, shape, string } from 'prop-types';

export default shape({
  _id: string.isRequired,
  name: string.isRequired,
  subDomain: string.isRequired,
  isActive: bool,
  waitAcceptTerms: arrayOf(string).isRequired,
  webSiteUrl: string.isRequired,
  createdAt: number.isRequired,
  logo: string.isRequired,
  onBoardingImage: string.isRequired,
  termsFile: string.isRequired,
  terms: string.isRequired,
  isAcceptTerms: bool.isRequired,
});
