/**
 * @flow
 */
import Box from '@mui/joy/Box';
import { FormControlLabel, OutlinedInput } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { I18n } from 'utils/i18n';

const styles = {
  label: {
    alignItems: 'flex-start',
  },
  labelText: {
    fontWeight: 500,
  },
  input: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      minWidth: 36,
    },
  },
};
const InputField = ({ name, disabled }: { name: String; disabled: boolean }) => {
  const { control } = useFormContext();
  return (
    <FormControlLabel
      sx={styles.label}
      labelPlacement="top"
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <OutlinedInput
              sx={{
                flexDirection: 'column',
              }}
              {...field}
              disabled={disabled}
              fullWidth
              multiline
              minRows={3}
            />
          )}
        />
      }
      label={<Box sx={styles.labelText}>{I18n.t(`OccurrenceDrawer.fields.${name}`)}</Box>}
    />
  );
};

export default InputField;
