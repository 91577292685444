import { makeStyles } from '@mui/styles';
import Loading from 'components/common/Loading';
import isEmpty from 'lodash/isEmpty';
import { node } from 'prop-types';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import messagesActions from 'store/app/entities/messages/action';
import currentChannel from 'store/selectors/currentChannel';
import EmptyChat from './EmptyChat';

const actions = {
  messages: messagesActions,
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
    overflow: 'hidden',
  },
  loadingChannel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const MessagesWrapper = ({ children }) => {
  const channel = useSelector(currentChannel);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { channelId } = useParams();
  const { state } = useLocation();
  const currentMessageId = useMemo(() => state?.messageId || null, [state]);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoading(false);
    setLoaded(false);
  }, [channelId, currentMessageId]);

  useEffect(() => {
    if (channel?._id && !channel?.loadedMessages && !loading) {
      setLoading(true);
    } else if (channel?._id && !loading) {
      setLoaded(true);
    }
  }, [channel?._id, channel?.loadedMessages, channel?.messages, currentMessageId, loading]);

  useEffect(() => {
    if (loading) {
      dispatch(actions.messages.load(channelId, { aroundId: currentMessageId })).finally(() => {
        setLoading(false);
        setLoaded(true);
      });
    }
  }, [channelId, currentMessageId, dispatch, loading]);

  if (!loaded) {
    return <Loading />;
  }

  if (isEmpty(channel?.messages)) {
    return (
      <div className={classes.root}>
        <EmptyChat />
      </div>
    );
  }

  return <div className={classes.root}>{children}</div>;
};

MessagesWrapper.propTypes = {
  children: node,
};

MessagesWrapper.defaultProps = {
  children: null,
};

export default memo(MessagesWrapper);
