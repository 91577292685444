/* eslint-disable react/no-array-index-key */
import {
  Button,
  FormHelperText,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ChatFlowCaption from 'components/@home/@settings/Tabs/ChatFlowCaption';
import chatFlowEntityTypeFormik from 'components/@home/@settings/Tabs/chatFlowEntityTypeFormik';
import connector from 'components/@home/@settings/Tabs/connector';
import { handleKeyDown } from 'components/@home/@settings/Tabs/handleKeyDown';
import withContext from 'components/@home/@settings/Tabs/withContext';
import FormikChipInput from 'components/formik/ChipInput';
import TextField from 'components/formik/TextField';
import { ErrorMessage, Field, FieldArray } from 'formik';
import isEqual from 'lodash/isEqual';
import ArrowBackIcon from 'mdi-react/ArrowBackIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import { func, object } from 'prop-types';
import React, { memo } from 'react';
import { I18n } from 'utils/i18n';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    flexDirection: 'column',
    height: 'calc(100vh - 64px)',
    borderLeft: `solid 1px ${theme.palette.secondary.extraLight}`,
  },
  title: {
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    color: theme.palette.primary.ultraDark,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  close: {
    color: theme.palette.primary.ultraDark,
  },
  body: {
    flexGrow: 1,
    overflow: 'auto',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(3),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingTop: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  details: {
    flexDirection: 'column',
  },
  field: {
    marginTop: theme.spacing(2),
  },
  table: {
    marginTop: theme.spacing(3),
    tableLayout: 'fixed',
    borderSpacing: '10px',
    borderCollapse: 'separate',
  },
  options: {
    borderRadius: 6,
    border: `1px solid ${theme.palette.secondary.light}`,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  inputWrapper: {
    '& + $inputWrapper': {
      borderTop: `1px solid ${theme.palette.secondary.light}`,
    },
    '&:hover $delete': {
      visibility: 'inherit',
    },
    '&:hover:last-child $delete': {
      visibility: 'hidden',
    },
  },
  input: {
    marginBottom: -1,
    paddingLeft: 0,
    border: 0,
  },
  delete: {
    visibility: 'hidden',
  },
  withActions: {
    display: 'flex',
    alignItems: 'center',
  },
  cell: {},
}));
const ChatFlowEntityType = ({
  context,
  setFieldValue,
  handleSubmit,
  values: { options: optionsIn },
}) => {
  const classes = useStyles();
  const options = [...optionsIn];
  if (options.length === 0 || options[options.length - 1].name !== '') {
    setFieldValue('options', [...options, { _id: `${Math.random()}`, name: '', texts: [] }]);
  }

  return (
    <form onSubmit={handleSubmit} className={classes.root}>
      <header className={classes.header}>
        <div className={classes.headerLeft}>
          {context.length > 1 && (
            <IconButton
              className={classes.close}
              edge="start"
              aria-label="close"
              onClick={context.pop}
              size="large"
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          <Typography size="small" className={classes.title}>
            {I18n.t('ChatFlowSettings.Entity type')}
          </Typography>
        </div>
        <div>
          <IconButton
            className={classes.close}
            edge="end"
            aria-label="close"
            onClick={context.clear}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </header>
      <section className={classes.body}>
        <ChatFlowCaption section="EntityType" />
        <Typography className={classes.heading}>
          {I18n.t('ChatFlowSettings.Display name')}
        </Typography>
        <Field
          className={classes.field}
          component={TextField}
          name="name"
          placeholder={I18n.t('ChatFlowSettings.Display name')}
        />
        <Typography className={classes.heading}>{I18n.t('ChatFlowSettings.Options')}</Typography>
        <ChatFlowCaption section="Options" />
        <Table size="small" padding="none" className={classes.table}>
          <TableHead>
            <TableRow hover={false}>
              <TableCell>{I18n.t('ChatFlowSettings.Name')}</TableCell>
              <TableCell>{I18n.t('ChatFlowSettings.Texts')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <FieldArray
              name="options"
              render={arrayHelpers =>
                options.map((p, idx) => (
                  <TableRow key={p._id}>
                    <TableCell className={classes.cell}>
                      <Field
                        component={TextField}
                        name={`options.${idx}.name`}
                        id={`options.${p._id}.name`}
                        placeholder={I18n.t('ChatFlowSettings.Option name')}
                        onKeyDown={handleKeyDown(
                          idx,
                          `options.${idx}.name`,
                          options[idx].name,
                          setFieldValue,
                          classes.cell,
                        )}
                        onBlur={() => {
                          if (
                            options[idx].name &&
                            !options[idx].texts.includes(options[idx].name)
                          ) {
                            setFieldValue(`options.${idx}.texts`, [
                              options[idx].name,
                              ...options[idx].texts,
                            ]);
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell className={classes.withActions}>
                      <Field
                        component={FormikChipInput}
                        placeholder={I18n.t('ChatFlowSettings.Add synonyms')}
                        name={`options.${idx}.texts`}
                        creatable
                      />
                      <IconButton
                        size="small"
                        edge="end"
                        aria-label="delete"
                        onClick={() => arrayHelpers.remove(idx)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              }
            />
            <ErrorMessage name="options">
              {msg => (
                <TableRow>
                  <TableCell>
                    <FormHelperText error>{msg}</FormHelperText>
                  </TableCell>
                </TableRow>
              )}
            </ErrorMessage>
          </TableBody>
        </Table>
      </section>
      <footer className={classes.footer}>
        <div />
        <Button variant="contained" color="primary" type="submit">
          {I18n.t('ChatFlowSettings.Save')}
        </Button>
      </footer>
    </form>
  );
};

ChatFlowEntityType.propTypes = {
  values: object.isRequired,
  handleSubmit: func.isRequired,
  context: object.isRequired,
  setFieldValue: func.isRequired,
};

const areEqual = (prev, next) => {
  return prev.entityType?._id === next.entityType?._id && isEqual(prev.values, next.values);
};
export default connector(withContext(memo(chatFlowEntityTypeFormik(ChatFlowEntityType), areEqual)));
