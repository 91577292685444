import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import channels from 'store/app/entities/channels/action';
import messages from 'store/app/entities/messages/action';
import authEmployee from 'store/selectors/authEmployee';
import currentChannel from 'store/selectors/currentChannel';
import isCompanyAdmin from 'store/selectors/isCompanyAdmin';
import isMember from 'store/selectors/isMember';

const mapStateToProps = state => ({
  isCompanyAdmin: isCompanyAdmin(state),
  channel: currentChannel(state),
  isMember: isMember(state),
  locale: state.i18n.locale,
  authEmployee: authEmployee(state),
});

const mapDispatchToProps = dispatch => ({
  actions: {
    channels: bindActionCreators(channels, dispatch),
    messages: bindActionCreators(messages, dispatch),
  },
  onUploadProgress: () => null,
});

export default connect(mapStateToProps, mapDispatchToProps);
