import { connect } from 'react-redux';
import currentChannel from 'store/selectors/currentChannel';
import authEmployee from 'store/selectors/authEmployee';
import isCompanyAdmin from 'store/selectors/isCompanyAdmin';

const mapStateToProps = state => ({
  channel: currentChannel(state),
  authEmployee: authEmployee(state),
  isCompanyAdmin: isCompanyAdmin(state),
});

const mapDispatchToProps = () => ({
  actions: {},
});

export default connect(mapStateToProps, mapDispatchToProps);
