import Box from '@mui/material/Box';
import EmployeeAvatar from 'components/@home/EmployeeAvatar';
import { bool, string } from 'prop-types';
import React, { memo } from 'react';

const styles = {
  avatarContainer: {
    paddingRight: '5px',
  },
  avatarRight: {
    paddingRight: 0,
    paddingLeft: '5px',
  },
};

const MessageAvatar = ({ right, name, avatar }) => (
  <Box sx={{ ...styles.avatarContainer, ...(right ? styles.avatarRight : {}) }}>
    <EmployeeAvatar employee={{ name, avatar }} />
  </Box>
);

MessageAvatar.propTypes = {
  right: bool.isRequired,
  name: string.isRequired,
  avatar: string,
};

MessageAvatar.defaultProps = {
  avatar: null,
};

export default memo(MessageAvatar);
