import Loading from 'components/common/Loading';
import React, { lazy, Suspense } from 'react';

const ArticleEditor = lazy(() => import('./ArticleEditor'));

const ArticleEditorLazy = props => {
  return (
    <Suspense fallback={<Loading />}>
      <ArticleEditor {...props} />
    </Suspense>
  );
};

export default ArticleEditorLazy;
