/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import { SvgIcon } from '@mui/material';
import BoltIcon from 'assets/icons/bolt.svg';
import { useFormikContext } from 'formik';
import React, { memo } from 'react';
import { I18n } from 'utils/i18n';
import formDrawer from 'store/app/ui/drawers/form/action';
import useActions from 'utils/useActions';
import OptionButton from '../OptionButton';

const NewFormButton = () => {
  const open = useActions(formDrawer.open);
  const {
    values: { sentAsChannel },
  } = useFormikContext();
  const openModal = () => {
    open({ sentAsChannel });
  };

  return (
    <div onClick={openModal}>
      <OptionButton
        icon={
          <SvgIcon>
            <BoltIcon />
          </SvgIcon>
        }
      >
        {I18n.t('SendMessageForm.Send form')}
      </OptionButton>
    </div>
  );
};

export default memo(NewFormButton);
