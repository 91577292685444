/**
 * @flow
 */
import { SvgIcon, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Confirm from 'assets/icons/question-confirm.svg';
import FileUpload from 'assets/icons/question-file-upload.svg';
import ImageUpload from 'assets/icons/question-image-upload.svg';
import MultipleChoice from 'assets/icons/question-multiple-choice.svg';
import SingleChoice from 'assets/icons/question-single-choice.svg';
import Text from 'assets/icons/question-text.svg';
import React from 'react';
import classnames from 'classnames';

const options = { SingleChoice, MultipleChoice, ImageUpload, FileUpload, Confirm, Text };

const useStyles = makeStyles(theme => ({
  iconWrapper: {
    borderRadius: 5,
    backgroundColor: ({ type }) => theme.palette.questions[type],
    color: alpha(theme.palette.white, 0.9),
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: ({ text }) => (text ? theme.spacing(1) : 0),
  },
  text: {
    marginRight: 10,
    color: theme.palette.white,
    minWidth: 20,
    textAlign: 'right',
  },
}));

const QuestionTypeIcon = ({
  type,
  text,
  className,
}: {
  type: string;
  text?: string;
  className?: string;
}) => {
  const classes = useStyles({ type });
  const Icon = options[type];
  return (
    <div className={classnames(className, classes.iconWrapper)}>
      <SvgIcon className={classes.icon}>
        <Icon />
      </SvgIcon>
      {text ? <Typography className={classes.text}>{text}</Typography> : null}
    </div>
  );
};

QuestionTypeIcon.defaultProps = {
  text: '',
  className: undefined,
};

export default QuestionTypeIcon;
