import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import { GET_MEMBER_CHANNELS_FULFILLED } from 'store/app/entities/channels/action';
import {
  ADD_MESSAGE,
  CONFIRM_ACKNOWLEDGE_MESSAGE_FULFILLED,
  DESTROY_MESSAGE_FULFILLED,
  GENERATE_MESSAGE_PREVIEW_FULFILLED,
  LOAD_MESSAGES_FULFILLED,
  MARK_AS_READ_MESSAGES,
  ON_READ_MESSAGE,
  PERMISSIONS_MESSAGE_FULFILLED,
  PERMISSIONS_MESSAGE_PENDING,
  PERMISSIONS_MESSAGE_REJECTED,
  READ_MESSAGES_FULFILLED,
  SEND_MESSAGE_FULFILLED,
  SEND_MESSAGE_PENDING,
  SEND_MESSAGE_REJECTED,
  UPDATE_MESSAGE_FULFILLED,
} from 'store/app/entities/messages/action';
import uniqid from 'uniqid';
import arrayToObject from 'utils/arrayToObject';
import generatePath from 'utils/generatePath';
import merge from 'utils/merge';

const createAttachment = a => ({
  _id: a._id,
  user_id: a.user?._id || a.user,
  httpLink: generatePath(a.httpLink),
  meta: a.meta,
  name: a.name,
  size: a.size,
  type: a.type,
  mimeType: a.mimeType,
  createdAt: a.createdAt,
});

const createMessage = m => ({
  _id: m._id,
  text: m.text,
  mentions: m.mentions,
  title: m.title,
  acknowledges: m.acknowledges,
  attachments: m.attachments?.map(a => createAttachment(a)) || [],
  channel_id: m.channel_id || m.channel,
  company_id: m.company_id || m.company,
  previews: {},
  createdAt: m.createdAt,
  employee_id: m.employee_id || m.employee?._id || m.employee,
  from: m.from,
  user_id: m.user_id || m.from?._id,
  isDeleted: !!m.deletedAt,
  isDeletedBy: m.isDeletedBy,
  isSuperAdmin: m?.from?.role === 2,
  isDraft: m.isDraft,
  isEdited: m.isEdited,
  softDeletedAt: m.softDeletedAt,
  isMandatory: m.isMandatory,
  isRead: m.isRead,
  isReadByMe: m.isReadByMe,
  links: m.links,
  temp: m.temp || false,
  seen: m.seen || m.isRead,
  error: m.error,
  senderWasDeleted: m.senderWasDeleted || false,
  sentAsChannel: m.sentAsChannel || false,
  digitalSignature: m.digitalSignature || false,
  form: m.form,
  formVisibleAll: m.formVisibleAll,
  suggestions: m.suggestions ? [...m.suggestions] : m.suggestions,
  blocks: m.blocks,
  canForward: m.canForward,
  canEdit: m.canEdit,
  canDelete: m.canDelete,
  canPin: m.canPin,
  showForwarded: m.showForwarded,
  forwardedFrom: m.forwardedFrom,
  replyTo: m.replyTo
    ? {
        ...m.replyTo,
        channel_id: m.channel_id || m.channel,
      }
    : null,
  article: m.article?._id ? m.article : null,
  reactions: m.reactions,
});

const messagesReducer = (state = {}, { type, payload, meta }) => {
  switch (type) {
    case GET_MEMBER_CHANNELS_FULFILLED: {
      const lastMessages =
        payload.channels?.filter(c => c.lastMessage).map(c => c.lastMessage) || [];

      return {
        ...state,
        ...arrayToObject(lastMessages.map(c => createMessage(merge(state[c._id], c)))),
      };
    }

    case LOAD_MESSAGES_FULFILLED: {
      const existing = meta.replace ? omitBy(state, m => m.channel_id === meta.channel_id) : state;
      return {
        ...existing,
        ...arrayToObject(payload.messages.map(c => createMessage(merge(state[c._id], c)))),
      };
    }

    case GENERATE_MESSAGE_PREVIEW_FULFILLED: {
      const messages = { ...state };
      const message = messages[meta.message_id];

      if (isEmpty(payload)) return messages;

      messages[meta.message_id] = merge(message, {
        previews: {
          ...message.previews,
          [payload.title]: {
            ...payload,
            link: meta.link,
            favicon: payload.image,
          },
        },
      });

      return messages;
    }

    case UPDATE_MESSAGE_FULFILLED:
      return {
        ...state,
        [meta.message_id]: createMessage({ ...state[meta.message_id], ...meta.message }),
      };

    case READ_MESSAGES_FULFILLED: {
      const markedAsSeenMessages = Object.values(state)
        .filter(m => m.channel_id === meta.channel_id && !m.isReadByMe)
        .map(m => ({
          ...m,
          seen: meta.authUser_id === m.from?._id ? m.seen : true,
          isReadByMe: true,
        }));

      return merge(state, arrayToObject(markedAsSeenMessages));
    }

    case SEND_MESSAGE_PENDING: {
      return merge(state, {
        [meta.message_id]: createMessage({
          ...meta.message,
          temp: true,
        }),
      });
    }

    case SEND_MESSAGE_REJECTED: {
      return merge(state, {
        [meta.message_id]: createMessage({
          ...state[meta.message_id],
          temp: false,
          error: true,
        }),
      });
    }

    case ADD_MESSAGE:
    case SEND_MESSAGE_FULFILLED: {
      return {
        ...state,
        [payload._id]: createMessage({
          ...payload,
          temp: false,
          seen:
            state[payload._id]?.seen ||
            payload.channel === meta.watchedChannel_id ||
            payload.from?._id === meta.authUser_id,
        }),
      };
    }

    case CONFIRM_ACKNOWLEDGE_MESSAGE_FULFILLED: {
      const message = state[meta.message_id];
      const newAcknowledge = {
        _id: uniqid(),
        user: meta.authUser_id,
        confirmedAt: Date.now() / 1000,
      };

      const newMessage = merge(state[message._id], {
        acknowledges: [...message.acknowledges, newAcknowledge],
      });

      return merge(state, {
        [newMessage._id]: newMessage,
      });
    }

    case MARK_AS_READ_MESSAGES: {
      const markedAsSeenMessages = Object.values(state)
        .filter(m => m.channel_id === payload && !m.seen)
        .map(m => ({ ...m, seen: true, isRead: true, isReadByMe: true }));

      return merge(state, arrayToObject(markedAsSeenMessages));
    }

    case DESTROY_MESSAGE_FULFILLED: {
      const messages = { ...state };
      if (payload.message.deletedAt) {
        delete messages[meta.message_id];
      } else {
        messages[meta.message_id] = createMessage({
          ...payload.message,
          temp: false,
          seen: false,
        });
      }
      return messages;
    }

    case ON_READ_MESSAGE: {
      const newMessages = payload.messages.reduce((acc, curr) => {
        const m = state[curr];
        if (m) {
          return {
            [curr]: {
              ...m,
              isRead: m.isRead || m.from._id !== payload.userId,
            },
          };
        }
        return acc;
      }, {});
      return merge(state, newMessages);
    }

    case PERMISSIONS_MESSAGE_PENDING: {
      return {
        ...state,
        [meta.message_id]: { ...state[meta.message_id], permissionsState: 'loading' },
      };
    }

    case PERMISSIONS_MESSAGE_REJECTED: {
      return {
        ...state,
        [meta.message_id]: { ...state[meta.message_id], permissionsState: 'failed' },
      };
    }

    case PERMISSIONS_MESSAGE_FULFILLED: {
      return {
        ...state,
        [meta.message_id]: { ...state[meta.message_id], ...payload, permissionsState: 'loaded' },
      };
    }

    default:
      return state;
  }
};

export default messagesReducer;
