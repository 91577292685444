import { CLOSE_TIME_TRACKING_DRAWER, OPEN_TIME_TRACKING_DRAWER } from './action';

const initialState = {
  isOpen: false,
};

const hrBotDrawer = (state = initialState, { type }) => {
  switch (type) {
    case OPEN_TIME_TRACKING_DRAWER:
      return {
        ...state,
        isOpen: true,
      };

    case CLOSE_TIME_TRACKING_DRAWER:
      return {
        ...state,
        isOpen: false,
      };

    default:
      return state;
  }
};

export default hrBotDrawer;
