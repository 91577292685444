import Box from '@mui/material/Box';
import Loading from 'components/common/Loading';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { useMe } from 'store/app/auth/action';
import companyActions from 'store/app/entities/companies/action';
import authCompany from 'store/selectors/authCompany';
import useActions from 'utils/useActions';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'secondary.ultraLight',
  },
};

const WelcomeLayout = () => {
  const company = useSelector(authCompany);
  const loadOnboarding = useActions(companyActions.loadOnboarding);
  const { isLoading } = useMe();
  useEffect(() => {
    if (!company.logo) {
      loadOnboarding();
    }
  }, [company.logo, loadOnboarding]);

  return <Box sx={styles.root}>{isLoading ? <Loading /> : <Outlet />}</Box>;
};

export default WelcomeLayout;
