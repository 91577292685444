import { Radio } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 20,
    height: 20,
    backgroundColor: theme.palette.white,
    border: `1px solid ${theme.palette.secondary.light}`,
    'input:hover ~ &': {
      borderColor: theme.palette.primary.light2,
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: theme.palette.greyBlue,
    },
  },
  checkedIcon: {
    '&:before': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '50%',
      display: 'block',
      width: 10,
      height: 10,
      content: '""',
    },
  },
}));

const StyledRadio = props => {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      color="default"
      checkedIcon={<span className={classNames(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
};

export default StyledRadio;
