import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { I18n } from 'utils/i18n';
import LoginForm from './LoginForm';
import Heading from '../Heading';

const styles = () => ({});

const LoginScene = () => (
  <section>
    <Heading headline={I18n.t('Login.headline')} subheading={I18n.t('Login.subheading')} />
    <LoginForm />
  </section>
);
export default withStyles(styles)(LoginScene);
