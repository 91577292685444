import React from 'react';
import { object, bool, func } from 'prop-types';
import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { I18n } from 'utils/i18n';

const styles = theme => ({
  root: {
    minWidth: '90px',
    fontSize: '15px',
    color: theme.palette.black,
  },
});

const DraftButton = ({ classes, disabled, onClick }) => (
  <Button disabled={disabled} variant="outlined" onClick={onClick} className={classes.root}>
    {I18n.t('AcknowledgementCreate.Save draft')}
  </Button>
);
DraftButton.propTypes = {
  classes: object.isRequired,
  disabled: bool,
  onClick: func.isRequired,
};

DraftButton.defaultProps = {
  disabled: false,
};

export default withStyles(styles)(DraftButton);
