export const OPEN_EMPLOYEE_DRAWER = 'OPEN_EMPLOYEE_DRAWER';
export const CLOSE_EMPLOYEE_DRAWER = 'CLOSE_EMPLOYEE_DRAWER';

const open = employeeId => ({
  type: OPEN_EMPLOYEE_DRAWER,
  payload: employeeId,
});

const close = () => ({
  type: CLOSE_EMPLOYEE_DRAWER,
});

export default { open, close };
