import Company from 'services/api/Company';
import authCompany from 'store/selectors/authCompany';

export const GET_CUSTOMER = 'GET_CUSTOMER';
export const GET_CUSTOMER_PENDING = 'GET_CUSTOMER_PENDING';
export const GET_CUSTOMER_FULFILLED = 'GET_CUSTOMER_FULFILLED';

const getCustomer = () => (dispatch, getState) => {
  const company = authCompany(getState());
  return dispatch({
    type: GET_CUSTOMER,
    payload: Company.getCustomer(company._id),
  });
};

export default {
  getCustomer,
};
