/* eslint-disable class-methods-use-this */
import Http from 'services/Http';

class Form {
  async find(id) {
    const { data } = await Http.get(`/v1/form/${id}`);
    return data.form;
  }

  async summary(id) {
    const { data } = await Http.get(`/v1/form/${id}/summary`);
    return data;
  }

  async answers(id) {
    const { data } = await Http.get(`/v1/form/${id}/answers`);
    return data;
  }

  async list() {
    const { data } = await Http.get('/v1/form');
    return data;
  }

  async create(form) {
    const response = await Http.post('/v1/form', form);
    return response.data.form;
  }

  async update(id, form) {
    const response = await Http.patch(`/v1/form/${id}`, form);
    return response.data.form;
  }

  async destroy(id) {
    const { data } = await Http.delete(`/v1/form/${id}`);
    return data;
  }
}

export default new Form();
