import { createSelector } from 'reselect';
import populateEmployees from 'store/selectors/populateEmployees';

const TIME_TRACKING_USER_ID = '5dc04ff620e252001061eb8c';
const timeTrackingEmployee = employees => {
  const emp = Object.values(employees).find(e => e.user_id === TIME_TRACKING_USER_ID);
  return emp;
};

export default createSelector(state => populateEmployees(state), timeTrackingEmployee);
