export const OPEN_WHISTLEBLOWER_BOT_DRAWER = 'OPEN_WHISTLEBLOWER_BOT_DRAWER';
export const CLOSE_WHISTLEBLOWER_BOT_DRAWER = 'CLOSE_WHISTLEBLOWER_BOT_DRAWER';

const open = data => ({
  type: OPEN_WHISTLEBLOWER_BOT_DRAWER,
  payload: data,
});

const close = () => ({
  type: CLOSE_WHISTLEBLOWER_BOT_DRAWER,
});

export default { open, close };
