import { UPDATE_LOGIN_FORM, LOAD_CONTENT_FULFILLED } from './action';

const initialState = {
  prefix: '',
  phone: '',
  terms: false,
  content: {},
};

const loginFormReducer = (state = initialState, { type, payload, meta }) => {
  switch (type) {
    case UPDATE_LOGIN_FORM:
      return { ...state, ...payload };

    case LOAD_CONTENT_FULFILLED: {
      const content = { ...state.content, [meta.contentId]: payload };
      return { ...state, content };
    }

    default:
      return state;
  }
};

export default loginFormReducer;
