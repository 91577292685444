/* eslint-disable react/no-array-index-key */
import {
  Button,
  FormHelperText,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import chatFlowBuiltInTypes from 'components/@home/@settings/Tabs/chatFlowBuiltInTypes';
import ChatFlowCaption from 'components/@home/@settings/Tabs/ChatFlowCaption';
import chatFlowIntentFormik from 'components/@home/@settings/Tabs/chatFlowIntentFormik';
import connector from 'components/@home/@settings/Tabs/connector';
import { handleKeyDown } from 'components/@home/@settings/Tabs/handleKeyDown';
import withContext from 'components/@home/@settings/Tabs/withContext';
import FormikChipInput from 'components/formik/ChipInput';
import FormikMentionsInput from 'components/formik/MentionsInput';
import TextField from 'components/formik/TextField';
import { ErrorMessage, Field, FieldArray } from 'formik';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';
import ArrowBackIcon from 'mdi-react/ArrowBackIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import { func, object } from 'prop-types';
import React, { memo, useCallback, useEffect } from 'react';
import { I18n } from 'utils/i18n';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    flexDirection: 'column',
    height: 'calc(100vh - 64px)',
    borderLeft: `solid 1px ${theme.palette.secondary.extraLight}`,
  },
  title: {
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    color: theme.palette.primary.ultraDark,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  close: {
    color: theme.palette.primary.ultraDark,
  },
  body: {
    flexGrow: 1,
    overflow: 'auto',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(3),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingTop: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  details: {
    flexDirection: 'column',
  },
  field: {
    marginTop: theme.spacing(1),
  },
  suggestions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  table: {
    marginTop: theme.spacing(3),
  },
  phrases: {
    borderRadius: 6,
    border: `1px solid ${theme.palette.secondary.light}`,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  inputWrapper: {
    '& + $inputWrapper': {
      borderTop: `1px solid ${theme.palette.secondary.light}`,
    },
    '&:hover $delete': {
      visibility: 'inherit',
    },
    '&:hover:last-child $delete': {
      visibility: 'hidden',
    },
  },
  inputRoot: {
    marginBottom: -1,
    paddingLeft: 0,
    border: 0,
  },
  delete: {
    visibility: 'hidden',
  },
}));

const getEntitiesFromUtterance = utterance => {
  const result = [];
  if (utterance) {
    const tokens = utterance.split(/[\s,.!?;:([\]'"¡¿)/]+/).filter(x => x);
    for (let i = 0; i < tokens.length; i += 1) {
      const token = tokens[i];
      if (token.startsWith('@')) {
        result.push(token.slice(1));
      }
    }
  }
  return result;
};

const getEntityType = (chatFlowEntityTypes, s) => {
  const type = s.name?.split('_')[0];
  return Object.values(chatFlowEntityTypes).find(e => e.name === type) ||
    chatFlowBuiltInTypes.includes(type)
    ? type
    : null;
};

const ChatFlowIntent = ({
  context,
  setFieldValue,
  handleSubmit,
  chatFlowEntityTypes,
  values: { _id, phrases: phrasesIn, slots: slotsIn },
}) => {
  let typeSuggestions = Object.values(chatFlowEntityTypes).map(e => ({
    display: e.name,
    id: e._id,
  }));
  typeSuggestions = typeSuggestions.concat(chatFlowBuiltInTypes.map(t => ({ display: t, id: t })));
  const slots = slotsIn.map(s => ({ ...s, type: getEntityType(chatFlowEntityTypes, s) }));

  const classes = useStyles();
  const phrases = [...phrasesIn];
  if (phrases.length === 0 || phrases[phrases.length - 1].text !== '') {
    setFieldValue('phrases', [...phrases, { _id: `${Math.random()}`, text: '' }]);
  }
  const addSlot = useCallback(
    (id, name) => {
      const slotsByName = slotsIn.reduce((acc, curr) => ({ ...acc, [curr.name]: curr }), {});
      if (!slotsByName[name]) {
        const newSlots = orderBy(
          [
            ...slots,
            {
              _id: `${Math.random()}`,
              type: getEntityType(chatFlowEntityTypes, { name }),
              name,
              suggestions: [],
              prompt: '',
            },
          ],
          'name',
        );
        setFieldValue('slots', newSlots);
      }
    },
    [chatFlowEntityTypes, setFieldValue, slots, slotsIn],
  );

  const computeSlots = useCallback(() => {
    const slotsByName = slotsIn.reduce((acc, curr) => ({ ...acc, [curr.name]: curr }), {});
    const foundSlots = phrasesIn.reduce((acc, p) => {
      return new Set([...acc, ...getEntitiesFromUtterance(p.text)]);
    }, new Set());
    const newSlots = orderBy(
      [
        ...slots.filter(s => {
          if (foundSlots.has(s.name)) {
            foundSlots.delete(s.name);
            return true;
          }
          return false;
        }),
        ...Array.from(foundSlots).map(s => ({
          _id: `${Math.random()}`,
          type: getEntityType(chatFlowEntityTypes, { name: s }),
          name: s,
          suggestions: [],
          prompt: '',
          ...slotsByName[s],
        })),
      ],
      'name',
    );
    if (!isEqual(newSlots, slots)) {
      setFieldValue('slots', newSlots);
    }
  }, [phrasesIn, setFieldValue, slots]);
  useEffect(computeSlots, [_id]);
  return (
    <form onSubmit={handleSubmit} className={classes.root}>
      <header className={classes.header}>
        <div className={classes.headerLeft}>
          {context.length > 1 && (
            <IconButton
              className={classes.close}
              edge="start"
              aria-label="close"
              onClick={context.pop}
              size="large"
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          <Typography size="small" className={classes.title}>
            {I18n.t('ChatFlowSettings.Intent')}
          </Typography>
        </div>
        <div>
          <IconButton
            className={classes.close}
            edge="end"
            aria-label="close"
            onClick={context.clear}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </header>
      <section className={classes.body}>
        <ChatFlowCaption section="Intent" />
        <Typography className={classes.heading}>
          {I18n.t('ChatFlowSettings.Display name')}
        </Typography>
        <Field
          className={classes.field}
          component={TextField}
          name="name"
          placeholder={I18n.t('ChatFlowSettings.Display name')}
        />
        <Typography className={classes.heading}>
          {I18n.t('ChatFlowSettings.Training phrases')}
        </Typography>
        <ChatFlowCaption section="Training phrases" />
        <FieldArray
          name="phrases"
          render={arrayHelpers => (
            <div className={classes.phrases}>
              {phrases.map((p, idx) => (
                <FieldArray
                  key={`${_id}-phrase-${p._id}-${idx}-${slots.length}`}
                  name={`phrases.${idx}`}
                  render={() => (
                    <Field
                      key={`${_id}-${p._id}-${idx}-${phrases[idx]?.hidden}`}
                      component={FormikMentionsInput}
                      name={`phrases.${idx}.text`}
                      onKeyDown={handleKeyDown(
                        idx,
                        'phrases',
                        phrases,
                        setFieldValue,
                        classes.inputWrapper,
                      )}
                      onBlur={computeSlots}
                      placeholder={I18n.t('ChatFlowSettings.parameters.phrase')}
                      data={typeSuggestions}
                      onAdd={addSlot}
                      InputProps={{
                        classes: { root: classes.inputRoot },
                        endAdornment: (
                          <InputAdornment position="end" className={classes.delete}>
                            <IconButton
                              aria-label="delete"
                              onClick={() => arrayHelpers.remove(idx)}
                              size="small"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      className={classes.inputWrapper}
                    />
                  )}
                />
              ))}
            </div>
          )}
        />
        <ErrorMessage name="phrases">
          {msg => <FormHelperText error>{msg}</FormHelperText>}
        </ErrorMessage>
        <Typography className={classes.heading}>{I18n.t('ChatFlowSettings.Slots')}</Typography>
        <ChatFlowCaption section="Slots" />
        {slots.length > 0 ? (
          <Table size="small" padding="none" className={classes.table}>
            <TableHead>
              <TableRow hover={false}>
                <TableCell size="small" style={{ width: '15%' }}>
                  {I18n.t('ChatFlowSettings.Name')}
                </TableCell>
                <TableCell size="small" style={{ width: '15%' }}>
                  {I18n.t('ChatFlowSettings.Type')}
                </TableCell>
                <TableCell style={{ width: '70%' }}>{I18n.t('ChatFlowSettings.Prompt')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <FieldArray
                name="slots"
                render={() =>
                  slots.map((s, idx) => (
                    <TableRow key={`${s.name}-${idx}`}>
                      <TableCell size="small">{s.name}</TableCell>
                      <TableCell size="small">
                        {s.type}
                        <ErrorMessage name={`slots.${idx}.type`}>
                          {msg => <FormHelperText error>{msg}</FormHelperText>}
                        </ErrorMessage>
                      </TableCell>
                      <TableCell>
                        <Field
                          className={classes.field}
                          component={TextField}
                          name={`slots.${idx}.prompt`}
                        />
                        <ErrorMessage name={`slots.${idx}.prompt`}>
                          {msg => <FormHelperText error>{msg}</FormHelperText>}
                        </ErrorMessage>
                        <Field
                          className={classes.suggestions}
                          component={FormikChipInput}
                          placeholder={I18n.t('ChatFlowSettings.Add suggestions')}
                          name={`slots.${idx}.suggestions`}
                          creatable
                        />
                      </TableCell>
                    </TableRow>
                  ))
                }
              />
            </TableBody>
          </Table>
        ) : (
          <>
            <Typography>{I18n.t('ChatFlowSettings.Slots empty')}</Typography>
            <br />
            <br />
            <Typography variant="caption">{I18n.t('ChatFlowSettings.Slots they are')}</Typography>
          </>
        )}
      </section>
      <footer className={classes.footer}>
        <div />
        <Button variant="contained" color="primary" type="submit">
          {I18n.t('ChatFlowSettings.Save')}
        </Button>
      </footer>
    </form>
  );
};

ChatFlowIntent.propTypes = {
  values: object.isRequired,
  handleSubmit: func.isRequired,
  context: object.isRequired,
  setFieldValue: func.isRequired,
  chatFlowEntityTypes: object.isRequired,
};

const areEqual = (prev, next) => {
  return prev.intent?._id === next.intent?._id && isEqual(prev.values, next.values);
};
export default connector(withContext(memo(chatFlowIntentFormik(ChatFlowIntent), areEqual)));
