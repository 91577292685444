import React from 'react';
import { object } from 'prop-types';
import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { I18n } from 'utils/i18n';
import { Link } from 'react-router-dom';

const styles = theme => ({
  link: {
    color: theme.palette.primary.dark,
  },
  heading: {
    maxWidth: 290,
  },
  subheading: {
    color: '#8b98ab',
    fontWeight: 'normal',
  },
  divider: {
    margin: '40px 0',
    border: '1px solid',
    borderColor: theme.palette.divider,
  },
  noInvitaion: {
    fontWeight: 'bold',
  },
  description: {
    paddingBottom: 20,
  },
});

const NoInvitationHeading = ({ classes }) => (
  <section className={classes.root}>
    <div>
      <Typography className={classes.heading} gutterBottom variant="h5">
        {I18n.t('NoInvitation.headline')}
      </Typography>
      <Typography variant="h5" className={classes.subheading}>
        {I18n.t('NoInvitation.subheading')}
      </Typography>
    </div>
    <hr className={classes.divider} />
    <div className={classes.description}>
      <Typography gutterBottom className={classes.noInvitaion}>
        {I18n.t('NoInvitation.no invitation')}
      </Typography>
      <Typography variant="caption">
        <Link to="/auth/login-no-invitation/form" className={classes.link}>
          {I18n.t('NoInvitation.click here')}{' '}
        </Link>
        {I18n.t('NoInvitation.fill the form')}
      </Typography>
    </div>
  </section>
);
NoInvitationHeading.propTypes = {
  classes: object.isRequired,
};

export default withStyles(styles)(NoInvitationHeading);
