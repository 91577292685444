import withStyles from '@mui/styles/withStyles';
import DocumentItem from 'components/@home/drawers/ProfileDrawer/Profile/Mandatories/DocumentItem';
import Pagination from 'components/controls/Pagination';
import { object } from 'prop-types';
import React from 'react';

const styles = {
  root: {},
  list: {},
};

const Mandatories = ({ classes, employee, documents, total, page, perPage, changePage }) => {
  return (
    <div className={classes.root}>
      <div className={classes.list}>
        {documents
          .filter(d => d.attachments[0])
          .map(document => {
            const doc = { ...document.attachments, ...document };
            return (
              <DocumentItem employee={employee} key={doc._id} doc={doc}>
                {doc.title ? `${doc.title} - ${doc.attachments[0].name}` : doc.attachments[0].name}
              </DocumentItem>
            );
          })}
      </div>
      <Pagination perPage={perPage} total={total} page={page} onChange={changePage} />
    </div>
  );
};

Mandatories.propTypes = {
  classes: object.isRequired,
};

export default withStyles(styles)(Mandatories);
