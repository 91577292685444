/** @flow */
import { Alert, IconButton, MenuItem, Popover } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArticleConfigPopover from 'components/@home/@messages/ArticlePanel/ArticleConfigPopover';
import { useConfirm } from 'components/common/GlobalConfirmDialog';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import React, { useCallback, useRef } from 'react';
import { I18n } from 'utils/i18n';
import useOpenClose from 'utils/useOpenClose';

const ArticleMenu = ({ article, handleDelete }: { article: Object; handleDelete: Function }) => {
  const anchorEl = useRef();
  const [isOpen, open, close] = useOpenClose();
  const [isConfigOpen, openConfig, closeConfig, content] = useOpenClose();
  const handleOpenConfig = useCallback(
    newContent => () => {
      openConfig(newContent);
    },
    [openConfig],
  );
  const handleClose = useCallback(() => {
    close();
    setTimeout(closeConfig, 600);
  }, [close, closeConfig]);
  const { open: openConfirm, close: closeConfirm } = useConfirm();
  const confirmDelete = useCallback(() => {
    close();
    openConfirm({
      message: I18n.t('ArticleMenu.ConfirmDelete'),
      isDelete: true,
      children: (
        <Alert severity="warning">
          <Typography display="inline">{I18n.t('ArticleMenu.DeleteWarning')}</Typography>
        </Alert>
      ),
      onConfirm: () => {
        closeConfirm();
        handleDelete();
      },
    });
  }, [close, closeConfirm, openConfirm, handleDelete]);
  return (
    <>
      <IconButton
        ref={anchorEl}
        color="secondary"
        onClick={open}
        aria-label={I18n.t('ArticleMenu.More actions')}
      >
        <DotsHorizontalIcon />
      </IconButton>
      <Popover
        anchorEl={anchorEl.current}
        open={isOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
      >
        <ArticleConfigPopover isOpen={isConfigOpen} content={content} close={handleClose} />
        {!isConfigOpen && (
          <Box sx={{ py: 0.5 }}>
            <MenuItem onClick={handleOpenConfig('title/subtitle')}>
              {I18n.t('ArticleMenu.Change title/subtitle')}
            </MenuItem>
            <MenuItem onClick={handleOpenConfig('featured-image')}>
              {I18n.t('ArticleMenu.Change featured image')}
            </MenuItem>
            {/* <MenuItem onClick={close}>{I18n.t('ArticleMenu.Save in')}</MenuItem> */}
            {!article?._id || (
              <MenuItem onClick={confirmDelete}>
                <Typography color="danger">{I18n.t('ArticleMenu.Delete')}</Typography>
              </MenuItem>
            )}
          </Box>
        )}
      </Popover>
    </>
  );
};

export default ArticleMenu;
