import ChatFlow from 'components/@home/@settings/Tabs/ChatFlow';
import ChatFlowEntityType from 'components/@home/@settings/Tabs/ChatFlowEntityType';
import ChatFlowEntryFulfillment from 'components/@home/@settings/Tabs/ChatFlowEntryFulfillment';
import ChatFlowIntent from 'components/@home/@settings/Tabs/ChatFlowIntent';
import ChatFlowPage from 'components/@home/@settings/Tabs/ChatFlowPage';
import ChatFlowRoute from 'components/@home/@settings/Tabs/ChatFlowRoute';
import ChatFlowTest from 'components/@home/@settings/Tabs/ChatFlowTest';
import ChatFlowWebhook from 'components/@home/@settings/Tabs/ChatFlowWebhook';
import React, { useEffect, useState } from 'react';
import { object, string } from 'prop-types';

const RightPane = ({
  context,
  chatFlows,
  chatFlowEntityTypes,
  chatFlowIntents,
  chatFlowWebhooks,
  selectedFlow,
}) => {
  const [selectedIntent, setSelectedIntent] = useState(null);
  const [selectedWebhook, setSelectedWebhook] = useState(null);
  useEffect(() => {
    setSelectedIntent(null);
    setSelectedWebhook(null);
  }, [context.current?.route]);
  switch (context.current?.type) {
    case 'flow':
      return <ChatFlow flow={chatFlows[(context.current?.flow)]} />;
    case 'entity-type':
      return <ChatFlowEntityType entityType={chatFlowEntityTypes[(context.current?.entityType)]} />;
    case 'intent':
      return <ChatFlowIntent intent={chatFlowIntents[(context.current?.intent)]} />;
    case 'entry-fulfillment':
      return <ChatFlowEntryFulfillment key={context.current.page} flow={chatFlows[selectedFlow]} />;
    case 'test':
      return <ChatFlowTest flow={chatFlows[selectedFlow]} />;
    case 'route':
      return (
        <ChatFlowRoute
          key={context.current.route}
          selectedIntent={selectedIntent}
          selectedWebhook={selectedWebhook}
          flow={chatFlows[selectedFlow]}
          setSelectedIntent={setSelectedIntent}
          setSelectedWebhook={setSelectedWebhook}
        />
      );
    case 'page': {
      return <ChatFlowPage flow={chatFlows[selectedFlow]} />;
    }
    case 'webhook': {
      return <ChatFlowWebhook webhook={chatFlowWebhooks[(context.current?.webhook)]} />;
    }
    default:
      return null;
  }
};

RightPane.propTypes = {
  context: object,
  chatFlows: object,
  chatFlowEntityTypes: object,
  chatFlowIntents: object,
  chatFlowWebhooks: object,
  selectedFlow: string,
};

RightPane.defaultProps = {
  context: null,
  chatFlows: null,
  chatFlowEntityTypes: null,
  chatFlowIntents: null,
  chatFlowWebhooks: null,
  selectedFlow: null,
};

export default RightPane;
