const chatFlowBuiltInTypes = [
  'date',
  'daterange',
  'email',
  'ip',
  'hashtag',
  'phonenumber',
  'url',
  'number',
  'ordinal',
  'percentage',
  'dimension',
  'age',
  'currency',
  'duration',
];

export default chatFlowBuiltInTypes;
