/**
 * @flow
 */
import makeStyles from '@mui/styles/makeStyles';
import TextField from 'components/formik/TextField';
import { Field } from 'formik';
import React from 'react';

const useStyles = makeStyles(() => ({
  field: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    '& div': {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    '& textarea': {
      flexGrow: 1,
      overflow: 'scroll !important',
    },
  },
}));

type Props = {
  question: Object,
};

const QuestionFormText = ({ question }: Props) => {
  const classes = useStyles();
  return (
    <Field
      className={classes.field}
      multiline
      rows={3}
      rowsMax={20}
      component={TextField}
      type="text"
      name={`answers.${question._id}`}
    />
  );
};

export default QuestionFormText;
