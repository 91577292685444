import { IconButton, SvgIcon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from 'assets/icons/search.svg';
import SearchField from 'components/controls/SearchField';
import { func, string } from 'prop-types';
import React, { useState } from 'react';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  searchIconButton: {
    marginLeft: 5,
  },
  searchIcon: {
    fontSize: 16,
  },
}));

const Search = ({ value, onChange }) => {
  const classes = useStyles();
  const [isVisible, setVisible] = useState(false);

  const toggleVisible = () => {
    setVisible(oldValue => !oldValue);
  };

  return (
    <div className={classes.root}>
      {isVisible && <SearchField onChange={onChange} value={value} />}

      <IconButton className={classes.searchIconButton} onClick={toggleVisible} size="large">
        <SvgIcon color="primary" className={classes.searchIcon}>
          <SearchIcon />
        </SvgIcon>
      </IconButton>
    </div>
  );
};

Search.propTypes = {
  value: string,
  onChange: func.isRequired,
};
Search.defaultProps = {
  value: '',
};

export default Search;
