import {
  arrayBufferToBase64,
  base64ToArrayBuffer,
  base64ToUint8,
  encodeMessage,
  getAESCryptoKey,
  getPrivateCryptoKey,
  getPublicCryptoKey,
  toPrivatePem,
  toPublicPem,
  uIntToBase64,
} from './e2eEncryptionUtils';
import Config from './EncryptionConfig';
import subtle from './subtle';

export const generateAESPassphrase = () => {
  const K = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-';
  return Array.from(globalThis.crypto.getRandomValues(new Uint32Array(12)))
    .map(oe => K[oe % K.length])
    .join('');
};

export const generateAESKeyFromPassphrase = async (passphrase, salt, iterations) => {
  const baseKey = await subtle.importKey(
    'raw',
    new TextEncoder().encode(passphrase),
    'PBKDF2',
    false,
    ['deriveKey'],
  );
  return subtle.deriveKey(
    {
      name: Config.derive.name,
      salt,
      iterations,
      hash: Config.derive.hash,
    },
    baseKey,
    {
      name: Config.pre.name,
      length: Config.pre.length,
    },
    true,
    ['encrypt', 'decrypt'],
  );
};

export const getKeys = async () => {
  // noinspection JSVoidFunctionReturnValueUsed
  const RSA_KEY = await subtle.generateKey(
    {
      name: Config.main.name,
      modulusLength: Config.main.modulus,
      publicExponent: new Uint8Array([1, 0, 1]),
      hash: Config.main.hash,
    },
    true,
    ['encrypt', 'decrypt'],
  );
  const spkiPublicKeyFormat = Config.main.exports.public;
  const pkcs8PrivateKeyFormat = Config.main.exports.private;
  const spkiPublicKey = await subtle.exportKey(spkiPublicKeyFormat, RSA_KEY.publicKey);
  const pkcs8PrivateKey = await subtle.exportKey(pkcs8PrivateKeyFormat, RSA_KEY.privateKey);
  return {
    publicKey: toPublicPem(spkiPublicKey),
    privateKey: toPrivatePem(pkcs8PrivateKey),
  };
};

export const rsaEncryptAes = async (aesKey, publicKey) => {
  const rawAesKeyFormat = Config.pre.exports;
  const rawAesKey = arrayBufferToBase64(await subtle.exportKey(rawAesKeyFormat, aesKey));
  const encodedAes = encodeMessage(rawAesKey);
  const rsaCryptoKey = await getPublicCryptoKey(publicKey);
  const rsaEncryptedAes = await subtle.encrypt(
    {
      name: Config.main.name,
    },
    rsaCryptoKey,
    encodedAes,
  );
  return arrayBufferToBase64(rsaEncryptedAes);
};

export const rsaDecryptAes = async (aesKey, privateKey) => {
  const rsaCryptoKey = await getPrivateCryptoKey(privateKey);
  const dec = new TextDecoder();
  const aesDecrypted = await subtle.decrypt(
    {
      name: Config.main.name,
    },
    rsaCryptoKey,
    base64ToArrayBuffer(Buffer.from(aesKey, 'base64').toString('binary')),
  );
  const decodedAes = dec.decode(aesDecrypted);
  const aesCryptoKey = await getAESCryptoKey(decodedAes);
  return aesCryptoKey;
};

const getEncrypt = encode => async (plainText, aesKey) => {
  if (!plainText) {
    return plainText;
  }
  const encodedText = encode ? encodeMessage(plainText) : plainText;
  const iv = globalThis.crypto.getRandomValues(new Uint8Array(16));
  const aesEncrypted = await subtle.encrypt(
    {
      name: Config.pre.name,
      iv,
    },
    aesKey,
    encodedText,
  );
  return uIntToBase64(iv) + arrayBufferToBase64(aesEncrypted);
};

const getDecrypt = decode => async (encryptedTextAndIv, aesKey) => {
  if (!encryptedTextAndIv) {
    return encryptedTextAndIv;
  }
  const iv = base64ToUint8(encryptedTextAndIv.slice(0, 24));
  const encryptedText = base64ToArrayBuffer(
    Buffer.from(encryptedTextAndIv.slice(24), 'base64').toString('binary'),
  );
  const dec = new TextDecoder();
  const decrypted = await subtle.decrypt(
    {
      name: Config.pre.name,
      iv,
    },
    aesKey,
    encryptedText,
  );
  return decode ? dec.decode(decrypted) : decrypted;
};

export const decrypt = getDecrypt(true);
export const decryptFile = getDecrypt(false);
export const encrypt = getEncrypt(true);

export const encryptFile = async (file, aesKey) => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = async e => {
      const encrypted = await getEncrypt(false)(new Uint8Array(e.target.result), aesKey);
      resolve(
        new File([encrypted], file.name, {
          type: file.type,
          lastModified: file.lastModified,
        }),
      );
    };
    reader.readAsArrayBuffer(file);
  });
};
