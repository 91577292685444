import makeStyles from '@mui/styles/makeStyles';
import React, { useRef, useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { string } from 'prop-types';

const useStyles = makeStyles(() => ({
  text: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const OverflowTip = props => {
  const classes = useStyles();

  // Define state and function to update the value
  const [hoverStatus, setHover] = useState(false);

  // Create Ref
  const textElementRef = useRef();

  const compareSize = () => {
    const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
    setHover(compare);
  };

  // compare once and add resize listener on "componentDidMount"
  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
  }, []);

  // remove resize listener again on "componentWillUnmount"
  useEffect(
    () => () => {
      window.removeEventListener('resize', compareSize);
    },
    [],
  );

  return (
    <Tooltip title={props.value} interactive disableHoverListener={!hoverStatus}>
      <span ref={textElementRef} className={classes.text}>
        {props.value}
      </span>
    </Tooltip>
  );
};

OverflowTip.propTypes = {
  value: string.isRequired,
};

export default OverflowTip;
