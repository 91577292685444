import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import isSuperAdminSelector from 'store/selectors/isSuperAdmin';

// eslint-disable-next-line react/prop-types
const SuperAdminGate = ({ component: Component }) => {
  const isSuperAdmin = useSelector(isSuperAdminSelector);
  if (isSuperAdmin) {
    return <Component />;
  }

  return <Navigate replace to="/home/messages" />;
};

export default SuperAdminGate;
