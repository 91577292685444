import React from 'react';
import CopyField from 'components/controls/CopyField';
import { object } from 'prop-types';

const FormikCopyField = ({ form, field }) => {
  const { name } = field;
  const { getFieldProps } = form;
  const { value } = getFieldProps(name);
  return <CopyField value={value} {...field} />;
};

FormikCopyField.propTypes = {
  form: object.isRequired,
  field: object.isRequired,
};

export default FormikCopyField;
