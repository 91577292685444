/**
 * @flow
 */

import { Tooltip } from '@mui/material';
import React from 'react';
import { I18n } from 'utils/i18n';

type Props = {
  teams: Array<Object>;
  member: Object;
  children: Object;
};

const TooltipIfFromTeam = ({ teams, member, children }: Props) => {
  const fromTeams = member?.fromTeams || [];
  const teamNamesArr = fromTeams
    .map(ft => teams[ft])
    .filter(t => t)
    .map(t => t.name);
  const teamNames = teamNamesArr?.length
    ? teamNamesArr.join(', ')
    : I18n.t('ChannelDrawer.EmployeesTable.teams', { count: member?.fromTeams?.length });
  const fromTeamsTitle = I18n.t('ChannelDrawer.EmployeesTable.Subscribed from team', {
    teamNames,
    count: member?.fromTeams?.length,
  });
  return member?.fromTeams?.length ? (
    <Tooltip title={fromTeamsTitle}>
      <div>{children}</div>
    </Tooltip>
  ) : (
    <>{children}</>
  );
};

export default TooltipIfFromTeam;
