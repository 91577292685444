const getImageDimensions = meta => {
  if (meta.imageSize?.split('x')) {
    const [width, height] = meta.imageSize.split('x').map(i => +i);

    const MAX_WIDTH = 400;
    const MAX_HEIGHT = 400;

    const aspectRatio = Math.max(Math.min(width / height, 4), 0.05);
    if (width > height) {
      return {
        width: MAX_WIDTH,
        height: MAX_WIDTH / aspectRatio,
      };
    }
    return {
      width: MAX_HEIGHT * aspectRatio,
      height: MAX_HEIGHT,
    };
  }
  return {
    width: 0,
    height: 0,
  };
};

export default getImageDimensions;
