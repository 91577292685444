import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import { ChatFlowContext } from 'components/@home/@settings/Tabs/ChatFlowContext';
import AddIcon from 'mdi-react/AddIcon';
import AsteriskIcon from 'mdi-react/AsteriskIcon';
import CheckCircleOutlineIcon from 'mdi-react/CheckCircleOutlineIcon';
import CommentAccountOutlineIcon from 'mdi-react/CommentAccountOutlineIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import EditIcon from 'mdi-react/EditIcon';
import MoreVertIcon from 'mdi-react/MoreVertIcon';
import { object } from 'prop-types';
import React, { useContext, useState } from 'react';
import { Handle, useStoreActions } from 'react-flow-renderer';
import { I18n } from 'utils/i18n';

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'left',
    overflow: 'visible',
    minWidth: 200,
    '&:hover $header $menu': {
      visibility: 'visible',
    },
  },
  route: {
    position: 'relative',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    boxShadow: `0 0 0 0.5px ${theme.palette.secondary.light}`,
    borderRadius: 2,
    minHeight: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
    '&:hover $menu': {
      visibility: 'visible',
    },
  },
  handle: {
    right: -19,
  },
  item: {
    minHeight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    borderBottom: 'none',
    marginTop: 0,
    marginBottom: 0,
    marginRight: theme.spacing(4),
  },
  header: {
    backgroundColor: theme.palette.secondary.ultraLight,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    textAlign: 'center',
  },
  subHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    lineHeight: 1,
    alignItems: 'center',
  },
  title: {
    fontWeight: 'bold',
  },
  iconWrapper: {
    display: 'flex',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: -5,
    marginBottom: -7,
  },
  iconFinish: {
    display: 'flex',
    color: theme.palette.ok,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: -6,
    marginBottom: -6,
  },
  menu: {
    visibility: 'hidden',
  },
  more: {
    marginTop: -7,
    marginBottom: -5,
  },
  selected: {
    backgroundColor: theme.palette.secondary.extraLight,
  },
  asterisk: {
    marginLeft: theme.spacing(0.25),
    verticalAlign: 'super',
  },
}));

const ChatFlowNode = ({ data: { page, startPage } }) => {
  const setSelectedElements = useStoreActions(actions => actions.setSelectedElements);

  const isStart = pageId => startPage === pageId;
  const start = isStart(page._id);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpened, setMenuOpened] = useState(null);
  const { context, setToDelete } = useContext(ChatFlowContext);
  const onSelectRoute = route => () => {
    context.set({ type: 'route', page: page._id, route });
  };
  const onSelectEntryFulfillment = () => {
    context.set({ page: page._id, type: 'entry-fulfillment' });
  };
  const addRoute = () => {
    context.set({ type: 'route', page: page._id, route: 'new' });
  };
  const deleteElement = e => {
    e.stopPropagation();
    const element = { ...isMenuOpened };
    setMenuOpened(null);
    setToDelete(element);
  };
  const editElement = e => {
    e.stopPropagation();
    setMenuOpened(null);
    if (isMenuOpened.page) {
      context.set({
        type: 'route',
        route: isMenuOpened._id,
        page: isMenuOpened.page,
      });
    } else {
      context.set({
        type: 'page',
        page: page._id,
      });
    }
  };
  const handleHover = id => () => {
    setSelectedElements(id ? [{ type: 'route', id }] : []);
  };
  const fulfillmentSel = context.stack?.find(
    c => c.page === page._id && c.type === 'entry-fulfillment',
  );
  return (
    <Card className={classes.root} variant="outlined">
      {!start && <Handle type="target" position="left" isConnectable={false} />}
      <CardHeader
        className={classes.header}
        title={page.name}
        titleTypographyProps={{ variant: 'body1', classes: { root: classes.title } }}
        action={
          !start && (
            <IconButton
              size="small"
              aria-label="settings"
              onClick={e => {
                setAnchorEl(e.target);
                setMenuOpened(page);
              }}
              className={classes.menu}
            >
              <MoreVertIcon />
            </IconButton>
          )
        }
      />
      <CardContent>
        <List>
          <ListSubheader disableGutters classes={{ root: classes.subHeader }}>
            <div>{I18n.t('ChatFlowSettings.Entry fulfillment')}</div>
          </ListSubheader>
          <ListItem
            button
            onClick={onSelectEntryFulfillment}
            className={classnames(classes.route, { [classes.selected]: fulfillmentSel })}
          >
            <ListItemText classes={{ root: classes.item }}>
              {I18n.t('ChatFlowSettings.Edit fulfillment')}
            </ListItemText>
          </ListItem>
          <ListSubheader disableGutters classes={{ root: classes.subHeader }}>
            <div>{I18n.t('ChatFlowSettings.Routes')}</div>
            <div>
              <IconButton size="small" edge="end" aria-label="add" onClick={addRoute}>
                <AddIcon />
              </IconButton>
            </div>
          </ListSubheader>
          {page.routes.map(route => {
            const name = route.intent?.name || I18n.t('ChatFlowSettings.Default route');
            const hasCondition = !!route.condition;
            const selected = context.stack?.find(c => c.route === route._id);
            return (
              <ListItem
                key={route._id}
                button
                onClick={onSelectRoute(route._id)}
                className={classnames(classes.route, { [classes.selected]: selected })}
                onFocus={handleHover(route._id)}
                onBlur={handleHover()}
                onMouseOver={handleHover(route._id)}
                onMouseLeave={handleHover()}
              >
                <ListItemText classes={{ root: classes.item }}>
                  {name}
                  {hasCondition && (
                    <Tooltip title={route.condition}>
                      <span className={classes.asterisk}>
                        <AsteriskIcon size={8} />
                      </span>
                    </Tooltip>
                  )}
                </ListItemText>
                <div className={classes.menu}>
                  <IconButton
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setAnchorEl(e.target);
                      setMenuOpened({ ...route, name, page: page._id });
                    }}
                    size="small"
                    className={classes.more}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </div>
                {route.handoffTo && (
                  <Tooltip title={route.handoffTo.name}>
                    <div className={classes.iconWrapper}>
                      <CommentAccountOutlineIcon />
                    </div>
                  </Tooltip>
                )}
                {isStart(route.transition) && (
                  <div className={classes.iconFinish}>
                    <CheckCircleOutlineIcon />
                  </div>
                )}
                {route.transition && !isStart(route.transition) && (
                  <Handle
                    className={classes.handle}
                    type="source"
                    position="right"
                    id={route._id}
                    isConnectable={false}
                  />
                )}
              </ListItem>
            );
          })}
        </List>
      </CardContent>
      <Menu anchorEl={anchorEl} open={!!isMenuOpened} onClose={() => setMenuOpened(null)}>
        <MenuItem onClick={editElement}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <Typography variant="inherit">{I18n.t('ChatFlowSettings.Edit')}</Typography>
        </MenuItem>
        <MenuItem onClick={deleteElement}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <Typography variant="inherit">{I18n.t('ChatFlowSettings.Delete')}</Typography>
        </MenuItem>
      </Menu>
    </Card>
  );
};

ChatFlowNode.propTypes = {
  data: object.isRequired,
};

export default ChatFlowNode;
