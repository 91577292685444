import { Avatar, SvgIcon } from '@mui/material';
import Box from '@mui/material/Box';
import UploadIcon from 'assets/icons/upload.svg';
import Loading from 'components/common/Loading';
import { bool, func, node, number, object, string } from 'prop-types';
import React from 'react';

const styles = {
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'secondary.ultraLight',
    backgroundPosition: 'center',
    width: 80,
    color: 'rgba(0,0,0,0.8)',
    height: 80,
    borderRadius: 2,
    backgroundSize: '100%',
  },
  uploadIcon: {
    fontSize: 35,
    color: 'secondary.light',
    height: 'auto',
  },
  loading: {
    fontSize: 35,
    color: 'white',
    height: 'auto',
  },
  errorIcon: {
    fontSize: 35,
    color: 'error.main',
  },
  successIcon: {
    fontSize: 35,
    color: 'primary.main',
  },
  progressContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.05)',
    },
    cursor: 'pointer',
  },
  circle: {
    borderRadius: '100%',
  },
  imageWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    '&:after': {
      pointerEvents: 'none',
      position: 'absolute',
      content: `""`,
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%)',
    },
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: 1,
    objectFit: 'cover',
  },
  bright: {
    color: 'secondary.extraLight',
  },
};

const Thumbnail = ({ src, onClick, circle, progress, sx, children }) => {
  return (
    <Avatar
      sx={{
        ...styles.root,
        ...(circle && styles.circle),
        ...sx,
      }}
      onClick={onClick}
    >
      {src && (
        <Box sx={styles.imageWrapper}>
          <Box component="img" alt="" src={src} sx={styles.image} />
        </Box>
      )}
      <Box sx={styles.progressContainer}>
        {Number.isFinite(progress) && progress < 100 ? (
          <Loading value={progress} size={35} sx={styles.loading} color="white" />
        ) : (
          <SvgIcon sx={{ ...styles.uploadIcon, ...(src ? styles.bright : {}) }}>
            <UploadIcon />
          </SvgIcon>
        )}
        {children}
      </Box>
    </Avatar>
  );
};

Thumbnail.propTypes = {
  circle: bool.isRequired,
  src: string.isRequired,
  onClick: func.isRequired,
  progress: number,
  sx: object,
  children: node,
};

Thumbnail.defaultProps = {
  progress: null,
  sx: {},
  children: null,
};

export default Thumbnail;
