import { LIST_ADMINS_FULFILLED, LIST_ADMINS_PENDING } from 'store/app/entities/admins/action';

const adminsReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case LIST_ADMINS_PENDING: {
      return {
        ...state,
        loadedAt: null,
        loading: true,
      };
    }

    case LIST_ADMINS_FULFILLED: {
      return {
        ...state,
        employees: payload,
        loading: false,
        loadedAt: Date.now(),
      };
    }

    default:
      return state;
  }
};

export default adminsReducer;
