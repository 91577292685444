/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import { Box, IconButton } from '@mui/material';
import CloseIcon from 'mdi-react/CloseIcon';
import MenuIcon from 'mdi-react/MenuIcon';
import React from 'react';
import { useSelector } from 'react-redux';
import headerActions from 'store/app/ui/header/action';
import useActions from 'utils/useActions';

const Menu = () => {
  const header = useActions(headerActions);
  const channelsPannelOpen = useSelector(state => state.header.channelsPannelOpen);

  const openSidePanel = () => {
    header.toggleChannelsPanel();
  };

  return (
    <Box
      sx={{
        paddingRight: {
          sx: 10,
          md: 0,
        },
        display: {
          md: 'none',
        },
      }}
    >
      <IconButton color="inherit" size="large" onClick={openSidePanel}>
        {channelsPannelOpen ? <CloseIcon /> : <MenuIcon />}
      </IconButton>
    </Box>
  );
};

export default Menu;
