import { Box, Breadcrumbs, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import BulkMessagingToolbar from 'components/@home/@documents/BulkMessaging/BulkMessagingToolbar';
import OmmnioDataGrid from 'components/common/OmmnioDataGrid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'utils/i18n';
import { Link, useLocation, useParams } from 'react-router-dom';
import { HR_BOT_APP_ID } from 'services/constants';
import appsAction from 'store/app/entities/apps/action';
import { useListBulkMessagingQuery } from 'store/app/entities/SharedFilesSlice';
import employeesWithUser from 'store/selectors/employeesWithUser';
import arrayToObject from 'utils/arrayToObject';
import renderDateTimeCell from 'utils/renderDateTimeCell';

const BulkMessaging = ({ folder }) => {
  const employees = useSelector(employeesWithUser);
  const employeesByUser = arrayToObject(employees, 'user._id');
  const { id } = useParams();
  const [perPage, setPerPage] = useState(10);
  const [sortModel, setSortModel] = useState(undefined);
  const [filterModel, setFilterModel] = useState(undefined);
  const [page, setPage] = useState(0);
  const search = filterModel?.quickFilterValues.join(' ');
  const location = useLocation();
  const dispatch = useDispatch();
  const app = useSelector(state => state.apps.apps?.[HR_BOT_APP_ID]);
  useEffect(() => {
    if (!app?._id) {
      dispatch(appsAction.load());
    }
  }, [app?._id]);
  const {
    data: { bulkMessaging, totalCount, documentFormat } = {},
    isFetching,
    isUninitialized,
  } = useListBulkMessagingQuery({
    search,
    page: page + 1,
    perPage,
    id,
    sortBy: sortModel?.[0]?.field || 'createdAt',
    sort: sortModel?.[0]?.sort || 'desc',
  });
  const columns = [
    {
      field: 'createdAt',
      flex: 1,
      editable: false,
      renderCell: renderDateTimeCell,
      headerName: I18n.t('BulkMessaging.Table.createdAt'),
    },
    {
      field: 'user',
      flex: 1,
      editable: false,
      renderCell: params =>
        params.row.employeeName ||
        employeesByUser?.[params.value]?.name ||
        I18n.t('BulkMessaging.Not found'),
      headerName: I18n.t('BulkMessaging.Table.user'),
    },
    {
      field: 'sent',
      type: 'number',
      editable: false,
      maxWidth: 150,
      marginRight: 100,
      headerName: I18n.t('BulkMessaging.Table.sent'),
    },
  ];
  return (
    <Grid
      item
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <BulkMessagingToolbar documentFormat={documentFormat} />
      <Box
        sx={{
          p: 2,
          pb: 0,
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
            color="inherit"
            to={{
              pathname: `/home/documents/${folder}/`,
              search: location.state?.search,
            }}
          >
            <Typography variant="h3" sx={{ fontWeight: 'normal' }}>
              {I18n.t(`DocumentsScene.Folders.${folder}`)}
            </Typography>
          </Link>
          <Typography variant="h3">{documentFormat?.name}</Typography>
        </Breadcrumbs>
        <OmmnioDataGrid
          loading={isFetching || isUninitialized}
          sx={{ marginTop: 2 }}
          columns={columns}
          rows={bulkMessaging}
          paginationMode="server"
          getRowId={r => r._id}
          rowCount={totalCount || 0}
          sortingMode="server"
          filterMode="server"
          page={page}
          sortModel={sortModel}
          filterModel={filterModel}
          onPageChange={setPage}
          onPageSizeChange={setPerPage}
          onFilterModelChange={setFilterModel}
          onSortModelChange={setSortModel}
          defaultSortModel={[{ field: 'createdAt', sort: 'desc' }]}
          updateHistory
          disableEvenOdd
        />
      </Box>
    </Grid>
  );
};
export default BulkMessaging;
