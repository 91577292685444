const listToTree = (teams = {}) => {
  const nodeMap = Object.values(teams).reduce(
    (acc, curr) => ({
      ...acc,
      [curr._id]: { ...curr, id: curr._id, label: curr.name, children: [] },
    }),
    {},
  );
  const rootNodes = [];

  Object.values(nodeMap).forEach(currentNode => {
    const hasParentInMap = Object.prototype.hasOwnProperty.call(nodeMap, currentNode.parent);
    if (hasParentInMap) {
      nodeMap[currentNode.parent].children.push(currentNode);
    } else {
      rootNodes.push(currentNode);
    }
  });
  return rootNodes;
};

export default listToTree;
