/**
 * @flow
 */
import makeStyles from '@mui/styles/makeStyles';
import AddQuestionButton from 'components/@home/drawers/FormDrawer/AddQuestionButton';
import FormDrawerQuestionsEmpty from 'components/@home/drawers/FormDrawer/FormDrawerQuestionsEmpty';
import QuestionForm from 'components/@home/drawers/FormDrawer/QuestionForm';
import React from 'react';
import type { Question } from './Types';

type Props = {
  form: {
    values: { questions: Array<Question> },
    isValid: boolean,
    submitCount: number,
    errors: Object,
  },
  remove: Function,
  push: Function,
};

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
  },
}));

const FormDrawerQuestions = ({ form: { values, errors }, remove, push }: Props) => {
  const classes = useStyles();
  const disableAdd = errors?.questions?.some(q => q?.title || q?.options);
  const onAddQuestion = type => {
    const _id = Math.random().toString();
    push({ _id, title: '', type, options: [{ label: '' }] });
  };

  if (values.questions.length === 0) {
    return <FormDrawerQuestionsEmpty onAddQuestion={onAddQuestion} />;
  }

  return (
    <div className={classes.root}>
      {values.questions.map((question, index) => (
        <QuestionForm key={question._id} index={index} question={question} remove={remove} />
      ))}
      <AddQuestionButton disabled={disableAdd} onAddQuestion={onAddQuestion} />
    </div>
  );
};

export default FormDrawerQuestions;
