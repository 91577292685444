/**
 * @flow
 */
import React from 'react';
import XMLViewer from './XMLViewer';

type Props = {
  xml: String;
  onClick: Function;
};

const XmlPreview = ({ xml, onClick }: Props) => {
  return (
    <div>
      <XMLViewer xml={xml} onClick={onClick} />
    </div>
  );
};

export default XmlPreview;
