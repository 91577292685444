import imageCompression from 'browser-image-compression';

const compressImages = async files => {
  const images = await Promise.all(
    files.map(async (file, idx) => {
      if (file.type.startsWith('image/')) {
        const options = {
          maxWidthOrHeight: 1600,
          useWebWorker: true,
          fileType: 'image/jpeg',
          quality: 1,
        };
        const blob = await imageCompression(file, options);
        const dataUrl = await imageCompression.getDataUrlFromFile(blob);
        return new Promise(resolve => {
          const image = new Image();
          image.src = dataUrl;
          image.onload = () => {
            resolve({
              idx,
              file,
              src: dataUrl,
              origSrc: dataUrl,
              croppedSrc: dataUrl,
              width: image.width,
              height: image.height,
              aspectRatio: image.width / image.height,
              croppingBox: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                width: image.width,
                height: image.height,
                scale: 1,
                aspectRatio: image.width / image.height,
              },
            });
          };
        });
      }
      return Promise.resolve({
        idx,
        file,
      });
    }),
  );
  return images;
};

export default compressImages;
