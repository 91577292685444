import { Drawer } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import moment from 'moment';
import { arrayOf, object } from 'prop-types';
import React, { useEffect } from 'react';
import Mandatory from 'services/api/Mandatory';
import channelShape from 'shapes/channel';
import employeeShape from 'shapes/employee';
import CloseButton from '../CloseButton';
import Analytics from './Analytics';

import connector from './connector';
import Headline from './Headline';

const styles = theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 1000,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: 20,
  },
  closeButtonContainer: {
    paddingTop: 20,
    paddingLeft: 20,
  },
});

const AnalyticsDrawer = ({
  classes,
  actions,
  channel,
  employee,
  members,
  analyticsDrawer: { isOpen, mandatory },
}) => {
  useEffect(() => {
    if (isOpen && channel?._id && !channel?.isDirect) {
      actions.channels.find(channel._id);
    }
  }, [channel?._id, actions, isOpen, channel?.isDirect]);

  const handleClose = () => {
    actions.drawer.analyticsDrawer.close();
  };

  const openModal = message => () => {
    actions.acknowledgmentReadModal.open(message, channel, employee);
  };

  const handleExport = async () => {
    if (!mandatory) {
      return;
    }
    const { acknowledges } = await Mandatory.find(mandatory._id, { perPage: 100000 });
    const XLSX = await import('xlsx');
    const rows = channel.employees.map(e => {
      const ack = acknowledges.find(a => a.user === e.user_id);
      return [e.firstName, e.surName, e.code, ack ? new Date(ack.confirmedAt * 1000) : ''];
    });
    const data = [['givenName', 'familyName', 'code', 'confirmedAt'], ...rows];
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Logs');
    const now = moment();
    XLSX.writeFile(wb, `${now.format('YYYY_MM_DD')}_${mandatory.text}.xlsx`, {
      bookType: 'xlsx',
      type: 'array',
    });
  };

  if (!mandatory) return null;

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose}>
      <div className={classes.root}>
        <div className={classes.closeButtonContainer}>
          <CloseButton onClick={handleClose} />
        </div>
        <div className={classes.container}>
          <Headline onOpen={openModal(mandatory)} onExport={handleExport} name={mandatory.text} />
          <Analytics mandatoryId={mandatory._id} message={mandatory} />
        </div>
      </div>
    </Drawer>
  );
};

AnalyticsDrawer.propTypes = {
  channel: channelShape.isRequired,
  employee: employeeShape.isRequired,
  classes: object.isRequired,
  actions: object.isRequired,
  analyticsDrawer: object.isRequired,
  members: arrayOf(employeeShape).isRequired,
};

export default withStyles(styles)(connector(AnalyticsDrawer));
