import { useDispatch, useSelector } from 'react-redux';
import Employee from 'services/api/Employee';
import Settings from 'services/api/Settings';
import authCompany from 'store/selectors/authCompany';
import authCompanyId from 'store/selectors/authCompanyId';
import employeesStatus from 'store/selectors/employeesStatus';

export const ADD_EMPLOYEE = 'ADD_EMPLOYEE';
export const BLOCK_EMPLOYEE = 'BLOCK_EMPLOYEE';
export const BLOCK_EMPLOYEE_FULFILLED = 'BLOCK_EMPLOYEE_FULFILLED';
export const BLOCK_EMPLOYEE_PENDING = 'BLOCK_EMPLOYEE_PENDING';
export const CREATE_EMPLOYEE = 'CREATE_EMPLOYEE';
export const CREATE_EMPLOYEE_FULFILLED = 'CREATE_EMPLOYEE_FULFILLED';
export const CREATE_IMPORT = 'CREATE_IMPORT';
export const DESTROY_EMPLOYEE = 'DESTROY_EMPLOYEE';
export const DESTROY_EMPLOYEE_FULFILLED = 'DESTROY_EMPLOYEE_FULFILLED';
export const DESTROY_EMPLOYEE_PENDING = 'DESTROY_EMPLOYEE_PENDING';
export const FIND_VISIBLE_EMPLOYEES = 'FIND_VISIBLE_EMPLOYEES';
export const FIND_VISIBLE_EMPLOYEES_FULFILLED = 'FIND_VISIBLE_EMPLOYEES_FULFILLED';
export const FIND_VISIBLE_EMPLOYEES_PENDING = 'FIND_VISIBLE_EMPLOYEES_PENDING';
export const FIND_VISIBLE_EMPLOYEES_REJECTED = 'FIND_VISIBLE_EMPLOYEES_REJECTED';
export const IMPORT_DRY_RUN_FINISHED = 'IMPORT_DRY_RUN_FINISHED';
export const LOAD_EMPLOYEE = 'LOAD_EMPLOYEE';
export const LOAD_EMPLOYEE_FULFILLED = 'LOAD_EMPLOYEE_FULFILLED';
export const LOAD_EMPLOYEE_PENDING = 'LOAD_EMPLOYEE_PENDING';
export const LOAD_EMPLOYEE_REJECTED = 'LOAD_EMPLOYEE_REJECTED';
export const MANDATORIES = 'MANDATAORIES';
export const MANDATORIES_FULFILLED = 'MANDATAORIES_FULFILLED';
export const MODIFY_EMPLOYEE = 'MODIFY_EMPLOYEE';
export const PREVIEW_CHANNELS = 'PREVIEW_CHANNELS';
export const REMOVE_EMPLOYEE = 'DELETE_EMPLOYEE';
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const UPDATE_EMPLOYEE_FULFILLED = 'UPDATE_EMPLOYEE_FULFILLED';
export const UPDATE_EMPLOYEE_PENDING = 'UPDATE_EMPLOYEE_PENDING';
export const UPDATE_EMPLOYEE_SETTINGS = 'UPDATE_EMPLOYEE_SETTINGS';
export const UPDATE_EMPLOYEE_SETTINGS_FULFILLED = 'UPDATE_EMPLOYEE_SETTINGS_FULFILLED';
export const UPDATE_EMPLOYEE_SETTINGS_PENDING = 'UPDATE_EMPLOYEE_SETTINGS_PENDING';

const add = (_id, employee) => ({
  type: ADD_EMPLOYEE,
  payload: employee,
  meta: { _id },
});

const create = form => ({
  type: CREATE_EMPLOYEE,
  payload: Employee.create(form),
});

const destroy = employeeId => ({
  type: DESTROY_EMPLOYEE,
  payload: Employee.destroy(employeeId),
  meta: { employee_id: employeeId },
});

const load = employeeId => ({
  type: LOAD_EMPLOYEE,
  payload: Employee.load(employeeId),
  meta: { employee_id: employeeId },
});

const mandatories = (employeeId, page = 1, perPage = 10, sortBy = 'createdAt', sort = 'desc') => ({
  type: MANDATORIES,
  payload: Employee.mandatories(employeeId, { page, perPage, sortBy, sort }),
  meta: { employee_id: employeeId },
});

const modify = (_id, data) => ({
  type: MODIFY_EMPLOYEE,
  payload: data,
  meta: { _id },
});

const remove = employeeId => ({
  type: REMOVE_EMPLOYEE,
  payload: employeeId,
  meta: { employee_id: employeeId },
});

const update = (_id, data) => (dispatch, getState) => {
  const {
    employees,
    auth: { employee_id: authEmployeeId },
  } = getState();
  const newEmployee = { ...employees[_id], ...data };

  return dispatch({
    type: UPDATE_EMPLOYEE,
    payload: Employee.update(newEmployee._id, data),
    meta: { employee: newEmployee, isMe: authEmployeeId === newEmployee._id },
  });
};

const block = (employeeId, isActive) => {
  return {
    type: BLOCK_EMPLOYEE,
    payload: Employee.block(employeeId, isActive),
    meta: { employeeId },
  };
};

const updateSettings = (employeeId, settings) => ({
  type: UPDATE_EMPLOYEE_SETTINGS,
  payload: Settings.update(employeeId, settings),
  meta: {
    employee_id: employeeId,
    settings,
  },
});

const createImport = file => ({
  type: CREATE_IMPORT,
  payload: Employee.createImport(file),
  meta: { file },
});

const previewChannels = (employeeId, data) => ({
  type: PREVIEW_CHANNELS,
  payload: Employee.previewChannels(employeeId, data),
  meta: { employeeId },
});

const importDryRunFinished = payload => ({
  type: IMPORT_DRY_RUN_FINISHED,
  payload,
});

export default {
  add,
  block,
  create,
  createImport,
  destroy,
  importDryRunFinished,
  load,
  mandatories,
  modify,
  previewChannels,
  remove,
  update,
  updateSettings,
};
