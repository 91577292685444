import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { object, shape } from 'prop-types';
import React, { Component } from 'react';
import { I18n } from 'utils/i18n';
import messageShape from 'shapes/message';
import connector from './connector';

const styles = {
  root: {
    minWidth: '110px',
    fontSize: '15px',
    color: '#000',
  },
};

class EditDraftButton extends Component {
  handleEdit = () => {
    const { actions, message } = this.props;
    actions.acknowledgmentPreviewModal.open(message);
  };

  render() {
    const { classes } = this.props;
    return (
      <Button
        color="secondary"
        variant="outlined"
        className={classes.root}
        onClick={this.handleEdit}
      >
        {I18n.t('AcknowledgementMessage.Edit draft')}
      </Button>
    );
  }
}

EditDraftButton.propTypes = {
  classes: object.isRequired,
  message: messageShape.isRequired,
  actions: shape({
    acknowledgmentPreviewModal: object.isRequired,
  }).isRequired,
};

export default withStyles(styles)(connector(EditDraftButton));
