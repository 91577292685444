import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import channels from 'store/app/entities/channels/action';
import forms from 'store/app/entities/forms/action';
import messages from 'store/app/entities/messages/action';
import formAnalytics from 'store/app/ui/drawers/formAnalytics/action';
import authCompany from 'store/selectors/authCompany';
import authEmployee from 'store/selectors/authEmployee';
import authUser from 'store/selectors/authUser';
import currentChannel from 'store/selectors/currentChannel';
import getForm from 'store/selectors/getForm';

const mapStateToProps = state => {
  const { message } = state.drawers.formAnalytics;
  const formId = message?.form;
  const form = (formId && getForm(state, { formId })) || {};
  return {
    message,
    isOpen: state.drawers.formAnalytics.isOpen,
    channel: currentChannel(state),
    authCompany: authCompany(state),
    authEmployee: authEmployee(state),
    otherEmployee:
      form && Object.values(state.employees.employees).find(e => e.user_id === form.channel),
    authUser: authUser(state),
    formId,
    form,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    forms: bindActionCreators(forms, dispatch),
    channels: bindActionCreators(channels, dispatch),
    drawers: {
      formAnalytics: bindActionCreators(formAnalytics, dispatch),
    },
    messages: bindActionCreators(messages, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
