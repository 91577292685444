import { FLOPFLIP_STATE_SLICE, flopflipReducer } from '@flopflip/react-redux';
import { i18nReducer } from 'react-redux-i18n';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist-indexeddb-storage';
import { analyticsReducer } from 'store/app/entities/AnalyticsSlice';
import { employeesApiReducer } from 'store/app/entities/EmployeesApiSlice';
import { wbReducer } from 'store/app/entities/WbSlice';
import { firebaseBeaconReducer, gaBeaconReducer } from 'utils/reduxBeaconConfig';
import auth from './auth/reducer';
import authConfig from './authConfig/reducer';
import admins from './entities/admins/reducer';
import { appDataReducer } from './entities/AppDataSlice';
import apps from './entities/apps/reducer';
import { articlesReducer } from './entities/ArticlesSlice';
import bulkMessaging from './entities/bulkMessaging/reducer';
import channels from './entities/channels/reducer';
import chatFlowEntityTypes from './entities/chatFlowEntityTypes/reducer';
import chatFlowIntents from './entities/chatFlowIntents/reducer';
import chatFlows from './entities/chatFlows/reducer';
import chatFlowWebhooks from './entities/chatFlowWebhooks/reducer';
import companies from './entities/companies/reducer';
import { companiesReducer } from './entities/CompaniesSlice';
import config from './entities/config';
import { contentsReducer } from './entities/ContentsSlice';
import documentFormats from './entities/documentFormats/reducer';
import employeeImports from './entities/employeeImports/reducer';
import employees from './entities/employees/reducer';
import forms from './entities/forms/reducer';
import { groupsReducer } from './entities/GroupsSlice';
import linkPreviews from './entities/linkPreviews/reducer';
import messages from './entities/messages/reducer';
import settings from './entities/settings/reducer';
import { sharedFilesReducer } from './entities/SharedFilesSlice';
import subscriptions from './entities/subscriptions/reducer';
import { teamMembershipRulesReducer } from './entities/TeamMembershipRulesSlice';

import teams from './entities/teams/reducer';
import { teamsReducer } from './entities/TeamsSlice';
import timeTrackerLog from './entities/timeTrackerLog/reducer';
import users from './entities/users/reducer';
import { usersReducer } from './entities/UsersSlice';
import ui from './ui';
import drawers from './ui/drawers';
import header from './ui/header/reducer';
import login from './ui/login/reducer';
import media from './ui/media/reducer';
import modals from './ui/modals';

const persistConfig = {
  key: 'root',
  storage: storage('ommnio'),
  version: 1,
  whitelist: [],
  migrate: () => {
    return Promise.resolve({});
  },
};

const createRootReducer = () =>
  persistReducer(
    persistConfig,
    combineReducers({
      [FLOPFLIP_STATE_SLICE]: flopflipReducer,
      admins,
      analytics: analyticsReducer,
      apps,
      appData: appDataReducer,
      articles: articlesReducer,
      auth,
      authConfig,
      bulkMessaging,
      channels,
      chatFlowEntityTypes,
      chatFlowIntents,
      chatFlowWebhooks,
      chatFlows,
      companies,
      companiesReducer,
      contents: contentsReducer,
      wbSlice: wbReducer,
      config,
      documentFormats,
      drawers,
      elements: ui,
      employeeImports,
      employees,
      employeesApi: employeesApiReducer,
      firebaseBeaconReducer,
      forms,
      gaBeaconReducer,
      groups: groupsReducer,
      header,
      i18n: i18nReducer,
      linkPreviews,
      login,
      media,
      messages,
      modals,
      settings,
      sharedFiles: sharedFilesReducer,
      subscriptions,
      usersReducer,
      teamMembershipRules: teamMembershipRulesReducer,
      teams,
      teamsReducer,
      timeTrackerLog,
      users,
    }),
  );

export default createRootReducer;
