import React from 'react';
import { string } from 'prop-types';

const YoutubeIFrame = ({ url }) => (
<iframe
    title="youtube"
    width="100%"
    height="300"
    src={`https://www.youtube.com/embed/${url.split('v=')[1].substring(0, 11)}`}
    frameBorder="0"
    allow="autoplay; encrypted-media"
    allowFullScreen
  />
);YoutubeIFrame.propTypes = {
  url: string.isRequired,
};

export default YoutubeIFrame;
