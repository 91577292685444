import { Box, Button, IconButton, Toolbar, Typography } from '@mui/material';
import UserDrawer from 'components/@admin/@users/UserDrawer';
import ComposedIcon from 'components/common/ComposedIcon';
import DeleteDialog from 'components/common/DeleteDialog';
import LineClampTooltip from 'components/common/LineClampTooltip';
import OmmnioDataGrid from 'components/common/OmmnioDataGrid';
import AddBoldIcon from 'mdi-react/AddBoldIcon';
import CompanyIcon from 'mdi-react/CompanyIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import EditIcon from 'mdi-react/EditIcon';
import IncognitoIcon from 'mdi-react/IncognitoIcon';
import LockIcon from 'mdi-react/LockIcon';
import LockOpenOutlineIcon from 'mdi-react/LockOpenOutlineIcon';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { I18n } from 'utils/i18n';
import { useNavigate } from 'react-router-dom';
import Cookie from 'services/Cookie';
import authActions from 'store/app/auth/action';
import {
  useDeleteUserMutation,
  useEditUserMutation,
  useListUsersQuery,
} from 'store/app/entities/UsersSlice';
import formatPhoneNumber from 'utils/formatPhoneNumber';
import renderDateTimeCell from 'utils/renderDateTimeCell';
import useActions from 'utils/useActions';
import useOpenClose from 'utils/useOpenClose';

const defaultSortModel = [{ field: 'createdAt', sort: 'desc' }];

const PhoneNumber = ({ value }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  useEffect(() => {
    formatPhoneNumber(value, setPhoneNumber);
  }, [value]);
  return phoneNumber;
};

const AdminUsersScene = () => {
  const [user, setUser] = useState(undefined);
  const [isDeleteConfirmOpen, openDeleteConfirm, closeDeleteConfirm, { companyToRemove }] =
    useOpenClose();
  const userIsOpen = !!user;
  const handleUserClose = () => {
    setUser(undefined);
  };
  const [perPage, setPerPage] = useState(0);
  const [sortModel, setSortModel] = useState(defaultSortModel);
  const [filterModel, setFilterModel] = useState(undefined);
  const [page, setPage] = useState(0);
  const search = filterModel?.quickFilterValues.join(' ');
  const { data = {}, isFetching } = useListUsersQuery({
    search,
    page: page + 1,
    perPage,
    sortBy: sortModel?.[0]?.field || 'name',
    sort: sortModel?.[0]?.sort || 'asc',
  });
  const [editUser] = useEditUserMutation();
  const [deleteUser] = useDeleteUserMutation();

  const flushUserData = useActions(authActions.flushUserData);
  const navigate = useNavigate();
  const impersonate = u => e => {
    e.preventDefault();
    e.stopPropagation();
    Cookie.set('impersonate', u._id);
    flushUserData();
    navigate('/home/messages');
    setTimeout(() => {
      window.location.reload();
    }, 300);
  };

  const handleLock = (_id, isActive) => () => {
    editUser({ _id, body: { isActive: isActive ? 1 : 0 } });
  };

  const confirmDelete = () => {
    closeDeleteConfirm();
    deleteUser(companyToRemove._id);
  };

  const columns = [
    {
      field: 'phone',
      width: 140,
      editable: false,
      headerName: I18n.t('AdminUsersScene.DocumentsTable.Phone'),
      renderCell: params => <PhoneNumber value={params.value} />,
    },

    {
      field: 'createdAt',
      type: 'string',
      width: 140,
      editable: false,
      maxWidth: 200,
      renderCell: params => moment.unix(params.value).format('L'),
      headerName: I18n.t('AdminUsersScene.DocumentsTable.CreatedAt'),
    },
    {
      field: 'isActive',
      sortable: false,
      width: 140,
      editable: false,
      renderCell: params => (params.value ? 'Active' : 'Blocked'),
      headerName: I18n.t('AdminUsersScene.DocumentsTable.Status'),
    },
    {
      field: 'lastSeen',
      sortable: false,
      width: 140,
      editable: false,
      renderCell: params =>
        params.row.activeSocketNamespaces && params.row.activeSocketNamespaces.length ? (
          'Online'
        ) : (
          <Box sx={{ whiteSpace: 'normal' }}>{renderDateTimeCell(params)}</Box>
        ),
      headerName: I18n.t('AdminUsersScene.DocumentsTable.LastSeen'),
    },
    {
      field: 'companies',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: params => (
        <LineClampTooltip>
          <Box sx={{ whiteSpace: 'normal' }}>{params.value.map(c => c.name).join(', ')}</Box>
        </LineClampTooltip>
      ),
      headerName: I18n.t('AdminUsersScene.DocumentsTable.Companies'),
    },
  ];

  columns.push({
    field: 'actions',
    flex: 1,
    type: 'actions',
    editable: false,
    sortable: false,
    renderCell: params => (
      <Box sx={{ display: 'flex', gap: 2 }}>
        <IconButton onClick={impersonate(params.row)}>
          <IncognitoIcon />
        </IconButton>
        <IconButton onClick={() => setUser(params.row)}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={handleLock(params.row._id, !params.row.isActive)}>
          {params.row.isActive ? <LockIcon /> : <LockOpenOutlineIcon />}
        </IconButton>
        <IconButton
          sx={{ color: 'danger' }}
          onClick={() => {
            openDeleteConfirm({ companyToRemove: params.row });
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    ),
    headerName: I18n.t('AdminUsersScene.DocumentsTable.Actions'),
  });

  const rows = data?.users || [];
  return (
    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <Toolbar
        sx={{
          minHeight: '0 !important',
          overflow: 'hidden',
          boxShadow: 'inset 0 -0.5px 0 0 rgba(2, 2, 3, 0.2);',
          '& .MuiButton-root': {
            borderRadius: 0,
            minHeight: 49,
            whiteSpace: 'nowrap',
            '& .MuiCircularProgress-root': {
              marginLeft: 1,
            },
          },
        }}
      >
        <Button variant="text" color="secondary" onClick={() => setUser({})}>
          <ComposedIcon size={18} icon={<CompanyIcon />} extraIcon={<AddBoldIcon />} />

          {I18n.t('SuperAdminMenu.New user')}
        </Button>
      </Toolbar>
      <Box sx={{ padding: 2, paddingBottom: 5, flex: 1, display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h3">{I18n.t('SuperAdminMenu.users')}</Typography>
        <OmmnioDataGrid
          loading={isFetching}
          sx={{ marginTop: 2 }}
          columns={columns}
          rows={rows}
          rowCount={data?.totalCount || 0}
          paginationMode="server"
          getRowId={r => r._id}
          sortingMode="server"
          filterMode="server"
          filterModel={filterModel}
          onFilterModelChange={setFilterModel}
          onSortModelChange={setSortModel}
          onPageChange={setPage}
          onPageSizeChange={setPerPage}
          updateHistory
          disableEvenOdd
          defaultSortModel={defaultSortModel}
        />
        <UserDrawer onClose={handleUserClose} isOpen={userIsOpen} user={user} />
      </Box>
      <DeleteDialog
        message={I18n.t('AdminUsersScene.ConfirmDelete', {
          name: companyToRemove?.name,
        })}
        typeBack={companyToRemove?.name}
        isOpen={isDeleteConfirmOpen}
        onConfirm={confirmDelete}
        onClose={closeDeleteConfirm}
      />
    </Box>
  );
};

export default AdminUsersScene;
