import withStyles from '@mui/styles/withStyles';
import React from 'react';
import InvitationDialog from './InvitationDialog';
import LoginNoInvitationForm from './LoginNoInvitationForm';

const styles = () => ({});

const FormScene = () => (
  <div>
    <LoginNoInvitationForm />
    <InvitationDialog message="Our administrator will contact you soon" />
  </div>
);
export default withStyles(styles)(FormScene);
