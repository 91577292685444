/**
 * @flow
 */
import { Avatar, Card, CardContent, CardHeader, Typography } from '@mui/material';
import AttachmentsField from 'components/@whistleblower-admin/drawers/OccurrenceDrawer/AttachmentsField';
import { useOccurrence } from 'components/@whistleblower-admin/drawers/OccurrenceDrawer/OccurrenceContext';
import React, { useEffect } from 'react';
import { decrypt } from 'utils/e2eEncryption';
import initialsFromUsername from 'utils/initialsFromUsername';

type Props = {
  message: Object;
};

const MessageListItem = ({ message }: Props) => {
  const { aesKey } = useOccurrence();
  const [text, setText] = React.useState('');
  useEffect(() => {
    if (message.text && aesKey) {
      decrypt(message.text, aesKey).then(decryptedText => {
        setText(decryptedText);
      });
    }
  }, [message.text, aesKey]);
  return (
    <Card
      elevation={0}
      sx={{
        borderRadius: 0,
        borderBottom: 1,
        borderColor: 'divider',
      }}
    >
      <CardHeader
        avatar={
          <Avatar src={message.employee?.avatar}>
            {initialsFromUsername(message.employee?.name)}
          </Avatar>
        }
        title={message.employee?.name || 'Whistleblower'}
        subheader={new Date(message.createdAt).toLocaleString()}
      />
      <CardContent sx={{ display: 'flex', flexDirection: 'column', py: 0, gap: 2 }}>
        <Typography>{text}</Typography>
        <AttachmentsField attachments={message.attachments} aesKey={aesKey} />
      </CardContent>
    </Card>
  );
};

export default MessageListItem;
