import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  errorMessage: {
    flex: '1 0 auto',
  },
};

const QuestionErrorMessage = ({ children, classes }) => (
  <Typography color="error" className={classes.errorMessage}>
    {children}
  </Typography>
);

QuestionErrorMessage.propTypes = {
  children: PropTypes.any.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(QuestionErrorMessage);
