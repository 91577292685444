import { createSelector } from 'reselect';
import authCompany from './authCompany';

const employeesWithUser = company => {
  return company.employees
    ?.filter(e => e.user)
    .map(e => ({
      ...e,
      user_id: e.user._id,
      name: e.firstName ? `${e.firstName} ${e.surName}` : '*****',
    }));
};
export default createSelector(state => authCompany(state), employeesWithUser);
