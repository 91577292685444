import { Button, SvgIcon } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import EmailIcon from 'assets/icons/mail.svg';
import Alert from 'components/alerts/Alert';
import ms from 'ms';
import { object, string } from 'prop-types';
import React, { useState } from 'react';
import { I18n } from 'utils/i18n';
import Invite from 'services/api/Invite';

const autoHideDuration = ms('10 seconds');

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
  },
  icon: {
    fontSize: 18,
    marginRight: 5,
    color: theme.palette.primary.main,
  },
});

const InviteByEmail = ({ classes, employeeId }) => {
  const alertInitialState = { isOpen: false };
  const [alert, setAlert] = useState(alertInitialState);

  const onClose = () => {
    setAlert(a => ({ ...a, ...alertInitialState }));
  };

  const invite = () => {
    setAlert({
      isOpen: true,
      variant: 'success',
      message: I18n.t('ProfileDrawer.Invitation sent by email'),
    });
    Invite.send(employeeId, 'email');
  };

  return (
    <div className={classes.root}>
      <Button onClick={invite} variant="outlined" disabled={alert.isOpen} color="secondary">
        <SvgIcon className={classes.icon}>
          <EmailIcon />
        </SvgIcon>
        {I18n.t('ProfileDrawer.Invite by email')}
      </Button>
      <Alert
        isOpen={alert.isOpen}
        variant={alert.variant}
        onClose={onClose}
        label={I18n.t('ProfileDrawer.Invite by email')}
        caption={alert.caption}
        autoHideDuration={autoHideDuration}
      >
        {alert.message}
      </Alert>
    </div>
  );
};

InviteByEmail.propTypes = {
  classes: object.isRequired,
  employeeId: string.isRequired,
};

export default withStyles(styles)(InviteByEmail);
