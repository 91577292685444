import { SvgIcon, Tab } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import { bool, node, object } from 'prop-types';
import React from 'react';

const styles = theme => ({
  root: {
    '&$root2': {
      minWidth: 'calc(min(160px, 20%))',
      maxWidth: '20%',
      textTransform: 'none',
      fontSize: 15,
      minHeight: 63,
      color: '#738195',
      opacity: 1,
      '&$selected': {
        color: theme.palette.primary.ultraDark,
        '& $icon': {
          color: theme.palette.primary.main,
        },
      },
      '& $container': {
        display: 'flex',
        alignItems: 'center',
      },
      '& $icon': { fontSize: 30, paddingRight: 10 },
      '& $text': {
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
        fontWeight: 600,
        letterSpacing: 'initial',
      },
      '&$isInvisible': {
        display: 'none',
      },
    },
  },
  root2: {},
  selected: {},
  container: {},
  icon: {},
  text: {},
  isInvisible: {},
});

const HeaderTab = ({ classes, icon, children, visible, ...props }) => (
  <Tab
    {...props}
    disabled={!visible}
    component="div"
    classes={{
      root: classNames({
        [classes.root]: true,
        [classes.root2]: true,
        [classes.isInvisible]: !visible,
      }),
      selected: classes.selected,
    }}
    label={
      <div className={classes.container}>
        <SvgIcon className={classes.icon}>{icon}</SvgIcon>
        <span className={classes.text}>{children}</span>
      </div>
    }
  />
);
HeaderTab.propTypes = {
  classes: object.isRequired,
  icon: node.isRequired,
  visible: bool,
  children: node.isRequired,
};
HeaderTab.defaultProps = {
  visible: true,
};

export default withStyles(styles)(HeaderTab);
