import { SvgIcon, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import BellIcon from 'assets/icons/bell.svg';
import FileEmptyIcon from 'assets/icons/file-empty.svg';
import MessagesIcon from 'assets/icons/messages.svg';
import OmmnioAppIcon from 'assets/icons/ommnio-app-icon.svg';
import PhoneSmsIcon from 'assets/icons/phone-sms-icon.svg';
import AppleDownload from 'assets/images/apple_download.svg';
import GoogleDownload from 'assets/images/google_download.svg';
import DesktopClassicIcon from 'mdi-react/DesktopClassicIcon';
import { object, string } from 'prop-types';
import React from 'react';
import { QRCode } from 'react-qr-svg';
import { Translate } from 'utils/i18n';
import connector from './connector';

const styles = theme => ({
  root: {
    overflow: 'hidden',
    zoom: '75%',
    display: 'flex',
    flexDirection: 'column',
    padding: 50,
    pageBreakBefore: 'always',
    color: 'black',
  },
  whiteButton: {
    backgroundColor: 'white',
    boxShadow: '0 1px 2px 0 rgba(2, 2, 3, 0.05)',
    border: `solid 1px #e1e2e6`,
    padding: '0 10px',
    color: 'black',
    marginRight: 15,
  },
  icon: {
    fontSize: 18,
    marginRight: 5,
    color: '#f8f9fd',
  },
  logo: {
    '& img': {
      maxWidth: 100,
      maxHeight: 100,
    },
    marginBottom: 50,
  },
  stepsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  steps: {
    counterReset: 'steps',
    '& h3': {
      marginTop: 0,
    },
    '& > li': {
      position: 'relative',
      background: '#f8f9fd',
      border: `1px solid #e1e2e6`,
      borderRadius: '5px',
      padding: '18px 12px 12px 57px',
      marginBottom: 14,
      listStyleType: 'none',
    },
    '& > li:before': {
      margin: 12,
      content: 'counter(steps)',
      counterIncrement: 'steps',
      position: 'absolute',
      top: 0,
      left: 0,
      border: `1px solid #e1e2e6`,
      borderRadius: '50%',
      background: 'white',
      width: '2em',
      height: '2em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  stepTitle: {
    color: 'black',
  },
  stepDescription: {
    color: 'black',
  },
  stores: {
    position: 'absolute',
    right: '12px',
    top: '12px',
    '& svg': {
      width: 150,
      height: 'auto',
    },
  },
  appIcon: {
    width: 40,
    color: theme.palette.primary.main,
    marginRight: '1em',
  },
  substeps: {
    counterReset: 'substeps',
    display: 'flex',
    justifyContent: 'space-around',
    padding: 0,
    '& li': {
      listStyleType: 'none',
      display: 'flex',
      alignItems: 'flex-start',
      marginRight: '1em',
    },
    '& > li > span:before': {
      content: "counter(substeps)'. '",
      counterIncrement: 'substeps',
    },
  },
  heading2: {
    marginTop: '2em',
  },
  subheading2: {
    color: theme.palette.text.secondary,
    marginBottom: '1em',
    fontSize: '1.1em',
  },
  muted: {
    color: theme.palette.text.secondary,
  },
  advantages: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  advantage: {
    display: 'flex',
    marginBottom: '3em',
    marginRight: '4em',
    maxWidth: 400,
    alignItems: 'flex-start',
    '& h3': {
      margin: 0,
    },
  },
  advantageIcon: {
    color: theme.palette.primary.main,
  },
  qr: {
    width: 300,
    margin: '2em',
  },
  subheading: {
    color: theme.palette.text.secondary,
    fontWeight: 'normal',
    marginBottom: 40,
  },
  employeeCode: {
    textAlign: 'right',
  },
});

const Step = withStyles(styles)(props => {
  const { classes, title, description } = props;

  return (
    <li>
      <h3 className={classes.stepTitle}>{title}</h3>
      <div className={classes.stepDescription}>{description}</div>
    </li>
  );
});

const Advantage = withStyles(styles)(props => {
  const { classes, icon, title, description } = props;

  return (
    <li className={classes.advantage}>
      <SvgIcon className={classes.icon}>{icon}</SvgIcon>
      <div>
        {title}
        {description}
      </div>
    </li>
  );
});

const InvitationToPrint = ({ classes, company, employee, magicLink }) => (
  <div className={classes.root} data-mui-color-scheme="light">
    <div className={classes.logo}>
      <img src={company.logoDark || company.logo} alt={company.name} />
    </div>
    <Typography className={classes.heading} gutterBottom variant="h5">
      <Translate
        value="InvitationToPrint.headline"
        firstName={employee.firstName?.trim()}
        surName={employee.surName?.trim()}
      />
    </Typography>
    <Typography variant="h4" className={classes.subheading}>
      <Translate value="InvitationToPrint.subheading" company={company.name} dangerousHTML />
    </Typography>
    <div className={classes.stepsWrapper}>
      <ol className={classes.steps}>
        <Step
          title={<Translate value="InvitationToPrint.Step_1_Title" />}
          description={
            <div>
              <Translate value="InvitationToPrint.Step_1_Description" dangerousHTML />
              <div className={classes.stores}>
                <GoogleDownload />
                <AppleDownload />
              </div>
            </div>
          }
        />
        <Step
          title={<Translate value="InvitationToPrint.Step_2_Title" />}
          description={
            <div>
              <ol className={classes.substeps}>
                <li>
                  <OmmnioAppIcon className={classes.appIcon} />
                  <Translate value="InvitationToPrint.Step_2_Description_1" />
                </li>
                <li>
                  <PhoneSmsIcon className={classes.appIcon} />
                  <Translate value="InvitationToPrint.Step_2_Description_2" />
                </li>
              </ol>
            </div>
          }
        />
        <Step
          title={<Translate value="InvitationToPrint.Step_3_Title" />}
          description={<Translate value="InvitationToPrint.Step_3_Description" />}
        />
      </ol>
      <QRCode value={magicLink} size={200} level="H" className={classes.qr} />
    </div>
    <Typography className={classes.heading2} gutterBottom variant="h5">
      <Translate value="InvitationToPrint.Your colleagues" company={company.name} />
    </Typography>
    <div className={classes.subheading2}>
      <Translate value="InvitationToPrint.You only need" />
    </div>

    <ul className={classes.advantages}>
      {[MessagesIcon, BellIcon, FileEmptyIcon, DesktopClassicIcon].map((Icon, index) => (
        <Advantage
          key={`InvitationToPrint.Advantage_${index + 1}_Title`}
          icon={<Icon className={classes.advantageIcon} />}
          title={<Translate value={`InvitationToPrint.Advantage_${index + 1}_Title`} tag="h3" />}
          description={
            <Translate
              className={classes.muted}
              value={`InvitationToPrint.Advantage_${index + 1}_Description`}
            />
          }
        />
      ))}
    </ul>
    {employee.code ? (
      <div className={classes.employeeCode}>
        <Translate
          className={classes.muted}
          value="InvitationToPrint.Employee code"
          code={employee.code}
        />
      </div>
    ) : null}
  </div>
);
InvitationToPrint.propTypes = {
  classes: object.isRequired,
  company: object.isRequired,
  employee: object.isRequired,
  magicLink: string.isRequired,
};

export default withStyles(styles)(connector(InvitationToPrint));
