import isEmpty from 'lodash/isEmpty';
import { array, bool, number } from 'prop-types';
import React, { memo } from 'react';
import isAudio from 'utils/isAudio';
import isPicture from 'utils/isPicture';
import isVideo from 'utils/isVideo';
import AudioThumbnail from './AudioThumbnail';
import FileThumbnail from './FileThumbnail';
import PictureThumbnail from './PictureThumbnail';
import VideoThumbnail from './VideoThumbnail';

const Attachments = ({ attachments, right, maxWidth, maxHeight }) => {
  if (isEmpty(attachments)) return null;
  if (!attachments) return null;
  if (!attachments[0]) return null;

  const pictures = attachments.filter(isPicture);
  const videos = attachments.filter(isVideo);
  const audios = attachments.filter(isAudio);
  const files = attachments.filter(a => a.mimeType && !isPicture(a) && !isVideo(a) && !isAudio(a));

  return (
    <div>
      {pictures.map(picture => (
        <PictureThumbnail
          key={picture._id}
          src={picture.httpLink}
          meta={picture.meta}
          maxWidth={maxWidth}
          maxHeight={maxHeight}
        />
      ))}
      {videos.map(video => (
        <VideoThumbnail key={video._id} data={video} maxWidth={maxWidth} maxHeight={maxHeight} />
      ))}
      {audios.map(audio => (
        <AudioThumbnail minWidth={300} key={audio._id} data={audio} right={right} />
      ))}
      {files.map(file => (
        <FileThumbnail key={file._id} data={file} right={right} />
      ))}
    </div>
  );
};

Attachments.propTypes = {
  right: bool.isRequired,
  attachments: array,
  maxWidth: number,
  maxHeight: number,
};
Attachments.defaultProps = {
  attachments: null,
  maxWidth: null,
  maxHeight: null,
};

export default memo(Attachments);
