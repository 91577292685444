import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import channels from 'store/app/entities/channels/action';
import channelDrawer from 'store/app/ui/drawers/channel/action';
import employeeList from 'store/app/ui/drawers/employeeList/action';
import hrBotDrawer from 'store/app/ui/drawers/hrBot/action';
import mediaDrawer from 'store/app/ui/drawers/media/action';
import remoteDrawer from 'store/app/ui/drawers/remote/action';
import timeTrackingDrawer from 'store/app/ui/drawers/timeTracking/action';
import authCompany from 'store/selectors/authCompany';
import authEmployee from 'store/selectors/authEmployee';
import currentChannel from 'store/selectors/currentChannel';
import currentChannelEmployee from 'store/selectors/currentChannelEmployee';
import isCompanyAdmin from 'store/selectors/isCompanyAdmin';
import isTeamAdmin from 'store/selectors/isTeamAdmin';

const mapStateToProps = state => {
  const employee = authEmployee(state);
  const channel = currentChannel(state);
  return {
    company: authCompany(state),
    isCompanyAdmin: isCompanyAdmin(state),
    isTeamAdmin: isTeamAdmin(state),
    channel,
    app: currentChannelEmployee(state)?.user?.app,
    archived: employee?.archivedChannels?.includes(channel._id),
    authEmployee: employee,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    channels: bindActionCreators(channels, dispatch),
    drawers: {
      employeeList: bindActionCreators(employeeList, dispatch),
      channel: bindActionCreators(channelDrawer, dispatch),
      media: bindActionCreators(mediaDrawer, dispatch),
      hrBot: bindActionCreators(hrBotDrawer, dispatch),
      timeTracking: bindActionCreators(timeTrackingDrawer, dispatch),
      remote: bindActionCreators(remoteDrawer, dispatch),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
