import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { number, object } from 'prop-types';
import React from 'react';
import { I18n } from 'utils/i18n';

const styles = theme => ({
  digitalInfoWrapper: {
    display: 'flex',
    minHeight: 80,
    alignItems: 'stretch',
    borderRadius: 8,
    border: 'solid 1px #dde2eb',
    boxShadow: '0 1px 2px 0 rgba(2, 2, 3, 0.05)',
  },
  cellWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'center',
    flex: 1,
    paddingLeft: 20,
    borderRight: 'solid 1px #dde2eb',
    '&:last-child': {
      borderRight: 'none',
    },
  },
  cellValue: {
    fontSize: 15,
    fontWeight: 600,
    lineHeight: 1.47,
    letterSpacing: '-0.4px',
    color: theme.palette.text.primary,
  },
  cellLabel: {
    fontSize: 15,
    lineHeight: 1.47,
    letterSpacing: '-0.5px',
    color: theme.palette.text.secondary,
  },
});

const Statistics = ({ classes, lastSeen, mandatoriesCount, messagesSent }) => (
  <div className={classes.digitalInfoWrapper}>
    <div className={classes.cellWrapper}>
      <Typography className={classes.cellValue}>
        {lastSeen?.isValid && lastSeen?.isValid()
          ? lastSeen.fromNow()
          : I18n.t('ProfileDrawer.never')}
      </Typography>
      <Typography className={classes.cellLabel}>{I18n.t('ProfileDrawer.Last seen')}</Typography>
    </div>
    <div className={classes.cellWrapper}>
      <Typography className={classes.cellValue}>{mandatoriesCount}</Typography>
      <Typography className={classes.cellLabel}>
        {I18n.t('ProfileDrawer.Sent mandatory reads')}
      </Typography>
    </div>
    <div className={classes.cellWrapper}>
      <Typography className={classes.cellValue}>{messagesSent}</Typography>
      <Typography className={classes.cellLabel}>
        {' '}
        {I18n.t('ProfileDrawer.Messages sent')}
      </Typography>
    </div>
  </div>
);
Statistics.propTypes = {
  classes: object.isRequired,
  lastSeen: object,
  mandatoriesCount: number,
  messagesSent: number,
};
Statistics.defaultProps = {
  mandatoriesCount: 0,
  messagesSent: 0,
  lastSeen: null,
};

export default withStyles(styles)(Statistics);
