import { array, arrayOf, bool, number, shape, string } from 'prop-types';
import inviteShape from './invite';

export default shape({
  _id: string.isRequired,
  name: string.isRequired,
  avatar: string,
  jobTitle: string.isRequired,
  department: string.isRequired,
  email: string,
  phone: string,
  location: string.isRequired,
  tags: array.isRequired,
  isAdmin: bool.isRequired,
  isActive: bool.isRequired,
  code: string,
  user_id: string,
  firstName: string.isRequired,
  surName: string.isRequired,
  company_id: string.isRequired,
  createdAt: number.isRequired,
  mandatorySent: number,
  mandatoryRead: number,
  invite: inviteShape,
  settings: shape({
    dnd: bool.isRequired,
    timeZone: string.isRequired,
    isOpenCommunication: bool.isRequired,
    workingHours: shape({
      from: number.isRequired,
      to: number.isRequired,
    }),
    workingDays: arrayOf(
      arrayOf(
        shape({
          from: number.isRequired,
          to: number.isRequired,
        }),
      ),
    ),
    browser: shape({
      sound: bool.isRequired,
    }),
  }),
  magicLink: string,
  division: string,
});
