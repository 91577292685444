import { useFeatureToggle } from '@flopflip/react-redux';
import { Chip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import SendArticleButton from 'components/@home/@messages/ChatPanel/SendForm/buttons/SendArticleButton';
import ContextMessage from 'components/@home/@messages/ChatPanel/SendForm/ContextMessage';
import { Field, Form } from 'formik';
import findLast from 'lodash/findLast';
import { bool, func, object } from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'utils/i18n';
import isSuperAdminSelector from 'store/selectors/isSuperAdmin';
import Actions from './Actions';
import NewAcknowledgeButton from './buttons/NewAcknowledgeButton';
import NewFormButton from './buttons/NewFormButton';
import SendButton from './buttons/SendButton';
import connector from './connector';
import Container from './Container';
import formik from './formik';
import AttachFile from './formik/AttachFile';
import ChatField from './formik/ChatField';
import ChatSelect from './formik/ChatSelect';

const styles = theme => ({
  root: {
    position: 'relative',
  },
  form: {
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  },
  options: {
    display: 'flex',
    minHeight: 40,
  },
  mentionSuggestions: {
    border: 0,
    maxHeight: '25vh',
    overflowY: 'scroll',
    position: 'relative',
  },
  suggestions: {
    borderTop: `1px solid ${theme.palette.divider}`,
    background: theme.palette.white,
    padding: theme.spacing(1),
    lineHeight: 2.5,
  },
  suggestion: {
    marginRight: theme.spacing(1),
  },
  actions: {
    minHeight: 50,
  },
});

const withProgress = WrappedComponent =>
  class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        progress: null,
        onCancel: () => null,
      };
      this.onUploadProgress = this.onUploadProgress.bind(this);
      this.getCancelFunc = this.getCancelFunc.bind(this);
      this.onChange = this.onChange.bind(this);
    }

    onUploadProgress(progressEvent) {
      let progress = null;
      if (progressEvent?.lengthComputable) {
        progress = (progressEvent.loaded * 100) / progressEvent.total;
      }
      this.setState({ progress });
    }

    getCancelFunc(onCancel) {
      this.setState({ onCancel });
    }

    onChange() {
      const { submitForm } = this.props.form;
      submitForm();
    }

    render() {
      const { progress, onCancel } = this.state;
      return (
        <WrappedComponent
          {...this.props}
          progress={progress}
          onCancel={onCancel}
          onUploadProgress={this.onUploadProgress}
          getCancelFunc={this.getCancelFunc}
          onChange={this.onChange}
        />
      );
    }
  };

const AttachFileProgress = withProgress(AttachFile);

const SendMessageForm = ({
  channel: { _id, isAdmin, canWriteAsChannel, messages, handoffFrom, company_id: companyId },
  isDirect,
  classes,
  isCompanyAdmin,
  isTeamAdmin,
  handleSubmit,
  setFieldValue,
  values: { message, sentAsChannel },
  actions,
  authEmployee,
  onChangeSentAsChannel,
  contextMessage,
}) => {
  const suggestionsPortal = useRef();
  const inputRef = useRef();
  const isSuperAdmin = useSelector(isSuperAdminSelector);
  const areArticlesEnabled = useFeatureToggle('articles') || isSuperAdmin;
  const finishConversation = I18n.t('ChatFlow.Finish conversation');
  const suggestions = [...(findLast(messages, m => !m.deletedAt)?.suggestions || [])];
  if (handoffFrom && !suggestions.includes(finishConversation)) {
    suggestions.push(finishConversation);
  }
  useEffect(() => {
    onChangeSentAsChannel(sentAsChannel);
  }, [onChangeSentAsChannel, sentAsChannel]);
  useEffect(() => {
    if (contextMessage && inputRef.current) {
      inputRef.current.focus();
    }
  }, [contextMessage]);
  const renderSuggestion = useCallback(
    s => (
      <Chip
        disabled={!!message?.text}
        className={classes.suggestion}
        key={s}
        label={s}
        clickable
        onClick={e => {
          if (s === finishConversation) {
            actions.chatFlows.conversationRestart(handoffFrom);
            actions.channels.setArchived(companyId, authEmployee._id, _id, true);
          } else {
            setFieldValue('message.text', s);
            handleSubmit(e);
          }
        }}
      />
    ),
    [
      _id,
      actions.channels,
      actions.chatFlows,
      authEmployee._id,
      classes.suggestion,
      companyId,
      finishConversation,
      handleSubmit,
      handoffFrom,
      message.text,
      setFieldValue,
    ],
  );

  return (
    <div className={classes.root}>
      {contextMessage ? (
        <ContextMessage message={contextMessage.message} variant={contextMessage.type} />
      ) : null}
      <div ref={suggestionsPortal} className={classes.mentionSuggestions} />
      {suggestions.length > 0 && (
        <div className={classes.suggestions}>{suggestions.map(renderSuggestion)}</div>
      )}
      <Form className={classes.form}>
        <Container>
          {(isAdmin || canWriteAsChannel || isCompanyAdmin) && !isDirect && (
            <Field name="sentAsChannel" component={ChatSelect} />
          )}
          <Field
            name="message"
            component={ChatField}
            suggestionsPortal={suggestionsPortal}
            inputRef={inputRef}
          />
        </Container>
        <Actions className={classes.actions}>
          <div className={classes.options}>
            {!contextMessage && <Field name="attachments" component={AttachFileProgress} />}
            {/* FIXME: The following condition allows you to send acks to everyone in o2o chats, you should only be able to send them to members of teams you are admin */}
            {!contextMessage &&
              (isAdmin || canWriteAsChannel || isCompanyAdmin || (isDirect && isTeamAdmin)) && (
                <NewAcknowledgeButton />
              )}
            {!contextMessage &&
              (isAdmin || canWriteAsChannel || isCompanyAdmin || (isDirect && isTeamAdmin)) && (
                <>
                  <NewFormButton />
                  {!areArticlesEnabled || <SendArticleButton />}
                </>
              )}
          </div>
          <SendButton />
        </Actions>
      </Form>
    </div>
  );
};
SendMessageForm.propTypes = {
  channel: object.isRequired,
  classes: object.isRequired,
  isDirect: bool.isRequired,
  isCompanyAdmin: bool.isRequired,
  isTeamAdmin: bool.isRequired,
  handleSubmit: func.isRequired,
  setFieldValue: func.isRequired,
  values: object.isRequired,
  actions: object.isRequired,
  authEmployee: object.isRequired,
  onChangeSentAsChannel: func.isRequired,
  contextMessage: object,
};

SendMessageForm.defaultProps = {
  contextMessage: null,
};

export default withStyles(styles)(connector(formik(SendMessageForm)));
