import React from 'react';
import { node, object } from 'prop-types';
import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

const styles = {
  root: {
    fontSize: '15px',
    fontWeight: '600',
    marginBottom: '15px',
  },
};

const Title = ({ classes, children }) => (
<Typography
    className={classes.root}
    variant="h6"
    gutterBottom
  >
    {children}
  </Typography>
);Title.propTypes = {
  classes: object.isRequired,
  children: node.isRequired,
};

export default withStyles(styles)(Title);
