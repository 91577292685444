import HrBot from 'services/api/HrBot';

export const HR_BOT_SEND = 'HR_BOT_SEND';
export const HR_BOT_SEND_PENDING = 'HR_BOT_SEND_PENDING';
export const HR_BOT_SEND_FULFILLED = 'HR_BOT_SEND_FULFILLED';

export const FIND_BULK_MESSAGING = 'FIND_BULK_MESSAGING';
export const FIND_BULK_MESSAGING_PENDING = 'FIND_BULK_MESSAGING_PENDING';
export const FIND_BULK_MESSAGING_FULFILLED = 'FIND_BULK_MESSAGING_FULFILLED';

export const CLEAR_BULK_MESSAGING = 'CLEAR_BULK_MESSAGING';

export const CHECK_IDS = 'CHECK_IDS';
export const CHECK_IDS_PENDING = 'CHECK_IDS_PENDING';
export const CHECK_IDS_FULFILLED = 'CHECK_IDS_FULFILLED';

const hrBotSend = ({ text, files, format }) => ({
  type: HR_BOT_SEND,
  payload: HrBot.send({ text, files, format }),
});

const add = bulkMessaging => ({
  type: FIND_BULK_MESSAGING_FULFILLED,
  payload: { data: bulkMessaging },
});

const clear = () => ({
  type: CLEAR_BULK_MESSAGING,
});

const checkIds = payload => ({
  type: CHECK_IDS,
  payload: HrBot.checkIds(payload),
});

export default { add, checkIds, clear, hrBotSend };
