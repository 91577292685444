import { Button, SvgIcon } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import PrintIcon from 'assets/icons/print.svg';
import PrintContent from 'components/@home/drawers/ProfileDrawer/Profile/EmployeeActions/PrintInvitation/PrintContent';
import { object } from 'prop-types';
import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import employeeShape from 'shapes/employee';
import { I18n } from 'utils/i18n';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  whiteButton: {
    marginLeft: 15,
  },
  icon: {
    fontSize: 18,
    marginRight: 5,
    color: theme.palette.primary.main,
  },
});

const PrintInvitation = ({ classes, employee }) => {
  const componentRef = useRef();

  return (
    <div className={classes.root}>
      <ReactToPrint
        trigger={() => (
          <Button variant="outlined" color="secondary" className={classes.whiteButton}>
            <SvgIcon className={classes.icon}>
              <PrintIcon />
            </SvgIcon>
            {I18n.t('ProfileDrawer.Print invitation')}
          </Button>
        )}
        content={() => componentRef.current}
      />
      <div style={{ display: 'none' }}>
        <PrintContent ref={componentRef} employee={employee} />
      </div>
    </div>
  );
};

PrintInvitation.propTypes = {
  classes: object.isRequired,
  employee: employeeShape.isRequired,
};

export default withStyles(styles)(PrintInvitation);
