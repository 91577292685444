import Config from 'services/api/Config';

export const LOAD_LANGUAGES = 'LOAD_LANGUAGES';
export const LOAD_LANGUAGES_PENDING = 'LOAD_LANGUAGES_PENDING';
export const LOAD_LANGUAGES_FULFILLED = 'LOAD_LANGUAGES_FULFILLED';
export const LOAD_LANGUAGES_REJECTED = 'LOAD_LANGUAGES_REJECTED';

const loadLanguages = () => ({
  type: LOAD_LANGUAGES,
  payload: Config.getLanguages(),
});

export default { loadLanguages };
