/* eslint-disable react/destructuring-assignment */
import { Button, SvgIcon, Toolbar } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import FileDownload from 'mdi-react/FileDownloadIcon';
import { func, object, string } from 'prop-types';
import React, { Component } from 'react';
import { I18n } from 'utils/i18n';
import ErrorCaption from './ErrorCaption';
import Search from './Search';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(1),
  },
  actions: {
    display: 'flex',
    alignItem: 'center',
    color: theme.palette.text.secondary,
  },
  title: {
    fontWeight: 600,
  },
  button: {
    color: theme.palette.primary.ultraDark,
    marginLeft: 10,
  },
  plus: {
    fontSize: 18,
    marginRight: 5,
    color: theme.palette.primary.main,
  },
});

class EnhancedTableToolbar extends Component {
  state = {
    searchValue: '',
  };

  handleSearch = e => {
    const { onSearch } = this.props;
    this.setState({ searchValue: e.target.value });
    onSearch(e.target.value);
  };

  render() {
    const { error, classes, onExport } = this.props;
    return (
      <Toolbar className={classes.root}>
        <div className={classes.actions}>
          <ErrorCaption>{error}</ErrorCaption>
          <Search onChange={this.handleSearch} value={this.state.searchValue} />
          <Button
            variant="outlined"
            color="secondary"
            className={classes.button}
            onClick={onExport}
          >
            <SvgIcon className={classes.plus}>
              <FileDownload />
            </SvgIcon>
            {I18n.t('FormAnalyticsDrawer.EmployeesTable.Export responses')}
          </Button>
        </div>
      </Toolbar>
    );
  }
}

EnhancedTableToolbar.propTypes = {
  classes: object.isRequired,
  error: string,
  onSearch: func.isRequired,
  onExport: func.isRequired,
};

EnhancedTableToolbar.defaultProps = {
  error: '',
};

export default withStyles(styles)(EnhancedTableToolbar);
