import { OPEN_FORM_ANALYTICS_DRAWER, CLOSE_FORM_ANALYTICS_DRAWER } from './action';

const initialState = {
  isOpen: false,
};

const formDrawer = (state = initialState, { type, payload }) => {
  switch (type) {
    case OPEN_FORM_ANALYTICS_DRAWER:
      return {
        ...state,
        isOpen: true,
        ...payload,
      };

    case CLOSE_FORM_ANALYTICS_DRAWER:
      return {
        ...state,
        isOpen: false,
      };

    default:
      return state;
  }
};

export default formDrawer;
