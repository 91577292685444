import { alpha, lighten } from '@mui/material/styles';
import createTransitions from '@mui/material/styles/createTransitions';
import { darken } from '@mui/system';
import getSubdomain from 'utils/getSubdomain';
import ommnio from './ommnio';
import saintGobain from './saintGobain';

// Always ommnio except when the domain is saint-gobain.web.ommnio.com
const palette =
  {
    'saint-gobain': saintGobain,
  }[getSubdomain()] || ommnio;

const getColors = prefersDarkMode => ({
  palette: {
    mode: prefersDarkMode ? 'dark' : 'light',
    ...(prefersDarkMode
      ? {
          link: {
            main: '#198ae0',
            hover: '#0f67bf',
          },
          primary: {
            ultraLight: palette.primary[20],
            extraLight: palette.primary[30],
            lighter: palette.primary[40],
            light4: palette.primary[40],
            light2: palette.primary[50],
            light1: palette.primary[60],
            light: palette.primary[70],
            medium: palette.primary[80],
            main: palette.primary[90],
            dark: palette.primary[95],
            extraDark: palette.primary[98],
            ultraDark: palette.primary[99],
          },
          secondary: {
            ultraUltraLight: palette.secondary[5],
            ultraLight: palette.secondary[10],
            extraLight: palette.secondary[20],
            light: palette.secondary[30],
            medium: palette.secondary[40],
            main: palette.secondary[50],
            dark: palette.secondary[60],
            darker: palette.secondary[70],
          },
          tertiary: {
            main: palette.tertiary[60],
            dark: palette.tertiary[70],
          },
          error: {
            main: palette.error[50],
            dark: palette.error[70],
          },
          pre: '#e01e5a',
          white: '#000000',
          greyBlue: '#989ab4',
          black: '#ffffff',
          activePendingBlockedChartSeries: [
            palette.primary[50],
            palette.secondary[70],
            palette.error[70],
          ],
          onOffChartSeries: [palette.primary[50], palette.secondary[70]],
          colorChartSeries: palette.analogous,
          directGroupChartSeries: [palette.analogous[2], palette.analogous[1]],
          icons: palette.icons,
          danger: palette.error[70],
          ok: '#00c8a2',
          dangerBack: palette.error[2],
          mentionBack: palette.primary[10],
          mention: palette.primary[80],
          questions: {
            SingleChoice: palette.primary[50],
            MultipleChoice: palette.primary[30],
            ImageUpload: palette.icons[0],
            FileUpload: palette.icons[1],
            Confirm: palette.icons[3],
            Text: palette.icons[4],
          },
          codeBackground: palette.neutral[98],
          admin: '#FEC106',
        }
      : {
          link: {
            main: '#1264a3',
            hover: '#0b4c8c',
          },
          primary: {
            ultraLight: palette.primary[98],
            extraLight: palette.primary[95],
            lighter: palette.primary[90],
            light4: palette.primary[90],
            light2: palette.primary[90],
            light1: palette.primary[80],
            light: palette.primary[70],
            medium: palette.primary[60],
            main: palette.primary[50],
            dark: palette.primary[30],
            extraDark: palette.primary[20],
            ultraDark: palette.primary[2],
          },
          secondary: {
            ultraUltraLight: palette.secondary[99],
            ultraLight: palette.secondary[98],
            extraLight: palette.secondary[90],
            light: palette.secondary[80],
            medium: palette.secondary[60],
            main: palette.secondary[50],
            dark: palette.secondary[40],
            darker: palette.secondary[30],
          },
          tertiary: {
            main: palette.tertiary[70],
            dark: palette.tertiary[60],
          },
          error: {
            main: palette.error[50],
          },
          pre: '#e01e5a',
          white: '#ffffff',
          greyBlue: palette.neutral[70],
          black: '#000000',
          activePendingBlockedChartSeries: [
            palette.primary[50],
            palette.secondary[70],
            palette.error[70],
          ],
          onOffChartSeries: [palette.primary[50], palette.secondary[70]],
          colorChartSeries: palette.analogous,
          directGroupChartSeries: [palette.analogous[2], palette.analogous[1]],
          icons: palette.icons,
          danger: palette.error[50],
          ok: '#00c8a2',
          dangerBack: palette.error[95],
          mentionBack: palette.primary[95],
          mention: palette.primary[50],
          questions: {
            SingleChoice: palette.primary[50],
            MultipleChoice: palette.primary[30],
            ImageUpload: palette.icons[0],
            FileUpload: palette.icons[1],
            Confirm: palette.icons[3],
            Text: palette.icons[4],
          },
          codeBackground: palette.neutral[98],
          admin: '#FEC106',
        }),
  },
});

export default prefersDarkMode => {
  const colors = getColors(prefersDarkMode);
  return {
    typography: {
      useNextVariants: true,
      fontSize: 13,
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      h2: {
        fontSize: 24,
        fontWeight: 'bold',
        color: colors.palette.primary.ultraDark,
        letterSpacing: -0.72,
      },
      h3: {
        fontSize: 20,
        fontWeight: 'bold',
        color: colors.palette.primary.ultraDark,
        letterSpacing: -0.62,
      },
      h4: {
        fontSize: 20,
        fontWeight: 'bold',
        color: colors.palette.primary.ultraDark,
        letterSpacing: '-.72px',
      },
      h5: {
        fontSize: '28px',
        fontWeight: 'bold',
        color: colors.palette.primary.ultraDark,
        letterSpacing: '-.72px',
      },
      h6: {
        fontSize: 24,
        fontWeight: 'bold',
        color: colors.palette.primary.ultraDark,
        letterSpacing: '-.62',
      },
      subtitle1: {
        fontSize: 16,
      },
      subtitle2: {
        fontSize: 15,
        letterSpacing: '-.46',
        fontWeight: 'normal',
        color: colors.palette.secondary.main,
      },
      caption: {
        fontSize: 11.5,
        color: colors.palette.secondary.main,
      },
      body2: {
        color: alpha(colors.palette.black, 0.87),
        fontSize: '0.8125rem',
        fontWeight: 400,
        lineHeight: 1.5,
      },
      body1: {
        color: alpha(colors.palette.black, 0.87),
        fontSize: '0.8125rem',
        fontWeight: 400,
        lineHeight: 1.47,
      },
      status: {
        fontSize: 8,
      },
      tableHeader: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 1.29,
        color: colors.palette.secondary.main,
      },
    },
    '@global': {
      border: `1px solid ${colors.palette.secondary.main}`,
    },
    components: {
      PrivateSwitchBase: {
        input: {
          '&:focus': {
            outline: 'none',
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: alpha(colors.palette.white, 0.5),
            backdropFilter: 'blur(8px)',
          },
          invisible: {
            backgroundColor: 'transparent',
            backdropFilter: 'none',
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          filledDefault: {
            backgroundColor: colors.palette.white,
            border: `1px solid ${colors.palette.secondary.light}`,
            color: colors.palette.primary.main,
          },
          softInfo: {
            backgroundColor: lighten('#0288d1', 0.8),
            color: darken('#0288d1', 0.5),
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          input: {
            padding: 10,
          },
          underline: {
            '&:before': {
              border: 0,
              display: 'none',
            },
            '&:hover': {
              '&:before': {
                display: 'none',
              },
            },
            '&:focus': {
              '&:before': {
                display: 'none',
              },
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&.Mui-focused': {
              '&:after': {
                display: 'none',
              },
            },
            '&.Mui-error': {
              borderRadius: 6,
              '&:after': {
                display: 'none',
              },
            },
          },
          formControl: {
            borderRadius: 6,
          },
          input: {
            padding: 10,
            borderRadius: 6,
          },
          notchedOutline: {
            borderColor: '#dde2eb',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            lineHeight: 'inherit',
            borderRadius: '5px',
            padding: 10,
            fontWeight: 'normal',
            textTransform: 'none',
            boxShadow: 'none',
            minHeight: 36,
          },
          contained: {
            boxShadow: 'none',
          },
          outlined: {
            padding: 9,
          },
          outlinedSecondary: {
            borderColor: colors.palette.secondary.light,
            color: colors.palette.primary.ultraDark,
            backgroundColor: colors.palette.white,
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            maxHeight: '2.25em',
          },
          positionStart: {
            marginLeft: 8,
            marginRight: 0,
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            fontSize: 15.5,
            boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.08)',
            width: 30,
            height: 30,
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            minHeight: 42,
            paddingTop: 0,
            paddingBottom: 0,
            alignItems: 'center',
          },
          button: {
            '&:hover': {
              backgroundColor: colors.palette.secondary.extraLight,
            },
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          root: ({ theme }) => ({
            '& p': {
              color: '#8b98ab',
            },
            borderBottom: `1px solid ${theme.palette.divider}`,
            minHeight: 75,
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 0,
          }),
          multiline: {
            marginTop: 0,
            marginBottom: 0,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: colors.palette.primary.extraLight,
            },
            '&.MuiTableRow-hover': {
              '&:hover': {
                backgroundColor: colors.palette.primary.extraLight,
              },
            },
          },
        },
      },

      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: '4px 24px',
            '&.MuiTableCell-paddingNone': {
              padding: 0,
            },
          },
          body: {
            border: 0,
          },
        },
      },

      MuiTableSortLabel: {
        styleOverrides: {
          icon: {
            width: 16,
            opacity: 0,
          },
        },
      },

      MuiTypography: {
        styleOverrides: {
          root: {
            display: 'block',
          },
          gutterBottom: {
            marginBottom: '1em',
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: 15,
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            zIndex: null,
            fontSize: 15,
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            minHeight: '1.1876em',
            lineHeight: '1.1876em',
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          sizeSmall: {
            '& .MuiSwitch-thumb': {
              width: 12,
              height: 12,
            },
            '& .MuiSwitch-switchBase': {
              padding: 1,
              '&.Mui-checked': {
                transform: 'translateX(12px)',
              },
            },
            '& + .MuiFormControlLabel-label': {
              fontSize: 13,
              lineHeight: 1,
            },
          },
          root: {
            width: 36,
            height: 20,
            padding: 0,
            margin: 15,
            marginLeft: 5,
            '&.MuiSwitch-sizeSmall': {
              width: 26,
              height: 14,
              padding: 0,
              margin: 10,
              marginLeft: 0,
            },
          },
          switchBase: {
            padding: 1,
            '&.Mui-checked': {
              transform: 'translateX(16px)',
              color: colors.palette.white,
              '& + .MuiSwitch-track': {
                backgroundColor: colors.palette.primary.main,
                opacity: 1,
                border: 'none',
              },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
              color: colors.palette.primary.main,
              border: '6px solid #fff',
            },
          },
          thumb: {
            width: 18,
            height: 18,
          },
          track: {
            borderRadius: 20 / 2,
            border: 0,
            backgroundColor: prefersDarkMode
              ? colors.palette.secondary.main
              : colors.palette.secondary.light,
            opacity: 1,
            transition: createTransitions(['background-color', 'border']),
          },
          colorSecondary: {
            '&.Mui-checked': {
              transform: 'translateX(16px)',
              color: colors.palette.white,
              '& + .MuiSwitch-track': {
                backgroundColor: colors.palette.primary.main,
                opacity: 1,
                border: 'none',
              },
            },
          },
          checked: {},
          focusVisible: {},
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: ({ theme }) => ({
            borderColor: theme.palette.divider,
            borderLeft: 0,
            borderRight: 0,
            borderBottom: 0,
            borderRadius: 0,
            '& .MuiDataGrid-withBorderColor': {
              borderColor: theme.palette.divider,
            },
            '& .MuiDataGrid-cell': {
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            },
          }),
          columnSeparator: {
            display: 'none',
          },
          cell: {
            border: 0,
            '&:focus-within': {
              outline: 'none',
            },
          },
          columnHeader: {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: 1.29,
            color: colors.palette.secondary.main,
          },
          row: {
            '&:nth-of-type(odd)': {
              backgroundColor: colors.palette.secondary.ultraUltraLight,
            },
            '&:hover': {
              backgroundColor: colors.palette.primary.extraLight,
            },
          },
          columnHeaders: ({ theme }) => ({
            borderColor: theme.palette.divider,
          }),
        },
      },
    },
    shape: {
      borderRadius: 6,
    },
    spacing: 8,
    ...colors,
  };
};
