export const SELECT_ALL_EMPLOYEES = 'teamsTable/SELECT_ALL_EMPLOYEES';
export const SELECT_EMPLOYEE = 'teamsTable/SELECT_EMPLOYEE';
export const DESELECT_EMPLOYEE = 'teamsTable/DESELECT_EMPLOYEE';
export const FILTER_EMPLOYEES = 'teamsTable/FILTER_EMPLOYEES';
export const SORT_EMPLOYEES = 'teamsTable/SORT_EMPLOYEES';
export const CHANGE_PAGE = 'teamsTable/CHANGE_PAGE';
export const CHANGE_ROWS_PER_PAGE = 'teamsTable/CHANGE_ROWS_PER_PAGE';

const select = employeeId => ({
  type: SELECT_EMPLOYEE,
  payload: employeeId,
});

const deselect = employeeId => ({
  type: DESELECT_EMPLOYEE,
  payload: employeeId,
});

const selectAll = employeeIds => ({
  type: SELECT_ALL_EMPLOYEES,
  payload: employeeIds,
});

const filter = (keyword, employees) => ({
  type: FILTER_EMPLOYEES,
  payload: keyword,
  meta: { employees },
});

const sort = ({ by, order }) => ({
  type: SORT_EMPLOYEES,
  payload: { by, order },
});

const changePage = page => ({
  type: CHANGE_PAGE,
  payload: page,
});

const changeRowsPerPage = rowsPerPage => ({
  type: CHANGE_ROWS_PER_PAGE,
  payload: rowsPerPage,
});

export default { filter, deselect, select, selectAll, sort, changePage, changeRowsPerPage };
