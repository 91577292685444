import { createSelector } from 'reselect';
import authEmployee from './authEmployee';

const isCompanyAdmin = (auth, employee) => auth.isSuperAdmin || employee?.isAdmin || false;

export default createSelector(
  state => state.auth,
  state => authEmployee(state),
  isCompanyAdmin,
);
