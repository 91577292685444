import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CheckboxIcon from 'assets/icons/checkbox.svg';
import CheckboxDisabledIcon from 'assets/icons/checkboxDisabled.svg';
import classNames from 'classnames';
import { array, object, string } from 'prop-types';
import React from 'react';
import svgToStaticMarkup from 'utils/svgToStaticMarkup';

const checkBoxIconUri = svgToStaticMarkup(<CheckboxIcon />);
const checkBoxDisabledIconUri = svgToStaticMarkup(<CheckboxDisabledIcon />);

const useStyles = makeStyles(theme => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    width: '1em',
    height: '1em',
    padding: 2,
    '&:before': {
      content: '""',
      display: 'block',
      width: '100%',
      height: '100%',
      borderRadius: 3,
      backgroundColor: theme.palette.white,
      border: `1px solid ${theme.palette.secondary.light}`,
    },
    'input:hover ~ &:before': {
      borderColor: theme.palette.primary.light2,
    },
    'input:disabled ~ &:before': {
      boxShadow: 'none',
      background: theme.palette.greyBlue,
    },
  },
  checkedIcon: {
    color: theme.palette.primary.main,
    '&:before': {
      border: 0,
      content: '""',
      display: 'block',
      width: '100%',
      height: '100%',
      backgroundImage: `url("data:image/svg+xml,${checkBoxIconUri}")`,
    },
    'input:disabled ~ &:before': {
      backgroundImage: `url("data:image/svg+xml,${checkBoxDisabledIconUri}")`,
    },
  },
  label: {
    lineHeight: '19px',
  },
}));

const StyledCheckbox = props => {
  const classes = useStyles();

  return (
    <Checkbox
      className={classes.root}
      color="primary"
      icon={<span className={classes.icon} />}
      checkedIcon={<span className={classNames(classes.icon, classes.checkedIcon)} />}
      {...props}
    />
  );
};

const FormikCheckboxGroup = ({
  field,
  form,
  items,
  groupClassName,
  itemClassName,
  itemSelectedClassName,
  ...props
}) => {
  const { errors, submitCount, getFieldProps } = form;
  const { name, onChange, onBlur } = field;
  const { value } = getFieldProps(name);
  const classes = useStyles();
  return (
    <FormControl
      error={!!(errors[name] && submitCount > 0)}
      fullWidth
      component="fieldset"
      {...props}
    >
      {items.map(([key, label]) => (
        <FormControlLabel
          className={classNames({
            [itemClassName]: true,
            [itemSelectedClassName]: value?.includes(key),
          })}
          key={key}
          value={key}
          name={name}
          control={<StyledCheckbox onChange={onChange} onBlur={onBlur} />}
          label={label}
          checked={value?.includes(key)}
          classes={{ label: classes.label }}
        />
      ))}
      <FormHelperText>{submitCount > 0 && errors[name]}</FormHelperText>
    </FormControl>
  );
};

FormikCheckboxGroup.propTypes = {
  field: object.isRequired,
  form: object.isRequired,
  groupClassName: string,
  items: array.isRequired,
  itemClassName: string,
  itemSelectedClassName: string,
};
FormikCheckboxGroup.defaultProps = {
  groupClassName: null,
  itemClassName: null,
  itemSelectedClassName: null,
};

export default FormikCheckboxGroup;
