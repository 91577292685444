/* eslint-disable react/jsx-no-duplicate-props */
import { TextField } from '@mui/material';
import MentionsInput from 'components/controls/MentionsInput';
import { array, func, object } from 'prop-types';
import React from 'react';

const FormikMentionsInput = ({
  form,
  field,
  data,
  onAdd,
  onBlur: onBlurIn,
  onChange: onChangeIn,
  InputProps,
  inputProps,
  ...rest
}) => {
  const { getFieldProps, setFieldValue } = form;
  const { name, onBlur } = field;
  const { value } = getFieldProps(name);

  const handleOnChange = (event, markup, text, mentions) => {
    setFieldValue(name, text);
    if (typeof onChangeIn === 'function') {
      onChangeIn(event, { markup, text, mentions });
    }
  };

  const handleBlur = (...args) => {
    onBlur(...args);
    if (typeof onBlurIn === 'function') {
      onBlurIn(...args);
    }
  };

  return (
    <TextField
      fullWidth
      inputComponent={MentionsInput}
      InputProps={{ ...InputProps, inputComponent: MentionsInput }}
      inputProps={{ ...inputProps, onAdd, data }}
      name={name}
      value={value}
      onChange={handleOnChange}
      onBlur={handleBlur}
      {...rest}
    />
  );
};

FormikMentionsInput.propTypes = {
  form: object.isRequired,
  field: object.isRequired,
  data: array.isRequired,
  onChange: func,
  onBlur: func,
  onAdd: func,
  InputProps: object,
  inputProps: object,
};

FormikMentionsInput.defaultProps = {
  onChange: undefined,
  onBlur: undefined,
  onAdd: undefined,
  InputProps: {},
  inputProps: {},
};
export default FormikMentionsInput;
