import {
  GET_CUSTOMER_FULFILLED,
  GET_CUSTOMER_PENDING,
} from 'store/app/entities/subscriptions/action';

const subscriptionsReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case GET_CUSTOMER_PENDING: {
      return {
        ...state,
        loadedAt: null,
        loading: true,
      };
    }

    case GET_CUSTOMER_FULFILLED: {
      return {
        ...state,
        customer: payload.customer,
        loading: false,
        loadedAt: Date.now(),
      };
    }

    default:
      return state;
  }
};

export default subscriptionsReducer;
