import React, { Component } from 'react';
import { object, oneOf, bool, string } from 'prop-types';
import { Drawer } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { I18n } from 'utils/i18n';
import connector from './connector';
import CloseButton from '../CloseButton';
import MediaTabs from './MediaTabs';
import DrawerTitle from './DrawerTitle';

const styles = theme => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      width: 1000,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: '20px 30px 0 40px',
  },
  closeButtonContainer: {
    paddingTop: 20,
    paddingLeft: 20,
  },
});

class MediaDrawer extends Component {
  handleClose = () => {
    const { actions } = this.props;
    actions.mediaDrawer.close();
  };

  render() {
    const { classes, type, isOpen, channelName } = this.props;

    if (!isOpen) return null;

    return (
      <Drawer anchor="right" open={isOpen} onClose={this.handleClose}>
        <div className={classes.root}>
          <div className={classes.closeButtonContainer}>
            <CloseButton onClick={this.handleClose} />
          </div>
          <div className={classes.container}>
            <DrawerTitle>{`${channelName} ${I18n.t('files')}`}</DrawerTitle>
            <MediaTabs tab={type} />
          </div>
        </div>
      </Drawer>
    );
  }
}

MediaDrawer.propTypes = {
  classes: object.isRequired,
  actions: object.isRequired,
  isOpen: bool.isRequired,
  type: oneOf(['media', 'doc', 'link']).isRequired,
  channelName: string,
};
MediaDrawer.defaultProps = {
  channelName: null,
};

export default withStyles(styles)(connector(MediaDrawer));
