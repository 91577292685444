/* eslint-disable react/prop-types, react/jsx-props-no-spreading */
import { Paper, TextField, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { alpha } from '@mui/material/styles';
import { useTheme } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import isArray from 'lodash/isArray';
import React, { forwardRef } from 'react';
import Select from 'react-select';

const styles = theme => ({
  container: {
    flexGrow: 1,
  },
  input: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  placeholder: {
    position: 'absolute',
    left: 10,
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: '-0.1px',
    color: theme.palette.text.secondary,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(0.5),
    left: 0,
  },
  selectInput: {},
});

const Placeholder = props => (
  <Typography className={props.selectProps.classes.placeholder} {...props.innerProps}>
    {props.children}
  </Typography>
);
const Menu = ({ selectProps, innerProps, children }) => (
  <Paper elevation={8} className={selectProps.classes.paper} {...innerProps}>
    {children}
  </Paper>
);

const Option = ({ innerProps, isDisabled, isSelected, children }) => {
  return (
    <MenuItem selected={isSelected} disabled={isDisabled} {...innerProps}>
      {children}
    </MenuItem>
  );
};

const inputComponent = ({ inputRef, ...props }) => <div ref={inputRef} {...props} />;

const Control = ({ selectProps, children, innerProps }) => {
  return (
    <TextField
      fullWidth
      error={selectProps.error}
      helperText={selectProps.helperText}
      InputProps={{
        inputComponent,
        inputProps: {
          className: selectProps.classes.input,
          children,
          ...innerProps,
        },
        ...selectProps.controlProps,
      }}
    />
  );
};

const AutocompleteField = (
  {
    classes,
    options,
    value,
    onChange,
    labelGenerator,
    findValue = (opts, val) => opts.find(option => option.value === val),
    ...props
  },
  ref,
) => {
  const theme = useTheme();
  const result = findValue(options, value);
  return (
    <Select
      ref={ref}
      styles={{
        container: provided => ({ ...provided, flexGrow: 1 }),
      }}
      classes={classes}
      options={options}
      value={
        value && !isArray(value)
          ? { label: result && labelGenerator(result), value }
          : value?.map(v => ({ label: labelGenerator(findValue(options, v)), value: v }))
      }
      onChange={onChange}
      components={{ Placeholder, Menu, Control, Option }}
      {...props}
      theme={th => ({
        ...th,
        colors: {
          ...th.colors,
          primary:
            theme.palette.mode === 'dark'
              ? theme.palette.primary.lighter
              : theme.palette.primary.main,
          primary75: theme.palette.primary.lighter,
          primary50: theme.palette.primary.extraLight,
          primary25: theme.palette.primary.ultraLight,
          neutral10: alpha(theme.palette.text.primary, 0.1),
          neutral15: alpha(theme.palette.text.primary, 0.15),
          neutral20: alpha(theme.palette.text.primary, 0.2),
          neutral30: alpha(theme.palette.text.primary, 0.3),
          neutral40: alpha(theme.palette.text.primary, 0.4),
          neutral50: alpha(theme.palette.text.primary, 0.5),
          neutral60: alpha(theme.palette.text.primary, 0.6),
          neutral70: alpha(theme.palette.text.primary, 0.7),
          neutral80: alpha(theme.palette.text.primary, 0.8),
          neutral90: alpha(theme.palette.text.primary, 0.9),
        },
      })}
    />
  );
};

export default withStyles(styles)(forwardRef(AutocompleteField));
