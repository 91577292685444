import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import auth from 'store/app/auth/action';
import authConfig from 'store/app/authConfig/action';
import login from 'store/app/ui/login/action';
import form from 'store/app/ui/login/form/action';
import keepSessionOpen from 'store/selectors/keepSessionOpen';
import returnUrl from 'store/selectors/returnUrl';

const mapStateToProps = state => ({
  locale: state.i18n.locale,
  login: state.login,
  form: state.login.form,
  returnUrl: returnUrl(state),
  loginType: state.login.authRequestUser?.loginType ? state.login.authRequestUser.loginType : 'sms',
  confirmToken: state.login.confirmToken,
  deviceNames: state.login.deviceNames,
  keepSessionOpen: keepSessionOpen(state),
});

const mapDispatchToProps = dispatch => ({
  actions: {
    auth: bindActionCreators(auth, dispatch),
    authConfig: bindActionCreators(authConfig, dispatch),
    login: {
      ...bindActionCreators(login, dispatch),
      form: bindActionCreators(form, dispatch),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
