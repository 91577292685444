/* eslint-disable react/jsx-curly-spacing,jsx-a11y/click-events-have-key-events,react/destructuring-assignment */
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import ArrowRightIcon from 'mdi-react/ArrowRightIcon';
import { bool, func, number, object, string } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import { I18n } from 'utils/i18n';
import { Link, useNavigate } from 'react-router-dom';
import withNavigate from 'utils/withNavigate';
import connector from './connector';
import formik from './formik';

const styles = theme => ({
  arrowIcon: {
    fontSize: 17,
  },
  button: {
    marginTop: 20,
  },
  link: {
    cursor: 'pointer',
    color: theme.palette.primary.dark,
  },
  buttonLink: {
    padding: 0,
    minHeight: 'auto',
    display: 'inline',
    borderRadius: 0,
    color: theme.palette.primary.dark,
  },
  caption: {
    fontSize: 14,
    marginTop: 28,
    marginRight: 20,
  },
  checkboxError: {
    color: theme.palette.error.main,
  },
  formControl: {
    display: 'block',
  },
  helper: {
    marginTop: 0,
    marginBottom: 8,
  },
});

const ConfirmationForm = ({
  actions,
  classes,
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  isAcceptTerms,
  isSubmitting,
  keepSessionOpen,
  login,
  loginType,
  submitCount,
  values,
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!login.phone) {
      navigate('/auth/login');
    }
  }, [login.phone, navigate]);
  const timeout = useRef();

  const [inTimeout, setInTimeout] = useState(false);

  useEffect(
    () => () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    },
    [],
  );

  const sendSMS = options => () => {
    setInTimeout(true);
    timeout.current = setTimeout(() => {
      setInTimeout(false);
    }, 10000);
    actions.login.sendSMS(login.phone, options);
  };

  const hasSubmitted = submitCount > 0;
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <TextField
        style={{ height: 0, display: 'block', padding: 0, margin: 0, overflow: 'hidden' }}
        type="text"
        value={login.form && login.form.phone}
        autoComplete="username phone"
      />
      <FormControl fullWidth className={classes.input}>
        {loginType === 'sms' ? (
          <NumberFormat
            error={!!(errors.code && hasSubmitted)}
            helperText={hasSubmitted && errors.code && I18n.t(`LoginConfirmation.${errors.code}`)}
            name="code"
            placeholder={I18n.t('LoginConfirmation.sms code placeholder')}
            customInput={TextField}
            format="## ## ##"
            mask="_"
            type="text"
            value={values.code}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="one-time-code"
          />
        ) : (
          <TextField
            error={!!(errors.code && hasSubmitted)}
            helperText={hasSubmitted && errors.code}
            placeholder={I18n.t('LoginConfirmation.password placeholder')}
            name="code"
            type="password"
            value={values.code}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="current-password"
          />
        )}
      </FormControl>
      {!isAcceptTerms && (
        <FormControl
          required
          error={errors.terms}
          component="fieldset"
          className={classes.formControl}
        >
          <FormControlLabel
            className={classes.input}
            control={
              <Checkbox
                name="terms"
                helperText={hasSubmitted && errors.code}
                checked={values.terms?.[0] === 'on'}
                onChange={handleChange}
                value="on"
                classes={{ root: hasSubmitted && errors.terms ? classes.checkboxError : {} }}
                color={hasSubmitted && errors.terms ? 'error' : 'primary'}
              />
            }
            label={
              <Typography color={hasSubmitted && errors.terms ? 'error' : 'primary'}>
                {I18n.t('LoginConfirmation.i accept the')}{' '}
                <Link className={classes.link} to="/auth/login/terms">
                  {I18n.t('LoginConfirmation.terms and conditions')}
                </Link>{' '}
                {I18n.t('LoginConfirmation.and the')}{' '}
                <Link className={classes.link} to="/auth/login/privacy">
                  {I18n.t('LoginConfirmation.privacy policy')}
                </Link>
              </Typography>
            }
          />
          {hasSubmitted && errors.terms && (
            <FormHelperText className={classes.helper}>
              {I18n.t('LoginConfirmation.You must accept')}
            </FormHelperText>
          )}
        </FormControl>
      )}
      <Button
        fullWidth
        variant="contained"
        type="submit"
        classes={{ root: classes.button }}
        color="primary"
        disabled={isSubmitting}
      >
        {I18n.t('LoginConfirmation.next')}
        <SvgIcon className={classes.arrowIcon}>
          <ArrowRightIcon />
        </SvgIcon>
      </Button>
      {loginType === 'sms' ? (
        <Typography className={classes.caption} variant="caption">
          {I18n.t('LoginConfirmation.we sent sms to', { phone: login.phone })}{' '}
          <Link to="/auth/login" className={classes.link}>
            {I18n.t('LoginConfirmation.wrong number link')}
          </Link>{' '}
          {I18n.t('LoginConfirmation.or')}{' '}
          <Button
            component="span"
            variant="text"
            role="button"
            disabled={inTimeout}
            tabIndex={-1}
            className={classes.buttonLink}
            onClick={sendSMS({ keepSessionOpen })}
          >
            {I18n.t('LoginConfirmation.resend link')}
          </Button>
          {inTimeout && (
            <Typography className={classes.caption} align="center" variant="caption">
              {I18n.t('LoginConfirmation.wait 10 seconds')}
            </Typography>
          )}
        </Typography>
      ) : null}
    </form>
  );
};

ConfirmationForm.defaultProps = {
  loginType: 'sms',
};

ConfirmationForm.propTypes = {
  actions: object.isRequired,
  classes: object.isRequired,
  errors: object.isRequired,
  handleBlur: func.isRequired,
  handleChange: func.isRequired,
  handleSubmit: func.isRequired,
  isAcceptTerms: bool.isRequired,
  isSubmitting: bool.isRequired,
  keepSessionOpen: bool.isRequired,
  login: object.isRequired,
  loginType: string,
  submitCount: number.isRequired,
  values: object.isRequired,
};

export default withStyles(styles)(connector(withNavigate(formik(ConfirmationForm))));
