import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import documentModal from 'store/app/ui/modals/document/action';

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  actions: {
    documentModal: bindActionCreators(documentModal, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
