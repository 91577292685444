import { Typography } from '@mui/material';
import React, { memo } from 'react';
import { I18n } from 'utils/i18n';

const EmptyChat = () => (
  <>
    <p />
    <Typography align="center" variant="caption">
      {I18n.t('MessagesList.This is beginning of this channel')}
    </Typography>
    <p />
  </>
);

export default memo(EmptyChat);
