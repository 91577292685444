import { Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { I18n } from 'utils/i18n';
import getMoment from 'utils/getMoment';

const upperFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

const renderDateTimeCell = params => (
  <Tooltip title={moment(params.value).format('LLLL')} enterDelay={1000}>
    <Typography>
      {params.value &&
        upperFirstLetter(
          getMoment(params.value).calendar(null, {
            sameDay: `[${I18n.t('Ago.Today')} ${I18n.t('Ago.at')}] LT`,
            lastDay: `[${I18n.t('Ago.Yesterday')} ${I18n.t('Ago.at')}] LT`,
            nextDay: `[${I18n.t('Ago.Tomorrow')} ${I18n.t('Ago.at')}] LT`,
            lastWeek: `dddd [${I18n.t('Ago.at')}] LT`,
            nextWeek: `dddd [${I18n.t('Ago.at')}] LT`,
            sameElse: `L [${I18n.t('Ago.at')}] LT`,
          }),
        )}
    </Typography>
  </Tooltip>
);

export default renderDateTimeCell;
