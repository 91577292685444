import { SvgIcon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SignRequestIcon from 'assets/icons/sign-request.svg';
import { any, bool, func, node, number, string } from 'prop-types';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import useDimensions from 'react-cool-dimensions';
import Draggable from 'react-draggable';
import { I18n } from 'utils/i18n';
import getFile from 'utils/getFile';
import getPdfjs from 'utils/pdfjs';

const Document = React.lazy(() => import('./Document'));
const Page = React.lazy(() => import('./Page'));

const useStyles = makeStyles(theme => ({
  draggable: ({ top, left, width, height, display, dragging }) => ({
    cursor: dragging ? 'grabbing' : 'grab',
    position: 'absolute',
    flexDirection: 'column',
    top,
    left,
    width,
    height,
    display,
  }),
  box: {
    height: `${(4 / 7) * 100}%`,
    display: 'flex',
    backgroundColor: 'rgba(230, 134, 0, 0.1)',
    color: '#e68600',
    fontSize: 13,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #e68600',
  },
  icon: {
    fontSize: 13,
    marginRight: '0.5em',
    marginLeft: '-0.5em',
  },
  signText: {
    height: `${(3 / 7) * 100}%`,
    fontSize: 7,
  },
  pageWrapper: {
    flex: 1,
    width: '100%',
    position: 'relative',
  },
  annotations: {
    position: 'absolute',
    zIndex: 10,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 20,
  },
  textLayer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
    lineHeight: 'initial',
    '& span': {
      color: 'transparent',
      position: 'absolute',
      whiteSpace: 'pre',
      cursor: 'text',
      transformOrigin: '0% 0%',
    },
  },
}));

const PDFPreviewLazy = ({
  file,
  docClassName,
  pageClassName,
  width,
  loading,
  showSignature,
  onSignaturePlaced,
  canvasRef,
  onScaleChange,
  fullWidth,
  pageNum,
}) => {
  const canvas = useRef();
  const textLayerDiv = useRef();
  const [loaded, setLoaded] = useState(false);
  const [page, setPage] = useState(null);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [dimensions, setDimensions] = useState(null);

  const { observe, width: containerWidth } = useDimensions();
  const pageWidth = width || fullWidth ? containerWidth - 40 : undefined;

  const props = { display: 'none', dragging };
  if (dimensions) {
    const scale = dimensions.width / dimensions.originalWidth;
    props.scale = scale;
    props.left = (dimensions.originalWidth - 5 * 40 + 30) * scale;
    props.top = (dimensions.originalHeight - 110) * scale;
    props.height = 70 * scale;
    props.width = 141 * scale;
    props.display = 'flex';
  }
  const classes = useStyles(props);

  const loadPdf = () => {
    setLoaded(true);
  };

  const loadPage = p => {
    setPage(p);
    setDimensions({
      width: p.width,
      height: p.height,
      originalWidth: p.originalWidth,
      originalHeight: p.originalHeight,
    });
  };

  useEffect(() => {
    (async () => {
      if (page?.originalWidth > 0) {
        const textContent = await page.getTextContent();
        const viewport = page.getViewport({
          scale: pageWidth ? pageWidth / page.originalWidth : 1,
        });
        const pdfjs = await getPdfjs();
        textLayerDiv.current.innerHTML = '';
        await pdfjs.renderTextLayer({
          textContent,
          container: textLayerDiv.current,
          viewport,
          enhanceTextSelection: true,
        }).promise;
        textContent.items.forEach((t, index) => {
          const font = Object.values(page.commonObjs._objs || {}).find(
            o => o.data?.loadedName === t.fontName,
          );
          if (font?.data?.ascent) {
            const span = textLayerDiv.current.children[index];
            if (span) {
              span.style.transform += ` translateY(${(font?.data?.ascent - 1) * 100}%)`;
            }
          }
        });
        if (typeof onScaleChange === 'function') {
          onScaleChange(page.originalWidth / canvas.current.width);
        }
      }
    })();
  }, [page, pageWidth]);

  useEffect(() => {
    if (canvasRef) {
      const int = setInterval(() => {
        if (
          canvasRef?.current?.style?.width !== canvas?.current?.style?.width ||
          canvasRef?.current?.style?.height !== canvas?.current?.style?.height
        ) {
          const annCanvas = canvasRef.current;
          annCanvas.width = canvas.current.width;
          annCanvas.height = canvas.current.height;
          annCanvas.style.width = canvas.current.style.width;
          annCanvas.style.height = canvas.current.style.height;
        }
      }, 500);
      return () => clearInterval(int);
    }
    return () => null;
  }, [file, canvasRef]);

  useEffect(() => {
    if (!data) {
      if (file instanceof Blob || file instanceof File) {
        setData(file);
      } else {
        getFile(file)
          .then(d => setData(d))
          .catch(e => {
            setLoaded(true);
            setError(e);
          });
      }
    }
  }, [data, file]);

  const handleStart = () => {
    setDragging(true);
  };

  const handleStop = (e, d) => {
    setDragging(false);
    const x = d.x + d.node.offsetLeft;
    const y = d.node.parentNode.offsetHeight - d.y - d.node.offsetTop - d.node.offsetHeight + 40;
    onSignaturePlaced({ x, y });
  };

  return (
    <Suspense fallback={loading}>
      {!error && data && (
        <Document file={data} className={docClassName} onLoadSuccess={loadPdf} loading={loading}>
          {loaded && (
            <Suspense fallback={loading}>
              {loaded && (
                <div className={classes.pageWrapper} ref={observe}>
                  {canvasRef && <canvas className={classes.annotations} ref={canvasRef} />}
                  <Page
                    canvasRef={canvas}
                    width={pageWidth}
                    key={`page_${pageNum}`}
                    className={pageClassName}
                    pageNumber={pageNum}
                    onLoadSuccess={loadPage}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                  >
                    <div ref={textLayerDiv} className={classes.textLayer} />
                    {showSignature && (
                      <Draggable bounds="parent" onStart={handleStart} onStop={handleStop}>
                        <div className={classes.draggable}>
                          <div className={classes.box}>
                            <SvgIcon className={classes.icon}>
                              <SignRequestIcon />
                            </SvgIcon>
                            {I18n.t('AcknowledgementCreate.Signature')}
                          </div>
                          <svg viewBox="0 0 80 18" className={classes.signText}>
                            <text x="0" y="7">
                              {I18n.t('AcknowledgementCreate.SignerName')}
                            </text>
                            <text x="0" y="15">
                              {I18n.t('AcknowledgementCreate.SigningDate')}
                            </text>
                          </svg>
                        </div>
                      </Draggable>
                    )}
                  </Page>
                </div>
              )}
            </Suspense>
          )}
        </Document>
      )}
    </Suspense>
  );
};

PDFPreviewLazy.propTypes = {
  file: any.isRequired,
  docClassName: string.isRequired,
  pageClassName: string.isRequired,
  loading: node.isRequired,
  width: number,
  onSignaturePlaced: func,
  showSignature: bool,
  canvasRef: any,
  onScaleChange: func,
  fullWidth: bool,
  pageNum: number,
};

PDFPreviewLazy.defaultProps = {
  width: null,
  onSignaturePlaced: () => {},
  showSignature: false,
  canvasRef: null,
  onScaleChange: () => {},
  fullWidth: false,
  pageNum: 1,
};

export default PDFPreviewLazy;
