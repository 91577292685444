import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { node, object } from 'prop-types';

const styles = () => ({
  root: {
    height: '22px',
    marginBottom: '15px',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSize: '15px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: '-0.5px',
    color: '#738195',
    lineHeight: 1.47,
  },
});

const Company = ({ classes, headline }) => (
<div className={classes.root}>
    {headline}
  </div>
);Company.propTypes = {
  classes: object.isRequired,
  headline: node.isRequired,
};

export default withStyles(styles)(Company);
