import Editor from 'components/common/Editor';
import { object } from 'prop-types';
import React, { useState } from 'react';

const FormikEditor = ({ field, form, ...props }) => {
  const { name, onBlur } = field;
  const { errors, values, submitCount, setFieldValue } = form;
  const [initialValue] = useState(values[name]);
  return (
    <Editor
      {...props}
      onEditorChange={value => setFieldValue(name, value)}
      onBlur={onBlur}
      initialValue={initialValue}
      name={name}
      error={!!(errors[name] && submitCount > 0)}
      helperText={submitCount > 0 && errors[name]}
    />
  );
};

FormikEditor.propTypes = {
  field: object.isRequired,
  form: object.isRequired,
};

export default FormikEditor;
