import React, { Component } from 'react';
import { object } from 'prop-types';
import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { I18n } from 'utils/i18n';
import messageShape from 'shapes/message';
import connector from './connector';

const styles = {
  root: {
    minWidth: '110px',
    color: '#fd3b36',
    fontSize: '15px',
    marginTop: '10px',
  },
};

class DeleteDraftButton extends Component {
  handleDelete = () => {
    const { actions, message } = this.props;
    actions.messages.destroy(message._id);
  };

  render() {
    const { classes } = this.props;
    return (
      <Button className={classes.root} onClick={this.handleDelete}>
        {I18n.t('AcknowledgementMessage.Delete draft')}
      </Button>
    );
  }
}

DeleteDraftButton.propTypes = {
  classes: object.isRequired,
  actions: object.isRequired,
  message: messageShape.isRequired,
};

export default withStyles(styles)(connector(DeleteDraftButton));
