import { CLOSE_CHANNEL_DRAWER, OPEN_CHANNEL_DRAWER } from './action';
import employeesTableReducer from './employeesTable/reducer';

const initialState = {
  isOpen: false,
  type: 'create',
  employeesTable: undefined,
};

const channelDrawer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_CHANNEL_DRAWER:
      return {
        ...state,
        isOpen: true,
        type: action.payload?.type || 'create',
      };

    case CLOSE_CHANNEL_DRAWER:
      return { ...state, isOpen: false };

    default: {
      const employeesTable = employeesTableReducer(state.employeesTable, action);
      return {
        ...state,
        employeesTable,
      };
    }
  }
};

export default channelDrawer;
