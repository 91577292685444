import { OPEN_PICTURE_MODAL, CLOSE_PICTURE_MODAL } from './action';

const initialState = {
  isOpen: false,
};

const pictureModal = (state = initialState, { type, payload }) => {
  switch (type) {
    case OPEN_PICTURE_MODAL:
      return {
        ...state,
        isOpen: true,
        src: payload,
      };

    case CLOSE_PICTURE_MODAL:
      return {
        ...state,
        isOpen: false,
      };

    default:
      return state;
  }
};

export default pictureModal;
