import React from 'react';
import { APP_DOMAIN } from 'services/constants';
import Cookie from 'services/Cookie';

const isUrlWithoutSubdomain = window.location.host === APP_DOMAIN;

// eslint-disable-next-line react/prop-types
const GuestGate = ({ component: Component }) => {
  const token = Cookie.get('token');
  const company = Cookie.get('company');
  const isAuthenticated = company && token;

  if (isUrlWithoutSubdomain && isAuthenticated) {
    Cookie.clear();
  }

  return <Component />;
};

export default GuestGate;
