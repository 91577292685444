import { Slider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { func, object, string } from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import messages from 'store/app/entities/messages/action';
import useConnecting from 'utils/useConnecting';
import useSendAction from 'utils/useSendAction';

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    justifyContent: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
  },
  stepLabels: {
    width: '100%',
    flexGrow: 1,
    paddingLeft: 3,
    paddingRight: 30,
    flexDirection: 'row',
  },
  stepLabel: {
    flex: 0,
    alignItems: 'center',
  },
  slider: {
    width: '100%',
    padding: 0,
  },
  markLabel: {
    top: theme.spacing(2),
  },
}));

const BlockSlider = ({ slider, msgId, blockId, updateMessage }) => {
  const classes = useStyles();

  const [value, setValue] = useState(
    slider.value || Math.round((slider.maxValue - slider.minValue) / 2),
  );
  const onResponseOk = msg => {
    updateMessage(msg);
  };

  const [disabled, send] = useSendAction(slider, msgId, blockId, onResponseOk);
  const connecting = useConnecting();

  const onValueChange = (event, val) => {
    setValue(val);
  };

  const onSlidingComplete = async (event, val) => {
    await send(val);
  };

  const marks = [];
  if (slider.showSteps && slider.step) {
    for (let i = slider.minValue; i <= slider.maxValue; i += slider.step) {
      marks.push({ value: i, label: `${i}` });
    }
  }

  return (
    <div key={`button-${slider.actionId}`} className={classes.root}>
      <Slider
        classes={{ markLabel: classes.markLabel }}
        marks={marks}
        color="primary"
        step={slider.step}
        value={value}
        onChange={onValueChange}
        onChangeCommitted={onSlidingComplete}
        disabled={connecting || disabled || slider.disabled}
        className={classes.slider}
        min={slider.minValue}
        max={slider.maxValue}
      />
    </div>
  );
};

BlockSlider.propTypes = {
  slider: object.isRequired,
  msgId: string.isRequired,
  blockId: string.isRequired,
  updateMessage: func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  updateMessage: messages.updateSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockSlider);
