/**
 * @flow
 */
import { IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import QuestionErrorMessage from 'components/@home/drawers/FormDrawer/QuestionErrorMessage';
import QuestionOptions from 'components/@home/drawers/FormDrawer/QuestionOptions';
import QuestionTypeIcon from 'components/@home/drawers/FormDrawer/QuestionTypeIcon';
import { hasOptions } from 'components/@home/drawers/FormDrawer/questionTypes';
import type { Question } from 'components/@home/drawers/FormDrawer/Types';
import { ErrorMessage, Field, FieldArray, useFormikContext } from 'formik';
import DeleteIcon from 'mdi-react/DeleteIcon';
import React from 'react';
import { I18n } from 'utils/i18n';
import focusToEnd from 'utils/focusToEnd';
import keyIs, { DOWN, ENTER } from 'utils/keyIs';
import useFocusRef from 'utils/useFocusRef';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
  },
  question: {
    background: theme.palette.secondary.ultraLight,
    borderRadius: 6,
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
  },
  content: {
    display: 'flex',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: '1 0 auto',
    alignItems: 'center',
    marginBottom: -20,
    marginRight: -20,
  },
  remove: {
    color: theme.palette.secondary.main,
    fontSize: 11,
  },
  questionTitleWrapper: {
    display: 'flex',
  },
  questionTitle: {
    color: theme.palette.text.primary,
    background: 'transparent',
    border: 'none',
    outline: 'none',
    padding: 0,
    width: '100%',
    lineHeight: '22px',
  },
  iconWrapper: {
    color: theme.palette.primary.main,
    borderRadius: 5,
  },
  icon: {
    fontSize: 18,
    marginRight: 20,
  },
  fullWidth: {
    width: '100%',
  },
  errors: {
    flex: '1 0 auto',
  },
}));

type Props = {
  question: Question;
  index: number;
  remove: Function;
};

const QuestionForm = ({ question, index, remove }: Props) => {
  const classes = useStyles();

  const formik = useFormikContext();

  const { error } = formik.getFieldMeta(`questions.${index}`);
  const showErrors = [];
  if (formik.submitCount > 0 && typeof error === 'object') {
    Object.keys(error).forEach(field => {
      const { touched } = formik.getFieldMeta(`questions.${index}.${field}`);
      if (touched) {
        showErrors.push(field);
      }
    });
  }

  const focusRef = useFocusRef();

  const nextOnEnter = e => {
    if (keyIs(e, ENTER, DOWN)) {
      const input = e.target.nextSibling?.querySelector('input');
      focusToEnd(input);
      e.stopPropagation();
      e.preventDefault();
    }
  };

  let description = I18n.t(`FormDrawer.QuestionTypes.${question.type}.description`);
  if (description === 'description') {
    description = null;
  }
  return (
    <div className={classes.question}>
      <div className={classes.content}>
        <div className={classes.icon}>
          <QuestionTypeIcon type={question.type} text={`${index + 1}`} />
        </div>
        <div className={classes.fullWidth}>
          <Field
            name={`questions.${index}.title`}
            placeholder={I18n.t('FormDrawer.QuestionField.placeholder')}
            className={classes.questionTitle}
            id={question._id}
            innerRef={focusRef}
            onKeyDown={nextOnEnter}
          />
          {description && <Typography variant="caption">{description}</Typography>}
          {hasOptions(question.type) ? (
            <FieldArray name={`questions.${index}.options`} component={QuestionOptions} />
          ) : null}
        </div>
      </div>
      <div className={classes.actions}>
        <div className={classes.errors}>
          {showErrors.map(field => (
            <ErrorMessage
              key={field}
              name={`questions.${index}.${field}`}
              component={QuestionErrorMessage}
            />
          ))}
        </div>
        <IconButton onClick={() => remove(index)} className={classes.remove} size="large">
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default QuestionForm;
