import { OPEN_MEDIA_DRAWER, CLOSE_MEDIA_DRAWER } from './action';

const initialState = {
  isOpen: false,
  type: 'media',
};

const mediaDrawer = (state = initialState, { type, payload }) => {
  switch (type) {
    case OPEN_MEDIA_DRAWER:
      return {
        ...state,
        isOpen: true,
        type: payload,
      };

    case CLOSE_MEDIA_DRAWER:
      return {
        ...state,
        isOpen: false,
      };

    default:
      return state;
  }
};

export default mediaDrawer;
