/* eslint-disable no-nested-ternary */
import uniq from 'lodash/uniq';
import { createTransform, persistReducer } from 'redux-persist';
import storage from 'redux-persist-indexeddb-storage';
import { FLUSH_USER_DATA, LOGOUT_USER_PENDING } from 'store/app/auth/action';

import {
  DESTROY_CHANNEL_FULFILLED,
  DESTROY_CHANNEL_PENDING,
  FIND_CHANNEL_FULFILLED,
  FIND_CHANNEL_PENDING,
  GET_MEMBER_CHANNELS_FULFILLED,
  GET_MEMBER_CHANNELS_PENDING,
  GET_MEMBER_CHANNELS_REJECTED,
  JOIN_CHANNEL_FULFILLED,
  REMOVE_CHANNEL,
  SET_ARCHIVED_CHANNEL_FULFILLED,
  SET_CHANNEL,
  SET_PINNED_CHANNEL_FULFILLED,
} from 'store/app/entities/channels/action';
import { createChannel } from 'store/app/entities/channels/createChannel';
import {
  CREATE_EMPLOYEE_FULFILLED,
  MODIFY_EMPLOYEE,
  UPDATE_EMPLOYEE_FULFILLED,
} from 'store/app/entities/employees/action';
import {
  ADD_MESSAGE,
  CONFIRM_ACKNOWLEDGE_MESSAGE_FULFILLED,
  DESTROY_MESSAGE_FULFILLED,
  LOAD_MESSAGES,
  LOAD_MESSAGES_FULFILLED,
  LOAD_MESSAGES_PENDING,
  LOAD_MESSAGES_REJECTED,
  PIN_MESSAGE_FULFILLED,
  PIN_MESSAGE_PENDING,
  PIN_MESSAGE_REJECTED,
  READ_MESSAGES_FULFILLED,
  SEND_MESSAGE_FULFILLED,
  SEND_MESSAGE_PENDING,
  UNPIN_MESSAGE_FULFILLED,
  UNPIN_MESSAGE_PENDING,
  UNPIN_MESSAGE_REJECTED,
} from 'store/app/entities/messages/action';
import {
  CREATE_TEAM_FULFILLED,
  DESTROY_TEAM_FULFILLED,
  UPDATE_TEAM_FULFILLED,
} from 'store/app/entities/teams/action';
import AddRehydratedTransform from 'utils/AddRehydratedTransform';
import arrayToObject from 'utils/arrayToObject';
import merge from 'utils/merge';

const initialState = {
  error: null,
  fulfilledTimeStamp: null,
  isError: false,
  isFetching: false,
  isLoading: false,
  isSuccess: false,
  isUninitialized: true,
  startedTimeStamp: null,
  channels: {},
  rehydrated: false,
};

const channelsReducer = (state = { ...initialState }, { type, payload, meta }) => {
  const updateChannelMembership = () => {
    const channels = { ...state.channels };
    Object.keys(channels).forEach(cid => {
      const channel = { ...channels[cid] };
      const wasMember = channel.employees_ids?.find(eid => eid === payload._id);
      const member = payload.channels?.find(ec => ec._id === cid);
      if (wasMember && !member) {
        if (meta.isMe) {
          channel.isMember = false;
          channel.isAdmin = false;
          channel.canWriteAsChannel = false;
        }
        channel.employees_ids = channel.employees_ids.filter(eid => eid !== payload._id);
      }
      if (!wasMember && member) {
        if (meta?.isMe) {
          channel.isMember = true;
        }
        channel.employees_ids = [...channel.employees_ids, payload._id];
      }
    });
    return { ...state, channels };
  };
  switch (type) {
    case GET_MEMBER_CHANNELS_PENDING:
      return {
        ...state,
        isLoading: !!state.isUninitialized,
        isFetching: true,
        isSuccess: false,
        isError: false,
        error: null,
        startedTimeStamp: Date.now(),
      };
    case GET_MEMBER_CHANNELS_FULFILLED: {
      const channels =
        payload.channels.map(c =>
          createChannel({
            ...state.channels[c._id],
            ...c,
            isMember: true,
            isActive: true,
            loaded: false,
          }),
        ) || [];

      return {
        ...state,
        isFetching: false,
        isLoading: false,
        isSuccess: true,
        isUninitialized: false,
        fulfilledTimeStamp: Date.now(),
        rehydrated: false,
        channels: arrayToObject(channels, '_id'),
      };
    }
    case GET_MEMBER_CHANNELS_REJECTED:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        isSuccess: false,
        isError: true,
        error: payload,
      };

    case LOAD_MESSAGES_PENDING:
      if (!state.channels[meta.channel_id]) {
        return state;
      }

      return {
        ...state,
        channels: {
          ...state.channels,
          [meta.channel_id]: {
            ...state.channels[meta.channel_id],
            loadingMessages: true,
            loadedMessages: false,
          },
        },
      };

    case LOAD_MESSAGES_FULFILLED: {
      const _id = meta.channel_id;

      if (!state.channels[_id]) {
        return state;
      }

      const channel = state.channels[_id] || {};

      const isCurrentChannel = _id === meta.current_id;

      const newChannel = merge(channel, {
        complete: channel.complete || meta.complete,
        loadingMessages: false,
        loadedMessages: true,
        mandatoriesUnreadCount: payload.unreadMandatoriesCount,
        messages_ids: [
          ...(channel.messages_ids || []),
          ...(payload.messages?.map(m => m._id) || []),
        ],
        messagesTotalCount: payload.totalCount,
        messagesUnreadCount: isCurrentChannel && meta.hasFocus ? 0 : payload.unreadCount,
        page: meta.page || channel.page,
      });

      return { ...state, channels: { ...state.channels, [_id]: newChannel } };
    }

    case LOAD_MESSAGES_REJECTED:
      if (!state.channels[meta.channel_id]) {
        return state;
      }
      return {
        ...state,
        channels: {
          ...state.channels,
          [meta.channel_id]: {
            ...state.channels[meta.channel_id],
            loadingMessages: false,
            loadedMessages: false,
          },
        },
      };

    case READ_MESSAGES_FULFILLED:
      if (!state.channels[meta.channel_id]) {
        return state;
      }
      return {
        ...state,
        channels: {
          ...state.channels,
          [meta.channel_id]: { ...state.channels[meta.channel_id], messagesUnreadCount: 0 },
        },
      };

    case SEND_MESSAGE_PENDING: {
      const _id = meta.message.channel_id;

      return {
        ...state,
        channels: {
          ...state.channels,
          [_id]: {
            ...state.channels[_id],
            messages_ids: uniq([...state.channels[_id].messages_ids, meta.message_id]),
            messagesTotalCount: state.channels[_id].messagesTotalCount + 1,
            updatedAt: Date.now(),
          },
        },
      };
    }

    case SEND_MESSAGE_FULFILLED: {
      const _id = payload.channel;
      const messagesIds = uniq([...state.channels[_id].messages_ids, payload._id]).filter(
        id => id !== meta.message_id,
      );

      return {
        ...state,
        channels: {
          ...state.channels,
          [_id]: {
            ...state.channels[_id],
            lastMessage: payload,
            messages_ids: messagesIds,
            lastMessage_id: payload._id,
            updatedAt: Date.now(),
          },
        },
      };
    }

    case ADD_MESSAGE: {
      if (payload.deletedAt) {
        return state;
      }
      const _id = payload.channel?._id || payload.channel;
      const channel = state.channels[_id];

      if (!channel) {
        return state;
      }

      const isCurrentChannel = _id === meta.currentId;

      return {
        ...state,
        channels: {
          ...state.channels,
          [_id]: {
            ...state.channels[_id],
            lastMessage:
              !state.channels[_id].lastMessage_id ||
              payload._id > state.channels[_id].lastMessage_id
                ? payload
                : state.channels[_id].lastMessage,
            lastMessage_id:
              !state.channels[_id].lastMessage_id ||
              payload._id > state.channels[_id].lastMessage_id
                ? payload._id
                : state.channels[_id].lastMessage_id,
            mandatoriesUnreadCount: payload.isMandatory
              ? state.channels[_id].mandatoriesUnreadCount + 1
              : state.channels[_id].mandatoriesUnreadCount,
            // eslint-disable-next-line camelcase
            messages_ids: uniq([...channel.messages_ids, payload._id]),
            messagesTotalCount: channel.messagesTotalCount + 1,
            messagesUnreadCount:
              isCurrentChannel && meta.hasFocus
                ? 0
                : channel.messagesUnreadCount +
                  (payload.isMandatory ||
                  payload.isReadByMe ||
                  payload.from?._id === meta.authUserId
                    ? 0
                    : 1),
          },
        },
      };
    }

    case SET_CHANNEL: {
      const updatedChannel = createChannel(merge(state.channels[payload._id], payload));
      const newChannel = {
        ...updatedChannel,
        isReadOnly: payload.isReadOnly,
        updatedAt: Date.now(),
      };

      return {
        ...state,
        channels: {
          ...state.channels,
          [payload._id]: newChannel,
        },
      };
    }

    case DESTROY_CHANNEL_PENDING:
    case DESTROY_CHANNEL_FULFILLED: {
      const channels = { ...state.channels };
      delete channels[meta.channel_id];

      return { ...state, channels };
    }

    case REMOVE_CHANNEL: {
      const channels = { ...state.channels };
      delete channels[payload];

      return { ...state, channels };
    }

    case JOIN_CHANNEL_FULFILLED: {
      const channels = { ...state.channels };
      channels[meta.channel_id] = createChannel(merge(state.channels[payload._id], payload));
      return { ...state, channels };
    }

    case CREATE_EMPLOYEE_FULFILLED:
    case UPDATE_EMPLOYEE_FULFILLED:
      return updateChannelMembership();

    case MODIFY_EMPLOYEE: {
      const channels = { ...state.channels };

      const directChannel = channels[payload.user?._id];

      if (!directChannel) return state;

      channels[directChannel._id] = {
        ...directChannel,
        name: `${payload.firstName} ${payload.surName}`,
        avatar: payload.avatar,
      };

      return { ...state, channels };
    }

    case CONFIRM_ACKNOWLEDGE_MESSAGE_FULFILLED:
      return {
        ...state,
        channels: {
          ...state.channels,
          [meta.channel_id]: {
            ...state.channels[meta.channel_id],
            mandatoriesUnreadCount: state.channels[meta.channel_id].mandatoriesUnreadCount - 1,
          },
        },
      };

    case DESTROY_MESSAGE_FULFILLED: {
      const { channel_id: channelId, lastMessage_id: lastMessageId } = meta;

      return {
        ...state,
        channels: {
          ...state.channels,
          [channelId]: {
            ...state.channels[channelId],
            lastMessage_id: lastMessageId,
          },
        },
      };
    }

    case FIND_CHANNEL_PENDING: {
      const { channelId } = meta;
      return {
        ...state,
        channels: {
          ...state.channels,
          [channelId]: {
            ...state.channels[channelId],
            loading: true,
            loaded: false,
          },
        },
      };
    }

    case FIND_CHANNEL_FULFILLED: {
      const { channelId } = meta;
      return {
        ...state,
        channels: {
          ...state.channels,
          [channelId]: createChannel({
            ...state.channels[channelId],
            ...payload,
            loading: false,
            loaded: true,
          }),
        },
      };
    }

    case CREATE_TEAM_FULFILLED:
    case DESTROY_TEAM_FULFILLED:
    case UPDATE_TEAM_FULFILLED: {
      // Force reloading group members when editing teams
      const channels = {};
      Object.keys(state.channels).forEach(channelId => {
        channels[channelId] = { ...state.channels[channelId], loaded: false };
      });
      return { ...state, channels };
    }

    case LOAD_MESSAGES: {
      // Clear channel messages when loading messages from a search result
      return meta?.aroundId
        ? {
            ...state,
            channels: {
              ...state.channels,
              [payload]: { ...state.channels[payload], messages_ids: [], complete: false },
            },
          }
        : state;
    }

    case SET_ARCHIVED_CHANNEL_FULFILLED: {
      return { ...state };
    }

    case SET_PINNED_CHANNEL_FULFILLED: {
      return {
        ...state,
        channels: {
          ...state.channels,
          [meta.channelId]: createChannel({
            ...state.channels[meta.channelId],
            ...payload,
          }),
        },
      };
    }

    case PIN_MESSAGE_PENDING:
    case PIN_MESSAGE_FULFILLED:
      return {
        ...state,
        channels: {
          ...state.channels,
          [meta.channelId]: createChannel({
            ...state.channels[meta.channelId],
            ...payload,
            pinnedMessage: meta.message,
          }),
        },
      };

    case PIN_MESSAGE_REJECTED:
    case UNPIN_MESSAGE_REJECTED:
      return {
        ...state,
        channels: {
          ...state.channels,
          [meta.channelId]: createChannel({
            ...state.channels[meta.channelId],
            ...payload,
            pinnedMessage: meta.pinnedMessage,
          }),
        },
      };

    case UNPIN_MESSAGE_PENDING:
    case UNPIN_MESSAGE_FULFILLED:
      return {
        ...state,
        channels: {
          ...state.channels,
          [meta.channelId]: createChannel({
            ...state.channels[meta.channelId],
            ...payload,
            pinnedMessage: {},
          }),
        },
      };

    case FLUSH_USER_DATA:
    case LOGOUT_USER_PENDING: {
      storage('ommnio').removeItem('persist:channels');
      return initialState;
    }

    default:
      return state;
  }
};

const RemoveLoadedTransform = createTransform(
  inboundChannels => {
    const channels = { ...inboundChannels };
    if (!inboundChannels) return inboundChannels;
    Object.keys(inboundChannels).forEach(channelId => {
      channels[channelId] = {
        ...channels[channelId],
        loading: false,
        loaded: false,
        loadingMessages: false,
        loadedMessages: false,
      };
    });
    return channels;
  },
  outboundChannels => {
    const channels = { ...outboundChannels };
    if (!outboundChannels) return outboundChannels;
    Object.keys(outboundChannels).forEach(channelId => {
      channels[channelId] = {
        ...channels[channelId],
        loading: false,
        loaded: false,
        loadingMessages: false,
        loadedMessages: false,
      };
    });
    return channels;
  },
  { whitelist: ['channels'] },
);

const persistConfig = {
  key: 'channels',
  whitelist: ['fulfilledTimeStamp', 'isUninitialized', 'isSuccess', 'channels', 'rehydrated'],
  storage: storage('ommnio'),
  throttle: 200,
  version: 0,
  transforms: [AddRehydratedTransform, RemoveLoadedTransform],
};

export default persistReducer(persistConfig, channelsReducer);
