import { caES as caESCore } from '@mui/material/locale';
import { getGridLocalization } from '@mui/x-data-grid/utils/getGridLocalization';

const caESGrid = {
  // Root
  noRowsLabel: 'Sense resultats.',
  noResultsOverlayLabel: "No s'han trobat resultats.",
  errorOverlayDefaultLabel: 'Hi ha hagut un error.',
  // Density selector toolbar button text
  toolbarDensity: 'Densitat',
  toolbarDensityLabel: 'Densitat',
  toolbarDensityCompact: 'Compacta',
  toolbarDensityStandard: 'Estàndard',
  toolbarDensityComfortable: 'Còmoda',
  // Columns selector toolbar button text
  toolbarColumns: 'Columnes',
  toolbarColumnsLabel: 'Seleccionar columnes',
  // Filters toolbar button text
  toolbarFilters: 'Filtres',
  toolbarFiltersLabel: 'Mostrar filtres',
  toolbarFiltersTooltipHide: 'Ocultar filtres',
  toolbarFiltersTooltipShow: 'Mostrar filtres',
  toolbarFiltersTooltipActive: count =>
    count > 1 ? `${count} filtres actius` : `${count} filtre actiu`,
  // Export selector toolbar button text
  toolbarExport: 'Exportar',
  toolbarExportLabel: 'Exportar',
  toolbarExportCSV: 'Descarregar com a CSV',
  // toolbarExportPrint: 'Print',
  // Columns panel text
  columnsPanelTextFieldLabel: 'Columna de cerca',
  columnsPanelTextFieldPlaceholder: 'Títol de columna',
  columnsPanelDragIconLabel: 'Reordenar columna',
  columnsPanelShowAllButton: 'Mostrar tot',
  columnsPanelHideAllButton: 'Ocultar tot',
  // Filter panel text
  filterPanelAddFilter: 'Agregar filtre',
  filterPanelDeleteIconLabel: 'Esborrar',
  // filterPanelLinkOperator: 'Logic operator',
  filterPanelOperators: 'Operadors',
  // TODO v6: rename to filterPanelOperator
  filterPanelOperatorAnd: 'I',
  filterPanelOperatorOr: 'O',
  filterPanelColumns: 'Columnes',
  filterPanelInputLabel: 'Valor',
  filterPanelInputPlaceholder: 'Valor de filtre',
  // Filter operators text
  filterOperatorContains: 'conté',
  filterOperatorEquals: 'és igual',
  filterOperatorStartsWith: 'comença amb',
  filterOperatorEndsWith: 'acaba amb',
  filterOperatorIs: 'és',
  filterOperatorNot: 'no és',
  filterOperatorAfter: 'és posterior',
  filterOperatorOnOrAfter: 'és posterior o igual',
  filterOperatorBefore: 'és anterior',
  filterOperatorOnOrBefore: 'és anterior o igual',
  filterOperatorIsEmpty: 'és buit',
  filterOperatorIsNotEmpty: 'no és buit',
  filterOperatorIsAnyOf: 'és qualsevol de',
  // Filter values text
  // filterValueAny: 'any',
  // filterValueTrue: 'true',
  // filterValueFalse: 'false',
  // Column menu text
  columnMenuLabel: 'Menú',
  columnMenuShowColumns: 'Mostrar columnes',
  columnMenuFilter: 'Filtre',
  columnMenuHideColumn: 'Ocultar',
  columnMenuUnsort: 'Desordenar',
  columnMenuSortAsc: 'Ordenar ASC',
  columnMenuSortDesc: 'Ordenar DESC',
  // Column header text
  columnHeaderFiltersTooltipActive: count =>
    count > 1 ? `${count} filtres actius` : `${count} filtre actiu`,
  columnHeaderFiltersLabel: 'Mostrar filtres',
  columnHeaderSortIconLabel: 'Ordenar',
  // Rows selected footer text
  footerRowSelected: count =>
    count > 1
      ? `${count.toLocaleString()} files seleccionadas`
      : `${count.toLocaleString()} fila seleccionada`,
  // Total row amount footer text
  footerTotalRows: 'Files Totals:',
  // Total visible row amount footer text
  footerTotalVisibleRows: (visibleCount, totalCount) =>
    `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,
  // Checkbox selection text
  // checkboxSelectionHeaderName: 'Checkbox selection',
  // checkboxSelectionSelectAllRows: 'Select all rows',
  // checkboxSelectionUnselectAllRows: 'Unselect all rows',
  // checkboxSelectionSelectRow: 'Select row',
  // checkboxSelectionUnselectRow: 'Unselect row',
  // Boolean cell text
  booleanCellTrueLabel: 'sí',
  booleanCellFalseLabel: 'no',
  // Actions cell more text
  actionsCellMore: 'més', // Column pinning text
  // pinToLeft: 'Pin to left',
  // pinToRight: 'Pin to right',
  // unpin: 'Unpin',
  // Tree Data
  // treeDataGroupingHeaderName: 'Group',
  // treeDataExpand: 'see children',
  // treeDataCollapse: 'hide children',
  // Grouping columns
  // groupingColumnHeaderName: 'Group',
  // groupColumn: name => `Group by ${name}`,
  // unGroupColumn: name => `Stop grouping by ${name}`,
  // Master/detail
  // expandDetailPanel: 'Expand',
  // collapseDetailPanel: 'Collapse',
};
export const caES = getGridLocalization(caESGrid, caESCore);
