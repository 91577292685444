import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import messages from 'store/app/entities/messages/action';
import analyticsDrawer from 'store/app/ui/drawers/analytics/action';
import acknowledgmentReadModal from 'store/app/ui/modals/acknowledgment/read/action';
import authEmployee from 'store/selectors/authEmployee';
import isCompanyAdmin from 'store/selectors/isCompanyAdmin';
import isTeamAdmin from 'store/selectors/isTeamAdmin';

const mapStateToProps = state => ({
  isCompanyAdmin: isCompanyAdmin(state),
  isTeamAdmin: isTeamAdmin(state),
  authEmployee: authEmployee(state),
  message: state.modals.acknowledgment.read.message,
  employee: state.modals.acknowledgment.read.employee,
  isSignatureOpen: state.modals.acknowledgment.read.isSignatureOpen,
  signUrl: state.modals.acknowledgment.read.signUrl,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    acknowledgmentReadModal: bindActionCreators(acknowledgmentReadModal, dispatch),
    analyticsDrawer: bindActionCreators(analyticsDrawer, dispatch),
    messages: bindActionCreators(messages, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
