import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import forms from 'store/app/entities/forms/action';
import messages from 'store/app/entities/messages/action';
import fillFormDrawer from 'store/app/ui/drawers/fillForm/action';
import authCompany from 'store/selectors/authCompany';
import authEmployee from 'store/selectors/authEmployee';
import authUser from 'store/selectors/authUser';
import currentChannel from 'store/selectors/currentChannel';
import getForm from 'store/selectors/getForm';

const mapStateToProps = state => {
  const formId = state.drawers.fillForm.message?.form;
  return {
    isOpen: state.drawers.fillForm.isOpen,
    isEdit: state.drawers.fillForm.type === 'edit',
    message: state.drawers.fillForm.message,
    channel: currentChannel(state),
    authCompany: authCompany(state),
    authEmployee: authEmployee(state),
    authUser: authUser(state),
    formId,
    form: formId && getForm(state, { formId }),
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    forms: bindActionCreators(forms, dispatch),
    drawers: {
      fillForm: bindActionCreators(fillFormDrawer, dispatch),
    },
    messages: bindActionCreators(messages, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
