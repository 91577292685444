import isUrl from 'utils/isUrl';
import * as Yup from 'yup';

function testURL(message, { allowPath = false, ...options } = {}) {
  return this.test({
    name: 'url',
    message: message || 'Not a valid URL',
    params: { message },
    test: value =>
      value == null || isUrl(value, options) || (allowPath && value.match(/^\/[a-z0-9-/]+$/)),
  });
}

Yup.addMethod(Yup.string, 'isUrl', testURL);

export default Yup;
