/** @flow */
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AppData from 'services/api/AppData';
import { LAUNCHER_APP_ID } from 'services/constants';
import authEmployee from 'store/selectors/authEmployee';

type Props = {
  link: {
    _id: string;
    id: string;
    text: string;
    url: string;
    imageLight: string;
    imageDark: string;
    params: string[];
    embed: boolean;
  };
};

const styles = {
  link: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 1,
    backgroundColor: 'background.paper',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: 'background.default',
    },
  },
  figure: {
    width: 104,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textWrap: 'nowrap',
    overflow: 'hidden',
    margin: 0,
  },
  figcaption: {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  icon: {
    height: 60,
    aspectRatio: '1/1',
    objectFit: 'contain',
    marginBottom: 1,
    borderRadius: 1,
  },
};

const LauncherCard = ({ link }: Props) => {
  const theme = useTheme();
  const employee = useSelector(authEmployee);
  const getUrl = useCallback(async () => {
    if (link.params) {
      const newUrl = new URL(link.url);
      link.params.forEach(key => {
        newUrl.searchParams.append(key, employee[key]);
      });
      const newSignatureParams = await AppData.getSignatureParams(
        LAUNCHER_APP_ID,
        newUrl.searchParams.toString(),
      );
      Object.entries(newSignatureParams).forEach(([key, value]) => {
        newUrl.searchParams.append(key, value);
      });
      return newUrl.toString();
    }
    return link.url;
  }, [employee, link.params, link.url]);

  const navigate = useNavigate();
  const handleClick = useCallback(
    async e => {
      const url = await getUrl();
      if (!url) return;
      if (link.embed) {
        e.preventDefault();
        e.stopPropagation();
        navigate(`/home/launcher/${link._id}`, { state: { url } });
      } else if (window) {
        window.open(url, '_blank');
      }
    },
    [getUrl, link._id, link.embed, navigate],
  );
  return (
    <IconButton onClick={handleClick} key={link.id} sx={styles.link}>
      <Box sx={styles.figure} component="figure">
        <Box
          sx={styles.icon}
          component="img"
          src={
            theme.palette.mode === 'dark'
              ? link.imageDark || link.imageLight
              : link.imageLight || link.imageDark
          }
          alt={link.text}
        />
        <Typography sx={styles.figcaption} component="figcaption" variant="caption">
          {link.text}
        </Typography>
      </Box>
    </IconButton>
  );
};

export default LauncherCard;
