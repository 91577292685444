import React from 'react';
import { object, node } from 'prop-types';
import { ImageList } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

const styles = {
  root: {
    width: '100%',
    paddingTop: '20px',
  },
};

const MediaGrid = ({ classes, children }) => (
  <ImageList className={classes.root} spacing={20} cols={7} cellHeight={112}>
    {children}
  </ImageList>
);

MediaGrid.propTypes = {
  classes: object.isRequired,
  children: node.isRequired,
};

export default withStyles(styles)(MediaGrid);
