import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import apps from 'store/app/entities/apps/action';

const mapStateToProps = state => ({
  apps: state.apps.apps,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    apps: bindActionCreators(apps, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
