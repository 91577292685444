import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import employees from 'store/app/entities/employees/action';

const mapStateToProps = state => ({
  locale: state.i18n.locale,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    employees: bindActionCreators(employees, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
