import withStyles from '@mui/styles/withStyles';
import Pagination from 'components/controls/Pagination';
import isEmpty from 'lodash/isEmpty';
import { array, number, object } from 'prop-types';
import React, { Component } from 'react';
import connector from './connector';
import DocumentItem from './DocumentItem';
import DocumentList from './DocumentList';

const DOCS_PER_PAGE = 10;

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  list: {
    width: '100%',
    paddingTop: '20px',
  },
});

class Documents extends Component {
  state = {
    page: 0,
  };

  componentDidMount() {
    this.refresh();
  }

  changePage = ({ selected: page }) => {
    const { actions } = this.props;
    this.setState({ page });

    actions.media.docs(page + 1, DOCS_PER_PAGE);
  };

  refresh = () => {
    const { actions } = this.props;
    const { page } = this.state;
    actions.media.docs(page + 1, DOCS_PER_PAGE);
  };

  render() {
    const { classes, documents, total } = this.props;
    const { page } = this.state;

    if (isEmpty(documents)) return null;

    return (
      <div className={classes.root}>
        <DocumentList>
          {documents.map(doc => (
            <DocumentItem url={doc.httpLink} key={doc._id} doc={doc} confirmCallback={this.refresh}>
              {doc.title && doc.title !== doc.name ? `${doc.title} - ${doc.name}` : doc.name}
            </DocumentItem>
          ))}
        </DocumentList>
        <Pagination perPage={DOCS_PER_PAGE} total={total} page={page} onChange={this.changePage} />
      </div>
    );
  }
}

Documents.propTypes = {
  classes: object.isRequired,
  documents: array.isRequired,
  total: number.isRequired,
  actions: object.isRequired,
};
export default withStyles(styles)(connector(Documents));
