import throttle from 'lodash/throttle';
import ms from 'ms';
import { BACKEND_URL } from 'services/constants';
import Socket from 'services/Socket';

const postMessage = (worker, token, type) => {
  worker.postMessage({
    ...token,
    type,
    baseUrl: `${BACKEND_URL}/api`,
  });
};
const POST_MESSAGE_FREQ = ms('1 second');
const postMessageDebounced = throttle(postMessage, POST_MESSAGE_FREQ, {
  maxWait: POST_MESSAGE_FREQ,
});

export default (token, keepSessionOpen, onRefresh, setActive) => {
  const worker = new Worker('/worker.js?v=4');

  worker.onmessage = event => {
    switch (event.data.type) {
      case 'logout':
        window.location.replace('/logout');
        break;
      case 'refresh':
        onRefresh(event.data.token, keepSessionOpen);
        break;
      case 'active':
        setActive(true);
        break;
      case 'inactive':
        setActive(false);
        break;
      default:
    }
  };

  if (!keepSessionOpen) {
    postMessage(worker, token, 'init');
  }

  window.addEventListener('click', () => {
    postMessageDebounced(worker, token, 'refresh');
  });
  window.addEventListener('keyup', () => {
    postMessageDebounced(worker, token, 'refresh');
  });
  window.addEventListener('mousemove', () => {
    postMessageDebounced(worker, token, 'refresh');
  });
  return () => {
    postMessage(worker, token, 'cancel');
    worker.terminate();
  };
};
