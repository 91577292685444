import { SvgIcon } from '@mui/material';
import OptionButton from 'components/@home/@messages/ChatPanel/SendForm/buttons/OptionButton';
import React, { useCallback } from 'react';
import { I18n } from 'utils/i18n';
import PostIcon from '@mui/icons-material/Article';
import { useMatch, useNavigate } from 'react-router-dom';

const SendArticleButton = () => {
  const match = useMatch('/home/messages/:id/*');
  const navigate = useNavigate();

  const navigateToCreatePost = useCallback(() => {
    navigate(`${match.pathnameBase}/article/new`);
  }, [match.pathnameBase, navigate]);

  return (
    <OptionButton
      onClick={navigateToCreatePost}
      icon={
        <SvgIcon>
          <PostIcon />
        </SvgIcon>
      }
    >
      {I18n.t('SendMessageForm.Article')}
    </OptionButton>
  );
};

export default SendArticleButton;
