import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { object, string } from 'prop-types';
import React from 'react';
import { I18n } from 'utils/i18n';

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.link.main,
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.link.hover,
    },
  },
}));

const ChatFlowCaption = ({ section, translationProps, className }) => {
  const classes = useStyles();
  const link = I18n.t(`ChatFlowSettings.${section} link`);
  return (
    <Typography display="block" variant="body2" className={className}>
      {I18n.t(`ChatFlowSettings.${section} caption`, translationProps)}{' '}
      {link?.startsWith('http') ? (
        <a className={classes.link} target="_blank" rel="noopener noreferrer" href={link}>
          {I18n.t('ChatFlowSettings.Learn more')}
        </a>
      ) : (
        ''
      )}
    </Typography>
  );
};

ChatFlowCaption.propTypes = {
  section: string.isRequired,
  translationProps: object,
  className: string,
};

ChatFlowCaption.defaultProps = {
  translationProps: {},
  className: null,
};

export default ChatFlowCaption;
