const moveUp = (e, parentClass) => {
  const sibling = e.target.closest(`.${parentClass}`).previousElementSibling;
  if (sibling) {
    if (sibling.querySelector('input')) {
      sibling.querySelector('input').focus();
    } else {
      moveUp({ target: sibling }, parentClass);
    }
  }
};
const moveDown = (e, parentClass) => {
  const sibling = e.target.closest(`.${parentClass}`).nextElementSibling;
  if (sibling) {
    if (sibling.querySelector('input')) {
      sibling.querySelector('input').focus();
    } else {
      moveDown({ target: sibling }, parentClass);
    }
  }
};
export const handleKeyDown = (idx, name, value, setFieldValue, parentClass) => e => {
  if (e.key === 'Enter') {
    e.preventDefault();
    e.stopPropagation();
    moveDown(e, parentClass);
  } else if (e.key === 'Backspace' && e.target.value === '') {
    e.preventDefault();
    moveUp(e, parentClass);
    if (idx < value.length - 1) {
      setFieldValue(name, value.filter((v, i) => i !== idx));
    }
  } else if (e.key === 'ArrowUp') {
    moveUp(e, parentClass);
  } else if (e.key === 'ArrowDown') {
    moveDown(e, parentClass);
  }
};
