import { createSelector } from 'reselect';

export default createSelector(
  state => state.auth,
  ({
    error,
    fulfilledTimeStamp,
    isError,
    isFetching,
    isLoading,
    isSuccess,
    isUninitialized,
    rehydrated,
    startedTimeStamp,
  }) => ({
    error,
    fulfilledTimeStamp,
    isError,
    isFetching,
    isLoading,
    isSuccess,
    isUninitialized,
    rehydrated,
    startedTimeStamp,
  }),
);
