import arrayToObject from 'utils/arrayToObject';
import merge from 'utils/merge';
import {
  CREATE_CHAT_FLOW_INTENT_FULFILLED,
  DESTROY_CHAT_FLOW_INTENT_FULFILLED,
  FIND_CHAT_FLOW_INTENT_FULFILLED,
  FIND_CHAT_FLOW_INTENT_PENDING,
  LOAD_CHAT_FLOW_INTENTS_FULFILLED,
  LOAD_CHAT_FLOW_INTENTS_PENDING,
  UPDATE_CHAT_FLOW_INTENT_FULFILLED,
} from './action';

const chatFlowsReduceer = (
  state = { chatFlowIntents: {}, loading: false, loaded: false },
  { type, payload, meta },
) => {
  switch (type) {
    case LOAD_CHAT_FLOW_INTENTS_FULFILLED: {
      const chatFlowIntents = payload.chatFlowIntents.map(c =>
        merge(state.chatFlowIntents[c._id], c),
      );

      return merge(state, {
        chatFlowIntents: arrayToObject(chatFlowIntents),
        loading: false,
        loaded: true,
      });
    }

    case FIND_CHAT_FLOW_INTENT_PENDING: {
      return merge(state, { loading: true });
    }

    case CREATE_CHAT_FLOW_INTENT_FULFILLED:
    case FIND_CHAT_FLOW_INTENT_FULFILLED:
    case UPDATE_CHAT_FLOW_INTENT_FULFILLED: {
      const chatFlowIntent = merge({ ...state.chatFlowIntents[payload._id] }, payload);
      return merge(state, {
        loading: false,
        chatFlowIntents: { ...state.chatFlowIntents, [payload._id]: chatFlowIntent },
      });
    }

    case DESTROY_CHAT_FLOW_INTENT_FULFILLED: {
      const chatFlowIntents = { ...state.chatFlowIntents };
      delete chatFlowIntents[meta.intentId];
      return merge(state, { chatFlowIntents });
    }

    case LOAD_CHAT_FLOW_INTENTS_PENDING:
      return merge(state, { loading: true, loaded: false });

    default:
      return state;
  }
};

export default chatFlowsReduceer;
