import { OPEN_EMPLOYEE_DRAWER, CLOSE_EMPLOYEE_DRAWER } from './action';

const initialState = {
  isOpen: false,
};

const employeeDrawer = (state = initialState, { type, payload }) => {
  switch (type) {
    case OPEN_EMPLOYEE_DRAWER:
      return {
        ...state,
        isOpen: true,
        employee_id: payload?._id || payload,
      };

    case CLOSE_EMPLOYEE_DRAWER:
      return {
        ...state,
        isOpen: false,
      };

    default:
      return state;
  }
};

export default employeeDrawer;
