import { createSelector } from 'reselect';
import authCompany from './authCompany';
import authEmployee from './authEmployee';

const availableEmployees = (company, employee) => {
  const availableWithoutMe = company.employees?.filter(e => e._id !== employee?._id) || [];
  return availableWithoutMe
    .filter(e => e.isAcceptTerms)
    .map(e => ({
      ...e,
      user_id: e.user && typeof e.user === 'object' ? e.user._id : e.user,
      name: e.firstName ? `${e.firstName} ${e.surName}` : '*****',
    }));
};
export default createSelector(
  state => authCompany(state),
  state => authEmployee(state),
  availableEmployees,
);
