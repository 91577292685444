/**
 * @flow
 */
import { Stack } from '@mui/material';
import InputField from 'components/@whistleblower-admin/drawers/InputField';
import {
  OCCURRENCE_CLOSING_CODES,
  severities,
  statuses,
} from 'components/@whistleblower-admin/drawers/OccurrenceDrawer/constants';
import Section from 'components/@whistleblower-admin/drawers/Section';
import SelectField from 'components/@whistleblower-admin/drawers/SelectField';
import { useCanEdit } from 'components/@whistleblower-admin/WhistleblowerContext';
import categories from 'components/@whistleblower/translations/categories';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

const OccurrenceTools = () => {
  const { locale } = useSelector(state => state.i18n);
  const status = useWatch({ name: 'status' });
  const canEdit = useCanEdit('occurrences');
  return (
    <Section titleKey="OccurrenceDrawer.tools" sx={{ position: 'sticky', top: 0 }}>
      <Stack sx={{ py: 2 }} gap={2}>
        <SelectField disabled={!canEdit} name="status" options={Object.values(statuses)} />
        {status === statuses.CLOSED && (
          <>
            <SelectField
              disabled={!canEdit}
              name="closingCode"
              options={OCCURRENCE_CLOSING_CODES}
            />
            <InputField name="closingComment" disabled={!canEdit} />
          </>
        )}
        <SelectField
          disabled={!canEdit}
          name="category"
          options={Object.entries(categories[locale])}
        />
        <SelectField disabled={!canEdit} name="severity" options={severities} />
      </Stack>
    </Section>
  );
};

export default OccurrenceTools;
