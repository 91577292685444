import {
  CLEAR_BULK_MESSAGING,
  FIND_BULK_MESSAGING_FULFILLED,
  HR_BOT_SEND_FULFILLED,
} from './action';

const initialState = { bulkMessaging: null };

const bulkMessagingsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case HR_BOT_SEND_FULFILLED: {
      return { ...state, bulkMessaging: payload.data };
    }
    case FIND_BULK_MESSAGING_FULFILLED: {
      const newBulkMessaging = { ...payload.data };
      if (
        state.bulkMessaging?._id === newBulkMessaging._id &&
        state.bulkMessaging?.sent > newBulkMessaging.sent
      ) {
        newBulkMessaging.sent = state.bulkMessaging.sent;
      }
      return { ...state, bulkMessaging: newBulkMessaging };
    }

    case CLEAR_BULK_MESSAGING: {
      return { ...initialState };
    }
    default:
      return state;
  }
};

export default bulkMessagingsReducer;
