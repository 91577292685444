import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import renderFolderCell from 'components/@home/@documents/renderFolderCell';
import SentDocumentsToolbar from 'components/@home/@documents/SentDocuments/SentDocumentsToolbar';
import OmmnioDataGrid from 'components/common/OmmnioDataGrid';
import { string } from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'utils/i18n';
import { useLocation } from 'react-router-dom';
import { HR_BOT_APP_ID } from 'services/constants';
import appsAction from 'store/app/entities/apps/action';
import { useListDocumentFormatsQuery } from 'store/app/entities/SharedFilesSlice';
import renderDateTimeCell from 'utils/renderDateTimeCell';
import renderPermissionsCell from 'utils/renderPermissionsCell';

const SentDocuments = ({ folder }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const app = useSelector(state => state.apps.apps?.[HR_BOT_APP_ID]);
  useEffect(() => {
    if (!app?._id) {
      dispatch(appsAction.load());
    }
  }, [app?._id]);
  const {
    data: { documentFormats } = {},
    isLoading,
    isUninitialized,
  } = useListDocumentFormatsQuery();
  const columns = [
    {
      field: 'name',
      flex: 1,
      editable: false,
      renderCell: renderFolderCell(folder, { search: location.search }, 'sent'),
      headerName: I18n.t('SentDocuments.Table.name'),
    },
    {
      field: 'lastSentAt',
      type: 'string',
      flex: 1,
      editable: false,
      renderCell: renderDateTimeCell,
      headerName: I18n.t('SentDocuments.Table.lastSentAt'),
    },
    {
      field: 'permissions',
      flex: 1,
      editable: false,
      renderCell: renderPermissionsCell,
      headerName: I18n.t('SentDocuments.Table.SharedWith'),
    },
  ];
  return (
    <Grid
      item
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <SentDocumentsToolbar />
      <Box
        sx={{
          p: 2,
          pb: 0,
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h3">{I18n.t(`DocumentsScene.Folders.${folder}`)}</Typography>
        <OmmnioDataGrid
          loading={isLoading || isUninitialized}
          sx={{ marginTop: 2 }}
          columns={columns}
          rows={documentFormats}
          getRowId={r => r._id}
          updateHistory
          disableEvenOdd
        />
      </Box>
    </Grid>
  );
};

SentDocuments.propTypes = {
  folder: string.isRequired,
};

export default SentDocuments;
