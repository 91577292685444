import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import channels from 'store/app/entities/channels/action';
import chatFlows from 'store/app/entities/chatFlows/action';
import messages from 'store/app/entities/messages/action';
import * as chatPanel from 'store/app/ui/ChatPanelSlice';
import authEmployee from 'store/selectors/authEmployee';
import authUser from 'store/selectors/authUser';
import currentChannel from 'store/selectors/currentChannel';
import isChannelBeingWatched from 'store/selectors/isChannelBeingWatched';
import isCompanyAdmin from 'store/selectors/isCompanyAdmin';
import isMember from 'store/selectors/isMember';
import isTeamAdmin from 'store/selectors/isTeamAdmin';

const mapStateToProps = state => {
  const channel = currentChannel(state);
  return {
    channel,
    authEmployee: authEmployee(state),
    authUser: authUser(state),
    isCompanyAdmin: isCompanyAdmin(state),
    isTeamAdmin: isTeamAdmin(state),
    isMember: isMember(state),
    isDirect: channel.isDirect,
    isChannelBeingWatched: isChannelBeingWatched(state),
    locale: state.i18n.locale,
    getCancelFunc: () => null,
    contextMessage: state.elements.ChatPanel.contextMessage[channel?._id],
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    channels: bindActionCreators(channels, dispatch),
    chatFlows: bindActionCreators(chatFlows, dispatch),
    messages: bindActionCreators(messages, dispatch),
    chatPanel: bindActionCreators(chatPanel, dispatch),
  },
  onUploadProgress: () => null,
});

export default connect(mapStateToProps, mapDispatchToProps);
