import Loading from 'components/common/Loading';
import React, { useCallback, useEffect, useState } from 'react';
import { number, func, string } from 'prop-types';
import { Checkbox } from '@mui/material';

const SelectAllCheckbox = ({ selected, rows, search, onChange }) => {
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [isFetching, setFetching] = useState(false);

  useEffect(() => {
    setChecked(selected > 0);
    setIndeterminate((search && selected > 0) || (!search && selected > 0 && selected < rows));
  }, [rows, search, selected]);

  const handleChange = useCallback(() => {
    setFetching(true);
    onChange(!checked)
      .then(() => {
        setIndeterminate(false);
        setChecked(!checked);
      })
      .finally(() => {
        setFetching(false);
      });
  }, [checked, onChange]);

  return isFetching ? (
    <Loading sx={{ width: 'auto', height: 42 }} size={20} />
  ) : (
    <Checkbox
      disabled={isFetching}
      color="primary"
      indeterminate={indeterminate}
      checked={checked}
      onChange={handleChange}
    />
  );
};
SelectAllCheckbox.propTypes = {
  search: string.isRequired,
  selected: number.isRequired,
  rows: number.isRequired,
  onChange: func.isRequired,
};

export default SelectAllCheckbox;
