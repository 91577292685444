const formatPhoneNumber = (phoneNumber, cb) =>
  import('libphonenumber-js')
    .then(({ parsePhoneNumber }) => parsePhoneNumber(phoneNumber).formatInternational())
    .then(pn => {
      if (typeof cb === 'function') {
        cb(pn);
      }
      return pn;
    })
    .catch(() => phoneNumber);

export default formatPhoneNumber;
