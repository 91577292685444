import Alert from 'components/alerts/Alert';
import React from 'react';
import { connect } from 'react-redux';
import { bool, object } from 'prop-types';
import { bindActionCreators } from 'redux';
import alert from 'store/app/ui/Alert/action';
import ms from 'ms';

const GlobalAlert = ({ isVisible, options, actions }) => {
  return (
    <div>
      <Alert
        isOpen={isVisible}
        variant={options.variant || 'info'}
        label={options.label}
        caption={options.caption}
        onClose={actions.alert.hide}
        autoHideDuration={options.autoHideDuration || ms('7 seconds')}
      >
        {options?.message}
      </Alert>
    </div>
  );
};

GlobalAlert.propTypes = {
  isVisible: bool,
  options: object,
  actions: object.isRequired,
};

GlobalAlert.defaultProps = {
  options: {},
  isVisible: false,
};

const mapStateToProps = state => ({
  isVisible: state.elements.Alert.isVisible,
  options: state.elements.Alert.options,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    alert: bindActionCreators(alert, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalAlert);
