import { SvgIcon, TableCell, TableRow, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';
import PDFIcon from 'assets/icons/file-pdf.svg';
import classNames from 'classnames';
import PersonReportLazy from 'components/@home/@time-tracking/PersonReport/PersonReportLazy';
import EmployeeAvatar from 'components/@home/EmployeeAvatar';
import Modal from 'components/@home/modals/Modal';
import moment from 'moment-timezone';
import { object } from 'prop-types';
import React, { useState } from 'react';
import { I18n } from 'utils/i18n';
import companyShape from 'shapes/company';
import employeeShape from 'shapes/employee';

const styles = theme => ({
  row: {
    border: 0,
    cursor: 'pointer',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.secondary.ultraUltraLight,
    },
  },
  user: {
    display: 'flex',
    alignItems: 'center',
  },
  userName: {
    marginLeft: 10,
  },
  xsHidden: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  edit: {
    marginRight: 5,
    color: '#8b98ab',
    fontSize: 11,
  },
  delete: {
    color: '#8b98ab',
    fontSize: 11,
  },
  pending: {
    color: theme.palette.secondary.main,
  },
  tag: {
    margin: theme.spacing(1),
  },
  right: {
    textAlign: 'right',
  },
  bar: {
    position: 'relative',
    marginRight: 1,
    backgroundColor: 'rgba(90,97,220,.4)',
    borderRadius: 2,
    width: 6,
    minHeight: 2,
    '&::after': {
      content: `''`,
      position: 'absolute',
      left: 0,
      bottom: 0,
      display: 'block',
      width: '100%',
      borderRadius: 2,
      height: 2,
      backgroundColor: theme.palette.secondary.light,
    },
  },
  warning: {
    backgroundColor: 'rgba(220,90,90,0.4)',
  },
  barHighlight: {
    '&::after': {
      backgroundColor: 'rgba(124,126,151,.9)',
    },
    '&$warning::after': {
      backgroundColor: 'rgba(151,124,124,.9)',
    },
  },
  bars: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    minHeight: 50,
  },
  fileIcon: {
    fill: theme.palette.primary.main,
  },
  pdfShadow: {
    maxWidth: 860,
    width: '90vw',
    height: '90vh',
    position: 'relative',
    '&::before': {
      content: `''`,
      position: 'absolute',
      boxShadow: 'inset 0px -10px 10px -10px rgba(0,0,0,0.5)',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      pointerEvents: 'none',
    },
  },
  pdf: {
    zIndex: 'initial',
    border: 0,
    width: '100%',
    height: '100%',
  },
});

const EmployeeRow = ({ classes, employee, company }) => {
  const [pdfVisible, setPdfVisible] = useState(false);
  const { timeTrackerLog } = employee;
  const date = moment(timeTrackerLog.month).tz('UTC');
  const year = date.year();
  const month = date.month();
  const days = new Array(date.daysInMonth()).fill(null);
  const { max, total } = Object.keys(timeTrackerLog.days).reduce(
    ({ max: maxIn, total: totalIn }, key) => {
      const day = timeTrackerLog.days[key] || { totalHours: 0 };
      return {
        max: Math.max(day.totalHours || 0, maxIn),
        total: totalIn + (day.totalHours || 0),
      };
    },
    {
      max: 0,
      total: 0,
    },
  );
  const dayValues = days.map((d, idx) => {
    const day = idx + 1;
    const currDay = timeTrackerLog.days[day];
    const time = currDay?.totalHours || 0;
    const shifts = currDay?.shifts || [];
    const warning = currDay?.shifts?.some(s => s.warning);
    const hours = Math.floor(time);
    const minutes = Math.round((time - hours) * 60);
    const height = max ? (time / max) * 50 : 0;
    return {
      day,
      hours,
      minutes,
      height,
      warning,
      shifts,
    };
  });

  const toggleModal = open => () => {
    setPdfVisible(open);
  };

  const totalHours = Math.floor(total);
  const totalMinutes = Math.round((total - totalHours) * 60);
  return (
    <TableRow key={employee._id} hover className={classes.row}>
      <TableCell>
        <div className={classes.user}>
          <EmployeeAvatar employee={employee} />
          <Typography className={classes.userName}>{employee.name}</Typography>
        </div>
      </TableCell>
      <TableCell>
        <Typography>{employee.code}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{employee.department}</Typography>
      </TableCell>
      <TableCell className={classes.xsHidden}>
        <Typography>{employee.jobTitle}</Typography>
      </TableCell>
      <TableCell>{employee.location}</TableCell>
      <TableCell>{`${totalHours}h ${totalMinutes}m`}</TableCell>
      <TableCell>
        <div className={classes.bars}>
          {dayValues.map(({ day, height, hours, minutes, warning }) => (
            <div
              className={classNames({
                [classes.warning]: warning,
                [classes.bar]: true,
                [classes.barHighlight]: height >= 2,
              })}
              key={day}
              style={{ height }}
              data-tip={`${moment(new Date(year, month, day)).format(
                'LL',
              )} - ${hours}h ${minutes}m`}
            />
          ))}
        </div>
      </TableCell>
      <TableCell>
        <Button title={I18n.t(`TimeTracking.PersonReport.SeeReport`)} onClick={toggleModal(true)}>
          <SvgIcon className={classes.fileIcon}>
            <PDFIcon />
          </SvgIcon>
        </Button>
        <Modal isOpen={pdfVisible} onClose={toggleModal(false)}>
          <div className={classes.pdfShadow}>
            <PersonReportLazy
              pdfViewerClass={classes.pdf}
              date={date}
              companyName={company.name}
              employee={employee}
              dayValues={dayValues}
            />
          </div>
        </Modal>
      </TableCell>
    </TableRow>
  );
};

EmployeeRow.propTypes = {
  company: companyShape.isRequired,
  classes: object.isRequired,
  employee: employeeShape.isRequired,
};

export default withStyles(styles)(EmployeeRow);
