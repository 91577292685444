import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import { bool, func, object } from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'utils/i18n';
import Attachments from 'services/api/Attachments';
import channelShape from 'shapes/channel';
import authCompanyId from 'store/selectors/authCompanyId';
import CancelButton from './CancelButton';
import ConfirmButton from './ConfirmButton';
import DialogPicture from './DialogPicture';

function ClipboardImageDialog({ file, onClose, onConfirm, channel, isOpen }) {
  const companyId = useSelector(authCompanyId);
  const [isLoading, setIsLoading] = useState(false);

  const uploadAttachment = async () => {
    setIsLoading(true);

    try {
      const attachment = await Attachments.upload(companyId, channel._id, file);
      onConfirm(attachment);
    } catch (e) {
      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{I18n.t('PasteImageDialog.Title')}</DialogTitle>
      <DialogPicture file={file} />
      <DialogActions>
        <CancelButton onClick={onClose} />
        <ConfirmButton isLoading={isLoading} onClick={uploadAttachment} />
      </DialogActions>
    </Dialog>
  );
}

ClipboardImageDialog.propTypes = {
  isOpen: bool.isRequired,
  file: object,
  onClose: func.isRequired,
  onConfirm: func.isRequired,
  channel: channelShape.isRequired,
};

ClipboardImageDialog.defaultProps = {
  file: null,
};

export default ClipboardImageDialog;
