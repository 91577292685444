import { CLOSE_RULES_DRAWER, OPEN_RULES_DRAWER } from './action';

const initialState = {
  isOpen: false,
  team: {},
  employeesTable: undefined,
};

const rulesDrawer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_RULES_DRAWER:
      return {
        ...state,
        isOpen: true,
        team: action.payload || {},
      };

    case CLOSE_RULES_DRAWER:
      return { ...state, isOpen: false };

    default: {
      return {
        ...state,
      };
    }
  }
};

export default rulesDrawer;
